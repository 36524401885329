import React from 'react';
import PropTypes from 'prop-types';
import { Box, InputLabel } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Styled } from './form-control.style';

interface TextFieldProps {
  children: React.ReactNode;

  currency?: boolean;

  error?: boolean;

  helperText?: string;

  label?: React.ReactNode;

  labelVariant?: number;

  labelAddon?: React.ReactNode;

  leftLabel?: boolean;

  optional?: boolean;
}

const FormControl: React.FC<TextFieldProps> = ({
  children,
  currency,
  error,
  helperText,
  label,
  labelAddon,
  labelVariant,
  leftLabel,
  optional,
}) => {
  return (
    <Styled.FormControl fullWidth variant="outlined">
      {label && (
        <Box mb={0.5}>
          <InputLabel disableAnimation>
            <Typography
              color={error ? 'torchRed' : 'nero'}
              variant={TypographyVariant.LargerLabel}
            >
              {label}{' '}
              {optional && (
                <Styled.Optional ml={1} px={0.75}>
                  <Typography
                    color="nero"
                    variant={TypographyVariant.SmallLabel3}
                  >
                    Optional
                  </Typography>
                </Styled.Optional>
              )}
            </Typography>
          </InputLabel>
        </Box>
      )}

      {children}

      {helperText && (
        <Box mt={0.5} className="helper-text">
          <Typography
            color={error ? 'torchRed' : 'gothic'}
            variant={TypographyVariant.HelpText}
          >
            {helperText}
          </Typography>
        </Box>
      )}
    </Styled.FormControl>
  );
};

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
  currency: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.node,
  labelAddon: PropTypes.node,
  labelVariant: PropTypes.number,
  leftLabel: PropTypes.bool,
  optional: PropTypes.bool,
};

export { FormControl };
