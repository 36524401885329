import styled from 'styled-components';
import { Typography } from 'components/core';

const Divider = styled.hr`
  border: 0.5px solid var(--color-grey6);
`;

const Header = styled.div`
  border-top-left-radius: var(--border-radius-08);
  border-top-right-radius: var(--border-radius-08);
`;

const HeaderText = styled(Typography)`
  font-weight: 700;
  text-transform: uppercase;
`;

export const Styled = {
  Divider,
  Header,
  HeaderText,
};
