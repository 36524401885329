import React, { useEffect } from 'react';
import {
  usePlaidModal,
  OpenPlaidModalErrorResponse,
} from 'modules/plaid/hooks/use-plaid-modal';
import { useSetBankingItemStatus } from 'components/dashboard/dashboard-errors/hooks/use-set-banking-item-status';
import { useTracking, TrackEventName } from 'modules/tracking';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { UserPermissionsRequired } from 'modules/2023-q4/rbac/components/user-permissions-required/user-permissions-required';
import { Styled } from './bank-plaid-auth-error.style';

interface BankPlaidAuthErrorProps {
  bankItemId: string;
  bankName?: string;
}

export const BankPlaidAuthError: React.FC<BankPlaidAuthErrorProps> = ({
  bankItemId,
  bankName,
}) => {
  const { trackEvent } =
    useTracking<{
      error?: any;
      eventName: TrackEventName;
      plaidMetaData?: any;
    }>();

  const openPlaidModal = usePlaidModal();

  const setBankingItemStatus = useSetBankingItemStatus();

  const handleOpenPlaidModal = async (bankItemId: string) => {
    try {
      // open plaid modal with bank item id
      const plaidData = await openPlaidModal({
        bankItemId,
        forceNewInstance: true,
      });

      trackEvent({
        eventName: TrackEventName.PlaidLinkModalOpened,
      });

      // on success, update plaid status, get accounts
      // TODO: we probably want to get updated indicators, charts, etc
      if (plaidData) {
        await setBankingItemStatus.mutate({ bankItemId });

        trackEvent({
          eventName: TrackEventName.PlaidLinkUpdateSucceeded,
        });
      }
    } catch (plaidErrorData) {
      const { error, plaidMetaData } =
        plaidErrorData as OpenPlaidModalErrorResponse;

      trackEvent({
        eventName: TrackEventName.PlaidLinkUpdateFailure,
        error,
        plaidMetaData,
      });
    }
  };

  useEffect(() => {
    trackEvent({ eventName: TrackEventName.PlaidAccountNotAuthenticated });
  }, []);

  const reauthenticateLink = (
    <>
      , please{' '}
      <Styled.Link onClick={() => handleOpenPlaidModal(bankItemId)}>
        reauthenticate
      </Styled.Link>
    </>
  );

  return (
    <>
      We&apos;re no longer able to connect to {bankName || 'your bank'}
      <FeatureFlag
        disabled={<>{reauthenticateLink}</>}
        enabled={
          <UserPermissionsRequired roles={[UserRole.ADMIN]}>
            {reauthenticateLink}
          </UserPermissionsRequired>
        }
        flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
      />
      .
    </>
  );
};
