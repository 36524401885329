import React from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden } from '@material-ui/core';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { Footer, FootnoteProvider } from 'components/layouts';
import { Styled } from './no-auth-layout.style';

export const NoAuthLayout: React.FC = ({ children }) => {
  return (
    <FootnoteProvider>
      <Styled.LayoutContainer disableGutters maxWidth={false}>
        <Hidden xsDown>&nbsp;</Hidden>
        {children}
        <Hidden xsDown>
          <CenteredContainer>
            <Box p={10} pt={7}>
              <Footer
                dividerColor="hitGrey"
                linkColor="hitGrey"
                textColor="hitGrey"
                topDivider={false}
              />
            </Box>
          </CenteredContainer>
        </Hidden>
        <Hidden smUp>
          <Box bgcolor="white" p={5} pt={25}>
            <Footer
              dividerColor="hitGrey"
              linkColor="hitGrey"
              textColor="hitGrey"
              topDivider={false}
            />
          </Box>
        </Hidden>
      </Styled.LayoutContainer>
    </FootnoteProvider>
  );
};

NoAuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
