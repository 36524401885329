import treasureApi from 'api';

export const getTokenByEmail = async (
  email: string,
  callback: any,
): Promise<null> => {
  if (email) {
    try {
      const body = {
        email,
      };
      const { data } = await treasureApi('auth').post(
        '/user/resetPasswordLink',
        body,
      );

      if (data.error) {
        throw new Error(data.error);
      }

      if (data.generatedlink) {
        callback(new URLSearchParams(data.generatedlink).get('oobCode'));
      }
    } catch (error) {
      //
    }
  }
  return null;
};
