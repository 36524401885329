import { WhiteLabelPartners } from 'modules/2023-q4/white-label/consts/white-label-partners';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';

export const getWhiteLabelPartnerColors = () => {
  const partnerId = useStoreWhiteLabelPartner.getState().partnerId;

  const partnerData = WhiteLabelPartners.find(
    (partner) => partner.id === partnerId,
  );

  return partnerData?.colors;
};
