import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTracking, TrackEventName } from 'modules/tracking';
import { sendGoogleTagManagerEvent } from 'modules/tracking/analytics/google-tag-manager';
import { AffiliateType } from 'enums';
import { useParams } from 'react-router-dom';
import { createCtx } from 'utils/create-ctx';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';
import { useMutateBusinessProperty } from 'hooks/business/use-mutate-business-property';
import { SignUpForm } from './components/sign-up-form/sign-up-form';
import { useGetAffiliate } from 'modules/sign-up/hooks/use-get-affiliate';
import { useSelfServeOnboarding } from 'modules/sign-up/hooks/use-self-serve-onboarding';
import { SetPasswordContainer } from './components/set-password/set-password-container';

export interface Affiliate {
  Code: string | null;
  Type: string | null;
  Identifier: string | null;
  CreatedBy: AffiliateType | null;
}

interface SignUpContext {
  affiliate: Affiliate;
}

export const [useSignUpContext, SignUpCtxProvider] = createCtx<SignUpContext>();

export const SignUpContainer: React.FC = () => {
  const { handleSubmit, control } = useForm({
    defaultValues: { email: '', businessName: '' },
  });
  const [isLoading, setLoading] = useState(false);
  const [businessId, setBusinessId] = useState('');
  const [affiliate, setAffiliate] = useState<Affiliate>({
    Code: null,
    Type: null,
    Identifier: null,
    CreatedBy: null,
  });
  const [setPasswordEmail, setSetPasswordEmail] = useState<string>();
  const { trackEvent } = useTracking();
  const getAffiliate = useGetAffiliate();
  const selfServeOnboarding = useSelfServeOnboarding();
  const { affiliateCode } = useParams<{ affiliateCode: string }>();

  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { mutate: mutateBusinessProperty } = useMutateBusinessProperty();
  const whiteLabelEnabled = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2023_Q4_WHITELABEL_ENABLED,
  );
  const { partnerId } = useStoreWhiteLabelPartner((state) => state);

  useEffect(() => {
    // if there is an affiliateCode in the url params,
    // check that it's valid and get back the affiliate object.
    const verifyAffiliate = () => {
      if (affiliateCode) {
        getAffiliate.mutate(
          { code: affiliateCode },
          {
            onSuccess: (response: Affiliate) => {
              if (response) {
                setAffiliate(response);
              }
            },
          },
        );
      }
    };

    verifyAffiliate();
  }, [affiliateCode]);

  const submitOnboardingData = async (data: any) => {
    const { email, businessName } = data;
    const requestData: any = {
      DBA: businessName,
      User: {
        Email: email,
      },
    };

    // affiliateCode is optional
    if (affiliateCode) {
      requestData.AffiliateCode = affiliateCode;
    }

    // Properties are optional
    if (whiteLabelEnabled && partnerId) {
      requestData.Properties = {
        whiteLabelPartnerId: partnerId,
      };
    }

    await selfServeOnboarding.mutate(requestData, {
      onSuccess: (resp) => {
        const responseBusinessId = resp.data.business.Id;
        setBusinessId(responseBusinessId);

        // Send data to Google Tag Manager for tracking
        sendGoogleTagManagerEvent('signup-completion', {
          category: 'signup',
          action: 'signup-completed',
        });

        setSetPasswordEmail(email);
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  const submitForm = async (data?: any) => {
    if (data && !isLoading) {
      trackEvent({ eventName: TrackEventName.SignUpCreateAccountClicked });
      setLoading(true);

      submitOnboardingData(data);
    }
  };

  if (setPasswordEmail) {
    return <SetPasswordContainer email={setPasswordEmail} />;
  }

  return (
    <SignUpCtxProvider
      value={{
        affiliate,
      }}
    >
      <SignUpForm
        handleSubmit={handleSubmit(submitForm)}
        control={control}
        isLoading={isLoading}
      />
    </SignUpCtxProvider>
  );
};
