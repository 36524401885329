import styled from 'styled-components';
import { getColor } from 'styles/css-constants';
import { Box } from '@material-ui/core';

const ChartHeight = 140;
const DefaultPeriodLineStroke = getColor('nero');
const xAxisLineStroke = getColor('lightGrey');
const NoDataChartLineStroke = getColor('silver');
const TooltipContent = styled(Box)`
  background: var(--color-white);
  border: 1px solid var(--color-jumbo);
`;

export const Styled = {
  ChartHeight,
  DefaultPeriodLineStroke,
  xAxisLineStroke,
  NoDataChartLineStroke,
  TooltipContent,
};
