import styled from 'styled-components';
import { Box } from '@material-ui/core';

const ConnectBankContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const BenefitsList = styled.ul`
  text-align: left;
  width: 325px;
  margin: 15px auto;
  li {
    list-style: disc;
    list-style-position: inside;
    p {
      display: inline;
    }
  }
`;

export const Styled = {
  BenefitsList,
  ConnectBankContainer,
};
