import styled from 'styled-components';

const A = styled.a`
  text-decoration: underline;
`;

const Iframe = styled.iframe`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
`;

export const Styled = {
  A,
  Iframe,
};
