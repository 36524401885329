import React from 'react';
import { Styled } from './toast-container.style';
import 'react-toastify/dist/ReactToastify.css'; // TODO: move to global style?

export const ToastContainer = () => {
  return (
    <Styled.StyledToastContainer
      position="top-center"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
    />
  );
};
