import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import { Header } from 'modules/onboarding/v3-streamlined-onboarding/layout/header';
import { GHBAgreementForm } from 'modules/migration/ghb-agreement/components/ghb-agreement-form';
import { useBusiness } from 'hooks/business/use-business';
import { useQueryOnboarding } from 'modules/onboarding/v3-streamlined-onboarding/hooks/query-onboarding';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';
import { useHistory } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { PolicyKey } from 'enums';
import { Person } from 'modules/onboarding/v3-streamlined-onboarding/types/person';

export const GHBAgreement: React.FC = () => {
  const history = useHistory();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();

  const { data: onboardingResponse, isLoading: isLoadingOnboardingResponse } =
    useQueryOnboarding(business?.Id);

  const { data: treasureReserve, isLoading: isLoadingTreasureReserve } =
    useTreasureReserve(business?.Id);

  const acceptedGHB = onboardingResponse?.data.policiesKeysAgreed?.includes(
    PolicyKey.BankAccountAgreement,
  );

  const hasPeopleInformation =
    !onboardingResponse?.data.people ||
    onboardingResponse?.data.people?.every((person: Person) => {
      if (person.isBusinessOwner) {
        return (
          person.ownershipPercentage !== undefined &&
          person.ownershipPercentage !== null &&
          person.email &&
          person.phoneNumber &&
          person.jobTitle
        );
      }

      return person.email && person.phoneNumber && person.jobTitle;
    });

  const acceptedGHBAndHasPeopleInfo = acceptedGHB && hasPeopleInformation;

  useEffect(() => {
    if (treasureReserve?.custodian === 'GH_ON_TP') {
      history.push(RoutesPath.pages.home.path);
    }
  }, [treasureReserve]);

  if (
    isLoadingBusiness ||
    isLoadingOnboardingResponse ||
    isLoadingTreasureReserve
  ) {
    return <Spinner />;
  }

  if (acceptedGHBAndHasPeopleInfo) {
    return (
      <>
        <Header />

        <Box maxWidth={500} mx="auto" mt={10}>
          <Typography variant={TypographyVariant.SubHeader3}>
            Thank you for submitting your updated business information.
            We&apos;re working on setting up your account. If you have any
            questions, please{' '}
            <a href="mailto:support@treasure.tech?subject=Contact Support">
              contact support
            </a>
            .
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />

      <Box maxWidth={500} mx="auto">
        <GHBAgreementForm />
      </Box>
    </>
  );
};
