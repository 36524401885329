import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const ModalHeading = styled(Box)`
  border-bottom: 1px solid var(--color-lightGrey);
  padding: 24px 0;
  text-align: center;
`;

export const ModalBody = styled(Box)`
  padding: 24px 40px;
`;

export const ModalFooter = styled(Box)`
  padding: 24px 40px;
  p {
    font-size: 13px;
  }
`;

export const Styled = {
  ModalHeading,
  ModalBody,
  ModalFooter,
};
