import React from 'react';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';

export const FooterButton = ({
  children,
  disabled,
  mobile,
  onClick,
  type,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  mobile?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
}) => {
  return (
    <MobileView
      mobile={
        <Button
          borderRadius={0}
          disabled={disabled}
          fullWidth
          onClick={onClick}
          type={type}
        >
          {children}
        </Button>
      }
    >
      <Button disabled={disabled} fullWidth onClick={onClick} type={type}>
        {children}
      </Button>
    </MobileView>
  );
};
