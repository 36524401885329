import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import NumberFormat from 'react-number-format';
import { TextField } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/text-field';
import { Trans } from 'app/i18n';
import { i18nNamespaceAllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';
import { useTracking, TrackEventName } from 'modules/tracking';

export const defaultAmount = 10000000;
const maxAmount = 10000000000;

interface AllocationSetAmountProps {
  amount: number;

  onChange: React.Dispatch<React.SetStateAction<number>>;
}

export const AllocationSetAmount: React.FC<AllocationSetAmountProps> = ({
  amount,
  onChange,
}) => {
  const { trackEvent } =
    useTracking<{
      eventName: TrackEventName;
      label: string;
      value: number;
    }>();

  const handleOnChange = (value: number) => {
    onChange(value);

    trackEvent({
      eventName: TrackEventName.Input,
      label: 'No Funds Set Amount',
      value,
    });
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant={TypographyVariant.Body}>
          <Trans
            i18nKey="setAmountNotification"
            ns={i18nNamespaceAllocationModal}
          />
        </Typography>
      </Grid>

      <Grid item>
        <NumberFormat
          allowNegative={false}
          customInput={TextField}
          decimalScale={2}
          isAllowed={(values) => {
            const { floatValue } = values;
            return floatValue ? floatValue < maxAmount : true;
          }}
          prefix="$ "
          onValueChange={(data) => {
            handleOnChange(data.floatValue || 0);
          }}
          thousandSeparator
          value={amount}
        />
      </Grid>
    </Grid>
  );
};
