import logoGeneric from 'assets/modules/2023-q4/white-label/logos/generic_logo.png';
import logoAltir from 'assets/modules/2023-q4/white-label/logos/altir.png';
import logoAltirMobile from 'assets/modules/2023-q4/white-label/logos/altir_mobile.png';
import logoAngelList from 'assets/modules/2023-q4/white-label/logos/angellist-logo-vector.png';
import logoAngelListMobile from 'assets/modules/2023-q4/white-label/logos/angellist-mobile.png';
import logoMazlo from 'assets/modules/2023-q4/white-label/logos/mazlo-black.png';
import logoMazloMobile from 'assets/modules/2023-q4/white-label/logos/mazlo_official_logo.jpeg';
import logoSlice from 'assets/modules/2023-q4/white-label/logos/slice_payments.png';
import logoSliceMobile from 'assets/modules/2023-q4/white-label/logos/slice_payments_co_logo.jpeg';
import logoOtterz from 'assets/modules/2023-q4/white-label/logos/otterz_logo.png';
import logoMundi from 'assets/modules/2023-q4/white-label/logos/mundi_logo.png';
import logoFlowFi from 'assets/modules/2023-q4/white-label/logos/flowfi_logo.png';
import logoNorthOne from 'assets/modules/2023-q4/white-label/logos/northone_logo.png';
import logoBankOfBrodhead from 'assets/modules/2023-q4/white-label/logos/bankofbrodhead_logo.png';
import logoCovercy from 'assets/modules/2023-q4/white-label/logos/covercy_logo.png';
import logoFlex from 'assets/modules/2023-q4/white-label/logos/flex.png';

// importing modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-positions/product-positions caused a TS error
enum Product {
  'Cash' = 'Cash',
  'ManagedMoneyMarket' = 'Managed Money Market',
  'ManagedTreasuries' = 'Managed Treasuries',
  'ManagedIncome' = 'Managed Income',
  'Other' = 'Other',
}

interface IWhiteLabelPartner {
  id: string;
  name: string;
  solicitationDisclosure?: string;
  colors: {
    buttonPrimaryBackground: string;
    buttonPrimaryHoverBackground: string;
    headerNavigationText: string;
    primary: string;
    productManagedMoneyMarket: string;
    productManagedTreasuries: string;
    productManagedIncome: string;
    productUninvested: string;
  };
  logos: {
    default: string;
    mobile?: string;
  };
  options?: {
    hideProducts?: {
      [key: string]: boolean;
    };
  };
}

export const WhiteLabelPartners: IWhiteLabelPartner[] = [
  {
    id: 'b15c18ac-1649-4e76-8798-402983a723bb',
    name: 'Generic',
    colors: {
      buttonPrimaryBackground: '#111111',
      buttonPrimaryHoverBackground: '#111111',
      headerNavigationText: '#000000',
      primary: '#000000',
      productManagedMoneyMarket: '#5F8575',
      productManagedTreasuries: '#AFE1AF',
      productManagedIncome: '#355E3B',
      productUninvested: '#eeeeee',
    },
    logos: {
      default: logoGeneric,
      mobile: logoGeneric,
    },
  },
  {
    id: 'ddf20f01-d262-4a4e-901f-371fdf887a88',
    name: 'Altir',
    solicitationDisclosure: 'https://www.treasurefi.com/disclosure/altir',
    colors: {
      buttonPrimaryBackground: '#4460EB',
      buttonPrimaryHoverBackground: '#12285E',
      headerNavigationText: '#4460EB',
      primary: '#4460EB',
      productManagedMoneyMarket: '#3029D8',
      productManagedTreasuries: '#0693e3',
      productManagedIncome: '#8ed1fc',
      productUninvested: '#919BAA',
    },
    logos: {
      default: logoAltir,
      mobile: logoAltirMobile,
    },
    options: {
      hideProducts: {
        [Product.ManagedTreasuries]: false,
        [Product.ManagedIncome]: true,
      },
    },
  },
  {
    id: '0c582fc0-1b02-4e96-8e11-046dabdbc173',
    name: 'AngelList',
    colors: {
      buttonPrimaryBackground: '#5f58ff',
      buttonPrimaryHoverBackground: '#5f58ff',
      headerNavigationText: '#5f58ff',
      primary: '#4460eb',
      productManagedMoneyMarket: '#3029D8',
      productManagedTreasuries: '#0693e3',
      productManagedIncome: '#8ed1fc',
      productUninvested: '#bec0ff',
    },
    logos: {
      default: logoAngelList,
      mobile: logoAngelListMobile,
    },
  },
  {
    id: '34dd0feb-356b-4aea-91d4-e18bae46df8e',
    name: 'Mazlo',
    colors: {
      buttonPrimaryBackground: '#3029D8',
      buttonPrimaryHoverBackground: '#3029D8',
      headerNavigationText: '#3029D8',
      primary: '#00ACE8',
      productManagedMoneyMarket: '#3029D8',
      productManagedTreasuries: '#0693e3',
      productManagedIncome: '#8ed1fc',
      productUninvested: '#bec0ff',
    },
    logos: {
      default: logoMazlo,
      mobile: logoMazloMobile,
    },
  },
  {
    id: '5116ff73-fb60-476e-8638-f746eb822fd6',
    name: 'Slice',
    colors: {
      buttonPrimaryBackground: '#14111A',
      buttonPrimaryHoverBackground: '#14111A',
      headerNavigationText: '#14111A',
      primary: '#14111A',
      productManagedMoneyMarket: '#1E1A24',
      productManagedTreasuries: '#7425DB',
      productManagedIncome: '#429AD1',
      productUninvested: '#3C364E',
    },
    logos: {
      default: logoSlice,
      mobile: logoSliceMobile,
    },
  },
  {
    id: 'bd04ecce-f659-4f26-ab90-a7ee91fe4a2a',
    name: 'Otterz',
    solicitationDisclosure:
      'www.treasurefi.com/disclosure/otterz-solicitation-disclosure',
    colors: {
      buttonPrimaryBackground: '#000000',
      buttonPrimaryHoverBackground: '#000000',
      headerNavigationText: '#000000',
      primary: '#000000',
      productManagedMoneyMarket: '#7372fe',
      productManagedTreasuries: '#344db1',
      productManagedIncome: '#152c5b',
      productUninvested: '#8a95ad',
    },
    logos: {
      default: logoOtterz,
      mobile: logoOtterz,
    },
  },
  {
    id: '11d129df-d846-4c99-ba02-caa5c1fb2cd1',
    name: 'Mundi',
    colors: {
      buttonPrimaryBackground: '#f45c24',
      buttonPrimaryHoverBackground: '#f45c24',
      headerNavigationText: '#000000',
      primary: '#f45c24',
      productManagedMoneyMarket: '#f0a728',
      productManagedTreasuries: '#f45c24',
      productManagedIncome: '#916440',
      productUninvested: '#444444',
    },
    logos: {
      default: logoMundi,
      mobile: logoMundi,
    },
  },
  {
    id: 'a7961a25-b6ca-421e-80ef-48d770ee478e',
    name: 'FlowFi',
    colors: {
      buttonPrimaryBackground: '#001AFF',
      buttonPrimaryHoverBackground: '#001AFF',
      headerNavigationText: '#1D2632',
      primary: '#001AFF',
      productManagedMoneyMarket: '#2bc3a7',
      productManagedTreasuries: '#fe5a8b',
      productManagedIncome: '#1a4bf8',
      productUninvested: '#a17afc',
    },
    logos: {
      default: logoFlowFi,
      mobile: logoFlowFi,
    },
  },
  {
    id: 'b48ed0f7-0e4f-404f-8261-eba768951740',
    name: 'NorthOne',
    colors: {
      buttonPrimaryBackground: '#2d2d2d',
      buttonPrimaryHoverBackground: '#2d2d2d',
      headerNavigationText: '#2d2d2d',
      primary: '#2d2d2d',
      productManagedMoneyMarket: '#c99b40',
      productManagedTreasuries: '#f1951b',
      productManagedIncome: '#e42722',
      productUninvested: '#2d2d2d',
    },
    logos: {
      default: logoNorthOne,
      mobile: logoNorthOne,
    },
  },
  {
    id: 'e157281a-1e31-4814-bd37-3e020a0ffc8b',
    name: 'BankOfBrodhead',
    colors: {
      buttonPrimaryBackground: '#9e6900',
      buttonPrimaryHoverBackground: '#9e6900',
      headerNavigationText: '#000000',
      primary: '#000000',
      productManagedMoneyMarket: '#2b3f8a',
      productManagedTreasuries: '#9e6900',
      productManagedIncome: '#f2e9d6',
      productUninvested: '#eeeeee',
    },
    logos: {
      default: logoBankOfBrodhead,
      mobile: logoBankOfBrodhead,
    },
  },
  {
    id: '0d0bd4f9-1a8e-4435-aa4a-d4b37c9f8ac9',
    name: 'Covercy',
    colors: {
      buttonPrimaryBackground: '#3d40eb',
      buttonPrimaryHoverBackground: '#3d40eb',
      headerNavigationText: '#000000',
      primary: '#000000',
      productManagedMoneyMarket: '#FEC21A',
      productManagedTreasuries: '#50B08B',
      productManagedIncome: '#3948E2',
      productUninvested: '#eeeeee',
    },
    logos: {
      default: logoCovercy,
      mobile: logoCovercy,
    },
  },
  {
    id: '35077ca6-57b2-444d-804e-9e701fda4bf5',
    name: 'Flex',
    colors: {
      buttonPrimaryBackground: '#437e5b',
      buttonPrimaryHoverBackground: '#437e5b',
      headerNavigationText: '#000000',
      primary: '#437e5b',
      productManagedMoneyMarket: '#5F8575',
      productManagedTreasuries: '#AFE1AF',
      productManagedIncome: '#355E3B',
      productUninvested: '#eeeeee',
    },
    logos: {
      default: logoFlex,
      mobile: logoFlex,
    },
  },
];
