import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ColumnType } from 'modules/dashboard/components';

interface TableRowProps {
  columns?: ColumnType[];

  data: any;

  onClick?: () => void;
}

export const TableRow: React.FC<TableRowProps> = ({
  columns,
  data,
  onClick,
}) => {
  return (
    <Grid container onClick={onClick}>
      {data.map((column: any, columnIndex: number) => (
        <Grid
          item
          key={columnIndex}
          xs={
            columns && columns[columnIndex].size ? columns[columnIndex].size : 1
          }
        >
          <Box p={1} textAlign={columns && columns[columnIndex].alignment}>
            <Typography variant={TypographyVariant.BodySemiBold}>
              {column}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
