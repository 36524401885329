import React from 'react';
import { Textarea } from '../textarea';
import {
  factoryFormFieldComponent,
  FormFieldSpecs,
} from '../utils/factory-form-field-component';

export type TextareaSpecs = FormFieldSpecs<{
  maxRows?: number;
  minRows?: number;
}>;

export const TextareaController = factoryFormFieldComponent<TextareaSpecs>(
  ({
    defaultValue,
    error,
    label,
    maxRows,
    minRows,
    name,
    onChange,
    placeholder,
    validations,
  }) => {
    const textAreaProps = {
      defaultValue,
      error: !!error,
      helperText: error?.message,
      label,
      maxRows,
      minRows,
      name,
      optional: !validations?.required,
      placeholder,
    };

    return <Textarea {...textAreaProps} onChange={onChange} />;
  },
  { componentName: 'TextareaController' },
);
