import styled from 'styled-components';
import { Typography } from 'components/core';
import { colors, getColor } from 'styles/css-constants';

interface StyledRowIndicator {
  color: string;
}

const Amount = styled(Typography)`
  font-size: 18px;
`;

const Chart = styled.table`
    border-spacing 0;
    width: 100%;
`;

const ChartHeader = styled.tr`
  background-color: var(--color-whiteSmoke0);

  th {
    text-align: left;
  }
`;

const ChartRow = styled.tr`
  td {
    border-top: 1px solid var(--color-gainsboro0);
  }
`;

const ProductName = styled(Typography)`
  text-transform: uppercase;
  white-space: nowrap;
`;

const ReturnAmount = styled(Amount)`
  font-weight: 700;
`;

const RowIndicator = styled.td<StyledRowIndicator>`
  width: 11px;
  background-color: ${(props) => getColor(props.color as keyof typeof colors)};
`;

const TableCell = styled(Typography)`
  font-size: 18px;
`;

const TableHeader = styled(Typography)`
  font-weight: 700;
  text-transform: uppercase;
`;

export const Styled = {
  Amount,
  Chart,
  ChartHeader,
  ChartRow,
  ProductName,
  ReturnAmount,
  RowIndicator,
  TableCell,
  TableHeader,
};
