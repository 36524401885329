import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useDashboardTransactions = (): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  return useQuery([ReactQueryKeys.DashboardTransactions], async () => {
    const response = await treasureApi().get('/dashboard/transactions');

    return response.data;
  });
};
