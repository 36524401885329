import React from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core';
import Tick from './tick/tick';
import { Styled } from './checkbox.style';

export interface CustomCheckboxProps {
  error?: boolean;
  disabled?: boolean;
  checked?: boolean;
}

type Props = CustomCheckboxProps & CheckboxProps;

const getCheckedIcon = (props: Props) => {
  return <Tick disabled={props.disabled} error={props.error} />;
};

export const Checkbox: React.FC<Props> = (props: Props) => {
  return (
    <MuiCheckbox
      icon={<Styled.CheckIcon error={props.error} disabled={props.disabled} />}
      checkedIcon={getCheckedIcon(props)}
      {...props}
    />
  );
};
