import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { PolicyKey, ReactQueryKeys } from 'enums';

export const usePolicies = (policyKeys: PolicyKey[]): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery([ReactQueryKeys.Policies, policyKeys], async () => {
    const response = await treasureApi().get(
      `/policies?policyKeys[]=${policyKeys.join('&policyKeys[]=')}`,
    );

    return response.data;
  });
};
