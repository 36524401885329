import styled, { css } from 'styled-components';

interface StepProps {
  $isActive?: boolean;
}

const Step = styled.li<StepProps>`
  border-left: 2px solid var(--color-white);

  ${(props) =>
    props.$isActive &&
    css`
      border-color: var(--color-stromboli);
    `};
`;

export const Styled = {
  Step,
};
