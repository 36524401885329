import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { ClickAnchor, Typography, TypographyVariant } from 'components/core';

interface NoResultsProps {
  setFilterText: React.Dispatch<React.SetStateAction<string>>;
}

export const NoResults: React.FC<NoResultsProps> = ({ setFilterText }) => {
  return (
    <Box px={5} py={10} textAlign="center">
      <Box mb={1}>
        <Typography color="nero" variant={TypographyVariant.SubHeader1}>
          No Results
        </Typography>
      </Box>

      <ClickAnchor onClick={() => setFilterText('')} hoverUnderline>
        clear search
      </ClickAnchor>
    </Box>
  );
};

NoResults.propTypes = {
  setFilterText: PropTypes.func.isRequired,
};
