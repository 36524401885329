import React from 'react';
import { BoxProps } from '@material-ui/core';
import { Styled } from './svg-box.style';

interface SVGBoxProps {
  cursor?: string;

  fillColor?: string;

  height?: number;

  strokeColor?: string;

  width?: number;
}

export const SVGBox: React.FC<BoxProps & SVGBoxProps> = ({
  children,
  cursor,
  fillColor,
  height,
  strokeColor,
  width,
  ...rest
}) => {
  return (
    <Styled.SVGBox
      cursor={cursor}
      fillColor={fillColor}
      height={height}
      strokeColor={strokeColor}
      width={width}
      {...rest}
    >
      {children}
    </Styled.SVGBox>
  );
};
