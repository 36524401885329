export const MANGED_TREASURIES_MINIMUM = 250000;

export enum ProductAllocationProducts {
  ManagedMoneyMarket = 'Managed Money Market',
  ManagedTreasuries = 'Managed Treasuries',
  ManagedIncome = 'Managed Income',
  TreasureCash = 'Treasure Cash',
}

export interface IProductAllocation {
  amount: number;
  editing: boolean;
  rate: number;
  value: number | ''; // https://github.com/s-yadav/react-number-format/issues/500#issuecomment-797342449
  valueRaw: number | ''; // https://github.com/s-yadav/react-number-format/issues/500#issuecomment-797342449
}

export interface IProductAllocations {
  [ProductAllocationProducts.ManagedMoneyMarket]: IProductAllocation;
  [ProductAllocationProducts.ManagedTreasuries]: IProductAllocation;
  [ProductAllocationProducts.ManagedIncome]: IProductAllocation;
  [ProductAllocationProducts.TreasureCash]: IProductAllocation;
}
