import styled from 'styled-components';
import { Box } from '@material-ui/core';

const PlaidLinkBox = styled(Box)`
  cursor: pointer;
`;

export const Styled = {
  PlaidLinkBox,
};
