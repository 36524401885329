import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useDeleteScheduleTransfer = (): UseMutationResult<
  any,
  any,
  any
> => {
  const queryClient = useQueryClient();

  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const deleteScheduleTransfer = async ({ id }: { id: string }) => {
    const response = await treasureApi().post('/transfer/delete', {
      id,
    });

    return response.data;
  };

  return useMutation(deleteScheduleTransfer, {
    onSuccess: () => {
      return queryClient.invalidateQueries([ReactQueryKeys.ScheduleTransfers]);
    },
  });
};
