import React from 'react';
import { Currency, CurrencyVariant } from 'components/core';
import { MANGED_TREASURIES_MINIMUM } from 'modules/2023-q3/allocation/interface/product-allocation';

export const ManagedTreasuriesMinimumMessage = () => {
  return (
    <>
      Managed Treasuries requires{' '}
      <Currency
        number={MANGED_TREASURIES_MINIMUM}
        variant={CurrencyVariant.NearestDollar}
      />{' '}
      minimum. Any allocated funds under{' '}
      <Currency
        number={MANGED_TREASURIES_MINIMUM}
        variant={CurrencyVariant.NearestDollar}
      />{' '}
      will be added to Managed Money Market.
    </>
  );
};
