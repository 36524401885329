import React, { useState } from 'react';
import { createCtx } from 'utils/create-ctx';
import { Modal } from 'components/core';
import { MultiAccountSelection } from 'modals/multi-account';

// hooks
import { useBusinesses } from 'hooks/business/use-businesses';

interface MultipleAccountContext {
  accountSelectionOpen: boolean;

  hasMultipleAccounts: boolean;

  setAccountSelectionOpen: (accountSelectionOpen: boolean) => void;
}

export const [useMultipleAccountContext, MultipleAccountCtxProvider] =
  createCtx<MultipleAccountContext>();

export const MultipleAccountProvider: React.FC = ({ children }) => {
  const { data: businesses } = useBusinesses();

  const [showMultipleAccountSelection, setShowMultipleAccountSelection] =
    useState(false);

  return (
    <MultipleAccountCtxProvider
      value={{
        accountSelectionOpen: showMultipleAccountSelection,
        hasMultipleAccounts: businesses?.length > 1,
        setAccountSelectionOpen: setShowMultipleAccountSelection,
      }}
    >
      {children}

      <Modal
        hideCloseIcon
        open={showMultipleAccountSelection}
        onClose={() => setShowMultipleAccountSelection(false)}
      >
        <MultiAccountSelection />
      </Modal>
    </MultipleAccountCtxProvider>
  );
};
