import React from 'react';
import { Hidden } from '@material-ui/core';

interface MobileViewProps {
  children?: React.ReactNode;

  mobile?: React.ReactNode;
}

export const MobileView: React.FC<MobileViewProps> = ({ children, mobile }) => {
  return (
    <>
      {mobile && <Hidden smUp>{mobile}</Hidden>}

      {children && <Hidden xsDown>{children}</Hidden>}
    </>
  );
};
