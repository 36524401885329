import React from 'react';
import { Size } from 'enums';
import { Styled } from './form-container.style';

interface FormContainerProps {
  size?: Size;
}

export const FormContainer: React.FC<FormContainerProps> = ({
  children,
  size,
}) => {
  return (
    <Styled.FormContainer
      mt={{ xs: 0, sm: 5 }}
      mx="auto"
      p={{ xs: 0, sm: 3 }}
      size={size}
    >
      {children}
    </Styled.FormContainer>
  );
};
