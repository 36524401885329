import React from 'react';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { ReturnDistributionChart } from './components/return-distribution-chart/return-distribution-chart';
import { ReturnDistributionLegend } from './components/return-distribution-legend/return-distribution-legend';

interface ReturnDistributionProps {
  allocations: Allocation;

  product: keyof Allocation;
}

export const ReturnDistribution: React.FC<ReturnDistributionProps> = ({
  allocations,
  product,
}) => {
  return (
    <>
      <ReturnDistributionChart allocations={allocations} product={product} />
      <ReturnDistributionLegend allocations={allocations} product={product} />
    </>
  );
};
