import React, { useState } from 'react';
import { Box, Drawer } from '@material-ui/core';
import { GrowthPromoDetails } from './components/growth-promo-details/growth-promo-details';
import { DepositButton } from './components/growth-promo-details/components/deposit-button/deposit-button';
import { Styled } from 'modules/2023-q3/allocation/pages/form/components/product-allocation/components/more-information/more-information.style';

interface Props {
  handleDrawer: ({
    clickElement,
    clickText,
    drawerDirection,
  }: {
    clickElement?: string | undefined;
    clickText?: string | undefined;
    drawerDirection: boolean;
  }) => void;

  open: boolean;
}

export const GrowthPromoDrawer: React.FC<Props> = ({ handleDrawer, open }) => {
  return (
    <Styled.DrawerContainer>
      <Drawer
        anchor="right"
        BackdropComponent={Styled.Backdrop}
        onClose={() =>
          handleDrawer({ clickElement: 'Backdrop', drawerDirection: false })
        }
        open={open}
        PaperProps={{ elevation: 0 }}
      >
        <Box
          height="100%"
          maxWidth={400}
          minWidth={{ xs: '100vw', md: 400 }}
          overflow="scroll"
        >
          <GrowthPromoDetails handleDrawer={handleDrawer} />
        </Box>
      </Drawer>
    </Styled.DrawerContainer>
  );
};
