import React from 'react';
import { MultiAccountSelection } from 'modals/multi-account';
import { useTracking, TrackPageName } from 'modules/tracking';

export const Businesses: React.FC = () => {
  useTracking<{
    page: TrackPageName;
  }>({
    page: TrackPageName.SettingsBusinesses,
  });

  return <MultiAccountSelection />;
};
