import React from 'react';
import { Box } from '@material-ui/core';
import { TextFieldController } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { maxEmailLength, isValidEmail } from 'utils/forms/validators';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { Typography, TypographyVariant } from 'components/core';

interface IDocumentFormPassword {
  control: any;
  handleSubmit: any;
  isLoading: boolean;
}

export const DocumentFormPassword: React.FC<IDocumentFormPassword> = ({
  control,
  handleSubmit,
  isLoading,
}) => {
  const formFields = {
    password: {
      name: 'password',
      label: 'Password',
      placeholder: 'Enter your password',
      validations: {
        required: true,
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={5}>
        <TextFieldController
          {...formFields.password}
          control={control}
          type="password"
        />
      </Box>

      <Box mb={3}>
        <Button type="submit" disabled={isLoading} fullWidth>
          <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
            <Typography color="white" variant={TypographyVariant.Body}>
              Continue
            </Typography>
          </ButtonContent>
        </Button>
      </Box>
    </form>
  );
};
