import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { AttentionNotification } from './components/attention-notification/attention-notification';
import { SectionContainer, SectionTitle } from 'modules/dashboard/components';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { Link, useHistory } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { useBusiness } from 'hooks/business/use-business';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';

import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import {
  useMutatePortfolio,
  MutatePortfolioRequest,
} from 'modules/2024-q1/allocation-by-amount-or-percentage/pages/submit/hooks/use-mutate-portfolio';
import { AllocationProducts } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';

const redistributeAllocation = ({ portfolio_allocations }: any) => {
  const redistributed_portfolio_allocations = { ...portfolio_allocations };

  redistributed_portfolio_allocations.managed_income = Number(
    redistributed_portfolio_allocations.managed_income,
  );
  redistributed_portfolio_allocations.managed_money_market = Number(
    redistributed_portfolio_allocations.managed_money_market,
  );
  redistributed_portfolio_allocations.managed_treasuries = Number(
    redistributed_portfolio_allocations.managed_treasuries,
  );
  redistributed_portfolio_allocations.treasure_cash = 0;

  const percentage_to_redistribute = Number(
    portfolio_allocations.treasure_cash,
  );

  if (redistributed_portfolio_allocations.managed_income === 0) {
    redistributed_portfolio_allocations.managed_income = 0;
  } else {
    redistributed_portfolio_allocations.managed_income +=
      percentage_to_redistribute *
      (redistributed_portfolio_allocations.managed_income /
        (100 - percentage_to_redistribute));
  }

  if (redistributed_portfolio_allocations.managed_money_market === 0) {
    redistributed_portfolio_allocations.managed_money_market = 0;
  } else {
    redistributed_portfolio_allocations.managed_money_market +=
      percentage_to_redistribute *
      (redistributed_portfolio_allocations.managed_money_market /
        (100 - percentage_to_redistribute));
  }

  if (redistributed_portfolio_allocations.managed_treasuries === 0) {
    redistributed_portfolio_allocations.managed_treasuries = 0;
  } else {
    redistributed_portfolio_allocations.managed_treasuries +=
      percentage_to_redistribute *
      (redistributed_portfolio_allocations.managed_treasuries /
        (100 - percentage_to_redistribute));
  }

  if (
    redistributed_portfolio_allocations.managed_income === 0 &&
    redistributed_portfolio_allocations.managed_money_market === 0 &&
    redistributed_portfolio_allocations.managed_treasuries === 0
  ) {
    redistributed_portfolio_allocations.managed_money_market = 100;
  }

  return redistributed_portfolio_allocations;
};

export const GrasshopperSunsetOptions = () => {
  const history = useHistory();

  const { data: business } = useBusiness();
  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);

  const { data: portfolio, isLoading: isLoadingPortfolio } = usePortfolios(
    business?.Id,
  );
  const { mutate: mutatePortfolio } = useMutatePortfolio();

  const amountAtGrasshopper = aggregateBalances?.liquidity.oneDay.amount;
  const pendingWithdrawals = aggregateBalances?.pending.withdrawals.amount;

  const amountToWithdraw = amountAtGrasshopper - pendingWithdrawals;

  const handleSubmitPortfolioAllocation = () => {
    const portfolio_allocations = {
      managed_income: Number(
        portfolio?.portfolios[0]?.allocations?.find(
          (x: { type: string }) => x.type === 'HIGH_YIELD',
        )?.percentage,
      ),
      managed_money_market: Number(
        portfolio?.portfolios[0]?.allocations?.find(
          (x: { type: string }) => x.type === 'MONEY_MARKET',
        )?.percentage,
      ),
      managed_treasuries: Number(
        portfolio?.portfolios[0]?.allocations?.find(
          (x: { type: string }) => x.type === 'TBILL',
        )?.percentage,
      ),
      treasure_cash: Number(
        portfolio?.portfolios[0]?.allocations?.find(
          (x: { type: string }) => x.type === 'CASH',
        )?.percentage,
      ),
    };

    const redistributedAllocation = redistributeAllocation({
      portfolio_allocations,
    });

    const mutatePortfolioRequest: MutatePortfolioRequest = {
      [AllocationProducts.cash]: {
        amount: 0,
        percentage: 0,
      },
      [AllocationProducts.smart]: {
        amount: 0,
        percentage: redistributedAllocation.managed_income,
      },
      [AllocationProducts.tbills]: {
        amount: 0,
        percentage: redistributedAllocation.managed_treasuries,
      },
      [AllocationProducts.moneyMarket]: {
        amount: 0,
        percentage: redistributedAllocation.managed_money_market,
      },
    };

    mutatePortfolio(
      {
        businessId: business?.id,
        portfolio: mutatePortfolioRequest,
      },
      {
        onError: () => history.push(RoutesPath.tasks.withdrawal.path),
        onSuccess: () => history.push(RoutesPath.tasks.withdrawal.path),
      },
    );
  };

  return (
    <Box pt={8} mb={amountToWithdraw > 0 ? 10 : 0}>
      <Box textAlign="center">
        <Grid container spacing={10} justifyContent="center">
          <Grid item xs sm={8}>
            <AttentionNotification />
          </Grid>
        </Grid>
      </Box>

      {amountToWithdraw > 0 && (
        <Grid container spacing={10} justifyContent="center">
          <Grid item xs sm={5}>
            <SectionContainer>
              <Box mb={2}>
                <SectionTitle title="Allocate" />
              </Box>
              Allocate Treasure Cash funds into our managed products
              <Box mt={3}>
                <Link to={RoutesPath.tasks.allocation.path}>
                  <Button fullWidth>
                    <ButtonContent>
                      Allocate{' '}
                      <Currency
                        number={Math.abs(amountToWithdraw)}
                        variant={CurrencyVariant.Full}
                      />
                    </ButtonContent>
                  </Button>
                </Link>
              </Box>
            </SectionContainer>
          </Grid>

          <Grid item xs sm={5}>
            <SectionContainer>
              <Box mb={2}>
                <SectionTitle title="Withdraw" />
              </Box>
              Withdraw Treasure Cash funds to your bank account
              <Box mt={3}>
                <Button onClick={handleSubmitPortfolioAllocation} fullWidth>
                  <ButtonContent>
                    Withdraw{' '}
                    <Currency
                      number={Math.abs(amountToWithdraw)}
                      variant={CurrencyVariant.Full}
                    />
                  </ButtonContent>
                </Button>

                <Box mt={1}>
                  <Typography variant={TypographyVariant.SmallText}>
                    Any residual funds added after this withdrawal, will be sent
                    to the last bank account of the last withdrawl.
                  </Typography>
                </Box>
              </Box>
            </SectionContainer>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
