import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const IdleCashIndicator = styled(Grid)`
  cursor: pointer;
  position: relative;
  border: 1px solid var(--color-gainsboro0);
  box-shadow: var(--box-shadow-04);
  border-radius: 0 200px 200px 0;
`;

const TopIndicatorsContainer = styled(Grid)`
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-02);
`;

const TopIndicatorItem = styled(Grid)`
  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;
  `}
  position: relative;
  border-right: 1px solid var(--color-gainsboro0);

  &:last-child {
    border-right: 0 none;
  }

  white-space: nowrap; // safari fix
`;

export const Styled = {
  IdleCashIndicator,
  TopIndicatorsContainer,
  TopIndicatorItem,
};
