import styled from 'styled-components';
import { OutlinedInput } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';

const IconClear = styled(HighlightOff)`
  color: var(--color-smoky);
`;

const SearchInput = styled(OutlinedInput)`
  border-radius: 30px;
`;

export const Styled = {
  IconClear,
  SearchInput,
};
