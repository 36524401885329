import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

const Accordion = styled(MuiAccordion)`
  position: initial; // removes border

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  display: inherit;
  padding: 0;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: auto;
  padding: 0;
  user-select: auto;

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: inherit;
  }

  & .MuiAccordionSummary-content {
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

export const Styled = { Accordion, AccordionDetails, AccordionSummary };
