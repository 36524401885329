import React from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import { useStoreAuthentication } from 'stores';
import { GlobalServiceIssueNotification } from 'modules/page-errors/global-service-issue-notification/global-service-issue-notification';
import {
  DropdownMenu,
  Navigation,
  SweepButton,
  TreasureLogo,
} from './components';
import { RequestDemoButton } from './components/request-demo-button/request-demo-button';
import { SignUpButton } from './components/sign-up-button/sign-up-button';
import { Styled } from './header.style';

export const Header = () => {
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);

  const displaySignedInHeader = () => {
    return (
      <>
        <Grid item xs>
          <Box px={2}>
            <TreasureLogo />
          </Box>
        </Grid>

        <Hidden mdDown>
          <Navigation />
        </Hidden>

        <Grid container item justifyContent="flex-end" alignItems="center" xs>
          <SweepButton />
          <DropdownMenu />
        </Grid>
      </>
    );
  };

  const displaySignedOutHeader = () => {
    return (
      <>
        <Grid item xs>
          <Box px={2}>
            <TreasureLogo />
          </Box>
        </Grid>

        <Grid container item justifyContent="flex-end" alignItems="center" xs>
          <RequestDemoButton />
          <SignUpButton />
        </Grid>
      </>
    );
  };

  return (
    <>
      <GlobalServiceIssueNotification />

      <Styled.Container
        alignItems="center"
        container
        justifyContent="space-between"
      >
        {isSignedIn ? displaySignedInHeader() : displaySignedOutHeader()}
      </Styled.Container>
    </>
  );
};
