import styled from 'styled-components';
import { breakpoints } from 'styles/css-constants';
import CheckMark from 'assets/icons/check-mark.svg';

const BulletsContainer = styled.div`
  color: var(--color-darkGrey);
  display: flex;
  justify-content: center;
  margin: 40px auto 0 auto;
  max-height: 440px;
  padding: 0 40px;
  width: 100%;
  li {
    color: var(--color-black);
    margin-bottom: 30px;
    svg {
      margin-right: 15px;
    }
  }
  .header {
    font-size: 20px;
    margin-bottom: 10px;
  }
  ${breakpoints.up('lg')} {
    height: 100%;
    margin-top: 0;
    padding: 0 40px;

    position: absolute;
    left: -461px;
    top: 0;
  }
`;

const BulletsFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const Bullets = styled.ul`
  text-align: left;
  padding: 0 20px;
  margin-top: 10px;
  li {
    list-style-image: url(${CheckMark});
    padding-left: 10px;
    svg {
      display: inline-block;
      vertical-align: top;
    }
    p {
      display: inline-block;
      margin: 0;
    }
  }
`;

export const Styled = {
  BulletsContainer,
  Bullets,
  BulletsFlexWrapper,
};
