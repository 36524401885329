import React from 'react';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { Styled } from './container.style';

export const Container: React.FC = ({ children }) => {
  return (
    <MobileView mobile={children}>
      <Styled.RoundedContainer>{children}</Styled.RoundedContainer>
    </MobileView>
  );
};
