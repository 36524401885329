import { ChartHeight } from 'components/charts/v3/common/charts.common.style';
import { getColor } from 'styles/css-constants';

const PreviousMonthLineStrokeInflows = getColor('royalBlue');

const PreviousMonthLineStrokeOutflows = getColor('darkPurple');

const ReferenceLineStroke = getColor('gainsboro0');

const ReferenceTextStroke = getColor('gothic');

const ThisMonthLineStroke = getColor('stromboli');

const ThisYearLineStroke = getColor('silverChalice');

export const Styled = {
  ChartHeight,
  PreviousMonthLineStrokeInflows,
  PreviousMonthLineStrokeOutflows,
  ReferenceLineStroke,
  ReferenceTextStroke,
  ThisMonthLineStroke,
  ThisYearLineStroke,
};
