import styled from 'styled-components';
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';

const IconAlert = styled(AlertIcon)`
  & path:first-child {
    fill: rgb(51, 84, 67); // stromboli
  }
`;

export const Styled = {
  IconAlert,
};
