import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { SectionContainer } from 'modules/dashboard/components';
import { BankAccount as BankAccountType } from 'modules/2023-q3/banking-page/v2/types/bank-account';
import { BankAccountTransactions } from 'modules/2023-q3/banking-page/v2/components/bank-accounts/components/bank/components/bank-account-transactions/bank-account-transactions';

interface BankAccountProps {
  bankAccount: BankAccountType;
}

export const BankAccount: React.FC<BankAccountProps> = ({ bankAccount }) => {
  return (
    <SectionContainer>
      <Box mb={3}>
        <Typography
          color="black19"
          variant={TypographyVariant.Header4}
        >{`${bankAccount.Name} (...${bankAccount.Mask})`}</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm>
          <Typography variant={TypographyVariant.Paragraph2}>
            Account Type
          </Typography>

          <Box>
            <Typography
              capitalize
              color="grey1"
              variant={TypographyVariant.Paragraph1}
            >
              {bankAccount.Type}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm>
          <Typography variant={TypographyVariant.Paragraph2}>
            Balance
          </Typography>

          <Typography variant={TypographyVariant.Header4}>
            <Currency
              number={+bankAccount.Balance}
              variant={CurrencyVariant.Full}
            />
          </Typography>
        </Grid>

        <Grid item xs={12} sm>
          <Typography variant={TypographyVariant.Paragraph2}>
            Available Balance
          </Typography>

          <Typography variant={TypographyVariant.Header4}>
            <Currency
              number={+bankAccount.Available}
              variant={CurrencyVariant.Full}
            />
          </Typography>
        </Grid>
      </Grid>

      <BankAccountTransactions bankAccount={bankAccount} />
    </SectionContainer>
  );
};
