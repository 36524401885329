import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { colors, getColor } from 'styles/css-constants';

const Bullet = styled(Box)`
  background-color: ${(props) => {
    if (props.color) return getColor(props.color as keyof typeof colors);

    return css`var(--color-brown6)`;
  }};
  border-radius: 66px;
  height: 24px;
  width: 24px;
  line-height: 24px;
`;

export const Styled = {
  Bullet,
};
