import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, SelectProps as MuiSelectProps } from '@material-ui/core';
import { Text } from 'components/core';
import { FormControl } from '../common/form-control';
import { Styled } from './select.style';

interface SelectOption {
  label: string;
  value: string;
}

export interface SelectProps extends MuiSelectProps {
  helperText?: string;

  options: SelectOption[];

  optional?: boolean;
}

const Select: React.FC<SelectProps> = ({
  error,
  helperText,
  label,
  options,
  placeholder,
  optional,
  ...rest
}) => {
  return (
    <FormControl
      error={error}
      helperText={helperText}
      label={label}
      optional={optional}
    >
      <Styled.Select
        error={error}
        displayEmpty
        IconComponent={Styled.Arrow}
        {...rest}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            <Text color="lightGrey" variant={1}>
              {placeholder}
            </Text>
          </MenuItem>
        )}

        {options.map(function (option) {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Styled.Select>
    </FormControl>
  );
};

Select.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  placeholder: PropTypes.string,
};

export { Select };
