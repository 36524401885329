import React from 'react';
import PropTypes from 'prop-types';
import { Styled } from './text.style';

export interface TextProps {
  /** content of the text */
  children: React.ReactNode;

  /** used by styled-compoents to extend styling */
  className?: string;

  /** color of the text */
  color?: string;

  /** level (font size and weight) of the text */
  variant: number;
}

const Text: React.FC<TextProps> = ({ children, className, color, variant }) => {
  return (
    <Styled.Typography
      className={className}
      styledColor={color}
      level={variant}
    >
      {children}
    </Styled.Typography>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.number.isRequired,
};

export { Text };
