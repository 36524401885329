export const isDevOrStagingEnv = ['development', 'local', 'staging'].includes(
  window._env.NODE_ENV,
);

const demoPath = '/demo';
const settingsPath = '/settings';
const whiteLabelPartnerPath = '/partner';

export const RoutesPath = {
  noAuth: {
    forgotPassword: { path: '/forgot-password' },
    signIn: { path: '/sign-in' },
    signInAccept: { path: '/join-team/:token' },
    resetPassword: { path: '/reset-password/:token' },
    signUpWithAffCode: { path: '/sign-up/:affiliateCode' },
    signUp: { path: '/sign-up' },
    demo: {
      portfolioAllocation: { path: `${demoPath}/portfolio/allocation` },
      portfolioAllocationComparison: {
        path: `${demoPath}/portfolio/allocation/compare`,
      },
    },
    documents: { path: '/documents' },
  },
  notFound: {
    path: '/404',
  },
  onboarding: {
    accountIntakeToken: {
      path: '/account-intake/:token',
    },
    accountIntake: {
      path: '/account-intake',
    },
    ghbAgreement: {
      path: '/updated-terms',
    },
  },
  pages: {
    banking: {
      path: '/banking',
    },
    connectBankAccountManual: {
      path: '/connect-accounts/ach',
    },
    connectBankAccounts: {
      path: '/connect-accounts',
    },
    dashboard: {
      path: '/',
    },
    dashboardTreasureReserve: {
      path: '/treasure-reserve',
    },
    documents: {
      path: '/custodial-documents',
    },
    home: {
      path: '/',
    },
    insights: {
      path: '/insights',
    },
    onboardingComplete: {
      // added for SEO
      path: '/welcome',
    },
    settings: {
      path: settingsPath,
      pages: {
        businesses: {
          path: `${settingsPath}/businesses`, // TODO: this should throw a 404 based on multipleAccountContext.hasMultipleAccounts
        },
        primaryDepositAccount: {
          path: `${settingsPath}/primary-deposit-account`, // TODO: this should throw a 404 based on custodian
        },
        team: {
          path: `${settingsPath}/team`,
        },
        teamInvite: {
          path: `${settingsPath}/team/invite`,
        },
        tradeRestrictions: {
          path: `${settingsPath}/trade-restrictions`,
        },
        updatePassword: {
          path: `${settingsPath}/update-password`,
        },
        quickbooks: {
          path: `${settingsPath}/quickbooks`,
        },
      },
    },
    transfer: {
      path: '/transfer',
    },
  },
  tasks: {
    allocation: {
      path: '/allocation',
    },
    deposit: {
      path: '/deposit',
    },
    loginMFA: {
      path: '/security-check',
    },
    withdrawal: {
      path: '/withdrawal',
    },
  },
  whiteLabel: {
    landing: {
      path: `${whiteLabelPartnerPath}/:partnerId`,
    },
    signUp: {
      path: `${whiteLabelPartnerPath}/:partnerId/sign-up`,
    },
  },
};

export const getVersionedPath = (params: {
  path: string;
  version?: number;
}) => {
  switch (params.version) {
    default:
      return params.path;
  }
};
