import React from 'react';
import CheckMark from 'assets/forms/checkbox/checkmark.svg';

import { Styled } from './tick.style';

interface TickProps {
  disabled?: boolean;
  error?: boolean;
}

const Tick: React.FC<TickProps> = (props: TickProps) => {
  return (
    <Styled.TickContainer {...props}>
      <Styled.TickIcon alt="tick icon" src={CheckMark} />
    </Styled.TickContainer>
  );
};

export default Tick;
