import React, { useEffect, useState } from 'react';
import {
  i18nNamespace,
  DepositWithdrawalSteps,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { useLocation } from 'react-router-dom';
import { Header } from 'modules/2023-q3/deposit-withdrawal/components/header/header';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import {
  Confirmation,
  FailedRetry,
  Form,
  Processing,
  Review,
} from 'modules/2023-q3/deposit-withdrawal/pages';
import { MFA } from 'modules/2023-q3/components/mfa/mfa';
import { BankAccount } from 'types';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import { useBusiness } from 'hooks/business/use-business';
import { useSendSweepMFACode } from 'modules/2023-q3/deposit-withdrawal/hooks/use-send-sweep-mfa-code';
import { useSweepWithdrawal } from './hooks/use-sweep-withdrawal';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmNavigateAway } from 'modules/2023-q3/components/confirm-navigate-away/confirm-navigate-away';
import { ConfirmationModal } from 'modules/2023-q3/deposit-withdrawal/components/confirmation-modal/confirmation-modal';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Redirect } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { Box } from '@material-ui/core';
import { LiquidityBreakdown } from 'modules/2023-q3/deposit-withdrawal/withdrawal/components/liquidity-breakdown/liquidity-breakdown';
import { useTracking, TrackEventName } from 'modules/tracking';

export const Withdrawal: React.FC = () => {
  const { state }: { state: any } = useLocation();
  const from = state?.from;

  const { t } = useTranslation(i18nNamespace);

  const { data: business } = useBusiness();
  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);
  const sendSweepMFA = useSendSweepMFACode();
  const sweepWithdrawal = useSweepWithdrawal();

  const [amount, setAmount] = useState<number | undefined>();
  const [bank, setBank] = useState<BankAccount | undefined>();
  const [step, setStep] = useState(DepositWithdrawalSteps.Form);

  const { Track, trackEvent } = useTracking<{
    eventName?: TrackEventName;
    step: string;
  }>({
    step: DepositWithdrawalSteps[step],
  });

  const isOver1DayLiquidity =
    typeof amount !== 'undefined' &&
    typeof aggregateBalances !== 'undefined' &&
    amount > aggregateBalances?.liquidity.oneDay.amount;
  const isOverMaxAmount =
    typeof amount !== 'undefined' &&
    typeof aggregateBalances !== 'undefined' &&
    amount > aggregateBalances?.liquidity.oneDay.amount;

  const handleMFASuccess = async (token?: string) => {
    setStep(DepositWithdrawalSteps.Processing);

    try {
      await sweepWithdrawal.mutate(
        {
          amount: amount,
          authToken: token, // TODO: PortalGateway doesn't support token swap
          bankAccountId: bank?.Id,
          businessId: business?.Id,
          idempotencyKey: uuidv4(),
        },
        {
          onSuccess: () => {
            setStep(DepositWithdrawalSteps.Confirmation);
          },
        },
      );
    } catch (e: any) {
      // mutateAsync returns a promise and without this try/catch,
      // it's unhandled and triggers our error boundary.
      // TODO: is there a better way to handle this?
      setStep(DepositWithdrawalSteps.FailedRetry);
    }
  };

  const nextStep = () => {
    switch (step) {
      case DepositWithdrawalSteps.Form:
        return setStep(DepositWithdrawalSteps.Review);
      case DepositWithdrawalSteps.Review:
        return setStep(DepositWithdrawalSteps.MFA);
      case DepositWithdrawalSteps.Confirmation:
        setAmount(undefined); // reset the amount for the next withdrawal
        return setStep(DepositWithdrawalSteps.Form);
      default:
        return null;
    }
  };

  const previousStep = () => {
    switch (step) {
      case DepositWithdrawalSteps.Form:
        // TODO: logic to find previous step
        return setStep(DepositWithdrawalSteps.Form);
      case DepositWithdrawalSteps.Review:
        return setStep(DepositWithdrawalSteps.Form);
      case DepositWithdrawalSteps.MFA:
        return setStep(DepositWithdrawalSteps.Review);
      default:
        return null;
    }
  };

  const displayStep = (step: DepositWithdrawalSteps) => {
    switch (step) {
      case DepositWithdrawalSteps.Review:
        return (
          <Review
            amount={amount}
            bank={bank}
            nextStep={nextStep}
            previousStep={previousStep}
            variant={DepositWithdrawalVariant.Withdrawal}
          />
        );
      case DepositWithdrawalSteps.MFA:
        return (
          <MFA
            customSendMFA={{
              mutationResult: sendSweepMFA,
              variables: {
                transferType: DepositWithdrawalVariant.Withdrawal,
              },
            }}
            onMFASuccess={handleMFASuccess}
            onPrevious={previousStep}
          />
        );
      case DepositWithdrawalSteps.Processing:
        return <Processing variant={DepositWithdrawalVariant.Withdrawal} />;
      case DepositWithdrawalSteps.Confirmation:
        return (
          <Confirmation
            from={from}
            nextStep={nextStep}
            variant={DepositWithdrawalVariant.Withdrawal}
          />
        );
      case DepositWithdrawalSteps.FailedRetry:
        return (
          <FailedRetry
            onRetry={handleMFASuccess}
            variant={DepositWithdrawalVariant.Withdrawal}
          />
        );
      default:
        return (
          <>
            <Box mb={{ xs: 5, sm: 0 }}>
              <LiquidityBreakdown />
            </Box>

            <Form
              amount={amount}
              bank={bank}
              isOverMaxAmount={isOverMaxAmount}
              overMaxAmountMessage={t(
                'The amount entered exceeds your 1 day liquidity balance',
              )}
              nextStep={nextStep}
              setAmount={setAmount}
              setBank={setBank}
              variant={DepositWithdrawalVariant.Withdrawal}
            />
          </>
        );
    }
  };

  useEffect(() => {
    trackEvent({
      eventName: TrackEventName.Viewed,
      step: DepositWithdrawalSteps[step],
    });
  }, [step]);

  if (isLoadingAggregateBalances) {
    return null;
  }

  return (
    <FeatureFlag
      disabled={<Redirect to={RoutesPath.pages.home.path} />}
      enabled={
        <Track>
          <ConfirmNavigateAway
            when={
              amount !== undefined &&
              step !== DepositWithdrawalSteps.Confirmation
            }
            modal={
              <ConfirmationModal
                variant={DepositWithdrawalVariant.Withdrawal}
              />
            }
          />

          <Header
            from={from}
            previousStep={previousStep}
            step={step}
            variant={DepositWithdrawalVariant.Withdrawal}
          />

          <CenteredContainer>{displayStep(step)}</CenteredContainer>
        </Track>
      }
      flag={FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED}
    />
  );
};
