import React, { useRef, useState } from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import NumberFormat from 'react-number-format';
import {
  Box,
  Grid,
  TextField,
  StandardTextFieldProps,
} from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconCheckmarkCircleGreen } from 'assets/icons/checkmarks/checkmark-circle-green.svg';
import {
  IProductAllocation,
  IProductAllocations,
  ProductAllocationProducts,
} from 'modules/2024-q1/allocation-by-amount-or-percentage/interface/product-allocation';
import { useClickAway } from 'modules/dashboard/header/components/header-buttons/components/menu-options/hooks/use-click-away';
import { EditProductAllocation } from './components/edit-product-allocation/edit-product-allocation';
import { MoreInformation } from './components/more-information/more-information';
import { Styled } from './product-allocation.style';

interface ProductAllocationProps {
  product: ProductAllocationProducts;
  productAllocation: IProductAllocation;
  setAllocation: React.Dispatch<React.SetStateAction<IProductAllocations>>;
  setShowEditPercentage: React.Dispatch<React.SetStateAction<boolean>>;
  showEditPercentage: boolean;
  totalAmount: number;
}

export const ProductAllocation: React.FC<ProductAllocationProps> = ({
  product,
  productAllocation,
  setAllocation,
  setShowEditPercentage,
  showEditPercentage,
  totalAmount,
}) => {
  const noAUM = totalAmount === 0;

  const productAmount = productAllocation.value
    ? totalAmount * (productAllocation.value / 100)
    : 0;
  const productAmountRaw = productAllocation.valueRaw
    ? totalAmount * (productAllocation.valueRaw / 100)
    : 0;

  const { trackEvent, Track } = useTracking<{
    amount?: number;
    clickElement?: string;
    component: string;
    eventName: TrackEventName;
    product: ProductAllocationProducts;
  }>({
    component: 'Product Allocation',
    product,
  });

  const ref = useRef<HTMLInputElement>(null);

  // remove product
  // it is recommended to set value to '' instead of null or undefined
  // to clear it out
  // https://github.com/s-yadav/react-number-format/issues/500#issuecomment-797342449
  // however, setting to '' will show nothing while we want to display 0.00%
  const productRemoved =
    productAllocation.value === '' || productAllocation.value === 0;

  // the slider is using useClickAway, however, if the user clicks on the product input,
  // we want the slider to stay in editing mode
  const [textFieldEditing, setTextFieldEditing] = useState(false);

  const setProductEditing = ({
    editing,
    product,
  }: {
    editing: boolean;
    product: ProductAllocationProducts;
  }) => {
    setAllocation((previousState) => ({
      ...previousState,
      [product]: { ...previousState?.[product], editing },
    }));
  };

  const setProductValue = ({
    amount,
    product,
    value,
    valueRaw,
  }: {
    amount: number;
    product: ProductAllocationProducts;
    value: number | '';
    valueRaw: number | '';
  }) => {
    setAllocation((previousState) => ({
      ...previousState,
      [product]: { ...previousState?.[product], value, valueRaw, amount },
    }));
  };

  // when the checkbox icon is clicked, it removes the product
  // which means the value is set to 0.
  // if the icon is clicked again (plus sign), it remains 0,
  // however, we focus in the checkbox, which triggers handleInputFocus
  // which then sets the input to '' to clear it out
  const handleIconClick = () => {
    setProductValue({ amount: 0, product, value: 0, valueRaw: 0 });

    if (productRemoved) {
      ref.current?.setSelectionRange(0, 0);
      ref.current?.focus();
    }

    trackEvent({
      clickElement: productRemoved ? 'Add Icon' : 'Checkmark',
      eventName: TrackEventName.Click,
    });
  };

  const handleInputBlur = () => {
    setTextFieldEditing(false);

    // if the user didn't input anything, or cleared out everything
    // we set the value to 0
    if (productAllocation.value === '') {
      setProductValue({ amount: 0, product, value: 0, valueRaw: 0 });
    }
  };

  // adding useClickAway on the text input for chrome on ios issue
  useClickAway(ref, () => {
    handleInputBlur();
  });

  const handleInputFocus = () => {
    setAllocation((previousState) => ({
      ...previousState,
      [ProductAllocationProducts.ManagedMoneyMarket]: {
        ...previousState?.[ProductAllocationProducts.ManagedMoneyMarket],
        editing: false,
      },
      [ProductAllocationProducts.ManagedTreasuries]: {
        ...previousState?.[ProductAllocationProducts.ManagedTreasuries],
        editing: false,
      },
      [ProductAllocationProducts.ManagedIncome]: {
        ...previousState?.[ProductAllocationProducts.ManagedIncome],
        editing: false,
      },
      [ProductAllocationProducts.TreasureCash]: {
        ...previousState?.[ProductAllocationProducts.TreasureCash],
        editing: false,
      },
    }));

    // if the value is 0, the user is going to edit the input,
    // we need to clear out 0.00% so when they start typing, it
    // starts a new number (x) rather rather than appends (x0.00%)
    if (productAllocation.value === 0) {
      setProductValue({ amount: 0, product, value: '', valueRaw: '' });
    }

    setProductEditing({ product, editing: true });
    setTextFieldEditing(true);
  };

  const editPercentage = (
    <>
      <Box mb={1.5}>
        <Styled.PercentageInputContainer>
          <NumberFormat
            allowNegative={false}
            customInput={TextField}
            decimalScale={2}
            fixedDecimalScale
            inputRef={ref}
            inputProps={{ inputMode: 'decimal' }}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            onValueChange={(values, sourceInfo) => {
              const isEvent = sourceInfo.source === 'event';

              setProductValue({
                amount: Number(
                  (totalAmount * (Number(values.value) / 100)).toFixed(2),
                ),
                product,
                value: values.value === '' ? '' : Number(values.value),
                valueRaw: values.value === '' ? '' : Number(values.value),
              });

              // isEvent is true when the user changes the input
              if (isEvent) {
                trackEvent({
                  amount: Number(values.value),
                  eventName: TrackEventName.Input,
                });
              }
            }}
            suffix="%"
            value={productAllocation.value}
          />
        </Styled.PercentageInputContainer>
      </Box>

      {!noAUM && (
        <Box textAlign="center">
          <Typography color="grey3b" variant={TypographyVariant.Paragraph2}>
            <Currency number={productAmount} variant={CurrencyVariant.Full} />
          </Typography>
        </Box>
      )}
    </>
  );

  const editAmount = (
    <>
      <Box mb={1.5}>
        <Styled.PercentageInputContainer>
          <NumberFormat
            allowNegative={false}
            customInput={TextField}
            decimalScale={2}
            fixedDecimalScale
            inputRef={ref}
            inputProps={{ inputMode: 'decimal' }}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            onValueChange={(values, sourceInfo) => {
              const isEvent = sourceInfo.source === 'event';

              const percentageRaw = Number(
                (Number(values.value) / totalAmount) * 100,
              );

              const percentage = Number(
                ((Number(values.value) / totalAmount) * 100).toFixed(2),
              );

              const showMinimum =
                typeof values?.floatValue !== 'undefined' &&
                values?.floatValue > 0 &&
                percentage === 0;

              setProductValue({
                amount: Number(values.value),
                product,
                value:
                  values.value === '' ? '' : showMinimum ? 0.01 : percentage,
                valueRaw: percentageRaw,
              });

              // isEvent is true when the user changes the input
              if (isEvent) {
                trackEvent({
                  amount: Number(values.value),
                  eventName: TrackEventName.Input,
                });
              }
            }}
            prefix="$"
            thousandSeparator=","
            value={productAmountRaw}
          />
        </Styled.PercentageInputContainer>
      </Box>

      <Box textAlign="center">
        <Typography color="grey3b" variant={TypographyVariant.Paragraph2}>
          <Percentage
            decimalScale={Number(productAllocation.value) > 0 ? 2 : 0}
            number={Number(productAllocation.value)}
          />
        </Typography>
      </Box>
    </>
  );

  return (
    <>
      <Box mb={9}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Typography color="primary" variant={TypographyVariant.Paragraph2}>
              {product}
            </Typography>
          </Grid>

          <Grid item>
            <SVGBox
              cursor="pointer"
              onClick={handleIconClick}
              width={32}
              height={32}
            >
              {productRemoved ? (
                <Styled.IconAdd />
              ) : (
                <Styled.IconCheckmarkWrapper>
                  <IconCheckmarkCircleGreen />
                </Styled.IconCheckmarkWrapper>
              )}
            </SVGBox>
          </Grid>
        </Grid>
      </Box>

      {!showEditPercentage && !noAUM ? editAmount : editPercentage}

      <Box mt={7}>
        {textFieldEditing || productAllocation.editing ? (
          <Track>
            <EditProductAllocation
              product={product}
              setProductEditing={setProductEditing}
              setProductValue={setProductValue}
              totalAmount={totalAmount}
              value={productAllocation.value}
            />
          </Track>
        ) : (
          <MoreInformation product={product} rate={productAllocation.rate} />
        )}
      </Box>
    </>
  );
};
