import styled from 'styled-components';
import { Box } from '@material-ui/core';

const LineHeight = styled(Box)`
  line-height: 0;
`;

export const Styled = {
  LineHeight,
};
