import { TreasureIcon } from 'components/core/spinner/treasure-icon';
import styled, { css, keyframes } from 'styled-components';
import { SpinnerProps } from './spinner';

interface FullScreenContainerProps {
  bgColor: string;
}

interface SpinProps {
  height?: number;
}

export type SprinWrapperProps = SpinnerProps;

const fadeInFadeOutAnimation = keyframes`
 0% {
    opacity: 0;
  }

  50% {
    opacity: .8;
  }

  100% {
    opacity: 0;

  }
`;

const FullScreenContainer = styled.div<FullScreenContainerProps>`
  background-color: ${(props) => {
    if (props.bgColor) {
      return css`var(--color-${props.bgColor})`;
    }
    return 'var(--color-whiteSmoke3)';
  }};
  width: 100%;
  height: 100%;
`;

const Spin = styled(TreasureIcon)<SpinProps>`
  animation: ${fadeInFadeOutAnimation} 2s 0s linear infinite;
  width: 20%;
  height: 20%;

  max-width: 100px;
  max-height: 100px;

  ${(props) => {
    if (props.height) {
      return css`
        height: ${props.height}px;
      `;
    }

    return null;
  }};
`;

const SpinWrapper = styled.div<SprinWrapperProps>`
  height: 100%;

  ${(props) => {
    if (props.inline) {
      return css`
        display: inline-block;
      `;
    }
    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      align-self: center;
    `;
  }}
`;

export const Styled = {
  FullScreenContainer,
  Spin,
  SpinWrapper,
};
