import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { FormFieldContainer } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { TextField } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/text-field';
import { AllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';
import { useTracking, TrackPageSection } from 'modules/tracking';
import { Redirect, useLocation } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import {
  Allocation,
  initialAllocation,
  treasureReserveProductKey,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import {
  calculateComparisonChartData,
  calculateNetFeeRate,
} from './utils/calculate-comparison-chart-data';
import { ComparisonChart } from './components/comparison-chart/comparison-chart';

export const AllocationDemoComparison: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { Track } = useTracking<{
    section: TrackPageSection;
  }>({
    section: TrackPageSection.DemoPortfolioAllocationComparison,
  });

  const { search } = useLocation();
  const searchParamsAmount = Number(new URLSearchParams(search).get('amount'));
  const searchParamsCurrentRate = Number(
    new URLSearchParams(search).get('currentRate'),
  );

  const dflCurrRate = searchParamsCurrentRate || 2.5;
  const defaultAmount = searchParamsAmount || 10000000;
  const maxAmount = 10000000000;

  const [allocation, setAllocation] = useState<Allocation>(initialAllocation);
  const [amount, setAmount] = useState<number | undefined>(defaultAmount);
  const [currRate, setCurrRate] = useState<number | undefined>(dflCurrRate); // this line was annoying me in prettier hene the abbreviated variables
  const [currFee, setCurrFee] = useState<number>(
    isFeatureFlagEnabled(
      FeatureFlags.REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED,
    )
      ? 0.35
      : 0,
  );
  const [treasureRate, setTreasureRate] = useState<number>(
    initialAllocation[treasureReserveProductKey].return,
  );
  const portfolioAllocationDemoEnabled = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_ALLOCATION_DEMO_ENABLED,
  );

  const handleAllocationModalChange = (allocationChange: Allocation) => {
    setTreasureRate(allocationChange[treasureReserveProductKey].return);

    setAllocation(allocationChange);
  };

  const chartData = calculateComparisonChartData({
    amount: amount || 0,
    currentRate: calculateNetFeeRate(allocation, currFee, currRate),
    treasureNetRate: calculateNetFeeRate(allocation),
  });

  const amountInput = (
    <FormFieldContainer>
      <NumberFormat
        allowNegative={false}
        customInput={TextField}
        decimalScale={2}
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue ? floatValue < maxAmount : true;
        }}
        label="Amount"
        prefix="$ "
        onValueChange={(data) => {
          setAmount(data.floatValue || 0);
        }}
        thousandSeparator
        value={amount}
      />
    </FormFieldContainer>
  );

  const treasureRateDisplay = (
    <>
      <Box mb={0.75}>
        <Typography color="nero" variant={TypographyVariant.LargerLabel}>
          Treasure Rate
        </Typography>
      </Box>
      <Typography color="stromboli" variant={TypographyVariant.HeaderExtraBold}>
        <Percentage number={treasureRate} decimalScale={2} />
      </Typography>
    </>
  );

  if (!portfolioAllocationDemoEnabled) {
    return <Redirect to={RoutesPath.pages.home.path} />;
  }

  return (
    <Track>
      <CenteredContainer>
        <Box mt={5}>
          <Box mb={5}>
            <Grid container>
              <Grid item xs>
                <Box mb={0.5}>
                  <Typography
                    color="nero"
                    variant={TypographyVariant.LargerLabel}
                  >
                    12M Expected Total AUM with Treasure
                  </Typography>
                  <Typography
                    color="stromboli"
                    variant={TypographyVariant.HeaderExtraBold}
                  >
                    <Currency
                      number={chartData[11].Treasure}
                      variant={CurrencyVariant.Full}
                    />
                  </Typography>
                </Box>
                <Box mb={0.5}>
                  <Typography
                    color="nero"
                    variant={TypographyVariant.LargerLabel}
                  >
                    12M Expected Total AUM with Current
                    <br />
                    Financial Institution
                  </Typography>

                  <Typography
                    color="silverChalice"
                    variant={TypographyVariant.HeaderRegular}
                  >
                    <Currency
                      number={chartData[11].Current}
                      variant={CurrencyVariant.Full}
                    />
                  </Typography>
                </Box>
                <Box mb={1.5}>
                  <Typography
                    color="nero"
                    variant={TypographyVariant.LargerLabel}
                  >
                    Projected Additional Returns
                  </Typography>

                  <Typography color="black" variant={TypographyVariant.Header}>
                    <Currency
                      number={Math.abs(
                        chartData[11].Treasure - chartData[11].Current,
                      )}
                      variant={CurrencyVariant.Full}
                    />
                  </Typography>
                </Box>
                <FeatureFlag
                  disabled={amountInput}
                  enabled={
                    <Grid container spacing={4}>
                      <Grid item xs>
                        {amountInput}
                      </Grid>
                      <Grid item xs>
                        {treasureRateDisplay}
                      </Grid>
                    </Grid>
                  }
                  flag={
                    FeatureFlags.REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED
                  }
                />
                <Grid container spacing={4}>
                  <Grid item xs>
                    <FormFieldContainer>
                      <NumberFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalScale={3}
                        isAllowed={(values) => {
                          const { floatValue } = values;
                          return floatValue ? floatValue < maxAmount : true;
                        }}
                        label="Your Current Rate"
                        suffix="%"
                        onValueChange={(data) => {
                          setCurrRate(data.floatValue || 0);
                        }}
                        defaultValue={dflCurrRate}
                      />
                    </FormFieldContainer>
                  </Grid>
                  <FeatureFlag
                    disabled={
                      <Grid item xs>
                        {treasureRateDisplay}
                      </Grid>
                    }
                    enabled={
                      <Grid item xs>
                        <FormFieldContainer>
                          <NumberFormat
                            allowNegative={false}
                            customInput={TextField}
                            decimalScale={4}
                            isAllowed={(values) => {
                              const { floatValue } = values;
                              return floatValue ? floatValue < 10 : true;
                            }}
                            label="Your Current Fee"
                            suffix="%"
                            onValueChange={(data) => {
                              setCurrFee(data.floatValue || 0);
                            }}
                            defaultValue={currFee}
                          />
                        </FormFieldContainer>
                      </Grid>
                    }
                    flag={
                      FeatureFlags.REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED
                    }
                  />
                </Grid>
              </Grid>

              {amount !== undefined &&
              currRate !== undefined &&
              treasureRate !== undefined ? (
                <Grid item>
                  <ComparisonChart data={chartData} />
                </Grid>
              ) : null}
            </Grid>
          </Box>

          <Box bgcolor="white">
            <AllocationModal
              aum={amount || 0}
              isDemo
              onChange={handleAllocationModalChange}
            />
          </Box>
        </Box>
      </CenteredContainer>
    </Track>
  );
};
