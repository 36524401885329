import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden } from '@material-ui/core';

export interface GridCardContainerProps {
  /** content of the Grid Card Container */
  children: React.ReactNode;
}

const GridCardContainer: React.FC<GridCardContainerProps> = ({ children }) => {
  return (
    <>
      <Hidden xsDown>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid container>{children}</Grid>
      </Hidden>
    </>
  );
};

GridCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export { GridCardContainer };
