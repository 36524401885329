import styled from 'styled-components';
import {
  FormControlLabel as MuiFormControlLabel,
  Switch as MuiSwitch,
} from '@material-ui/core';
import { colors, getColor } from 'styles/css-constants';

interface StyledColor {
  styledColor: string;
}

const FormControlLabel = styled(MuiFormControlLabel)`
  align-items: flex-end;
  margin-right: 10px;
`;

const Switch = styled(MuiSwitch)<StyledColor>`
  position: relative;
  top: 6px;

  & .Mui-checked {
    color: ${(props) => {
      return getColor(props.styledColor as keyof typeof colors);
    }};
  }

  & .Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => {
      return getColor(props.styledColor as keyof typeof colors);
    }};
  }
`;

export const Styled = {
  FormControlLabel,
  Switch,
};
