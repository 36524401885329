import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  Currency,
  CurrencyVariant,
  LogoVariant,
  TypographyVariant,
} from 'components/core';
import { Mask } from 'components/banking';
import { Styled } from './account-card.style';

export type AccountCardProps = {
  balance: string;
  excludeFromAnalysis: boolean;
  logo: string;
  mask: string;
  name: string;
  onDeselectButtonClick?: (deselect: boolean) => void;
};

export const AccountCard: React.FC<AccountCardProps> = ({
  balance,
  excludeFromAnalysis,
  logo,
  mask,
  name,
  onDeselectButtonClick,
}) => {
  const [isDeselected, setDeselected] = useState(excludeFromAnalysis);

  const handleClick = () => {
    setDeselected(!isDeselected);
    onDeselectButtonClick && onDeselectButtonClick(!isDeselected);
  };

  return (
    <Styled.AccountCardBox
      borderRadius={6}
      p={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      disabled={isDeselected}
    >
      <Styled.AccountCardLogo
        institution={{ logo, name }}
        variant={LogoVariant.square}
      />

      <Box>
        <Styled.AccountMaskData display="flex" alignItems="center">
          <Mask
            mask={
              <Styled.AccountData
                color="white"
                variant={TypographyVariant.SmallText}
              >
                {mask}
              </Styled.AccountData>
            }
          />
        </Styled.AccountMaskData>

        <Styled.AccountData color="white" variant={TypographyVariant.SmallText}>
          {balance && Number(balance) > 0 ? (
            <Currency
              number={Number(balance)}
              variant={CurrencyVariant.Truncated}
            />
          ) : (
            <>&nbsp;</>
          )}
        </Styled.AccountData>
      </Box>

      <Box>
        {onDeselectButtonClick && (
          <Styled.AccountCardDisableIcon
            role="button"
            aria-hidden={false}
            fontSize="small"
            onClick={handleClick}
          />
        )}
      </Box>
    </Styled.AccountCardBox>
  );
};

AccountCard.propTypes = {
  balance: PropTypes.string.isRequired,
  excludeFromAnalysis: PropTypes.bool.isRequired,
  logo: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  onDeselectButtonClick: PropTypes.func,
};
