// https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39

import React, { useEffect, useState } from 'react';
import { createCtx } from 'utils/create-ctx';
import { Location } from 'history';
import { useHistory, Prompt } from 'react-router-dom';
import { Modal } from 'components/core';
import { Size } from 'enums';
import { useTracking, TrackEventName } from 'modules/tracking';
import { MobileView } from '../mobile-view/mobile-view';

interface ConfirmNavigateAwayContext {
  cancel: () => void;

  confirm: () => void;
}

const [useConfirmNavigateAwayContext, ConfirmNavigateAwayProvider] =
  createCtx<ConfirmNavigateAwayContext>();

export { useConfirmNavigateAwayContext };

interface ConfirmNavigateAwayProps {
  modal: React.ReactElement;

  when: boolean;
}

export const ConfirmNavigateAway: React.FC<ConfirmNavigateAwayProps> = ({
  modal,
  when,
}) => {
  const { trackEvent } = useTracking();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setShowModal(true);
      setLastLocation(nextLocation);

      return false;
    }

    return true;
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirmNavigationClick = () => {
    setShowModal(false);

    setConfirmedNavigation(true);
  };

  // if the user closes the tab/window (on desktop)
  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!when) {
        return false;
      }

      trackEvent({
        component: 'Confirm Navigate Away - onBeforeUnload',
        eventName: TrackEventName.Viewed,
      });

      // this text may show for legacy browsers
      // but modern browsers use a generic message that can't be changed
      event.returnValue = 'Changes you made may not be saved.';
      return 'Changes you made may not be saved.';
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [when]);

  // user has confirmed they want to exit
  // return them to their last location
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <ConfirmNavigateAwayProvider
        value={{ cancel: handleCancel, confirm: handleConfirmNavigationClick }}
      >
        <MobileView
          mobile={
            <Modal
              hideCloseIcon
              open={showModal}
              onClose={handleCancel}
              size={Size.FullScreen}
            >
              {modal}
            </Modal>
          }
        >
          <Modal
            hideCloseIcon
            open={showModal}
            onClose={handleCancel}
            size={Size.Medium}
          >
            {modal}
          </Modal>
        </MobileView>
      </ConfirmNavigateAwayProvider>
    </>
  );
};
