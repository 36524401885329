import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button, Typography, TypographyVariant } from 'components/core';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { OnboardingButtons } from 'modules/2023-q4/onboarding/v1-style-overlay/components/onboarding-buttons/onboarding-buttons';

interface SubmitButtonProps {
  disabled?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ disabled }) => {
  return (
    <FeatureFlag
      disabled={
        <Box mt={5} mb={10}>
          <Button disabled={disabled} type="submit" width={100}>
            <Typography color="white" variant={TypographyVariant.Body}>
              Continue
            </Typography>
          </Button>
        </Box>
      }
      enabled={<OnboardingButtons disabled={disabled} />}
      flag={FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED}
    />
  );
};
