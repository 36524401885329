import React, { useRef } from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Box } from '@material-ui/core';
import { Percentage, Typography, TypographyVariant } from 'components/core';
import { useClickAway } from 'modules/dashboard/header/components/header-buttons/components/menu-options/hooks/use-click-away';
import { Styled } from './edit-product-allocation.style';

export enum ProductAllocationProducts {
  ManagedMoneyMarket = 'Managed Money Market',
  ManagedTreasuries = 'Managed Treasuries',
  ManagedIncome = 'Managed Income',
  TreasureCash = 'Treasure Cash',
}

export interface IProductAllocation {
  [ProductAllocationProducts.ManagedMoneyMarket]: {
    value: number;
  };
  [ProductAllocationProducts.ManagedTreasuries]: {
    value: number;
  };
  [ProductAllocationProducts.ManagedIncome]: {
    value: number;
  };
  [ProductAllocationProducts.TreasureCash]: {
    value: number;
  };
}

interface EditProductAllocationProps {
  product: ProductAllocationProducts;
  setProductEditing: ({
    editing,
    product,
  }: {
    editing: boolean;
    product: ProductAllocationProducts;
  }) => void;
  setProductValue: ({
    product,
    value,
  }: {
    product: ProductAllocationProducts;
    value: number | '';
  }) => void;
  value: number | '';
}

export const EditProductAllocation: React.FC<EditProductAllocationProps> = ({
  product,
  setProductEditing,
  setProductValue,
  value,
}) => {
  const { trackEvent } = useTracking<{
    amount: number;
    clickElement: string;
    eventName: TrackEventName;
    product: ProductAllocationProducts;
  }>({
    clickElement: 'Slider',
    eventName: TrackEventName.Slider,
  });

  const ref = useRef(null);

  const stopEditing = () => {
    setProductEditing({ product, editing: false });
  };

  useClickAway(ref, () => {
    stopEditing();
  });

  const handleSliderChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | number[],
  ) => {
    const value = newValue as number;

    setProductValue({ product, value });
  };

  const handleSliderChangeComitted = (
    event: React.ChangeEvent<{}>,
    newValue: number | number[],
  ) => {
    const amount = newValue as number;

    trackEvent({ amount });
  };

  return (
    <>
      <Box textAlign="center" mb={1.5}>
        <Typography color="grey4" variant={TypographyVariant.Paragraph2}>
          Tap anywhere to save
        </Typography>
      </Box>

      <Styled.SliderContainer px={3} py={1}>
        <Styled.Slider
          onBlur={() => stopEditing()}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeComitted}
          ref={ref}
          step={0.1}
          value={value === '' ? 0 : value}
        />
      </Styled.SliderContainer>
    </>
  );
};
