import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Container = styled(Box)`
  background-color: var(--color-white);
  border: 1px solid var(--color-alto);
  border-radius: var(--border-radius-08);
`;

export const Styled = {
  Container,
};
