import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Button, Typography, TypographyVariant } from 'components/core';

import { useForm } from 'react-hook-form';
import { TextFieldController } from 'modules/form-helpers';

import { useLoadingContext } from 'modules/no-auth/treasure-logo-box';

import { useTracking, TrackEventName } from 'modules/tracking';

import { useForgotPassword } from 'modules/no-auth/hooks/use-forgot-password';
import { Toast } from 'components/layouts';

interface IForgotPasswordForm {
  email: string;
}

interface ForgotPasswordFormProps {
  setForgotPasswordSent: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  setForgotPasswordSent,
}) => {
  const { control, handleSubmit } = useForm<IForgotPasswordForm>();
  const { forgotPassword } = useForgotPassword();
  const loadingContext = useLoadingContext();
  const { trackEvent } = useTracking();

  const onSubmit = async (data: IForgotPasswordForm) => {
    loadingContext.setLoading(true);

    try {
      await forgotPassword(data);

      loadingContext.setLoading(false);

      trackEvent({
        eventName: TrackEventName.PasswordResetRequested,
        username: data.email,
      });

      setForgotPasswordSent(true);
    } catch (error) {
      const err = error as { message: string };

      Toast.show('error', err.message);

      loadingContext.setLoading(false);
    }
  };

  return (
    <Box px={{ xs: 3, sm: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <TextFieldController
            label="Email"
            name="email"
            placeholder="Enter your email"
            validations={{
              required: true,
            }}
            control={control}
          />
        </Box>

        <Box mt={3}>
          <Button type="submit" width={100}>
            <Box py={0.75}>
              <Typography color="white" variant={TypographyVariant.Body}>
                Send reset email
              </Typography>
            </Box>
          </Button>
        </Box>
      </form>
    </Box>
  );
};

ForgotPasswordForm.propTypes = {
  setForgotPasswordSent: PropTypes.func.isRequired,
};
