import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { getColor } from 'styles/css-constants';

interface IconCheckProps {
  $isValid: boolean;
}

const IconCheck = styled(Check)<IconCheckProps>`
  color: ${(props) =>
    props.$isValid ? getColor('black') : getColor('lightGrey')};
  font-size: 14px;
`;

const PasswordCriteriaBox = styled(Box)`
  background-color: var(--color-whiteSmoke1);
  border: 1px solid var(--color-gainsboro2);
  border-radius: var(--border-radius-02);
  box-shadow: var(--box-shadow-01);
`;

export const Styled = {
  IconCheck,
  PasswordCriteriaBox,
};
