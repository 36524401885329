import React from 'react';
import { Typography, TypographyVariant } from 'components/core';
import { useFootnoteContext } from 'modules/portfolio/allocation/components/allocation-modal/components/allocation-footnotes/allocation-footnote-provider';

export const AllocationDisclaimers = () => {
  const { footnotes } = useFootnoteContext();

  return (
    footnotes && (
      <Typography color="dustyGray" variant={TypographyVariant.SmallLabel3}>
        {footnotes.map((footnote, index) => {
          return (
            <React.Fragment key={index.toString()}>
              {footnote.symbol} {footnote.description}{' '}
            </React.Fragment>
          );
        })}
      </Typography>
    )
  );
};
