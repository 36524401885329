import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useRemoveRestrictedCusip = (): UseMutationResult<
  any,
  any,
  any
> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const deleteRestrictedCusip = async ({
    businessId,
    cusipId,
  }: {
    businessId: string;
    cusipId: string;
  }) => {
    const { data } = await treasureApi().delete(
      `/business/${businessId}/reserve-account/restricted-cusips/${cusipId}`,
    );

    return data;
  };

  return useMutation(deleteRestrictedCusip, {
    onSuccess: () => {
      return queryClient.invalidateQueries([ReactQueryKeys.RestrictedCusips]);
    },
  });
};
