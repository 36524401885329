import styled from 'styled-components';
import { Grid as GridAlias, Paper as PaperAlias } from '@material-ui/core';
import { Heading as HeadingAlias } from 'components/core';

const Grid = styled(GridAlias)`
  position: relative;
  margin-bottom: var(--spacing-02);
`;

const Heading = styled(HeadingAlias)`
  font-weight: 700;
`;

// TODO: this will most likely get replaced when implementing top indicators
const LiquidSense = styled.div`
  position: absolute;
  top: -15px;
  left: -10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro0);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 200px 200px 0;
`;

const Paper = styled(PaperAlias)`
  border: 1px solid var(--color-alto);
  border-radius: var(--border-radius-08);
  box-shadow: var(--box-shadow-07);
  height: 100%;
`;

export const Styled = {
  Grid,
  Heading,
  LiquidSense,
  Paper,
};
