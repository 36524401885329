import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { useMultipleAccountContext } from 'components/layouts/contexts/multiple-account';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';
import { Styled } from './user-menu-options.style';

interface Option {
  action: () => void;

  description: string;

  eventName: TrackEventName;
}

interface UserMenuOptionsProps {
  setShowActions: (showActions: boolean) => void;
}

export const UserMenuOptions: React.FC<UserMenuOptionsProps> = ({
  setShowActions,
}) => {
  const { signOut } = useSignOut();
  const multipleAccountContext = useMultipleAccountContext();
  const history = useHistory();
  const { Track, trackEvent } = useTracking<{
    eventName: TrackEventName;
    section: TrackPageSection;
  }>({
    section: TrackPageSection.UserMenu,
  });

  const options: Option[] = [
    {
      description: 'Settings',
      action: () => {
        history.push(
          getVersionedPath({
            path: RoutesPath.pages.settings.path,
            version: 3,
          }),
        );
      },
      eventName: TrackEventName.SettingsClicked,
    },
    {
      description: 'Documents',
      action: () => {
        history.push(
          getVersionedPath({
            path: RoutesPath.pages.documents.path,
            version: 3,
          }),
        );
      },
      eventName: TrackEventName.DocumentsClicked,
    },
    {
      description: 'Sign Out',
      action: () => {
        signOut();
      },
      eventName: TrackEventName.SignOutClicked,
    },
  ];

  if (multipleAccountContext.hasMultipleAccounts) {
    options.unshift({
      description: 'Switch Business',
      action: () => {
        multipleAccountContext.setAccountSelectionOpen(true);
        setShowActions(false);
      },
      eventName: TrackEventName.SwitchBusinessClicked,
    });
  }

  const handleActionClick = (o: Option) => {
    o.action();

    trackEvent({
      eventName: o.eventName,
    });
  };

  return (
    <Track>
      <Styled.MenuContainer>
        <Styled.MenuConainerArrowBorder>
          <Styled.MenuConainerArrow />
        </Styled.MenuConainerArrowBorder>

        <Styled.Options>
          {options.map((o) => (
            <Styled.Option
              onClick={(e) => {
                handleActionClick(o);
              }}
              key={o.description}
            >
              {o.description}
            </Styled.Option>
          ))}
        </Styled.Options>
      </Styled.MenuContainer>
    </Track>
  );
};

UserMenuOptions.propTypes = {
  setShowActions: PropTypes.func.isRequired,
};
