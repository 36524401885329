import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const Container = styled(Grid)`
  width: 100%;
  background-color: var(--color-whiteSmoke1);
  border: 1px solid var(--color-gainsboro2);
  box-shadow: var(--box-shadow-01);
  border-radius: var(--border-radius-02);
`;

const IconContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro1);
  border-radius: var(--border-radius-02) 0 0 var(--border-radius-02);
`;

const Popover = styled.div`
  > div {
    position: relative;
    top: 4px;
    background-color: var(--color-fuchsia);
    box-shadow: var(--box-shadow-01);
    border-radius: var(--border-radius-02);

    &:after {
      content: '';
      position: absolute;
      top: -30%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent var(--color-fuchsia) transparent;
    }
  }
`;

export const Styled = {
  Container,
  IconContainer,
  Popover,
};
