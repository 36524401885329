import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { useLegalEntityTypes } from 'modules/onboarding/v3-streamlined-onboarding/hooks/get-legal-entity-types';
import { formErrorFocus } from 'modules/onboarding/v3-streamlined-onboarding/utils';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { PageTitle } from '../components/page-title';
import {
  formFields,
  AutocompleteController,
  CheckboxController,
  FormFieldContainer,
  SubmitButton,
  TextareaController,
  TextFieldController,
} from '../components/forms';

const getLLCId = (legalEntityTypes: { Id: string; Name: string }[]) => {
  return legalEntityTypes?.filter(
    (legalEntityType) => legalEntityType.Name === 'LLC',
  )[0]?.Id;
};

interface BusinessDetailsForm {
  legalName: string;
  businessDescription: string;
  ein: string;
  doingBusinessAs: string;
  industryType: string;
  taxClassification: string;
  annualGrossRevenue: number;
  totalLiquidAssets: number;
  investmentObjective: string;
  exemptListingBeneficialOwners: boolean;
  foreignFinancialInstitution: boolean;
}

export const BusinessDetails: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.BusinessDetails,
  });

  const onboardingContext = useOnboardingContext();
  const savedOnboardingData = onboardingContext.onboardingResponse.data;

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
  } = useForm<BusinessDetailsForm>();

  const foreignFinancialInstitutionChecked = watch(
    'foreignFinancialInstitution',
  );

  useEffect(() => {
    formErrorFocus(errors, isDirty);
  }, [errors, isDirty]);

  const { data: legalEntityTypes, isLoading: isLoadingLegalEntityTypes } =
    useLegalEntityTypes();

  const onSubmit = (data: BusinessDetailsForm) => {
    onboardingContext.postOnboardingData({
      businessId: onboardingContext.businessId,
      data: {
        business: {
          ...data,
        },
      },
    });

    trackEvent({
      eventName: TrackEventName.BusinessDetailsCompleted,
    });
  };

  if (isLoadingLegalEntityTypes) {
    return <Spinner />;
  }

  return (
    <>
      <PageTitle title="Business details" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldContainer>
          <TextFieldController
            {...formFields.legalName}
            control={control}
            defaultValue={savedOnboardingData.business?.legalName}
          />

          <Box ml={0.5} mt={1}>
            <Grid item xs={10}>
              <Typography color="midGray" variant={TypographyVariant.SubLabel}>
                The combination of your name and Employer Identification Number
                (EIN) must exactly match the one listed on your IRS documents
                (e.g., Letter 147C or SS-4 Confirmation letter), including
                capitalization and punctuation.
              </Typography>
            </Grid>
          </Box>
        </FormFieldContainer>

        <FormFieldContainer>
          <TextareaController
            {...formFields.businessDescription}
            control={control}
            defaultValue={savedOnboardingData.business?.businessDescription}
            maxRows={4}
            minRows={4}
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <TextFieldController
            {...formFields.ein}
            control={control}
            defaultValue={savedOnboardingData.business?.ein}
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <TextFieldController
            {...formFields.doingBusinessAs}
            control={control}
            defaultValue={savedOnboardingData.business?.doingBusinessAs}
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <AutocompleteController
            {...formFields.industryType}
            control={control}
            defaultValue={savedOnboardingData.business?.industryType}
          />
        </FormFieldContainer>

        {savedOnboardingData.business?.businessTypeId ===
          getLLCId(legalEntityTypes) && (
          <FormFieldContainer>
            <AutocompleteController
              {...formFields.taxClassification}
              control={control}
              defaultValue={savedOnboardingData.business?.taxClassification}
            />
          </FormFieldContainer>
        )}

        <FormFieldContainer>
          <TextFieldController
            {...formFields.annualGrossRevenue}
            control={control}
            defaultValue={savedOnboardingData.business?.annualGrossRevenue}
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <TextFieldController
            {...formFields.totalLiquidAssets}
            control={control}
            defaultValue={savedOnboardingData.business?.totalLiquidAssets}
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <AutocompleteController
            {...formFields.investmentObjective}
            control={control}
            defaultValue={savedOnboardingData.business?.investmentObjective}
          />
        </FormFieldContainer>

        <FeatureFlag
          disabled={
            <FormFieldContainer>
              <CheckboxController
                {...formFields.exemptListingBeneficialOwners}
                control={control}
                defaultValue={
                  savedOnboardingData.business?.exemptListingBeneficialOwners ||
                  false
                }
              />
            </FormFieldContainer>
          }
          flag={FeatureFlags.REACT_APP_2023_Q3_ONBOARDING_UPDATES_ENABLED}
        />

        <FeatureFlag
          disabled={
            <>
              <FormFieldContainer>
                <CheckboxController
                  {...formFields.foreignFinancialInstitution}
                  control={control}
                  defaultValue={
                    savedOnboardingData.business?.foreignFinancialInstitution ||
                    false
                  }
                />
              </FormFieldContainer>

              <SubmitButton />
            </>
          }
          enabled={
            <>
              <FormFieldContainer>
                <CheckboxController
                  {...formFields.foreignFinancialInstitution}
                  control={control}
                  defaultValue={
                    savedOnboardingData.business?.foreignFinancialInstitution ||
                    false
                  }
                />

                {foreignFinancialInstitutionChecked && (
                  <Box mt={2} className="helper-text">
                    <Typography
                      color="torchRed"
                      variant={TypographyVariant.Paragraph2}
                    >
                      A Treasure account cannot be created for businesses that
                      qualify as foreign financial institutions. Please update
                      your selections to continue or contact{' '}
                      <ContactTreasureSupportLink subject="Foreign Financial Institution">
                        support@treasure.tech
                      </ContactTreasureSupportLink>{' '}
                      if you have any questions.
                    </Typography>
                  </Box>
                )}
              </FormFieldContainer>

              <SubmitButton disabled={foreignFinancialInstitutionChecked} />
            </>
          }
          flag={FeatureFlags.REACT_APP_2023_Q3_ONBOARDING_UPDATES_ENABLED}
        />
      </form>
    </>
  );
};
