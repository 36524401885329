import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { ReactQueryMutationKeys } from 'enums';
import { getUserAgent } from 'modules/tracking/utils/get-user-agent';

const userAgentInformation = getUserAgent();

export const useValidateDevice = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Authentication);

  const validateDevice = async ({ token }: { token: string }) => {
    const response = await treasureApi().post('/user/validate-device', {
      token,
      user_agent: userAgentInformation,
    });

    return response;
  };

  return useMutation(validateDevice, {
    mutationKey: ReactQueryMutationKeys.ValidateDevice, // this mutation key is used by react-query-error-toasts so it will never show a user an error
  });
};
