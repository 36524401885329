import React from 'react';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { Styled } from './header.style';

export const Header: React.FC = ({ children }) => {
  return (
    <Styled.Container py={2.5}>
      <CenteredContainer>{children}</CenteredContainer>
    </Styled.Container>
  );
};
