import styled from 'styled-components';
import { Slider as MuiSlider } from '@material-ui/core';
import { colors, getColor } from 'styles/css-constants';

interface StyledColor {
  styledColor: string;
}

const Slider = styled(MuiSlider)<StyledColor>`
  &.MuiSlider-root {
    color: ${(props) => {
      return getColor(props.styledColor as keyof typeof colors);
    }};
  }
`;

export const Styled = {
  Slider,
};
