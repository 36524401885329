import React from 'react';
import { Box } from '@material-ui/core';
import { Styled } from './mfa-code-box.style';
interface MFACodeBoxProps {
  forwardRef: React.RefObject<HTMLInputElement>;

  onChange: (event: React.FormEvent<HTMLInputElement>) => void;

  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;

  readonly: boolean;

  value: string;

  mobile?: boolean;
}

export const MFACodeBox: React.FC<MFACodeBoxProps> = ({
  forwardRef,
  onChange,
  onKeyDown,
  onPaste,
  readonly,
  value,
  mobile,
}) => {
  return (
    <Box py={2.5}>
      <Styled.CodeInput
        autoComplete="off"
        data-lpignore="true" // lastpass
        data-form-type="other" // dashlane
        minLength={1}
        maxLength={1}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        readOnly={readonly}
        required
        ref={forwardRef}
        type="text"
        inputMode="numeric"
        value={value}
      />
    </Box>
  );
};
