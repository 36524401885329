import React from 'react';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import { Deposit } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/deposit/deposit';
import { CheckAccountStatusBeforeDeposit } from 'modules/2024-q3/grasshopper-sunset/deposit/check-account-status-before-deposit/check-account-status-before-deposit';
import { Deposit as DepositApexACH } from 'modules/2024-q4/apex-ach/deposit-apex-ach/deposit';
import { useBusiness } from 'hooks/business/use-business';
import { Redirect } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

export const DepositTransition = () => {
  // testing Apex ACH flow
  // TODO: remove me
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { data: business } = useBusiness();

  if (business?.LegalName === 'Bridge Building Inc') {
    return <Redirect to={RoutesPath.pages.home.path} />;
  }

  if (isFeatureFlagEnabled(FeatureFlags.REACT_APP_2024_Q4_APEX_ACH)) {
    return <DepositApexACH />;
  }

  return (
    <FeatureFlag
      disabled={<Deposit />}
      enabled={<CheckAccountStatusBeforeDeposit />}
      flag={FeatureFlags.REACT_APP_2024_Q3_GRASSHOPPER_SUNSET}
    />
  );
};
