import { dateMask, ssnMask, USAZipCodeMask } from 'utils/forms/masks';

import {
  formattedSSNRegExp,
  USAZipCodeRegExp,
} from 'utils/forms/regular-expressions';

import {
  isValidDateOfBirth,
  isValidEmail,
  maxAddressLength,
  maxEmailLength,
  maxJobTitleLength,
  maxNameLength,
  maxNonUSANationalIdentifierLength,
  maxNonUSAStateLength,
  maxNonUSAZipCodeLength,
  maxUSAStateLength,
  maxUSAZipCodeLength,
} from 'utils/forms/validators';

import { Country } from 'enums';

import { AutocompleteSpecs } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/autocomplete-controller/autocomplete-controller';
import { TextFormFieldSpecs } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/text-field-controller/text-field-controller';

export const countryOptions = Object.entries(Country).map(
  ([countryCode, countryName]) => {
    return { label: countryName, value: countryCode };
  },
);

type FormFields = {
  email: TextFormFieldSpecs;
  jobTitle: TextFormFieldSpecs;
  firstName: TextFormFieldSpecs;
  lastName: TextFormFieldSpecs;
  dateOfBirth: TextFormFieldSpecs;
  citizenshipCountry: AutocompleteSpecs;
  residencyCountry: AutocompleteSpecs;
  USANationalIdentifier: TextFormFieldSpecs;
  nonUSANationalIdentifier: TextFormFieldSpecs;
  address1: TextFormFieldSpecs;
  address2: TextFormFieldSpecs;
  city: TextFormFieldSpecs;
  USAState: Omit<AutocompleteSpecs, 'options'>; // these options are not static, they are fetched from the backend
  nonUSAState: TextFormFieldSpecs;
  USAZipCode: TextFormFieldSpecs;
  nonUSAZipCode: TextFormFieldSpecs;
};

export const formFields: FormFields = {
  email: {
    name: 'email',
    label: 'Email',
    validations: {
      required: true,
      maxLength: maxEmailLength,
      validate: (value: string) =>
        isValidEmail(value) || 'Please use a valid email address',
    },
  },
  jobTitle: {
    name: 'jobTitle',
    label: 'Title / Role',
    validations: {
      required: true,
      maxLength: maxJobTitleLength,
    },
  },
  firstName: {
    name: 'firstName',
    label: 'First name',
    validations: {
      required: true,
      maxLength: maxNameLength,
    },
  },
  lastName: {
    name: 'lastName',
    label: 'Last name',
    validations: {
      required: true,
      maxLength: maxNameLength,
    },
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    placeholder: 'MM/DD/YYYY',
    mask: dateMask,
    validations: {
      required: true,
      validate: (value) =>
        isValidDateOfBirth(value) || 'Date of Birth must be a valid date',
    },
  },
  citizenshipCountry: {
    name: 'citizenshipCountry',
    label: 'Country of Citizenship',
    options: countryOptions,
    validations: {
      required: true,
    },
  },
  residencyCountry: {
    name: 'residencyCountry',
    label: 'Country of Residency',
    options: countryOptions,
    validations: {
      required: true,
    },
  },
  USANationalIdentifier: {
    name: 'nationalIdentifier',
    label: 'Social Security Number',
    placeholder: '###-##-####',
    hasVisibilityToggle: true,
    mask: ssnMask,
    validations: {
      required: true,
      pattern: formattedSSNRegExp,
    },
  },
  nonUSANationalIdentifier: {
    name: 'nationalIdentifier',
    label: 'National Identifier',
    placeholder: 'Government ID/Passport No.',
    validations: {
      required: true,
      maxLength: maxNonUSANationalIdentifierLength,
    },
  },
  address1: {
    errorLabel: 'Address',
    name: 'address1',
    placeholder: 'Address line 1',
    validations: {
      required: true,
      maxLength: maxAddressLength,
    },
  },
  address2: {
    name: 'address2',
    placeholder: 'Address line 2',
    validations: {
      maxLength: maxAddressLength,
    },
  },
  city: {
    name: 'city',
    placeholder: 'City',
    validations: {
      required: true,
      maxLength: maxAddressLength,
    },
  },
  USAState: {
    name: 'state',
    placeholder: 'State',
    validations: {
      required: true,
      maxLength: maxUSAStateLength,
    },
  },
  nonUSAState: {
    name: 'state',
    placeholder: 'State',
    validations: {
      required: true,
      maxLength: maxNonUSAStateLength,
    },
  },
  USAZipCode: {
    name: 'zipCode',
    placeholder: 'Zip',
    mask: USAZipCodeMask,
    validations: {
      required: true,
      pattern: USAZipCodeRegExp,
      maxLength: maxUSAZipCodeLength,
    },
  },
  nonUSAZipCode: {
    name: 'zipCode',
    placeholder: 'Zip',
    validations: {
      required: true,
      maxLength: maxNonUSAZipCodeLength,
    },
  },
};
