import { getCookie, setCookie } from 'react-use-cookie';
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';
import { isDevOrStagingEnv } from 'routes/constants/routes-path';
import { useRememberDevice } from './use-remember-device';
import { useValidateDevice } from './use-validate-device';

export const useLoginDevice = () => {
  const { data: business } = useBusiness();
  const { data: user } = useUser(business?.Id);

  const saveRememberDevice = useRememberDevice();
  const validateDevice = useValidateDevice();

  const isValidDevice = async () => {
    const rememberDeviceToken = getCookie(`${user?.uid}remdev`);
    let validDevice = false;

    // check if a remember device cookie exists
    // if so, check the db to see if this is a valid device
    if (rememberDeviceToken) {
      await validateDevice.mutateAsync(
        { token: rememberDeviceToken },
        {
          onSuccess: (response) => {
            if (response.data.valid_device) {
              validDevice = true;
            }
          },
        },
      );
    }

    return validDevice;
  };

  const rememberDevice = () => {
    const randomToken = Math.random().toString(36).substr(2, 15);

    setCookie(`${user?.uid}remdev`, randomToken, {
      days: 30,
      SameSite: 'Strict',
      Secure: !isDevOrStagingEnv,
    });

    saveRememberDevice.mutate({
      token: randomToken,
    });
  };

  return { isValidDevice, rememberDevice };
};
