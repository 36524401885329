import React from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'components/core';
import {
  HasMultipleAccounts,
  SignOutLink,
  SwitchMultipleAccounts,
} from './components';
import { Styled } from './header-options.style';

export const HeaderOptions: React.FC = () => {
  return (
    <Grid container spacing={1}>
      {HasMultipleAccounts() && (
        <>
          <Grid item>
            <SwitchMultipleAccounts>
              <Text color="silverChalice" variant={2}>
                Switch business
              </Text>
            </SwitchMultipleAccounts>
          </Grid>

          <Grid item>
            <Text color="silverChalice" variant={2}>
              &#124;
            </Text>
          </Grid>
        </>
      )}

      <Grid item>
        <SignOutLink>
          <Text color="silverChalice" variant={2}>
            Sign out <Styled.RightArrow />
          </Text>
        </SignOutLink>
      </Grid>
    </Grid>
  );
};
