import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useFees = (businessId: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery(
    [ReactQueryKeys.Fees, businessId],
    async () => {
      const response = await treasureApi().get(`/business/${businessId}/fees`);

      return response.data.fees;
    },
    {
      enabled: !!businessId,
    },
  );
};
