import React from 'react';
import PropTypes from 'prop-types';
import { Styled } from './info-grid.style';

interface InfoGridProps {
  info: {
    label: string;
    value: string | React.ReactNode;
  }[];
}

export const InfoGrid: React.FC<InfoGridProps> = ({ info }) => (
  <Styled.InfoGridContainer>
    {info.map((infoItem, index) => (
      <Styled.InfoGridRow key={infoItem.label}>
        <Styled.InfoGridLabel>{infoItem.label}</Styled.InfoGridLabel>
        {typeof infoItem.value === 'string' ? (
          <Styled.InfoGridValue>{infoItem.value}</Styled.InfoGridValue>
        ) : (
          <Styled.InfoGridComponent>{infoItem.value}</Styled.InfoGridComponent>
        )}
      </Styled.InfoGridRow>
    ))}
  </Styled.InfoGridContainer>
);

InfoGrid.propTypes = {
  info: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    }).isRequired,
  ).isRequired,
};
