import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { Typography, Currency as CurrencyAlias } from 'components/core';

interface AccountSelectContainer {
  fixedSelection: boolean;
}

export const FormContainer = styled.form`
  max-height: 3000px;
  padding: 24px 20px;

  .left-label {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &.currency {
      .MuiInputBase-root {
        input {
          text-align: right;
          ::placeholder,
          ::-webkit-input-placeholder {
            opacity: 1;
          }
          :-ms-input-placeholder {
            opacity: 1;
          }
        }
      }
    }

    > div {
      display: flex;
      flex-basis: 75%;

      &.MuiBox-root {
        flex-basis: 25%;
      }

      &.helper-text {
        flex-basis: 100%;
        justify-content: end;
      }

      .MuiInputBase-root {
        border: none;
        border-radius: 3px;
        box-shadow: none;
        font-family: var(--font-family);
        font-size: var(--font-size-body-03);
        height: 44px;
      }
    }

    label > p {
      color: var(--color-nero);
    }
  }
`;

export const TransferReview = styled(Typography)`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0;
  transition: all 0.25s ease-in-out;
  height: auto;
  max-height: 0;
  opacity: 0;
  overflow: hidden;

  span {
    display: contents;
  }

  &.show {
    max-height: 500px;
    opacity: 1;
    padding: 24px 0;
  }
`;

export const ButtonContainer = styled(Box)`
  display: flex;

  button {
    flex: 1;
    font-size: var(--font-size-body-04);
    height: 40px;
    margin-top: 12px;
  }
`;

export const Currency = styled(CurrencyAlias)`
  display: inline;
`;

const SpinnerContainer = styled(Box)`
  padding: 24px 0;
`;

const AccountSelectContainer = styled(({ fixedSelection, ...rest }) => (
  <Grid {...rest} />
))<AccountSelectContainer>`
  ${(props) =>
    props.fixedSelection &&
    css`
      .MuiInputBase-root {
        .MuiInputAdornment-root {
          display: none;
        }
      }
      input {
        &.Mui-disabled {
          color: var(--color-nero);
        }
      }
      fieldset {
        border: none;
      }

      & .Mui-disabled {
        input {
          z-index: 1;
        }

        fieldset {
          background-color: var(--color-whiteSmoke4);
        }
      }
    `}
`;

export const Styled = {
  ButtonContainer,
  FormContainer,
  TransferReview,
  Currency,
  SpinnerContainer,
  AccountSelectContainer,
};
