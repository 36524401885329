import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useValidateToken = (token: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery(
    [ReactQueryKeys.OnboardingToken],
    async () => {
      const response = await treasureApi().get(
        `/onboarding/validate-token?token=${token}`,
      );

      return response.data;
    },
    {
      retry: false,
    },
  );
};
