import React from 'react';
import PropTypes from 'prop-types';
import { dayjs } from 'app/dayjs';
import { TableRow } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Text,
  TransactionTypeIcon,
} from 'components/core';
import { Mask } from 'components/banking';
import { ActivityAccountType, TransactionType, TransactionStatus } from 'enums';
import { TransactionPropTypes } from 'types/prop-types';
import { Styled } from './activity-list.style';

export interface IActivityListItem {
  accountMask: string;
  accountName: string;
  accountType: ActivityAccountType;
  amount: number;
  date: string; // Date
  description: string;
  status: string;
}

export interface ActivityListItemProps {
  data: IActivityListItem;

  showDivider: boolean;
}

const TREASURE_RESERVE = 'Treasure Reserve';

const ActivityListItem: React.FC<ActivityListItemProps> = ({
  data,
  showDivider,
}) => {
  let transactionType = TransactionType.Debit;
  if (data.accountType === ActivityAccountType.Reserve) {
    transactionType = TransactionType.Transfer;
  } else if (data.amount > 0) {
    transactionType = TransactionType.Credit;
  } else if (data.accountType === ActivityAccountType.Bank) {
    transactionType = TransactionType.BankDebit;
  }

  const statusColor =
    data.status ===
    Object.keys(TransactionStatus).find(
      (status) =>
        TransactionStatus[status as keyof typeof TransactionStatus] ===
        'Completed',
    )
      ? 'stromboli'
      : 'amaranth';

  return (
    <TableRow>
      <Styled.TableCell>
        <TransactionTypeIcon type={transactionType} />
        {showDivider && (
          <Styled.TransactionTypeIconConnector orientation="vertical" />
        )}
      </Styled.TableCell>
      <Styled.TableCell>
        <Text variant={2}>{data.description}</Text>
        <Text color="gothic" variant={1}>
          {dayjs.utc(data.date).format('MMM D, YYYY')}
        </Text>
      </Styled.TableCell>
      <Styled.TableCell>
        <Text color="nero" variant={1}>
          {data.accountType !== ActivityAccountType.TreasureReserve ? (
            <>
              {data.accountName}
              {data.accountMask && <Mask mask={data.accountMask} />}
            </>
          ) : (
            TREASURE_RESERVE
          )}
        </Text>
      </Styled.TableCell>
      <Styled.TableCell>
        <Text color="nero" variant={1}>
          <Currency number={data.amount} variant={CurrencyVariant.Full} />
        </Text>
      </Styled.TableCell>
      <Styled.TableCell>
        <Text color={statusColor} variant={1}>
          {TransactionStatus[data.status as keyof typeof TransactionStatus]}
        </Text>
      </Styled.TableCell>
    </TableRow>
  );
};

ActivityListItem.propTypes = {
  data: TransactionPropTypes.isRequired,
  showDivider: PropTypes.bool.isRequired,
};

export { ActivityListItem };
