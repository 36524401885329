import React from 'react';
import {
  IProductAllocations,
  ProductAllocationProducts,
} from 'modules/2024-q1/allocation-by-amount-or-percentage/interface/product-allocation';
import { Box, Divider } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { useHideProduct } from 'modules/2023-q4/white-label/hooks/use-hide-product';
import { PortfolioAllocationListItem } from './components/portfolio-allocation-list-item/portfolio-allocation-list-item';

interface Props {
  allocation: IProductAllocations;
  title?: string;
}

export const PortfolioAllocationList: React.FC<Props> = ({
  allocation,
  title,
}) => {
  const { hideProduct } = useHideProduct();

  return (
    <>
      {title && (
        <Box mb={2}>
          <Typography color="black19" variant={TypographyVariant.Header5}>
            {title}
          </Typography>
        </Box>
      )}

      {Object.entries(allocation).map((productAllocation) => {
        if (hideProduct(productAllocation[0])) {
          return null;
        }

        return (
          <Box my={2} key={productAllocation[0]}>
            <Box mb={2}>
              <Divider />
            </Box>

            <PortfolioAllocationListItem
              allocationPercentage={productAllocation[1].value}
              allocationValue={productAllocation[1].amount}
              productName={productAllocation[0]}
            />
          </Box>
        );
      })}
    </>
  );
};
