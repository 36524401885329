import styled from 'styled-components';
import { Button } from 'components/core';

const ConnectQBButton = styled(Button)`
  padding: 0px;
`;

const DisconnectQBButton = styled(Button)`
  min-width: unset;
  text-decoration: underline;
  &:active,
  &:hover {
    text-decoration: underline;
  }
`;

export const Styled = {
  ConnectQBButton,
  DisconnectQBButton,
};
