import React from 'react';
import { SwitchProps } from '@material-ui/core';
import { Styled } from './custom-switch.style';

export interface CustomSwitchProps {
  error?: boolean | string | undefined;
  disabled?: boolean;
  checked?: boolean;
}

type Props = CustomSwitchProps & SwitchProps;

export const CustomSwitch: React.FC<Props> = (props: Props) => {
  const { disabled, error, checked, defaultValue, ...restProps } = props;

  const handleError = (error: Props) => (error ? 'true' : '');

  return (
    <Styled.StyledSwitch
      icon={
        <Styled.StyledSwitchIcon
          disabled={disabled}
          error={handleError(error as Props)}
        />
      }
      checkedIcon={
        <Styled.StyledActiveSwitchIcon
          disabled={disabled}
          error={handleError(error as Props)}
        />
      }
      error={handleError(error as Props)}
      disabled={disabled}
      defaultValue={defaultValue}
      checked={checked}
      {...restProps}
    />
  );
};

CustomSwitch.defaultProps = {
  error: false,
};

export default CustomSwitch;
