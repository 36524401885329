import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export enum NumberDisplayVariant {
  currency,
  percentage,
}

export interface NumberDisplayProps {
  /** decimal scale */
  decimalScale: number;

  /** the number to display */
  number: number;

  /** prefix */
  prefix?: string;

  /** function to allow different rendering of the output */
  renderText?: (formattedValue: string) => React.ReactNode;

  /** suffix */
  suffix?: string;

  /** variants: currency, percentage */
  variant: NumberDisplayVariant;
}

const getVariant = (
  decimalScale: number,
  number: number,
  renderText?: (formattedValue: string) => React.ReactNode,
  prefix?: string,
  suffix?: string,
) => ({
  [NumberDisplayVariant.currency]: (
    <NumberFormat
      value={number}
      displayType="text"
      thousandSeparator
      decimalScale={decimalScale}
      fixedDecimalScale
      prefix="$"
      suffix={suffix}
      renderText={renderText}
    />
  ),
  [NumberDisplayVariant.percentage]: (
    <NumberFormat
      value={number}
      displayType="text"
      thousandSeparator
      decimalScale={decimalScale}
      fixedDecimalScale
      prefix={prefix}
      suffix="%"
      renderText={renderText}
    />
  ),
});

const NumberDisplay: React.FC<NumberDisplayProps> = ({
  decimalScale,
  number,
  prefix,
  renderText,
  suffix,
  variant,
}) => {
  return (
    <>{getVariant(decimalScale, number, renderText, prefix, suffix)[variant]}</>
  );
};

NumberDisplay.propTypes = {
  decimalScale: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  prefix: PropTypes.string,
  renderText: PropTypes.func,
  suffix: PropTypes.string,
  variant: PropTypes.oneOf([
    NumberDisplayVariant.currency,
    NumberDisplayVariant.percentage,
  ]).isRequired,
};

export { NumberDisplay };
