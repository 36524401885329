import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { TypographyVariant } from 'components/core';
import { UserMenuOptions } from './user-menu-options';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrows/caret-down.svg';
import { Styled } from './user-menu.style';

// hooks
import { useBusiness } from 'hooks/business/use-business';

export const UserMenu: React.FC = () => {
  const { data: business } = useBusiness();

  const [showActions, setShowActions] = useState(false);

  const companyName = business?.LegalName || business?.DBA;

  return (
    <Styled.HeaderMenu
      onClick={() => {
        setShowActions(!showActions);
      }}
      position="relative"
      px={2}
      py={1}
    >
      <Styled.CompanyName variant={TypographyVariant.Paragraph2}>
        {companyName}
      </Styled.CompanyName>

      <Box position="absolute" top={7} right={15}>
        <ArrowDropDownIcon />
      </Box>

      {showActions ? <UserMenuOptions setShowActions={setShowActions} /> : null}
    </Styled.HeaderMenu>
  );

  /*return (
    <Styled.Container
      onClick={() => {
        setShowActions(!showActions);
      }}
      textAlign="right"
      px={2}
    >
      <Styled.GridContainer container alignItems="center">
        <Grid item>
          <Styled.CompanyName variant={TypographyVariant.Body}>
            {companyName}
          </Styled.CompanyName>
        </Grid>

        <Grid item>
          <ArrowDropDownIcon />
        </Grid>
      </Styled.GridContainer>

      {showActions ? <UserMenuOptions setShowActions={setShowActions} /> : null}
    </Styled.Container>
  );*/
};
