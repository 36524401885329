import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Check } from '@material-ui/icons';

const IconCheck = styled(Check)`
  color: var(--color-nero);
  font-size: 14px;
`;

const PasswordCriteriaBox = styled(Box)`
  background-color: var(--color-whiteSmoke1);
  border: 1px solid var(--color-gainsboro2);
  border-radius: var(--border-radius-02);
  box-shadow: var(--box-shadow-01);
`;

export const Styled = {
  IconCheck,
  PasswordCriteriaBox,
};
