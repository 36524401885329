import styled from 'styled-components';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const UserAvatar = styled(AccountCircleIcon)`
  color: var(--color-gothic);
  font-size: 50px;
`;

export const Styled = {
  UserAvatar,
};
