import styled from 'styled-components';
import { LinearProgress as LinearProgressAlias } from '@material-ui/core';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as TrendCurrencyUp } from 'assets/icons/trend_currency_up.svg';
import { Divider } from '../sub-indicators.common.style';

interface StyledLinearProgressArrow {
  value: number;
}

const LinearProgress = styled(LinearProgressAlias)`
  &.MuiLinearProgress-root {
    height: 2px;
  }

  &.MuiLinearProgress-colorPrimary {
    background-color: var(--color-hitGrey);
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: var(--color-stromboli);
  }
`;

const LinearProgressArrow = styled(Arrow)<StyledLinearProgressArrow>`
  position: relative;
  left: calc(${(props) => props.value}% - 4px);
  width: 8px;
`;

const TrendCurrencyUpIcon = styled(TrendCurrencyUp)`
  transform: scale(1.5);
`;

export const Styled = {
  Divider,
  LinearProgress,
  LinearProgressArrow,
  TrendCurrencyUpIcon,
};
