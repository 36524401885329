export const maskEmail = (email: string) => {
  const localPart = email?.split('@')[0];

  // option: first and last character before domain
  /* const mask = email.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c,
  ); */

  // option: replace each character with an asterisk (except last 4)
  /* const mask = email.replace(
    /^(.*)(....@.*)$/,
    (_, a, b) => a.replace(/./g, '*') + b,
  ); */

  // option: replace email with 5 asterisks (except last 4)
  // added check to see if the localPart is less than 4 (otherwise this would expose the full email)
  const maskLastCharacters = (numberOfCharacters: number) => {
    const re = new RegExp(`^(.*)(.{${numberOfCharacters}}@.*)$`);

    return email?.replace(re, (_, a, b) => `*****${b}`);
  };

  if (localPart?.length < 4) {
    return maskLastCharacters(localPart.length - 1);
  }

  return maskLastCharacters(4);
};
