import firebase from 'utils/firebase';

// react-query
import { Mutation, MutationCache, Query, QueryCache } from 'react-query';
import { ReactQueryKeys, ReactQueryMutationKeys } from 'enums';

// sentry
// import { sentryLog } from 'utils/sentryHelper';

// toast
import { Toast } from 'components/layouts';

const onError = (error: any) => {
  window.scrollTo(0, 0);

  // TODO: probably a better way of handling this
  // will look into it during dev work for the ping check
  if (
    error.response?.status === 500 &&
    error.response?.data?.message === 'Not authorized, no valid relationship.'
  ) {
    firebase.auth().signOut();
    return; // so we don't show the error toast below
  }

  /*
    Default to a generic user visible message unless this error
    has a 4xx status.
    If the error has a 4xx status, there are 3 reponse shapes we need
    to account for:
      1) errors.error.response?.data?.message => used by (at least)
        the reset-password endpoint from auth-api
      2) errors.error.response?.data?.errors => used by onboarding
        endpoint from custodian
      3) errors.error?.message => original default for legacy calls
  */
  let message = 'Oops! An error occurred, please try again later';
  const status = (error.response?.status || error?.status) ?? 500;

  if (status >= 400 && status <= 499) {
    message =
      (error.response?.data?.message ||
        error.response?.data?.errors?.[0]?.error_message ||
        error.response?.data?.errors ||
        error.response?.data?.error ||
        error?.message) ??
      message;
  }

  /* if (!errors?.skipSentryLog) {
    sentryLog({
      error: errors.error,
      userFacingError: message,
      customTag: errors?.tag,
      errorLevel: errors?.level,
    });
  } */

  Toast.show('error', message);
};

const dontToastMutationKeys = [
  ReactQueryMutationKeys.ConfirmMFACode,
  ReactQueryMutationKeys.IsApiUser,
  ReactQueryMutationKeys.LoginRecord,
  ReactQueryMutationKeys.RememberDevice,
  ReactQueryMutationKeys.SavePortfolio,
  ReactQueryMutationKeys.SendTreasureEvent,
  ReactQueryMutationKeys.ValidateDevice,
  ReactQueryMutationKeys.ValidateRelationship,
];

const onErrorMutation = (
  error: unknown,
  variables: unknown,
  context: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>,
) => {
  if (
    dontToastMutationKeys.includes(
      mutation.options.mutationKey as ReactQueryMutationKeys,
    )
  ) {
    return;
  }

  onError(error);
};

export const mutationCache = new MutationCache({
  onError: onErrorMutation,
});

const dontToastQueryKeys = [ReactQueryKeys.InviteToken, ReactQueryKeys.UserIP];

const onErrorQuery = (error: unknown, query: Query) => {
  if (dontToastQueryKeys.includes(query.queryKey as ReactQueryKeys)) {
    return;
  }

  onError(error);
};

export const queryCache = new QueryCache({
  onError: onErrorQuery,
});
