import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ReactComponent as IconCheckmarkCircleGreen } from 'assets/icons/checkmarks/checkmark-circle-green.svg';
import { Styled } from './sign-up-copy.style';

const Bullet: React.FC = ({ children }) => {
  return (
    <Box mb={2}>
      <Grid container>
        <Grid item>
          <Styled.IconCheckmarkWrapper mr={1}>
            <IconCheckmarkCircleGreen />
          </Styled.IconCheckmarkWrapper>
        </Grid>
        <Grid item>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export const SignUpCopy = () => {
  return (
    <>
      <Box mb={3}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          With Treasure, the burden of cash management is removed, risk is
          reduced and more revenue is only a click away.
        </Typography>
      </Box>

      <Bullet>Account Setup Takes Minutes</Bullet>
      <Bullet>
        Designed to offer yield and liquidity without sacrificing security
      </Bullet>
      <Bullet>SEC-registered Investment Adviser</Bullet>
      <Bullet>Actively Managed by the Treasure Investment Team</Bullet>
    </>
  );
};
