import styled from 'styled-components';
import { Box } from '@material-ui/core';

const ChartHeader = styled(Box)`
  background-color: var(--color-wildSand);
  border-radius: var(--border-radius-08);
`;

export const Styled = {
  ChartHeader,
};
