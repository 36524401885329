import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const Container = styled(Grid)`
  background-color: var(--color-white);
  border: 1px solid var(--color-alto);
  box-shadow: var(--box-shadow-07);
  height: 55px;
`;

export const Styled = {
  Container,
};
