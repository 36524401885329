import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createCtx } from 'utils/create-ctx';

interface Footnote {
  description: string;

  stayOnScreen?: boolean;

  symbol: string;
}

interface FootnoteContext {
  footnotes: Footnote[];

  register: (footnote: Footnote) => () => void;
}

export const [useFootnoteContext, FootnoteContextProvider] =
  createCtx<FootnoteContext>();

export const FootnoteProvider: React.FC = ({ children }) => {
  const [footnotes, setFootnotes] = useState<Footnote[]>([]);

  const register = (footnote: Footnote) => {
    setFootnotes((footnotes) => {
      // don't add duplicate footnotes to the footer
      // currently we're only checking the description but we may want to be
      // looking at the description and symbol
      if (
        footnotes.filter((note) => note.description === footnote.description)
          .length === 0
      ) {
        return [...footnotes, footnote];
      }

      return [...footnotes];
    });

    return () => {
      // this removes footnotes when the corresponding component/content is removed
      // however, if the text is in a modal, we want the disclaimer to remain
      // so set those footnote's stayOnScreen to true
      setFootnotes((footnotes) => [
        ...footnotes.filter((note) => {
          if (footnote.stayOnScreen) {
            return true;
          }

          return note.description !== footnote.description;
        }),
      ]);
    };
  };

  return (
    <FootnoteContextProvider
      value={{
        footnotes,
        register,
      }}
    >
      {children}
    </FootnoteContextProvider>
  );
};

FootnoteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
