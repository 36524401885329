import React from 'react';
import { AccordionDetails } from '@material-ui/core';
import { ReturnDistribution } from 'modules/portfolio/allocation/components/allocation-modal/components';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { Styled } from 'modules/portfolio/allocation/components/allocation-modal/components/return-distribution-section/return-distribution-section.style';

const ReturnDistributionAccordion = ({
  allocations,
  expanded,
  product,
}: {
  allocations: Allocation;
  expanded: boolean;
  product: keyof Allocation;
}) => {
  return (
    <Styled.Accordion expanded={expanded}>
      <></>
      <AccordionDetails>
        <ReturnDistribution allocations={allocations} product={product} />
      </AccordionDetails>
    </Styled.Accordion>
  );
};

export const getReturnDistributions = ({
  allocations,
  expanded,
  onClick,
}: {
  allocations: Allocation;
  expanded: boolean;
  onClick: () => void;
}) => {
  const data = [<></>];

  Object.entries(allocations).map(([productKey, product]) => {
    return data.push(
      <ReturnDistributionAccordion
        allocations={allocations}
        expanded={expanded}
        product={productKey as keyof Allocation}
      />,
    );
  });

  return data;
};
