import styled, { css } from 'styled-components';
import { Divider as DividerAlias } from '@material-ui/core';
import { colors, getColor } from 'styles/css-constants';

const Divider = styled(DividerAlias)`
  background-color: ${(props) => {
    if (props.color) return getColor(props.color as keyof typeof colors);

    return css`var(--color-gainsboro0)`;
  }};
`;

export const Styled = {
  Divider,
};
