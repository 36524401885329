import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { BusinessStructure as BusinessStructureV1 } from './business-structure-v1-without-country';
import { BusinessStructure as BusinessStructureV2 } from './business-structure-v2-with-country';

export const BusinessStructure: React.FC = () => {
  return (
    <FeatureFlag
      disabled={<BusinessStructureV1 />}
      enabled={<BusinessStructureV2 />}
      flag={
        FeatureFlags.REACT_APP_2023_Q3_INTERNATIONAL_BUSINESS_ADDRESS_ENABLED
      }
    />
  );
};
