import React from 'react';
import { Box } from '@material-ui/core';
import { Select } from 'components/core/forms';
import { Typography, TypographyVariant } from 'components/core';
import dayjs from 'dayjs';

const PeriodDateFormat = 'YYYY-MM-DD';
export const Period30Days = dayjs()
  .subtract(30, 'days')
  .format(PeriodDateFormat);
const Period90Days = dayjs().subtract(90, 'days').format(PeriodDateFormat);
const Period1Year = dayjs().subtract(1, 'year').format(PeriodDateFormat);

export const ProductYieldPanelPeriods: { label: string; value: string }[] = [
  { label: 'Last 30 days', value: Period30Days },
  { label: 'Last 90 days', value: Period90Days },
  // { label: 'Last year', value: Period1Year },
  // { label: 'All time', value: '' },
];

interface FilterBarProps {
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

export const FilterBar: React.FC<FilterBarProps> = ({ onChange }) => {
  const handlePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box display="inline-flex" mr={2} width="170px">
        <Select
          defaultValue={Period30Days}
          onChange={handlePeriodChange}
          options={ProductYieldPanelPeriods}
        />
      </Box>
      <Box display="inline-flex">
        <Typography variant={TypographyVariant.SubHeader1}>
          compare to <b>previous period</b>
        </Typography>
      </Box>
    </Box>
  );
};
