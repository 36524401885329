import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { TickStyle as DefaultTickStyle } from 'components/charts/v3/common/charts.common.style';
import { getColor } from 'styles/css-constants';

const AUMLineStroke = getColor('grey1');

const BarFillCash = getColor('brown4a');

const BarFillManagedInvestments = getColor('green2');

const CartesianGridStroke = getColor('grey5');

const RechartsContainer = styled(Box)`
  .recharts-responsive-container {
    position: absolute;
  }
`;

const TickStyle = {
  ...DefaultTickStyle,
  fill: getColor('grey4'),
  fontSize: '14px',
};

const XAxisStroke = getColor('grey5');

export const Styled = {
  AUMLineStroke,
  BarFillCash,
  BarFillManagedInvestments,
  CartesianGridStroke,
  RechartsContainer,
  TickStyle,
  XAxisStroke,
};
