import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Spinner } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { UserPermissionsRequired } from 'modules/2023-q4/rbac/components/user-permissions-required/user-permissions-required';

export const Overview = () => {
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: bankAccounts, isLoading: isLoadingBankAccounts } =
    useBankAccounts(business?.Id);

  const totalBankAccountBalance = bankAccounts?.reduce(
    (sum: number, a: { balance: number }) => sum + a.balance,
    0,
  );

  const totalBankAccountAvailableBalance = bankAccounts?.reduce(
    (sum: number, a: { available: number }) => sum + a.available,
    0,
  );

  const showBalances =
    totalBankAccountBalance > 0 || totalBankAccountAvailableBalance > 0;

  const addMoreBankAccountsButton = (
    <Link to={RoutesPath.pages.connectBankAccounts.path}>
      <Button>
        <ButtonContent iconStart={SupportedIcons.Add}>
          Add more bank accounts
        </ButtonContent>
      </Button>
    </Link>
  );

  if (isLoadingBusiness || isLoadingBankAccounts) {
    return <Spinner />;
  }

  return (
    <Grid container spacing={3}>
      {showBalances && (
        <>
          <Grid item xs={12} sm>
            <Typography variant={TypographyVariant.Body}>
              Total Balance of Bank Accounts
            </Typography>

            <Typography variant={TypographyVariant.LargeHeader}>
              <Currency
                number={totalBankAccountBalance}
                variant={CurrencyVariant.Full}
              />
            </Typography>
          </Grid>

          <Grid item xs={12} sm>
            <Typography variant={TypographyVariant.Body}>
              Available Balance of Bank Accounts
            </Typography>

            <Typography variant={TypographyVariant.LargeHeader}>
              <Currency
                number={totalBankAccountAvailableBalance}
                variant={CurrencyVariant.Full}
              />
            </Typography>
          </Grid>
          <MobileView>
            <Grid item sm={1}>
              <Divider orientation="vertical" />
            </Grid>
          </MobileView>
        </>
      )}

      <Grid item xs={12} sm>
        <Box mb={1}>
          <Typography variant={TypographyVariant.HeaderRegular}>
            {bankAccounts?.length} Connected Bank Account
            {bankAccounts?.length > 1 ? 's' : ''}
          </Typography>
        </Box>

        <FeatureFlag
          disabled={<>{addMoreBankAccountsButton}</>}
          enabled={
            <UserPermissionsRequired roles={[UserRole.ADMIN]}>
              {addMoreBankAccountsButton}
            </UserPermissionsRequired>
          }
          flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
        />
      </Grid>
    </Grid>
  );
};
