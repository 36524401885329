import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Styled } from './product-label.style';

export enum ProductLabelVariant {
  Horizontal,
  Vertical,
}

interface ProductLabelProps {
  color?: string;

  helperText?: string;

  icon?: React.ReactElement;

  name: string;

  variant?: ProductLabelVariant;
}

export const ProductLabel: React.FC<ProductLabelProps> = ({
  color,
  helperText,
  icon,
  name,
  variant,
}) => {
  const verticalIconHeight = 20;
  const verticalIconMarginBottom = 0.75;

  return (
    <Grid
      alignItems="center"
      container
      direction={variant === ProductLabelVariant.Vertical ? 'column' : 'row'}
    >
      {icon && (
        <Styled.FirstGridItem item>
          <Box
            height={verticalIconHeight}
            mb={
              variant === ProductLabelVariant.Vertical
                ? verticalIconMarginBottom
                : 0
            }
          >
            {icon}
          </Box>
        </Styled.FirstGridItem>
      )}

      {color && (
        <Styled.FirstGridItem item>
          <Box
            height={
              variant === ProductLabelVariant.Vertical
                ? verticalIconHeight
                : 'auto'
            }
            mr={variant === ProductLabelVariant.Vertical ? 0 : 1.5}
            mb={
              variant === ProductLabelVariant.Vertical
                ? verticalIconMarginBottom
                : 0
            }
          >
            <Styled.ProductLegend
              bgcolor={color}
              borderRadius="100%"
              width={12}
              height={12}
            />
          </Box>
        </Styled.FirstGridItem>
      )}

      <Grid item>
        <Typography variant={TypographyVariant.Paragraph2}>{name}</Typography>
      </Grid>

      {helperText && (
        <Grid item>
          <Box textAlign="center">
            <Typography color="grey4" variant={TypographyVariant.Paragraph3}>
              {helperText}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
