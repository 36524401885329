import React from 'react';
import { useTranslation } from 'app/i18n';
import { i18nNamespaceMFAContent } from 'modules/2023-q3/components/mfa/mfa';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ProgressSpinner } from 'modules/2023-q3/components/progress-spinner/progress-spinner';

export const MFAResendingCode: React.FC = () => {
  const { t } = useTranslation(i18nNamespaceMFAContent);

  return (
    <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
      <Grid container justifyContent="center">
        <Grid item>{t("We're resending now")}</Grid>

        <Grid item>
          <Box display="inline-block" ml={1}>
            <ProgressSpinner />
          </Box>
        </Grid>
      </Grid>
    </Typography>
  );
};
