import React from 'react';
import {
  i18nNamespace,
  IDepositWithdrawal,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import NumberFormat from 'react-number-format';
import { Box, FormControl, TextField } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { InputLabel } from 'modules/2023-q3/components/forms/components/input-label/input-label';
import { Styled } from 'modules/2023-q3/deposit-withdrawal/pages/form/components/input-amount/input-amount.style';
import { useTracking, TrackEventName } from 'modules/tracking';

export interface IInputAmount extends IDepositWithdrawal {
  amount: number | undefined;

  isOverMaxAmount?: boolean;

  setAmount: (amount: number | undefined) => void;
}

const CustomTextField = ({ ...props }) => {
  return <TextField {...props} inputProps={{ inputMode: 'decimal' }} />;
};

export const InputAmount: React.FC<IInputAmount> = ({
  amount,
  isOverMaxAmount,
  setAmount,
  variant,
}) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation(i18nNamespace);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;
  const depositOrWithdrawal = t(isDeposit ? 'deposit' : 'withdrawal');

  const label = t('Amount');

  const handleChange = (amount: number | undefined, isEvent: boolean) => {
    setAmount(amount);

    // isEvent is true when the user changes the input
    if (isEvent) {
      trackEvent({
        amount,
        eventName: TrackEventName.Input,
        isOverMaxAmount,
        label,
        variant: DepositWithdrawalVariant[variant],
      });
    }
  };

  return (
    <FormControl fullWidth>
      <Box mb={1.5}>
        <InputLabel disableAnimation htmlFor="amount" shrink>
          <Typography color="black19" variant={TypographyVariant.Header5}>
            {label}
          </Typography>
        </InputLabel>
      </Box>

      <Styled.NumberFormatContainer isOverMaxAmount={isOverMaxAmount}>
        <NumberFormat
          allowNegative={false}
          autoFocus
          customInput={CustomTextField}
          decimalScale={2}
          fixedDecimalScale
          fullWidth
          id="amount"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <Styled.InputAdornment
                isOverMaxAmount={isOverMaxAmount}
                placeholderShown={amount === undefined}
                position="start"
              >
                $
              </Styled.InputAdornment>
            ),
          }}
          placeholder={t('Enter an amount', { depositOrWithdrawal })}
          thousandSeparator
          onValueChange={(values, sourceInfo) => {
            const isEvent = sourceInfo.source === 'event';

            handleChange(values.floatValue, isEvent);
          }}
          value={amount}
        />
      </Styled.NumberFormatContainer>
    </FormControl>
  );
};
