// According to IRI-1329 investigation and discussion with investments,
// we can differentiate MMFs and mutual funds based on their ticker
// (MMF ticker ends with "XX", mutual fund ticker ends with "X")
// PR here: https://github.com/TreasureAI/retool-apps/commit/5f8c0f2611f58beb76c0a95d28e06b3ecfc8305f
// Same logic as custodian https://github.com/TreasureAI/custodian/pull/753

// https://treasure-tech.atlassian.net/jira/software/c/projects/IRI/issues/IRI-2001
// Managed Income moved to 100% VUSXX (which is a Money Market Fund)
// managedIncomeOverrideTickers wil allow this fund to be included with Managed Income
// and not Managed Money Market

const etfTickers = [
  'VTIP',
  'FTSL',
  'BSJP',
  'IBHD',
  'SRLN',
  'BKLN',
  'FPE',
  'CWB',
  'VRP',
];

const managedIncomeOverrideTickers = ['VUSXX'];

const isMoneyMarketFund = (ticker: string) => {
  return /XX$/.test(ticker);
};

const isMutualFund = (ticker: string) => {
  return !isMoneyMarketFund(ticker) && /X$/.test(ticker);
};

export const isManagedIncome = (ticker: string) => {
  if (
    etfTickers.includes(ticker) ||
    managedIncomeOverrideTickers.includes(ticker)
  ) {
    return true;
  }

  return isMutualFund(ticker);
};

export const isManagedMoneyMarket = (ticker: string) => {
  if (managedIncomeOverrideTickers.includes(ticker)) {
    return false;
  }

  return isMoneyMarketFund(ticker);
};

export const treasuryBillTickerStartsWith = 'US T Bill';
export const treasuryTIPSTickerStartsWith = 'TIPS';
