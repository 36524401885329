import React from 'react';
import styled from 'styled-components';
import { Currency as CurrencyAlias } from 'components/core';

export const Currency = styled(CurrencyAlias)`
  display: inline;
`;

export const Styled = {
  Currency,
};
