import React from 'react';
import { BoxProps, Grid } from '@material-ui/core';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconExclamationCircleOrange } from 'assets/icons/checkmarks/exclamation-circle-orange.svg';
import { Styled } from './notification-container.style';

export const NotificationContainer: React.FC<BoxProps> = ({
  children,
  ...rest
}) => {
  return (
    <MobileView
      mobile={
        <Styled.Container px={2} py={1} {...rest}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <SVGBox
                mr={1}
                position="relative"
                textAlign="center"
                top={1.5}
                width={25}
              >
                <IconExclamationCircleOrange />
              </SVGBox>
            </Grid>

            <Grid item>{children}</Grid>
          </Grid>
        </Styled.Container>
      }
    >
      <Styled.Container px={2} py={1} {...rest}>
        <Grid container alignItems="center">
          <Grid item>
            <SVGBox
              mr={1}
              position="relative"
              textAlign="center"
              top={1.5}
              width={25}
            >
              <IconExclamationCircleOrange />
            </SVGBox>
          </Grid>

          <Grid item xs>
            {children}
          </Grid>
        </Grid>
      </Styled.Container>
    </MobileView>
  );
};
