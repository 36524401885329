import React from 'react';
import PropTypes from 'prop-types';
import { TypographyVariant } from 'enums';
import { Styled } from './typography.style';

export interface TypographyProps {
  capitalize?: boolean;

  /** content of the text */
  children: React.ReactNode;

  /** used by styled-compoents to extend styling */
  className?: string;

  /** color of the text */
  color?: string;

  noWrap?: boolean;

  /** overwrite design system default element */
  semanticElement?: string;

  uppercase?: boolean;

  /** properties (font size, weight, text transform) of the text */
  variant: TypographyVariant;
}

const Typography: React.FC<TypographyProps> = ({
  capitalize,
  children,
  className,
  color,
  noWrap,
  semanticElement,
  uppercase,
  variant,
}) => {
  return (
    <Styled.Typography
      capitalize={capitalize}
      className={className}
      noWrap={noWrap}
      semanticElement={semanticElement}
      styledColor={color}
      uppercase={uppercase}
      variant={variant}
    >
      {children}
    </Styled.Typography>
  );
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  semanticElement: PropTypes.string,
  variant: PropTypes.oneOf([...Object.values(TypographyVariant)]).isRequired,
};

export { Typography, TypographyVariant };
