import React from 'react';
import { Box } from '@material-ui/core';
import { DashboardContainer } from 'layouts/v3-portal-layout/components';
import { WelcomeModal } from 'pages/v3-portal/dashboard/components';
import {
  Overview,
  Returns,
  Transactions,
} from 'modules/dashboard/treasure-reserve';
import { PortfolioTransition } from 'modules/dashboard/treasure-reserve/portfolio-transition/portfolio-transition';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { DashboardTransition } from 'modules/dashboard/dashboard-transition/dashboard-transition';
import { GrasshopperSunset } from 'modules/2024-q3/grasshopper-sunset/grasshopper-sunset';

const TreasureReserveDashboard: React.FC = () => {
  return (
    <DashboardContainer>
      <GrasshopperSunset />

      <Box mt={5} mb={10}>
        <Overview />
      </Box>

      <Box mb={10}>
        <PortfolioTransition />
      </Box>

      <Box mb={10}>
        <Returns />
      </Box>

      <Transactions />

      <WelcomeModal />
    </DashboardContainer>
  );
};

export const Dashboard: React.FC = () => {
  return (
    <FeatureFlag
      disabled={<DashboardTransition />}
      enabled={<TreasureReserveDashboard />}
      flag={FeatureFlags.REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED}
    />
  );
};
