import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

// product amounts are returned from the api as cents
const convertCentsToDollars = (object: { [index: string]: number }) => {
  const returnObject = { ...object };

  Object.keys(returnObject).forEach((key) => {
    returnObject[key] /= 100;
  });

  return returnObject;
};

export const usePortfolios = (businessId: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery(
    [ReactQueryKeys.Portfolios, businessId],
    async () => {
      const response = await treasureApi({ businessId }).get(
        `/business/${businessId}/portfolios`,
      );

      // TODO: this should be done in api-portal-gateway
      response.data.amountsInDollars = {
        totalAmount: response.data.totalAmount / 100,
        totalBlendedRateAmount: response.data.totalBlendedRateAmount / 100,
        productAmounts: convertCentsToDollars(response.data.productAmounts),
      };

      return response.data;
    },
    {
      enabled: !!businessId,
    },
  );
};
