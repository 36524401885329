import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import {
  SelectProps as MuiSelectProps,
  InputAdornment,
  InputProps,
  IconButton,
} from '@material-ui/core'; // TODO: this is a hack to mimic Selects for now
import { Heading, Text } from 'components/core';
import { ReactComponent as MultipleSelectAdornment } from 'assets/icons/multiple-select-adornment.svg';
import { ReactComponent as SelectAdornment } from 'assets/icons/select-adornment.svg';
import { Styled } from './autocomplete.style';
import { FormControl } from '../common/form-control';

interface SelectOption {
  label: string;
  description?: string;
  value: string;
}

export enum AutocompleteVariants {
  bankAccounts = 'bankAccounts',
  multipleBankAccounts = 'multipleBankAccounts',
}

export interface AutocompleteProps {
  disabled?: boolean;

  disableClearable?: boolean;

  helperText?: string;

  options?: SelectOption[];

  optional?: boolean;

  onChange?: any;

  value?: string;

  leftLabel?: boolean;

  labelVariant?: number;

  autocompleteVariant?: AutocompleteVariants;

  // an array of select option values that should be disabled
  disabledOptions?: any[];
}

export const Autocomplete: React.FC<AutocompleteProps & MuiSelectProps> = ({
  autocompleteVariant,
  disabled,
  disableClearable,
  disabledOptions,
  error,
  helperText,
  label,
  labelVariant,
  leftLabel,
  name,
  onChange,
  optional,
  options,
  placeholder,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(
    options?.filter((option) => option.value === value)[0],
  );
  const [hasCustomAdornment, setHasCustomAdornment] = useState(false);

  const handleOnChange = (e: any, data: any) => {
    setInputValue(data);

    // TODO: another hack to mimic our existing Select behavior
    const fakeEvent = {
      target: {
        value: data.value,
      },
    };

    onChange(fakeEvent);
  };

  const muiInputProps: InputProps = {};

  // Render the text input for the MUI autocomplete component
  // as well as any special adornments for the particular
  // variant specified.
  const renderInput = (params: any) => {
    let inputDisplay = (
      <Styled.TextField
        error={error}
        placeholder={placeholder}
        variant="outlined"
        {...params}
      />
    );

    switch (autocompleteVariant) {
      case AutocompleteVariants.multipleBankAccounts:
        setHasCustomAdornment(true);

        muiInputProps.endAdornment = (
          <InputAdornment
            position="end"
            onClick={() => {
              if (!disabled) {
                setOpen(!open);
              }
            }}
          >
            <IconButton size="small" disableRipple>
              <MultipleSelectAdornment />
            </IconButton>
          </InputAdornment>
        );

        inputDisplay = (
          <Styled.TextField
            error={error}
            placeholder={placeholder}
            variant="outlined"
            {...params}
            InputProps={{
              ...params.InputProps,
              ...muiInputProps,
            }}
          />
        );
        break;
      case AutocompleteVariants.bankAccounts:
        setHasCustomAdornment(true);

        muiInputProps.endAdornment = (
          <InputAdornment
            position="end"
            onClick={() => {
              if (!disabled) {
                setOpen(!open);
              }
            }}
          >
            <IconButton size="small" disableRipple>
              <SelectAdornment />
            </IconButton>
          </InputAdornment>
        );

        inputDisplay = (
          <Styled.TextField
            error={error}
            placeholder={placeholder}
            variant="outlined"
            {...params}
            InputProps={{
              ...params.InputProps,
              ...muiInputProps,
            }}
          />
        );
        break;
      default:
        break;
    }

    return inputDisplay;
  };

  // Render the dropdown option for the MUI autocomplete component
  // for the particular variant specified.
  const renderOption = (option: SelectOption) => {
    let optionDisplay = <div>{option.label}</div>;

    switch (autocompleteVariant) {
      case AutocompleteVariants.bankAccounts:
      case AutocompleteVariants.multipleBankAccounts:
        optionDisplay = (
          <Styled.AutocompleteOption
            className={AutocompleteVariants.bankAccounts}
          >
            <div>
              <Heading variant={3} className="heading">
                {option.label}
              </Heading>
              <Text variant={3}>{option.description}</Text>
            </div>
          </Styled.AutocompleteOption>
        );
        break;
      default:
        break;
    }

    return optionDisplay;
  };

  return (
    <FormControl
      error={error}
      helperText={helperText}
      label={label}
      labelVariant={labelVariant}
      leftLabel={leftLabel}
      optional={optional}
    >
      <Styled.AutocompleteWrapper>
        <MuiAutocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disabled={disabled}
          disablePortal
          getOptionDisabled={
            disabledOptions &&
            ((option: SelectOption) => disabledOptions.includes(option.value))
          }
          disableClearable={disableClearable}
          forcePopupIcon={!hasCustomAdornment}
          options={options ?? []}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => renderInput({ ...params, name })}
          renderOption={(option) => renderOption(option)}
          onChange={handleOnChange}
          value={inputValue}
        />
      </Styled.AutocompleteWrapper>
    </FormControl>
  );
};

Autocomplete.propTypes = {
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  leftLabel: PropTypes.bool,
  labelVariant: PropTypes.number,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  autocompleteVariant: PropTypes.oneOf<AutocompleteVariants>([
    AutocompleteVariants.bankAccounts,
    AutocompleteVariants.multipleBankAccounts,
  ]),
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
};
