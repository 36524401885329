import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

export interface MaskProps {
  /** the mask to display */
  mask: ReactNode;

  /** variant */
  variant?: string;
}

const Mask: React.FC<MaskProps> = ({ mask, variant }) => {
  if (variant === 'small') {
    return <>{`··${mask}`}</>;
  }
  return <>······ {mask}</>;
};

Mask.propTypes = {
  mask: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export { Mask };
