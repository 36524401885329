import styled from 'styled-components';
import { MobileStepper } from '@material-ui/core';

const StepDots = styled(MobileStepper)`
  background-color: var(--color-white);
  padding: 0;

  .MuiMobileStepper-dot:not(:last-child) {
    margin-right: var(--spacing-01);
  }

  .MuiMobileStepper-dotActive {
    background-color: var(--color-nero);
  }
`;

export const Styled = {
  StepDots,
};
