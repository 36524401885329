import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

interface PortfolioAllocation {
  amount: number;
  percentage: number;
}

export interface MutatePortfolioRequest {
  cash: PortfolioAllocation;
  smart: PortfolioAllocation;
  tbills: PortfolioAllocation;
  moneyMarket: PortfolioAllocation;
}

export const useMutatePortfolio = (): UseMutationResult<any, any, any> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const postPortfolio = async ({
    businessId,
    portfolio,
  }: {
    businessId: string;
    portfolio: MutatePortfolioRequest;
  }) => {
    const endpoint = '/business/portfolio';

    const response = await treasureApi().post(endpoint, portfolio);

    return response.data;
  };

  return useMutation(postPortfolio);
};
