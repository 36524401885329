import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, ListItem } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { LineChartOneMonth } from 'components/charts/v3';
import { CashDirection } from 'enums';
import { DateAndValue } from 'types';
import { Styled } from './month-to-date-card.style';

interface MonthToDateCardProps {
  data: {
    currentMonthDaily: DateAndValue[];
    periodOverPeriodDaily: DateAndValue[];
    previousMonthDaily: DateAndValue[];
    previousMonthTotal: number;
    thisMonthPreviousYearTotal: number;
    thisMonthTotal: number;
  };

  variant: CashDirection;
}

const MonthToDateCard: React.FC<MonthToDateCardProps> = ({ data, variant }) => {
  const legendData = [
    {
      label: 'This month',
      legendColor: 'stromboli',
      value: data.thisMonthTotal,
    },
    {
      label: 'Last month',
      legendColor:
        variant === CashDirection.Inflows ? 'royalBlue' : 'darkPurple',
      value: data.previousMonthTotal,
    },
    {
      label: 'Last year',
      legendColor: 'silverChalice',
      value: data.thisMonthPreviousYearTotal,
    },
  ];

  return (
    <Styled.FullHeightGrid container>
      <Grid item xs={12} md={9}>
        <Box pt={2} pl={2}>
          <Typography color="nero" variant={TypographyVariant.SubHeader1}>
            {`Cash ${
              variant === CashDirection.Inflows ? 'Inflows' : 'Outflows'
            }`}
          </Typography>
        </Box>

        <LineChartOneMonth data={data} variant={variant} />
      </Grid>

      <Grid item xs={12} md={3}>
        <Box mt={3} ml={2}>
          <Styled.List>
            {legendData.map(function (legend, index) {
              if (!legend.label || Number.isNaN(legend.value)) {
                return null;
              }

              // TODO: add copy explaning why data is missing
              if (legend.label === 'Last year' && legend.value === 0) {
                return null;
              }

              return (
                <ListItem key={index.toString()}>
                  <Grid container>
                    <Grid item>
                      <Box mr={1}>
                        <Styled.LegendSquare color={legend.legendColor} />
                      </Box>
                    </Grid>

                    <Grid item>
                      <Typography
                        color="gothic"
                        variant={TypographyVariant.SmallLabel2}
                      >
                        {legend.label}
                      </Typography>

                      <Typography
                        color="gothic"
                        variant={TypographyVariant.SubHeader2}
                      >
                        <Currency
                          number={legend.value}
                          variant={CurrencyVariant.Truncated}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </Styled.List>
        </Box>
      </Grid>
    </Styled.FullHeightGrid>
  );
};

MonthToDateCard.propTypes = {
  data: PropTypes.shape({
    currentMonthDaily: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    periodOverPeriodDaily: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    previousMonthDaily: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    previousMonthTotal: PropTypes.number.isRequired,
    thisMonthPreviousYearTotal: PropTypes.number.isRequired,
    thisMonthTotal: PropTypes.number.isRequired,
  }).isRequired,
};

export { MonthToDateCard };
