import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { TreasureLogoHorizontal } from 'assets';
import { GlobalServiceIssueNotification } from 'modules/page-errors/global-service-issue-notification/global-service-issue-notification';
import { HeaderOptions } from './components';
import { Styled } from './header.style';

export const Header: React.FC = () => {
  return (
    <Styled.BackgroundColor>
      <Box p={3.5}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <img
              src={TreasureLogoHorizontal}
              alt="Treasure logo"
              width={200}
              height={30}
            />
          </Grid>

          <Grid item xs>
            <GlobalServiceIssueNotification />
          </Grid>

          <Grid item>
            <HeaderOptions />
          </Grid>
        </Grid>
      </Box>

      <Styled.Divider />
    </Styled.BackgroundColor>
  );
};
