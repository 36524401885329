import React, { useState } from 'react';
import { TextareaAutosizeProps } from '@material-ui/core';
import { FormControl } from '../common/form-control';
import { Styled } from './textarea.style';

interface TextareaProps extends TextareaAutosizeProps {
  error?: boolean;

  helperText?: string;

  label?: string;

  optional?: boolean;
}

export const Textarea: React.FC<TextareaProps> = ({
  defaultValue,
  error,
  helperText,
  label,
  optional,
  onChange,
  placeholder,
  ...textareaProps
}) => {
  const [text, setText] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;

    setText(value);
    onChange && onChange(e);
  };

  return (
    <FormControl
      error={error}
      helperText={helperText}
      label={label}
      optional={optional}
    >
      <Styled.Textarea
        {...textareaProps}
        $error={error}
        onChange={handleChange}
        placeholder={placeholder}
        value={text}
      />
    </FormControl>
  );
};
