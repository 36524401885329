import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ReactComponent as TreasureReserveDataIcon } from 'assets/icons/tr-data.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as CashFlowIcon } from 'assets/icons/cash-flow.svg';

const Highlights: React.FC = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="center" mb={1}>
        <Typography variant={TypographyVariant.Header}>
          Welcome to your Treasure Dashboard
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="column" mx={4}>
        <Box display="flex" my={1}>
          <Box display="flex" alignItems="center">
            <TreasureReserveDataIcon />
          </Box>
          <Box display="flex" alignItems="center" p={2} pl={4}>
            <Typography variant={TypographyVariant.SubHeader2}>
              View your Treasure balance and earnings performance
            </Typography>
          </Box>
        </Box>
        <Box display="flex" mb={1}>
          <Box display="flex" alignItems="center">
            <LinkIcon />
          </Box>
          <Box display="flex" alignItems="center" p={2} pl={4}>
            <Typography variant={TypographyVariant.SubHeader2}>
              Connect and view all your operation accounts
            </Typography>
          </Box>
        </Box>
        <Box display="flex" mb={1}>
          <Box display="flex" alignItems="center">
            <CashFlowIcon />
          </Box>
          <Box display="flex" alignItems="center" p={2} pl={4}>
            <Typography variant={TypographyVariant.SubHeader2}>
              View Cash flow trends for your connected accounts
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { Highlights };
