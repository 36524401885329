import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Bullet } from 'modules/2023-q4/growth-promo/components/growth-promo-drawer/components/growth-promo-details/components/bullet/bullet';

export const GrowthPromoSignUp = () => {
  return (
    <>
      <Box mb={2}>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Ending Dec 15, 2023
        </Typography>
      </Box>

      <Typography color="black19" variant={TypographyVariant.Header3}>
        Get 50% Off Investment Management Fees For a Full Year When You Deposit
        $2M+
      </Typography>

      <Box my={3}>
        <Box mb={1}>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            Take control of your business&apos;s financial destiny! Join
            Treasure and deposit a minimum of $2 million before December 15th to
            unlock your 50% discount.*
          </Typography>
        </Box>

        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          * 50% discount applies to funds deposited between now and December
          15th, 2023.
        </Typography>
      </Box>

      <Divider />

      <Box my={3}>
        <Typography color="black19" variant={TypographyVariant.Header4}>
          How It Works
        </Typography>
      </Box>

      <Box mb={2}>
        <Bullet color="green1" number={1}>
          Create a Treasure Account
        </Bullet>
      </Box>

      <Box mb={2}>
        <Bullet color="green1" number={2}>
          Deposit Funds
        </Bullet>
      </Box>

      <Box mb={2}>
        <Bullet color="green1" number={3}>
          Get Rewarded
        </Bullet>
      </Box>

      <Box mt={4}>
        <Typography color="grey1" variant={TypographyVariant.Paragraph3}>
          See offer{' '}
          <a
            href="//www.treasurefi.com/promotions/50-percent-off-investment-management-fees"
            rel="noreferrer"
            target="_blank"
          >
            <Typography color="blue2" variant={TypographyVariant.Paragraph3}>
              Terms & Conditions
            </Typography>
          </a>{' '}
          for full details.
        </Typography>
      </Box>
    </>
  );
};
