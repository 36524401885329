import React from 'react';
import { Button } from 'components/core';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { PageErrorMessage } from 'modules/page-errors/page-error-message';

export const PageNotFound: React.FC = () => {
  return (
    <PageErrorMessage
      errorAction={
        <Link to={RoutesPath.pages.home.path}>
          <Button>Back to Home</Button>
        </Link>
      }
      errorMessage="Oops! We can’t find that page"
    />
  );
};
