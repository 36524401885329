import styled from 'styled-components';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const Icon = styled(PriorityHighIcon)``;

const NotificaitonBox = styled.div`
  border-radius: var(--border-radius-08);
  padding: var(--spacing-01);
`;

const NotificationBoxInformation = styled(NotificaitonBox)`
  background-color: var(--color-grannyApple);

  ${Icon} {
    color: var(--color-mountainMeadow);
  }
`;

const NotificationBoxWarning = styled(NotificaitonBox)`
  background-color: var(--color-newOrleans);

  ${Icon} {
    color: var(--color-jaffa);
  }
`;

export const Styled = {
  Icon,
  NotificationBoxInformation,
  NotificationBoxWarning,
};
