import React from 'react';
import { WhiteLabelLogoVariant } from 'modules/2023-q4/white-label/components/white-label-logo/white-label-logo';
import { TreasureLogo, TreasureLogoHorizontal } from 'assets';
import { ReactComponent as TreasureLogoWithText } from 'assets/logos/horizontal/treasure_logo_green.svg';
import { ReactComponent as TreasureLogoSymbol } from 'assets/logos/symbol/treasure_logo_green.svg';

interface IDefaultTreasureLogos {
  variant: WhiteLabelLogoVariant;
}

export const DefaultTreasureLogos: React.FC<IDefaultTreasureLogos> = ({
  variant,
}) => {
  switch (variant) {
    case WhiteLabelLogoVariant.Header:
      return <TreasureLogoWithText />;

    case WhiteLabelLogoVariant.HeaderMobile:
      return <TreasureLogoSymbol />;

    case WhiteLabelLogoVariant.TaskHeader:
      return <TreasureLogo />;

    default:
      return (
        <img
          alt="Treasure logo"
          src={TreasureLogoHorizontal}
          width={158}
          height={25}
        />
      );
  }
};
