import styled from 'styled-components';
import { getColor } from 'styles/css-constants';

const BackgroundColor = styled.div`
  background-color: var(--color-whiteSmoke3);

  @media print {
    display: none;
  }
`;

const Divider = styled.hr`
  border-top: 1px solid ${getColor('mischka')};
`;

export const Styled = {
  BackgroundColor,
  Divider,
};
