import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { useStoreAuthentication } from 'stores';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Styled } from './mobile-menu.style';

export const MobileMenu: React.FC = () => {
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);
  const { signOut } = useSignOut();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsMenuOpen(open);
  };

  const renderLoggedInItems = () => {
    return (
      <>
        <NavLink
          to={getVersionedPath({
            path: RoutesPath.pages.home.path,
            version: 3,
          })}
        >
          <Styled.ListItem button>Treasure Reserve</Styled.ListItem>
        </NavLink>

        <NavLink
          to={getVersionedPath({
            path: RoutesPath.pages.banking.path,
            version: 3,
          })}
        >
          <Styled.ListItem button>Banking</Styled.ListItem>
        </NavLink>

        <FeatureFlag
          enabled={
            <NavLink
              to={getVersionedPath({
                path: RoutesPath.pages.insights.path,
                version: 3,
              })}
            >
              <Styled.ListItem button>Insights</Styled.ListItem>
            </NavLink>
          }
          flag={FeatureFlags.REACT_APP_2023_Q3_DISPLAY_INSIGHTS_ENABLED}
        />

        <NavLink
          to={getVersionedPath({
            path: RoutesPath.pages.transfer.path,
            version: 3,
          })}
        >
          <Styled.ListItem button>Transfer Funds</Styled.ListItem>
        </NavLink>

        <NavLink
          to={getVersionedPath({
            path: RoutesPath.pages.settings.path,
            version: 3,
          })}
        >
          <Styled.ListItem button>Account Settings</Styled.ListItem>
        </NavLink>

        <NavLink
          to={getVersionedPath({
            path: RoutesPath.pages.documents.path,
            version: 3,
          })}
        >
          <Styled.ListItem button>Documents</Styled.ListItem>
        </NavLink>

        <Styled.ListItem button onClick={() => signOut()}>
          <div>Sign Out</div>
        </Styled.ListItem>
      </>
    );
  };

  const renderLoggedOutItems = () => {
    return (
      <NavLink to={RoutesPath.noAuth.signIn.path}>
        <Styled.ListItem button>Sign In</Styled.ListItem>
      </NavLink>
    );
  };

  return (
    <>
      <Styled.Menu
        role="button"
        aria-label="Open menu"
        onClick={toggleDrawer(true)}
      />

      <Drawer anchor="right" open={isMenuOpen} onClose={toggleDrawer(false)}>
        <Styled.CloseButtonWrapper>
          <Styled.CloseButton
            onClick={toggleDrawer(false)}
            aria-label="Close menu"
          />
        </Styled.CloseButtonWrapper>

        <Styled.List>
          {isSignedIn ? renderLoggedInItems() : renderLoggedOutItems()}
        </Styled.List>
      </Drawer>
    </>
  );
};
