import React from 'react';
import { useBusiness } from 'hooks/business/use-business';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';
import { Deposit } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/deposit/deposit';
import { DepositApexWireInstructions } from 'modules/2024-q3/grasshopper-sunset/deposit/deposit-apex-wire-instructions/deposit-apex-wire-instructions';
import { RoutesPath } from 'routes/constants/routes-path';
import { Redirect } from 'react-router-dom';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { Deposit as DepositApexACH } from 'modules/2024-q4/apex-ach/deposit-apex-ach/deposit';

export const CheckAccountStatusBeforeDeposit = () => {
  const { data: business } = useBusiness();
  const { data: treasureReserve } = useTreasureReserve(business?.Id);

  const { isFeatureFlagEnabled } = useFeatureFlags();

  // if the account is on grasshopper, display the deposit page
  if (treasureReserve?.custodian === 'GH_ON_TP') {
    return <Deposit />;
  }

  // if the account has an apex account number, display the apex wire instructions
  if (treasureReserve?.custodianAccountNumber) {
    // if Apex ACH is enabled, show the flow
    if (isFeatureFlagEnabled(FeatureFlags.REACT_APP_2024_Q4_APEX_ACH)) {
      return <DepositApexACH />;
    }

    return <DepositApexWireInstructions />;
  }

  // redirect back home
  return <Redirect to={RoutesPath.pages.home.path} />;
};
