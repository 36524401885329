import React, { useEffect, useState } from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { RoutesPath } from 'routes/constants/routes-path';
import { useLocation, Redirect } from 'react-router-dom';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { Box } from '@material-ui/core';
import { Spinner } from 'components/core';
import { useTracking, TrackEventName } from 'modules/tracking';
import { i18n, useTranslation } from 'app/i18n';
import AllocationContent from 'modules/2023-q3/allocation/content/allocation.content.json';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import { Header } from './components/header/header';
import { Form } from './pages/form/form';
import { Review } from './pages/review/review';
import { Submit } from './pages/submit/submit';
import { Confirmation } from './pages/confirmation/confirmation';
import {
  IProductAllocations,
  ProductAllocationProducts,
} from 'modules/2023-q3/allocation/interface/product-allocation';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';

export const i18nNamespace = 'allocation-content';
i18n.addResourceBundle('en', i18nNamespace, AllocationContent);

// TODO: default allocation is hardcoded, should be data driven
const defaultAllocation = {
  [ProductAllocationProducts.ManagedMoneyMarket]: {
    editing: false,
    rate: 0,
    value: 100,
  },
  [ProductAllocationProducts.ManagedTreasuries]: {
    editing: false,
    rate: 0,
    value: 0,
  },
  [ProductAllocationProducts.ManagedIncome]: {
    editing: false,
    rate: 0,
    value: 0,
  },
  [ProductAllocationProducts.TreasureCash]: {
    editing: false,
    rate: 0,
    value: 0,
  },
};

export enum AllocationSteps {
  Form,
  Review,
  Submit,
  Confirmation,
}

export const Allocation: React.FC = () => {
  const { state }: { state: any } = useLocation();
  const from = state?.from;

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolio, isLoading: isLoadingPortfolio } = usePortfolios(
    business?.Id,
  );

  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);

  const totalAmount = Number(aggregateBalances?.aum?.total?.amount);

  const getPercentage = (totalAmount: number, productAmount: number) => {
    if (Number.isNaN(totalAmount) || Number.isNaN(productAmount)) {
      return 0;
    }

    return Number(((productAmount / totalAmount) * 100).toFixed(2));
  };

  const [allocation, setAllocation] =
    useState<IProductAllocations>(defaultAllocation);
  const [initialAllocation, setInitialAllocation] =
    useState<IProductAllocations>(defaultAllocation);
  const [step, setStep] = useState(AllocationSteps.Form);

  const { Track, trackEvent } = useTracking<{
    eventName?: TrackEventName;
    step: string;
  }>({
    step: AllocationSteps[step],
  });

  const nextStep = () => {
    switch (step) {
      case AllocationSteps.Form:
        return setStep(AllocationSteps.Review);
      case AllocationSteps.Review:
        return setStep(AllocationSteps.Submit);
      case AllocationSteps.Submit:
        return setStep(AllocationSteps.Confirmation);
      default:
        return null;
    }
  };

  const previousStep = () => {
    switch (step) {
      case AllocationSteps.Review:
        return setStep(AllocationSteps.Form);
      default:
        return null;
    }
  };

  const displayStep = (step: AllocationSteps) => {
    switch (step) {
      case AllocationSteps.Review:
        return (
          <Review
            allocation={allocation}
            initialAllocation={initialAllocation}
            nextStep={nextStep}
            previousStep={previousStep}
            totalAmount={totalAmount}
          />
        );
      case AllocationSteps.Submit:
        return (
          <Submit
            allocation={allocation}
            nextStep={nextStep}
            totalAmount={totalAmount}
          />
        );
      case AllocationSteps.Confirmation:
        return <Confirmation from={from} />;
      default:
        return (
          <Form
            allocation={allocation}
            initialAllocation={initialAllocation}
            nextStep={nextStep}
            setAllocation={setAllocation}
            totalAmount={totalAmount}
          />
        );
    }
  };

  useEffect(() => {
    if (portfolio?.amountsInDollars?.productAmounts) {
      const allocation = defaultAllocation;
      const totalAmountAllocated = Number(
        portfolio?.amountsInDollars?.totalAmount,
      );

      allocation[ProductAllocationProducts.ManagedMoneyMarket].rate =
        portfolio?.rates.moneyMarketRate;
      allocation[ProductAllocationProducts.ManagedTreasuries].rate =
        portfolio?.rates.managedTreasuriesRate;
      allocation[ProductAllocationProducts.ManagedIncome].rate =
        portfolio?.rates.managedIncomeRate;
      allocation[ProductAllocationProducts.TreasureCash].rate =
        portfolio?.rates.cashRate;

      // this covers the use ase if there is a pending deposit
      // the totalAmountAllocated is money that is in a treasure account
      if (totalAmountAllocated > 0) {
        allocation[ProductAllocationProducts.ManagedMoneyMarket].value =
          getPercentage(
            totalAmount,
            portfolio?.amountsInDollars?.productAmounts.moneyMarket,
          );
        allocation[ProductAllocationProducts.ManagedTreasuries].value =
          getPercentage(
            totalAmount,
            portfolio?.amountsInDollars?.productAmounts.managedTreasuries,
          );
        allocation[ProductAllocationProducts.ManagedIncome].value =
          getPercentage(
            totalAmount,
            portfolio?.amountsInDollars?.productAmounts.managedIncome,
          );
        allocation[ProductAllocationProducts.TreasureCash].value =
          getPercentage(
            totalAmount,
            portfolio?.amountsInDollars?.productAmounts.cash,
          );
      }

      setAllocation(allocation);
      setInitialAllocation(allocation);
    }
  }, [portfolio, totalAmount]);

  useEffect(() => {
    // scroll restoration
    document.querySelector('body')?.scrollTo(0, 0);

    trackEvent({
      eventName: TrackEventName.Viewed,
      step: AllocationSteps[step],
    });
  }, [step]);

  return (
    <FeatureFlag
      disabled={<Redirect to={RoutesPath.pages.home.path} />}
      enabled={
        <>
          <Header from={from} previousStep={previousStep} step={step} />

          <CenteredContainer>
            {isLoadingBusiness || isLoadingPortfolio ? (
              <Box mt={15}>
                <Spinner />
              </Box>
            ) : (
              <MobileView mobile={<Box pb={10}>{displayStep(step)}</Box>}>
                {displayStep(step)}
              </MobileView>
            )}
          </CenteredContainer>
        </>
      }
      flag={FeatureFlags.REACT_APP_2023_Q3_ALLOCATION_ENABLED}
    />
  );
};
