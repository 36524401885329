import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Percentage, Typography, TypographyVariant } from 'components/core';
import { ProductLabel } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-table/components/product-label/product-label';

interface ProductAllocationProps {
  percentage: number;

  productName: string;

  productColor: string;
}

export const ProductAllocation: React.FC<ProductAllocationProps> = ({
  percentage,
  productName,
  productColor,
}) => {
  return (
    <>
      <Box my={2}>
        <Divider />
      </Box>

      <Grid container justifyContent="space-between">
        <Grid item>
          <ProductLabel color={productColor} name={productName} />
        </Grid>

        <Grid item>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            <Percentage decimalScale={2} number={percentage} />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
