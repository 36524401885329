import React from 'react';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { PageTitle } from '../components/page-title';
import {
  CheckboxController,
  FormFieldContainer,
  SubmitButton,
  formFields,
} from '../components/forms';

interface BusinessRepresentativeForm {
  brokerDealer: boolean;
  haveInterestedPartiesToReport: boolean;
  largeTrader: boolean;
  leaderOfPublicCompany: boolean;
  politicallyExposed: boolean;
  stockExchangeFinraMemberAssociated: boolean;
}

export const RareScenarios: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.RareScenarios,
  });

  const onboardingContext = useOnboardingContext();
  const savedOnboardingData = onboardingContext.onboardingResponse.data;
  const savedBusinessRepresentativeData = savedOnboardingData.people?.filter(
    (person: { isPersonOnboarding: boolean }) =>
      person.isPersonOnboarding === true,
  )[0];

  const { control, handleSubmit } = useForm<BusinessRepresentativeForm>();

  const onSubmit = (data: BusinessRepresentativeForm) => {
    const { haveInterestedPartiesToReport, ...personOnboarding } = data;

    onboardingContext.postOnboardingData({
      businessId: onboardingContext.businessId,
      data: {
        business: {
          haveInterestedPartiesToReport,
        },
        people: [
          {
            ...personOnboarding,
            id: savedBusinessRepresentativeData?.id,
          },
        ],
      },
    });

    trackEvent({
      eventName: TrackEventName.RareScenariosCompleted,
    });
  };

  return (
    <>
      <PageTitle
        title="Do any of these happen to apply?"
        description="If these terms are not familiar, these cases likely do not apply and you can continue."
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldContainer>
          <CheckboxController
            {...formFields.leaderOfPublicCompany}
            control={control}
            defaultValue={
              savedOnboardingData.personOnboarding?.leaderOfPublicCompany ||
              false
            }
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <CheckboxController
            {...formFields.brokerDealer}
            control={control}
            defaultValue={
              savedOnboardingData.personOnboarding?.brokerDealer || false
            }
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <CheckboxController
            {...formFields.largeTrader}
            control={control}
            defaultValue={
              savedOnboardingData.personOnboarding?.largeTrader || false
            }
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <CheckboxController
            {...formFields.stockExchangeFinraMemberAssociated}
            control={control}
            defaultValue={
              savedOnboardingData.personOnboarding
                ?.stockExchangeFinraMemberAssociated || false
            }
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <CheckboxController
            {...formFields.haveInterestedPartiesToReport}
            control={control}
            defaultValue={
              savedOnboardingData.business?.haveInterestedPartiesToReport ||
              false
            }
          />
        </FormFieldContainer>

        <FormFieldContainer>
          <CheckboxController
            {...formFields.politicallyExposed}
            control={control}
            defaultValue={
              savedOnboardingData.personOnboarding?.politicallyExposed || false
            }
          />
        </FormFieldContainer>

        <SubmitButton />
      </form>
    </>
  );
};
