import React, { useRef, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import {
  factoryFormFieldComponent,
  FormFieldSpecs,
  Validations,
} from 'modules/form-helpers/utils/factory-form-field-component';
import { Styled } from './password-field.style';

import {
  numberRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from '../password-validations';

export const validatePasswordString = (password: string): string | boolean => {
  if (!password.match(numberRegex)) {
    return 'One number required';
  }

  if (!password.match(specialCharacterRegex)) {
    return 'One special character required';
  }

  if (!password.match(upperCaseRegex)) {
    return 'One upper-case letter required';
  }

  return true;
};

export type PasswordFormFieldSpecs = FormFieldSpecs;

const BasePasswordFieldController =
  factoryFormFieldComponent<PasswordFormFieldSpecs>(
    ({ label, value, onBlur, onChange, error }) => {
      const passwordRef = useRef<HTMLInputElement>();
      const [showPassword, setShowPassword] = useState(false);

      const handleClickShowPassword = () => {
        setShowPassword(!showPassword);

        if (passwordRef.current) {
          // TODO: move the focus to the end of the input
          passwordRef.current.focus();
        }
      };
      return (
        <Styled.PasswordField
          value={value}
          type={showPassword ? 'text' : 'password'}
          onBlur={onBlur}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
          inputRef={passwordRef}
          label={label}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Styled.IconButton
                  aria-label="toggle password visibility"
                  disableRipple
                  onClick={handleClickShowPassword}
                  tabIndex="-1"
                >
                  <Styled.IconVisibility />
                </Styled.IconButton>
              </InputAdornment>
            ),
          }}
        />
      );
    },
    {
      componentName: 'PasswordFieldController',
    },
  );

export const PasswordFieldController: typeof BasePasswordFieldController = ({
  validations,
  ...otherProps
}) => {
  const defaultValidations: Validations = {
    required: true,
    minLength: 8,
    validate: validatePasswordString,
  };

  const mergedValidations = { ...defaultValidations, ...validations };

  return (
    <BasePasswordFieldController
      {...otherProps}
      validations={mergedValidations}
    />
  );
};

PasswordFieldController.propTypes = BasePasswordFieldController.propTypes;
