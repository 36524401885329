import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { ReactQueryMutationKeys } from 'enums';
import { getUserAgent } from 'modules/tracking/utils/get-user-agent';

const userAgentInformation = getUserAgent();

export const useRememberDevice = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Authentication);

  const rememberDevice = async ({ token }: { token: string }) => {
    const response = await treasureApi().post('/user/remember-device', {
      token,
      user_agent: userAgentInformation,
    });

    return response;
  };

  return useMutation(rememberDevice, {
    mutationKey: ReactQueryMutationKeys.RememberDevice, // this mutation key is used by react-query-error-toasts so it will never show a user an error
  });
};
