import React from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import {
  Label,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
} from 'recharts';
import { Currency, CurrencyVariant } from 'components/core';
import { CashDirection } from 'enums';
import { DateAndValue } from 'types';
import { dayjs } from 'app/dayjs';
import { Styled } from './line-chart-one-month.style';

enum chartDataTypes {
  currentMonthDaily = 'currentMonthDaily',
  periodOverPeriodDaily = 'periodOverPeriodDaily',
  previousMonthDaily = 'previousMonthDaily',
}

interface LineChartOneMonthData {
  currentMonthDaily: DateAndValue[];
  periodOverPeriodDaily: DateAndValue[];
  previousMonthDaily: DateAndValue[];
}

interface LineChartOneMonthProps {
  data: LineChartOneMonthData;

  variant: CashDirection;
}

const CustomReferenceArea = (props: {
  stroke?: string;
  strokeDasharray?: string;
  x?: number;
  y?: number;
  y2?: number;
}) => {
  return (
    <line
      stroke={props.stroke}
      strokeDasharray={props.strokeDasharray}
      x1={props.x}
      x2={props.x}
      y1={props.y}
      y2={props.y2}
    />
  );
};

// TODO: move to shared folder
const moneyFormatter = (value: number) => {
  return renderToString(
    <Currency
      number={value}
      renderText={(value) => <>{value}</>}
      variant={CurrencyVariant.Truncated}
    />, // TODO: is this the best approach?
  );
};

const LineChartOneMonth: React.FC<LineChartOneMonthProps> = ({
  data,
  variant,
}) => {
  const chartPoints: { maxValue: number; today: string; todayValue: number } = {
    maxValue: 0,
    today: new Date().toISOString(),
    todayValue: 0,
  };

  const getDate = (date: string) => {
    return dayjs(date).utc().date();
  };

  const processChartData = (data: LineChartOneMonthData) => {
    const chartArrays = [
      chartDataTypes.currentMonthDaily,
      chartDataTypes.previousMonthDaily,
      chartDataTypes.periodOverPeriodDaily,
    ];
    const chartData: {
      date: string;
      currentMonthDaily?: number;
      previousMonthDaily?: number;
      periodOverPeriodDaily?: number;
    }[] = [];
    const today = new Date().toISOString();
    let key: keyof typeof data;

    const addArrayToChartData = (array: DateAndValue[]) => {
      let currentValue = 0;

      array.sort((a, b) => (a.date > b.date ? 1 : -1));

      array.forEach(function (entry) {
        currentValue += entry.value;

        const dateExists = chartData.find(
          (x) => getDate(x.date) === getDate(entry.date),
        );

        if (dateExists) {
          dateExists[key] = currentValue;
        } else {
          chartData.push({ date: entry.date, [key]: currentValue });
        }

        if (
          key === chartDataTypes.currentMonthDaily &&
          getDate(today) === getDate(entry.date)
        ) {
          chartPoints.today = entry.date;
          chartPoints.todayValue = currentValue;
        }

        if (currentValue > chartPoints.maxValue) {
          chartPoints.maxValue = currentValue;
        }
      });
    };

    for (key in data) {
      if (chartArrays.includes(key as chartDataTypes)) {
        addArrayToChartData(data[key]);
      }
    }

    return chartData;
  };

  return (
    <ResponsiveContainer height={Styled.ChartHeight} width="100%">
      <LineChart
        data={processChartData(data)}
        margin={{ top: 15, left: 0, right: 0, bottom: 0 }}
      >
        <XAxis dataKey="date" axisLine={false} tick={false}>
          <Label
            value="Start"
            position="insideLeft"
            stroke={Styled.ReferenceTextStroke}
            fontSize={10}
            fontWeight={200}
          />
          <Label
            value="End"
            position="insideRight"
            stroke={Styled.ReferenceTextStroke}
            fontSize={10}
            fontWeight={200}
          />
        </XAxis>

        {/* Today */}
        <ReferenceDot
          x={chartPoints.today}
          y={chartPoints.todayValue}
          stroke="none"
          fill="none"
        >
          <Label
            value="Today"
            position="top"
            offset={-5}
            stroke={Styled.ReferenceTextStroke}
            fontSize={10}
            fontWeight={200}
          />
        </ReferenceDot>

        {/* Today Vertial */}
        <ReferenceArea
          x1={chartPoints.today}
          y1={0}
          y2={chartPoints.todayValue}
          fill="none"
          stroke={Styled.ReferenceLineStroke}
          strokeDasharray="5 5"
          shape={<CustomReferenceArea />}
        />

        {/* Today Horizontal */}
        <ReferenceLine
          y={chartPoints.todayValue}
          stroke={Styled.ReferenceLineStroke}
          strokeDasharray="5 5"
        >
          <Label
            value={moneyFormatter(chartPoints.todayValue)}
            position="insideBottomLeft"
            stroke={Styled.ReferenceTextStroke}
            fontSize={10}
            fontWeight={200}
          />
        </ReferenceLine>

        {/* Max Horizontal */}
        <ReferenceLine
          y={chartPoints.maxValue}
          stroke={Styled.ReferenceLineStroke}
          strokeDasharray="5 5"
        >
          <Label
            value={moneyFormatter(chartPoints.maxValue)}
            position="insideBottomLeft"
            stroke={Styled.ReferenceTextStroke}
            fontSize={10}
            fontWeight={200}
          />
        </ReferenceLine>

        <Line
          dot={false}
          type="monotone"
          dataKey="currentMonthDaily"
          stroke={Styled.ThisMonthLineStroke}
        />
        <Line
          dot={false}
          type="monotone"
          dataKey="previousMonthDaily"
          stroke={
            variant === CashDirection.Inflows
              ? Styled.PreviousMonthLineStrokeInflows
              : Styled.PreviousMonthLineStrokeOutflows
          }
        />
        <Line
          dot={false}
          type="monotone"
          dataKey="periodOverPeriodDaily"
          stroke={Styled.ThisYearLineStroke}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

LineChartOneMonth.propTypes = {
  data: PropTypes.shape({
    currentMonthDaily: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    periodOverPeriodDaily: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    previousMonthDaily: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export { LineChartOneMonth };
