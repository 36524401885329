import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import {
  FootnoteProvider,
  LoadingProvider,
  MultipleAccountProvider,
} from 'components/layouts';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { RoutesPath } from 'routes/constants/routes-path';
import { Header as HeaderV2 } from 'modules/dashboard/header/header';
import { Header as HeaderV1 } from './components';

export const V3PortalLayoutNoFooter: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  // scroll restoration
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <FootnoteProvider>
      <MultipleAccountProvider>
        <FeatureFlag
          disabled={<HeaderV1 />}
          enabled={<HeaderV2 />}
          flag={FeatureFlags.REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED}
        />

        <LoadingProvider>{children}</LoadingProvider>
      </MultipleAccountProvider>
    </FootnoteProvider>
  );
};
