import React from 'react';
import styled from 'styled-components';

interface StyledContainer {
  whiteBackground: boolean;
}

const Container = styled(({ whiteBackground, ...rest }) => (
  <div {...rest} />
))<StyledContainer>`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  max-width: 100vw;
  background-color: ${(props) =>
    props.whiteBackground ? 'var(--color-white)' : 'var(--color-whiteSmoke3)'};
`;

const Main = styled.div`
  flex-grow: 1;
`;

export const Styled = {
  Container,
  Main,
};
