import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { GrowthPromoSignUp } from 'modules/2023-q4/growth-promo/sign-up/sign-up-growth-promo';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';
import { SignUpContainer } from './components/sign-up-container/sign-up-container';
import { SignUpCopy } from './components/sign-up-copy/sign-up-copy';
import { Styled } from './sign-up.style';

export const SignUp = () => {
  const { partnerId } = useStoreWhiteLabelPartner((state) => state);

  const copy = (
    <FeatureFlag
      disabled={<SignUpCopy />}
      enabled={partnerId ? <SignUpCopy /> : <GrowthPromoSignUp />}
      flag={FeatureFlags.REACT_APP_2023_Q4_GROWTH_PROMO_ENABLED}
    />
  );

  return (
    <Grid container>
      <Styled.SignUpCopy item sm={5}>
        <MobileView mobile={<Box p={3}>{copy}</Box>}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            minHeight="100vh"
            px={7}
            py={3}
          >
            {copy}
          </Box>
        </MobileView>
      </Styled.SignUpCopy>

      <Grid item sm>
        <SignUpContainer />
      </Grid>
    </Grid>
  );
};
