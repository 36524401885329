import styled from 'styled-components';
import { Divider as DividerAlias } from '@material-ui/core';
import { ClickAnchor } from 'components/core';
import { breakpoints } from 'styles/css-constants';

const Button = styled(ClickAnchor)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: var(--border-radius-05);

  &:hover {
    background-color: var(--color-darkGrey);
  }
`;

const Divider = styled(DividerAlias)`
  background-color: var(--color-white);
`;

const ErrorContainer = styled.div`
  position: fixed;
  bottom: 40px;
  background-color: var(--color-mineShaft);
  border-radius: var(--border-radius-05);
  z-index: var(--z-index-toast-level);

  ${breakpoints.down('md')} {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 95%;
  }

  ${breakpoints.up('md')} {
    min-width: 400px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Styled = {
  Button,
  Divider,
  ErrorContainer,
};
