import React from 'react';
import PropTypes from 'prop-types';
import { DashboardType } from 'enums';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { IndicatorProps, IndicatorType } from './top-indicator';
import { TopIndicators } from './top-indicators';

interface IBankingTopIndicators {
  availableToSpend: {
    value: number;
  };
  runway: {
    unit: string;
    value: number;
  };
  totalCash: {
    value: number;
  };
}

interface BankingTopIndicatorsProps {
  plaidDataReady: boolean;

  indicators: IBankingTopIndicators;
}

function getIndicators(
  plaidDataReady: boolean,
  indicators: IBankingTopIndicators,
  showRunwayIndicator?: boolean,
): IndicatorProps[] {
  const { runway } = indicators;

  const isNil = (val: any) => val == null;
  const runwayHasInvalidData = isNil(runway.value) || isNil(runway.unit);
  const runwayValue = runwayHasInvalidData
    ? undefined
    : `${runway.value} ${runway.unit}`;

  const indicatorProps: IndicatorProps[] = [
    {
      label: 'Total Cash',
      type: IndicatorType.TotalCash,
      value: indicators.totalCash.value,
    },
    {
      label: 'Available To Spend',
      type: IndicatorType.AvailableToSpend,
      value: indicators.availableToSpend?.value,
    },
  ];

  if (showRunwayIndicator) {
    indicatorProps.push({
      label: 'Runway',
      type: IndicatorType.Runway,
      value: plaidDataReady ? runwayValue : '--',
    });
  }

  return indicatorProps;
}

export const BankingTopIndicators: React.FC<BankingTopIndicatorsProps> = ({
  plaidDataReady,
  indicators,
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const showRunwayIndicator = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2023_Q3_DISPLAY_INSIGHTS_ENABLED,
  );
  const indicatorOrder = getIndicators(
    plaidDataReady,
    indicators,
    showRunwayIndicator,
  );

  return (
    <TopIndicators
      dashboard={DashboardType.Banking}
      indicators={indicatorOrder}
    />
  );
};

BankingTopIndicators.propTypes = {
  plaidDataReady: PropTypes.bool.isRequired,
  indicators: PropTypes.shape({
    availableToSpend: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
    runway: PropTypes.shape({
      unit: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    totalCash: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
