import React from 'react';
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';
import { isProhibitedRole } from 'modules/2023-q4/rbac/utils/is-prohibited-role';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';

interface Props {
  children: React.ReactNode;

  roles: UserRole[];
}

export const UserPermissionsProhibited: React.FC<Props> = ({
  children,
  roles,
}) => {
  const { data: business } = useBusiness();
  const { data: user } = useUser(business?.Id);

  if (!user || !user.role) {
    return null;
  }

  if (isProhibitedRole({ prohibitedRoles: roles, roles: user.roles })) {
    return null;
  }

  return <>{children}</>;
};
