import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { BankAccount } from 'modules/2023-q3/banking-page/v2/types/bank-account';

export const useBankAccounts = (): UseQueryResult<BankAccount[]> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  return useQuery<BankAccount[], Error>(
    [ReactQueryKeys.BankAccountsV2],
    async () => {
      const response = await treasureApi().get('/accounts');

      return response.data;
    },
  );
};
