import React from 'react';
import { dayjs } from 'app/dayjs';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Mask } from 'components/banking';
import {
  ColumnType,
  DashboardTable,
  wrapTableText,
} from 'modules/dashboard/components';
import { TableText } from 'modules/dashboard/components/dashboard-table/components/table-text/table-text';
import { TransactionArrow } from './components/transaction-arrow/transaction-arrow';
import { TransactionStatus } from './components/transaction-status/transaction-status';

const columnsTransactions: ColumnType[] = [
  { name: 'Transaction Type', size: 4 },
  { alignment: 'center', name: 'Account', size: 2 },
  { alignment: 'center', name: 'Amount', size: 2 },
  { alignment: 'center', name: 'Date', size: 2 },
  { alignment: 'center', name: 'Status', size: 2 },
];

const getTransactionAccount = (transaction: Transaction) => {
  if (transaction.description === 'Interest Paid into your Treasure Reserve.') {
    return 'Treasure Reserve';
  }

  // TODO: api should return formatted account information
  return (
    <>
      {transaction.accountName?.toLowerCase()}
      {transaction.accountMask && (
        <>
          {' '}
          <Mask mask={transaction.accountMask} />
        </>
      )}
    </>
  );
};

type TranscationTableData = [
  React.ReactElement,
  React.ReactElement | null,
  React.ReactElement,
  React.ReactElement,
  React.ReactElement,
];

export interface Transaction {
  accountMask: string;
  accountName: string;
  accountType: string;
  amount: number;
  date: string;
  description: string;
  status: string;
}

interface TransactionsTableProps {
  data: Transaction[];
}

export const TransactionsTable: React.FC<TransactionsTableProps> = ({
  data,
}) => {
  const tableData: TranscationTableData[] = [];

  data.map((transaction: Transaction) => {
    return tableData.push([
      wrapTableText(
        <>
          <TransactionArrow transaction={transaction} />
          {transaction.description.replace(/\.$/, '')}
        </>,
      ),

      <TableText capitalize>{getTransactionAccount(transaction)}</TableText>,

      wrapTableText(
        <Currency number={transaction.amount} variant={CurrencyVariant.Full} />,
      ),

      wrapTableText(dayjs(transaction.date).format('MMM D, YYYY')),

      wrapTableText(<TransactionStatus status={transaction.status} />),
    ]);
  });

  return <DashboardTable columns={columnsTransactions} data={tableData} />;
};
