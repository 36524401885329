import React from 'react';
import PropTypes from 'prop-types';
import { Box, Popover as MuiPopover } from '@material-ui/core';
import { Text } from 'components/core';
import { Styled } from './popover.style';

export interface PopoverProps {
  /** element to anchor the popover to */
  anchorEl?: HTMLElement | null;

  /** boolean to show or hide the popover */
  open: boolean;

  /** text to display in the popover */
  text: string;
}

const Popover: React.FC<PopoverProps> = ({ anchorEl, open, text }) => {
  return (
    <MuiPopover
      open={open}
      elevation={0}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: -5,
        horizontal: 'center',
      }}
    >
      <Styled.Popover>
        <Box p={1}>
          <Text color="white" variant={1}>
            {text}
          </Text>
        </Box>
      </Styled.Popover>
    </MuiPopover>
  );
};

Popover.propTypes = {
  anchorEl: PropTypes.instanceOf(HTMLElement),
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export { Popover };
