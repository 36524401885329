import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useExcludeAccountFromAnalysis = (): UseMutationResult<
  any,
  any,
  any
> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  const setExcludeFromAnalysis = ({
    accountId,
    excludeFromAnalysis,
  }: {
    accountId: string;
    excludeFromAnalysis: boolean;
  }) =>
    treasureApi().patch(`/accounts/${accountId}`, {
      excludeFromAnalysis,
    });

  return useMutation(setExcludeFromAnalysis, {
    onSuccess: () => {
      return queryClient.invalidateQueries([ReactQueryKeys.ConnectedAccounts]);
    },
  });
};
