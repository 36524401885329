import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';

const Dots = styled.ul`
  li {
    list-style: disc;
  }
`;

const Plus = styled(AddIcon)`
  position: relative;
  top: 4px;
`;

export const Styled = {
  Dots,
  Plus,
};
