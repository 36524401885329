import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

export enum FormFieldContainerVariant {
  Small = 'Small',
}

interface FormFieldContainerProps {
  children: React.ReactNode;

  variant?: FormFieldContainerVariant;
}

export const FormFieldContainer: React.FC<FormFieldContainerProps> = ({
  children,
  variant,
}) => {
  const getMarginBotton = () => {
    if (variant === FormFieldContainerVariant.Small) {
      return 1;
    }

    return 2.5;
  };
  return <Box mb={getMarginBotton()}>{children}</Box>;
};

FormFieldContainer.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([...Object.values(FormFieldContainerVariant)]),
};
