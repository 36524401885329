import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { Button } from 'components/core';
import { useHistory, Link } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';

export const SignUpButton: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Hidden mdUp>
        <Box px={2}>
          <Link
            to={getVersionedPath({
              path: RoutesPath.noAuth.signUp.path,
              version: 3,
            })}
          >
            Sign Up
          </Link>
        </Box>
      </Hidden>

      <Hidden smDown>
        <Box px={2}>
          <Button
            variant="contained"
            onClick={() => {
              history.push(
                getVersionedPath({
                  path: RoutesPath.noAuth.signUp.path,
                  version: 3,
                }),
              );
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Hidden>
    </>
  );
};
