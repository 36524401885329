import React from 'react';
import { SweepFunds as SweepFundsV1 } from './sweep-funds-v1';
import { Deposit } from 'modules/2023-q4/onboarding/v1-style-overlay/pages/deposit/deposit';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

export const SweepFunds: React.FC = () => {
  return (
    <FeatureFlag
      disabled={<SweepFundsV1 />}
      enabled={<Deposit />}
      flag={FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED}
    />
  );
};
