import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useSweepDeposit = (): UseMutationResult<any, any, any> => {
  const queryClient = useQueryClient();

  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const postSweepDeposit = async ({
    amount,
    authToken,
    bankAccountId,
    businessId,
    idempotencyKey,
  }: {
    amount: number;
    authToken: string;
    bankAccountId: string;
    businessId: string;
    idempotencyKey: string;
  }) => {
    const response = await treasureApi({
      // authToken, TODO: PortalGateway doesn't support token swap
    }).post('/sweep/deposit', {
      amount: amount * 100, // api expects the amount to be in cents
      bankAccountId,
      idempotencyKey,
    });

    response.data.amount /= 100; // api returns the amount in cents

    return response.data;
  };

  return useMutation(postSweepDeposit, {
    onSuccess: () => {
      return queryClient.invalidateQueries([
        ReactQueryKeys.DashboardActivityGateway,
      ]);
    },
  });
};
