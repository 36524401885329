import styled from 'styled-components';
import { Heading } from 'components/core';

const SectionHeading = styled(Heading)`
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const Styled = {
  SectionHeading,
};
