export const treasureReserveProductKey = 'treasureReserve';

// we are using the order of this object to display the UI
export enum AllocationProducts {
  cash = 'cash',
  moneyMarket = 'moneyMarket',
  tbills = 'tbills',
  smart = 'smart',
}

interface AllocationProduct {
  allocation: number;
  color: string;
  liquidity: number;
  liquidityString: string;
  liquidityUnit: 'day'; // anticipating hour
  name: string;
  return: number;
  volatility: number;
  fee: number;
}

// we are using the order of this object to display the UI
export interface Allocation {
  [treasureReserveProductKey]: AllocationProduct;
  [AllocationProducts.cash]: AllocationProduct;
  [AllocationProducts.moneyMarket]: AllocationProduct;
  [AllocationProducts.tbills]: AllocationProduct;
  [AllocationProducts.smart]: AllocationProduct;
}

export interface AllocationWithOther extends Allocation {
  ['other']?: {
    allocation: number;
    color: string;
    liquidity: number;
    liquidityUnit: string;
    name: string;
  };
}

// TODO: refactor initialAllocation to not have 0 returns
export const initialAllocation: Allocation = {
  [treasureReserveProductKey]: {
    allocation: 100,
    color: 'black',
    liquidity: 3,
    liquidityString: '3-5',
    liquidityUnit: 'day',
    name: 'Treasure Reserve',
    return: 0,
    volatility: 0.9,
    fee: 0,
  },
  [AllocationProducts.cash]: {
    allocation: 0,
    color: 'productCash',
    liquidity: 1,
    liquidityString: '1',
    liquidityUnit: 'day',
    name: 'Cash',
    return: 0,
    volatility: 1.2,
    fee: 0,
  },
  [AllocationProducts.moneyMarket]: {
    allocation: 100,
    color: 'productManagedMoneyMarket',
    liquidity: 3,
    liquidityString: '3-5',
    liquidityUnit: 'day',
    name: 'Managed Money Market',
    return: 0,
    volatility: 1.3,
    fee: 0,
  },
  [AllocationProducts.tbills]: {
    allocation: 0,
    color: 'productManagedTreasuries',
    liquidity: 3,
    liquidityString: '3-5',
    liquidityUnit: 'day',
    name: 'Managed Treasuries',
    return: 0,
    volatility: 1.49,
    fee: 0,
  },
  [AllocationProducts.smart]: {
    allocation: 0,
    color: 'productManagedIncome',
    liquidity: 3,
    liquidityString: '3-5',
    liquidityUnit: 'day',
    name: 'Managed Income',
    return: 0,
    volatility: 3,
    fee: 0,
  },
};

const percentageToDecimal = (percentage: number) => {
  return percentage / 100;
};

const allocationPrecision = (number: number) => {
  // never return a negative number
  // return one decimal space
  if (Number.isNaN(number)) {
    return 0;
  }

  return Math.max(0, Math.round(number * 10) / 10);
};

const allocationTo100 = (allocation: Allocation) => {
  let totalAllocation = 0;

  // javascript doesn't do Math well so we need to get rid of the decimals
  // and then divide the total again
  Object.entries(allocation).map(([productKey, product]) => {
    if (productKey === treasureReserveProductKey) {
      return null;
    }

    totalAllocation += product.allocation * 10;

    return null;
  });

  totalAllocation /= 10;

  if (totalAllocation !== 100) {
    let diff = (100 * 10 - totalAllocation * 10) / 10;
    const add = diff > 0;

    // TODO: order by highest/lowest allocation
    Object.entries(allocation).map(([productKey, product]) => {
      if (productKey === treasureReserveProductKey) {
        return null;
      }

      if (product.allocation === 0) {
        return null;
      }

      if (diff !== 0) {
        if (add) {
          product.allocation += 0.1;
          diff -= 0.1;
        } else {
          product.allocation -= 0.1;
          diff += 0.1;
        }
      }

      return null;
    });
  }

  return allocation;
};

const calculateBlendedRate = (allocation: Allocation) => {
  let blendedRate = 0;

  Object.entries(allocation).map(([productKey, product]) => {
    if (productKey === treasureReserveProductKey) {
      return null;
    }
    /*
     * RETURN
     * sum of each product's return x allocation
     */
    blendedRate += product.return * percentageToDecimal(product.allocation);

    return null;
  });

  return blendedRate;
};

const calculateBlendedFee = (allocation: Allocation) => {
  let blendedFee = 0;

  Object.entries(allocation).map(([productKey, product]) => {
    if (productKey === treasureReserveProductKey) {
      return null;
    }
    blendedFee += product.fee * percentageToDecimal(product.allocation);
    return null;
  });

  return blendedFee;
};

export const getAllocation = ({
  currentAllocation,
  allocationChange,
}: {
  currentAllocation: Allocation;
  allocationChange: any;
}): Allocation => {
  /* If the values corresponding to cash are modified then the other products
   * change pro rata based on their current values. If any of the values
   * corresponding to a non cash product are changed then those changes are
   * balanced by changes in cash until it can't absorb anymore, then it is
   * distributed among the remaining products.
   */
  const { allocationType, newAllocation } = allocationChange;
  let returnAllocation = { ...currentAllocation };

  switch (allocationType) {
    case AllocationProducts.cash: {
      const weightNonCash =
        returnAllocation[AllocationProducts.moneyMarket].allocation +
        returnAllocation[AllocationProducts.smart].allocation +
        returnAllocation[AllocationProducts.tbills].allocation;
      const changeCash =
        newAllocation - returnAllocation[AllocationProducts.cash].allocation;

      returnAllocation = {
        ...returnAllocation,
        [AllocationProducts.cash]: {
          ...returnAllocation[AllocationProducts.cash],
          allocation: newAllocation,
        },
      };

      if (weightNonCash === 0) {
        const evenDistribution = Math.abs(changeCash) / 3;

        returnAllocation = {
          ...returnAllocation,
          [AllocationProducts.moneyMarket]: {
            ...returnAllocation[AllocationProducts.moneyMarket],
            allocation: allocationPrecision(evenDistribution),
          },
          [AllocationProducts.smart]: {
            ...returnAllocation[AllocationProducts.smart],
            allocation: allocationPrecision(evenDistribution),
          },
          [AllocationProducts.tbills]: {
            ...returnAllocation[AllocationProducts.tbills],
            allocation: allocationPrecision(evenDistribution),
          },
        };
      } else {
        const scalar = changeCash / weightNonCash;

        returnAllocation = {
          ...returnAllocation,
          [AllocationProducts.moneyMarket]: {
            ...returnAllocation[AllocationProducts.moneyMarket],
            allocation: allocationPrecision(
              returnAllocation[AllocationProducts.moneyMarket].allocation -
                scalar *
                  returnAllocation[AllocationProducts.moneyMarket].allocation,
            ),
          },
          [AllocationProducts.smart]: {
            ...returnAllocation[AllocationProducts.smart],
            allocation: allocationPrecision(
              returnAllocation[AllocationProducts.smart].allocation -
                scalar * returnAllocation[AllocationProducts.smart].allocation,
            ),
          },
          [AllocationProducts.tbills]: {
            ...returnAllocation[AllocationProducts.tbills],
            allocation: allocationPrecision(
              returnAllocation[AllocationProducts.tbills].allocation -
                scalar * returnAllocation[AllocationProducts.tbills].allocation,
            ),
          },
        };
      }
      break;
    }
    default: {
      // for products other than cash, if they are increased, they take from cash
      // if cash is at 0 (off), then take from the other products weighted by
      // the current allocation
      const product = allocationType as keyof Allocation;
      const change = newAllocation - returnAllocation[product].allocation;
      const weightCash = returnAllocation[AllocationProducts.cash].allocation;
      let weightCashAfterChange = 0;

      const [otherProductA, otherProductB] = Object.keys(
        AllocationProducts,
      ).filter((key) => {
        return key !== AllocationProducts.cash && key !== allocationType;
      });

      const otherProduct1 = otherProductA as keyof Allocation;
      const otherProduct2 = otherProductB as keyof Allocation;

      // set the new allocation of the selected product
      returnAllocation = {
        ...returnAllocation,
        [allocationType]: {
          ...returnAllocation[product],
          allocation: newAllocation,
        },
      };

      // if cash is on, we decrease cash as much as we can
      if (weightCash !== 0) {
        returnAllocation = {
          ...returnAllocation,
          [AllocationProducts.cash]: {
            ...returnAllocation[AllocationProducts.cash],
            allocation: allocationPrecision(
              100 -
                returnAllocation[otherProduct1].allocation -
                returnAllocation[otherProduct2].allocation -
                newAllocation,
            ),
          },
        };

        // update the new cash allocation to compute the surplus below
        weightCashAfterChange =
          returnAllocation[AllocationProducts.cash].allocation;
      }

      // if the new weight of cash is 0, then there might be a surplus
      if (weightCashAfterChange === 0) {
        // check if either of the other products are on
        const weightOther =
          returnAllocation[otherProduct1].allocation +
          returnAllocation[otherProduct2].allocation;

        // if all of the products are off, we turn cash back on
        if (weightOther === 0) {
          returnAllocation = {
            ...returnAllocation,
            [AllocationProducts.cash]: {
              ...returnAllocation[AllocationProducts.cash],
              allocation: allocationPrecision(
                100 -
                  returnAllocation[otherProduct1].allocation -
                  returnAllocation[otherProduct2].allocation -
                  newAllocation,
              ),
            },
          };
        } else {
          // there might not be enough cash, to offset the change,
          // so we'll take it from the other products based on their current allocation
          const surplus = change - weightCash;
          const scalar = surplus / weightOther;

          returnAllocation = {
            ...returnAllocation,
            [otherProduct1]: {
              ...returnAllocation[otherProduct1],
              allocation: allocationPrecision(
                returnAllocation[otherProduct1].allocation -
                  scalar * returnAllocation[otherProduct1].allocation,
              ),
            },
            [otherProduct2]: {
              ...returnAllocation[otherProduct2],
              allocation: allocationPrecision(
                returnAllocation[otherProduct2].allocation -
                  scalar * returnAllocation[otherProduct2].allocation,
              ),
            },
          };
        }
      }
    }
  }

  // calculate blended treasure reserve liquidity and volatility
  let treasureReserveLiquidity = 0;
  let treasureReserveLiquidityString = '0';
  let treasureReserveVolatility = 0;

  Object.entries(returnAllocation).map(([productKey, product]) => {
    if (productKey === treasureReserveProductKey) {
      return null;
    }

    /*
     * LIQUIDITY
     * currently assuming all liquidityUnits are the same (day)
     * take the max liquidity of any product that is "on"
     * (meaning its allocaiton > 0)
     */
    if (
      product.allocation > 0 &&
      product.liquidity > treasureReserveLiquidity
    ) {
      treasureReserveLiquidity = product.liquidity;
      treasureReserveLiquidityString = product.liquidityString;
    }

    /*
     * VOLATILITY
     * sqrt[(weight_cash * return_cash)**2 + (weight_mm * return_mm)**2 + (weight_tbill * return_tbill)**2 + (weight_pro * return_pro)**2]
     */
    treasureReserveVolatility +=
      (percentageToDecimal(product.allocation) * product.volatility) ** 2;

    return null;
  });

  treasureReserveVolatility = Math.sqrt(treasureReserveVolatility);
  returnAllocation = {
    ...returnAllocation,
    treasureReserve: {
      ...returnAllocation.treasureReserve,
      liquidity: treasureReserveLiquidity,
      liquidityString: treasureReserveLiquidityString,
      return: calculateBlendedRate(returnAllocation),
      fee: calculateBlendedFee(returnAllocation),
      volatility: treasureReserveVolatility,
    },
  };

  // because of rounding, we can sometimes go over/under, so we need to remedy that
  returnAllocation = allocationTo100(returnAllocation);

  return returnAllocation;
};

export const getAllocationAmount = ({
  allocation,
  aum,
}: {
  allocation: number;
  aum: number;
}) => {
  return Number((aum * percentageToDecimal(allocation)).toFixed(2));
};

export const getAllocationMax = ({
  allocations,
  productKey,
}: {
  allocations: Allocation;
  productKey: keyof Allocation;
}) => {
  // sum of allocations not including the productKey or treasureReserve
  const filtered = Object.entries(allocations).filter(
    ([key, value]) => key !== productKey && key !== treasureReserveProductKey,
  );

  const filteredObject = Object.fromEntries(filtered);

  const sum = Object.values(filteredObject).reduce(
    (a: number, b: { allocation: number }) => a + b.allocation,
    0,
  );

  return 100 - sum;
};

export const getAllocationReturn = ({
  allocationAmount,
  allocationReturn,
}: {
  allocationAmount: number;
  allocationReturn: number;
}) => {
  return allocationAmount * percentageToDecimal(allocationReturn);
};

export const applyExistingAllocation = ({
  allocations,
}: {
  allocations: any;
}) => {
  if (allocations) {
    const responseAllocation = allocations;
    let returnAllocation = { ...initialAllocation };

    const getAllocationKey = (productType: string): string | undefined => {
      switch (productType) {
        case 'CASH':
          return 'cash';
        case 'HIGH_YIELD':
          return 'smart';
        case 'MONEY_MARKET':
          return 'moneyMarket';
        case 'TBILL':
          return 'tbills';
        default:
        // do nothing
      }
    };

    Object.keys(responseAllocation).forEach((key) => {
      const allocationKey = getAllocationKey(responseAllocation[key].type);

      if (allocationKey) {
        return (returnAllocation = {
          ...returnAllocation,
          [allocationKey]: {
            ...returnAllocation[
              allocationKey as keyof typeof AllocationProducts
            ],
            allocation: Number(responseAllocation[key].percentage),
          },
        });
      }
    });

    returnAllocation = {
      ...returnAllocation,
      [treasureReserveProductKey]: {
        ...returnAllocation[treasureReserveProductKey],
        return: calculateBlendedRate(returnAllocation),
        fee: calculateBlendedFee(returnAllocation),
      },
    };

    return returnAllocation;
  }

  return initialAllocation;
};

export const applyRates = (rates: {
  cashRate: number;
  managedIncomeRate: number;
  moneyMarketRate: number;
  managedTreasuriesRate: number;
}) => {
  initialAllocation[AllocationProducts.cash].return = rates.cashRate * 100;
  initialAllocation[AllocationProducts.moneyMarket].return =
    rates.moneyMarketRate * 100;
  initialAllocation[AllocationProducts.tbills].return =
    rates.managedTreasuriesRate * 100;
  initialAllocation[AllocationProducts.smart].return =
    rates.managedIncomeRate * 100;

  initialAllocation[treasureReserveProductKey].return =
    calculateBlendedRate(initialAllocation);
  initialAllocation[treasureReserveProductKey].fee =
    calculateBlendedFee(initialAllocation);

  return initialAllocation;
};

export const applyFee = (fee: number) => {
  initialAllocation[AllocationProducts.moneyMarket].fee = fee;
  initialAllocation[AllocationProducts.tbills].fee = fee;
  initialAllocation[AllocationProducts.smart].fee = fee;

  return initialAllocation;
};
