import React from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from './contact-treasure-support-link.style';

interface Props {
  children: React.ReactNode;

  subject?: string;
}

export const ContactTreasureSupportLink: React.FC<Props> = ({
  children,
  subject,
}) => {
  const { trackEvent } =
    useTracking<{
      emailSubject?: string;
      eventName: TrackEventName;
    }>();

  // iframe is used to circumvent onberforeunload
  // https://stackoverflow.com/questions/9740510/mailto-link-in-chrome-is-triggering-window-onbeforeunload-can-i-prevent-this

  return (
    <>
      <Styled.A
        href={`mailto:support@treasure.tech${
          subject ? `?subject=${subject}` : ''
        }`}
        onClick={() =>
          trackEvent({
            emailSubject: subject,
            eventName: TrackEventName.ContactSupportClicked,
          })
        }
        target="hidden-mailto-iframe"
      >
        {children}
      </Styled.A>

      <Styled.Iframe
        name="hidden-mailto-iframe"
        title="hidden-mailto-iframe"
      ></Styled.Iframe>
    </>
  );
};
