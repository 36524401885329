import styled from 'styled-components';
import { Tab as TabAlias, Tabs as TabsAlias } from '@material-ui/core';

const Tab = styled(TabAlias)`
  border-bottom: 5px solid var(--color-gainsboro0);
`;

const Tabs = styled(TabsAlias)`
  .MuiTabs-indicator {
    background-color: var(--color-stromboli);
    height: 5px;
  }
`;

export const Styled = {
  Tab,
  Tabs,
};
