import firebase from 'utils/firebase';
import { Toast } from 'components/layouts';

export const verifyPasswordResetCode: (
  actionCode: string,
) => Promise<boolean | string> = async (actionCode) => {
  try {
    const email = await firebase.auth().verifyPasswordResetCode(actionCode);

    return email;
  } catch (error) {
    const err = error as { code: string; message: string };

    Toast.show('error', err.message);

    return false;
  }
};
