import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { Toast } from 'components/layouts';

// format data appropriately. API returns data as a decimal, but we want to show it as a percentage so we multiply by 100
//   we also do toFixed(3) as we can potentially show 3 nums right of the decimal
const formatValues = (value: number) => Number((value * 100).toFixed(3));

interface ProductInfo {
  treasureHighYieldFee: number;
}
/**
 * Retrieve the products for this business.
 */
export const useProducts = (businessId: string) => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery(
    [ReactQueryKeys.ProductInfo, businessId],
    async () => {
      try {
        const response = await treasureApi().get(`/products/${businessId}`);

        let { amount: treasureHighYieldFee } = response.data.HIGH_YIELD.FEE;
        // if no fee is returned, set to default
        if (!treasureHighYieldFee) treasureHighYieldFee = 0.0035;

        // Use a hardcode yield if present, otherwise use what we get from api
        treasureHighYieldFee = formatValues(treasureHighYieldFee);

        return { treasureHighYieldFee } as ProductInfo;
      } catch (err) {
        const error = err as Error;

        Toast.showError(error);

        throw new Error(error.message); // throw the error to tell react query that the call failed
      }
    },
    {
      enabled: !!businessId,
      retry: false,
    },
  );
};
