import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const SettingsSidebar = styled(Grid)`
  background-color: var(--color-gainsboro0);
  min-height: 100vh;
`;

export const Styled = {
  SettingsSidebar,
};
