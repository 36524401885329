import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { ReactQueryMutationKeys } from 'enums';

export const useConfirmMFACode = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const confirmMFACode = async ({ mfaCode }: { mfaCode: string }) => {
    const { data } = await treasureApi().post('/sweeps/auth', {
      code: mfaCode,
    });

    return data;
  };

  return useMutation(confirmMFACode, {
    mutationKey: ReactQueryMutationKeys.ConfirmMFACode, // this mutation key is used by react-query-error-toasts so it will never show a user an error
  });
};
