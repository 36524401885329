export enum ReactQueryKeys {
  AccountsBalances = 'accountsBalances',
  AggregateBalances = 'aggregateBalances',
  ApplicationStatus = 'applicationStatus',
  AUMBalances = 'aumBalanes',
  BankAccounts = 'bankAccounts',
  BankAccountsV2 = 'bankAccountsV2',
  BankAccountTransactions = 'bankAccountTransactions',
  Business = 'business',
  BusinessMembers = 'businessMembers',
  Businesses = 'businesses',
  CashAccount = 'cashAccount',
  CashDocument = 'cashDocument',
  CashDocumentMigrated = 'cashDocumentMigrated',
  CashSnapshot = 'cashSnapshot',
  ChartData = 'chartData',
  ConnectedAccounts = 'connectedAccounts',
  DashboardActivity = 'dashboardActivity',
  DashboardActivityGateway = 'dashboardActivityGateway',
  DashboardTransactions = 'dashboardTransactions',
  Documents = 'documents',
  Fees = 'fees',
  InitialOnboardingSweep = 'initialOnboardingSweep',
  InviteToken = 'inviteToken',
  LegalEntityTypes = 'legalEntityTypes',
  MonthlyReturns = 'monthlyReturns',
  Onboarding = 'onboarding',
  OnboardingToken = 'onboardingToken',
  Policies = 'policies',
  Portfolio = 'portfolio',
  Portfolios = 'portfolios',
  ProductInfo = 'productInfo',
  ProductPositions = 'productPositions',
  QuickbooksConnect = 'quickbooksConnect',
  QuickbooksStatus = 'quickbooksStatus',
  RestrictedCusips = 'restrictedCusips',
  ScheduleTransfers = 'scheduleTransfers',
  States = 'states',
  SubIndicator = 'subIndicator',
  TopIndicators = 'topIndicators',
  TreasureReserve = 'treasureReserve',
  TreasureReserveEarnings = 'treasureReserveEarnigns',
  TreasureReservePositions = 'treasureReservePositions',
  User = 'user',
  UserIP = 'userIp',
}
