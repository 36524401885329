import {
  USAZipCodeRegExp,
  EINRegExp,
  formattedSSNRegExp,
  nonFormattedSSNRegExp,
  onlyNumbersRegExp,
  emailRegExp,
} from './regular-expressions';

enum DateComparisonOption {
  Now = 'now',
  Today = 'today',
}

type Validator<
  InputValueType = string | number,
  OptionsType = {
    formatted?: boolean;
    greaterThan?: DateComparisonOptions;
    lessThan?: DateComparisonOptions;
  },
> = (value: InputValueType, options?: Partial<OptionsType>) => boolean;

type ComparisonValidator<
  InputValue1Type = number,
  InputValue2Type = number,
  OptionsType = { orEqual?: boolean },
> = (
  value1: InputValue1Type,
  value2: InputValue2Type,
  options?: Partial<OptionsType>,
) => boolean;

type DateComparisonOptions =
  | Date
  | DateComparisonOption.Now
  | DateComparisonOption.Today;

export const DEFAULT_MAX_INPUT_LENGTH = 255;
export const DEFAULT_MAX_TEXTAREA_LENGTH = 500;
export const VALID_USA_PHONE_NUMBER_MAX_DIGITS = 10;

export const maxCompanyNameLength = DEFAULT_MAX_INPUT_LENGTH;
export const maxAddressLength = DEFAULT_MAX_INPUT_LENGTH;
export const maxCityLength = DEFAULT_MAX_INPUT_LENGTH;
export const maxNameLength = DEFAULT_MAX_INPUT_LENGTH;
export const maxJobTitleLength = DEFAULT_MAX_INPUT_LENGTH;
export const maxEmailLength = DEFAULT_MAX_INPUT_LENGTH;
export const maxUSAStateLength = 5;
export const maxNonUSAStateLength = 50;
export const maxUSAZipCodeLength = 5;
export const maxNonUSAZipCodeLength = 20;
export const maxNonUSANationalIdentifierLength = 50;
export const maxDateOfBirth = new Date(
  new Date().setDate(new Date().getDate() - 1),
);
export const minDateOfBirth = new Date(
  new Date().setFullYear(new Date().getFullYear() - 120),
);

export const isValidDate: Validator<
  string,
  { greaterThan: DateComparisonOptions; lessThan: DateComparisonOptions }
> = (value, { greaterThan, lessThan } = {}) => {
  try {
    // assumes format mm/dd/yyyy
    if (!value || !/^\d\d\/\d\d\/\d\d\d\d$/.test(value)) {
      return false;
    }

    // check valid number inputs
    // do not use new Date(string) or Date.parse()
    const [mm, dd, yyyy] = value.split('/').map((p) => parseInt(p, 10));
    const month = mm - 1;
    const date = new Date(yyyy, month, dd);
    let isValid =
      date.getMonth() === month &&
      date.getDate() === dd &&
      date.getFullYear() === yyyy;

    if (!isValid) {
      return false;
    }

    const now = new Date();
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    if (lessThan) {
      switch (lessThan) {
        case DateComparisonOption.Now:
          isValid = date <= now;
          break;
        case DateComparisonOption.Today:
          isValid = date <= today;
          break;
        default: {
          isValid = date <= lessThan;
        }
      }
    }

    if (!isValid) {
      return false;
    }

    if (greaterThan) {
      switch (greaterThan) {
        case DateComparisonOption.Now:
          isValid = date >= now;
          break;
        case DateComparisonOption.Today:
          isValid = date >= today;
          break;
        default: {
          isValid = date >= greaterThan;
        }
      }
    }

    return isValid;
  } catch {
    return false;
  }
};

export const isValidDateOfBirth: Validator<string> = (value) =>
  isValidDate(value, {
    greaterThan: minDateOfBirth,
    lessThan: maxDateOfBirth,
  });

export const isValidNumber: Validator<number> = (value) => {
  const parsedValue = Number(value);
  if (
    Number.isNaN(parsedValue) ||
    !['string', 'number'].includes(typeof value)
  ) {
    return false;
  }
  return true;
};

export const isGreaterThan: ComparisonValidator<
  number,
  number,
  { orEqual: boolean }
> = (value1, value2, { orEqual = false } = {}) =>
  isValidNumber(value1) && isValidNumber(value2)
    ? orEqual
      ? value1 >= value2
      : value1 > value2
    : false;

export const isLessThan: ComparisonValidator<
  number,
  number,
  { orEqual: boolean }
> = (value1, value2, { orEqual = false } = {}) =>
  isValidNumber(value1) && isValidNumber(value2)
    ? orEqual
      ? value1 <= value2
      : value1 < value2
    : false;

export const isValidUSAZipCode: Validator<string> = (value) =>
  USAZipCodeRegExp.test(value);

export const isValidEIN: Validator<string> = (value) => EINRegExp.test(value);

export const isValidSSN: Validator<string, { formatted: boolean }> = (
  value: string,
  { formatted = true } = {},
) => (formatted ? formattedSSNRegExp : nonFormattedSSNRegExp).test(value);

export const isValidUSAPhoneNumber: Validator<string> = (value: string) => {
  const digits = String(value.match(onlyNumbersRegExp)?.join('')).length;

  if (digits === VALID_USA_PHONE_NUMBER_MAX_DIGITS) {
    return true;
  }

  return false;
};
export const isValidEmail: Validator<string> = (value) =>
  emailRegExp.test(value);

export const isValidRoutingNumber: Validator<string> = (value) => {
  const digits = value.toString().split('');
  const digitsArray = digits.map(Number);

  // Checksum test is a position-weighted sum of each of the digits used to verify the routing number. The weights 371 371 371 catches any single-digit error and most transposition errors. 1, 3, and 7 are used because they're coprime to 10.
  // https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit
  const checkSum =
    (3 * (digitsArray[0] + digitsArray[3] + digitsArray[6]) +
      7 * (digitsArray[1] + digitsArray[4] + digitsArray[7]) +
      (digitsArray[2] + digitsArray[5] + digitsArray[8])) %
      10 ==
    0;

  return checkSum;
};
