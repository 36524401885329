import React from 'react';

// feature flags
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

// react-query
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  mutationCache,
  queryCache,
} from 'modules/page-errors/error-toasts/react-query-error-toasts';

// style
import { StylesProvider } from '@material-ui/core';
import { CssVariables } from 'styles/css-variables';
import GlobalStyle from 'styles/global.style';

// toast
import { ToastContainer } from 'components/layouts/toast/components/toast-container/toast-container';

// tracking
import { Tracking } from 'modules/tracking';

// polling
import { Polling } from 'modules/polling/polling';

// font
import 'typeface-nunito-sans';

// react-router-dom
import { Router } from 'react-router-dom';
import Routes from 'routes';
import { createBrowserHistory } from 'history';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    mutationCache,
    queryCache,
  });

  const history = createBrowserHistory();

  return (
    <QueryClientProvider client={queryClient}>
      <Tracking history={history}>
        <Router history={history}>
          <StylesProvider injectFirst>
            <CssVariables />
            <GlobalStyle />
            <ToastContainer />
            <Polling>
              <Routes />
            </Polling>
          </StylesProvider>
        </Router>
      </Tracking>
    </QueryClientProvider>
  );
};

export default App;
