import React from 'react';
import { Box } from '@material-ui/core';
import { DepositWithdrawalVariant } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrows/arrow-left.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrows/arrow-right.svg';

const directionToDepositWithdrawal = (direction: string) => {
  switch (direction) {
    case 'CREDIT':
      return DepositWithdrawalVariant.Withdrawal;
    case 'DEBIT':
      return DepositWithdrawalVariant.Deposit;
  }

  return null;
};

const wrapArrow = (arrow: React.ReactElement) => {
  return (
    <Box display="inline" position="relative" top={5} mr={1}>
      {arrow}
    </Box>
  );
};

interface TransactionArrowProps {
  direction: string;
}

export const TransactionArrow: React.FC<TransactionArrowProps> = ({
  direction,
}) => {
  const directionType = directionToDepositWithdrawal(direction);

  if (directionType === DepositWithdrawalVariant.Deposit) {
    return wrapArrow(<ArrowForwardIcon />);
  }

  if (directionType === DepositWithdrawalVariant.Withdrawal) {
    return wrapArrow(<ArrowBackIcon />);
  }

  return null;
};
