import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useManualBankAccountConnect = (): UseMutationResult<
  any,
  any,
  any
> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  const manualBankAccountConnect = async ({
    accountNumber,
    accountType,
    bankName,
    routingNumber,
  }: {
    accountNumber: number;
    accountType: string;
    bankName: string;
    routingNumber: number;
  }) => {
    const { data } = await treasureApi().post('/accounts/manual-connect', {
      accountNumber,
      accountType,
      bankName,
      routingNumber,
    });

    return data;
  };

  return useMutation(manualBankAccountConnect);
};
