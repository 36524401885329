import React from 'react';
import { Legalese as LegaleseV1 } from './legalese-v1';
import { Legalese as LegaleseV2 } from 'modules/2023-q4/onboarding/v1-style-overlay/pages/legalese/legalese-v2';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

interface LegaleseProps {
  policyKeys: string[];
}

export const Legalese: React.FC<LegaleseProps> = ({ policyKeys }) => {
  return (
    <FeatureFlag
      disabled={<LegaleseV1 policyKeys={policyKeys} />}
      enabled={<LegaleseV2 policyKeys={policyKeys} />}
      flag={FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED}
    />
  );
};
