import styled from 'styled-components';
import { Button as ButtonAlias } from 'components/core';

const Button = styled(ButtonAlias)`
  &.MuiButton- {
    &contained {
      background-color: var(--color-green2);

      font-size: 14px;
      font-weight: 400;
    }

    &outlined {
      border: 1px solid var(--color-green2);

      font-size: 14px;
      font-weight: 400;
    }
  }
`;

export const Styled = {
  Button,
};
