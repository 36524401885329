import React from 'react';
import { Box } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Styled } from './pie-chart-text.style';

interface PieChartTextProps {
  allocationBalance?: number;

  aum: number;

  returnPercentage: number;

  netReturnPercentage: number;

  showReturnAmount?: boolean;
}

export const PieChartText: React.FC<PieChartTextProps> = ({
  allocationBalance,
  aum,
  returnPercentage,
  showReturnAmount,
  netReturnPercentage,
}) => {
  // the total aum may not all be getting a return (or a return that's handled by this component)
  // therefore, if an allocationBalance is passed in, use that number to calculate amounts and returns
  const allocationAum = allocationBalance || aum;

  return (
    <Box pt={8} textAlign="center">
      <Typography color="gothic" variant={TypographyVariant.LargeLabel}>
        Total Balance
      </Typography>

      <Box mt={showReturnAmount ? 0.5 : 0} mb={showReturnAmount ? 0.5 : 1}>
        <Styled.AUM color="nero" variant={TypographyVariant.LargeLabel}>
          <Currency number={aum} variant={CurrencyVariant.Full} />
        </Styled.AUM>
      </Box>

      {showReturnAmount ? (
        <>
          <Styled.ReturnTitle
            color="gothic"
            variant={TypographyVariant.LargeLabel}
          >
            Total Net Exp Return in 12M
          </Styled.ReturnTitle>

          <Styled.ReturnBox display="inline-block" px={1} mt={0.5} mb={0.5}>
            <Typography
              color="whiteSmoke1"
              variant={TypographyVariant.SubHeader1}
            >
              <Percentage number={returnPercentage} decimalScale={2} />
            </Typography>
          </Styled.ReturnBox>

          <Styled.ReturnNumber
            color="salem"
            variant={TypographyVariant.SubHeader1}
          >
            <Currency
              number={(allocationAum * netReturnPercentage) / 100}
              variant={CurrencyVariant.Full}
            />
          </Styled.ReturnNumber>
        </>
      ) : (
        <Styled.ReturnAmount
          color="gothic"
          variant={TypographyVariant.LargeLabel}
        >
          <Percentage
            number={returnPercentage}
            decimalScale={returnPercentage === 0 ? 0 : 2}
          />{' '}
          Return
        </Styled.ReturnAmount>
      )}
    </Box>
  );
};
