import React from 'react';
import { Trans } from 'app/i18n';
import { Link } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Box, Grid } from '@material-ui/core';
import { i18nNamespaceAllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';
import { Styled } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal.style';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

interface Props {
  handlePrimaryButtonClick: () => void;
  handleSecondaryButtonClick: () => void;
  isLoadingMutatePortfolio: boolean;
  noFunds: boolean;
  showConfirmation: boolean;
}

export const AllocationModalButtons: React.FC<Props> = ({
  handlePrimaryButtonClick,
  handleSecondaryButtonClick,
  isLoadingMutatePortfolio,
  noFunds,
  showConfirmation,
}) => {
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();

  if (showConfirmation && noFunds) {
    return (
      <Box textAlign="right" mr={5} mb={2}>
        <FeatureFlag
          disabled={
            <Link
              onClick={() =>
                trackEvent({
                  eventName: TrackEventName.TransferFundsButtonClicked,
                })
              }
              to={getVersionedPath({
                path: RoutesPath.pages.transfer.path,
                version: 3,
              })}
            >
              <Styled.RoundedButton>
                <Trans
                  i18nKey="Transfer Funds"
                  ns={i18nNamespaceAllocationModal}
                />
              </Styled.RoundedButton>
            </Link>
          }
          enabled={
            <Link
              onClick={() =>
                trackEvent({
                  eventName: TrackEventName.TransferFundsButtonClicked,
                })
              }
              to={RoutesPath.tasks.deposit.path}
            >
              <Styled.RoundedButton>
                <Trans
                  i18nKey="Transfer Funds"
                  ns={i18nNamespaceAllocationModal}
                />
              </Styled.RoundedButton>
            </Link>
          }
          flag={FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED}
        />
      </Box>
    );
  }

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item>
        <Styled.RoundedButton
          onClick={handleSecondaryButtonClick}
          variant="outlined"
        >
          {showConfirmation ? (
            <Trans i18nKey="Back" ns={i18nNamespaceAllocationModal} />
          ) : (
            <Trans i18nKey="Cancel" ns={i18nNamespaceAllocationModal} />
          )}
        </Styled.RoundedButton>
      </Grid>

      <Grid item>
        <Styled.RoundedPrimaryButton
          disabled={isLoadingMutatePortfolio}
          onClick={handlePrimaryButtonClick}
        >
          {showConfirmation ? (
            isLoadingMutatePortfolio ? (
              <Styled.Spinner inline />
            ) : (
              <Trans i18nKey="Confirm" ns={i18nNamespaceAllocationModal} />
            )
          ) : (
            <Trans
              i18nKey={noFunds ? 'Save' : 'Accept'}
              ns={i18nNamespaceAllocationModal}
            />
          )}
        </Styled.RoundedPrimaryButton>
      </Grid>
    </Grid>
  );
};
