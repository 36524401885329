import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { PageTitle } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/page-title';
import { Container } from 'modules/2023-q3/allocation/components/container/container';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Styled } from './interstitial.style';

interface InterstitialProps {
  onConnectBank: () => void;
  onManualBank: () => void;
}

const Interstitial: React.FC<InterstitialProps> = ({
  onConnectBank,
  onManualBank,
}) => {
  const content = (
    <>
      <PageTitle
        title="Bank details"
        description="Treasure requires at least 1 bank account connected to generate
          revenue off your idle cash. It works best with all your operational
          accounts connected."
      />

      <Box my={3}>
        <Button fullWidth onClick={onConnectBank}>
          <ButtonContent>
            <Typography color="white" variant={TypographyVariant.Body}>
              Connect bank account
            </Typography>
          </ButtonContent>
        </Button>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          bgColor="white"
          bgHoverColor="white"
          onClick={onManualBank}
          variant="outlined"
        >
          <ButtonContent>
            <Typography color="grey1" variant={TypographyVariant.Body}>
              Manually link bank account
            </Typography>
          </ButtonContent>
        </Button>
      </Box>
    </>
  );

  return (
    <FeatureFlag
      disabled={
        <Styled.Container p={3} width={450}>
          {content}
        </Styled.Container>
      }
      enabled={
        <Container>
          <Box p={2.5}>{content}</Box>
        </Container>
      }
      flag={FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED}
    />
  );
};

Interstitial.propTypes = {
  onConnectBank: PropTypes.func.isRequired,
  onManualBank: PropTypes.func.isRequired,
};

export { Interstitial };
