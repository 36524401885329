import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useChartData = (categoryId: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  return useQuery([ReactQueryKeys.ChartData, categoryId], async () => {
    const response = await treasureApi().get(`/charts/${categoryId}`);

    return response.data;
  });
};
