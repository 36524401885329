import React from 'react';
import { Typography, TypographyVariant } from 'components/core';
import { Box } from '@material-ui/core';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as ArrowBackwardIcon } from 'assets/icons/arrows/arrow-left.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrows/arrow-right.svg';
import { ReactComponent as Checkmark } from 'assets/icons/checkmarks/checkmark.svg';
import { ReactComponent as Plus } from 'assets/icons/other/plus.svg';

export enum SupportedIcons {
  Add = 'add',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  Checkmark = 'checkmark',
}

interface Props {
  iconStart?: SupportedIcons | React.ReactNode;
  iconEnd?: SupportedIcons | React.ReactNode;
  mobile?: boolean;
}

export const ButtonContent: React.FC<Props> = ({
  children,
  iconStart,
  iconEnd,
  mobile,
}) => {
  const conditionalProps = mobile
    ? {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }
    : {};

  const resolveIconComponent = (icon: SupportedIcons | React.ReactNode) => {
    if (typeof icon === 'string') {
      switch (icon) {
        case SupportedIcons.Add:
          return <Plus />;
        case SupportedIcons.ArrowLeft:
          return <ArrowBackwardIcon />;
        case SupportedIcons.ArrowRight:
          return <ArrowForwardIcon />;
        case SupportedIcons.Checkmark:
          return <Checkmark />;
        default:
          return null;
      }
    } else {
      const IconComponent = icon as React.ComponentType;
      return <IconComponent />;
    }
  };

  return (
    <Box px={mobile ? 2.5 : 2} py={mobile ? 2.5 : 1.5} {...conditionalProps}>
      {iconStart && (
        <SVGBox
          display="inline-block"
          height={14}
          mr={1}
          position="relative"
          strokeColor="white"
          top={2}
          width={18}
        >
          {resolveIconComponent(iconStart)}
        </SVGBox>
      )}

      <Typography color="white" variant={TypographyVariant.Paragraph2}>
        {children}
      </Typography>

      {iconEnd && (
        <SVGBox
          display="inline-block"
          height={14}
          ml={1}
          position="relative"
          top={2}
          strokeColor="white"
          width={18}
        >
          {resolveIconComponent(iconEnd)}
        </SVGBox>
      )}
    </Box>
  );
};
