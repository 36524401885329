import styled from 'styled-components';
import {
  CartesianGridStroke,
  TickStyle as DefaultTickStyle,
} from 'components/charts/v3/common/charts.common.style';
import { getColor } from 'styles/css-constants';
import { Box } from '@material-ui/core';

const ChartContainer = styled(Box)`
  & .recharts-cartesian-grid {
    line:last-child {
      display: none;
    }
  }

  .recharts-text.recharts-label {
    fill: var(--color-gothic);
    font-size: var(--font-size-body-02);
  }
`;

const FillActuals = getColor('mintGreen');

const FillProjected = getColor('gallery');

const PastLineStroke = getColor('christalle');

const TickStyle = {
  ...DefaultTickStyle,
  fontSize: '12px',
};

export const Styled = {
  CartesianGridStroke,
  ChartContainer,
  ChartHeight: 250,
  FillActuals,
  FillProjected,
  PastLineStroke,
  TickStyle,
};
