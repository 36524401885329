import React, { useEffect } from 'react';
import { SignInForm, TreasureLogoBox } from 'modules/no-auth';
import { useLocation } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

interface LocationState {
  idleSignedOut: boolean;
}

export const SignIn: React.FC = () => {
  const location = useLocation<LocationState>();
  const { idleSignedOut } = location?.state || {};

  const footerLinks = [
    {
      path: RoutesPath.noAuth.forgotPassword.path,
      text: 'Forgot your password?',
    },
    {
      path: RoutesPath.noAuth.signUp.path,
      text: "Don't have an account? Sign up",
    },
  ];

  // we're adding idleSignedOut to the react-router-dom location state
  // this state persists when a user reloads the page
  // replacing the history without state will remove idleSignedOut
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <TreasureLogoBox
      headingText={idleSignedOut ? "You've been signed out" : 'Welcome Back'}
      footerLinks={footerLinks}
    >
      <SignInForm />
    </TreasureLogoBox>
  );
};
