import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useProductPositions = (): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery([ReactQueryKeys.TreasureReservePositions], async () => {
    const response = await treasureApi().get(`/products/positions`);

    return response.data;
  });
};
