import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createCtx } from 'utils/create-ctx';

interface LoadingContext {
  loading: boolean;

  setLoading: (loading: boolean) => void;
}

export const [useLoadingContext, LoadingCtxProvider] =
  createCtx<LoadingContext>();

export const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const { hash, pathname } = useLocation();

  // scroll restoration
  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 0);
  }, [hash, loading, pathname]);

  return (
    <LoadingCtxProvider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoadingCtxProvider>
  );
};
