import React, { useState } from 'react';
import { ClickAnchor, Modal, Text } from 'components/core';
import { TreasureReservePositions } from 'modals/treasure-reserve-positions';
import { TreasureReservePositionsPortfolio } from 'modules/treasure-reserve-positions/components/treasure-reserve-positions-portfolio/treasure-reserve-positions-portfolio';
import { Size } from 'enums';

interface Props {
  hasPortfolio?: boolean;
}

export const PositionsModal: React.FC<Props> = ({ hasPortfolio }) => {
  const [showPositionsModal, setShowPositionsModal] = useState(false);

  return (
    <>
      <ClickAnchor onClick={() => setShowPositionsModal(true)}>
        <Text color="electricViolet" variant={2}>
          View positions &gt;
        </Text>
      </ClickAnchor>

      <Modal
        open={showPositionsModal}
        onClose={() => {
          setShowPositionsModal(false);
        }}
        size={hasPortfolio ? Size.Medium : Size.Small}
      >
        {hasPortfolio ? (
          <TreasureReservePositionsPortfolio />
        ) : (
          <TreasureReservePositions />
        )}
      </Modal>
    </>
  );
};
