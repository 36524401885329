import React from 'react';
import { TypographyVariant } from 'components/core';
import { Styled } from './transaction-status.style';

interface TransactionStatusProps {
  status: string;
}

export const TransactionStatus: React.FC<TransactionStatusProps> = ({
  status,
}) => {
  // TODO: update TransactionStatus enum
  return (
    <Styled.StatusContainer mx="auto" py={1} textAlign="center" status={status}>
      <Styled.Status status={status} variant={TypographyVariant.LargeLabel}>
        {status === 'COMPLETED' ? 'Complete' : status}
      </Styled.Status>
    </Styled.StatusContainer>
  );
};
