import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  Button,
  CurrencyVariant,
  Typography,
  TypographyVariant,
  Spinner,
} from 'components/core';
import { AutocompleteVariants } from 'components/core/forms/autocomplete/autocomplete';
import { useForm } from 'react-hook-form';
import {
  SelectFieldController,
  TextFieldController,
} from 'modules/form-helpers';
import { ReactQueryKeys } from 'enums';
import { TrackEventName } from 'modules/tracking';
import { TransferSteps } from 'pages/v3-portal/transfer/enums/transfer-steps';
import { TransferConfirmation } from 'modules/ach/transfer-confirmation';
import { Allocation, MFA } from './components';
import { useSweep } from 'hooks/portal-gateway/use-sweep';
import { BankAccount, TreasureReserve } from 'types';
import {
  BankAccountPropTypes,
  TreasureReservePropTypes,
} from 'types/prop-types';
import {
  useOnboardingContext,
  useOnboardingTransferContext,
} from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { formFields } from './transfer-widget-form-fields';
import { Styled } from './transfer-widget-form.style';
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from 'react-query';
import { useAddedInitialSweep } from 'modules/onboarding/v3-streamlined-onboarding/hooks/mutate-added-initial-sweep';

interface TransferWidgetForm {
  transferAmount: number;
  fromAccount: string;
  toAccount: string;
}

interface TransferWidgetFormProps {
  accounts: BankAccount[];
  treasureReserve: TreasureReserve;
  fieldsDisabled: boolean;
  submitDisabled: boolean;
  currentStep: any;
  setNextStep: (step: TransferSteps) => void;
  setSubmitDisabled: (disabled: boolean) => void;
  customSendMFA?: () => void;
  customConfirmMFA?: () => void;
  onMFASuccess?: () => void;
}

const gridDirection = 'column';

const TransferWidgetForm: React.FC<TransferWidgetFormProps> = ({
  accounts,
  treasureReserve,
  submitDisabled,
  fieldsDisabled,
  currentStep,
  setNextStep,
  setSubmitDisabled,
  customSendMFA,
  customConfirmMFA,
}) => {
  const defaultValues = { amount: '', toAccount: '', fromAccount: '' };
  const { control, handleSubmit, getValues, setValue, watch, reset } =
    useForm<TransferWidgetForm>({
      defaultValues,
    });
  const watchFromAccount = watch('fromAccount');
  const watchToAccount = watch('toAccount');
  const [confirmationReset, setConfirmationReset] = useState(true);
  const [loading, setLoading] = useState(false);

  const onboardingContext = useOnboardingContext();
  const transferContext = useOnboardingTransferContext();
  const queryClient = useQueryClient();
  const mutateAddedInitialSweep = useAddedInitialSweep();

  const sweep = useSweep();

  useEffect(() => {
    if (currentStep.step === TransferSteps.TransferSettings) {
      setValue('toAccount', treasureReserve.id);
    }
  }, [currentStep, transferContext]);

  const handleDuplicateAccountSelection = () => {
    return !!(
      watchFromAccount &&
      watchToAccount &&
      watchFromAccount === watchToAccount
    );
  };

  const onMFASuccess = async (token?: string) => {
    setLoading(true);
    if (token) {
      await sweep.mutate(
        {
          amount: getValues('transferAmount'),
          bankAccountId: getValues('fromAccount'),
          businessId: onboardingContext.businessId,
          idempotencyKey: uuidv4(),
        },
        {
          onSuccess: (initialSweepResponse) => {
            queryClient.setQueryData(ReactQueryKeys.InitialOnboardingSweep, {
              ...initialSweepResponse,
              accountName: getBankAccounts().Name,
            });
          },
        },
      );

      mutateAddedInitialSweep.mutate({ addedInitialSweep: true });

      transferContext.trackEvent({
        eventName: TrackEventName.OnboardingSweepCompleted,
      });

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getFromOptions = () => {
    // if the smb doesn't have a pda, return all the checking and savings accounts available
    return accounts
      .filter((account) => ['checking', 'savings'].includes(account.Type))
      .map((account) => {
        return {
          label: account.Name,
          description: `${account.Type} ${account.Mask}`,
          value: account.Id,
        };
      });
  };

  const getToOptions = () => {
    return [
      {
        label: 'Treasure Reserve',
        description: '',
        value: treasureReserve.id,
      },
    ];
  };

  const getBankAccounts = () => {
    const account = accounts.find(
      (account) => account.Id === getValues('fromAccount'),
    );

    if (account === undefined) {
      throw new TypeError(
        `Account with id: ${getValues(
          'fromAccount',
        )} expected during transfer.`,
      );
    }

    return account;
  };

  const renderSmallTransferCopy = () => {
    return (
      <>
        <Styled.Currency
          number={getValues('transferAmount')}
          variant={CurrencyVariant.Full}
        />{' '}
        will be deducted from your bank account within the next day.
      </>
    );
  };

  const renderTransferReviewCopy = () => {
    return (
      <Styled.TransferReview
        color="nero"
        variant={TypographyVariant.Body}
        className={
          currentStep.step === TransferSteps.TransferReview ? 'show' : ''
        }
      >
        {renderSmallTransferCopy()}
      </Styled.TransferReview>
    );
  };

  const renderTransferForm = () => {
    const amountIsRequired = `${formFields.transferAmount.label} is required`;

    return (
      <>
        <Grid container direction={gridDirection}>
          <Grid item>
            <TextFieldController
              {...formFields.transferAmount}
              control={control}
              disabled={fieldsDisabled}
              validations={{
                required: amountIsRequired,
                validate: (value) => {
                  // handle 0 submission
                  if (value <= 0.0) {
                    return amountIsRequired;
                  }

                  return true;
                },
              }}
            />
          </Grid>

          <Styled.AccountSelectContainer fixedSelection={fieldsDisabled} item>
            <SelectFieldController
              {...formFields.fromAccount}
              control={control}
              disabled={fieldsDisabled}
              options={getFromOptions()}
              value={''}
              autocompleteVariant={AutocompleteVariants.multipleBankAccounts}
            />
          </Styled.AccountSelectContainer>

          <Styled.AccountSelectContainer
            fixedSelection={fieldsDisabled || true} // currently hardcoded to Treasure Reserve
            item
          >
            <SelectFieldController
              {...formFields.toAccount}
              control={control}
              disabled={fieldsDisabled || true} // currently hardcoded to Treasure Reserve
              options={getToOptions()}
              value={treasureReserve.id}
              autocompleteVariant={AutocompleteVariants.multipleBankAccounts}
            />
          </Styled.AccountSelectContainer>

          <Grid item>
            <Allocation />
          </Grid>
        </Grid>

        {renderTransferReviewCopy()}
      </>
    );
  };

  const renderSMSForm = () => {
    return (
      <>
        <Typography color="nero" variant={TypographyVariant.Body}>
          We’ve emailed you a code to confirm your transfer. Check your email
          and enter it here.
        </Typography>
        <MFA
          amount={getValues('transferAmount')}
          bankAccount={getBankAccounts()}
          customSendMFA={customSendMFA}
          customConfirmMFA={customConfirmMFA}
          onMFASuccess={onMFASuccess}
        />
      </>
    );
  };

  const renderForm = () => {
    switch (currentStep.step) {
      case TransferSteps.TransferSettings:
        if (!confirmationReset) {
          setConfirmationReset(true);
        }
        return renderTransferForm();
      case TransferSteps.TransferReview:
        return renderTransferForm();
      case TransferSteps.TransferSMS:
        return renderSMSForm();
      default:
        break;
    }
  };

  const renderCTA = () => {
    if (!currentStep?.submitCTA) {
      return;
    }
    return (
      <Styled.ButtonContainer>
        <Button
          type="submit"
          disabled={submitDisabled || handleDuplicateAccountSelection()}
        >
          {currentStep.submitCTA}
        </Button>
      </Styled.ButtonContainer>
    );
  };

  const onSubmit = async () => {
    transferContext.trackEvent({
      eventName: currentStep.trackEvent,
      amount: getValues('transferAmount') * 100, // api expects the amount to be in cents
      fromAccount: getValues('fromAccount'),
      toAccount: getValues('toAccount'),
    });

    setNextStep(currentStep.nextStep);
  };

  return (
    <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
      {loading ? (
        <Styled.SpinnerContainer>
          <Spinner />
        </Styled.SpinnerContainer>
      ) : (
        <>
          <>{renderForm()}</>
          {renderCTA()}
        </>
      )}
    </Styled.FormContainer>
  );
};

TransferWidgetForm.propTypes = {
  accounts: PropTypes.arrayOf(BankAccountPropTypes.isRequired).isRequired,
  treasureReserve: TreasureReservePropTypes.isRequired,
  fieldsDisabled: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  currentStep: PropTypes.object.isRequired,
  setNextStep: PropTypes.func.isRequired,
  setSubmitDisabled: PropTypes.func.isRequired,
  customSendMFA: PropTypes.func,
  customConfirmMFA: PropTypes.func,
  onMFASuccess: PropTypes.func,
};

export { TransferWidgetForm };
