import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Hidden } from '@material-ui/core';
import {
  Button,
  Currency,
  CurrencyVariant,
  Heading,
  ItemDefinition,
  Logo,
  LogoVariant,
  Percentage,
  Spinner,
} from 'components/core';
import { TreasureReserveStatus } from 'enums';
import { Footnote } from 'components/layouts';
import { TreasureReserve } from 'types';
import { TreasureReservePropTypes } from 'types/prop-types';
import { useTranslation } from 'app/i18n';
import { useHistory, useLocation } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';

// wire fee
import { WIRE_FEE } from 'modules/sweeps/constants/wire-fee';
import { getRateScale } from 'utils/cash-rate-scale';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';

import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { BalanceBreakdown } from './components/balance-breakdown';

import { Styled } from './treasure-reserve-row.style';

export interface TreasureReserveRowProps {
  /** treasure reserve data */
  data: TreasureReserve;

  /** idle cash */
  idleCash: number;
}

/**
 * MF: Created a Treasure Reserve Row which duplicates some code from Banking Account Row
 * The treasure reserve object is different than a bank account but visually they're pretty similiar
 * I could see pros/cons of either approach or even abstracting another layer and creating a component
 * per type.
 */

const TreasureReserveRow: React.FC<TreasureReserveRowProps> = ({
  data,
  idleCash,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  // TODO: for now, we are using the top indicators response to get the treasure reserve balance
  // with treasure cash
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );
  const treasureCashRate = portfolios?.rates.cashRate * 100;
  const blendedRate =
    portfolios?.actualBlendedRate > 0
      ? portfolios?.actualBlendedRate * 100
      : treasureCashRate;

  const hasOpenTreasureReserve =
    data.status === TreasureReserveStatus.OpenAndUnfunded ||
    data.status === TreasureReserveStatus.OpenAndFunded;

  const treasureReserveBalanceAsNumber =
    portfolios?.amountsInDollars.totalAmount;

  const handleTransferButtonClick = () => {
    history.push(
      getVersionedPath({ path: RoutesPath.pages.transfer.path, version: 3 }),
    );
  };

  const handleWithdrawButtonClick = () => {
    history.push({
      pathname: RoutesPath.tasks.withdrawal.path,
      state: { from: location },
    });
  };

  const fundedTreasureReserveBody = () => {
    const currentlyEarning = (
      <FeatureFlag
        disabled={
          <Footnote
            description={t('high yield description', { ns: 'compliance' })}
            stayOnScreen
            symbol="*"
          >
            Current Yield
          </Footnote>
        }
        enabled={<>Currently Earning</>}
        flag={FeatureFlags.REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED}
      />
    );

    const displayData = {
      leftSide: {
        copy: 'Net Deposits',
        value: data.netDeposits,
      },
      percentageCircle: {
        copy: currentlyEarning,
        value: blendedRate,
      },
      rightSide: {
        copy: 'Dividends Earned',
        value: data.dividends,
      },
    };

    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          {displayData.leftSide.value ? (
            <Box mt={{ xs: 2, md: 0 }}>
              <ItemDefinition
                label={displayData.leftSide.copy}
                value={
                  <Heading variant={2}>
                    <Currency
                      number={displayData.leftSide.value}
                      variant={CurrencyVariant.Full}
                    />
                  </Heading>
                }
              />
            </Box>
          ) : (
            ''
          )}
        </Grid>

        {blendedRate > 0 && (
          <Grid item xs={12} md={4}>
            <Box mt={{ xs: 2, md: 0 }}>
              <ItemDefinition
                label={displayData.percentageCircle.copy}
                value={
                  <Heading variant={2}>
                    <Percentage
                      decimalScale={2}
                      number={displayData.percentageCircle.value}
                      showExtremePrecision
                    />
                  </Heading>
                }
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          {displayData.rightSide.value ? (
            <Box mt={{ xs: 2, md: 0 }}>
              <ItemDefinition
                label={displayData.rightSide.copy}
                value={
                  <Heading variant={2}>
                    <Currency
                      number={displayData.rightSide.value}
                      variant={CurrencyVariant.Full}
                    />
                  </Heading>
                }
              />
            </Box>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    );
  };

  const unfundedTreasureReserveBody = () => {
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Hidden smDown>
          <Grid item xs={3} />
        </Hidden>

        {treasureCashRate > 0 && (
          <Grid item xs>
            <Box textAlign="center">
              <ItemDefinition
                label="Current Yield"
                value={
                  <Heading variant={3}>
                    <Percentage
                      decimalScale={2}
                      number={treasureCashRate}
                      showExtremePrecision
                    />
                  </Heading>
                }
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={3}>
          {idleCash > 0 && (
            <ItemDefinition
              label="Idle Cash"
              value={
                <Heading variant={2}>
                  <Currency
                    number={idleCash}
                    variant={CurrencyVariant.NearestDollar}
                  />
                </Heading>
              }
            />
          )}
        </Grid>
      </Grid>
    );
  };

  if (isLoadingBusiness || isLoadingPortfolios) {
    return (
      <Styled.Row>
        <Box p={{ xs: 2, lg: 3 }}>
          <Spinner />
        </Box>
      </Styled.Row>
    );
  }

  return (
    <Styled.Row>
      <Box p={{ xs: 2, lg: 3 }}>
        <Grid container>
          <Hidden xsDown>
            <Grid item>
              <Box mr={3}>
                <Logo
                  institution={{ logo: '', name: 'Treasure' }}
                  variant={LogoVariant.square}
                />
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs>
            <Grid container>
              <Hidden smUp>
                <Grid item>
                  <Box mr={3}>
                    <Logo
                      institution={{ logo: '', name: 'Treasure' }}
                      variant={LogoVariant.square}
                    />
                  </Box>
                </Grid>
              </Hidden>

              <Grid item xs>
                <Box mb={3}>
                  <Styled.TreasureReserveHeading color="nero" variant={4}>
                    Treasure Reserve
                  </Styled.TreasureReserveHeading>
                </Box>
              </Grid>

              <Hidden xsDown mdUp>
                <Grid item xs={4}>
                  {hasOpenTreasureReserve && (
                    <Box textAlign="right">
                      <FeatureFlag
                        disabled={
                          <Button onClick={handleTransferButtonClick}>
                            {treasureReserveBalanceAsNumber - WIRE_FEE > 0
                              ? 'Withdraw Cash'
                              : 'Fund Your Account'}
                          </Button>
                        }
                        enabled={
                          <Button onClick={handleWithdrawButtonClick}>
                            {treasureReserveBalanceAsNumber - WIRE_FEE > 0
                              ? 'Withdraw Cash'
                              : 'Fund Your Account'}
                          </Button>
                        }
                        flag={
                          FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED
                        }
                      />
                    </Box>
                  )}
                </Grid>
              </Hidden>
            </Grid>

            <Grid container alignItems="flex-start">
              <Grid item xs={12} md={3}>
                <ItemDefinition
                  label="Balance"
                  value={
                    <Heading variant={2}>
                      <Currency
                        number={treasureReserveBalanceAsNumber}
                        variant={CurrencyVariant.Full}
                      />
                    </Heading>
                  }
                />

                <Box mt={2}>
                  <BalanceBreakdown />
                </Box>
              </Grid>

              <Grid item xs>
                {treasureReserveBalanceAsNumber <= 0
                  ? unfundedTreasureReserveBody()
                  : fundedTreasureReserveBody()}
              </Grid>

              <Hidden xsDown>
                <Grid item xs={3}>
                  {hasOpenTreasureReserve && (
                    <Hidden smDown>
                      <Box textAlign="right">
                        <FeatureFlag
                          disabled={
                            <Button onClick={handleTransferButtonClick}>
                              {treasureReserveBalanceAsNumber - WIRE_FEE > 0
                                ? 'Withdraw Cash'
                                : 'Fund Your Account'}
                            </Button>
                          }
                          enabled={
                            <Button onClick={handleWithdrawButtonClick}>
                              {treasureReserveBalanceAsNumber - WIRE_FEE > 0
                                ? 'Withdraw Cash'
                                : 'Fund Your Account'}
                            </Button>
                          }
                          flag={
                            FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED
                          }
                        />
                      </Box>
                    </Hidden>
                  )}
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Styled.Row>
  );
};

TreasureReserveRow.propTypes = {
  data: TreasureReservePropTypes.isRequired,
  idleCash: PropTypes.number.isRequired,
};

export { TreasureReserveRow };
