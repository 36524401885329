import React, { useEffect, useState } from 'react';
import {
  i18nNamespace,
  DepositWithdrawalSteps,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { dayjs } from 'app/dayjs';
import { useLocation } from 'react-router-dom';
import { Header } from 'modules/2023-q3/deposit-withdrawal/components/header/header';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import {
  FailedRetry,
  Processing,
} from 'modules/2023-q3/deposit-withdrawal/pages';
import { Form } from 'modules/2024-q4/apex-ach/deposit-apex-ach/pages/form/form';
import { Review } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/pages/review/review';
import { Confirmation } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/pages/confirmation/confirmation';
import { MFA } from 'modules/2023-q3/components/mfa/mfa';
import { BankAccount } from 'types';
import { useBusiness } from 'hooks/business/use-business';
import { useSendSweepMFACode } from 'modules/2023-q3/deposit-withdrawal/hooks/use-send-sweep-mfa-code';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmNavigateAway } from 'modules/2023-q3/components/confirm-navigate-away/confirm-navigate-away';
import { ConfirmationModal } from 'modules/2023-q3/deposit-withdrawal/components/confirmation-modal/confirmation-modal';
import { i18n, useTranslation } from 'app/i18n';
import DepositWithdrawalContent from 'modules/2023-q3/deposit-withdrawal/content/deposit-withdrawal.content.json';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Redirect } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { useTracking, TrackEventName } from 'modules/tracking';
import { SelectSwitchOption } from 'modules/2023-q3/components/forms/components/select-switch/select-switch';
import { useCreateScheduleTransfer } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/hooks/use-create-schedule-transfer';
import { FrequencyOptions } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/pages/form/components/select-frequency/select-frequency';
import { ApexWireInstructions } from 'modules/2024-q3/grasshopper-sunset/deposit/deposit-apex-wire-instructions/components/apex-wire-instructions/apex-wire-instructions';
import { WireInstructionsLink } from 'modules/2024-q4/apex-ach/deposit-apex-ach/pages/wire-instructions/wire-instructions-link';
import { Currency, CurrencyVariant } from 'components/core';

i18n.addResourceBundle('en', i18nNamespace, DepositWithdrawalContent);

interface DepositProps {
  hideContainer?: boolean;
  hideHeader?: boolean;
  onStepChange?: (step: number) => void;
}

export const Deposit: React.FC<DepositProps> = ({
  hideContainer,
  hideHeader,
  onStepChange,
}) => {
  const { state }: { state: any } = useLocation();
  const from = state?.from;

  const { t } = useTranslation(i18nNamespace);

  const { data: business } = useBusiness();
  const sendSweepMFA = useSendSweepMFACode();
  const createScheduleTransfer = useCreateScheduleTransfer();

  const [amount, setAmount] = useState<number | undefined>();
  const [bank, setBank] = useState<BankAccount | undefined>();
  const [frequency, setFrequency] = useState<SelectSwitchOption>(
    FrequencyOptions[0],
  );
  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD'),
  );
  const [step, setStep] = useState(DepositWithdrawalSteps.Form);

  const { Track, trackEvent } = useTracking<{
    eventName?: TrackEventName;
    step: string;
  }>({
    step: DepositWithdrawalSteps[step],
  });

  const maxAchAmount = 100000;

  const isOverMaxAmount =
    typeof amount !== 'undefined' && amount > maxAchAmount;

  const handleMFASuccess = async (token?: string) => {
    setStep(DepositWithdrawalSteps.Processing);

    try {
      await createScheduleTransfer.mutate(
        {
          amount: amount,
          authToken: token, // TODO: PortalGateway doesn't support token swap
          bankAccountId: bank?.Id,
          direction: 'DEBIT',
          frequency: frequency?.value,
          idempotencyKey: uuidv4(),
          startDate: startDate,
        },
        {
          onSuccess: () => {
            setStep(DepositWithdrawalSteps.Confirmation);
          },
          onError: () => {
            setStep(DepositWithdrawalSteps.FailedRetry);
          },
        },
      );
    } catch (e: any) {
      // mutateAsync returns a promise and without this try/catch,
      // it's unhandled and triggers our error boundary.
      // TODO: is there a better way to handle this?
      setStep(DepositWithdrawalSteps.FailedRetry);
    }
  };

  const nextStep = () => {
    switch (step) {
      case DepositWithdrawalSteps.Form:
        return setStep(DepositWithdrawalSteps.Review);
      case DepositWithdrawalSteps.Review:
        return setStep(DepositWithdrawalSteps.MFA);
      case DepositWithdrawalSteps.Confirmation:
        setAmount(undefined); // reset the amount for the next deposit
        return setStep(DepositWithdrawalSteps.Form);
      default:
        return null;
    }
  };

  const previousStep = () => {
    switch (step) {
      case DepositWithdrawalSteps.WireInstructions:
        return setStep(DepositWithdrawalSteps.Form);
      case DepositWithdrawalSteps.Form:
        // hijacking this to show Wire Instructions
        return setStep(DepositWithdrawalSteps.WireInstructions);
      case DepositWithdrawalSteps.Review:
        return setStep(DepositWithdrawalSteps.Form);
      case DepositWithdrawalSteps.MFA:
        return setStep(DepositWithdrawalSteps.Review);
      default:
        return null;
    }
  };

  const displayStep = (step: DepositWithdrawalSteps) => {
    switch (step) {
      case DepositWithdrawalSteps.Review:
        return (
          <Review
            amount={amount}
            bank={bank}
            frequency={frequency}
            nextStep={nextStep}
            previousStep={previousStep}
            startDate={startDate}
            variant={DepositWithdrawalVariant.Deposit}
          />
        );
      case DepositWithdrawalSteps.MFA:
        return (
          <MFA
            customSendMFA={{
              mutationResult: sendSweepMFA,
              variables: {
                transferType: DepositWithdrawalVariant.Deposit,
              },
            }}
            onMFASuccess={handleMFASuccess}
            onPrevious={previousStep}
          />
        );
      case DepositWithdrawalSteps.Processing:
        return <Processing variant={DepositWithdrawalVariant.Deposit} />;
      case DepositWithdrawalSteps.Confirmation:
        return (
          <Confirmation
            from={from}
            nextStep={nextStep}
            startDate={startDate}
            variant={DepositWithdrawalVariant.Deposit}
          />
        );
      case DepositWithdrawalSteps.FailedRetry:
        return (
          <FailedRetry
            onRetry={handleMFASuccess}
            variant={DepositWithdrawalVariant.Deposit}
          />
        );
      case DepositWithdrawalSteps.WireInstructions:
        return <ApexWireInstructions previousStep={previousStep} />;
      default:
        return (
          <Form
            amount={amount}
            bank={bank}
            isOverMaxAmount={isOverMaxAmount}
            overMaxAmountMessage={
              <>
                To deposit more than{' '}
                <Currency
                  number={maxAchAmount}
                  variant={CurrencyVariant.Full}
                />
                ,{' '}
                <WireInstructionsLink
                  onClick={previousStep}
                  text="please use a wire"
                />
                .
              </>
            }
            frequency={frequency}
            nextStep={nextStep}
            previousStep={previousStep}
            setAmount={setAmount}
            setBank={setBank}
            setFrequency={setFrequency}
            setStartDate={setStartDate}
            startDate={startDate}
            variant={DepositWithdrawalVariant.Deposit}
          />
        );
    }
  };

  useEffect(() => {
    if (typeof onStepChange !== 'undefined') {
      onStepChange(step);
    }

    trackEvent({
      eventName: TrackEventName.Viewed,
      step: DepositWithdrawalSteps[step],
    });
  }, [step]);

  return (
    <FeatureFlag
      disabled={<Redirect to={RoutesPath.pages.home.path} />}
      enabled={
        <Track>
          <MobileView
            mobile={
              <ConfirmNavigateAway
                when={
                  amount !== undefined && step === DepositWithdrawalSteps.Form
                }
                modal={
                  <ConfirmationModal
                    variant={DepositWithdrawalVariant.Deposit}
                  />
                }
              />
            }
          >
            <ConfirmNavigateAway
              when={
                amount !== undefined &&
                step !== DepositWithdrawalSteps.Confirmation
              }
              modal={
                <ConfirmationModal variant={DepositWithdrawalVariant.Deposit} />
              }
            />
          </MobileView>

          {!hideHeader && (
            <Header
              from={from}
              previousStep={previousStep}
              step={step}
              variant={DepositWithdrawalVariant.Deposit}
            />
          )}

          {hideContainer ? (
            displayStep(step)
          ) : (
            <CenteredContainer>{displayStep(step)}</CenteredContainer>
          )}
        </Track>
      }
      flag={FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED}
    />
  );
};
