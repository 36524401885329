import React from 'react';
import { Typography, TypographyVariant } from 'components/core';

interface TableTextProps {
  capitalize?: boolean;

  children: React.ReactNode;
}

export const TableText: React.FC<TableTextProps> = ({
  children,
  capitalize,
}) => {
  return (
    <Typography
      capitalize={capitalize}
      color="grey1"
      variant={TypographyVariant.Paragraph2}
    >
      {children}
    </Typography>
  );
};
