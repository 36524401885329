import styled from 'styled-components';
import { ToggleButton } from '@material-ui/lab';

const ButtonToggle = styled(ToggleButton)`
  border-radius: 100px;
  padding: 6px 16px;
`;

export const Styled = {
  ButtonToggle,
};
