import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Typography } from 'components/core';

const ConfirmationContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Typography)`
  margin-bottom: 4px;
`;

const ConfirmationImageContainer = styled(Box)`
  margin-bottom: 24px;
`;

const VerticalStepperContainer = styled(Box)`
  max-width: 80%;
`;

const TransferTable = styled.table`
  border: 1px solid var(--color-lightGrey);
  border-collapse: collapse;
  border-radius: 4px;
  display: table;
  margin: 0 0 24px 0;
  width: fit-content;

  th {
    h5 {
      color: var(--color-regalBlue);
      text-transform: none;
    }
  }

  th,
  td {
    border: 1px solid var(--color-lightGrey);
    padding: 10px 14px;

    &:nth-child(1) {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: right;
    }

    span {
      color: var(--color-regalBlue);
      font-size: 12px;
    }
  }
`;

const MaxTransferAlert = styled(Typography)`
  font-size: 13px;
`;

export const Styled = {
  ConfirmationImageContainer,
  Header,
  ConfirmationContainer,
  MaxTransferAlert,
  TransferTable,
  VerticalStepperContainer,
};
