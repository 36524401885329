import React from 'react';
import {
  i18nNamespace,
  AllocationSteps,
} from 'modules/2024-q1/allocation-by-amount-or-percentage/allocation';
import { useTranslation } from 'app/i18n';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { TaskHeader } from 'modules/task/components/task-header/task-header';
import { TaskProgress } from 'modules/task/components/task-progress/task-progress';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { useTracking, TrackEventName } from 'modules/tracking';

interface IHeader {
  from: { pathname: string };

  previousStep: () => void;

  step: AllocationSteps;
}

export const Header: React.FC<IHeader> = ({ from, previousStep, step }) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation(i18nNamespace);
  const history = useHistory();

  const getActiveStep = (step: AllocationSteps) => {
    switch (step) {
      case AllocationSteps.Review:
        return 1;
      case AllocationSteps.Confirmation:
        return 2;
      default:
        return 0;
    }
  };

  const getButtonText = (getButtonTextProps?: { mobile?: boolean }) => {
    if (step === AllocationSteps.Confirmation) {
      return t('Done', { ns: 'app' });
    }

    if (getButtonTextProps?.mobile) {
      if (step === AllocationSteps.Form) {
        return t('Cancel', { ns: 'app' });
      }

      return t('Back', { ns: 'app' });
    }

    return t('Cancel Allocation');
  };

  const handleMobileButtonClick = () => {
    // as user does not have uncommitted work if they are on the first or last page
    const uncommittedWork =
      step !== getActiveStep(AllocationSteps.Form) &&
      step !== getActiveStep(AllocationSteps.Confirmation);

    if (!uncommittedWork) {
      // if no uncommitted work we can go back to the dashboard
      history.push(RoutesPath.pages.dashboard.path);
    } else {
      // otherwise navigate to the previous step
      previousStep();
    }
  };

  return (
    <MobileView
      mobile={
        <TaskHeader
          mobile
          button={
            <Button
              bgColor="brown6"
              bgHoverColor="brown4b"
              onClick={handleMobileButtonClick}
            >
              <Box px={3} py={1}>
                <Typography
                  color="black19"
                  variant={TypographyVariant.Paragraph2}
                >
                  {getButtonText({ mobile: true })}
                </Typography>
              </Box>
            </Button>
          }
          taskProgress={
            <TaskProgress activeStep={getActiveStep(step)} steps={2} />
          }
          title={t('Portfolio Allocation')}
        />
      }
    >
      <TaskHeader
        button={
          <Link to={from?.pathname || RoutesPath.pages.dashboard.path}>
            <Button bgColor="brown6" bgHoverColor="brown4b">
              <Box px={2} py={1}>
                <Typography
                  color="black19"
                  variant={TypographyVariant.Paragraph2}
                >
                  {getButtonText()}
                </Typography>
              </Box>
            </Button>
          </Link>
        }
        taskProgress={
          <TaskProgress activeStep={getActiveStep(step)} steps={2} />
        }
        title={t('Portfolio Allocation')}
      />
    </MobileView>
  );
};
