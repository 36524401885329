import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { PlaidAccountStatus, ReactQueryKeys } from 'enums';

export const useSetBankingItemStatus = (): UseMutationResult<any, any, any> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  const patchBankingItemStatus = ({ bankItemId }: { bankItemId: string }) =>
    treasureApi().patch(`/item/${bankItemId}/status`, {
      status: PlaidAccountStatus.PENDING_READY,
    });

  return useMutation(patchBankingItemStatus, {
    onSuccess: () => {
      return queryClient.invalidateQueries([ReactQueryKeys.BankAccounts]);
    },
  });
};
