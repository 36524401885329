import React from 'react';
import { Box } from '@material-ui/core';
import { CurrencyVariant } from 'components/core';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { useSendEvent, TrackEventName } from 'modules/tracking';
import { i18n, useTranslation, Trans } from 'app/i18n';
import { Styled } from 'modules/ach/transfer-widget-v2/components/transfer-widget-form/components/transfer-copy/render-transfer-copy.style';
import Content from './liquidation-withdrawal.content.json';

const i18nLiquidationWithdrawal = 'liquidation-withdrawal';
i18n.addResourceBundle('en', i18nLiquidationWithdrawal, Content);

export const LiquidationWithdrawal: React.FC<{
  amount: number;
  apexAvailableBalance: number;
  ghbAvailableBalance: number;
}> = ({ amount, apexAvailableBalance, ghbAvailableBalance }) => {
  const { t } = useTranslation(i18nLiquidationWithdrawal);
  const { sendEvent } = useSendEvent();

  const oneDayWithdrawalAmount = Math.min(ghbAvailableBalance, amount);
  const threeDayWithdrawalAmount = Math.min(
    apexAvailableBalance,
    amount - oneDayWithdrawalAmount,
  );

  const emailSubject = 'Withdrawal';
  const handleTrackEmailClick = () => {
    sendEvent(TrackEventName.ContactSupportClicked, {
      emailSubject,
    });
  };

  return (
    <FeatureFlag
      disabled={
        <Box display="block">
          <Box>
            We are working on building self-service 3 Day liquidity withdrawals.
            In the meantime please email us at{' '}
            <a
              href={`mailto:support@treasure.tech?subject=${emailSubject}`}
              onClick={handleTrackEmailClick}
            >
              support@treasure.tech
            </a>{' '}
            to complete this transfer.
          </Box>

          {ghbAvailableBalance > 0 && (
            <Box mt={2}>
              <strong>
                To withdraw from your 1 Day liquidity funds, please enter a
                withdrawal amount up to{' '}
                <Styled.Currency
                  number={ghbAvailableBalance}
                  variant={CurrencyVariant.Full}
                />
              </strong>
            </Box>
          )}
        </Box>
      }
      enabled={
        <Box display="block">
          <Box mb={3}>
            <Trans
              t={t}
              i18nKey="withdrawalMessage"
              count={1}
              components={{
                withdrawalAmount: (
                  <Styled.Currency
                    number={oneDayWithdrawalAmount}
                    variant={CurrencyVariant.Full}
                  />
                ),
              }}
            />
          </Box>

          <Trans
            t={t}
            i18nKey="withdrawalMessage"
            count={3}
            components={{
              withdrawalAmount: (
                <Styled.Currency
                  number={threeDayWithdrawalAmount}
                  variant={CurrencyVariant.Full}
                />
              ),
            }}
          />
        </Box>
      }
      flag={FeatureFlags.REACT_APP_TRANSFER_LIQUIDITY_WITHDRAWAL_ENABLED}
    />
  );
};
