import React, { useEffect, useState } from 'react';
import { i18n, useTranslation } from 'app/i18n';
import { Box, FormControl, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { InputLabel } from 'modules/2023-q3/components/forms/components/input-label/input-label';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { useTracking, TrackEventName } from 'modules/tracking';
import { SelectSwitchOptions } from './components/select-switch-options/select-switch-options';
import Content from './content/select-switch.content.json';
import { Styled } from './select-switch.style';

const i18nSelectSwitch = 'select-switch';
i18n.addResourceBundle('en', i18nSelectSwitch, Content);

export interface SelectSwitchOption {
  label: React.ReactNode | string;
  value: string;
}

interface SelectSwitchProps {
  isSelecting: boolean;

  label?: string;

  onChange: (selectedOption: SelectSwitchOption) => void;

  options: SelectSwitchOption[];

  selectedOption: SelectSwitchOption | undefined;

  setIsSelecting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectSwitch: React.FC<SelectSwitchProps> = ({
  isSelecting,
  label,
  onChange,
  options,
  selectedOption,
  setIsSelecting,
}) => {
  const { Track, trackEvent } = useTracking<{
    clickElement?: string;
    clickText?: string;
    component: string;
    eventName?: TrackEventName;
  }>({
    component: 'SelectSwitch',
  });
  const { t } = useTranslation(i18nSelectSwitch);

  const defaultSelection = selectedOption || options[0];

  const [selected, setSelected] = useState(defaultSelection);

  const getSwitchText = (): string => {
    if (isSelecting) {
      return t('Cancel', { ns: 'app' });
    }

    return t('Switch');
  };

  const handleOnChange = (option: SelectSwitchOption) => {
    onChange(option);
    setIsSelecting(false);

    trackEvent({
      clickElement: 'Option',
      eventName: TrackEventName.Click,
    });
  };

  const handleLabelClick = () => {
    handleShowOptions();

    trackEvent({
      clickElement: 'Label',
      eventName: TrackEventName.Click,
    });
  };

  const handleOptionClick = () => {
    handleShowOptions();

    trackEvent({
      clickElement: 'Selected Option',
      eventName: TrackEventName.Click,
    });
  };

  const handleSwitchClick = () => {
    handleShowOptions();

    trackEvent({
      clickElement: 'Switch',
      clickText: getSwitchText(),
      eventName: TrackEventName.Click,
    });
  };

  const handleShowOptions = () => {
    setIsSelecting(!isSelecting);
  };

  const showOptions = (
    <SelectSwitchOptions
      onChange={handleOnChange}
      options={options}
      selectedValue={defaultSelection?.value}
    />
  );

  const showSelected = (
    <Styled.Cursor onClick={handleOptionClick}>
      <Box py={2}>{selected?.label}</Box>
    </Styled.Cursor>
  );

  useEffect(() => {
    if (typeof selectedOption !== 'undefined' && selected !== selectedOption) {
      setSelected(selectedOption);
    }
  }, [selectedOption]);

  return (
    <Track>
      <FormControl fullWidth>
        <Grid container justifyContent="space-between">
          <Grid item>
            {label && (
              <InputLabel
                cursor="pointer"
                disableAnimation
                onClick={handleLabelClick}
                shrink
              >
                <Typography color="black19" variant={TypographyVariant.Header5}>
                  {label}
                </Typography>
              </InputLabel>
            )}
          </Grid>

          <Grid item>
            <Styled.Cursor onClick={handleSwitchClick}>
              <Typography color="green2" variant={TypographyVariant.Paragraph2}>
                {getSwitchText()}
              </Typography>

              <SVGBox
                display="inline-block"
                fillColor="green2"
                ml={1}
                position="relative"
                top={-1}
              >
                <Styled.ArrowIcon isSelecting={isSelecting} />
              </SVGBox>
            </Styled.Cursor>
          </Grid>
        </Grid>

        {isSelecting ? showOptions : showSelected}
      </FormControl>
    </Track>
  );
};
