import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useSyncQuickbooks = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const useConnectQuickbooks = async (params: Object) => {
    const { data } = await treasureApi().post('/quickbooks/sync', params);

    return data;
  };

  return useMutation(useConnectQuickbooks);
};
