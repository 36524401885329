import React from 'react';
import PropTypes from 'prop-types';
import { TreasureSymbol } from 'assets';
import { Styled } from './logo.style';

export enum LogoVariant {
  square,
}

interface Institution {
  logo: string;
  name: string;
}

interface LogoProps {
  /** object containing the logo (as base64) and name of the institution */
  institution: Institution;

  /** type of logo display, currently only a square (40px/40px) which is hardcoded */
  variant: LogoVariant.square;
}

const Logo: React.FC<LogoProps> = ({ institution, variant }) => {
  const getLogoImage = (logoInstitution: Institution, src: string) => {
    return (
      <img
        alt={`${logoInstitution.name ? `${logoInstitution.name} ` : ''}logo`}
        src={src}
        width={Styled.LogoWidth}
        height={Styled.LogoHeight}
      />
    );
  };

  if (institution.name === 'Treasure') {
    return getLogoImage(institution, TreasureSymbol);
  }

  if (institution.logo !== null) {
    return getLogoImage(
      institution,
      `data:image/jpeg;base64,${institution.logo}`,
    );
  }

  return <Styled.BankIcon />;
};

Logo.propTypes = {
  institution: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  variant: PropTypes.oneOf([LogoVariant.square]).isRequired,
};

export { Logo };
