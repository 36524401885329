import styled from 'styled-components';
import { Button as ButtonAlias } from 'modules/2023-q3/components/button/button';
import { breakpoints } from 'styles/css-constants';

const Button = styled(ButtonAlias)`
  border-radius: 100px;

  ${breakpoints.down('sm')} {
    &.MuiButton-root {
      min-width: 42px;
    }
  }
`;

export const Styled = {
  Button,
};
