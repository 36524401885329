import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Typography, TypographyVariant } from 'components/core';
import { Styled } from './persistent-error.style';

const pxr = 2;
const py = 1;
const mt = 0.1;

export interface PersistentErrorProps {
  button?: {
    action: () => void;

    text: string;
  };

  errorMessage: string | React.ReactNode;
}

export const PersistentError: React.FC<PersistentErrorProps> = ({
  button,
  errorMessage,
}) => {
  return (
    <Styled.ErrorContainer>
      <Grid container>
        <Grid item>
          <Box px={pxr} py={py} mt={mt}>
            <Typography color="white" variant={TypographyVariant.Body}>
              <WarningIcon fontSize="small" />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs>
          <Box pr={pxr} py={py} mt={mt}>
            <Typography color="white" variant={TypographyVariant.Body}>
              {errorMessage}
            </Typography>
          </Box>
        </Grid>

        {button && (
          <>
            <Styled.Divider orientation="vertical" flexItem />

            <Grid item>
              <Styled.Button onClick={button.action}>
                <Box px={pxr} py={py} mt={mt} textAlign="center">
                  <Typography
                    color="white"
                    variant={TypographyVariant.SubHeader1}
                  >
                    <Box px={pxr}>{button.text}</Box>
                  </Typography>
                </Box>
              </Styled.Button>
            </Grid>
          </>
        )}
      </Grid>
    </Styled.ErrorContainer>
  );
};

PersistentError.propTypes = {
  button: PropTypes.shape({
    action: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
};
