import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import {
  FootnoteProvider,
  LoadingProvider,
  MultipleAccountProvider,
} from 'components/layouts';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import { RoutesPath } from 'routes/constants/routes-path';
import { Header as HeaderV2 } from 'modules/dashboard/header/header';
import { Footer, Header as HeaderV1 } from './components';

// temporary fix as the growth promo probably shouldn't be in the v3 portal layout
export const V3PortalLayoutWithoutGrowthPromo: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  // TODO: temporary logic while we transition page by page
  const migratedPages = [
    RoutesPath.pages.connectBankAccountManual.path,
    RoutesPath.pages.connectBankAccounts.path,
    RoutesPath.pages.dashboard.path,
    RoutesPath.pages.onboardingComplete.path,
  ];

  const displayPortalLayout = (showWhiteBackgroundProp: boolean) => {
    const bankingWhiteBackground =
      isFeatureFlagEnabled(
        FeatureFlags.REACT_APP_2023_Q3_BANKING_PAGE_ENABLED,
      ) && pathname === RoutesPath.pages.banking.path;
    const showWhiteBackground =
      showWhiteBackgroundProp || bankingWhiteBackground;

    return (
      <FootnoteProvider>
        <MultipleAccountProvider>
          <FeatureFlag
            disabled={<HeaderV1 />}
            enabled={<HeaderV2 />}
            flag={FeatureFlags.REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED}
          />

          <LoadingProvider>
            {/* push the footer down */}
            <Box
              bgcolor={showWhiteBackground ? 'white' : ''}
              display="table"
              height="inherit"
              width="100%"
            >
              {children}
            </Box>

            <Box bgcolor={showWhiteBackground ? 'white' : ''}>
              <Footer />
            </Box>
          </LoadingProvider>
        </MultipleAccountProvider>
      </FootnoteProvider>
    );
  };

  return (
    <FeatureFlag
      disabled={displayPortalLayout(false)}
      enabled={displayPortalLayout(migratedPages.includes(pathname))}
      flag={FeatureFlags.REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED}
    />
  );
};
