import styled from 'styled-components';

const Scroll = styled.div`
  height: 175px;
  overflow-y: scroll;
`;

export const Styled = {
  Scroll,
};
