import React from 'react';
import { Percentage } from 'components/core';
import {
  AllocationModalRowKeys,
  getRowHeader,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { cellText } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-typography/allocation-typography';

export const getReturns = ({ allocations }: { allocations: Allocation }) => {
  const percentage = (number: number) => {
    return <Percentage decimalScale={2} number={number} />;
  };

  const data = [
    getRowHeader({
      rowKey: AllocationModalRowKeys.Return,
      hasTooltip: true,
    }),
  ];

  Object.entries(allocations).map(([productKey, product]) => {
    return data.push(cellText(percentage(product.return)));
  });

  return data;
};
