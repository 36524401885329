import React from 'react';
import firebase from 'utils/firebase';
import mixpanel from 'modules/tracking/analytics/mixpanel';
import { useSendEvent, TrackEventName } from 'modules/tracking';

interface signInData {
  email: string;
  password: string;
}

const genericErrorMessage =
  'The password is invalid or the user does not have a password.';

export const useSignIn = () => {
  const { sendEvent } = useSendEvent();

  const signIn: ({ email, password }: signInData) => Promise<void> = async ({
    email,
    password,
  }) => {
    // first, check if the email exists by using fetchSignInMethodsForEmail
    // if the array is empty, then the email hasn't been used to sign up yet
    try {
      await firebase
        .auth()
        .fetchSignInMethodsForEmail(email)
        .then(async (result) => {
          if (result.length > 0) {
            const { user } = await firebase
              .auth()
              .signInWithEmailAndPassword(email, password);

            if (!user) {
              throw new Error('Error signing in');
            }

            mixpanel.identify(user.uid);
            mixpanel.people.set({
              $email: user.email,
              fbUid: user.uid,
              // userId: user.userId,
            });

            sendEvent(TrackEventName.FirebaseSignInSucceeded, {
              username: user.email,
            });

            return user;
          }

          sendEvent(TrackEventName.FirebaseSignInFailed, { username: email });

          throw new Error(genericErrorMessage);
        });
    } catch (error) {
      // this is catching the disabled user error message and returning the generic one instead
      throw new Error(genericErrorMessage);
    }
  };

  return { signIn };
};
