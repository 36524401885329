import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import appContent from 'content/app.content.json';
import complianceResource from 'content/compliance.content.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        app: appContent,
        compliance: complianceResource,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export { i18n };
export { useTranslation, Trans } from 'react-i18next';
