import mixpanel from 'modules/tracking/analytics/mixpanel';
import firebase from 'utils/firebase';
import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { useStoreAuthentication, useStoreBusiness } from 'stores';
import { ReactQueryMutationKeys } from 'enums';

// hooks
import { getUserAgent } from 'modules/tracking/utils/get-user-agent';
import { useUserIP } from 'modules/tracking/hooks/use-user-ip';

const userAgentInformation = getUserAgent();

export const useSendTreasureEvent = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);
  const { businessId } = useStoreBusiness();
  const { data: userIP } = useUserIP(!!isSignedIn); // passing in isSignedIn to toggle enabled
  // otherwise, when signing out, useUserIP was triggering a get too many times

  const postTreasureEvent = async ({
    eventName,
    properties,
    publishEventToMixpanel = false,
  }: {
    eventName: string;
    properties: any;
    publishEventToMixpanel: boolean;
  }) => {
    // api-portal-gateway / events requires Authorization and x-business-id in header
    // TODO: we could store these events and wait until the user has signed in to send them
    // we'd probably want to support sending batched events
    if (isSignedIn && businessId) {
      const currentUser = await firebase.auth().currentUser; // race condition with signing out
      const userAuthToken = currentUser?.getIdToken();
      if (userAuthToken) {
        const { data } = await treasureApi().post('/events', {
          message: eventName,
          eventType: 'USER_ACTION',
          ipAddress: userIP?.IPv4,
          geoLocation: userIP?.country_code,
          data: {
            ...properties,
            ip: userIP,
            ua: userAgentInformation,
            publishEventToMixpanel,
            distinctId: currentUser?.uid, // we use the firebase uid to track users in mixpanel
            currentUrl: window.location.href,
            screenHeight: `${window.innerHeight}px`,
            screenWidth: `${window.innerWidth}px`,
            email: currentUser?.email,
          },
        });

        return data;
      }
    } else {
      // TODO: PD-1197
      // fallback to mixpanel
      mixpanel.track(eventName, properties);
    }
  };

  return useMutation(postTreasureEvent, {
    mutationKey: ReactQueryMutationKeys.SendTreasureEvent, // this mutation key is used by react-query-error-toasts so it will never show a user an error
  });
};
