import React from 'react';
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';
import { isAllowedRole } from 'modules/2023-q4/rbac/utils/is-allowed-role';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';

interface Props {
  children: React.ReactNode;

  roles: UserRole[];
}

export const UserPermissionsRequired: React.FC<Props> = ({
  children,
  roles,
}) => {
  const { data: business } = useBusiness();
  const { data: user } = useUser(business?.Id);

  if (!user || !user.role) {
    return null;
  }

  if (isAllowedRole({ allowedRoles: roles, role: user.role })) {
    return <>{children}</>;
  }

  return null;
};
