import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrows/caret-down.svg';

interface ArrowIconProps {
  isSelecting: boolean;
}

const ArrowIcon = styled(({ isSelecting, ...rest }) => (
  <ArrowDropDownIcon {...rest} />
))<ArrowIconProps>`
  ${(props) =>
    props.isSelecting &&
    css`
      transform: rotate(180deg);
    `}
`;

const Cursor = styled(Box)`
  cursor: pointer;
`;

export const Styled = { ArrowIcon, Cursor };
