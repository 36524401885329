import styled from 'styled-components';
import { Box } from '@material-ui/core';

const TooltipContainer = styled(Box)`
  background-color: var(--color-white);
  border: 1px solid var(--color-grey5);
  border-radius: var(--border-radius-02);
`;

export const Styled = {
  TooltipContainer,
};
