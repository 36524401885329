import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import {
  Button,
  Currency,
  CurrencyVariant,
  Heading,
  Modal,
  Text,
} from 'components/core';
import { ButtonAddAccount } from 'components/dashboard/v3/buttons/add-account/button-add-account';
import { AccountAllocation, Allocation } from 'components/banking/allocation';
import { BankingAccountRow, TreasureReserveRow } from 'components/banking/v3';
import { AccountType, Size, SweepType, TreasureReserveStatus } from 'enums';
import { BankAccount, TreasureReserve } from 'types';
import {
  BankAccountPropTypes,
  TreasureReservePropTypes,
} from 'types/prop-types';
import { RoutesPath } from 'routes/constants/routes-path';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

// wire fee
import { WIRE_FEE } from 'modules/sweeps/constants/wire-fee';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';

import { Styled } from './banking-dashboard.style';

const AccountTypes = [
  AccountType.Reserve,
  AccountType.Checking,
  AccountType.Liability,
];

export interface BankingDashboardProps {
  accounts: BankAccount[];

  allocation: AccountAllocation[];

  idleCash: number;

  treasureReserve: TreasureReserve;
}

const BankingDashboard: React.FC<BankingDashboardProps> = ({
  accounts,
  allocation,
  idleCash,
  treasureReserve,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { data: business } = useBusiness();
  const { data: portfolios } = usePortfolios(business?.Id);

  const hasAccounts = accounts?.length > 0;
  const hasOpenTreasureReserve =
    treasureReserve.status === TreasureReserveStatus.OpenAndUnfunded ||
    treasureReserve.status === TreasureReserveStatus.OpenAndFunded;

  const renderNoAccountsMessage = () => (
    <Box mt={3} textAlign="center">
      <Heading variant={1}>{`Hi${
        business.administrator && business.administrator.FirstName
          ? ` ${business.administrator.FirstName}`
          : ''
      }, Welcome to Treasure!`}</Heading>

      <Box my={2}>
        <Text variant={3}>
          Treasure works best when all of your financial accounts are connected.
          Please link your accounts to start first.
        </Text>
      </Box>

      <ButtonAddAccount />
    </Box>
  );

  if (!hasAccounts && !hasOpenTreasureReserve) {
    return (
      <Box mx={3} my={2} width="95%">
        {renderNoAccountsMessage()}
      </Box>
    );
  }

  const getTotalBalance = (
    accountsList: { balance: number; Type: string }[],
    type: string,
    treasureReserveForBalance?: TreasureReserve,
  ) => {
    let totalSum = accountsList
      .filter((a: { Type: string }) => a.Type === type)
      .reduce((sum: number, a: { balance: number }) => sum + a.balance, 0);

    if (
      type === AccountType.Reserve &&
      portfolios?.amountsInDollars.totalAmount
    ) {
      totalSum += portfolios?.amountsInDollars.totalAmount;
    }

    return totalSum;
  };

  const showAccountTypeHeading = (type: AccountType) => {
    return (
      <Box mt={8} mb={2}>
        <Styled.SectionHeading color="gothic" variant={4}>
          {type}
          <Box display="inline" ml={2}>
            <Currency
              number={
                type === AccountType.Reserve
                  ? getTotalBalance(accounts, type, treasureReserve)
                  : getTotalBalance(accounts, type)
              }
              variant={CurrencyVariant.NearestDollar}
            />
          </Box>
        </Styled.SectionHeading>
      </Box>
    );
  };

  const showTreasureReserve = (
    <Box mb={2}>
      <TreasureReserveRow data={treasureReserve} idleCash={idleCash} />
    </Box>
  );

  const hasAllocation = allocation.filter(
    (account) => account.containedPercentage !== null,
  );

  const showAddAccountButton = (
    type: AccountType,
    allAccounts: BankAccount[],
  ) => {
    if (type === AccountType.Reserve) {
      return false;
    }

    const hasAccount = allAccounts.filter((account) => account.Type === type);

    return hasAccount.length > 0;
  };

  return (
    <>
      <Box mx={{ xs: 0, sm: 3 }} mb={2} width={{ xs: '100%', sm: '95%' }}>
        {hasAllocation.length > 0 && (
          <Box mx={{ xs: 2, sm: 0 }} mt={5}>
            <Box mb={2}>
              <Text color="black" variant={3}>
                Allocation
              </Text>
            </Box>

            <Allocation
              accounts={allocation}
              backgroundColor="alabaster"
              displayShowAllAccountsLink={false}
              variant="large"
            />
          </Box>
        )}

        {AccountTypes.map((type) => (
          <React.Fragment key={type}>
            {type === AccountType.Reserve && (
              <>
                {showAccountTypeHeading(type)}
                {showTreasureReserve}
              </>
            )}

            {accounts
              .filter(
                (account) =>
                  account.Type === type &&
                  account.institution.name !== 'Treasure', // TODO: this filter can be removed when the old treasure reserve is removed from all accounts
              )
              .map((account, index) => (
                <React.Fragment key={account.Id}>
                  {index === 0 &&
                    account.Type !== AccountType.Reserve &&
                    showAccountTypeHeading(type)}

                  <Box mb={2}>
                    <FeatureFlag
                      disabled={
                        <BankingAccountRow
                          data={account}
                          hasOpenTreasureReserve={hasOpenTreasureReserve}
                          sendToTreasureModal={() => {
                            history.push(RoutesPath.pages.transfer.path);
                          }}
                          sweepAccountModal={() => {
                            history.push(RoutesPath.pages.transfer.path);
                          }}
                        />
                      }
                      enabled={
                        <BankingAccountRow
                          data={account}
                          hasOpenTreasureReserve={hasOpenTreasureReserve}
                          sendToTreasureModal={() => {
                            history.push(RoutesPath.tasks.deposit.path);
                          }}
                          sweepAccountModal={() => {
                            history.push(RoutesPath.tasks.deposit.path);
                          }}
                        />
                      }
                      flag={
                        FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED
                      }
                    />
                  </Box>
                </React.Fragment>
              ))}

            {showAddAccountButton(type, accounts) && (
              <Box mt={3}>
                <ButtonAddAccount />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

BankingDashboard.propTypes = {
  accounts: PropTypes.arrayOf(BankAccountPropTypes.isRequired).isRequired,
  allocation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      lastFourDigits: PropTypes.string.isRequired,
      containedPercentage: PropTypes.number.isRequired,
      containedAmount: PropTypes.string.isRequired, // TODO: (API) should be number but api returns string
      displayWidth: PropTypes.number,
    }).isRequired,
  ).isRequired,
  idleCash: PropTypes.number.isRequired,
  treasureReserve: TreasureReservePropTypes.isRequired,
};

export { BankingDashboard };
