import styled from 'styled-components';
import { Box } from '@material-ui/core';

const ScheduledTransferText = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px 0;

  button {
    margin-top: 30px;
    width: 300px;
  }
`;

const ScheduledTransferStatus = styled(Box)`
  display: flex;
  margin: 0;
  padding: 12px 20px;
`;

const ScheduledTransferContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 40px;
  text-align: center;

  &.not-allowed {
    background-color: var(--color-whiteSmoke1);

    ${ScheduledTransferStatus} {
      background-color: var(--color-salmon);
    }

    ${ScheduledTransferText} {
      p,
      h2 {
        opacity: 0.6;
      }
    }
  }
`;

export const Styled = {
  ScheduledTransferText,
  ScheduledTransferStatus,
  ScheduledTransferContainer,
};
