import styled from 'styled-components';

const Link = styled.a`
  text-decoration: underline;

  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: var(--color-mintGreen);
  }
`;

export const Styled = {
  Link,
};
