import React, { useState } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Typography, TypographyVariant } from 'components/core';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconCheckmarkCircleTransparent } from 'assets/icons/checkmarks/checkmark-circle-transparent.svg';
import { ReactComponent as IconNotificationBell } from 'assets/icons/notification/bell.svg';
import { AllocationTotalMessage } from './components/allocation-total-message/allocation-total-message';
import { ResetAllocation } from './components/reset-allocation/reset-allocation';
import {
  IProductAllocations,
  ProductAllocationProducts,
} from 'modules/2024-q1/allocation-by-amount-or-percentage/interface/product-allocation';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { Container } from 'modules/2024-q1/allocation-by-amount-or-percentage/components/container/container';
import { useHideProduct } from 'modules/2023-q4/white-label/hooks/use-hide-product';
import { DepositWithdrawalVariant } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { AmountPercentageToggle } from './components/amount-percentage-toggle/amount-percentage-toggle';
import { ProductAllocation } from './components/product-allocation/product-allocation';
import { Styled } from './form.style';

interface FormProps {
  allocation: IProductAllocations;
  allowDefaultAllocationSave: boolean;
  initialAllocation: IProductAllocations;
  nextStep: () => void;
  setAllocation: React.Dispatch<React.SetStateAction<IProductAllocations>>;
  totalAmount: number;
}

export const Form: React.FC<FormProps> = ({
  allocation,
  allowDefaultAllocationSave,
  initialAllocation,
  nextStep,
  setAllocation,
  totalAmount,
}) => {
  const { hideProduct } = useHideProduct();

  const [showEditPercentage, setShowEditPercentage] = useState(false);

  // set hidden products allocations to 0
  [
    ProductAllocationProducts.ManagedMoneyMarket,
    ProductAllocationProducts.ManagedTreasuries,
    ProductAllocationProducts.ManagedIncome,
    ProductAllocationProducts.TreasureCash,
  ].map((product) => {
    if (hideProduct(product)) {
      allocation[product].amount = 0;
      allocation[product].value = 0;
      allocation[product].valueRaw = 0;
    }
  });

  return (
    <>
      <Box mt={{ xs: 0, sm: 5 }}>
        <Box mb={3.5} textAlign={{ sm: 'center' }}>
          <Box mb={1}>
            <MobileView
              mobile={
                <Typography color="black19" variant={TypographyVariant.Header3}>
                  Update Your Portfolio Allocation
                </Typography>
              }
            >
              <Typography color="black19" variant={TypographyVariant.Header2}>
                Update Your Portfolio Allocation
              </Typography>
            </MobileView>
          </Box>

          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            Tap on{' '}
            <SVGBox display="inline" position="relative" top={3} mx={0.5}>
              <IconCheckmarkCircleTransparent />
            </SVGBox>{' '}
            to remove any selected investment products. Total allocation must
            equal exactly 100% to continue.
          </Typography>
        </Box>

        <MobileView
          mobile={
            <Box mb={3.5}>
              <Divider />
            </Box>
          }
        />

        <Container>
          <Box p={{ xs: 0, sm: 3 }}>
            <AllocationTotalMessage
              allocation={allocation}
              allowDefaultAllocationSave={allowDefaultAllocationSave}
              initialAllocation={initialAllocation}
              nextStep={nextStep}
              showEditPercentage={showEditPercentage}
              totalAmount={totalAmount}
            />

            <Box mt={1} textAlign="center">
              <AmountPercentageToggle
                setShowEditPercentage={setShowEditPercentage}
                showEditPercentage={showEditPercentage}
              />
            </Box>

            <Box mt={1} textAlign="center">
              <ResetAllocation
                allocation={allocation}
                initialAllocation={initialAllocation}
                setAllocation={setAllocation}
              />
            </Box>

            <Box my={3}>
              <Grid alignItems="stretch" container spacing={2}>
                {Object.keys(ProductAllocationProducts).map((productKey) => {
                  const product =
                    ProductAllocationProducts[
                      productKey as keyof typeof ProductAllocationProducts
                    ];

                  if (hideProduct(product)) {
                    return null;
                  }

                  return (
                    <Grid item key={productKey} xs={12} md>
                      <Styled.RoundedContainer height="100%" p={2}>
                        <ProductAllocation
                          product={product}
                          productAllocation={allocation[product]}
                          setAllocation={setAllocation}
                          setShowEditPercentage={setShowEditPercentage}
                          showEditPercentage={showEditPercentage}
                          totalAmount={totalAmount}
                        />
                      </Styled.RoundedContainer>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <FeatureFlag
              disabled={
                <Box textAlign="center">
                  <Typography
                    color="brown3a"
                    variant={TypographyVariant.Paragraph2}
                  >
                    <SVGBox
                      display="inline"
                      position="relative"
                      top={3}
                      mx={0.5}
                    >
                      <IconNotificationBell />
                    </SVGBox>
                    Reminder{' '}
                    <Typography
                      color="grey3b"
                      variant={TypographyVariant.Paragraph2}
                    >
                      / Treasure Cash has 1 business day liquidity. All other
                      products have 3-5 business day liquidity.
                    </Typography>
                  </Typography>
                </Box>
              }
              flag={FeatureFlags.REACT_APP_2024_Q3_GRASSHOPPER_SUNSET}
            />
          </Box>
        </Container>
      </Box>

      <Box height={{ xs: 50, sm: 100 }} />
    </>
  );
};
