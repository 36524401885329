import React from 'react';
import PropTypes from 'prop-types';
import { Styled } from './heading.style';

export interface HeadingProps {
  /** content of the heading */
  children: React.ReactNode;

  /** used by styled-compoents to extend styling */
  className?: string;

  /** color of the heading */
  color?: string;

  // TODO:
  /* The HTML element tag used for the root node. 
  htmlTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'; */

  /** level (font size and weight) of the heading */
  variant: number;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  className,
  color,
  variant,
}) => {
  return (
    <Styled.Typography
      className={className}
      styledColor={color}
      level={variant}
    >
      {children}
    </Styled.Typography>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  // htmlTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  variant: PropTypes.number.isRequired,
};

export { Heading };
