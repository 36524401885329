import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ButtonAddAccount } from 'components/dashboard/v3/buttons/add-account/button-add-account';
import { Styled } from './connect-bank-accounts-card.style';

export const ConnectBankAccountsCard: React.FC = () => {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
    >
      <Box textAlign="center">
        <Typography color="black" variant={TypographyVariant.LegacyHeader2}>
          Connect bank accounts
        </Typography>

        <Box display="inline-block" mt={2} mx="auto" textAlign="left">
          <Styled.Dots>
            <li>
              <Typography color="nero" variant={TypographyVariant.SubHeader3}>
                See all your account balances in 1 place
              </Typography>
            </li>

            <li>
              <Typography color="nero" variant={TypographyVariant.SubHeader3}>
                See insights on cash in and cash out
              </Typography>
            </li>

            <li>
              <Typography color="nero" variant={TypographyVariant.SubHeader3}>
                View all recent transactions in 1 place
              </Typography>
            </li>
          </Styled.Dots>
        </Box>

        <Box mt={3}>
          <ButtonAddAccount />
        </Box>
      </Box>
    </Box>
  );
};
