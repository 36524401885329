import React, { useEffect, useState } from 'react';
import {
  IProductAllocations,
  ProductAllocationProducts,
} from 'modules/2024-q1/allocation-by-amount-or-percentage/interface/product-allocation';
import { useBusiness } from 'hooks/business/use-business';
import { AllocationProducts } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { Box } from '@material-ui/core';
import { Spinner } from 'components/core';
import { FailedRetry } from './components/failed-retry/failed-retry';
import { Styled } from 'modules/2024-q1/allocation-by-amount-or-percentage/pages/form/form.style';
import {
  useMutatePortfolio,
  MutatePortfolioRequest,
} from './hooks/use-mutate-portfolio';

interface SubmitProps {
  allocation: IProductAllocations;
  nextStep: () => void;
}

export const Submit: React.FC<SubmitProps> = ({ allocation, nextStep }) => {
  const { data: business } = useBusiness();
  const { mutate: mutatePortfolio } = useMutatePortfolio();

  const [showFailed, setShowFailed] = useState(false);

  const handleSubmitPortfolioAllocation = () => {
    // in order for the form to empty the inputs, we're allowing the value
    // to be '', here, we're making sure everything is a number to submit to the mutate
    const allocations = {
      [ProductAllocationProducts.ManagedMoneyMarket]: Number(
        allocation[ProductAllocationProducts.ManagedMoneyMarket].value,
      ),
      [ProductAllocationProducts.ManagedTreasuries]: Number(
        allocation[ProductAllocationProducts.ManagedTreasuries].value,
      ),
      [ProductAllocationProducts.ManagedIncome]: Number(
        allocation[ProductAllocationProducts.ManagedIncome].value,
      ),
      [ProductAllocationProducts.TreasureCash]: Number(
        allocation[ProductAllocationProducts.TreasureCash].value,
      ),
    };

    const mutatePortfolioRequest: MutatePortfolioRequest = {
      [AllocationProducts.cash]: {
        amount: allocation[ProductAllocationProducts.TreasureCash].amount,
        percentage: allocations[ProductAllocationProducts.TreasureCash],
      },
      [AllocationProducts.smart]: {
        amount: allocation[ProductAllocationProducts.ManagedIncome].amount,
        percentage: allocations[ProductAllocationProducts.ManagedIncome],
      },
      [AllocationProducts.tbills]: {
        amount: allocation[ProductAllocationProducts.ManagedTreasuries].amount,
        percentage: allocations[ProductAllocationProducts.ManagedTreasuries],
      },
      [AllocationProducts.moneyMarket]: {
        amount: allocation[ProductAllocationProducts.ManagedMoneyMarket].amount,
        percentage: allocations[ProductAllocationProducts.ManagedMoneyMarket],
      },
    };

    mutatePortfolio(
      {
        businessId: business?.id,
        portfolio: mutatePortfolioRequest,
      },
      {
        onError: () => setShowFailed(true),
        onSuccess: nextStep,
      },
    );
  };

  const handleRetry = () => {
    handleSubmitPortfolioAllocation();
  };

  useEffect(() => {
    handleSubmitPortfolioAllocation();
  }, []);

  if (showFailed) {
    return <FailedRetry onRetry={handleRetry} />;
  }

  return (
    <Box maxWidth={720} mt={5} mx="auto">
      <Styled.RoundedContainer p={3}>
        <Box my={15}>
          <Spinner />
        </Box>
      </Styled.RoundedContainer>
    </Box>
  );
};
