import styled from 'styled-components';

const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

export const Styled = {
  Link,
};
