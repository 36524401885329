import React from 'react';
import { i18n, useTranslation } from 'app/i18n';
import { Button } from 'modules/2023-q3/components/button/button';
import { useBusiness } from 'hooks/business/use-business';
import { useCashAccount } from 'hooks/portal-gateway/use-cash-account';
import WireInstructionsContent from './content/wire-instructions.content.json';

const i18nNamespace = 'WireInstructions';
i18n.addResourceBundle('en', i18nNamespace, WireInstructionsContent);

interface WireInstructionsLinkProps {
  onClick: () => void;
}

export const WireInstructionsLink: React.FC<WireInstructionsLinkProps> = ({
  onClick,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const { data: business } = useBusiness();
  const { data: cashAccount } = useCashAccount(business?.Id);

  if (
    cashAccount?.accountNumber === '' ||
    cashAccount?.accountNumber === undefined
  ) {
    return null;
  }

  return (
    <Button
      bgColor="white"
      bgHoverColor="white"
      onClick={onClick}
      variant="text"
    >
      {t('Looking for Wire Instructions?')}
    </Button>
  );
};
