import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';

export const isProhibitedRole = ({
  prohibitedRoles,
  roles,
}: {
  prohibitedRoles: UserRole[];
  roles: UserRole[];
}) => {
  return prohibitedRoles.some((role) => roles.includes(role));
};
