import React from 'react';
import { Box } from '@material-ui/core';
import { ReactComponent as TreasureLogoWithText } from 'assets/logos/horizontal/treasure_logo_green.svg';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { RoutesPath } from 'routes/constants/routes-path';
import { MFA } from 'modules/2023-q3/components/mfa/mfa';
import { useStoreLoginMFA } from 'stores';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { useSendLoginMFA } from './hooks/use-send-login-mfa-code';

export const LoginMFA = () => {
  const history = useHistory();
  const location = useLocation<{ redirect: string }>();
  const sendLoginMFA = useSendLoginMFA();
  const { passedMFA, setPassedMFA } = useStoreLoginMFA((state) => state);
  const { signOut } = useSignOut();

  const redirectTo = location.state?.redirect || RoutesPath.pages.home.path;

  const handleMFASuccess = () => {
    setPassedMFA();

    history.push(redirectTo);
  };

  return (
    <FeatureFlag
      disabled={<Redirect to={redirectTo} />}
      enabled={
        passedMFA ? (
          <Redirect to={redirectTo} />
        ) : (
          <>
            <Box mt={10} mb={5} textAlign="center">
              <TreasureLogoWithText />
            </Box>

            <MFA
              customSendMFA={{ mutationResult: sendLoginMFA }}
              customVerificationMessage="A verification code has been sent to log in"
              onMFASuccess={handleMFASuccess}
              onPrevious={signOut}
              showRememberDeviceCheckbox
            />
          </>
        )
      }
      flag={FeatureFlags.REACT_APP_2023_Q3_LOGIN_MFA_ENABLED}
    />
  );
};
