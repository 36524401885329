import { TypographyVariant } from 'enums';
import { getWhiteLabelPartnerColors } from 'modules/2023-q4/white-label/components/get-white-label-partner-colors/get-white-label-partner-colors';

// Breakpoints
const breakpointsValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

type BreakpointsValuesType = keyof typeof breakpointsValues;

const getBreakpointString = (
  type: 'up' | 'down',
  breakpointsValue: BreakpointsValuesType,
) => {
  const width = type === 'up' ? 'min-width' : 'max-width';

  return `@media (${width}: ${breakpointsValues[breakpointsValue]}px)`;
};

export const breakpoints = {
  up: (breakpointsValue: BreakpointsValuesType): string =>
    getBreakpointString('up', breakpointsValue),
  down: (breakpointsValue: BreakpointsValuesType): string =>
    getBreakpointString('down', breakpointsValue),
};

// Colors
// for now, we're using https://chir.ag/projects/name-that-color/
// until we standardize
export const defaultColors = {
  /* TODO: replace legacy colors */
  /* Legacy */
  legacyBlue100: '#3465EF',
  legacyBlue400: '#13102C',
  legacyGray: '#85868D',
  legacyGrayBorder: '#E0E8EC',
  legacygreyDarkSelector: '#555555',
  legacyGrayLight: '#D8DDE6',
  /* TODO: replace legacy colors */

  /* Whites */
  white: '#FFF',
  whiteSmoke0: '#FAFAFA',
  whiteSmoke1: '#F1F1F1',
  whiteSmoke2: '#F2F2F2',
  whiteSmoke3: '#F4F4F4',
  whiteSmoke4: '#F7F7F7',
  wildSand: '#F5F5F5',
  gainsboro0: '#E0E0E0',
  gainsboro1: '#E1E1E1',
  gainsboro2: '#E5E5E5',
  albescentWhite: '#F5E7CE',

  /* Reds */
  amaranth: '#EA435A',
  cardinal: '#C11A31',
  cinnabar: '#E63528',
  radicalRed: '#FE3672',
  torchRed: '#FF004C',
  darkRed: '#C24A4A',
  flatRed: '#DE6060',

  /* Blacks */
  black: '#000',
  charcoal: '#444',
  nero: '#222',

  /* Greys */
  alabaster: '#F8F8F8',
  alto: '#D9D9D9',
  gallery: '#EAEAEA',
  gallery2: '#EFEFEF',
  gothic: '#71898F',
  hitGrey: '#AFB0B0',
  kokoda: '#666357',
  lightGrey: '#D2D2D2',
  midGray: '#616166',
  mineShaft: '#3D3D3D',
  mischka: '#E3E2EA',
  mischka2: '#DBDAE2',
  satinLinen: '#EBE8DF',
  silver: '#C4C4C4',
  silverChalice: '#ACACAC',
  smoky: '#64626D',
  stack: '#8A908C',
  darkGrey: '#232323',
  greyDark: '#222222',
  jumbo: '#717177',
  disabledGrey: '#B2B1B8',
  blackHaze: '#F5F6F6',
  dustyGray: '#9B9B9B',

  /* Purples */
  electricViolet: '#661BF1',
  hanPurple: '#4025F3',
  lavenderBlue: '#BBB1FB',
  lightSlateBlue: '#9080F8',
  lavender: '#DAD5FB',
  mediumSlateBlue: '#6954F6',
  heliotrope: '#AA7EFF',
  seance: '#9C27B0',

  /* Blues */
  blue: '#001AF5',
  christalle: '#231B4B',
  persianIndigo: '#221579',
  regalBlue: '#1A2850',
  royalBlue: '#4542DC',
  deepKoamaru: '#221579',
  linkBlue: '#005DCA',
  mirage: '#121828',
  azureRadiance: '#0077FF',

  /* Pinks */
  bizarre: '#F2E1E6',
  darkPurple: '#8F298B',
  fuchsia: '#CB4AC6',
  plum: '#E093DD',
  blushPink: '#FD82F8',
  salmon: '#F3DCC6',
  sockeye: '#FFE3E3',

  /* Greens */
  dell: '#2C6218',
  emerald: '#47d764',
  turtle: '#008000',
  grannyApple: '#d4f4db',
  salem: '#0d922a',
  silverTree: '#5DAF8D',
  stromboli: '#335443',
  burnham: '#002913',
  holly: '#00150A',
  edward: '#ADBBB3',
  mineralGreen: '#43655B',
  mintGreen: '#A3FFC1',
  powderAsh: '#AEBBB2',
  mountainMeadow: '#1BBD99',
  mystic: '#E6EFED',

  /* Oranges */
  clementine: '#ED6C02',
  ecruWhite: '#FAF8F3',
  jaffa: '#EF683D',
  newOrleans: '#F3D2A1',

  /* Browns */
  dallas: '#664D1F',
  lividBrown: '#4D2E36',

  /* 2023 Q2 DSM */
  black19: '#191919',

  blue2: '#324899',
  blue3: '#536AC0',
  blue4: '#809AFF',

  brown2: '#4F4C49',
  brown3: '#8F8E8C',
  brown3a: '#A69285',
  brown4a: '#C7BFAF',
  brown4b: '#D6D5D2',
  brown5: '#E0DFDC',
  brown5b: '#DBD9D5',
  brown6: '#F0ECE9',
  brown6a: '#F0EBE6',
  brown6b: '#F0EDE9',
  brown7: '#F7F5F2',

  green0: '#003329',
  green0b: '#002B23',
  green1: '#075E50',
  green2: '#2F6B60',
  green3: '#3D9988',
  green4: '#59B2A2',
  green5: '#A7D1C9',
  green6: '#E1F0ED',
  green7: '#E6EFED',

  grey1: '#262626',
  grey2: '#424545',
  grey3: '#696B6B',
  grey3b: '#6A7173',
  grey4: '#8F8F8F',
  grey5: '#CCCCCC',
  grey6: '#E6E6E6',
  grey7: '#F0F0F0',

  orange3: '#C76636',

  red2: '#982932',
};

const whiteLabelColors = {
  buttonPrimaryBackground: defaultColors.green2,
  buttonPrimaryHoverBackground: defaultColors.green0,
  headerNavigationText: defaultColors.green2,
  primary: defaultColors.green2,
  productCash: defaultColors.brown4a,
  productManagedMoneyMarket: defaultColors.green0,
  productManagedTreasuries: defaultColors.green2,
  productManagedIncome: defaultColors.green3,
  productUninvested: defaultColors.green6,
};

export const colors = {
  ...defaultColors,
  ...whiteLabelColors,
  ...getWhiteLabelPartnerColors(),
};

export const getColor = (color: keyof typeof colors): string => colors[color];

// Typography
const fontFamily = 'Nunito Sans';
const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

interface TypographyProperties {
  fontSize: string;
  fontWeight: number;
  semanticElement?: string;
  textTransform?: string;
}

const fontBodyProperties: TypographyProperties[] = [
  {
    fontSize: '10px',
    fontWeight: fontWeight.regular,
  },
  {
    fontSize: '12px',
    fontWeight: fontWeight.regular,
  },
  {
    fontSize: '15px',
    fontWeight: fontWeight.light,
  },
  {
    fontSize: '16px',
    fontWeight: fontWeight.light,
  },
];

const fontHeadingProperties: TypographyProperties[] = [
  {
    fontSize: '32px',
    fontWeight: fontWeight.regular,
  },
  {
    fontSize: '22px',
    fontWeight: fontWeight.light,
  },
  {
    fontSize: '16px',
    fontWeight: fontWeight.light,
  },
  {
    fontSize: '12px',
    fontWeight: fontWeight.regular,
  },
  {
    fontSize: '10px',
    fontWeight: fontWeight.bold,
  },
];

const typographyProperties = {
  [TypographyVariant.BoldLargeHeader]: {
    fontSize: '35px',
    fontWeight: fontWeight.bold,
    semanticElement: 'h2',
    textTransform: 'none',
  },
  [TypographyVariant.LargeHeader]: {
    fontSize: '33px',
    fontWeight: fontWeight.light,
    semanticElement: 'h2',
    textTransform: 'none',
  },
  [TypographyVariant.LegacyHeader2]: {
    fontSize: '24px',
    fontWeight: fontWeight.bold,
    semanticElement: 'h3',
    textTransform: 'none',
  },
  [TypographyVariant.Header]: {
    fontSize: '22px',
    fontWeight: fontWeight.bold,
    semanticElement: 'h3',
    textTransform: 'none',
  },
  [TypographyVariant.HeaderExtraBold]: {
    fontSize: '22px',
    fontWeight: fontWeight.extraBold,
    semanticElement: 'h2',
    textTransform: 'uppercase',
  },
  [TypographyVariant.HeaderRegular]: {
    fontSize: '22px',
    fontWeight: fontWeight.regular,
    semanticElement: 'h3',
    textTransform: 'none',
  },
  [TypographyVariant.SubHeader1]: {
    fontSize: '16px',
    fontWeight: fontWeight.bold,
    semanticElement: 'h4',
    textTransform: 'none',
  },
  [TypographyVariant.SubHeader2]: {
    fontSize: '16px',
    fontWeight: fontWeight.light,
    semanticElement: 'h4',
    textTransform: 'none',
  },
  [TypographyVariant.SubHeader3]: {
    fontSize: '16px',
    fontWeight: fontWeight.regular,
    semanticElement: 'h4',
    textTransform: 'none',
  },
  [TypographyVariant.Body]: {
    fontSize: '15px',
    fontWeight: fontWeight.regular,
    semanticElement: 'span',
    textTransform: 'none',
  },
  [TypographyVariant.BodyBold]: {
    fontSize: '15px',
    fontWeight: fontWeight.bold,
    semanticElement: 'span',
    textTransform: 'none',
  },
  [TypographyVariant.BodySemiBold]: {
    fontSize: '15px',
    fontWeight: fontWeight.semiBold,
    semanticElement: 'span',
    textTransform: 'none',
  },
  [TypographyVariant.LargerLabel]: {
    fontSize: '13px',
    fontWeight: fontWeight.semiBold,
    semanticElement: 'h4',
    textTransform: 'none',
  },
  [TypographyVariant.SubLabel]: {
    fontSize: '13px',
    fontWeight: fontWeight.regular,
    semanticElement: 'h5',
    textTransform: 'none',
  },
  [TypographyVariant.LargeLabel]: {
    fontSize: '12px',
    fontWeight: fontWeight.bold,
    semanticElement: 'h5',
    textTransform: 'uppercase',
  },
  [TypographyVariant.SmallLabel2]: {
    fontSize: '10px',
    fontWeight: fontWeight.bold,
    semanticElement: 'h5',
    textTransform: 'uppercase',
  },
  [TypographyVariant.SmallLabel3]: {
    fontSize: '10px',
    fontWeight: fontWeight.regular,
    semanticElement: 'h6',
    textTransform: 'none',
  },
  [TypographyVariant.SmallText]: {
    fontSize: '12px',
    fontWeight: fontWeight.regular,
    semanticElement: 'span',
    textTransform: 'none',
  },
  [TypographyVariant.HelpText]: {
    fontSize: '10px',
    fontWeight: fontWeight.bold,
    semanticElement: 'h6',
    textTransform: 'none',
  },
  [TypographyVariant.DisplayHeader1]: {
    fontSize: '40px',
    fontWeight: fontWeight.medium,
    semanticElement: 'h1',
    textTransform: 'none',
  },
  [TypographyVariant.DisplayHeader2]: {
    fontSize: '40px',
    fontWeight: fontWeight.medium,
    semanticElement: 'h1',
    textTransform: 'none',
  },
  [TypographyVariant.Header1]: {
    fontSize: '30px',
    fontWeight: fontWeight.semiBold,
    semanticElement: 'h1',
    textTransform: 'none',
  },
  [TypographyVariant.Header2]: {
    fontSize: '24px',
    fontWeight: fontWeight.semiBold,
    semanticElement: 'h2',
    textTransform: 'none',
  },
  [TypographyVariant.Header3]: {
    fontSize: '20px',
    fontWeight: fontWeight.semiBold,
    semanticElement: 'h3',
    textTransform: 'none',
  },
  [TypographyVariant.Header4]: {
    fontSize: '16px',
    fontWeight: fontWeight.semiBold,
    semanticElement: 'h4',
    textTransform: 'none',
  },
  [TypographyVariant.Header5]: {
    fontSize: '14px',
    fontWeight: fontWeight.semiBold,
    semanticElement: 'h5',
    textTransform: 'none',
  },
  [TypographyVariant.Paragraph1]: {
    fontSize: '16px',
    fontWeight: fontWeight.regular,
    semanticElement: 'span',
    textTransform: 'none',
  },
  [TypographyVariant.Paragraph2]: {
    fontSize: '14px',
    fontWeight: fontWeight.regular,
    semanticElement: 'span',
    textTransform: 'none',
  },
  [TypographyVariant.Paragraph3]: {
    fontSize: '12px',
    fontWeight: fontWeight.regular,
    semanticElement: 'span',
    textTransform: 'none',
  },
  [TypographyVariant.Paragraph4]: {
    fontSize: '10px',
    fontWeight: fontWeight.regular,
    semanticElement: 'span',
    textTransform: 'none',
  },
};

export const getFontProperties = {
  fontFamily,
  fontWeight,
  fontBodyProperties,
  fontHeadingProperties,
  getBodyProperties: (variant: number): TypographyProperties =>
    fontBodyProperties[variant],
  getHeadingProperties: (variant: number): TypographyProperties =>
    fontHeadingProperties[variant],
  getTypographyProperties: (variant: TypographyVariant): TypographyProperties =>
    typographyProperties[variant],
};
