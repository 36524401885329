import styled, { css } from 'styled-components';
import { Typography } from 'components/core';

interface ChipContainerProps {
  isNegative?: boolean;
}

const PercentageYield = styled(Typography)`
  color: var(--color-salem);
  font-size: 12px;
`;

const ChipContainer = styled.div<ChipContainerProps>`
  background: var(--color-grannyApple);
  border-radius: 4px;
  display: inline-block;
  padding: 4px;

  ${(props) =>
    props.isNegative &&
    css`
      background-color: var(--color-sockeye);
      ${PercentageYield} {
        color: var(--color-torchRed);
      }
    `};
`;

export const Styled = {
  ChipContainer,
  PercentageYield,
};
