import React from 'react';
import { Box } from '@material-ui/core';
import { ChartLegend } from 'modules/charts/legend/ChartLegend';
import { getColor } from 'styles/css-constants';

export const CashFlowTrendsLegend: React.FC = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={3}>
        <ChartLegend
          line
          subtitle="Prior Year"
          color={getColor('christalle')}
        />
      </Box>
      <Box mr={3}>
        <ChartLegend subtitle="Actuals" color={getColor('mintGreen')} />
      </Box>
      <Box mr={3}>
        <ChartLegend subtitle="Projected" color={getColor('gallery')} />
      </Box>
    </Box>
  );
};
