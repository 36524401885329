import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { useForm } from 'react-hook-form';
import { useTracking, TrackEventName } from 'modules/tracking';

import { ManualBankAccount } from 'types';
import { useManualBankAccountConnect } from 'modules/onboarding/v3-streamlined-onboarding/pages/bank-details/components/manual-ach/manual-ach-form/hooks/use-manual-bank-account-connect';

import {
  AutocompleteController,
  TextFieldController,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { formErrorFocus } from 'modules/onboarding/v3-streamlined-onboarding/utils';

import {
  formFields,
  BankAccountType,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/bank-details/components/manual-ach/manual-ach-form/manual-ach-form-fields';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Button } from 'modules/2023-q3/components/button/button';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { Styled } from 'modules/2023-q3/deposit-withdrawal/pages/form/form.style';

interface Props {
  nextStep: () => void;
  setBankAccount: React.Dispatch<
    React.SetStateAction<ManualBankAccount | undefined>
  >;
}

export const BankAccountACHInformationForm: React.FC<Props> = ({
  nextStep,
  setBankAccount,
}) => {
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();

  const { control, handleSubmit } = useForm<ManualBankAccount>();

  const manualBankAccountConnect = useManualBankAccountConnect();

  const onSubmit = (data: ManualBankAccount) => {
    data.accountType = data.accountType.toLowerCase();
    manualBankAccountConnect.mutate(data, {
      onSuccess: () => {
        setBankAccount(data);
        nextStep();
      },
    });

    trackEvent({
      eventName: TrackEventName.ManualACHFormCompleted,
    });
  };

  const Title = (
    <Typography color="black19" variant={TypographyVariant.Header3}>
      Bank Account ACH Information
    </Typography>
  );

  const ContinueButton = ({ mobile }: { mobile?: boolean }) => {
    return (
      <Box mt={mobile ? 10 : undefined}>
        <Button
          borderRadius={mobile ? 0 : undefined}
          bottomFixed={mobile}
          fullWidth
          type="submit"
        >
          <ButtonContent mobile={mobile}>Add Bank Account</ButtonContent>
        </Button>
      </Box>
    );
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MobileView mobile={<Box p={2.5}>{Title}</Box>}>
          <Box mb={3}>{Title}</Box>
        </MobileView>

        <Styled.FormBlocks>
          <Styled.FormBlock p={2.5}>
            <TextFieldController {...formFields.bankName} control={control} />
          </Styled.FormBlock>

          <Styled.FormBlock p={2.5}>
            <TextFieldController
              {...formFields.routingNumber}
              control={control}
            />
          </Styled.FormBlock>

          <Styled.FormBlock p={2.5}>
            <TextFieldController
              {...formFields.accountNumber}
              control={control}
            />
          </Styled.FormBlock>
          <Styled.FormBlock p={2.5}>
            <AutocompleteController
              {...formFields.accountType}
              control={control}
            />
          </Styled.FormBlock>
        </Styled.FormBlocks>

        <MobileView mobile={<ContinueButton mobile />}>
          <Box mt={5}>
            <ContinueButton />
          </Box>
        </MobileView>
      </form>
    </FormContainer>
  );
};
