import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { Button, Heading, Typography } from 'components/core';
import { ReactComponent as QuestionMarkIconAlias } from 'assets/icons/question-mark.svg';

const Tooltip = styled(Box)`
  border: 1px solid var(--color-stromboli);
  border-radius: 3px;
  bottom: 0px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.1s ease-in;
`;

const TooltipContainer = styled(Box)`
  height: fit-content;

  &:hover {
    ${Tooltip} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Styled = { TooltipContainer, Tooltip };
