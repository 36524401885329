import React from 'react';
import { Box } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Spinner,
  TypographyVariant,
} from 'components/core';
import { GridCard } from 'components/dashboard';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';

import { Styled } from './treasure-reserve-card.style';

export const TreasureReserveCard = () => {
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();

  const { data: portfolio, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );

  return (
    <GridCard title="Balance">
      <Box p={2}>
        {(isLoadingBusiness || isLoadingPortfolios) && <Spinner />}

        {portfolio && !Number.isNaN(portfolio?.amountsInDollars?.totalAmount) && (
          <Styled.BigBoldNumber
            color="nero"
            variant={TypographyVariant.BoldLargeHeader}
          >
            <Currency
              number={portfolio.amountsInDollars.totalAmount}
              variant={CurrencyVariant.Full}
            />
          </Styled.BigBoldNumber>
        )}
      </Box>
    </GridCard>
  );
};
