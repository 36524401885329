import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg';
import { useTranslation } from 'app/i18n';
import { Styled } from './allocation.style';

const Allocation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" my={2}>
      <Box width={0.25}>
        <label>
          <Typography variant={TypographyVariant.SubHeader2} color="nero">
            Portfolio
          </Typography>
        </label>
      </Box>
      <Box display="flex" width={0.75}>
        <Box display="flex" flexDirection="column" width={1}>
          <Box display="flex">
            <Box width={0.3} textAlign="right" mr={1}>
              <Typography variant={TypographyVariant.BodyBold}>100%</Typography>
            </Box>
            <Box width={0.7}>
              <Typography variant={TypographyVariant.Body}>Cash</Typography>
            </Box>
          </Box>
        </Box>
        <Styled.TooltipContainer display="flex" p={0.5} position="relative">
          <Styled.Tooltip bgcolor="white" width={265} p={2} mb={4}>
            <Typography variant={TypographyVariant.Body}>
              {t('onboarding sweep widget allocation', { ns: 'compliance' })}
            </Typography>
          </Styled.Tooltip>
          <QuestionMarkIcon />
        </Styled.TooltipContainer>
      </Box>
    </Box>
  );
};

export { Allocation };
