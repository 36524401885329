import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconCheckmarkCircleTransparent } from 'assets/icons/checkmarks/checkmark-circle-transparent.svg';
import { ReactComponent as IconNotificationBell } from 'assets/icons/notification/bell.svg';
import { AllocationTotalMessage } from './components/allocation-total-message/allocation-total-message';
import { ResetAllocation } from './components/reset-allocation/reset-allocation';
import {
  IProductAllocations,
  ProductAllocationProducts,
} from 'modules/2023-q3/allocation/interface/product-allocation';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { Container } from 'modules/2023-q3/allocation/components/container/container';
import { useHideProduct } from 'modules/2023-q4/white-label/hooks/use-hide-product';
import { ProductAllocation } from './components/product-allocation/product-allocation';
import { ManagedTreasuriesMinimumMessage } from './components/managed-treasuries-minimum-message/managed-treasuries-minimum-message';
import { Styled } from './form.style';

interface FormProps {
  allocation: IProductAllocations;
  initialAllocation: IProductAllocations;
  nextStep: () => void;
  setAllocation: React.Dispatch<React.SetStateAction<IProductAllocations>>;
  totalAmount: number;
}

export const Form: React.FC<FormProps> = ({
  allocation,
  initialAllocation,
  nextStep,
  setAllocation,
  totalAmount,
}) => {
  const { hideProduct } = useHideProduct();

  // set hidden products allocations to 0
  [
    ProductAllocationProducts.ManagedMoneyMarket,
    ProductAllocationProducts.ManagedTreasuries,
    ProductAllocationProducts.ManagedIncome,
  ].map((product) => {
    if (hideProduct(product)) {
      allocation[product].value = 0;
    }
  });

  const showManagedTreasuriesMessage =
    !hideProduct(ProductAllocationProducts.ManagedMoneyMarket) &&
    !hideProduct(ProductAllocationProducts.ManagedTreasuries);

  return (
    <>
      <Box mt={{ xs: 0, sm: 5 }}>
        <Box mb={3.5} textAlign={{ sm: 'center' }}>
          <Box mb={1}>
            <MobileView
              mobile={
                <Typography color="black19" variant={TypographyVariant.Header3}>
                  Update Your Portfolio Allocation
                </Typography>
              }
            >
              <Typography color="black19" variant={TypographyVariant.Header2}>
                Update Your Portfolio Allocation
              </Typography>
            </MobileView>
          </Box>

          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            Tap on{' '}
            <SVGBox display="inline" position="relative" top={3} mx={0.5}>
              <IconCheckmarkCircleTransparent />
            </SVGBox>{' '}
            to remove any selected investment products. Total allocation must
            equal exactly 100% to continue.
          </Typography>
        </Box>

        <MobileView
          mobile={
            <Box mb={3.5}>
              <Divider />
            </Box>
          }
        />

        <Container>
          <Box p={{ xs: 0, sm: 3 }}>
            <AllocationTotalMessage
              allocation={allocation}
              initialAllocation={initialAllocation}
              nextStep={nextStep}
              totalAmount={totalAmount}
            />

            <Box mt={1} textAlign="center">
              <ResetAllocation
                allocation={allocation}
                initialAllocation={initialAllocation}
                setAllocation={setAllocation}
              />
            </Box>

            <Box my={3}>
              <Grid alignItems="stretch" container spacing={2}>
                {Object.keys(ProductAllocationProducts).map((productKey) => {
                  const product =
                    ProductAllocationProducts[
                      productKey as keyof typeof ProductAllocationProducts
                    ];

                  if (hideProduct(product)) {
                    return null;
                  }

                  return (
                    <Grid item xs={12} md>
                      <Styled.RoundedContainer height="100%" p={2}>
                        <ProductAllocation
                          product={product}
                          productAllocation={allocation[product]}
                          rate={allocation[product].rate}
                          setAllocation={setAllocation}
                          totalAmount={totalAmount}
                          value={allocation[product].value}
                        />
                      </Styled.RoundedContainer>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Box textAlign="center">
              <Typography
                color="brown3a"
                variant={TypographyVariant.Paragraph2}
              >
                <SVGBox display="inline" position="relative" top={3} mx={0.5}>
                  <IconNotificationBell />
                </SVGBox>
                Reminder{' '}
                <Typography
                  color="grey3b"
                  variant={TypographyVariant.Paragraph2}
                >
                  / Treasure Cash has 1 business day liquidity. All other
                  products have 3-5 business day liquidity.
                </Typography>
              </Typography>
            </Box>

            {showManagedTreasuriesMessage && (
              <Box textAlign="center">
                <Typography
                  color="grey3b"
                  variant={TypographyVariant.Paragraph2}
                >
                  <ManagedTreasuriesMinimumMessage />
                </Typography>
              </Box>
            )}
          </Box>
        </Container>
      </Box>

      <Box height={{ xs: 50, sm: 100 }} />
    </>
  );
};
