import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { Cusip } from '../types/cusip';

export const useRestrictedCusips = (
  businessId: string,
): UseQueryResult<Cusip[]> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery(
    [ReactQueryKeys.RestrictedCusips, businessId],
    async () => {
      const { data } = await treasureApi().get(
        `/business/${businessId}/reserve-account/restricted-cusips`,
      );

      return data.items;
    },
    {
      enabled: !!businessId,
    },
  );
};
