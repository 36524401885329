import React from 'react';
import PropTypes from 'prop-types';
import { TypeOptions } from 'react-toastify';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from './toast-message.style';

export const ToastMessage = ({
  type,
  message,
}: {
  type: TypeOptions;
  message: string;
}): React.ReactElement => {
  const { trackEvent } = useTracking();

  trackEvent({
    eventName: TrackEventName.ToastNotificationShown,
    message,
    type,
  });
  const getIcon = (type: TypeOptions) => {
    switch (type) {
      case 'success':
        return <Styled.SuccessIcon />;
      case 'error':
        return <Styled.ErrorIcon />;
      case 'info':
      default:
        return <Styled.InfoIcon />;
    }
  };

  return (
    <>
      <Styled.IconWrapper>{getIcon(type)}</Styled.IconWrapper>
      <div>
        <Styled.ToastMessageType>{type}</Styled.ToastMessageType>
        <Styled.ToastMessage>{message}</Styled.ToastMessage>
      </div>
    </>
  );
};

ToastMessage.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
