import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useStoreAuthentication } from 'stores';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';

export const useUser = (businessId: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Authentication);
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);
  const { partnerId } = useStoreWhiteLabelPartner((state) => state);

  return useQuery(
    [ReactQueryKeys.User],
    async () => {
      const response = await treasureApi().get('/user');

      response.data.roles = [response.data.role];

      if (partnerId) {
        response.data.roles.push(UserRole.WHITE_LABEL);
      }

      return response.data;
    },
    {
      enabled: isSignedIn && !!businessId,
      staleTime: Infinity,
    },
  );
};
