import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Hidden } from '@material-ui/core';
import {
  Button,
  Currency,
  CurrencyVariant,
  Heading,
  ItemDefinition,
  Logo,
  LogoVariant,
  Percentage,
} from 'components/core';
import { BankAccount } from 'types';
import { BankAccountPropTypes } from 'types/prop-types';
import { Styled } from './banking-account-row.style';

enum BankAccountRowType {
  checking = 'checking',
  liability = 'liability',
  reserve = 'reserve',
}

export interface BankingAccountRowData {
  /** bank account data */
  data: BankAccount;

  /** does the current business have an open treasure reserve? */
  hasOpenTreasureReserve: boolean;

  /** click handler for the send to treasure button */
  sendToTreasureModal?: () => void;

  /** click handler for the sweep account button */
  sweepAccountModal?: () => void;
}

const BankingAccountRow: React.FC<BankingAccountRowData> = ({
  data,
  hasOpenTreasureReserve,
  sendToTreasureModal,
  sweepAccountModal,
}) => {
  const checkingBody = () => {
    return (
      <>
        <Box mt={{ xs: 3, sm: 0 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Hidden xsDown>
              <Grid item xs={3}>
                <ItemDefinition
                  label="Available Balance"
                  value={
                    <Heading variant={3}>
                      <Currency
                        number={data.available}
                        variant={CurrencyVariant.NearestDollar}
                      />
                    </Heading>
                  }
                />
              </Grid>
            </Hidden>

            <Grid item xs>
              <Box mx={{ xs: 0, sm: 3.5 }}>
                <Box mb={1}>
                  <Styled.UtilizedCashProgressLabel>
                    <Percentage number={data.utilization} />
                  </Styled.UtilizedCashProgressLabel>
                  <Heading color="gothic" variant={5}>
                    Optimally Utilized Cash
                  </Heading>
                </Box>
                <Styled.UtilizedCashProgress
                  variant="determinate"
                  value={data.utilization}
                />

                <Box mt={2} mb={1}>
                  <Styled.AllocatedCashProgressLabel>
                    <Percentage number={data.allocatedCash} />
                  </Styled.AllocatedCashProgressLabel>
                  <Heading color="gothic" variant={5}>
                    Allocated Cash
                  </Heading>
                </Box>
                <Styled.AllocatedCashProgress
                  variant="determinate"
                  value={data.allocatedCash}
                />
              </Box>
            </Grid>

            <Hidden xsDown>
              <Grid item xs={3}>
                {data.idle > 0 && (
                  <ItemDefinition
                    label="Idle Cash"
                    value={
                      <Heading variant={2}>
                        <Currency
                          number={data.idle}
                          variant={CurrencyVariant.NearestDollar}
                        />
                      </Heading>
                    }
                  />
                )}
              </Grid>
            </Hidden>
          </Grid>
        </Box>

        <Hidden smUp>
          <Box mt={3}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <ItemDefinition
                  label="Available Balance"
                  value={
                    <Heading variant={3}>
                      <Currency
                        number={data.available}
                        variant={CurrencyVariant.NearestDollar}
                      />
                    </Heading>
                  }
                />
              </Grid>

              <Grid item>
                {data.idle > 0 && (
                  <ItemDefinition
                    label="Idle Cash"
                    value={
                      <Heading variant={3}>
                        <Currency
                          number={data.idle}
                          variant={CurrencyVariant.NearestDollar}
                        />
                      </Heading>
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Hidden>
      </>
    );
  };

  const renderRowBodyExtraData = () => {
    const isCheckingType = data.Type === BankAccountRowType.checking;

    if (isCheckingType) {
      return checkingBody();
    }
    return null;
  };

  const noDataAvailable = data.available === 0 && data.balance === 0;

  if (noDataAvailable) {
    return (
      <Styled.Row>
        <Box p={{ xs: 2, sm: 3 }}>
          <Grid container>
            <Hidden xsDown>
              <Grid item>
                <Box mr={3}>
                  <Logo
                    institution={data.institution}
                    variant={LogoVariant.square}
                  />
                </Box>
              </Grid>
            </Hidden>

            <Grid item xs>
              <Grid container>
                <Hidden smUp>
                  <Grid item>
                    <Box mr={3}>
                      <Logo
                        institution={data.institution}
                        variant={LogoVariant.square}
                      />
                    </Box>
                  </Grid>
                </Hidden>

                <Grid item xs>
                  <Styled.RowHeading color="nero" variant={4}>
                    {data.Name || ''} {`······${data.mask}`}{' '}
                  </Styled.RowHeading>
                </Grid>

                {hasOpenTreasureReserve && (
                  <Hidden xsDown mdUp>
                    <Grid item>
                      <Styled.RowCallToAction item xs={3}>
                        {data.Type === BankAccountRowType.checking && (
                          <Box textAlign="right">
                            <Button onClick={sendToTreasureModal}>
                              Transfer to Reserve
                            </Button>
                          </Box>
                        )}
                      </Styled.RowCallToAction>
                    </Grid>
                  </Hidden>
                )}
              </Grid>

              <Hidden smUp>
                <Styled.RowContentGrid container alignItems="center">
                  <Grid item>
                    <Box mr={3} visibility="hidden">
                      <Logo
                        institution={data.institution}
                        variant={LogoVariant.square}
                      />
                    </Box>
                  </Grid>
                </Styled.RowContentGrid>
              </Hidden>
            </Grid>

            {hasOpenTreasureReserve && (
              <Hidden smDown>
                <Styled.RowCallToAction item xs={3}>
                  {data.Type === BankAccountRowType.checking && (
                    <Box textAlign="right">
                      <Button onClick={sendToTreasureModal}>
                        Transfer to Reserve
                      </Button>
                    </Box>
                  )}
                </Styled.RowCallToAction>
              </Hidden>
            )}
          </Grid>
        </Box>
      </Styled.Row>
    );
  }

  return (
    <Styled.Row>
      <Box p={{ xs: 2, sm: 3 }}>
        <Grid container>
          <Hidden xsDown>
            <Grid item>
              <Box mr={3}>
                <Logo
                  institution={data.institution}
                  variant={LogoVariant.square}
                />
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs>
            <Grid container>
              <Hidden smUp>
                <Grid item>
                  <Box mr={3}>
                    <Logo
                      institution={data.institution}
                      variant={LogoVariant.square}
                    />
                  </Box>
                </Grid>
              </Hidden>

              <Grid item xs>
                <Styled.RowHeading color="nero" variant={4}>
                  {data.Name || ''} {`······${data.mask}`}{' '}
                </Styled.RowHeading>
              </Grid>

              {hasOpenTreasureReserve && (
                <Hidden xsDown mdUp>
                  <Grid item>
                    <Styled.RowCallToAction item xs={3}>
                      {data.Type === BankAccountRowType.checking && (
                        <Box textAlign="right">
                          <Button onClick={sendToTreasureModal}>
                            Transfer to Reserve
                          </Button>
                        </Box>
                      )}
                    </Styled.RowCallToAction>
                  </Grid>
                </Hidden>
              )}
            </Grid>

            <Hidden smUp>
              <Styled.RowContentGrid container alignItems="center">
                <Grid item>
                  <Box mr={3} visibility="hidden">
                    <Logo
                      institution={data.institution}
                      variant={LogoVariant.square}
                    />
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <ItemDefinition
                    label="Balance"
                    value={
                      <Heading variant={2}>
                        <Currency
                          number={data.balance}
                          variant={CurrencyVariant.NearestDollar}
                        />
                      </Heading>
                    }
                  />
                </Grid>
              </Styled.RowContentGrid>
            </Hidden>

            <Styled.RowContentGrid container alignItems="center">
              <Hidden xsDown>
                <Grid item xs={2}>
                  <ItemDefinition
                    label="Balance"
                    value={
                      <Heading variant={2}>
                        <Currency
                          number={data.balance}
                          variant={CurrencyVariant.NearestDollar}
                        />
                      </Heading>
                    }
                  />
                </Grid>

                <Grid item xs={1}>
                  {data.Type === BankAccountRowType.liability &&
                  data.interestRate ? (
                    <ItemDefinition
                      label="Interest Rate"
                      value={
                        <Heading variant={3}>
                          <Percentage number={data.interestRate} />
                        </Heading>
                      }
                    />
                  ) : (
                    ''
                  )}
                </Grid>
              </Hidden>

              <Grid item xs>
                {renderRowBodyExtraData()}
              </Grid>
            </Styled.RowContentGrid>
          </Grid>

          {hasOpenTreasureReserve && (
            <Hidden smDown>
              <Styled.RowCallToAction item xs={3}>
                {data.Type === BankAccountRowType.checking && (
                  <Box textAlign="right">
                    <Button onClick={sendToTreasureModal}>
                      Transfer to Reserve
                    </Button>
                  </Box>
                )}
              </Styled.RowCallToAction>
            </Hidden>
          )}
        </Grid>
      </Box>
    </Styled.Row>
  );
};

BankingAccountRow.propTypes = {
  data: BankAccountPropTypes.isRequired,
  hasOpenTreasureReserve: PropTypes.bool.isRequired,
  sendToTreasureModal: PropTypes.func,
  sweepAccountModal: PropTypes.func,
};

export { BankingAccountRow };
