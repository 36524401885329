import React, { useState } from 'react';
import { Box, Divider } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';
import {
  IProductAllocations,
  MANGED_TREASURIES_MINIMUM,
  ProductAllocationProducts,
} from 'modules/2023-q3/allocation/interface/product-allocation';
import { ProductAllocation } from 'modules/2023-q3/allocation/pages/form/components/product-allocation/product-allocation';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { FooterButton } from 'modules/2023-q3/allocation/components/footer/footer';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { ManagedTreasuriesMinimumModal } from './components/managed-treasuries-minimum-modal/managed-treasuries-minimum-modal';

export const AllocationTotalMessage = ({
  allocation,
  initialAllocation,
  nextStep,
  totalAmount,
}: {
  allocation: IProductAllocations;
  initialAllocation: IProductAllocations;
  nextStep: () => void;
  totalAmount: number;
}) => {
  const [
    showManagedTreasuriesMinimumModal,
    setShowManagedTreasuriesMinimumModal,
  ] = useState(false);
  // this allows the component to work for new customers who have no funds
  // they can also submit the default (initial) allocation
  const hasAUM = totalAmount > 0;

  const allocationChange =
    allocation[ProductAllocationProducts.ManagedMoneyMarket].value !==
      initialAllocation[ProductAllocationProducts.ManagedMoneyMarket].value ||
    allocation[ProductAllocationProducts.ManagedTreasuries].value !==
      initialAllocation[ProductAllocationProducts.ManagedTreasuries].value ||
    allocation[ProductAllocationProducts.ManagedIncome].value !==
      initialAllocation[ProductAllocationProducts.ManagedIncome].value ||
    allocation[ProductAllocationProducts.TreasureCash].value !==
      initialAllocation[ProductAllocationProducts.TreasureCash].value;

  const allocationPercentageTotal = Number(
    (
      Number(allocation[ProductAllocationProducts.ManagedMoneyMarket].value) +
      Number(allocation[ProductAllocationProducts.ManagedTreasuries].value) +
      Number(allocation[ProductAllocationProducts.ManagedIncome].value) +
      Number(allocation[ProductAllocationProducts.TreasureCash].value)
    ).toFixed(2),
  );

  const managedTreasuriesAmount =
    totalAmount *
    (Number(allocation[ProductAllocationProducts.ManagedTreasuries].value) /
      100);

  const fullyAllocated = allocationPercentageTotal === 100;
  const overAllocated = allocationPercentageTotal > 100;
  const underAllocated = allocationPercentageTotal < 100;

  const getContinueButtonText = () => {
    if (fullyAllocated && hasAUM && !allocationChange) {
      return 'Update Allocation(s) to Continue';
    }

    if (overAllocated) {
      return 'Fix Over-Allocation to Continue';
    }

    if (underAllocated) {
      return 'Fix Under-Allocation to Continue';
    }

    return 'Continue';
  };

  const handleContinueClick = () => {
    if (
      managedTreasuriesAmount > 0 &&
      managedTreasuriesAmount < MANGED_TREASURIES_MINIMUM
    ) {
      setShowManagedTreasuriesMinimumModal(true);
    } else {
      nextStep();
    }
  };

  const allocationMessage = (
    <Box textAlign="center">
      {fullyAllocated && (
        <>
          <Box mb={0.5}>
            <Typography color="primary" variant={TypographyVariant.Header5}>
              Funds Fully Allocated{' '}
              <Percentage
                decimalScale={2}
                number={allocationPercentageTotal}
                showExtremePrecision
              />
              {hasAUM && (
                <>
                  {' '}
                  /{' '}
                  <Currency
                    number={totalAmount}
                    variant={CurrencyVariant.Full}
                  />
                </>
              )}
            </Typography>
          </Box>

          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            {allocationChange
              ? "Awesome, you're updated and fully allocated."
              : 'Update one or more of your allocations to continue.'}
          </Typography>
        </>
      )}

      {underAllocated && (
        <>
          <Box mb={0.5}>
            <Typography color="blue2" variant={TypographyVariant.Header5}>
              Under-Allocated by{' '}
              <Percentage
                decimalScale={2}
                number={100 - allocationPercentageTotal}
                showExtremePrecision
              />
              {hasAUM && (
                <>
                  {' '}
                  /{' '}
                  <Currency
                    number={
                      totalAmount -
                      totalAmount * (allocationPercentageTotal / 100)
                    }
                    variant={CurrencyVariant.Full}
                  />
                </>
              )}
            </Typography>
          </Box>

          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            Allocate the remaining{' '}
            <Percentage
              decimalScale={2}
              number={100 - allocationPercentageTotal}
              showExtremePrecision
            />{' '}
            in order to move forward.
            {hasAUM && <> This includes any uninvested funds at Apex.</>}
          </Typography>
        </>
      )}

      {overAllocated && (
        <>
          <Box mb={0.5}>
            <Typography color="red2" variant={TypographyVariant.Header5}>
              Over-Allocated by{' '}
              <Percentage
                decimalScale={2}
                number={Math.abs(100 - allocationPercentageTotal)}
                showExtremePrecision
              />
              {hasAUM && (
                <>
                  {' '}
                  /{' '}
                  <Currency
                    number={
                      totalAmount -
                      totalAmount * (allocationPercentageTotal / 100)
                    }
                    variant={CurrencyVariant.Full}
                  />
                </>
              )}
            </Typography>
          </Box>

          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            Reduce your allocations by{' '}
            <Percentage
              decimalScale={2}
              number={Math.abs(100 - allocationPercentageTotal)}
              showExtremePrecision
            />{' '}
            in order to move forward.
          </Typography>
        </>
      )}
    </Box>
  );

  return (
    <>
      <MobileView
        mobile={
          <Box
            bgcolor="white"
            position="fixed"
            bottom={0}
            left="0"
            right="0"
            zIndex={100}
          >
            <Divider />

            <Box mb={1}>{allocationMessage}</Box>

            <Box
              mx="auto"
              my={{ xs: 0, sm: 2.5 }}
              width={{ xs: '100%', sm: 700 }}
            >
              <FooterButton
                disabled={!fullyAllocated || !allocationChange}
                onClick={handleContinueClick}
              >
                <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
                  {getContinueButtonText()}
                </ButtonContent>
              </FooterButton>
            </Box>
          </Box>
        }
      >
        {allocationMessage}

        <Box
          bgcolor="white"
          position="fixed"
          bottom={0}
          left="0"
          right="0"
          zIndex={100}
        >
          <Divider />

          <Box
            mx="auto"
            my={{ xs: 0, sm: 2.5 }}
            width={{ xs: '100%', sm: 700 }}
          >
            <FooterButton
              disabled={!fullyAllocated || (hasAUM && !allocationChange)}
              onClick={handleContinueClick}
            >
              <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
                {getContinueButtonText()}
              </ButtonContent>
            </FooterButton>
          </Box>
        </Box>
      </MobileView>

      <ManagedTreasuriesMinimumModal
        managedTreasuriesAmount={managedTreasuriesAmount}
        nextStep={nextStep}
        showModal={showManagedTreasuriesMinimumModal}
        setShowModal={setShowManagedTreasuriesMinimumModal}
      />
    </>
  );
};
