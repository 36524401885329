import styled from 'styled-components';
import { Box } from '@material-ui/core';

const CenteredContainer = styled(Box)`
  max-width: 1400px;
`;

export const Styled = {
  CenteredContainer,
};
