import React from 'react';
import { Box, Divider, Grid, Hidden } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Header } from 'layouts/v3-blank-layout/components/header/header';
import { TreasureLogo } from 'assets';
import { useTracking, TrackEventName } from 'modules/tracking';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import {
  WhiteLabelLogo,
  WhiteLabelLogoVariant,
} from 'modules/2023-q4/white-label/components/white-label-logo/white-label-logo';

interface TaskHeaderProps {
  button?: React.ReactNode;

  taskProgress?: React.ReactNode;

  title?: string;

  mobile?: boolean;
}

export const TaskHeader: React.FC<TaskHeaderProps> = ({
  button,
  taskProgress,
  title,
  mobile,
}) => {
  const { Track, trackEvent } = useTracking<{
    clickText: string;
    eventName: TrackEventName;
    section: string;
  }>({
    section: 'Task Header',
  });

  const handleLogoClick = () => {
    trackEvent({
      clickText: title,
      eventName: TrackEventName.Click,
    });
  };

  return (
    <Track>
      <Header>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={9} sm>
            <Grid container alignItems="center" onClick={handleLogoClick}>
              <Grid item>
                <Box mr={1.5}>
                  <FeatureFlag
                    disabled={<TreasureLogo />}
                    enabled={
                      <WhiteLabelLogo
                        variant={WhiteLabelLogoVariant.TaskHeader}
                      />
                    }
                    flag={FeatureFlags.REACT_APP_2023_Q4_WHITELABEL_ENABLED}
                  />
                </Box>
              </Grid>
              {title && (
                <Grid item>
                  <Typography
                    color="black19"
                    variant={TypographyVariant.Paragraph1}
                  >
                    {title}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {taskProgress && (
            <Hidden xsDown>
              <Grid item sm>
                {taskProgress}
              </Grid>
            </Hidden>
          )}

          <Grid item xs={3} sm>
            <Box textAlign="right">{button}</Box>
          </Grid>
        </Grid>
      </Header>

      <Hidden smUp>
        <Box py={mobile ? 1.5 : 1} px={2}>
          {taskProgress}
        </Box>
        <Box pb={2.5}>
          <Divider />
        </Box>
      </Hidden>
    </Track>
  );
};
