import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Currency, CurrencyVariant, Percentage, Text } from 'components/core';
import { Mask } from 'components/banking';

import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Styled } from './allocation.style';

export interface AccountAllocation {
  id: string;
  name: string;
  lastFourDigits: string;
  containedPercentage: number;
  containedAmount: string; // TODO: (API) should be number but api returns string
}

interface DisplayedAccountAllocation extends AccountAllocation {
  displayLevel: number;
  displayWidth: number;
}

export interface AllocationProps {
  accounts: AccountAllocation[];

  backgroundColor?: string;

  displayShowAllAccountsLink?: boolean;

  isIdle?: boolean;

  numberOfDisplayedAccounts?: number;

  variant?: string;
}

const TREASURE_RESERVE_NAME = 'Treasure Reserve';

const Allocation: React.FC<AllocationProps> = ({
  accounts,
  backgroundColor,
  displayShowAllAccountsLink,
  isIdle,
  numberOfDisplayedAccounts,
  variant,
}) => {
  const refs: React.RefObject<HTMLDivElement>[] = useMemo(() => [], []);
  const [didLoad, setDidLoad] = useState<boolean>(false);
  const [hasOtherAccountsGroup, setHasOtherAccountsGroup] = useState(false);
  const [displayedAccounts, setDisplayedAccounts] =
    useState<DisplayedAccountAllocation[]>();
  const numberofAccountsToDisplay = numberOfDisplayedAccounts || 4;

  useEffect(() => {
    const sortAndGroupAccounts = () => {
      const MAX_ACCOUNTS_TO_DISPLAY = numberofAccountsToDisplay;
      const PERCENTAGE_THRESHOLD = 0.15;
      const MINIMUM_OTHER_WIDTH = 0.1;

      let combinedAmount = 0;
      let combinedPercentage = 0;
      const displayedAccounts = [];
      const displayTreasureReserve =
        accounts.filter(
          (account) =>
            account.name === TREASURE_RESERVE_NAME &&
            Number(account.containedAmount) > 0.0, // TODO: (API) should be number but api returns string
        ).length === 1;

      // sort accounts by percentage in descending order
      if (accounts.length > 1) {
        accounts.sort((a, b) =>
          a.containedPercentage > b.containedPercentage ? -1 : 1,
        );
      }

      // check if account meets threshold, if so, display it
      // Treasure Reserve will always be shown (even if it doesn't meet the treshold)
      for (let i = 0; i < accounts.length; i++) {
        const displayAccount: DisplayedAccountAllocation = {
          ...accounts[i],
          displayLevel: 1,
          displayWidth:
            accounts[i].containedPercentage < PERCENTAGE_THRESHOLD
              ? MINIMUM_OTHER_WIDTH
              : accounts[i].containedPercentage,
        };

        const alwaysAddTreasureReserve =
          displayTreasureReserve &&
          displayAccount.name === TREASURE_RESERVE_NAME;
        const addMaxAccountsThatMeetThreshold =
          displayedAccounts.length <
            MAX_ACCOUNTS_TO_DISPLAY - (displayTreasureReserve ? 1 : 0) &&
          accounts[i].containedPercentage >= PERCENTAGE_THRESHOLD;

        if (alwaysAddTreasureReserve || addMaxAccountsThatMeetThreshold) {
          displayedAccounts.push(displayAccount);
        } else {
          combinedAmount += +accounts[i].containedAmount; // TODO: (API) should be number but api returns string
          combinedPercentage += +accounts[i].containedPercentage;
        }
      }

      if (combinedPercentage > 0.0) {
        // if other is less than the threshold, apply minium width and subtract from the other accounts
        if (combinedPercentage < PERCENTAGE_THRESHOLD) {
          const widthAdjustment = MINIMUM_OTHER_WIDTH - combinedPercentage;
          const amountToRemove =
            widthAdjustment /
            displayedAccounts.filter(
              (account) => account.displayWidth !== MINIMUM_OTHER_WIDTH,
            ).length;

          displayedAccounts.forEach(function (account) {
            if (account.displayWidth === MINIMUM_OTHER_WIDTH) {
              return;
            }

            account.displayWidth -= amountToRemove;
          });
        }

        displayedAccounts.push({
          id: 'other',
          name: 'Other',
          lastFourDigits: '',
          containedPercentage: combinedPercentage,
          containedAmount: combinedAmount.toString(), // TODO: (API) should be number but api returns string
          displayLevel: 1,
          displayWidth:
            combinedPercentage < PERCENTAGE_THRESHOLD
              ? MINIMUM_OTHER_WIDTH
              : combinedPercentage,
        });

        setHasOtherAccountsGroup(true);
      }

      // TODO: if/when backend updates roundings, this check can be removed
      const allocationWidth = displayedAccounts.reduce(
        (totalWidth, displayAccount) =>
          totalWidth + displayAccount.displayWidth,
        0,
      );

      if (allocationWidth > 1) {
        const widthAdjustment =
          (allocationWidth - 1) /
          displayedAccounts.filter(
            (account) => account.displayWidth !== MINIMUM_OTHER_WIDTH,
          ).length;

        displayedAccounts.forEach((displayAccount) => {
          if (displayAccount.displayWidth === MINIMUM_OTHER_WIDTH) {
            return;
          }

          displayAccount.displayWidth -= widthAdjustment;
        });
      }
      // TODO: if/when backend updates roundings, this check can be removed

      // if the refs are set, determine which accounts (if any) need to go to the 2nd level
      if (refs && refs.length === displayedAccounts.length) {
        for (let i = 1; i < displayedAccounts.length; i++) {
          const prevRef = refs[i - 1];

          if (
            prevRef.current &&
            prevRef.current.offsetWidth < prevRef.current.scrollWidth &&
            displayedAccounts[i - 1].displayLevel === 1
          ) {
            displayedAccounts[i].displayLevel = 2;
          }
        }
      }

      setDisplayedAccounts(displayedAccounts);

      // trigger sort and group again to change the levels
      if (!didLoad) {
        setDidLoad(true);
      }
    };

    sortAndGroupAccounts();
  }, [didLoad, numberofAccountsToDisplay, accounts, refs]);

  return (
    <>
      <Grid container>
        {displayedAccounts &&
          displayedAccounts.length > 0 &&
          displayedAccounts.map(function (account, index) {
            const ref = React.createRef<HTMLDivElement>();

            if (index === 0) {
              refs.length = 0;
            }

            refs.push(ref);

            return (
              <Styled.Allocation
                item
                numberOfDisplayedAccounts={
                  numberofAccountsToDisplay + (hasOtherAccountsGroup ? 1 : 0)
                }
                width={
                  (account.displayWidth
                    ? account.displayWidth
                    : account.containedPercentage) * 100
                }
                forwardRef={ref}
                key={index.toString()}
              >
                <Styled.AllocationBar size={variant} />

                <Styled.AllocationInformation
                  pt={account.displayLevel === 2 ? 10 : 1}
                  pl={0.5}
                >
                  <Styled.AllocationText
                    backgroundColor={backgroundColor}
                    variant={1}
                  >
                    {account.name}{' '}
                    {account.lastFourDigits && (
                      <Mask mask={account.lastFourDigits} variant="small" />
                    )}
                  </Styled.AllocationText>

                  <Styled.AllocationText
                    backgroundColor={backgroundColor}
                    color="blank"
                    variant={3}
                  >
                    <Currency
                      number={+account.containedAmount} // TODO: (API) should be number but api returns string
                      variant={CurrencyVariant.Truncated}
                    />{' '}
                    {isIdle ? 'idle' : ''}
                  </Styled.AllocationText>

                  <Styled.AllocationText
                    backgroundColor={backgroundColor}
                    variant={2}
                  >
                    <Percentage number={account.containedPercentage * 100} />
                  </Styled.AllocationText>
                </Styled.AllocationInformation>
              </Styled.Allocation>
            );
          })}
      </Grid>

      {hasOtherAccountsGroup && displayShowAllAccountsLink !== false && (
        <Box mt={1.5} textAlign="center">
          <Link to={RoutesPath.pages.banking.path}>
            <Text color="hanPurple" variant={1}>
              See all accounts &gt;
            </Text>
          </Link>
        </Box>
      )}
    </>
  );
};

Allocation.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      lastFourDigits: PropTypes.string.isRequired,
      containedPercentage: PropTypes.number.isRequired,
      containedAmount: PropTypes.string.isRequired, // should be number but api returns string
    }).isRequired,
  ).isRequired,
  backgroundColor: PropTypes.string,
  displayShowAllAccountsLink: PropTypes.bool,
  isIdle: PropTypes.bool,
  numberOfDisplayedAccounts: PropTypes.number,
  variant: PropTypes.string,
};

export { Allocation };
