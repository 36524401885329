import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { MenuOption as MenuOptionAlias } from './components/menu-option/menu-option';

const MenuOption = styled(MenuOptionAlias)`
  background-color: var(--color-black19);
  cursor: pointer;
  display: block;

  &:hover {
    background-color: var(--color-grey2);
  }
`;

const MenuOptions = styled(Box)`
  min-width: 168px;

  ${MenuOption}:first-child {
    border-top-left-radius: var(--border-radius-06px);
    border-top-right-radius: var(--border-radius-06px);
  }

  ${MenuOption}:last-child {
    border-bottom-left-radius: var(--border-radius-06px);
    border-bottom-right-radius: var(--border-radius-06px);
  }
`;

export const Styled = {
  MenuOption,
  MenuOptions,
};
