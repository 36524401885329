import React from 'react';
import PropTypes from 'prop-types';
import { TrackEventName } from 'modules/tracking';
import { ReactComponent as Arrow } from 'assets/icons/fancy-arrow.svg';
import { ResendConfirmationEmail } from './resend-confirmation-email';

interface SignUpConfirmationProps {
  businessId: string;
}
export const SignUpConfirmation: React.FC<SignUpConfirmationProps> = ({
  businessId,
}) => {
  return (
    <ResendConfirmationEmail
      businessId={businessId}
      eventName={TrackEventName.SignUpResendEmail}
    >
      <>
        <Arrow />
        <p className="cta">
          We just sent you an email. Click the link to complete sign up
        </p>
        <p className="resend-text">Didn&apos;t get the email? </p>
      </>
    </ResendConfirmationEmail>
  );
};

SignUpConfirmation.propTypes = {
  businessId: PropTypes.string.isRequired,
};
