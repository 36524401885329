import styled from 'styled-components';
import { breakpoints } from 'styles/css-constants';

const CodeInput = styled.input`
  border: 0 none;
  border-right: 1px solid var(--color-grey6);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  width: 100%;
  text-align: center;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:read-only,
  &:valid {
    border-color: var(--color-green2);
  }
`;

export const Styled = {
  CodeInput,
};
