export enum BeneficialOwnershipExemptionStatus {
  STATE_REGULATED_INSURANCE_COMPANY = 'A financial institution regulated by a Federal functional regulator, or a bank regulated by a state bank regulator',
  STOCK_EXCHANGE = 'Entities traded on a U.S. stock exchange (NYSE, American or NASDAQ)',
  STOCK_EXCHANGE_MAJORITY = 'Any entity organized under the laws of the United States or of any State at least 51% of whose common stock or analogous equity interests are held by an entity traded on a U.S. stock exchange',
  COMMODITY_FUTURES_TRADING_COMMISSION_REGISTERED = 'A Commodity Futures Trading Commission registered entity',
  DEPARTMENT_OR_AGENCY_OF_FEDERAL_STATE_OR_SUBDIVISION = 'U.S. Federal/State/Local Government Agency',
  INSURANCE_COMPANY = 'Insurance Company regulated by a State of the United States',
  SECTION_12_SECURITIES_EXCHANGE_ACT_1934_OR_15D = 'An issuer of a class of securities registered under section 12 of the Securities Exchange Act of 1934',
  SEC_REGISTRATION_NUMBER = 'A Securities and Exchange Commission (SEC) registered investment company, investment advisor, broker dealer or other registered firm with a current SEC registration number',
  GOVERNMENT_ACTIVITIES = 'Non-U.S. Government Agency – engaging in government not commercial activities',
  PUBLIC_ACCOUNTING_FIRM_SECTION_102_SARBANES_OXLEY = 'A Public Accounting Firm registered under section 102 of the Sarbanes-Oxley Act',
  BANK_HOLDING_COMPANY = 'A Bank Holding Company',
  SAVIGNS_AND_LOAN_COMPANY = 'A Savings and Loan Holding Company',
  FINANCIAL_MARKET_UTILITY = 'A financial market utility designated by the Financial Stability Oversight Council',
  POOLED_INVESTMENT_VEHICLE = 'A pooled investment vehicle that is operated or advised by a financial institution that is exempt from Beneficial Ownership',
  NONPROFIT = 'A nonprofit corporation or similar entity and has filed its organizational documents with the appropriate State authority',
}
