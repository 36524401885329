import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { useTracking, TrackEventName } from 'modules/tracking';

export const AllocateButton: React.FC = () => {
  const { trackEvent } = useTracking();

  const buttonText = 'Allocate';

  const handleOnClick = () => {
    trackEvent({
      buttonText,
      eventName: TrackEventName.Click,
    });
  };
  return (
    <Link to={RoutesPath.tasks.allocation.path}>
      <Button>
        <Box px={2} py={1}>
          <Typography color="white" variant={TypographyVariant.Paragraph2}>
            {buttonText}
          </Typography>
        </Box>
      </Button>
    </Link>
  );
};
