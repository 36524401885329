import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { WireTransfer } from '../../wire-instructions';
import { Styled } from './instruction-row.style';

interface InstructionRowProps {
  data: WireTransfer;
}

export const InstructionRow: React.FC<InstructionRowProps> = ({ data }) => {
  return (
    <>
      <Typography color="black19" variant={TypographyVariant.Header5}>
        {data.label}
      </Typography>

      <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
        {data.value1}
      </Typography>

      {data.value2 && (
        <Box>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            {data.value2}
          </Typography>
        </Box>
      )}

      {data.helperText && (
        <Box mt={1} mb={2} textAlign="right">
          <Divider />

          <Styled.HelperText
            color="grey1"
            variant={TypographyVariant.Paragraph2}
          >
            {data.helperText}
          </Styled.HelperText>
        </Box>
      )}
    </>
  );
};
