import React from 'react';
import { Spinner } from 'components/core';
import { useHistory, useParams } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';

import { useStoreAuthentication } from 'stores';
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';

import { InvalidTokenSelfServe } from 'modules/sign-up/invalid-token-self-serve';
import { InvalidToken } from 'pages/onboarding';
import { V2Layout } from 'layouts';
import { SetPassword } from './pages/set-password';
import { useValidateToken } from './hooks/use-validate-token';

export const StreamlinedOnboardingToken: React.FC = () => {
  const { signOut } = useSignOut();
  const history = useHistory();

  const { token } = useParams<{ token: string }>();
  const { data: validateTokenResponse, isLoading: isLoadingValidateToken } =
    useValidateToken(token);

  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);

  const { data: business } = useBusiness();
  const { data: user, isLoading: isLoadingUser } = useUser(business?.Id);

  // These query params will only appear for self serve users
  const params = new URLSearchParams(window.location.search);
  const isSelfServe = params.get('isSelfServe') === 'true';
  const businessId = params.get('businessId') || '';

  if (isLoadingValidateToken || isLoadingUser) {
    return <Spinner />;
  }

  // If we get the email, that means the token is valid (ie the user hasn't set a pw yet)
  const emailFromToken = validateTokenResponse?.email;

  if (isSignedIn) {
    if (!emailFromToken) {
      // The user is navigating back to this page so send them back to the accountIntake
      history.push(RoutesPath.onboarding.accountIntake.path);
    } else {
      const onboardingAsDifferentUser = emailFromToken !== user.email;
      if (onboardingAsDifferentUser) {
        // Sign the user out of the current account so they can begin onboarding with the new account
        signOut();
      }
    }
  }

  if (emailFromToken) {
    return (
      <V2Layout hideFooter>
        <SetPassword
          email={emailFromToken}
          onSuccessCallback={() => {
            // This needs to be wrapped in setTimeout so that it goes to the end of the event queue (and allows for the loggedin state to be set)
            setTimeout(
              () =>
                history.push(
                  getVersionedPath({
                    path: RoutesPath.onboarding.accountIntake.path,
                    version: 3,
                  }),
                ),
              0,
            );
          }}
        />
      </V2Layout>
    );
  }

  // This component is useless without the businessId since we won't be able to make the resendEmail call
  if (isSelfServe && businessId?.length > 0) {
    return <InvalidTokenSelfServe businessId={businessId} />;
  }

  return <InvalidToken />;
};
