import React, { useEffect, useRef, useState } from 'react';
import useOnScreen from 'utils/hooks/useOnScreen';
import { useTracking, TrackEventName } from 'modules/tracking';

export const TrackView: React.FC = () => {
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>({
    eventName: TrackEventName.Viewed,
  });

  const ref: any = useRef<HTMLDivElement>();

  const [eventTracked, setEvetTracked] = useState(false);

  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref);

  useEffect(() => {
    if (onScreen && !eventTracked) {
      trackEvent({});
      setEvetTracked(true);
    }
  }, [onScreen]);

  return <div ref={ref} />;
};
