import React from 'react';
import { GridSize } from '@material-ui/core';
import { TableBody } from './components/table-body/table-body';
import { TableHeader } from './components/table-header/table-header';

export type ColumnType = {
  alignment?: string;

  name?: string;

  size?: GridSize;
};

interface DashboardTableProps {
  columns?: ColumnType[];

  data?: any;
}

export const DashboardTable: React.FC<DashboardTableProps> = ({
  columns,
  data,
}) => {
  const hasColumnHeaders = columns && columns.some((column) => column.name);

  return (
    <>
      {hasColumnHeaders && <TableHeader columns={columns} />}
      {data && <TableBody columns={columns} data={data} />}
    </>
  );
};
