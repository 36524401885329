import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { DepositWithdrawalVariant } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';

export const useSendSweepMFACode = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const sendSweepMFACode = async ({
    transferType,
  }: {
    transferType: DepositWithdrawalVariant;
  }) => {
    const { data } = await treasureApi().put('/sweeps/auth/email', {
      transferType: DepositWithdrawalVariant[transferType],
    });

    return data;
  };

  return useMutation(sendSweepMFACode);
};
