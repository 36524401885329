import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { throttle } from 'utils/throttle';
import { useTracking, TrackEventName } from 'modules/tracking';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg';
import { Styled } from './tooltip.style';

interface TooltipProps {
  className?: string;

  content: React.ReactFragment;

  eventLabel: string;

  interactive?: boolean;

  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export const Tooltip: React.FC<TooltipProps> = ({
  className,
  content,
  eventLabel,
  interactive,
  placement,
}) => {
  const { trackEvent } =
    useTracking<{
      eventName: TrackEventName;
      label: string;
    }>();

  const handleOnOpen = () => {
    // tooltips were firing way too often so created a throttle to only record them
    // every 1000 ms. TODO: this delay can be tweaked
    throttle(
      eventLabel,
      () => {
        trackEvent({ eventName: TrackEventName.Tooltip, label: eventLabel });
      },
      1000,
    );
  };

  return (
    <MuiTooltip
      arrow
      className={className}
      onOpen={handleOnOpen}
      placement={placement ?? 'right'}
      title={
        <Styled.Tooltip bgcolor="white" width={265}>
          {content}
        </Styled.Tooltip>
      }
    >
      <QuestionMarkIcon />
    </MuiTooltip>
  );
};
