import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@material-ui/core';
import { ActivityTotal } from 'types';
import { ActivityTotalsItem } from './activity-totals-item';

const TITLES = {
  credit: 'Credit',
  operating: 'Debit',
  sweepsIn: 'Transfers In',
  sweepsOut: 'Transfers Out',
  total: 'Total',
  transfers: 'Transfers',
};

export interface IActivityTotals {
  credit?: ActivityTotal | null;
  operating?: ActivityTotal | null;
  sweepsIn?: ActivityTotal | null;
  sweepsOut?: ActivityTotal | null;
  total: ActivityTotal;
  transfers: ActivityTotal;
}

export interface ActivityTotalsProps {
  data: IActivityTotals;
}

const ActivityTotals: React.FC<ActivityTotalsProps> = ({ data }) => {
  const { total, transfers, ...rest } = data;

  return (
    <>
      <Box mt={2}>
        <Grid container justifyContent="space-around">
          <Grid item>
            <ActivityTotalsItem
              label="Total"
              trendDirection={total.trend}
              value={total.amount}
            />
          </Grid>

          {rest &&
            Object.entries(rest).map(([key, value]) => (
              <>
                <Divider orientation="vertical" flexItem />

                <Grid item key={key}>
                  <ActivityTotalsItem
                    label={TITLES[key as keyof typeof TITLES]}
                    trendDirection={value.trend}
                    value={value.amount}
                  />
                </Grid>
              </>
            ))}
        </Grid>
      </Box>

      <Box mt={2}>
        <Divider />
      </Box>
    </>
  );
};

ActivityTotals.propTypes = {
  data: PropTypes.shape({
    credit: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      trend: PropTypes.string.isRequired,
    }),
    operating: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      trend: PropTypes.string.isRequired,
    }),
    sweepsIn: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      trend: PropTypes.string.isRequired,
    }),
    sweepsOut: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      trend: PropTypes.string.isRequired,
    }),
    total: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      trend: PropTypes.string.isRequired,
    }).isRequired,
    transfers: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      trend: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export { ActivityTotals };
