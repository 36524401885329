import React from 'react';
import PropTypes from 'prop-types';
import { Styled } from './click-anchor.style';

interface ClickAnchorProps {
  children: React.ReactNode;

  onClick?: (() => void) | React.MouseEventHandler<Element>;

  hoverUnderline?: boolean;
}

const ClickAnchor: React.FC<ClickAnchorProps> = ({ children, ...rest }) => {
  return <Styled.Link {...rest}>{children}</Styled.Link>;
};

ClickAnchor.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ClickAnchor };
