import React from 'react';
import PropTypes from 'prop-types';
import { Step, StepContent, StepLabel } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Styled } from './vertical-stepper.style';

export interface StepProps {
  label: string | React.ReactNode;
  description?: React.ReactNode;
  action?: string;
}

export interface VerticalStepperProps {
  /** active step */
  activeStep: number;

  /** array of step objects */
  steps: Array<StepProps>;

  /** bg color of step dots */
  color?: string;

  className?: string;
}

const VerticalStepper: React.FC<VerticalStepperProps> = ({
  activeStep,
  steps,
  className,
  color,
}) => {
  return (
    <Styled.VerticalStepper
      $color={color}
      activeStep={activeStep}
      orientation="vertical"
      connector={<Styled.VerticalConnector $color={color} />}
    >
      {steps.map((step, index) => {
        const isStepComplete = index <= activeStep;

        return (
          <Step key={index} completed={isStepComplete} expanded>
            <StepLabel icon=" ">
              <Typography
                variant={
                  isStepComplete
                    ? TypographyVariant.Header
                    : TypographyVariant.HeaderRegular
                }
                color={isStepComplete ? 'nero' : 'powderAsh'}
              >
                {step.label}
              </Typography>
            </StepLabel>

            <StepContent>
              {step.description ? (
                <Typography variant={TypographyVariant.Body} color="nero">
                  {step.description}
                </Typography>
              ) : (
                ''
              )}
              {step.action ? (
                <Typography
                  variant={TypographyVariant.Body}
                  color="nero"
                  className="action"
                >
                  {step.action}
                </Typography>
              ) : (
                ''
              )}
            </StepContent>
          </Step>
        );
      })}
    </Styled.VerticalStepper>
  );
};

VerticalStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

export { VerticalStepper };
