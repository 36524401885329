import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Heading } from 'components/core';
import { Styled } from './step-dots.style';

export interface StepDotsProps {
  /** active step */
  activeStep: number;

  /** number of steps */
  steps: number;

  /** hide or show the heading */
  showHeading?: boolean;
}

const StepDots: React.FC<StepDotsProps> = ({
  activeStep,
  steps,
  showHeading = true,
}) => {
  return (
    <>
      {showHeading ? (
        <Box mb={0.5}>
          <Heading color="gothic" variant={5}>
            Step {activeStep}
          </Heading>
        </Box>
      ) : (
        ''
      )}
      <Styled.StepDots
        variant="dots"
        steps={steps}
        position="static"
        activeStep={activeStep - 1}
        nextButton={<></>}
        backButton={<></>}
      />
    </>
  );
};

StepDots.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  showHeading: PropTypes.bool,
};

export { StepDots };
