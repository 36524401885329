import React from 'react';
import { InputAdornment, InputProps } from '@material-ui/core';
import { Styled as StyledTextField } from 'components/core/forms/text-field/text-field.style';
import { TextField } from 'components/core/forms';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { MAX_ALLOWED_MONEY } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/utils/form-fields';
import {
  factoryFormFieldComponent,
  FormFieldSpecs,
} from '../utils/factory-form-field-component';

export type TextFormFieldSpecs = FormFieldSpecs<{
  type?: 'text' | 'password' | 'tel';
  currency?: boolean;
  disabled?: boolean;
  mask?: string;
  hasVisibilityToggle?: boolean;
  labelAddon?: React.ReactNode;
  leftLabel?: boolean;
  labelVariant?: number;
  customMaxAmount?: number;
}>;

export const TextFieldController =
  factoryFormFieldComponent<TextFormFieldSpecs>(
    ({
      value,
      label,
      placeholder,
      defaultValue,
      currency,
      disabled,
      mask,
      hasVisibilityToggle,
      labelAddon,
      labelVariant,
      leftLabel,
      type,
      validations,
      onBlur,
      onChange,
      error,
    }) => {
      const [isVisibilityHidden, setIsVisibilityHidden] = React.useState(
        defaultValue && hasVisibilityToggle,
      );

      const inputType = isVisibilityHidden ? 'password' : 'text';
      const muiInputProps: InputProps = {};

      const handleToggleVisibility = () =>
        setIsVisibilityHidden(!isVisibilityHidden);

      if (hasVisibilityToggle) {
        muiInputProps.endAdornment = (
          <InputAdornment position="end">
            <StyledTextField.EyeIconButton
              aria-label="toggle field visibility"
              disableRipple
              onClick={handleToggleVisibility}
              tabIndex="-1"
            >
              <StyledTextField.EyeIconVisibility />
            </StyledTextField.EyeIconButton>
          </InputAdornment>
        );
      }

      const textFieldProps = {
        value,
        label,
        labelAddon,
        labelVariant,
        currency,
        disabled,
        leftLabel,
        placeholder,
        onBlur,
        optional: !validations?.required,
        helperText: error?.message,
        error: !!error,
        type: hasVisibilityToggle ? inputType : type,
        InputProps: muiInputProps,
      };

      if (mask) {
        return (
          <NumberFormat
            {...textFieldProps}
            customInput={TextField}
            mask="#"
            format={mask}
            type={hasVisibilityToggle ? inputType : type}
            onValueChange={(data) => {
              onChange(data.formattedValue, data.value);
            }}
          />
        );
      }

      if (currency) {
        const withValueLimit = ({ floatValue }: NumberFormatValues) =>
          floatValue ? floatValue <= MAX_ALLOWED_MONEY : true;

        return (
          <NumberFormat
            {...textFieldProps}
            customInput={TextField}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale
            isAllowed={withValueLimit}
            prefix="$ "
            thousandSeparator
            onValueChange={(data) => {
              onChange(data.floatValue);
            }}
          />
        );
      }

      return <TextField {...textFieldProps} onChange={onChange} />;
    },
    { componentName: 'TextFieldController' },
  );
