import { getColor } from 'styles/css-constants';
import styled from 'styled-components';
import { CheckboxProps } from '@material-ui/core';
import { CustomCheckboxProps } from './checkbox';

const CHECKBOX_WIDTH = '20px';
const CHECKBOX_HEIGHT = '20px';

type Props = CustomCheckboxProps & CheckboxProps;

const CheckIcon = styled.span<Props>`
  border-radius: var(--border-radius-02);
  width: ${CHECKBOX_WIDTH};
  height: ${CHECKBOX_HEIGHT};
  border: 1px solid
    ${(props) => (props.error ? getColor('amaranth') : getColor('hitGrey'))};

  background-color: var(--color-white);
  input:hover ~ & {
    background-color: var(--color-white);

    border: 1px solid
      ${(props) =>
        props.error
          ? getColor('amaranth')
          : props.disabled
          ? getColor('hitGrey')
          : getColor('primary')};
  }
  input:disabled ~ & {
    box-shadow: none;
    background-color: var(--color-gainsboro0);

    ${(props) => (props.disabled ? getColor('hitGrey') : getColor('hitGrey'))};
  }
`;

export const Styled = {
  CHECKBOX_WIDTH,
  CHECKBOX_HEIGHT,
  CheckIcon,
};
