import React from 'react';
import PropTypes from 'prop-types';
import { useTracking, TrackPageName } from 'modules/tracking';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { Container } from 'modules/2023-q3/allocation/components/container/container';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { ManualACHForm } from './manual-ach-form';
import { Styled } from './manual-ach.style';

interface ManualACHProps {
  onExit: () => void;
}

const ManualACH: React.FC<ManualACHProps> = ({ onExit }) => {
  useTracking<{
    page: TrackPageName;
  }>({
    page: TrackPageName.OnboardingManualACH,
  });

  const content = (
    <>
      <Styled.IconContainer display="flex" p={2}>
        <Styled.IconButton onClick={onExit}>
          <Styled.CaretIcon />
        </Styled.IconButton>
        <Styled.IconButton onClick={onExit}>
          <CancelIcon />
        </Styled.IconButton>
      </Styled.IconContainer>
      <Box p={3} pt={0}>
        <Box mb={2}>
          <Typography color="nero" variant={TypographyVariant.Header}>
            Enter banking details to connect an account to Treasure
          </Typography>
        </Box>

        <ManualACHForm />
      </Box>
    </>
  );

  return (
    <FeatureFlag
      disabled={<Styled.Container width={450}>{content}</Styled.Container>}
      enabled={<Container>{content}</Container>}
      flag={FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED}
    />
  );
};

ManualACH.propTypes = {
  onExit: PropTypes.func.isRequired,
};

export { ManualACH };
