import React from 'react';
import { Box } from '@material-ui/core';
import { SectionTitle } from 'modules/dashboard/components';
import { PortfolioView } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/portfolio-view';
import { useTracking, TrackPageSection, TrackView } from 'modules/tracking';

export const Portfolio: React.FC = () => {
  const { Track } = useTracking<{
    section: TrackPageSection;
  }>({
    section: TrackPageSection.TreasureReserveDashboardPortfolio,
  });

  return (
    <Track>
      <TrackView />

      <Box mb={2}>
        <SectionTitle title="Treasure Reserve Portfolio" />
      </Box>

      <PortfolioView />
    </Track>
  );
};
