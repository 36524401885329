import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  Footer,
  FootnoteProvider,
  MultipleAccountProvider,
} from 'components/layouts';
import { createCtx } from 'utils/create-ctx';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { RoutesPath } from 'routes/constants/routes-path';
import { Styled } from './blank-layout.style';

interface LoadingContext {
  loading: boolean;

  setLoading: (loading: boolean) => void;
}

export const [useLoadingContext, LoadingCtxProvider] =
  createCtx<LoadingContext>();

export const BlankLayout: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);

  // TODO: temporary logic while we transition page by page
  const { pathname } = useLocation();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const migratedPages = [RoutesPath.onboarding.accountIntake.path];

  const displayWhiteBackground =
    isFeatureFlagEnabled(FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED) &&
    migratedPages.includes(pathname);

  return (
    <FootnoteProvider>
      <MultipleAccountProvider>
        <Styled.Container whiteBackground={displayWhiteBackground}>
          <Styled.Main>
            <LoadingCtxProvider value={{ loading, setLoading }}>
              {children}
            </LoadingCtxProvider>

            {!loading && (
              <Box p={{ xs: 5, lg: 10 }} pt={{ xs: 5, lg: 7 }}>
                <Footer textColor="hitGrey" topDivider />
              </Box>
            )}
          </Styled.Main>
        </Styled.Container>
      </MultipleAccountProvider>
    </FootnoteProvider>
  );
};

BlankLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
