import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useTopIndicators = (businessId: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  return useQuery(
    [ReactQueryKeys.TopIndicators, businessId],
    async () => {
      const response = await treasureApi().get('/accounts/indicators', {
        params: { id: businessId },
      });

      return response.data;
    },
    {
      enabled: !!businessId,
    },
  );
};
