import React, { useState } from 'react';
import {
  i18nNamespace,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Button } from 'modules/2023-q3/components/button/button';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import {
  IInputAmount,
  InputAmount,
} from './components/input-amount/input-amount';
import {
  ISelectACHBank,
  SelectSwitchACHBank,
} from './components/select-switch-ach-bank/select-switch-ach-bank';
import { BankAccount } from 'types';
import { OverWithdrawalLimitMessage } from 'modules/2023-q3/deposit-withdrawal/withdrawal/components/over-withdrawal-limit-message/over-withdrawal-limit-message';
import { useForm } from 'react-hook-form';
import { WireInstructionsLink } from 'modules/2023-q3/deposit-withdrawal/pages/wire-instructions/wire-instructions-link';
import { Styled } from './form.style';

interface FormProps extends IInputAmount, ISelectACHBank {
  isOverMaxAmount?: boolean;

  nextStep: () => void;

  overMaxAmountMessage?: string;

  previousStep?: () => void;
}

export const Form: React.FC<FormProps> = ({
  amount,
  bank,
  isOverMaxAmount,
  nextStep,
  overMaxAmountMessage,
  previousStep,
  setAmount,
  setBank,
  variant,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const { handleSubmit } = useForm();

  const [isSelectingBank, setIsSelectingBank] = useState(false);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;

  const canContinue =
    amount && amount > 0.0 && bank !== undefined && !isOverMaxAmount;

  const handleContinue = () => {
    if (canContinue) {
      nextStep();
    }
  };

  const onSubmit = async () => {
    if (canContinue) {
      handleContinue();
    }
  };

  const ContinueButton = ({ mobile }: { mobile?: boolean }) => {
    return (
      <Box mt={mobile ? 10 : undefined}>
        <Button
          borderRadius={mobile ? 0 : undefined}
          bottomFixed={mobile}
          disabled={!canContinue}
          fullWidth
          onClick={handleContinue}
        >
          <ButtonContent iconEnd={SupportedIcons.ArrowRight} mobile={mobile}>
            {t('Continue', { ns: 'app' })}
          </ButtonContent>
        </Button>
      </Box>
    );
  };

  return (
    <>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <Typography color="black19" variant={TypographyVariant.Header3}>
              {isDeposit
                ? t('Deposit to Treasure')
                : t('Withdraw from Treasure')}
            </Typography>
          </Box>

          <Styled.FormBlocks disableLastRoundedCorners={isSelectingBank}>
            <Styled.FormBlock px={{ xs: 0, sm: 2.5 }} py={2.5}>
              <InputAmount
                amount={amount}
                isOverMaxAmount={isOverMaxAmount}
                setAmount={setAmount}
                variant={variant}
              />
            </Styled.FormBlock>

            <Styled.FormBlock px={{ xs: 0, sm: 2.5 }} py={2.5}>
              <SelectSwitchACHBank
                bank={bank}
                isSelectingBank={isSelectingBank}
                setBank={setBank}
                setIsSelectingBank={setIsSelectingBank}
                variant={variant}
              />
            </Styled.FormBlock>
          </Styled.FormBlocks>

          {!isDeposit && isOverMaxAmount && overMaxAmountMessage && (
            <MobileView
              mobile={
                <OverWithdrawalLimitMessage
                  overMaxAmountMessage={overMaxAmountMessage}
                />
              }
            >
              {!isSelectingBank && (
                <OverWithdrawalLimitMessage
                  overMaxAmountMessage={overMaxAmountMessage}
                />
              )}
            </MobileView>
          )}

          <MobileView mobile={<ContinueButton mobile />}>
            <Box mt={isSelectingBank ? 0 : 25}>
              <ContinueButton />
            </Box>
          </MobileView>
        </form>
      </FormContainer>

      {isDeposit && previousStep && (
        <MobileView
          mobile={
            <Box mt={4} mb={15} textAlign="center">
              <WireInstructionsLink onClick={previousStep} />
            </Box>
          }
        >
          <Box mt={4} textAlign="center">
            <WireInstructionsLink onClick={previousStep} />
          </Box>
        </MobileView>
      )}
    </>
  );
};
