import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useSendLoginMFA = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const sendLoginMFACode = async () => {
    const { data } = await treasureApi().put('/mfa/generate');

    return data;
  };

  return useMutation(sendLoginMFACode);
};
