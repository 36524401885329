import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TypographyVariant } from 'components/core';
import { Box } from '@material-ui/core';
import { Styled } from './vertical-step-bar.style';

export interface VerticalStepBarProps {
  className?: string;

  /** active step */
  activeStep?: string;

  /** array of steps */
  steps: Array<string>;
}

const VerticalStepBar: React.FC<VerticalStepBarProps> = ({
  className,
  activeStep,
  steps,
}) => {
  return (
    <ul>
      {steps.map((step) => {
        const isActiveStep = step === activeStep;
        return (
          <Styled.Step
            key={step}
            className={className}
            $isActive={isActiveStep}
          >
            <Box px={2} py={0.5}>
              <Typography
                variant={
                  isActiveStep
                    ? TypographyVariant.SubHeader1
                    : TypographyVariant.SubHeader2
                }
                color={isActiveStep ? 'stromboli' : 'jumbo'}
              >
                {step}
              </Typography>
            </Box>
          </Styled.Step>
        );
      })}
    </ul>
  );
};

VerticalStepBar.propTypes = {
  className: PropTypes.string,
  activeStep: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export { VerticalStepBar };
