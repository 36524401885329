import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useSendOnboardingEmail = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const sendOnboardingEmail = ({ businessId }: { businessId: string }) => {
    return treasureApi({ businessId }).post(`onboarding/self-serve/email`, {
      businessId,
    });
  };

  return useMutation(sendOnboardingEmail);
};
