import React from 'react';
import { Box } from '@material-ui/core';
import { TextFieldController } from 'modules/form-helpers';
import {
  Button,
  Heading,
  Typography,
  TypographyVariant,
} from 'components/core';
import {
  maxCompanyNameLength,
  maxEmailLength,
  isValidEmail,
} from 'utils/forms/validators';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import PropTypes from 'prop-types';
import { Bullets } from './bullets';
import { Styled } from './sign-up.style';

interface SignUpFormProps {
  handleSubmit: any;
  control: any;
  isLoading: boolean;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({
  handleSubmit,
  control,
  isLoading,
}) => {
  const formFields = {
    businessName: {
      name: 'businessName',
      label: 'Business Name',
      validations: {
        required: true,
        maxLength: maxCompanyNameLength,
      },
    },
    email: {
      name: 'email',
      label: 'Business Email',
      validations: {
        required: true,
        maxLength: maxEmailLength,
        validate: (value: string) =>
          isValidEmail(value) || 'Please use a valid email address',
      },
    },
  };
  const { trackEvent } = useTracking();

  return (
    <Styled.ChildContainer>
      <Styled.FormContainer>
        <Heading variant={1} className="header">
          Create your Treasure Account
        </Heading>
        <form onSubmit={handleSubmit}>
          <TextFieldController {...formFields.businessName} control={control} />
          <TextFieldController {...formFields.email} control={control} />
          <Button type="submit" disabled={isLoading}>
            <Typography color="white" variant={TypographyVariant.Body}>
              Create account
            </Typography>
          </Button>
        </form>
        <p>
          Have an account?{' '}
          <Link
            to={RoutesPath.noAuth.signIn.path}
            onClick={() =>
              trackEvent({ eventName: TrackEventName.SignUpSignInClicked })
            }
          >
            Sign in
          </Link>
        </p>
        <Box mt={2}>
          Questions about Treasure?{' '}
          <a
            href="https://go.treasurefi.com/request-a-demo?utm_source=sign-up-page&utm_medium=demo-request&utm_campaign=sign-up-page"
            rel="noreferrer"
            target="_blank"
          >
            Request a Demo
          </a>
        </Box>
      </Styled.FormContainer>
      <Bullets />
    </Styled.ChildContainer>
  );
};

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
