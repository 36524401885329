import React, { useState } from 'react';
import { ClickAnchor, Modal } from 'components/core';
import { MultiAccountSelection } from 'modals/multi-account';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';

// hooks
import { useBusinesses } from 'hooks/business/use-businesses';

// TODO: move to context?
export const HasMultipleAccounts: () => boolean = () => {
  const { data: businesses } = useBusinesses();

  return businesses ? businesses.length >= 2 : false;
};

export const SwitchMultipleAccounts: React.FC = ({ children }) => {
  const { trackEvent } = useTracking();

  const [openMultiAccountSelection, setOpenMultiAccountSelection] =
    useState(false);

  const handleSwitchBusiness = () => {
    setOpenMultiAccountSelection(true);

    // TODO: refactor
    // section should be on a higher level component but don't want to edit
    // onboarding files for this change

    trackEvent({
      eventName: TrackEventName.SwitchBusinessClicked,
      section: TrackPageSection.AccountIntake,
    });
  };

  if (!HasMultipleAccounts) {
    return null;
  }

  return (
    <>
      <ClickAnchor onClick={handleSwitchBusiness}>{children}</ClickAnchor>

      <Modal
        open={openMultiAccountSelection}
        onClose={() => setOpenMultiAccountSelection(false)}
      >
        <MultiAccountSelection />
      </Modal>
    </>
  );
};
