import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconCheckmarkCircleGreen } from 'assets/icons/checkmarks/checkmark-circle-green.svg';
import { SelectSwitchOption } from '../../select-switch';
import { Styled } from './select-switch-options.style';

interface SelectSwitchOptionsProps {
  onChange: (selectedOption: SelectSwitchOption) => void;
  options: SelectSwitchOption[];
  selectedValue: string | undefined;
}

export const SelectSwitchOptions: React.FC<SelectSwitchOptionsProps> = ({
  options,
  onChange,
  selectedValue,
}) => {
  return (
    <>
      {options.map((option, i) => (
        <React.Fragment key={option.value}>
          <Styled.Cursor onClick={() => onChange(option)}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                <Styled.LabelContainer py={2}>
                  {option.label}
                </Styled.LabelContainer>
              </Grid>

              <Grid item>
                <SVGBox width={25} height={25}>
                  {option.value === selectedValue ? (
                    <IconCheckmarkCircleGreen />
                  ) : (
                    <Styled.ButtonAdd />
                  )}
                </SVGBox>
              </Grid>
            </Grid>
          </Styled.Cursor>

          {i !== options.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </>
  );
};
