import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useDisconnectQuickbooks = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const useDisconnectQb = async () => {
    const { data } = await treasureApi().post(
      '/quickbooks/disconnectQuickbooks',
    );

    return data;
  };

  return useMutation(useDisconnectQb);
};
