import React from 'react';
import { Box } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Heading,
  ItemDefinition,
  Spinner,
} from 'components/core';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useTreasureReservePositions } from 'modules/treasure-reserve-positions/hooks/use-treasure-reserve-positions';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { PositionsModal } from './positions-modal';

interface ICurrencyItemDefinition {
  label: string;
  number: number;
}

const CurrencyItemDefinition: React.FC<ICurrencyItemDefinition> = ({
  label,
  number,
}) => {
  return (
    <ItemDefinition
      label={label}
      value={
        <Heading variant={3}>
          <Currency number={number} variant={CurrencyVariant.Full} />
        </Heading>
      }
    />
  );
};

export const BalanceBreakdown = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { data: business } = useBusiness();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );
  const { data: treasureReservePositions } = useTreasureReservePositions(
    business?.Id,
  );

  const hasPortfolio = !!portfolios?.portfolios[0]?.allocations;
  const productAmounts = portfolios?.amountsInDollars.productAmounts;

  const treasureReserveBalanceAsNumber =
    portfolios?.amountsInDollars.totalAmount;

  const showOther = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_ALLOCATION_OTHER_ENABLED,
  );

  const portfolioBreakdown = () => {
    return treasureReserveBalanceAsNumber > 0 ? (
      <>
        <CurrencyItemDefinition label="Cash" number={productAmounts?.cash} />

        <Box mt={2}>
          <CurrencyItemDefinition
            label="Managed Money Market"
            number={productAmounts?.moneyMarket}
          />
        </Box>

        <Box mt={2}>
          <CurrencyItemDefinition
            label="Managed Treasuries"
            number={productAmounts?.managedTreasuries}
          />
        </Box>

        <Box mt={2}>
          <CurrencyItemDefinition
            label="Managed Income"
            number={productAmounts?.managedIncome}
          />
        </Box>

        {showOther && productAmounts?.other > 0 && (
          <Box mt={2}>
            <CurrencyItemDefinition
              label="Other"
              number={productAmounts?.other}
            />
          </Box>
        )}

        {treasureReservePositions?.length > 0 && (
          <Box mt={2}>
            <PositionsModal hasPortfolio={hasPortfolio} />
          </Box>
        )}
      </>
    ) : null;
  };

  if (isLoadingPortfolios || productAmounts === undefined) {
    return <Spinner />;
  }

  if (hasPortfolio) {
    return portfolioBreakdown();
  }

  return (
    <>
      {treasureReserveBalanceAsNumber > 0 && (
        <>
          <CurrencyItemDefinition label="Cash" number={productAmounts?.cash} />

          <Box mt={2}>
            <CurrencyItemDefinition
              label="Mutual Funds"
              number={
                productAmounts?.moneyMarket + productAmounts?.managedIncome
              }
            />
          </Box>
        </>
      )}

      {productAmounts?.managedTreasuries > 0 && (
        <Box mt={2}>
          <CurrencyItemDefinition
            label="Treasuries"
            number={productAmounts?.managedTreasuries}
          />
        </Box>
      )}

      {treasureReservePositions?.length > 0 && (
        <Box mt={2}>
          <PositionsModal />
        </Box>
      )}
    </>
  );
};
