import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button, Typography, TypographyVariant } from 'components/core';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { PolicyKey } from 'enums';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PageTitle } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/page-title';
import { usePolicies } from 'modules/onboarding/v3-streamlined-onboarding/pages/legalese/hooks/get-policies';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconPrint } from 'assets/icons/documents/print/print.svg';
import { FooterButton } from 'modules/2023-q3/allocation/components/footer/footer';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { Styled } from './legalese.style';

interface LegaleseProps {
  policyKeys: string[];
}

export const Legalese: React.FC<LegaleseProps> = ({ policyKeys }) => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.ApexAgreements,
  });

  const onboardingContext = useOnboardingContext();
  const { handleSubmit } = useForm();

  // TODO: does the order of the forms matter?
  const policyKeysEnum = Object.values(PolicyKey).filter((policyKey) =>
    policyKeys.includes(policyKey),
  );
  const { data: policies = [], isLoading: isLoadingPolicies } =
    usePolicies(policyKeysEnum);

  const handlePrint: React.MouseEventHandler = (e) => {
    e.preventDefault();
    window.print();

    trackEvent({
      eventName: TrackEventName.PrintAgreementClicked,
    });
  };

  const onSubmit = (data: any) => {
    onboardingContext.postOnboardingData({
      businessId: onboardingContext.businessId,
      data: {
        policiesKeysAgreed: policyKeysEnum,
      },
    });

    trackEvent({
      eventName: TrackEventName.ApexAgreementsCompleted,
    });
  };

  useEffect(() => {
    onboardingContext.showLoading(isLoadingPolicies);
  }, [isLoadingPolicies]);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <PageTitle title="Legal disclosures" />
        </Grid>

        <Grid item>
          <SVGBox cursor="pointer" onClick={handlePrint} width={32} height={32}>
            <IconPrint />
          </SVGBox>
        </Grid>
      </Grid>

      <Box position="relative" top={-15}>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Please review all of the terms & conditions.
        </Typography>
      </Box>

      <Styled.Container px={3} mb={3}>
        {policies.map((policy: { key: string; content: string }) => {
          return (
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              <ReactMarkdown
                key={policy.key}
                linkTarget="_blank"
                remarkPlugins={[remarkGfm]}
              >
                {policy.content}
              </ReactMarkdown>
            </Typography>
          );
        })}
      </Styled.Container>

      <Box displayPrint="none">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={5}>
            <Grid container spacing={2}>
              <Grid item xs>
                <FooterButton onClick={() => onboardingContext.previousStep()}>
                  <ButtonContent iconStart={SupportedIcons.ArrowLeft}>
                    Back
                  </ButtonContent>
                </FooterButton>
              </Grid>

              <Grid item xs>
                <FooterButton type="submit">
                  <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
                    I Agree, Submit Application
                  </ButtonContent>
                </FooterButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
};
