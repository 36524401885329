import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Heading } from 'components/core';

const TransferWidgetHeading = styled(Heading)`
  border-bottom: 1px solid var(--color-lightGrey);
  display: flex;
  font-weight: var(--font-weight-bold);
  padding: 20px;
`;

const IconContainer = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;

  button {
    padding: 10px;
  }
`;

const TransferWidgetContainer = styled(Box)`
  border: 1px solid var(--color-lightGrey);
  border-radius: 3px;

  .MuiGrid-container {
    flex-wrap: nowrap;
  }
`;

export const Styled = {
  TransferWidgetHeading,
  TransferWidgetContainer,
  IconContainer,
};
