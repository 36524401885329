import {
  Allocation,
  AllocationProducts,
  treasureReserveProductKey,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';

export const calculateComparisonChartData = ({
  amount,
  currentRate,
  treasureNetRate,
}: {
  amount: number;
  currentRate: number;
  treasureNetRate: number;
}) => {
  const data = [];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const d = new Date();
  const currentMonth = d.getMonth();

  let currentRateSum = amount;
  let treasureRateSum = amount;

  let i = 0;
  while (i < 12) {
    const currentRateMonth =
      currentRateSum * ((1 + currentRate / 100) ** (1 / 12) - 1);
    const treasureRateMonth =
      treasureRateSum * ((1 + treasureNetRate / 100) ** (1 / 12) - 1);

    data.push({
      name: monthNames[
        currentMonth + i > 11 ? currentMonth + i - 12 : currentMonth + i
      ],
      Current: currentRateSum + currentRateMonth,
      Treasure: treasureRateSum + treasureRateMonth,
    });

    currentRateSum += currentRateMonth;
    treasureRateSum += treasureRateMonth;
    i += 1;
  }

  return data;
};

export const calculateNetFeeRate = (
  allocation: Allocation,
  overrideFee?: number,
  overrideReturn?: number,
) => {
  let netFeeRate = 0;

  Object.keys(allocation).map((productKey) => {
    const product = allocation[productKey as keyof Allocation];
    const productReturn = overrideReturn ?? product.return;
    let productFee = overrideFee ?? product.fee;

    if (productKey === treasureReserveProductKey) {
      return;
    }

    if (productKey === AllocationProducts.cash) {
      productFee = 0;
    }

    netFeeRate += (product.allocation / 100) * (productReturn - productFee);
  });

  return netFeeRate;
};
