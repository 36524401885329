import styled from 'styled-components';
import { FormControl as FormControlAlias, Grid } from '@material-ui/core';

export const FormControl = styled(FormControlAlias)`
  .MuiInputLabel-outlined {
    transform: none;
  }

  .MuiInputLabel-formControl {
    position: initial;
  }
`;

export const GridRelative = styled(Grid)`
  position: relative;
`;

export const Optional = styled.span`
  text-transform: lowercase;
`;

export const Styled = {
  FormControl,
  GridRelative,
  Optional,
};
