import React, { useState } from 'react';
import { PersistentError } from 'modules/page-errors/persistent-error';
import { PlaidAuthError } from 'modules/page-errors/plaid-auth-error';
import {
  usePlaidModal,
  OpenPlaidModalErrorResponse,
} from 'modules/plaid/hooks/use-plaid-modal';
import { PlaidAccountStatus } from 'enums';
import { BankAccount } from 'types';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { useSetBankingItemStatus } from './hooks/use-set-banking-item-status';

import { Styled } from './dashboard-errors.style';

export const DashboardErrors: React.FC = () => {
  const { data: business } = useBusiness();
  const { data: accounts } = useBankAccounts(business?.Id);

  const openPlaidModal = usePlaidModal();

  const setBankingItemStatus = useSetBankingItemStatus();

  const { trackEvent } = useTracking<{
    contactSupportReason?: TrackEventName;
    error?: any;
    eventName: TrackEventName;
    plaidMetaData?: any;
    section: TrackPageSection;
  }>({
    section: TrackPageSection.PersistentError,
  });

  const [showError, setShowError] = useState(true);

  // check if any plaid account has an AUTH_ERROR
  const hasPlaidAuthError = accounts?.find(
    (account: BankAccount) =>
      account.PlaidStatus === PlaidAccountStatus.AUTH_ERROR,
  );

  // check if any plaid account's data is stale and hasn't just been re-authed (PENDING_READY).
  const isPlaidDataStale = accounts?.find(
    (account: BankAccount) =>
      account.isPlaidDataStale &&
      account.PlaidStatus !== PlaidAccountStatus.PENDING_READY,
  );

  const handleContactCustomerSupport = () => {
    trackEvent({
      eventName: TrackEventName.ContactSupportClicked,
      contactSupportReason: TrackEventName.PlaidStaleDataErrorShown,
    });
  };

  const handleOpenPlaidModal = async (bankItemId: string) => {
    setShowError(false);

    try {
      // open plaid modal with bank item id
      const plaidData = await openPlaidModal({
        bankItemId,
        forceNewInstance: true,
      });

      trackEvent({
        eventName: TrackEventName.PlaidLinkModalOpened,
      });

      // on success, update plaid status, get accounts
      // TODO: we probably want to get updated indicators, charts, etc
      if (plaidData) {
        await setBankingItemStatus.mutate({ bankItemId });

        trackEvent({
          eventName: TrackEventName.PlaidLinkUpdateSucceeded,
        });
      }
    } catch (plaidErrorData) {
      const { error, plaidMetaData } =
        plaidErrorData as OpenPlaidModalErrorResponse;

      setShowError(true);

      trackEvent({
        eventName: TrackEventName.PlaidLinkUpdateFailure,
        error,
        plaidMetaData,
      });
    }
  };

  if (hasPlaidAuthError && showError) {
    return (
      <PlaidAuthError
        handleOpenPlaidModal={handleOpenPlaidModal}
        bankItemId={hasPlaidAuthError.BankItemId}
        institutionName={hasPlaidAuthError.institution.name}
      />
    );
  }

  if (isPlaidDataStale && showError) {
    trackEvent({
      eventName: TrackEventName.PlaidStaleDataErrorShown,
    });

    return (
      <PersistentError
        errorMessage={
          <>
            We haven&apos;t received recent transaction updates for one of your
            banks. Please{' '}
            <Styled.Link
              href="mailto:support@treasure.tech?subject=Contact Support"
              onClick={handleContactCustomerSupport}
            >
              contact support
            </Styled.Link>{' '}
            if this is inaccurate.
          </>
        }
      />
    );
  }

  return null;
};
