import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Text } from 'components/core';
import { useTracking, TrackPageName } from 'modules/tracking';

import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { UserPermissionsRequired } from 'modules/2023-q4/rbac/components/user-permissions-required/user-permissions-required';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useRestrictedCusips } from './hooks/use-restricted-cusips';

import { AddRestrictedCusipForm } from './add-restricted-cusip-form';
import { RestrictedCusipList } from './restricted-cusip-list';

import { Styled } from './trade-restrictions.style';

export const TradeRestrictions: React.FC = () => {
  useTracking<{
    page: TrackPageName;
  }>({
    page: TrackPageName.SettingsTradeRestrictions,
  });

  const { data: business } = useBusiness();
  const { data: restrictedCUSIPs = [] } = useRestrictedCusips(business?.Id);

  const addCusip = (
    <Grid item xs>
      <Box mb={3}>
        <Text variant={3}>Restrict Securities</Text>
      </Box>

      <AddRestrictedCusipForm restrictedCusips={restrictedCUSIPs} />
    </Grid>
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={5}>
        <Box mb={3}>
          <Text variant={3}>
            You may need to restrict assets if you work for a public company and
            are privy to confidential information.
          </Text>
        </Box>

        <ul>
          <Styled.Li>
            <Text variant={2}>
              Our allocation is carefully orchestrated, and trade restrictions
              may impact performance.
            </Text>
          </Styled.Li>
          <Styled.Li>
            <Text variant={2}>
              We don&apos;t buy or sell restricted assets. If a restricted asset
              is currently held, we will continue to hold it.
            </Text>
          </Styled.Li>
          <Styled.Li>
            <Text variant={2}>
              We are unable to stop the purchase of stocks within ETFs and
              Mutual Funds.
            </Text>
          </Styled.Li>
        </ul>
      </Grid>

      <Divider orientation="vertical" flexItem />

      <FeatureFlag
        disabled={<>{addCusip}</>}
        enabled={
          <UserPermissionsRequired roles={[UserRole.ADMIN]}>
            {addCusip}
          </UserPermissionsRequired>
        }
        flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
      />

      <Divider orientation="vertical" flexItem />

      <Grid item xs>
        <Box mb={3}>
          <Text variant={3}>Currently Restricted</Text>
        </Box>

        <RestrictedCusipList restrictedCusips={restrictedCUSIPs} />
      </Grid>
    </Grid>
  );
};
