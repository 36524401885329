import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys, TopIndicators } from 'enums';

export const useSubIndicator = (
  subIndicator: TopIndicators,
): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  return useQuery([ReactQueryKeys.SubIndicator, subIndicator], async () => {
    const response = await treasureApi().get(
      `/indicators/additional/${subIndicator}`,
    );

    return response.data.data;
  });
};
