import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const PieChartContainer = styled(Grid)`
  height: 100%;
  position: relative;
  top: -30px;
`;

export const Styled = {
  PieChartContainer,
};
