export enum TrackPageName {
  // No Auth Pages
  ForgotPassword = 'Forgot Password',
  GetStarted = 'Get Started',
  ResetPassword = 'Reset Password',
  SignIn = 'Sign In',
  SignUp = 'Sign Up',

  // Demo
  DemoPortfolioAllocation = 'Demo Portfolio Allocation',
  DemoPortfolioAllocationComparison = 'Demo Portfolio Allocation Comparison',

  // Pages
  Banking = 'Banking',
  ConnectBankAccountManual = 'Bank Account Information',
  ConnectBankAccounts = 'Connect Bank Accounts',
  Dashboard = 'Dashboard',
  Documents = 'Documents',
  Insights = 'Insights',
  Team = 'Team',
  Settings = 'Settings',
  SweepFunds = 'Transfer Funds',

  // Tasks
  Deposit = 'Make a Deposit',
  PortfolioAllocation = 'Portfolio Allocation',
  SecurityCheck = 'Security Check',
  Withdrawal = 'Make a Withdrawal',

  // Onboarding Intake
  Welcome = 'Welcome',
  IMA = 'IMA',
  Password = 'Password',
  UserPersonalInfo = 'User personal info',
  RareScenarios = 'Rare Scenarios',
  PublicCompanyControl = 'Public Company Control',
  FINRAAssociation = 'FINRA Association',
  InterestedParties = 'Interested Parties',
  PoliticallyExposed = 'Politically Exposed',
  PoliticallyExposedAffiliations = 'Politically Exposed Affiliations',
  ConnectAccounts = 'Connect Accounts',
  ConnectSummary = 'Connect Summary',
  BusinessDetails = 'Business Details',
  BusinessClassification = 'Business Classification',
  RiskProfile = 'Risk Profile',
  UBO = 'UBO',
  EO = 'EO ',
  OwnersAndOfficers = 'Owners and Officers',
  AuthorizedSigner = 'Authorized Signer',
  InformationSummary = 'Information Summary',
  IncompletePagesSummary = 'Incomplete Pages Summary',
  ApexAgreements = 'Apex Agreements',
  Transfer = 'Transfer',

  // v3 Streamlined Onboarding
  BusinessStructure = 'Business Structure',
  OnboardingBankDetails = 'Onboarding Bank Details',
  OnboardingManualACH = 'Onboarding Manual ACH',
  OnboaringSweep = 'Onboarding Transfer',

  // Settings
  SettingsBusinesses = 'Settings - Businesses',
  SettingsTeam = 'Settings - Team',
  SettingsTradeRestrictions = 'Settings - Trade Restrictions',
  SettingsQuickbooks = 'Settings - Quickbooks',
}
