import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';
import { Styled } from './settings-nav-link.style';

interface SettingsNavLinkProps {
  children: React.ReactNode;

  eventName: TrackEventName;

  to: string;
}

export const SettingsNavLink: React.FC<SettingsNavLinkProps> = ({
  children,
  eventName,
  to,
}) => {
  const { trackEvent } = useTracking<{
    eventName: TrackEventName;
    section: TrackPageSection;
  }>({
    eventName,
    section: TrackPageSection.SettingsMenu,
  });

  const handleClick = () => {
    trackEvent({});
  };

  return (
    <Box mb={1}>
      <Styled.NavLink
        activeClassName="active"
        exact
        onClick={handleClick}
        to={to}
      >
        <Typography variant={TypographyVariant.Body}>{children}</Typography>
      </Styled.NavLink>
    </Box>
  );
};
