import React from 'react';
import { Text } from 'components/core';
import { RemoveRestrictedCusip } from '../remove-restricted-cusip';
import { Styled } from './restricted-cusip-list.style';

interface RestrictedCusipListProps {
  restrictedCusips: {
    id: string;
    cusip: string;
  }[];
}

export const RestrictedCusipList: React.FC<RestrictedCusipListProps> = ({
  restrictedCusips,
}) => {
  return (
    <Styled.Scroll>
      {restrictedCusips && restrictedCusips.length ? (
        restrictedCusips.map((restrictedCusip) => {
          return (
            <RemoveRestrictedCusip
              restrictedCusip={restrictedCusip}
              key={restrictedCusip.id}
            />
          );
        })
      ) : (
        <Text variant={2}>There are no trade restrictions.</Text>
      )}
    </Styled.Scroll>
  );
};
