import React from 'react';
import mixpanel from 'modules/tracking/analytics/mixpanel';
import { useSendTreasureEvent } from 'modules/tracking/hooks/use-send-treasure-event';
import { TrackEventName } from 'modules/tracking';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';

export const useSendEvent = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const sendTreasureEvent = useSendTreasureEvent();

  const sendEvent = (eventName: string, properties?: any) => {
    if (isFeatureFlagEnabled(FeatureFlags.REACT_APP_MIXPANEL_THROUGH_PUBSUB)) {
      // Send mixpanel events to portal gateway
      sendTreasureEvent.mutate({
        eventName,
        properties,
        publishEventToMixpanel: true,
      });
    } else {
      // filter out pageview events
      if (eventName !== TrackEventName.Pageview) {
        sendTreasureEvent.mutate({
          eventName,
          properties,
        });
      }

      mixpanel.track(eventName, properties);
    }
  };

  return { sendEvent };
};
