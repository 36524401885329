import styled, { css } from 'styled-components';
import { TextareaAutosize } from '@material-ui/core';

interface TextareaProps {
  $error: boolean | undefined;
}

const Textarea = styled(TextareaAutosize)<TextareaProps>`
  border: 1px solid var(--color-mischka2);
  border-radius: var(--border-radius-02);
  font-size: var(--font-size-body-02);
  font-weight: var(--font-weight-regular);
  padding: 10px; // same-ish as textfields (TODO: refactor)

  ${(props) =>
    props.$error &&
    css`
      border-color: var(--color-torchRed);
    `};
`;

export const Styled = {
  Textarea,
};
