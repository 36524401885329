import React from 'react';
import { Box } from '@material-ui/core';
import { Spinner } from 'components/core';

export const LoadingSubIndicator: React.FC = () => {
  return (
    <Box my={3}>
      <Spinner />
    </Box>
  );
};
