import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';

import { AccountCard } from 'modules/onboarding/account-card/account-card';
import { Heading } from 'components/core';
import { Bank } from 'modules/onboarding/connect-accounts/connect-accounts';

export type BankAccountsProps = Omit<Bank, 'id'>;

export const BankAccounts: React.FC<BankAccountsProps> = ({
  name,
  accounts,
}) => {
  return (
    <Box marginBottom={4} width="100%">
      <Box marginBottom={1}>
        <Heading variant={3}>{name} Accounts</Heading>
      </Box>
      <Grid container spacing={2}>
        {accounts.map((account) => (
          <Grid key={account.id} item xs={6} sm={4}>
            <AccountCard {...account} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

BankAccounts.propTypes = {
  name: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      balance: PropTypes.string.isRequired,
      excludeFromAnalysis: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      mask: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};
