import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant, Spinner } from 'components/core';
import { i18n, Trans } from 'app/i18n';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { AlertPlaceholder } from 'modules/page-errors/alert-placeholder/alert-placeholder';
import { isDevOrStagingEnv } from 'routes/constants/routes-path';
import {
  useMutatePortfolio,
  MutatePortfolioRequest,
} from 'modules/portfolio/allocation/hooks/use-mutate-portfolio';
import { useMutateBusinessProperty } from 'hooks/business/use-mutate-business-property';
import {
  Allocation,
  AllocationProducts,
  getAllocationAmount,
  initialAllocation,
  applyRates,
  applyExistingAllocation,
  applyFee,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { defaultAmount } from 'modules/portfolio/allocation/components/allocation-modal/components/allocation-set-amount/allocation-set-amount';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';
import ErrorContent from 'content/error.content.json';
import { useProducts } from 'hooks/products/use-products';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import {
  AllocationChart,
  AllocationConfirmation,
  AllocationDisclaimers,
  AllocationModalButtons,
  FootnoteProvider,
} from './components';
import Content from './utils/allocation-content/content/allocation-modal.content.json';

// adds the allocaiton modal content used by ./utils/allocation-content/get-*
export const i18nNamespaceAllocationModal = 'allocation-modal';
const i18nNamespaceErrorContent = 'error-content';
i18n.addResourceBundle('en', i18nNamespaceAllocationModal, Content);
i18n.addResourceBundle('en', i18nNamespaceErrorContent, ErrorContent);

interface AllocationModalProps {
  aum: number;

  isDemo?: boolean;

  onChange?: (allocation: Allocation) => void;

  onClose?: () => void;
}

export const AllocationModal: React.FC<AllocationModalProps> = ({
  aum,
  isDemo,
  onChange,
  onClose,
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { Track, trackEvent } = useTracking<{
    eventName: TrackEventName;
    section: TrackPageSection;
  }>({
    section: TrackPageSection.PortfolioAllocationModal,
  });

  const showButtons = isDemo;
  const showHeader = isDemo;
  const showNotificationBox = isDemo;

  const { data: business } = useBusiness();
  const businessId =
    business?.Id ||
    (isDevOrStagingEnv
      ? '24dfec32-c332-4bb3-9e8b-ed77646fd68b'
      : '3bf3c605-f36a-4e1f-bd2f-c6f681e843ed');
  const {
    data: portfolios,
    isLoading: isLoadingPortfolios,
    isError: isErrorPortfolios,
  } = usePortfolios(businessId);

  const { isLoading: isLoadingMutatePortfolio, mutate: mutatePortfolio } =
    useMutatePortfolio();
  const { mutate: mutateBusinessProperty } = useMutateBusinessProperty();

  const [allocation, setAllocation] = useState<Allocation>(initialAllocation);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // variables to handle no funds users
  const [noFundsAUM, setNoFundsAUM] = useState<number>(defaultAmount);
  const noFunds = aum === 0;
  const aumToDisplay = noFunds ? noFundsAUM : aum;

  const ratesAreNotLoaded = Object.values(allocation).some(
    (productAllocation) => productAllocation.return === 0,
  );

  const acceptAllocations = () => {
    const mutatePortfolioRequest: MutatePortfolioRequest = {
      [AllocationProducts.cash]: {
        amount: getAllocationAmount({
          allocation: allocation[AllocationProducts.cash].allocation,
          aum,
        }),
        percentage: allocation[AllocationProducts.cash].allocation,
      },
      [AllocationProducts.smart]: {
        amount: getAllocationAmount({
          allocation: allocation[AllocationProducts.smart].allocation,
          aum,
        }),
        percentage: allocation[AllocationProducts.smart].allocation,
      },
      [AllocationProducts.tbills]: {
        amount: getAllocationAmount({
          allocation: allocation[AllocationProducts.tbills].allocation,
          aum,
        }),
        percentage: allocation[AllocationProducts.tbills].allocation,
      },
      [AllocationProducts.moneyMarket]: {
        amount: getAllocationAmount({
          allocation: allocation[AllocationProducts.moneyMarket].allocation,
          aum,
        }),
        percentage: allocation[AllocationProducts.moneyMarket].allocation,
      },
    };

    mutatePortfolio(
      {
        businessId: business.id,
        portfolio: mutatePortfolioRequest,
      },
      {
        onSettled: () => {
          if (onClose && !noFunds) {
            onClose();
          }
        },
      },
    );

    if (noFunds) {
      mutateBusinessProperty({
        businessId: business.Id,
        name: 'portfolioAllocationNoFundsAUM',
        value: noFundsAUM,
      });

      setShowConfirmation(true);
    }
  };

  const handlePrimaryButtonClick = () => {
    if (showConfirmation || noFunds) {
      acceptAllocations();

      trackEvent({ eventName: TrackEventName.AcceptButtonClicked });
    } else {
      setShowConfirmation(true);

      trackEvent({ eventName: TrackEventName.ConfirmButtonClicked });
    }
  };

  const handleSecondaryButtonClick = () => {
    if (showConfirmation) {
      setShowConfirmation(false);

      trackEvent({ eventName: TrackEventName.BackButtonClicked });
    } else {
      if (onClose) {
        onClose();
      }

      trackEvent({ eventName: TrackEventName.CancelButtonClicked });
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(allocation);
    }
  }, [allocation, onChange]);

  useEffect(() => {
    if (portfolios?.portfolios[0]?.allocations) {
      setAllocation({ ...applyExistingAllocation(portfolios?.portfolios[0]) });
    }
    if (portfolios?.rates) {
      setAllocation({ ...applyRates(portfolios.rates) });
    }
  }, [portfolios]);

  const { data: products } = useProducts(businessId);
  const treasureFee = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED,
  )
    ? products?.treasureHighYieldFee || 0
    : 0;

  useEffect(() => {
    setAllocation({ ...applyFee(treasureFee) });
  }, [treasureFee]);
  if (isErrorPortfolios) {
    return (
      <Box p={6}>
        <AlertPlaceholder
          message={
            <Trans
              i18nKey="experiencingIssues"
              ns={i18nNamespaceErrorContent}
            />
          }
        />
      </Box>
    );
  }

  if (ratesAreNotLoaded || isLoadingPortfolios) {
    return (
      <Box p={6}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Track>
      <FootnoteProvider>
        <Box p={2}>
          {!showHeader && (
            <Box mb={1}>
              <Typography variant={TypographyVariant.Header}>
                <Trans i18nKey="Portfolio" ns={i18nNamespaceAllocationModal} />
              </Typography>
            </Box>
          )}

          {showConfirmation ? (
            <AllocationConfirmation
              allocation={allocation}
              aum={aumToDisplay}
              noFunds={noFunds}
            />
          ) : (
            <AllocationChart
              allocations={allocation}
              aum={aumToDisplay}
              noFunds={noFunds}
              setAllocations={setAllocation}
              setNoFundsAUM={setNoFundsAUM}
              showNotificationBox={showNotificationBox}
            />
          )}

          <Box mt={6}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Grid item xs>
                <AllocationDisclaimers />
              </Grid>

              {!showButtons && (
                <Grid item xs={5}>
                  <AllocationModalButtons
                    handlePrimaryButtonClick={handlePrimaryButtonClick}
                    handleSecondaryButtonClick={handleSecondaryButtonClick}
                    isLoadingMutatePortfolio={isLoadingMutatePortfolio}
                    noFunds={noFunds}
                    showConfirmation={showConfirmation}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </FootnoteProvider>
    </Track>
  );
};
