import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { AllocationSwitch } from 'modules/portfolio/allocation/components/allocation-modal/components';
import { getTooltip } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import {
  Allocation,
  AllocationProducts,
  initialAllocation,
  treasureReserveProductKey,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import {
  columnHeader,
  tooltipList,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-typography/allocation-typography';
import { Footnote } from 'modules/portfolio/allocation/components/allocation-modal/components/allocation-footnotes';
import { Trans } from 'app/i18n';
import { i18nNamespaceAllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';

interface TooltipParameters {
  content?: React.ReactElement;
  eventLabel: string;
  header?: React.ReactElement;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export const getHeaders = ({
  allocations,
  handleAllocationChange,
}: {
  allocations: Allocation;
  handleAllocationChange: (
    allocationType: keyof AllocationProducts,
    allocationWeight: number,
  ) => void;
}) => {
  const getColumnHeader = (productName: string) => {
    switch (productName) {
      case allocations[AllocationProducts.cash].name:
        return columnHeader(
          <Footnote
            description={
              <Trans i18nKey="footnoteFDIC" ns={i18nNamespaceAllocationModal} />
            }
            symbol="*"
          >
            {productName}
          </Footnote>,
        );
      default:
        return columnHeader(productName);
    }
  };

  const getTooltipParameters = (allocationType: keyof Allocation) => {
    const params: TooltipParameters = {
      content: tooltipList(
        <Trans
          i18nKey={`${allocationType}TooltipContent`}
          ns={i18nNamespaceAllocationModal}
          components={{ ul: <ul />, li: <li /> }}
        />,
      ),
      eventLabel: allocationType,
      placement: 'left',
    };

    return params;
  };

  const handleSwitchChange = (allocationType: keyof AllocationProducts) => {
    handleAllocationChange(
      allocationType,
      allocations[allocationType as keyof Allocation].allocation === 0
        ? initialAllocation[allocationType as keyof Allocation].allocation
        : 0,
    );
  };

  const showHeader = (
    allocationSwitch: React.ReactElement,
    tooltipParameters?: TooltipParameters,
  ) => {
    return (
      <Grid container alignItems="flex-end">
        <Grid item xs>
          {allocationSwitch}
        </Grid>

        <Grid item xs>
          <Box position="relative" top="2px">
            {tooltipParameters && getTooltip(tooltipParameters)}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const data = ['', columnHeader(allocations[treasureReserveProductKey].name)];

  Object.entries(allocations).map(([productKey, product]) => {
    if (productKey === treasureReserveProductKey) {
      return null;
    }

    return data.push(
      showHeader(
        <AllocationSwitch
          checked={product.allocation !== 0}
          color={product.color}
          eventLabel={product.name}
          label={getColumnHeader(product.name)}
          onChange={() =>
            handleSwitchChange(productKey as keyof AllocationProducts)
          }
        />,
        getTooltipParameters(productKey as keyof Allocation),
      ),
    );
  });

  return data;
};
