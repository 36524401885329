import React from 'react';
import { Box } from '@material-ui/core';
import {
  Button,
  Heading,
  Typography,
  TypographyVariant,
} from 'components/core';
import { useHistory } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

export const ForgotPasswordSent: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Box mt={4} mb={10} px={4} textAlign="center">
        <Heading variant={2}>
          If you have an account, we’ll send you a password reset email.
        </Heading>
      </Box>

      <Box mt={3} px={10}>
        <Button
          onClick={() => history.push(RoutesPath.noAuth.signIn.path)}
          width={100}
        >
          <Box py={0.75}>
            <Typography color="white" variant={TypographyVariant.Body}>
              Sign In
            </Typography>
          </Box>
        </Button>
      </Box>
    </>
  );
};
