import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components/core';

interface ItemDefinitionProps {
  /** heading of the item */
  label: React.ReactNode;

  labelColor?: string;

  /** the actual item definition */
  value: React.ReactNode;
}

const ItemDefinition: React.FC<ItemDefinitionProps> = ({
  label,
  labelColor,
  value,
}) => {
  return (
    <>
      <Heading color={labelColor || 'gothic'} variant={5}>
        {label}
      </Heading>
      {value}
    </>
  );
};

ItemDefinition.propTypes = {
  label: PropTypes.node.isRequired,
  labelColor: PropTypes.string,
  value: PropTypes.node.isRequired,
};

export { ItemDefinition };
