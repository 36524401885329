import React from 'react';
import { useHistory } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { V2Layout } from 'layouts';
import { SetPassword } from './pages/set-password';

interface Props {
  email: string;
}

export const StreamlinedOnboardingSetPassword: React.FC<Props> = ({
  email,
}) => {
  const history = useHistory();

  return (
    <SetPassword
      email={email}
      onSuccessCallback={() => {
        // This needs to be wrapped in setTimeout so that it goes to the end of the event queue (and allows for the loggedin state to be set)
        setTimeout(
          () =>
            history.push(
              getVersionedPath({
                path: RoutesPath.onboarding.accountIntake.path,
                version: 3,
              }),
            ),
          0,
        );
      }}
    />
  );
};
