import React from 'react';
import { Button } from 'components/core';
import { AccountType, SweepType, TreasureReserveStatus } from 'enums';
import { BankAccount, TreasureReserve } from 'types';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { LiquidSenseContentType } from '../enums';

const BUILD_YOUR_RESERVE = 'Build Your Reserve';
const CONNECT_ACCOUNTS = 'Connect Accounts';
const RESERVE_UNDER_REVIEW =
  'We appreciate your patience as we review your application to open a Reserve account.';
const SWEEP_TO_RESERVE = 'Transfer to Reserve';

export interface LiquidSenseContent {
  action: React.ReactElement;
  attentionNeeded?: boolean;
  bankAccount?: BankAccount;
  description: string;
  liquidSenseContentType: LiquidSenseContentType;
  sweepType?: SweepType;
  title: string;
}

export const getLiquidSenseContent = (
  accounts: BankAccount[],
  treasureReserve: TreasureReserve,
): LiquidSenseContent => {
  const hasConnectedAccount = accounts.length > 0;

  const hasOpenTreasureReserve =
    treasureReserve.status === TreasureReserveStatus.OpenAndUnfunded ||
    treasureReserve.status === TreasureReserveStatus.OpenAndFunded;

  const treasureReserveBalanceAsNumber = Number(treasureReserve.balance); // TODO: (API) should be number but api returns string

  const checkingAccountsWithNegativeRecommendedCashSweep = accounts.filter(
    (account: BankAccount) =>
      account.Type === AccountType.Checking && account.rcs <= 0,
  );

  // no open treasure reserve and no connected account
  if (!hasOpenTreasureReserve && !hasConnectedAccount) {
    return {
      action: (
        <Link to={RoutesPath.pages.connectBankAccounts.path}>
          <Button>Get Started</Button>
        </Link>
      ),
      description: `${RESERVE_UNDER_REVIEW} While you wait, would you like to connect accounts for insights?`,
      liquidSenseContentType: LiquidSenseContentType.ConnectAccounts,
      title: CONNECT_ACCOUNTS,
    };
  }

  // no open treasure reserve and at least 1 connected account
  if (!hasOpenTreasureReserve && hasConnectedAccount) {
    return {
      action: (
        <Link to={RoutesPath.pages.connectBankAccounts.path}>
          <Button>Connect additional accounts</Button>
        </Link>
      ),
      description: `${RESERVE_UNDER_REVIEW} Have you connected all of your accounts for insights?`,
      liquidSenseContentType: LiquidSenseContentType.ConnectAccounts,
      title: CONNECT_ACCOUNTS,
    };
  }

  // open treasure reserve, negative rcs in a connected account
  if (
    hasOpenTreasureReserve &&
    treasureReserveBalanceAsNumber > 0 &&
    checkingAccountsWithNegativeRecommendedCashSweep.length > 0
  ) {
    // TODO: if there is more than 1 account with a negative recommended cash sweep, which one should we display?
    const bankAccount = checkingAccountsWithNegativeRecommendedCashSweep[0];

    return {
      action: (
        <FeatureFlag
          disabled={
            <Link to={RoutesPath.pages.transfer.path}>
              <Button>Withdraw Cash</Button>
            </Link>
          }
          enabled={
            <Link to={RoutesPath.tasks.withdrawal.path}>
              <Button>Withdraw Cash</Button>
            </Link>
          }
          flag={FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED}
        />
      ),
      attentionNeeded: true,
      bankAccount,
      description:
        'Transfer cash from your Treasure Reserve account to make sure you have the funds on hand to cover all upcoming expenses',
      title: 'Upcoming expenses exceed cash',
      liquidSenseContentType: LiquidSenseContentType.UpcomingExpensesExceedCash,
      sweepType: SweepType.Withdrawal,
    };
  }

  // treasure reserve open and unfunded
  if (treasureReserve.status === TreasureReserveStatus.OpenAndUnfunded) {
    return {
      action: (
        <FeatureFlag
          disabled={
            <Link to={RoutesPath.pages.transfer.path}>
              <Button>Initiate First Transfer</Button>
            </Link>
          }
          enabled={
            <Link to={RoutesPath.tasks.deposit.path}>
              <Button>Initiate First Transfer</Button>
            </Link>
          }
          flag={FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED}
        />
      ),
      description:
        'Transfer funds into your Treasure Reserve account and start growing your runway',
      liquidSenseContentType: LiquidSenseContentType.FirstSweep,
      sweepType: SweepType.Deposit,
      title: 'Perform your first transfer',
    };
  }

  // treasure reserve no balance
  if (
    treasureReserve.status === TreasureReserveStatus.OpenAndFunded &&
    treasureReserveBalanceAsNumber <= 0
  ) {
    return {
      action: (
        <FeatureFlag
          disabled={
            <Link to={RoutesPath.pages.transfer.path}>
              <Button>{SWEEP_TO_RESERVE}</Button>
            </Link>
          }
          enabled={
            <Link to={RoutesPath.tasks.deposit.path}>
              <Button>{SWEEP_TO_RESERVE}</Button>
            </Link>
          }
          flag={FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED}
        />
      ),
      description: 'Earn more by funding your Reserve.',
      liquidSenseContentType: LiquidSenseContentType.BuildReserve,
      sweepType: SweepType.Deposit,
      title: BUILD_YOUR_RESERVE,
    };
  }

  // default: open treasure reserve
  return {
    action: (
      <FeatureFlag
        disabled={
          <Link to={RoutesPath.pages.transfer.path}>
            <Button>{SWEEP_TO_RESERVE}</Button>
          </Link>
        }
        enabled={
          <Link to={RoutesPath.tasks.deposit.path}>
            <Button>{SWEEP_TO_RESERVE}</Button>
          </Link>
        }
        flag={FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED}
      />
    ),
    description: 'Keep earning by funding your Reserve.',
    liquidSenseContentType: LiquidSenseContentType.BuildReserve,
    sweepType: SweepType.Deposit,
    title: BUILD_YOUR_RESERVE,
  };
};
