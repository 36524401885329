import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';

interface ConfirmationModalContainerProps {
  mobile?: boolean;
}

const ConfirmationModalContainer = styled(Box)<ConfirmationModalContainerProps>`
  ${(props) =>
    props.mobile &&
    css`
      height: 100vh;
      height: -webkit-fill-available;
    `}
`;

export const Styled = { ConfirmationModalContainer };
