import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { useHistory, Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { useBusiness } from 'hooks/business/use-business';
import { useMutateBusinessProperty } from 'hooks/business/use-mutate-business-property';

export const DepositButton = () => {
  const history = useHistory();
  const { data: business } = useBusiness();
  const claimedOffer = business?.Properties?.claimed2023DecemberGrowthPromo;
  const { isLoading, mutate: mutateBusinessProperty } =
    useMutateBusinessProperty();

  const handleClaimOffer = () => {
    mutateBusinessProperty(
      {
        businessId: business.Id,
        name: 'claimed2023DecemberGrowthPromo',
        value: true,
      },
      {
        onSettled: () => {
          history.push(RoutesPath.tasks.deposit.path);
        },
      },
    );
  };

  const ClaimOfferButton = isLoading ? (
    <Button disabled fullWidth>
      <Box p={1} position="relative" top={3}>
        <Typography color="black19" variant={TypographyVariant.Paragraph2}>
          <CircularProgress color="inherit" size={25} />
        </Typography>
      </Box>
    </Button>
  ) : (
    <Button fullWidth onClick={handleClaimOffer}>
      <ButtonContent>
        <Typography color="white" variant={TypographyVariant.Paragraph2}>
          Claim Offer
        </Typography>
      </ButtonContent>
    </Button>
  );

  return (
    <>
      <Box mb={1.5} textAlign="center">
        <Typography color="grey1" variant={TypographyVariant.Paragraph3}>
          See{' '}
          <a
            href="//www.treasurefi.com/promotions/50-percent-off-investment-management-fees"
            rel="noreferrer"
            target="_blank"
          >
            <Typography color="blue2" variant={TypographyVariant.Paragraph3}>
              Terms and Conditions
            </Typography>
          </a>{' '}
          for Full Offer Details
        </Typography>
      </Box>

      {claimedOffer ? (
        <Link to={RoutesPath.tasks.deposit.path}>
          <Button fullWidth>
            <ButtonContent>
              <Typography color="white" variant={TypographyVariant.Paragraph2}>
                Deposit Now
              </Typography>
            </ButtonContent>
          </Button>
        </Link>
      ) : (
        ClaimOfferButton
      )}
    </>
  );
};
