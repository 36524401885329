import React from 'react';
import { Spinner } from 'components/core';
import { UserRow } from 'modules/multi-user/components/user-row/user-row';
import { v4 as uuidv4 } from 'uuid';

// hooks
import { useBusinessMembers } from 'hooks/business/use-business-members';

export const UserList: React.FC = () => {
  const {
    data: businessMembers,
    isLoading,
    isRefetching,
  } = useBusinessMembers();

  if (isLoading || isRefetching) {
    return <Spinner />;
  }

  if (!businessMembers) {
    return null;
  }

  return businessMembers?.map((member: any) => (
    <UserRow user={member} key={uuidv4()} />
  ));
};
