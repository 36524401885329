import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridProps } from '@material-ui/core';
import {
  Controller,
  Message,
  ValidationValueMessage,
  Validate,
  ValidationRule,
  Control,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from 'react-hook-form';

export type ObjectLiteral<ValueType = any> = Record<string, ValueType>;

export type Validations = Partial<{
  required: ValidationRule<boolean> | Message;
  min: ValidationRule<number | string>;
  max: ValidationRule<number | string>;
  maxLength: ValidationRule<number>;
  minLength: ValidationRule<number>;
  pattern: ValidationRule<RegExp>;
  validate: Validate<any> | ObjectLiteral<Validate<any>>;
}>;

export type BaseFormFieldSpecs = {
  name: string;
  label?: string;
  errorLabel?: string;
  placeholder?: string;
  defaultValue?: any;
  validations?: Validations;
};

export type FormFieldSpecs<ExtraFormFieldSpecs = ObjectLiteral> =
  ExtraFormFieldSpecs & BaseFormFieldSpecs;

export type FormFieldComponentProps<ExtraFormFieldSpecs> =
  FormFieldSpecs<ExtraFormFieldSpecs> & {
    control: Control<any, any>;
    gridProps?: GridProps;
  };

export type FactoryFormFieldComponentRenderFuncArgs<ExtraFormFieldSpecs> =
  FormFieldSpecs<ExtraFormFieldSpecs> &
    ControllerRenderProps &
    ControllerFieldState & {
      field: ControllerRenderProps;
      fieldState: ControllerFieldState;
      formState: UseFormStateReturn<ObjectLiteral>;
    };

export type FactoryFormFieldComponentRenderFunc<ExtraFormFieldSpecs> = (
  args: FactoryFormFieldComponentRenderFuncArgs<ExtraFormFieldSpecs>,
) => React.ReactElement;

export type FactoryFormFieldComponentOptions = Partial<{
  componentName: string;
}>;

export function factoryFormFieldComponent<ExtraFormFieldSpecs>(
  renderComponent: FactoryFormFieldComponentRenderFunc<ExtraFormFieldSpecs>,
  options: FactoryFormFieldComponentOptions = {},
): React.FC<FormFieldComponentProps<ExtraFormFieldSpecs>> {
  const { componentName = 'FormFieldController' } = options;

  const FormFieldController: React.FC<
    FormFieldComponentProps<ExtraFormFieldSpecs>
  > = ({
    name,
    label,
    errorLabel,
    placeholder,
    defaultValue,
    validations = {},
    control,
    gridProps,
    ...otherProps
  }) => {
    const { required, max, min, maxLength, minLength, pattern, validate } =
      validations;

    const getErrorMessage = (errorMessage: string) => {
      const errorMessageLabel = errorLabel || label || placeholder;

      if (errorMessage === 'required' && errorMessageLabel) {
        errorMessage = 'is required';
      }

      return errorMessageLabel
        ? `${errorMessageLabel} ${errorMessage}`
        : errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1); // capitalize first letter
    };

    const customValidations: ObjectLiteral<Validate<any>> = {
      required: (value) => {
        if (required) {
          const isValid = value !== undefined && String(value).trim() !== '';
          const errorMessage =
            typeof required === 'string'
              ? required
              : (required as ValidationValueMessage)?.message ||
                `${errorLabel} is required`;

          if (!isValid) {
            return errorMessage;
          }
        }
      },
    };

    const validateWithCustomValidations =
      typeof validate === 'function'
        ? {
            ...customValidations,
            validate,
          }
        : {
            ...customValidations,
            ...validate,
          };

    const validationsWithMessage: Validations = {
      ...validations,
      ...(typeof required === 'boolean' && {
        required: {
          value: required,
          message: getErrorMessage('required'),
        },
      }),
      ...(typeof maxLength === 'number' && {
        maxLength: {
          value: maxLength,
          message: getErrorMessage(
            `must be a maximum of ${maxLength} characters or less`,
          ),
        },
      }),
      ...(typeof minLength === 'number' && {
        minLength: {
          value: minLength,
          message: getErrorMessage(
            `must be at least ${minLength} characters long`,
          ),
        },
      }),
      ...(typeof max === 'number' && {
        max: {
          value: max,
          message: getErrorMessage(`must be less or equal to ${max}`),
        },
      }),
      ...(typeof min === 'number' && {
        min: {
          value: min,
          message: getErrorMessage(`must be greater or equal to ${min}`),
        },
      }),
      ...(pattern instanceof RegExp && {
        pattern: {
          value: pattern,
          message: getErrorMessage(`must be valid`),
        },
      }),
      validate: validateWithCustomValidations,
    };

    return (
      <Grid item {...gridProps}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={validationsWithMessage}
          render={({ field, fieldState, formState }) => {
            const renderComponentParams = {
              ...field,
              ...fieldState,
              ...otherProps,
              field,
              fieldState,
              formState,
              name,
              label,
              placeholder,
              defaultValue,
              validations,
            } as FactoryFormFieldComponentRenderFuncArgs<ExtraFormFieldSpecs>;
            return renderComponent(renderComponentParams);
          }}
        />
      </Grid>
    );
  };

  FormFieldController.displayName = componentName;

  // @ts-ignore
  FormFieldController.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    validations: PropTypes.shape({
      required: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.shape({
          value: PropTypes.bool,
          message: PropTypes.string,
        }),
      ]),
      min: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.number,
          message: PropTypes.string,
        }),
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.number,
          message: PropTypes.string,
        }),
      ]),
      maxLength: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.number,
          message: PropTypes.string,
        }),
      ]),
      minLength: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.number,
          message: PropTypes.string,
        }),
      ]),
      pattern: PropTypes.oneOfType([
        PropTypes.instanceOf(RegExp),
        PropTypes.shape({
          value: PropTypes.instanceOf(RegExp),
          message: PropTypes.string,
        }),
      ]),
      validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }),
    control: PropTypes.any.isRequired,
    gridProps: PropTypes.object,
  };

  return FormFieldController;
}

export default factoryFormFieldComponent;
