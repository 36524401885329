import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { DashboardType } from 'enums';
import { IndicatorProps, IndicatorType, TopIndicator } from './top-indicator';
import { Styled } from './top-indicators.style';

export interface TopIndicatorsProps {
  dashboard: DashboardType;
  indicators: IndicatorProps[];
}

const TopIndicators: React.FC<TopIndicatorsProps> = ({
  dashboard,
  indicators,
}) => {
  return (
    <Styled.TopIndicatorsContainer container>
      {indicators.map(function (indicator) {
        return indicator.type === IndicatorType.IdleCash ? (
          <Styled.IdleCashIndicator
            item
            key={indicator.type}
            {...(indicator.handleClick
              ? {
                  onClick: (event: React.MouseEvent<HTMLDivElement>) =>
                    indicator.handleClick &&
                    indicator.handleClick(event, indicator),
                }
              : null)}
          >
            <TopIndicator dashboard={dashboard} indicator={indicator} />
          </Styled.IdleCashIndicator>
        ) : (
          <Styled.TopIndicatorItem
            item
            xs
            key={indicator.type}
            {...(indicator.handleClick
              ? {
                  onClick: (event: React.MouseEvent<HTMLDivElement>) =>
                    indicator.handleClick &&
                    indicator.handleClick(event, indicator),
                }
              : null)}
          >
            <Box textAlign="center">
              <TopIndicator dashboard={dashboard} indicator={indicator} />
            </Box>
          </Styled.TopIndicatorItem>
        );
      })}
    </Styled.TopIndicatorsContainer>
  );
};

TopIndicators.propTypes = {
  dashboard: PropTypes.oneOf(Object.values(DashboardType) as DashboardType[])
    .isRequired,
  indicators: PropTypes.array.isRequired,
};

export { TopIndicators };
