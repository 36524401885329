import React from 'react';
import NumberFormat from 'react-number-format';
import { Currency, CurrencyVariant } from 'components/core';
import { ColumnType, DashboardTable } from 'modules/dashboard/components';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useTreasureReservePositions } from 'modules/treasure-reserve-positions/hooks/use-treasure-reserve-positions';
import { useProductPositions } from 'modules/treasure-reserve-positions/hooks/use-product-positions';
import {
  isManagedIncome,
  isManagedMoneyMarket,
  treasuryBillTickerStartsWith,
  treasuryTIPSTickerStartsWith,
} from 'modules/portfolio/utils/portfolio-product-helpers';

const fundColumns: ColumnType[] = [
  { name: 'Asset Name', size: 5 },
  { alignment: 'center', name: 'Ticker', size: 2 },
  { alignment: 'center', name: 'Current Value', size: 2 },
  { alignment: 'right', name: 'Shares or Value at Maturity', size: 2 },
  { size: 1 },
];

const uninvestedColumns: ColumnType[] = [
  { name: 'Asset Name', size: 7 },
  { alignment: 'center', name: 'Current Value', size: 2 },
  { size: 3 },
];

export enum Product {
  'Cash' = 'Cash',
  'ManagedMoneyMarket' = 'Managed Money Market',
  'ManagedTreasuries' = 'Managed Treasuries',
  'ManagedIncome' = 'Managed Income',
  'Other' = 'Other',
}

interface ProductPositionsProps {
  product: Product;
}

export const ProductPositions: React.FC<ProductPositionsProps> = ({
  product,
}) => {
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolio, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );
  const { data: positions, isLoading: isLoadingTreasureReservePositions } =
    useTreasureReservePositions(business?.Id);
  const { data: productPositions, isLoading: isLoadingProductPositions } =
    useProductPositions();

  const managedMoneyMarketCusips = Array.from(
    new Set(
      productPositions?.managedMoneyMarketAllocation?.map(
        (fund: {
          fund: {
            cusip: string;
          };
        }) => fund?.fund?.cusip,
      ),
    ),
  );

  const managedIncomeCusips = Array.from(
    new Set(
      productPositions?.managedIncomeAllocation?.map(
        (fund: {
          fund: {
            cusip: string;
          };
        }) => fund?.fund?.cusip,
      ),
    ),
  );

  const managedTreasuriesCusips = Array.from(
    new Set(
      productPositions?.managedTreasuries?.map(
        (fund: {
          fund: {
            cusip: string;
          };
        }) => fund?.fund?.cusip,
      ),
    ),
  );

  if (
    isLoadingBusiness ||
    isLoadingPortfolios ||
    isLoadingTreasureReservePositions ||
    isLoadingProductPositions
  ) {
    return null;
  }

  if (
    product === Product.ManagedMoneyMarket ||
    product === Product.ManagedIncome ||
    product === Product.ManagedTreasuries
  ) {
    const tableData: [
      string,
      string | null,
      React.ReactElement | null,
      React.ReactElement | null,
    ][] = [];

    let funds = [];

    if (product === Product.ManagedMoneyMarket) {
      funds = positions?.filter((position: { cusip: string }) =>
        managedMoneyMarketCusips.includes(position.cusip),
      );
    }

    if (product === Product.ManagedIncome) {
      funds = positions?.filter((position: { cusip: string }) =>
        managedIncomeCusips.includes(position.cusip),
      );
    }

    if (product === Product.ManagedTreasuries) {
      funds = positions?.filter((position: { cusip: string }) =>
        managedTreasuriesCusips.includes(position.cusip),
      );
    }

    funds?.map(
      (fund: {
        cusip: string;
        name: string;
        ticker: string;
        shares: number;
        dollarValue: number;
      }) => {
        if (
          fund.cusip.startsWith('912795') ||
          fund.cusip.startsWith('912796') ||
          fund.cusip.startsWith('912797')
        ) {
          // Treasury Bills
          return tableData.push([
            fund.ticker,
            null,
            <Currency
              number={fund.dollarValue}
              variant={CurrencyVariant.Full}
            />,
            <Currency number={fund.shares} variant={CurrencyVariant.Full} />,
          ]);
        } else if (
          fund.ticker?.startsWith(treasuryBillTickerStartsWith) ||
          fund.ticker?.startsWith(treasuryTIPSTickerStartsWith)
        ) {
          // TIPS
          return tableData.push([
            fund.ticker.replace(
              treasuryBillTickerStartsWith,
              treasuryTIPSTickerStartsWith,
            ),
            null,
            <Currency
              number={fund.dollarValue}
              variant={CurrencyVariant.Full}
            />,
            null,
          ]);
        }

        return tableData.push([
          fund.name,
          fund.ticker,
          <Currency number={fund.dollarValue} variant={CurrencyVariant.Full} />,
          <NumberFormat
            decimalScale={3}
            displayType="text"
            fixedDecimalScale
            thousandSeparator
            value={fund.shares}
          />,
        ]);
      },
    );

    if (tableData?.length === 0) {
      return null;
    }

    return <DashboardTable columns={fundColumns} data={tableData} />;
  }

  // TODO: if/when we allow businesses to purchase funds that aren't within our products
  // add another Other section? (currently Other is named Uninvested)
  // and list the below funds (currently we're only showing Cash from above)
  if (product === Product.Other) {
    const tableData: [string, React.ReactElement | null][] = [];

    if (product === Product.Other) {
      tableData.push([
        'Cash',
        <Currency
          number={portfolio?.amountsInDollars?.productAmounts.otherCash}
          variant={CurrencyVariant.Full}
        />,
      ]);
    }

    if (tableData?.length === 0) {
      return null;
    }

    return <DashboardTable columns={uninvestedColumns} data={tableData} />;
  }

  return null;
};
