import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box } from '@material-ui/core';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { FormFieldContainer } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { TextField } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/text-field';
import { AllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';
import { useTracking, TrackPageSection } from 'modules/tracking';
import { Redirect } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';

export const AllocationDemo: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { Track } = useTracking<{
    section: TrackPageSection;
  }>({
    section: TrackPageSection.DemoPortfolioAllocation,
  });

  const defaultAmount = 10000000;
  const maxAmount = 10000000000;

  const [amount, setAmount] = useState<number | undefined>(defaultAmount);

  const portfolioAllocationDemoEnabled = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_ALLOCATION_DEMO_ENABLED,
  );

  if (!portfolioAllocationDemoEnabled) {
    return <Redirect to={RoutesPath.pages.home.path} />;
  }

  return (
    <Track>
      <CenteredContainer>
        <Box mt={5}>
          <FormFieldContainer>
            <NumberFormat
              allowNegative={false}
              customInput={TextField}
              decimalScale={2}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue ? floatValue < maxAmount : true;
              }}
              label="Deposit"
              prefix="$ "
              onValueChange={(data) => {
                setAmount(data.floatValue);
              }}
              thousandSeparator
              value={amount}
            />
          </FormFieldContainer>

          <Box bgcolor="white">
            <AllocationModal aum={amount || defaultAmount} isDemo />
          </Box>
        </Box>
      </CenteredContainer>
    </Track>
  );
};
