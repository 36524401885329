import styled from 'styled-components';
import { Heading } from 'components/core';
import { Box, IconButton as IconButtonAlias } from '@material-ui/core';
import { ReactComponent as CaretIconAlias } from 'assets/icons/caret.svg';

const Container = styled(Box)`
  background-color: var(--color-white);
  border-radius: var(--border-radius-02);
  box-shadow: var(--box-shadow-06);
`;

const IconButton = styled(IconButtonAlias)`
  padding-left: 0;
  padding-right: 0;
`;

const IconContainer = styled(Box)`
  ${IconButton} {
    &:first-child {
      margin-right: auto;
    }
  }
`;

const CaretIcon = styled(CaretIconAlias)`
  transform: rotate(90deg);
  height: 20px;
  path {
    stroke: var(--color-nero);
  }
`;

export const Styled = {
  CaretIcon,
  Container,
  IconButton,
  IconContainer,
};
