import styled from 'styled-components';
import { Button, Spinner as SpinnerAlias } from 'components/core';

const RoundedButton = styled(Button)`
  &.MuiButton-contained,
  &.MuiButton-outlined {
    border-color: var(--color-primary);
    border-radius: 100px;
    min-width: unset; // TODO: on click, buttons gain width
  }

  &.MuiButton-contained {
    background-color: var(--color-primary);

    &:hover,
    &:focus {
      background-color: var(--color-primary);
    }
  }

  &.MuiButton-outlined {
    color: var(--color-grey3);
  }
`;

const RoundedPrimaryButton = styled(RoundedButton)`
  width: 200px;
`;

const Spinner = styled(SpinnerAlias)`
  scale: 2;
`;

export const Styled = {
  RoundedButton,
  RoundedPrimaryButton,
  Spinner,
};
