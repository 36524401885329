import React from 'react';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { Currency, CurrencyVariant, Percentage, Text } from 'components/core';
import NumberFormat from 'react-number-format';
import { ValueType } from 'modules/treasure-reserve-positions/enums/value-type';
import { Styled } from './positions-asset-table.style';

const treasuryBillTickerStartsWith = 'US T Bill';
const treasuryTIPSTickerStartsWith = 'TIPS';

interface PositionsAssetTableProps {
  columns: {
    label: string;
    type: ValueType;
    value: string;
  }[];

  data: {
    cusip: string;
    dollarValue: string; // should be number
    shares: string; // should be number
    ticker: string;
  }[];
}

export const PositionsAssetTable: React.FC<PositionsAssetTableProps> = ({
  columns,
  data,
}) => {
  const getAlignment = (type: ValueType) => {
    switch (type) {
      case ValueType.CurrencyValue:
        return 'right';
      case ValueType.Number:
        return 'right';
      case ValueType.Percentage:
        return 'right';
      default:
        return 'left';
    }
  };

  const renderValue = (
    type: ValueType,
    value: string | number,
    positionData?: any,
  ) => {
    switch (type) {
      case ValueType.CurrencyValue:
        return <Currency number={+value} variant={CurrencyVariant.Full} />;
      case ValueType.Number:
        return (
          <NumberFormat
            decimalScale={3}
            displayType="text"
            fixedDecimalScale
            thousandSeparator
            value={value}
          />
        );
      case ValueType.Percentage:
        return (
          <>
            <Percentage decimalScale={2} number={+value * 100} />
            {positionData?.ticker?.startsWith(treasuryTIPSTickerStartsWith) &&
              ' + inflation'}
          </>
        );
      case ValueType.TickerSymbol:
        if (
          positionData?.ticker?.startsWith(treasuryBillTickerStartsWith) ||
          positionData?.ticker?.startsWith(treasuryTIPSTickerStartsWith)
        ) {
          return value;
        }

        return (
          <a
            href={`https://www.marketwatch.com/investing/fund/${value}`}
            rel="noreferrer"
            target="_blank"
          >
            {value}
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <Styled.TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <Styled.TableHeadCell
                key={column.type}
                align={getAlignment(column.type)}
              >
                <Styled.TableHeadText color="regalBlue" variant={2}>
                  {column.label}
                </Styled.TableHeadText>
              </Styled.TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row: any) => {
            return (
              <TableRow hover tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.value];

                  return (
                    <Styled.TableCell
                      key={column.type}
                      align={getAlignment(column.type)}
                    >
                      <Text color="regalBlue" variant={2}>
                        {renderValue(column.type, value, row)}
                      </Text>
                    </Styled.TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Styled.TableContainer>
  );
};
