import React from 'react';
import {
  i18nNamespace,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { ReactComponent as IconExclamationCircleOrange } from 'assets/icons/checkmarks/exclamation-circle-orange.svg';
import { Button } from 'modules/2023-q3/components/button/button';
import { Size } from 'enums';

interface FailedRetryProps {
  onRetry: (token?: string) => void;

  variant: DepositWithdrawalVariant;
}

export const FailedRetry: React.FC<FailedRetryProps> = ({
  onRetry,
  variant,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;
  const depositOrWithdrawal = t(isDeposit ? 'deposit' : 'withdrawal');
  const DepositOrWithdrawal = t(isDeposit ? 'Deposit' : 'Withdrawal');

  const handleDeposit = () => {
    onRetry();
  };

  return (
    <FormContainer size={Size.Medium}>
      <Box mt={16} mb={12} textAlign="center">
        <Box mb={2}>
          <IconExclamationCircleOrange />
        </Box>

        <Typography color="black19" variant={TypographyVariant.Header3}>
          {t("We're sorry, something on our end went wrong.", { ns: 'app' })}
        </Typography>

        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {t("Don't worry, your money's still here. Please retry", {
            depositOrWithdrawal,
          })}
        </Typography>
      </Box>

      <Button fullWidth onClick={handleDeposit}>
        <Box px={2} py={1.5}>
          <Typography color="white" variant={TypographyVariant.Paragraph2}>
            {t('Retry', {
              DepositOrWithdrawal,
            })}
          </Typography>
        </Box>
      </Button>
    </FormContainer>
  );
};
