import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button, Typography, TypographyVariant } from 'components/core';
import { FooterButton } from 'modules/2023-q3/allocation/components/footer/footer';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { OnboardingSteps } from 'modules/onboarding/v3-streamlined-onboarding/enums';

interface SubmitButtonProps {
  disabled?: boolean;
}

export const OnboardingButtons: React.FC<SubmitButtonProps> = ({
  disabled,
}) => {
  const onboardingContext = useOnboardingContext();

  return (
    <Box mt={5}>
      <Grid container spacing={2}>
        {onboardingContext.currentStep !==
          OnboardingSteps.BusinessStructure && (
          <Grid item xs>
            <FooterButton onClick={() => onboardingContext.previousStep()}>
              <ButtonContent iconStart={SupportedIcons.ArrowLeft}>
                Back
              </ButtonContent>
            </FooterButton>
          </Grid>
        )}

        <Grid item xs>
          <FooterButton disabled={disabled} type="submit">
            <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
              Continue
            </ButtonContent>
          </FooterButton>
        </Grid>
      </Grid>
    </Box>
  );
};
