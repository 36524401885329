import React, { useEffect, useState } from 'react';
import firebase from 'utils/firebase';
import { useParams } from 'react-router-dom';
import { Spinner } from 'components/core';
import {
  InvalidResetPasswordLink,
  ResetPasswordForm,
  TreasureLogoBox,
} from 'modules/no-auth';
import { RoutesPath } from 'routes/constants/routes-path';

export const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [invalidToken, setInvalidToken] = useState<boolean>();
  const { token } = useParams<{ token: string }>();

  const footerLinks = [
    {
      path: RoutesPath.noAuth.signIn.path,
      text: 'Sign In',
    },
  ];

  useEffect(() => {
    setLoading(true);

    firebase
      .auth()
      .verifyPasswordResetCode(token)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setInvalidToken(true);
      });
  }, [token]);

  if (loading) {
    return <Spinner />;
  }

  if (invalidToken) {
    return (
      <TreasureLogoBox headingText="Please Try Again">
        <InvalidResetPasswordLink />
      </TreasureLogoBox>
    );
  }

  return (
    <TreasureLogoBox
      headingText="Reset your password"
      footerLinks={footerLinks}
    >
      <ResetPasswordForm />
    </TreasureLogoBox>
  );
};
