import React from 'react';
import { Link } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { TreasureLogoHorizontal } from 'assets';

export const TreasureLogo = () => {
  return (
    <Link
      to={getVersionedPath({
        path: RoutesPath.pages.dashboard.path,
        version: 3,
      })}
    >
      <img
        src={TreasureLogoHorizontal}
        alt="Treasure logo"
        width={200}
        height={30}
      />
    </Link>
  );
};
