import React, { useEffect } from 'react';
import { useSendEvent, useTracking, TrackEventName } from 'modules/tracking';

// sentry
import { initSentry } from 'utils/sentryHelper';

interface TrackingProps {
  children: any;

  history: any;
}

export const Tracking: React.FC<TrackingProps> = ({ children, history }) => {
  const { sendEvent } = useSendEvent();

  const { Track } = useTracking<any>(
    {},
    {
      dispatch: ({ eventName, ...properties }) => {
        if (eventName) {
          sendEvent(eventName, properties);
        }
      },
      process: (ownTrackingData) => {
        if (ownTrackingData.page) {
          sendEvent(TrackEventName.Pageview, ownTrackingData);
        }
      },
    },
  );

  useEffect(() => {
    sendEvent(TrackEventName.LoadedApplication);
    initSentry(history);
  }, [history]);

  return <Track>{children}</Track>;
};
