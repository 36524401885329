import React from 'react';
import { Styled } from './progress-spinner.style';

interface ProgressSpinnerProps {
  color?: string;
}

export const ProgressSpinner: React.FC<ProgressSpinnerProps> = ({ color }) => {
  return <Styled.ProgressSpinner spinnercolor={color} size={20} />;
};
