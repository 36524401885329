import React from 'react';
import { Styled } from './notification-bar.style';

export const NotificationBar: React.FC = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <Styled.Bar px={10} py={1.5}>
      {children}
    </Styled.Bar>
  );
};
