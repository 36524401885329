import styled, { css } from 'styled-components';
import { InfoSharp, CheckCircleSharp, Error } from '@material-ui/icons/';

const baseIcon = css`
  height: var(--spacing-04);
  width: var(--spacing-04);
`;

export const SuccessIcon = styled(CheckCircleSharp)`
  ${baseIcon}

  color: var(--color-emerald);
`;

export const InfoIcon = styled(InfoSharp)`
  ${baseIcon}

  color:  var(--color-hanPurple);
`;

export const ErrorIcon = styled(Error)`
  ${baseIcon}

  color:  var(--color-amaranth);
`;

export const IconWrapper = styled.div`
  margin-right: var(--spacing-02);
`;

export const ToastMessageType = styled.span`
  font-size: var(--font-size-body-04);
  font-weight: var(--font-weight-light);
  line-height: 22px;
  text-transform: capitalize;
  display: block;
`;

export const ToastMessage = styled.p`
  font-size: var(--font-size-body-02);
  font-weight: var(--font-weight-regular);
  line-height: 16px;
  margin: 0;
`;

export const Styled = {
  InfoIcon,
  ErrorIcon,
  SuccessIcon,
  IconWrapper,
  ToastMessageType,
  ToastMessage,
};
