import React, { useState, useEffect } from 'react';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import useScript from 'react-script-hook';
import {
  usePlaidModal,
  PLAID_LINK_STABLE_URL,
  OpenPlaidModalErrorResponse,
} from 'modules/plaid/hooks/use-plaid-modal';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { useAccountsConnect } from 'hooks/banking/link/use-accounts-connect';

import { Interstitial } from './components/interstitial';
import { ManualACH } from './components/manual-ach';

export const BankDetails: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
    accountMetadata?: any;
    error?: any;
    plaidLinkSessionId?: any;
  }>({
    page: TrackPageName.OnboardingBankDetails,
  });

  const connectAccounts = useAccountsConnect();

  // loading the plaid library here and firing handleConnectBank
  // onload because we want the plaid modal to open on page load.
  // relying on the use-plaid-modal hook doesn't load the plaid
  // library before we invoke openPlaidModal here, resulting in errors.
  const [plaidScriptLoading, plaidScriptError] = useScript({
    src: PLAID_LINK_STABLE_URL,
    checkForExisting: true,
  });

  const onboardingContext = useOnboardingContext();
  const [plaidLoading, setPlaidLoading] = useState(true);
  const openPlaidModal = usePlaidModal();
  const [showInterstitial, toggleShowInterstitial] = useState(false);
  const [showManualBank, toggleManualBank] = useState(false);

  const onSubmit = () => {
    onboardingContext.nextStep();

    trackEvent({
      eventName: TrackEventName.ConnectBanksCompleted,
    });
  };

  const handleConnectBank = async (initialLoad: boolean = false) => {
    onboardingContext.showLoading(true);

    try {
      if (!initialLoad) {
        toggleShowInterstitial(false);
      }

      // leaving this event to compare apples/apples, we are basically clicking the button for the user
      trackEvent({
        eventName: TrackEventName.ClickAddNewAccount,
      });

      trackEvent({
        eventName: TrackEventName.PlaidModalOpened,
      });

      const plaidData = await openPlaidModal({
        withManualFlow: true,
        forceOauthPopup: true, // because the user is still onboarding, we don't want them to be redirected after oauth
      })
        .then((response: any) => {
          if (response) {
            const { plaidPublicToken, plaidMetaData } = response;
            const institutionId = plaidMetaData?.institution
              ?.institution_id as string;

            // save the plaid accounts
            connectAccounts.mutate(
              {
                public_token: plaidPublicToken,
                institutionId,
              },
              { onSuccess: () => onSubmit() },
            );

            trackEvent({
              eventName: TrackEventName.PlaidAccountConnectionSucceeded,
              accountMetadata: plaidMetaData,
            });
          } else {
            toggleShowInterstitial(true);
          }
        })
        .catch((error: Error) => {
          toggleShowInterstitial(true);
        });
    } catch (plaidErrorData) {
      toggleShowInterstitial(true);
      // dispatch toast error
      const { error, plaidMetaData } =
        plaidErrorData as OpenPlaidModalErrorResponse;

      trackEvent({
        eventName: TrackEventName.PlaidAccountConnectionFailure,
        error,
        plaidLinkSessionId: plaidMetaData?.link_session_id,
      });
    }
  };

  const handleManualBank = () => {
    toggleShowInterstitial(!showInterstitial);
    toggleManualBank(!showManualBank);

    trackEvent({
      eventName: TrackEventName.ManualACHFormDisplayed,
    });
  };

  useEffect(() => {
    if (showInterstitial) {
      onboardingContext.showLoading(false);

      trackEvent({
        eventName: TrackEventName.PlaidOrManualIntersitialDisplayed,
      });
    }
  }, [showInterstitial, trackEvent]);

  useEffect(() => {
    if (
      !plaidScriptError &&
      !plaidScriptLoading &&
      !showInterstitial &&
      !showManualBank
    ) {
      handleConnectBank(true);
    }
  }, [plaidScriptError, plaidScriptLoading, showInterstitial, showManualBank]);

  return (
    <>
      {showInterstitial && (
        <Interstitial
          onConnectBank={() => handleConnectBank()}
          onManualBank={() => handleManualBank()}
        />
      )}
      {showManualBank && <ManualACH onExit={() => handleManualBank()} />}
    </>
  );
};
