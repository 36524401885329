import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Redirect } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Documents } from 'modules/2023-q4/documents/documents';

export const DocumentsTransition = () => {
  return (
    <FeatureFlag
      disabled={<Redirect to={RoutesPath.pages.documents.path} />}
      enabled={<Documents />}
      flag={FeatureFlags.REACT_APP_2023_Q4_API_DOCUMENTS_ENABLED}
    />
  );
};
