import { FieldErrors } from 'react-hook-form';

export const formErrorFocus = (errors: FieldErrors, isDirty: boolean) => {
  if (isDirty) {
    return;
  }

  const elements = Object.keys(errors)
    .map((name) => document.getElementsByName(name)[0])
    .filter((el) => !!el);
  elements.sort((a, b) => {
    const aBounds = a.getBoundingClientRect();
    const bBounds = b.getBoundingClientRect();
    return aBounds.top - bBounds.top;
  });

  if (elements[0]) {
    elements[0].scrollIntoView();
    // handle fixed header
    window.scrollBy({ top: -150 });
  }
};
