import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useGetAffiliate = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Business);

  const getAffiliate = async ({ code }: { code: string }) => {
    const { data } = await treasureApi().get(`/affiliate/${code}`);

    return data;
  };

  return useMutation(getAffiliate);
};
