import React from 'react';
import { Box } from '@material-ui/core';
import { TypographyVariant } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { PortfolioState } from 'modules/portfolio/enums/portfolio-state';
import { Styled } from './portfolio-status.style';

export const PortfolioStatus = () => {
  const { data: business } = useBusiness();
  const { data: portfolios } = usePortfolios(business?.Id);

  const portfolioPending =
    portfolios?.portfolios[0]?.state === PortfolioState.Pending;

  if (portfolioPending) {
    return (
      <Box textAlign="right">
        <Styled.StatusBox display="inline-block" px={2} py={1}>
          <Styled.Status variant={TypographyVariant.SmallLabel2}>
            Pending
          </Styled.Status>
        </Styled.StatusBox>
      </Box>
    );
  }

  return null;
};
