import React from 'react';
import { Box, Divider } from '@material-ui/core';
import {
  AllocationChartGrid,
  AllocationChartGridProps,
} from 'modules/portfolio/allocation/components/allocation-modal/components/allocation-chart-grid/allocation-chart-grid';

interface WrapperProps extends AllocationChartGridProps {
  hideBottomBorder?: boolean;
}

export const AllocationChartGridWrapper: React.FC<WrapperProps> = ({
  alignItems,
  allocations,
  data,
  hideBottomBorder,
  hideChartDivider,
}) => {
  return (
    <Box px={2}>
      <AllocationChartGrid
        alignItems={alignItems}
        allocations={allocations}
        data={data}
        hideChartDivider={hideChartDivider}
      />
      {!hideBottomBorder && <Divider />}
    </Box>
  );
};
