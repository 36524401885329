import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useConnectQuickbooks = (): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  return useQuery(
    [ReactQueryKeys.QuickbooksConnect],
    async () => {
      const response = await treasureApi().get('/quickbooks/begin-oauth');

      return response.data;
    },
    {
      // force query to be triggered manually via refetch
      enabled: false,
    },
  );
};
