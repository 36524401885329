import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { colors, getColor } from 'styles/css-constants';

interface SVGBoxProps {
  cursor?: string;

  fillColor?: string;

  height?: number;

  maxWidth?: string;

  strokeColor?: string;

  width?: number;
}

const SVGBox = styled(
  ({ cursor, fillColor, height, strokeColor, width, ...rest }) => (
    <Box {...rest} />
  ),
)<SVGBoxProps>`
  svg {
    ${(props) => {
      if (props.height) {
        return css`
          height: ${props.height}px;
        `;
      }

      return null;
    }}

    ${(props) => {
      if (props.width) {
        return css`
          width: ${props.width}px;
        `;
      }

      return null;
    }}

    ${(props) => {
      if (props.maxWidth) {
        return css`
          max-width: ${props.maxWidth};
        `;
      }

      return null;
    }}
  }

  ${(props) =>
    props.fillColor &&
    css`
      path {
        fill: ${getColor(props.fillColor as keyof typeof colors)};
      }
    `}

  ${(props) => {
    if (props.strokeColor) {
      return css`
        path {
          stroke: ${getColor(props.strokeColor as keyof typeof colors)};
        }
      `;
    }

    return null;
  }}

  ${(props) => {
    if (props.cursor) {
      return css`
        cursor: ${props.cursor};
      `;
    }

    return null;
  }}
`;

export const Styled = { SVGBox };
