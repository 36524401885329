export enum ReactQueryMutationKeys {
  ConfirmMFACode = 'confirmMFACode',
  IsApiUser = 'IsApiUser',
  LoginRecord = 'loginRecord',
  RememberDevice = 'rememberDevice',
  SavePortfolio = 'savePortfolio',
  SendTreasureEvent = 'sendTreasureEvent',
  ValidateDevice = 'validDevice',
  ValidateRelationship = 'validateRelationship',
}
