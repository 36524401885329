import React from 'react';
import { i18n, useTranslation, Trans } from 'app/i18n';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { TreasureLogoHorizontal } from 'assets';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';
import Content from 'modules/page-errors/global-service-issue-notification/global-service-issue-notification.content.json';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';

const i18nServiceIssueNotificationContent =
  'i18nServiceIssueNotificationContent';
i18n.addResourceBundle('en', i18nServiceIssueNotificationContent, Content);

interface IProps {
  maintenanceMode: boolean;
}

export const GlobalServiceIssueShutdown: React.FC<IProps> = ({
  maintenanceMode,
}) => {
  const { signOut } = useSignOut();
  const { t } = useTranslation(i18nServiceIssueNotificationContent);

  const { trackEvent } = useTracking<{
    contactSupportReason?: TrackEventName;
    eventName: TrackEventName;
    section: TrackPageSection;
  }>({
    section: TrackPageSection.GlobalServiceIssuePage,
  });

  const handleContactCustomerSupport = () => {
    trackEvent({
      eventName: TrackEventName.ContactSupportClicked,
      contactSupportReason: TrackEventName.GlobalServiceIssue,
    });
  };

  return (
    <CenteredContainer>
      <Box p={2} textAlign="center">
        <Box textAlign="right">
          <Button
            bgColor="brown6"
            bgHoverColor="brown4b"
            onClick={() => signOut()}
          >
            <ButtonContent>
              <Typography
                color="black19"
                variant={TypographyVariant.Paragraph2}
              >
                Sign Out
              </Typography>
            </ButtonContent>
          </Button>
        </Box>

        <Box my={10}>
          <img
            src={TreasureLogoHorizontal}
            alt="Treasure logo"
            width={200}
            height={30}
          />
        </Box>

        <Typography variant={TypographyVariant.Body}>
          <Trans
            t={t}
            i18nKey={
              maintenanceMode
                ? 'Our service is currently down for scheduled maintenance'
                : 'Our service is experiencing an issue'
            }
            components={{
              supportLink: (
                <a
                  href="mailto:support@treasure.tech?subject=Contact Support"
                  onClick={handleContactCustomerSupport}
                >
                  support@treasure.tech
                </a>
              ),
            }}
          />
        </Typography>
      </Box>
    </CenteredContainer>
  );
};
