import { getColor } from 'styles/css-constants';
import styled, { css } from 'styled-components';
import { SwitchProps } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { CustomSwitchProps } from './custom-switch';

const SWITCH_HEIGHT = '16px';
const SWITCH_WIDTH = '16px';
type Props = SwitchProps & CustomSwitchProps;

const commonBackgroundTrackProperties = css<Props>`
  background-color: ${({ error, disabled }) => {
    if (error) {
      return getColor('amaranth');
    }
    if (disabled) {
      return getColor('gainsboro0');
    }
    return getColor('gainsboro0');
  }};
`;

const StyledSwitch = styled(Switch)<Props>`
  .MuiIconButton-root:hover {
    background-color: unset;
  }
  .MuiSwitch-switchBase.Mui-checked,
  .MuiSwitch-switchBase + .MuiSwitch-track {
    opacity: initial;
  }

  .MuiSwitch-switchBase + .MuiSwitch-track,
  .Mui-disabled + .MuiSwitch-track {
    ${commonBackgroundTrackProperties}
  }

  .MuiSwitch-track {
    height: ${SWITCH_HEIGHT};
    border-radius: 16px;
    margin-top: -2px;
    ${commonBackgroundTrackProperties}
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: ${({ error, disabled }) => {
      if (error) {
        return getColor('amaranth');
      }
      if (disabled) {
        return getColor('gainsboro0');
      }
      return getColor('hanPurple');
    }};
  }

  .MuiSwitch-switchBase {
    height: 37px;
  }
`;

const StyledActiveSwitchIcon = styled.div<Props>`
  height: ${SWITCH_HEIGHT};
  width: ${SWITCH_WIDTH};
  display: block;
  background-color: ${getColor('white')};
  border-radius: 50%;
  margin-left: 2px;
  margin-top: -1px;
  border: ${({ error, disabled }) => {
    if (error) {
      return `1px solid ${getColor('amaranth')}`;
    }
    if (disabled) {
      return `1px solid ${getColor('gainsboro0')}`;
    }
    return `1px solid ${getColor('hanPurple')}`;
  }};
  box-shadow: var(--box-shadow-04);
`;

const StyledSwitchIcon = styled.div<Props>`
  height: ${SWITCH_HEIGHT};
  width: ${SWITCH_WIDTH};
  display: block;
  background-color: ${getColor('white')};
  border-radius: 50%;
  margin-left: 3px;
  margin-top: -1px;
  border: ${({ error, disabled }) => {
    if (error) {
      return `1px solid ${getColor('amaranth')}`;
    }
    if (disabled) {
      return `1px solid ${getColor('hitGrey')}`;
    }
    return `1px solid ${getColor('hitGrey')}`;
  }};
  box-shadow: var(--box-shadow-04);
`;

export const Styled = {
  StyledActiveSwitchIcon,
  StyledSwitchIcon,
  StyledSwitch,
};
