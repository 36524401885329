declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

/**
 * Used to send info to google tag manager for tracking and conversion purposes
 * window.dataLayer is specific to GTM and is defined in our index.html file
 */
export const sendGoogleTagManagerEvent = (
  event: string,
  eventProps: any = {},
): void => {
  window.dataLayer.push({
    event,
    eventProps,
  });
};
