import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface LoginMFAState {
  passedMFA: boolean;
  resetPassedMFA: () => void;
  setPassedMFA: () => void;
}

export const useStoreLoginMFA = create<LoginMFAState>()(
  devtools(
    persist(
      (set) => ({
        passedMFA: false,
        resetPassedMFA: () => set((state) => ({ passedMFA: false })),
        setPassedMFA: () => set((state) => ({ passedMFA: true })),
      }),
      {
        name: 'login-mfa',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);
