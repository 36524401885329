import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Redirect } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Insights } from 'pages/v3-portal/insights/insights';

export const DisplayInsightsTab = () => {
  return (
    <FeatureFlag
      disabled={<Redirect to={RoutesPath.pages.home.path} />}
      enabled={<Insights />}
      flag={FeatureFlags.REACT_APP_2023_Q3_DISPLAY_INSIGHTS_ENABLED}
    />
  );
};
