import React from 'react';
import { Box } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from 'modules/2023-q3/components/button/button.style';

interface ButtonProps extends MuiButtonProps {
  borderRadius?: number;

  bottomFixed?: boolean;

  bgColor?: string;

  bgHoverColor?: string;

  cursor?: string;

  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  ...rest
}) => {
  const { trackEvent } = useTracking();

  // gets the button text
  const extractString = (obj: React.ReactNode): string => {
    if (obj === null || typeof obj === 'undefined') {
      return '';
    }

    if (typeof obj === 'string') {
      return obj;
    } else if (React.isValidElement(obj)) {
      return extractString(obj.props.children);
    } else if (Array.isArray(obj)) {
      return obj.map((e) => extractString(e)).join(' ');
    } else {
      return obj.toString();
    }
  };

  // if the button is disabled, an event won't fire if the button is clicked
  // adding a layer on top captures the click/event
  const handleParentClick = () => {
    if (rest.disabled) {
      trackEvent({
        clickText: extractString(children),
        disabled: true,
        eventName: TrackEventName.Click,
      });
    }
  };

  const handleButtonClick = () => {
    if (typeof onClick !== 'undefined') {
      onClick();
    }

    trackEvent({
      clickText: extractString(children),
      eventName: TrackEventName.Click,
    });
  };

  return (
    <Box
      display="inline-block"
      onClick={handleParentClick}
      width={rest.fullWidth ? '100%' : undefined}
    >
      <Styled.Button {...rest} disableRipple onClick={handleButtonClick}>
        {children}
      </Styled.Button>
    </Box>
  );
};
