import { createGlobalStyle, css } from 'styled-components';
import { getColor, getFontProperties } from './css-constants';

const spacing = (step: number): string => {
  return `${step * 8}px`;
};

const padIndex = (index: number): string => {
  return index.toString().padStart(2, '0');
};

export const CssVariables = createGlobalStyle`
  :root {
    /* TODO: Replace legacy colors */
    /* Legacy */
    --color-legacyBlue100: ${getColor('legacyBlue100')};
    --color-legacyBlue400: ${getColor('legacyBlue400')};
    --color-legacyGray: ${getColor('legacyGray')};
    --color-legacyGrayBorder: ${getColor('legacyGrayBorder')};
    --color-legacygreyDarkSelector: ${getColor('legacygreyDarkSelector')};
    --color-legacyGrayLight: ${getColor('legacyGrayLight')};
    /* TODO: Replace legacy colors */
    
    /* Whites */
    --color-white: ${getColor('white')};
    --color-whiteSmoke0: ${getColor('whiteSmoke0')};
    --color-whiteSmoke1: ${getColor('whiteSmoke1')};
    --color-whiteSmoke2: ${getColor('whiteSmoke2')};
    --color-whiteSmoke3: ${getColor('whiteSmoke3')};
    --color-whiteSmoke4: ${getColor('whiteSmoke4')};
    --color-wildSand: ${getColor('wildSand')};
    --color-gainsboro0: ${getColor('gainsboro0')};
    --color-gainsboro1: ${getColor('gainsboro1')};
    --color-gainsboro2: ${getColor('gainsboro2')};
    --color-albescentWhite: ${getColor('albescentWhite')};

    /* Reds */
    --color-amaranth: ${getColor('amaranth')};
    --color-cardinal: ${getColor('cardinal')};
    --color-cinnabar: ${getColor('cinnabar')};
    --color-radicalRed: ${getColor('radicalRed')};
    --color-torchRed: ${getColor('torchRed')};
    --color-darkRed: ${getColor('darkRed')};
    --color-flatRed: ${getColor('flatRed')};

    /* Blacks */
    --color-black: ${getColor('black')};
    --color-charcoal: ${getColor('charcoal')};
    --color-nero: ${getColor('nero')};

    /* Greys */
    --color-alabaster: ${getColor('alabaster')};
    --color-alto: ${getColor('alto')};
    --color-gallery: ${getColor('gallery')};
    --color-gallery2: ${getColor('gallery2')};
    --color-gothic: ${getColor('gothic')};
    --color-hitGrey: ${getColor('hitGrey')};
    --color-kokoda: ${getColor('kokoda')};
    --color-lightGrey: ${getColor('lightGrey')};
    --color-midGray: ${getColor('midGray')};
    --color-mineShaft: ${getColor('mineShaft')};
    --color-mischka: ${getColor('mischka')};
    --color-mischka2: ${getColor('mischka2')};
    --color-satinLinen: ${getColor('satinLinen')};
    --color-silver: ${getColor('silver')};
    --color-silverChalice: ${getColor('silverChalice')};
    --color-smoky: ${getColor('smoky')};
    --color-stack: ${getColor('stack')};
    --color-darkGrey: ${getColor('darkGrey')};
    --color-greyDark: ${getColor('greyDark')};
    --color-jumbo: ${getColor('jumbo')};
    --color-disabledGrey: ${getColor('disabledGrey')};
    --color-blackHaze: ${getColor('blackHaze')};
    --color-dustyGray: ${getColor('dustyGray')};

    /* Purples */
    --color-electricViolet: ${getColor('electricViolet')};
    --color-hanPurple: ${getColor('hanPurple')};
    --color-lavenderBlue: ${getColor('lavenderBlue')};
    --color-lightSlateBlue: ${getColor('lightSlateBlue')};
    --color-lavender: ${getColor('lavender')};
    --color-mediumSlateBlue: ${getColor('mediumSlateBlue')};
    --color-heliotrope: ${getColor('heliotrope')};
    --color-seance: ${getColor('seance')};

    /* Blues */
    --color-blue: ${getColor('blue')};
    --color-christalle: ${getColor('christalle')};
    --color-persianIndigo: ${getColor('persianIndigo')};
    --color-regalBlue: ${getColor('regalBlue')};
    --color-royalBlue: ${getColor('royalBlue')};
    --color-deepKoamaru: ${getColor('deepKoamaru')};
    --color-linkBlue: ${getColor('linkBlue')};
    --color-mirage: ${getColor('mirage')};
    --color-azureRadiance: ${getColor('azureRadiance')};

    /* Pinks */
    --color-bizarre: ${getColor('bizarre')};
    --color-darkPurple: ${getColor('darkPurple')};
    --color-fuchsia: ${getColor('fuchsia')};
    --color-plum: ${getColor('plum')};
    --color-salmon: ${getColor('salmon')};
    --color-sockeye: ${getColor('sockeye')};

    /* Greens */
    --color-dell: ${getColor('dell')};
    --color-emerald: ${getColor('emerald')};
    --color-turtle: ${getColor('turtle')};
    --color-grannyApple: ${getColor('grannyApple')};
    --color-salem: ${getColor('salem')};
    --color-silverTree: ${getColor('silverTree')};
    --color-stromboli: ${getColor('stromboli')};
    --color-burnham: ${getColor('burnham')};
    --color-holly: ${getColor('holly')};
    --color-edward: ${getColor('edward')};
    --color-mineralGreen: ${getColor('mineralGreen')};
    --color-mintGreen: ${getColor('mintGreen')};
    --color-powderAsh: ${getColor('powderAsh')};
    --color-mountainMeadow: ${getColor('mountainMeadow')};
    --color-mystic: ${getColor('mystic')};

    /* Oranges */
    --color-clementine: ${getColor('clementine')};
    --color-ecruWhite: ${getColor('ecruWhite')};
    --color-jaffa: ${getColor('jaffa')};
    --color-newOrleans: ${getColor('newOrleans')};

    /* Browns */
    --color-dallas: ${getColor('dallas')};
    --color-lividBrown: ${getColor('lividBrown')};

    /* 2023 Q2 DSM */
    --color-black19: ${getColor('black19')};

    --color-blue2: ${getColor('blue2')};
    --color-blue3: ${getColor('blue3')};
    --color-blue4: ${getColor('blue4')};

    --color-brown2: ${getColor('brown2')};
    --color-brown3: ${getColor('brown3')};
    --color-brown3a: ${getColor('brown3a')};
    --color-brown4a: ${getColor('brown4a')};
    --color-brown4b: ${getColor('brown4b')};
    --color-brown5: ${getColor('brown5')};
    --color-brown5b: ${getColor('brown5b')};
    --color-brown6: ${getColor('brown6')};
    --color-brown6a: ${getColor('brown6a')};
    --color-brown6b: ${getColor('brown6b')};
    --color-brown7: ${getColor('brown7')};

    --color-green0: ${getColor('green0')};
    --color-green0b: ${getColor('green0b')};
    --color-green1: ${getColor('green1')};
    --color-green2: ${getColor('green2')};
    --color-green3: ${getColor('green3')};
    --color-green4: ${getColor('green4')};
    --color-green5: ${getColor('green5')};
    --color-green6: ${getColor('green6')};
    --color-green7: ${getColor('green7')};

    --color-grey1: ${getColor('grey1')};
    --color-grey2: ${getColor('grey2')};
    --color-grey3: ${getColor('grey3')};
    --color-grey3b: ${getColor('grey3b')};
    --color-grey4: ${getColor('grey4')};
    --color-grey5: ${getColor('grey5')};
    --color-grey6: ${getColor('grey6')};
    --color-grey7: ${getColor('grey7')};

    --color-orange3: ${getColor('orange3')};

    --color-red2: ${getColor('red2')};

    --color-main: var(--color-hanPurple);

    /* Animation Delays */
    --animation-delay-01: 500ms;

    /* Buttons */
    --color-bg-button-main: var(--color-stromboli);
    --color-bg-button-focus: var(--color-edward);
    --color-bg-button-disabled: var(--color-disabledGrey);
    --color-bg-button-needs-attention-main: var(--color-amaranth);
    --color-bg-button-needs-attention-focus: var(--color-cardinal);

    /* Box Shadows */
    --box-shadow-01: 0 1px 4px rgba(0, 0, 0, 0.25);
    --box-shadow-02: 0 1px 4px rgba(0, 0, 0, 0.5);
    --box-shadow-03: 0 2px 4px rgba(0, 0, 0, 0.25);
    --box-shadow-04: 0 4px 4px rgba(0, 0, 0, 0.25);
    --box-shadow-05: 0 6px 4px rgba(0, 0, 0, 0.25);
    --box-shadow-06: 0 12px 19px rgba(0, 0, 0, 0.06);
    --box-shadow-07: 0 7px 7px rgba(0, 0, 0, 0.09);

    /* Drop Shadow */
    --drop-shadow-01: 0 1px 4px rgba(0, 0, 0, 0.25);
    --drop-shadow-02: 0 4px 4px rgba(0, 0, 0, 0.25);

    /* Z-Index */
    --z-index-header-dropdown: 200;
    --z-index-toast-level: 10000;

    /* Spacing */
    ${[...Array(10)].map((_, i) => {
      const spacingIndex = padIndex(i + 1);

      return css`--spacing-${spacingIndex}: ${spacing(i + 1)};`;
    })}

    /* Border Radius */
    --border-radius-01px: 1px;
    --border-radius-06px: 6px;
    --border-radius-12px: 12px;

    --border-radius-01: 2px;
    --border-radius-02: 3px;
    --border-radius-03: 4px;
    --border-radius-04: 5px;
    --border-radius-05: 6px;
    --border-radius-06: 7px;
    --border-radius-08: 8px; // TODO: matching numbers -.-

    /* Font Family */
    --font-family:  ${getFontProperties.fontFamily};

    /* Font Weight */
    ${Object.keys(getFontProperties.fontWeight).map((key: string) => {
      // @ts-ignore
      return css`--font-weight-${key}: ${getFontProperties.fontWeight[key]};
      `;
    })}

    /* Font Size Body */
    ${getFontProperties.fontBodyProperties.map(
      ({ fontSize }, index: number) => {
        return css`--font-size-body-${padIndex(index + 1)}: ${fontSize};
      `;
      },
    )}

    /* Font Size Heading */
    ${getFontProperties.fontHeadingProperties.map(
      ({ fontSize }, index: number) => {
        return css`--font-size-heading-${padIndex(index + 1)}: ${fontSize};
      `;
      },
    )}

    /* Widths */
    --width-nav-bar: 200px;

    /* White Label Colors */
    --color-header-navigation-text: ${getColor('headerNavigationText')};
    --color-primary: ${getColor('primary')};
  }
`;
