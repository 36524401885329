import React from 'react';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import { BusinessOwners as BusinessOwnersV1 } from './business-owners-v1';
import { BusinessOwners as BusinessOwnersV2 } from './business-owners-v2-exemption-status';

export const BusinessOwners = () => {
  return (
    <FeatureFlag
      disabled={<BusinessOwnersV1 />}
      enabled={<BusinessOwnersV2 />}
      flag={FeatureFlags.REACT_APP_2023_Q3_ONBOARDING_UPDATES_ENABLED}
    />
  );
};
