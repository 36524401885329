import {
  AllocationModalRowKeys,
  getRowHeader,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { cellText } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-typography/allocation-typography';

export const getLiquidity = ({ allocations }: { allocations: Allocation }) => {
  const data = [
    getRowHeader({
      rowKey: AllocationModalRowKeys.Liquidity,
      hasTooltip: true,
    }),
  ];

  Object.entries(allocations).map(([productKey, product]) => {
    return data.push(
      cellText(
        `${product.liquidityString} ${product.liquidityUnit}${
          product.liquidity > 1 ? 's' : ''
        }`,
      ),
    );
  });

  return data;
};
