import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { AxiosResponse } from 'axios';

export const useRedeemToken = () => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const redeemToken: (token: string) => Promise<AxiosResponse> = async (
    token,
  ) => {
    const { data } = await treasureApi().put('/onboarding/redeem-token', {
      token,
    });

    return data;
  };

  return { redeemToken };
};
