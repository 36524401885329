import styled from 'styled-components';
import { Divider as DividerAlias } from '@material-ui/core';
import { getColor } from 'styles/css-constants';

const Divider = styled(DividerAlias)`
  background-color: ${getColor('gainsboro0')};
  height: 1px;
`;

export const Styled = {
  Divider,
};
