export interface IFeatureFlags {
  REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED: string;
  REACT_APP_GATEWAY_ACTIVITIES_ENDPOINT_ENABLED: string;
  REACT_APP_GLOBAL_SERVICE_ISSUE_NOTIFICATION: string;
  REACT_APP_GLOBAL_SERVICE_ISSUE_SHUTDOWN: string;
  REACT_APP_HUBSPOT_TRACKING_ENABLED: string;
  REACT_APP_MIXPANEL_THROUGH_PUBSUB: string;
  REACT_APP_ONBOARDING_SKIP_EMAIL_VERIFICATION: string;
  REACT_APP_PORTFOLIO_ALLOCATION_DEMO_ENABLED: string;
  REACT_APP_PORTFOLIO_ALLOCATION_ENABLED: string;
  REACT_APP_PORTFOLIO_ALLOCATION_OTHER_ENABLED: string;
  REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED: string;
  REACT_APP_QUICKBOOKS_SYNC_ENABLED: string;
  REACT_APP_TRANSFER_DETAILED_AVAILABLE_BALANCE_ENABLED: string;
  REACT_APP_TRANSFER_LIQUIDITY_WITHDRAWAL_ENABLED: string;
  REACT_APP_2023_Q3_ADD_MANUAL_BANK_ACCOUNT_ENABLED: string;
  REACT_APP_2023_Q3_AGGREGATE_BALANCES_ON_DASH: string;
  REACT_APP_2023_Q3_ALLOCATION_ENABLED: string;
  REACT_APP_2023_Q3_BANKING_PAGE_ENABLED: string;
  REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED: string;
  REACT_APP_2023_Q3_DISPLAY_INSIGHTS_ENABLED: string;
  REACT_APP_2023_Q3_INTERNATIONAL_BUSINESS_ADDRESS_ENABLED: string;
  REACT_APP_2023_Q3_LOGIN_MFA_ENABLED: string;
  REACT_APP_2023_Q3_ONBOARDING_UPDATES_ENABLED: string;
  REACT_APP_2023_Q4_ONBOARDING_ENABLED: string;
  REACT_APP_2023_Q4_GROWTH_PROMO_ENABLED: string;
  REACT_APP_2023_Q4_WHITELABEL_ENABLED: string;
  REACT_APP_2023_Q4_API_DOCUMENTS_ENABLED: string;
  REACT_APP_2023_Q4_USER_ROLES_ENABLED: string;
  REACT_APP_2024_Q1_ALLOCATION_AMOUNT: string;
  REACT_APP_2024_Q1_MAINTENANCE_MODE: string;
  REACT_APP_2024_Q1_SCHEDULED_TRANSFERS: string;
  REACT_APP_2024_Q2_TP_STATEMENTS_ENABLED: string;
  REACT_APP_2024_Q2_SHOW_FEES: string;
  REACT_APP_2024_Q2_ALLOCATION_BREAKDOWN_BY_PRODUCT: string;
  REACT_APP_2024_Q3_GRASSHOPPER_SUNSET: string;
  REACT_APP_2024_Q4_APEX_ACH: string;
}

export enum FeatureFlags {
  REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED,
  REACT_APP_GATEWAY_ACTIVITIES_ENDPOINT_ENABLED,
  REACT_APP_GLOBAL_SERVICE_ISSUE_NOTIFICATION,
  REACT_APP_GLOBAL_SERVICE_ISSUE_SHUTDOWN,
  REACT_APP_HUBSPOT_TRACKING_ENABLED,
  REACT_APP_MIXPANEL_THROUGH_PUBSUB,
  REACT_APP_ONBOARDING_SKIP_EMAIL_VERIFICATION,
  REACT_APP_PORTFOLIO_ALLOCATION_DEMO_ENABLED,
  REACT_APP_PORTFOLIO_ALLOCATION_ENABLED,
  REACT_APP_PORTFOLIO_ALLOCATION_OTHER_ENABLED,
  REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED,
  REACT_APP_QUICKBOOKS_SYNC_ENABLED,
  REACT_APP_TRANSFER_DETAILED_AVAILABLE_BALANCE_ENABLED,
  REACT_APP_TRANSFER_LIQUIDITY_WITHDRAWAL_ENABLED,
  REACT_APP_2023_Q3_ADD_MANUAL_BANK_ACCOUNT_ENABLED,
  REACT_APP_2023_Q3_AGGREGATE_BALANCES_ON_DASH,
  REACT_APP_2023_Q3_ALLOCATION_ENABLED,
  REACT_APP_2023_Q3_BANKING_PAGE_ENABLED,
  REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED,
  REACT_APP_2023_Q3_DISPLAY_INSIGHTS_ENABLED,
  REACT_APP_2023_Q3_INTERNATIONAL_BUSINESS_ADDRESS_ENABLED,
  REACT_APP_2023_Q3_LOGIN_MFA_ENABLED,
  REACT_APP_2023_Q3_ONBOARDING_UPDATES_ENABLED,
  REACT_APP_2023_Q4_ONBOARDING_ENABLED,
  REACT_APP_2023_Q4_GROWTH_PROMO_ENABLED,
  REACT_APP_2023_Q4_WHITELABEL_ENABLED,
  REACT_APP_2023_Q4_API_DOCUMENTS_ENABLED,
  REACT_APP_2023_Q4_USER_ROLES_ENABLED,
  REACT_APP_2024_Q1_ALLOCATION_AMOUNT,
  REACT_APP_2024_Q1_MAINTENANCE_MODE,
  REACT_APP_2024_Q1_SCHEDULED_TRANSFERS,
  REACT_APP_2024_Q2_TP_STATEMENTS_ENABLED,
  REACT_APP_2024_Q2_SHOW_FEES,
  REACT_APP_2024_Q2_ALLOCATION_BREAKDOWN_BY_PRODUCT,
  REACT_APP_2024_Q3_GRASSHOPPER_SUNSET,
  REACT_APP_2024_Q4_APEX_ACH,
}
