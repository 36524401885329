import React, { useEffect, useState } from 'react';
import { useConnectedAccounts } from 'modules/onboarding/connect-accounts/hooks/get-connected-accounts';
import { ConnectedBankAccount } from 'types';
import { Box, Divider } from '@material-ui/core';
import {
  Button as OldButton,
  Spinner,
  Typography,
  TypographyVariant,
} from 'components/core';
import useScript from 'react-script-hook';
import { Styled as PlaidLinkStyled } from 'modules/plaid/components/plaid-link.style';
import {
  usePlaidModal,
  OpenPlaidModalErrorResponse,
  PLAID_LINK_STABLE_URL,
} from 'modules/plaid/hooks/use-plaid-modal';
import { BankAccounts } from 'modules/onboarding/bank-accounts/bank-accounts';
import { useExcludeAccountFromAnalysis } from 'hooks/banking/link/use-exclude-account-from-analysis';
import { useAccountsConnect } from 'hooks/banking/link/use-accounts-connect';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { DashboardContainer } from 'layouts/v3-portal-layout/components';
import { Styled } from './connect-bank-accounts.style';

export type BankAccount = {
  balance: string;
  excludeFromAnalysis: boolean;
  id: string;
  logo: string;
  mask: string;
  name: string;
  onDeselectButtonClick?: (deselect: boolean) => void;
};

export type Bank = {
  id: string;
  name: string;
  accounts: BankAccount[];
};

export const ConnectBankAccounts: React.FC = () => {
  const plaidScriptAlreadyExists: HTMLScriptElement | null =
    document.querySelector(`script[src="${PLAID_LINK_STABLE_URL}"]`);

  const { trackEvent } = useTracking();

  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState(true);
  const [initialLoadingCheck, setInitialLoadingCheck] = useState({
    accounts: false,
  });
  const [hasPlaidModalBeenOpened, setHasPlaidModalBeenOpened] = useState(false);
  const [letUseScriptOnLoad, setLetUseScriptOnLoad] = useState(false);

  const { data: accounts = [], isLoading } = useConnectedAccounts();
  const excludeAccountFromAnalysis = useExcludeAccountFromAnalysis();
  const connectAccounts = useAccountsConnect();

  const handleConnectBank = async () => {
    trackEvent({
      eventName: TrackEventName.ConnectFlowStarted,
    });

    try {
      setHasPlaidModalBeenOpened(true);
      const plaidData = await openPlaidModal();
      if (plaidData) {
        const { plaidPublicToken, plaidMetaData } = plaidData;
        const institutionId = plaidMetaData?.institution
          ?.institution_id as string;

        connectAccounts.mutate({
          public_token: plaidPublicToken,
          institutionId,
        });

        trackEvent({
          eventName: TrackEventName.PlaidAccountConnectionSucceeded,
          accountMetadata: plaidMetaData,
        });
      }
    } catch (plaidErrorData) {
      const { error, plaidMetaData } =
        plaidErrorData as OpenPlaidModalErrorResponse;
      trackEvent({
        eventName: TrackEventName.PlaidAccountConnectionFailure,
        error,
        plaidLinkSessionId: plaidMetaData?.link_session_id,
      });
    }
  };

  // loading the plaid library here and firing handleConnectBank
  // onload because we want the plaid modal to open on page load.
  // relying on the use-plaid-modal hook doesn't load the plaid
  // library before we invoke openPlaidModal here, resulting in errors.
  useScript({
    src: PLAID_LINK_STABLE_URL,
    checkForExisting: true,
    onload: () => handleConnectBank(),
  });

  const openPlaidModal = usePlaidModal(); // needs to be called after useScript

  useEffect(() => {
    // display accounts by bank
    const banksWithAccounts: Bank[] = [];
    const banksArray = Array.from(
      new Set(
        accounts.map(
          (account: ConnectedBankAccount) => account.institution.name,
        ),
      ),
    );

    // sort bank names
    banksArray.sort((a: string, b: string) => {
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    });

    banksArray.map((bankName) => {
      const bankAccounts = accounts.filter(
        (account: ConnectedBankAccount) =>
          account.institution.name === bankName,
      );

      // sort accounts by Mask if it exists on both accounts
      bankAccounts.sort((a, b) => {
        if (a.Mask && b.Mask) {
          return a.Mask.localeCompare(b.Mask);
        } else {
          return 0;
        }
      });

      // TODO: we could combine this mapping from handleRetrieveBankAccounts if back end returns the same object
      // (capital vs lowercase variables)
      const bank: Bank = {
        id: bankName,
        name: bankName,
        accounts: bankAccounts.map(
          (account: ConnectedBankAccount): BankAccount => ({
            balance: account.Balance,
            excludeFromAnalysis: account.excludeFromAnalysis,
            id: account.AccountId,
            logo: account.institution.logo,
            mask: account.Mask,
            name: account.Name,
            onDeselectButtonClick: (excludeFromAnalysis) => {
              const EventName = excludeFromAnalysis
                ? TrackEventName.BankAccountExcluded
                : TrackEventName.BankAccountIncluded;

              trackEvent({
                eventName: EventName,
              });

              excludeAccountFromAnalysis.mutate({
                accountId: account.AccountId,
                excludeFromAnalysis,
              });
            },
          }),
        ),
      };

      banksWithAccounts.push(bank);

      return null;
    });

    setBanks(banksWithAccounts);

    setInitialLoadingCheck((initialLoadingCheckPreviousState) => ({
      ...initialLoadingCheckPreviousState,
      accounts: true,
    }));
  }, [accounts, trackEvent]);

  useEffect(() => {
    if (initialLoadingCheck.accounts) {
      setLoading(false);
    }
  }, [initialLoadingCheck]);

  useEffect(() => {
    setLetUseScriptOnLoad(plaidScriptAlreadyExists === null);
  }, []);

  useEffect(() => {
    let openPlaidModalDelay!: ReturnType<typeof setTimeout>; // ! Definite Assignment Assertion

    clearTimeout(openPlaidModalDelay);

    // only launch handleConnectBank if the plaid script isn't loading and the modal has not already been opened
    // can't rely on loading from useScript because useScript returns true if the script already exists
    // and doesn't trigger the onload to call handleConnectBank
    if (
      plaidScriptAlreadyExists !== null &&
      !hasPlaidModalBeenOpened &&
      !letUseScriptOnLoad
    ) {
      openPlaidModalDelay = setTimeout(() => {
        handleConnectBank();
      });
    }
  }, [hasPlaidModalBeenOpened, letUseScriptOnLoad, plaidScriptAlreadyExists]);

  const pageContent = (
    <>
      <Box p={2}>
        <Typography color="nero" variant={TypographyVariant.Header}>
          Connect Bank
        </Typography>
      </Box>

      <Divider />

      <Box p={2}>
        <Typography color="nero" variant={TypographyVariant.BodyBold}>
          Link all operational accounts for powerful, accurate cash flow
          insights
        </Typography>

        <br />

        <Typography color="nero" variant={TypographyVariant.SmallText}>
          Linking accounts does not authorize us to transact. Linking is for
          analysis only.
        </Typography>
      </Box>

      <Box width={300} mx="auto" mt={3} mb={5}>
        <FeatureFlag
          disabled={
            <PlaidLinkStyled.PlaidLinkBox
              onClick={handleConnectBank}
              mt={3}
              display="flex"
              alignItems="center"
            >
              <OldButton variant="contained" width={100}>
                <Typography
                  color="white"
                  variant={TypographyVariant.BodySemiBold}
                >
                  <Box display="inline-block" mr={0.25}>
                    <Styled.Add fontSize="small" />
                  </Box>{' '}
                  Add account
                </Typography>
              </OldButton>
            </PlaidLinkStyled.PlaidLinkBox>
          }
          enabled={
            <>
              <Box mb={2}>
                <PlaidLinkStyled.PlaidLinkBox
                  onClick={handleConnectBank}
                  mt={3}
                >
                  <Button fullWidth>
                    <ButtonContent iconStart={SupportedIcons.Add}>
                      Add account via Plaid
                    </ButtonContent>
                  </Button>
                </PlaidLinkStyled.PlaidLinkBox>
              </Box>

              <Link to={RoutesPath.pages.connectBankAccountManual.path}>
                <Button fullWidth>
                  <ButtonContent iconStart={SupportedIcons.Add}>
                    Add bank account manually
                  </ButtonContent>
                </Button>
              </Link>
            </>
          }
          flag={FeatureFlags.REACT_APP_2023_Q3_ADD_MANUAL_BANK_ACCOUNT_ENABLED}
        />
      </Box>

      {banks.length > 0 && (
        <>
          <Divider />

          <Box p={2}>
            {isLoading || connectAccounts.isLoading || loading ? (
              <Spinner />
            ) : (
              <>
                <Box mb={3}>
                  <Typography color="nero" variant={TypographyVariant.BodyBold}>
                    Connected Accounts
                  </Typography>

                  <br />

                  <Typography color="nero" variant={TypographyVariant.Body}>
                    Treasure is displaying Insights data for the accounts
                    selected below.
                    <br />
                    De-select to remove an account’s data from Insights.
                  </Typography>
                </Box>

                {banks.map((bank) => (
                  <BankAccounts
                    key={bank.id}
                    name={bank.name}
                    accounts={bank.accounts}
                  />
                ))}
              </>
            )}
          </Box>
        </>
      )}
    </>
  );

  return (
    <MobileView mobile={<DashboardContainer>{pageContent}</DashboardContainer>}>
      <Styled.Container mt={5} mx="auto">
        {pageContent}
      </Styled.Container>
    </MobileView>
  );
};
