import styled, { css } from 'styled-components';
import {
  Stepper,
  StepConnector as StepConnectorAlias,
} from '@material-ui/core';

interface VerticalStepperProps {
  $color?: string;
}

const VerticalStepper = styled(Stepper)<VerticalStepperProps>`
  background-color: var(--color-white);
  padding: 20px 0;

  &.MuiStepper- {
    &root {
      .MuiStepConnector-lineVertical,
      .MuiStepContent-root {
        border-color: ${(props) => {
          const color = props.$color || 'hanPurple';
          return css`
                  var(--color-${color})
                `;
        }};
        border-width: 2px;
        left: -1px;
        position: relative;
      }
      .MuiStepLabel-iconContainer {
        padding-right: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        svg {
          &.MuiStepIcon- {
            &root {
              color: var(--color-lightGrey);
              circle {
                r: 8px;
              }
            }
            &active,
            &completed {
              color: ${(props) => {
                const color = props.$color || 'hanPurple';
                return css`
                  var(--color-${color})
                `;
              }};
              circle: {
                r: 10px;
              }
            }
          }
        }
      }
      .MuiStepLabel-labelContainer {
        p {
          display: inline-block;
          padding-right: 14px;

          &.action {
            color: var(--color-smoky);
          }
        }
      }
    }
  }
`;

const VerticalConnector = styled(StepConnectorAlias)<VerticalStepperProps>`
  &.MuiStepConnector- {
    &active,
    &completed {
      .MuiStepConnector-line {
        border-color: ${(props) => {
          const color = props.$color || 'hanPurple';
          return css`
                  var(--color-${color})
                `;
        }};
        border-width: 2px;
        left: -1px;
        position: relative;
      }
    }
  }
`;

export const Styled = {
  VerticalStepper,
  VerticalConnector,
};
