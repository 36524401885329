import React, { useEffect, useState } from 'react';
import { Typography, TypographyVariant } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { useBusinessMembers } from 'hooks/business/use-business-members';
import { useUser } from 'hooks/auth/use-user';
import { Styled } from './greeting.style';

export const Greeting: React.FC = () => {
  const [displayName, setDisplayName] = useState();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: businessMembers, isLoading: isLoadingBusinessMembers } =
    useBusinessMembers();
  const { data: user, isLoading: isLoadingUser } = useUser(business?.Id);

  // TODO: api should return user name
  // TODO: add loading to this component before name is available?
  useEffect(() => {
    if (user && businessMembers) {
      const currentUser = businessMembers.find(
        (member: { email: string }) => member.email === user.email,
      );

      setDisplayName(currentUser?.firstName);
    }
  }, [user, businessMembers]);

  return (
    <>
      {displayName && (
        <Styled.Capitalize variant={TypographyVariant.Header3}>
          Greetings {displayName}
        </Styled.Capitalize>
      )}

      <Typography color="grey4" variant={TypographyVariant.Paragraph2}>
        Here are the latest updates for {business?.DBA || business?.LegalName}
      </Typography>
    </>
  );
};
