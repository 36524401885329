import React from 'react';
import { SelectProps } from '@material-ui/core';
import { Autocomplete } from 'components/core/forms';
import { AutocompleteVariants } from 'components/core/forms/autocomplete/autocomplete';
import {
  factoryFormFieldComponent,
  FormFieldSpecs,
} from '../utils/factory-form-field-component';

export type OptionType = { label: string; description?: string; value: string };
type SelectOnChange = SelectProps['onChange'];

export type SelectFormFieldSpecs = FormFieldSpecs<{
  options?: OptionType[];
  onAfterChange?: (selected: any) => void;
  leftLabel?: boolean;
  labelVariant?: number;
  disabled?: boolean;
  autocompleteVariant?: AutocompleteVariants;
  value?: string;
  disabledOptions?: string[];
}>;

export const SelectFieldController =
  factoryFormFieldComponent<SelectFormFieldSpecs>(
    ({
      label,
      leftLabel,
      labelVariant,
      placeholder,
      validations,
      options,
      value,
      onAfterChange,
      onBlur,
      onChange,
      error,
      disabled,
      autocompleteVariant,
      disabledOptions,
    }) => {
      const handleChange: SelectOnChange = (evt) => {
        onChange(evt);
        onAfterChange?.(evt);
      };

      return (
        <Autocomplete
          options={options}
          disabledOptions={disabledOptions}
          label={label}
          labelVariant={labelVariant}
          leftLabel={leftLabel}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          helperText={error?.message}
          error={!!error}
          placeholder={placeholder}
          optional={!validations?.required}
          disableClearable
          disabled={disabled}
          autocompleteVariant={autocompleteVariant}
        />
      );
    },
    { componentName: 'SelectFieldController' },
  );
