import React from 'react';
import PropTypes from 'prop-types';
import { NumberDisplay, NumberDisplayVariant } from '.';

export enum CurrencyVariant {
  Full = 'Full',
  NearestDollar = 'NearestDollar',
  Truncated = 'Truncated',
  TruncatedWithoutDecimals = 'TruncatedWithoutDecimals',
}

interface CurrencyProps {
  decimalScale?: number;

  /** the number to display as currency */
  number: number;

  /** function to allow different rendering of the output */
  renderText?: (formattedValue: string) => React.ReactNode;

  variant: CurrencyVariant;
}

const Currency: React.FC<CurrencyProps> = ({
  decimalScale,
  number,
  renderText,
  variant,
}) => {
  const largeNumberFormatter = (number: number) => {
    const lookup = [
      { value: 1, symbol: '', decimalScale: 2 }, // hundreds
      { value: 1e3, symbol: 'K', decimalScale: 1 }, // thousands
      { value: 1e6, symbol: 'M', decimalScale: 1 }, // millions
      { value: 1e9, symbol: 'B', decimalScale: 1 }, // billions
      { value: 1e12, symbol: 'T', decimalScale: 1 }, // trillions
    ];

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

    let i = 0;
    for (i = lookup.length - 1; i > 0; i--) {
      if (Math.abs(number) >= lookup[i].value) {
        break;
      }
    }

    // format the number using the highest symbol and remove trailing 0s
    const formattedNumber = (number / lookup[i].value)
      .toFixed(lookup[i].decimalScale)
      .replace(rx, '$1');

    return {
      ...lookup[i],
      value: formattedNumber,
    };
  };

  const getDecimalAndSuffix = (
    number: number,
    variant: CurrencyVariant,
  ): { currencyAmount: number; getDecimalScale: number; suffix: string } => {
    switch (variant) {
      case CurrencyVariant.Full:
        return {
          currencyAmount: number,
          getDecimalScale: 2,
          suffix: '',
        };
      case CurrencyVariant.NearestDollar:
        return {
          currencyAmount: number,
          getDecimalScale: 0,
          suffix: '',
        };
      case CurrencyVariant.Truncated: {
        const formattedNumber = largeNumberFormatter(number);

        return {
          currencyAmount: +formattedNumber.value,
          getDecimalScale: decimalScale ?? formattedNumber.decimalScale,
          suffix: formattedNumber.symbol,
        };
      }
      case CurrencyVariant.TruncatedWithoutDecimals: {
        const formattedNumber = largeNumberFormatter(number);

        return {
          currencyAmount: +formattedNumber.value,
          getDecimalScale: 0,
          suffix: formattedNumber.symbol,
        };
      }
    }
  };

  const { currencyAmount, getDecimalScale, suffix } = getDecimalAndSuffix(
    number,
    variant,
  );

  return (
    <NumberDisplay
      decimalScale={getDecimalScale}
      number={currencyAmount}
      variant={NumberDisplayVariant.currency}
      suffix={suffix}
      renderText={renderText}
    />
  );
};

Currency.propTypes = {
  number: PropTypes.number.isRequired,
  renderText: PropTypes.func,
  variant: PropTypes.oneOf([...Object.values(CurrencyVariant)]).isRequired,
};

export { Currency };
