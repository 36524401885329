import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { Text } from 'components/core';
import { colors, getColor } from 'styles/css-constants';

interface StyledAllocation {
  numberOfDisplayedAccounts: number;
  width: number;
}

interface StyledAllocationBar {
  size?: string;
}

interface StyledAllocationText {
  backgroundColor?: string;
}

const AllocationBar = styled.div<StyledAllocationBar>`
  height: ${(props) => (props.size === 'large' ? '18px' : '13px')};
`;

const AllocationInformation = styled(Box)`
  border-left: 1px solid;
  position: relative;
  width: max-content;
`;

const AllocationText = styled(Text)<StyledAllocationText>`
  background-color: ${(props) => {
    if (props.backgroundColor)
      return getColor(props.backgroundColor as keyof typeof colors);

    return css`var(--color-white)`;
  }};

  width: inherit;

  &:first-child {
    font-weight: 700;
  }
`;

const Allocation = styled(
  ({ numberOfDisplayedAccounts, forwardRef, width, ...rest }) => (
    <Grid ref={forwardRef} {...rest} />
  ),
)<StyledAllocation>`
  width: ${(props) => props.width}%;

  &:nth-child(1) {
    ${AllocationText}:first-child {
      color: var(--color-mirage);
    }

    ${AllocationBar} {
      background-color: var(--color-mirage);
    }

    ${AllocationInformation} {
      border-color: var(--color-mirage);
      z-index: 100;
    }
  }

  ${(props) =>
    props.numberOfDisplayedAccounts === 5 &&
    css`
      &:nth-child(2) {
        ${AllocationText}:first-child {
          color: var(--color-powderAsh);
        }

        ${AllocationBar} {
          background-color: var(--color-powderAsh);
        }

        ${AllocationInformation} {
          border-color: var(--color-powderAsh);
          z-index: 90;
        }
      }
    `}

  &:nth-child(${(props) => (props.numberOfDisplayedAccounts === 5 ? 3 : 2)}) {
    ${AllocationText}:first-child {
      color: var(--color-heliotrope);
    }

    ${AllocationBar} {
      background-color: var(--color-heliotrope);
    }

    ${AllocationInformation} {
      border-color: var(--color-heliotrope);
      z-index: 80;
    }
  }

  &:nth-child(${(props) => (props.numberOfDisplayedAccounts === 5 ? 4 : 3)}) {
    ${AllocationText}:first-child {
      color: var(--color-stromboli);
    }

    ${AllocationBar} {
      background-color: var(--color-stromboli);
    }

    ${AllocationInformation} {
      border-color: var(--color-stromboli);
      z-index: 70;
    }
  }

  &:nth-child(${(props) => (props.numberOfDisplayedAccounts === 5 ? 5 : 4)}) {
    ${AllocationText}:first-child {
      color: var(--color-silverTree);
    }

    ${AllocationBar} {
      background-color: var(--color-silverTree);
    }

    ${AllocationInformation} {
      border-color: var(--color-silverTree);
      z-index: 60;
    }
`;

export const Styled = {
  Allocation,
  AllocationBar,
  AllocationInformation,
  AllocationText,
};
