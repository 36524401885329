import React, { useState } from 'react';
import { Box, Hidden } from '@material-ui/core';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { UserPermissionsRequired } from 'modules/2023-q4/rbac/components/user-permissions-required/user-permissions-required';
import { AppMenuButton } from './components/app-menu-button/app-menu-button';
import { DepositWithdrawalButton } from './components/deposit-withdrawal-button/deposit-withdrawal-button';
import { AllocateButton } from './components/allocate-button/allocate-button';

export interface IHeaderButtons {
  appMenuButton: boolean;
  depositWithdrawalButton: boolean;
}

export const HeaderButtons = () => {
  const [menuStatuses, setMenuStatuses] = useState<IHeaderButtons>({
    appMenuButton: false,
    depositWithdrawalButton: false,
  });

  const depositWithdrawalButtonComponent = (
    <DepositWithdrawalButton
      menuStatuses={menuStatuses}
      setMenuStatuses={setMenuStatuses}
    />
  );

  return (
    <>
      <FeatureFlag
        disabled={<>{depositWithdrawalButtonComponent}</>}
        enabled={
          <UserPermissionsRequired roles={[UserRole.ADMIN]}>
            {depositWithdrawalButtonComponent}
          </UserPermissionsRequired>
        }
        flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
      />

      <FeatureFlag
        enabled={
          <Hidden mdDown>
            <Box ml={2}>
              <FeatureFlag
                disabled={<AllocateButton />}
                enabled={
                  <UserPermissionsRequired roles={[UserRole.ADMIN]}>
                    <AllocateButton />
                  </UserPermissionsRequired>
                }
                flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
              />
            </Box>
          </Hidden>
        }
        flag={FeatureFlags.REACT_APP_2023_Q3_ALLOCATION_ENABLED}
      />

      <Box ml={2}>
        <AppMenuButton
          menuStatuses={menuStatuses}
          setMenuStatuses={setMenuStatuses}
        />
      </Box>
    </>
  );
};
