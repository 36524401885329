import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Typography } from 'components/core';

const NavigationTypography = styled(Typography)`
  font-weight: 700;
`;

const NavigationLink = styled(NavLink)`
  ${NavigationTypography} {
    border-bottom: 5px solid transparent;
    display: inline-block;
  }

  &.selected {
    ${NavigationTypography} {
      border-color: var(--color-stromboli);
      color: var(--color-stromboli);
    }
  }
`;

export const Styled = {
  NavigationLink,
  NavigationTypography,
};
