import React from 'react';
import { Box } from '@material-ui/core';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Styled } from './centered-container.style';

export const CenteredContainer: React.FC = ({ children }) => {
  return (
    <FeatureFlag
      disabled={
        <Styled.CenteredContainer mx="auto">
          <Box mx={3}>{children}</Box>
        </Styled.CenteredContainer>
      }
      enabled={
        <Styled.CenteredContainer mx="auto">
          <Box mx={{ xs: 2, sm: 3 }}>{children}</Box>
        </Styled.CenteredContainer>
      }
      flag={FeatureFlags.REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED}
    />
  );
};
