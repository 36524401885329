import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, GridProps } from '@material-ui/core';
import { Text } from 'components/core';
import { ReactComponent as LiquidSenseLogo } from 'assets/logos/liquid_sense.svg';
import { Styled } from './grid-card.style';

export type GridCardProps = GridProps & {
  /** content of the Grid Card */
  children?: React.ReactNode;

  /** is a Liquid Sense Card */
  isLiquidSense?: boolean;

  /** title of the Grid Card */
  title?: string;

  /** chart subtitles of the Grid Card */
  headerContent?: React.ReactNode;
};

const GridCard: React.FC<GridCardProps> = ({
  children,
  isLiquidSense,
  title,
  headerContent,
  ...otherGridProps
}) => {
  return (
    <Styled.Grid item {...otherGridProps}>
      {/* TODO: this could be its own component */}
      {/* safari spacing issue: https://github.com/mui-org/material-ui/issues/17142 */}
      {isLiquidSense && (
        <Styled.LiquidSense>
          <Box p={1} ml={0.5} pl={{ xs: 3, sm: 8 }}>
            <Grid container spacing={0}>
              <Grid item>
                <LiquidSenseLogo />
              </Grid>
              <Grid item>
                <Box mt={0.8} mx={1}>
                  <Text color="gothic" variant={2}>
                    AI-powered recommendations
                  </Text>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Styled.LiquidSense>
      )}
      <Styled.Paper>
        {title && (
          <Box
            pt={2}
            pl={2}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Styled.Heading variant={3}>{title}</Styled.Heading>

            {headerContent}
          </Box>
        )}

        {children}
      </Styled.Paper>
    </Styled.Grid>
  );
};

GridCard.propTypes = {
  children: PropTypes.node,
  headerContent: PropTypes.node,
  isLiquidSense: PropTypes.bool,
  title: PropTypes.string,
  xs: PropTypes.oneOf([
    'auto',
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    undefined,
  ]), // TODO: add boolean
};

export { GridCard };
