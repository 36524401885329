import React from 'react';
import { ProductAllocationProducts } from 'modules/2023-q3/allocation/interface/product-allocation';
import { Percentage } from 'components/core';

const treasureFee = 0.35;

const getVolatility = (product: ProductAllocationProducts) => {
  switch (product) {
    case ProductAllocationProducts.ManagedMoneyMarket:
      return 0.125;
    case ProductAllocationProducts.ManagedTreasuries:
      return 0.25;
    case ProductAllocationProducts.ManagedIncome:
      return 2;
  }

  return 0;
};

export const getReturnRangeText = (
  product: ProductAllocationProducts,
  rate: number,
) => {
  const lower = rate * 100 - treasureFee - 2 * getVolatility(product);
  const upper = rate * 100 - treasureFee + 2 * getVolatility(product);

  return (
    <>
      <Percentage decimalScale={2} number={lower} showExtremePrecision /> -{' '}
      <Percentage decimalScale={2} number={upper} showExtremePrecision />
    </>
  );
};
