import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal } from '@material-ui/core';
import { DashboardType } from 'enums';
import { useTracking, TrackEventName } from 'modules/tracking';
import { IndicatorProps, IndicatorType, TopIndicator } from './top-indicator';
import { TopIndicators } from './top-indicators';
import {
  IdleCashSubIndicator,
  LiquidSense,
  NetCashFlowSubIndicator,
  RunwaySubIndicator,
  TotalCashSubIndicator,
  TreasureReserveSubIndicator,
} from './sub-indicators';
import { Styled } from './dashboard-top-indicators.style';

export interface IDashboardTopIndicators {
  cashReserve: {
    value: number;
  };
  idle: {
    value: number;
  };
  netCashFlow: {
    value: number;
    inflows: string; // should be number but api returns string
    outflows: string; // should be number but api returns string
  };
  runway: {
    unit: string;
    value: number;
  };
  totalCash: {
    value: number;
  };
}

export interface DashboardTopIndicatorsProps {
  plaidDataReady: boolean;

  indicators: IDashboardTopIndicators;

  totalBalance: number;
}

function getIndicators(
  plaidDataReady: boolean,
  indicators: IDashboardTopIndicators,
  totalBalance: number,
  handleClick: (
    event: React.MouseEvent<HTMLDivElement>,
    indicator: IndicatorProps,
  ) => void,
): IndicatorProps[] {
  const { runway } = indicators;

  const isNil = (val: any) => val == null;
  const runwayHasInvalidData = isNil(runway.value) || isNil(runway.unit);
  const runwayValue = runwayHasInvalidData
    ? undefined
    : `${runway.value} ${runway.unit}`;

  return [
    {
      handleClick,
      label: 'Idle Cash',
      type: IndicatorType.IdleCash,
      value: plaidDataReady ? indicators.idle.value : '--',
    },
    {
      handleClick,
      label: 'Treasure Reserve',
      type: IndicatorType.CashReserve,
      value: totalBalance,
      // value: indicators.cashReserve?.value, // replaced with usePortfolios call which is used on other pages
    },
    {
      handleClick: plaidDataReady ? handleClick : undefined,
      label: 'Runway',
      type: IndicatorType.Runway,
      value: plaidDataReady ? runwayValue : '--',
    },
    {
      handleClick,
      label: 'Net Cash Flow',
      type: IndicatorType.NetCashFlow,
      value: indicators.netCashFlow.value,
    },
    {
      handleClick,
      label: 'Total Cash',
      type: IndicatorType.TotalCash,
      value: indicators.totalCash.value,
    },
  ];
}

export const DashboardTopIndicators: React.FC<DashboardTopIndicatorsProps> = ({
  plaidDataReady,
  indicators,
  totalBalance,
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    indicator: IndicatorProps,
  ) => {
    const bounds = event.currentTarget.getBoundingClientRect();

    // if the user clicks on an indicator while scrolled down, scroll them back to the top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // TODO: could add a resize listener to set browserWidth only when changed

    // TODO: combine these in a single state?
    setSelectedIndicator(indicator);
    setOpen(true);
    setPopoutOpen(true);
    setPopoutBounds({
      browserWidth: window.innerWidth || document.body.clientWidth,
      indicatorWidth: bounds.width,
      xPos: bounds.left,
    });

    trackEvent({
      eventName: TrackEventName.TopIndicatorDrilldownOpened,
      indicator: indicator.label,
    });
  };

  const indicatorOrder = getIndicators(
    plaidDataReady,
    indicators,
    totalBalance,
    handleClick,
  );
  const [open, setOpen] = useState(false);
  const [popoutOpen, setPopoutOpen] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState<IndicatorProps>(
    indicatorOrder[0],
  );
  const [popoutBounds, setPopoutBounds] = useState({
    browserWidth: window.innerWidth || document.body.clientWidth,
    indicatorWidth: 0,
    xPos: 0,
  });
  const { Track, trackEvent } = useTracking<{
    eventName: TrackEventName;
    indicator: string;
  }>({
    indicator: selectedIndicator.label,
  });

  const hideIndicatorPopout = () => {
    setPopoutOpen(false);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const getSubIndicator = (indicatorType: IndicatorType) => {
    switch (indicatorType) {
      case IndicatorType.CashReserve:
        return <TreasureReserveSubIndicator />;
      case IndicatorType.IdleCash:
        return <IdleCashSubIndicator />;
      case IndicatorType.NetCashFlow:
        return <NetCashFlowSubIndicator data={indicators.netCashFlow} />;
      case IndicatorType.Runway:
        return <RunwaySubIndicator />;
      case IndicatorType.TotalCash:
        return <TotalCashSubIndicator totalCash={indicators.totalCash.value} />;
    }
  };

  return (
    <>
      <TopIndicators
        dashboard={DashboardType.Dashboard}
        indicators={indicatorOrder}
      />

      <Modal
        open={open}
        onClose={hideIndicatorPopout}
        BackdropComponent={Styled.Backdrop}
      >
        <>
          <Styled.CSSTransition
            in={popoutOpen}
            timeout={300}
            classNames={
              selectedIndicator.type === IndicatorType.IdleCash
                ? 'grow-indicator'
                : 'animate-modal'
            }
            appear
            popoutBounds={popoutBounds}
            onExited={handleModalClose}
          >
            {selectedIndicator.type === IndicatorType.IdleCash ? (
              <Styled.IdleCashPopout
                popoutBounds={popoutBounds}
                onClick={hideIndicatorPopout}
              >
                <TopIndicator
                  dashboard={DashboardType.Dashboard}
                  indicator={selectedIndicator}
                  isPopOut
                  showCircle={false}
                />
              </Styled.IdleCashPopout>
            ) : (
              <Styled.SubIndicatorPopout
                popoutBounds={popoutBounds}
                onClick={hideIndicatorPopout}
                textAlign="center"
              >
                <TopIndicator
                  dashboard={DashboardType.Dashboard}
                  indicator={selectedIndicator}
                  isPopOut
                />
              </Styled.SubIndicatorPopout>
            )}
          </Styled.CSSTransition>

          <Styled.SubIndicatorModal popoutBounds={popoutBounds}>
            <Track>
              <Box p={5}>
                {getSubIndicator(selectedIndicator.type)}

                <Box mt={5}>
                  <LiquidSense indicator={selectedIndicator} />
                </Box>
              </Box>
            </Track>
          </Styled.SubIndicatorModal>
        </>
      </Modal>
    </>
  );
};

DashboardTopIndicators.propTypes = {
  plaidDataReady: PropTypes.bool.isRequired,
  indicators: PropTypes.shape({
    cashReserve: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
    idle: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
    netCashFlow: PropTypes.shape({
      value: PropTypes.number.isRequired,
      inflows: PropTypes.string.isRequired,
      outflows: PropTypes.string.isRequired,
    }).isRequired,
    runway: PropTypes.shape({
      unit: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    totalCash: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
