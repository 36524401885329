import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { UserPermissionsRequired } from 'modules/2023-q4/rbac/components/user-permissions-required/user-permissions-required';
import { Cusip } from '../types/cusip';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useRemoveRestrictedCusip } from '../hooks/use-remove-restricted-cusip';

import { Styled } from './remove-restricted-cusip.style';

interface RemoveRestrictedCusipProps {
  restrictedCusip: Cusip;
}

export const RemoveRestrictedCusip: React.FC<RemoveRestrictedCusipProps> = ({
  restrictedCusip,
}) => {
  const { data: business } = useBusiness();

  const removeRestrictedCusip = useRemoveRestrictedCusip();

  const handleRemoveCUSIP = () => {
    try {
      removeRestrictedCusip.mutate({
        businessId: business?.Id,
        cusipId: restrictedCusip.id,
      });
    } catch {
      //
    }
  };

  const removeRestrictedCusipButton = (
    <Grid item>
      <Styled.RemoveIcon fontSize="small" onClick={handleRemoveCUSIP} />
    </Grid>
  );

  return (
    <Styled.Box p={1} mb={1}>
      <Grid container alignItems="center">
        <Grid item xs>
          {restrictedCusip.cusip}
        </Grid>

        <FeatureFlag
          disabled={<>{removeRestrictedCusipButton}</>}
          enabled={
            <UserPermissionsRequired roles={[UserRole.ADMIN]}>
              {removeRestrictedCusipButton}
            </UserPermissionsRequired>
          }
          flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
        />
      </Grid>
    </Styled.Box>
  );
};

RemoveRestrictedCusip.propTypes = {
  restrictedCusip: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cusip: PropTypes.string.isRequired,
  }).isRequired,
};
