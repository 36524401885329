import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { FilterBar, DefaultPeriod } from './components/filter-bar';
import { GridCard } from 'components/dashboard';
import { ProductType, ProductYield } from 'components/banking';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useTreasureReserveEarnings } from 'pages/v3-portal/dashboard/components/dashboard-column-treasure-reserve/components/performance-charts/hooks/use-treasure-reserve-earnings';

export const PerformanceCharts: React.FC = () => {
  const [period, setPeriod] = useState<string>(DefaultPeriod);

  const { data: business } = useBusiness();

  const { data, isSuccess, refetch } = useTreasureReserveEarnings(
    business?.Id,
    period,
  );

  useEffect(() => {
    refetch();
  }, [period]);

  const displayChart = (chart: JSX.Element) => {
    return (
      <GridCard>
        <Box p={2}>{chart}</Box>
      </GridCard>
    );
  };

  return (
    <>
      <Box mb={2}>
        <FilterBar onChange={setPeriod} />
      </Box>

      {isSuccess && (
        <>
          {displayChart(<ProductYield data={data} type={ProductType.cash} />)}
        </>
      )}
    </>
  );
};
