import styled from 'styled-components';
import { LinearProgress, Grid } from '@material-ui/core';
import { ReactComponent as SparklineNeutral } from 'assets/icons/sparklines/sparkline-neutral.svg';
import { ReactComponent as SparklineUp } from 'assets/icons/sparklines/sparkline-up.svg';
import { Heading } from 'components/core';
import { breakpoints } from 'styles/css-constants';

const PercentageCircle = styled.div`
  background: linear-gradient(180deg, #8f298b 6.3%, #3b1cb4 92.8%);
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  margin: 0 auto;
`;

const PercentageCircleTrendDown = styled(SparklineUp)`
  position: absolute;
  transform: scaleX(-1);
`;

const PercentageCircleTrendNeutral = styled(SparklineNeutral)`
  position: absolute;
`;

const PercentageCircleTrendUp = styled(SparklineUp)`
  position: absolute;
`;

const UtilizedCashProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    height: 10px;
    border-radius: var(--border-radius-06);
    box-shadow: var(--box-shadow-02);
  }

  &.MuiLinearProgress-colorPrimary {
    background-color: var(--color-whiteSmoke4);
  }

  .MuiLinearProgress-barColorPrimary {
    background: linear-gradient(
      270deg,
      var(--color-stromboli) 0%,
      var(--color-mintGreen) 100%
    );
  }
`;

const UtilizedCashProgressLabel = styled.div`
  float: right;
  color: var(--color-stromboli);
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: var(--font-weight-regular);
`;

const AllocatedCashProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    height: 10px;
    border-radius: var(--border-radius-06);
    box-shadow: var(--box-shadow-02);
  }

  &.MuiLinearProgress-colorPrimary {
    background-color: var(--color-whiteSmoke4);
  }

  .MuiLinearProgress-barColorPrimary {
    background: linear-gradient(
      270deg,
      var(--color-stromboli) 0%,
      var(--color-edward) 100%
    );
  }
`;

const AllocatedCashProgressLabel = styled.div`
  float: right;
  color: var(--color-stromboli);
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: var(--font-weight-regular);
`;

const Row = styled.div`
  background: var(--color-white);
  border: 1px solid var(--color-alto);
  border-radius: var(--border-radius-08);
  box-shadow: var(--box-shadow-07);
`;

const RowHeading = styled(Heading)`
  height: 40px;
  display: flex;
  align-items: center;
`;

const RowContentGrid = styled(Grid)`
  ${breakpoints.up('sm')} {
    min-height: 92px;
  }
`;

const RowCallToAction = styled(Grid)`
  align-self: center;
`;

export const Styled = {
  PercentageCircle,
  PercentageCircleTrendDown,
  PercentageCircleTrendNeutral,
  PercentageCircleTrendUp,
  UtilizedCashProgress,
  UtilizedCashProgressLabel,
  AllocatedCashProgress,
  AllocatedCashProgressLabel,
  Row,
  RowHeading,
  RowContentGrid,
  RowCallToAction,
};
