import React from 'react';
import styled from 'styled-components';
import { Backdrop as BackdropAlias, Box } from '@material-ui/core';
import { CSSTransition as CSSTransitionAlias } from 'react-transition-group';

interface StyledSubIndicatorPopout {
  popoutBounds: {
    browserWidth: number;
    indicatorWidth: number;
    xPos: number;
  };
}

const X_OFFSET = 15;
const Y_OFFSET = 15;
const INDICATOR_MODAL_WIDTH = 540;

enum PopoutDirection {
  Left,
  Right,
}

const popoutDirection = (props: { browserWidth: number; xPos: number }) => {
  return props.xPos + INDICATOR_MODAL_WIDTH >= props.browserWidth
    ? PopoutDirection.Left
    : PopoutDirection.Right;
};

const Backdrop = styled(BackdropAlias)`
  background-color: rgba(34, 34, 34, 0.75);
`;

const CSSTransition = styled(CSSTransitionAlias)<StyledSubIndicatorPopout>`
  transition: transform 300ms, padding-left 300ms;

  &.animate-modal- {
    &appear-active,
    &appear-done {
      transform: translate(
        ${(props) =>
          popoutDirection(props.popoutBounds) === PopoutDirection.Right
            ? `${X_OFFSET}px`
            : `-${X_OFFSET}px`},
        ${Y_OFFSET}px
      );
    }

    &exit-active,
    &exit-done {
      transform: translate(0, 0);
    }
  }

  &.grow-indicator- {
    &appear-active,
    &appear-done {
      padding-left: 100px;
    }

    &exit-active,
    &exit-done {
      padding-left: 0;
    }
  }
`;

const IdleCashPopout = styled.div<StyledSubIndicatorPopout>`
  cursor: pointer;
  position: absolute;
  top: 56px;
  left: ${(props) => props.popoutBounds.xPos}px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro0);
  box-shadow: var(--box-shadow-04);
  border-radius: 0 200px 200px 0;
`;

const SubIndicatorModal = styled.div<StyledSubIndicatorPopout>`
  position: absolute;
  top: 180px;
  ${(props) =>
    popoutDirection(props.popoutBounds) === PopoutDirection.Right
      ? `
    left: ${
      props.popoutBounds.xPos + X_OFFSET < 300
        ? 300
        : props.popoutBounds.xPos + X_OFFSET
    }px;
    `
      : `
    right: ${
      props.popoutBounds.browserWidth -
      props.popoutBounds.xPos -
      props.popoutBounds.indicatorWidth +
      X_OFFSET
    }px;
    `}
  width: ${INDICATOR_MODAL_WIDTH}px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-05);
  border-radius: var(--border-radius-01);
  overflow: hidden;
`;

const SubIndicatorPopout = styled(({ popoutBounds, ...rest }) => (
  <Box {...rest} />
))<StyledSubIndicatorPopout>`
  cursor: pointer;
  position: absolute;
  top: 56px;
  ${(props) =>
    popoutDirection(props.popoutBounds) === PopoutDirection.Right
      ? `
    left: ${props.popoutBounds.xPos}px;
    `
      : `
    right: ${
      props.popoutBounds.browserWidth -
      props.popoutBounds.xPos -
      props.popoutBounds.indicatorWidth
    }px;
    `}
  width: ${(props) => `${props.popoutBounds.indicatorWidth}px`};
  background-color: var(--color-white);
  border-radius: var(--border-radius-01);
`;

export const Styled = {
  Backdrop,
  CSSTransition,
  IdleCashPopout,
  SubIndicatorModal,
  SubIndicatorPopout,
};
