import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import {
  AllocationChartGridWrapper,
  NotificationBox,
  NotificationBoxVariant,
  ReturnDistributionSection,
} from 'modules/portfolio/allocation/components/allocation-modal/components';
import {
  getAllocations,
  getAmounts,
  getHeaders,
  getLiquidity,
  getReturnAmounts,
  getReturns,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import {
  Allocation,
  AllocationProducts,
  getAllocation,
  initialAllocation,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { AllocationSetAmount } from 'modules/portfolio/allocation/components/allocation-modal/components/allocation-set-amount/allocation-set-amount';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from './allocation-chart.style';

interface AllocationChartProps {
  aum: number;

  allocations: Allocation;

  noFunds: boolean;

  setAllocations: React.Dispatch<React.SetStateAction<Allocation>>;

  setNoFundsAUM: React.Dispatch<React.SetStateAction<number>>;

  showNotificationBox?: boolean;
}

export const AllocationChart: React.FC<AllocationChartProps> = ({
  aum,
  allocations,
  noFunds,
  setAllocations,
  setNoFundsAUM,
  showNotificationBox,
}) => {
  const { trackEvent } =
    useTracking<{
      eventName: TrackEventName;
    }>();

  const handleAllocationChange = (
    allocationType: keyof AllocationProducts,
    newAllocation: number,
  ) => {
    const newAllocationObject = getAllocation({
      currentAllocation: allocations,
      allocationChange: { allocationType, newAllocation },
    });

    setAllocations(newAllocationObject);
  };

  const handleResetAllocation = () => {
    setAllocations(initialAllocation);

    trackEvent({ eventName: TrackEventName.ResetButtonClicked });
  };

  return (
    <>
      {!showNotificationBox && !noFunds && (
        <Box mb={1.5}>
          <NotificationBox variant={NotificationBoxVariant.Information}>
            <Typography variant={TypographyVariant.Body}>
              <strong>
                Please review & accept your recommended portfolio.
              </strong>{' '}
              You can customize using the sliders.
            </Typography>
          </NotificationBox>
        </Box>
      )}

      {noFunds && (
        <Box mb={1.5}>
          <NotificationBox variant={NotificationBoxVariant.Warning}>
            <AllocationSetAmount amount={aum} onChange={setNoFundsAUM} />
          </NotificationBox>
        </Box>
      )}

      <Styled.ChartHeader>
        <AllocationChartGridWrapper
          alignItems="flex-end"
          allocations={allocations}
          data={getHeaders({
            allocations,
            handleAllocationChange,
          })}
          hideBottomBorder
        />
      </Styled.ChartHeader>

      <AllocationChartGridWrapper
        allocations={allocations}
        data={getAllocations({
          allocations,
          handleAllocationChange,
          handleResetAllocation,
        })}
      />

      <AllocationChartGridWrapper
        allocations={allocations}
        data={getAmounts({ allocations, aum })}
      />

      <AllocationChartGridWrapper
        allocations={allocations}
        data={getLiquidity({ allocations })}
      />

      <AllocationChartGridWrapper
        allocations={allocations}
        data={getReturns({ allocations })}
      />

      <AllocationChartGridWrapper
        allocations={allocations}
        data={getReturnAmounts({ allocations, aum })}
      />

      <ReturnDistributionSection allocations={allocations} />
    </>
  );
};
