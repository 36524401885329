import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Bar = styled(Box)`
  background-color: var(--color-green1);
`;

export const Styled = {
  Bar,
};
