import React, { useEffect } from 'react';
import { Hidden } from '@material-ui/core';
import { BankingDashboard } from 'components/banking/v3';
import { BankingTopIndicators } from 'components/dashboard';
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { DashboardContainer } from 'layouts/v3-portal-layout/components';
import { TopIndicators } from 'enums';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { useSubIndicator } from 'modules/sub-indicators/hooks/get-subindicator';
import { useTopIndicators } from 'hooks/banking/use-top-indicators';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';

export const Banking: React.FC = () => {
  const loadingContext = useLoadingContext();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();

  const { data: accounts, isLoading: isLoadingBankAccounts } = useBankAccounts(
    business && business.Id,
  );

  const { data: allocation = [], isLoading: isLoadingAllocation } =
    useSubIndicator(TopIndicators.Allocation);

  const { data: topIndicators, isLoading: isLoadingTopIndicators } =
    useTopIndicators(business && business.Id);

  const { data: treasureReserve, isLoading: isLoadingTreasureReserve } =
    useTreasureReserve(business && business.Id);

  const plaidDataReady =
    accounts &&
    accounts.every(
      (account: { PlaidDataReady: boolean }) => account.PlaidDataReady === true,
    );

  const bankingTopIndicatorsReady = plaidDataReady && topIndicators;

  const bankingDashboardReady =
    accounts && allocation && topIndicators && treasureReserve;

  useEffect(() => {
    loadingContext.setLoading(
      isLoadingAllocation ||
        isLoadingBankAccounts ||
        isLoadingBusiness ||
        isLoadingTopIndicators ||
        isLoadingTreasureReserve,
    );
  }, [
    isLoadingAllocation,
    isLoadingBankAccounts,
    isLoadingBusiness,
    isLoadingTopIndicators,
    isLoadingTreasureReserve,
    loadingContext,
  ]);

  return (
    <>
      {!loadingContext.loading && (
        <Hidden smDown>
          {bankingTopIndicatorsReady && (
            <BankingTopIndicators
              plaidDataReady={plaidDataReady}
              indicators={topIndicators}
            />
          )}
        </Hidden>
      )}

      <DashboardContainer>
        {bankingDashboardReady && (
          <BankingDashboard
            accounts={accounts}
            allocation={allocation}
            idleCash={topIndicators.idle.value}
            treasureReserve={treasureReserve}
          />
        )}
      </DashboardContainer>
    </>
  );
};
