import React from 'react';
import { Currency, CurrencyVariant, TypographyVariant } from 'components/core';
import { Styled } from './allocation-typography.style';

export const cellText = (text: React.ReactNode, color?: string) => {
  return (
    <Styled.CellText
      color={color || 'nero'}
      variant={TypographyVariant.BodySemiBold}
    >
      {text}
    </Styled.CellText>
  );
};

export const columnHeader = (header: React.ReactNode) => {
  return (
    <Styled.UpperCase color="nero" variant={TypographyVariant.SubHeader1}>
      {header}
    </Styled.UpperCase>
  );
};

export const currency = (number: number) => {
  return <Currency number={number} variant={CurrencyVariant.Full} />;
};

export const rowHeader = (header: React.ReactNode) => {
  return (
    <Styled.UpperCase color="stromboli" variant={TypographyVariant.LargerLabel}>
      {header}
    </Styled.UpperCase>
  );
};

export const tooltipList = (content: React.ReactNode) => {
  return <Styled.TooltipList>{content}</Styled.TooltipList>;
};
