import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import {
  Button,
  Spinner,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Toast } from 'components/layouts';

import { ReactQueryKeys } from 'enums';
import { useHistory } from 'react-router-dom';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useBusiness } from 'hooks/business/use-business';
import { useConnectQuickbooks } from 'modules/quickbooks/hooks/use-connect-quickbooks';
import { useSyncQuickbooks } from 'modules/quickbooks/hooks/use-sync-quickbooks';
import { useDisconnectQuickbooks } from 'modules/quickbooks/hooks/use-disconnect-quickbooks';
import { useQuickbooksStatusCheck } from 'modules/quickbooks/hooks/user-quickbooks-status-check';

import { RoutesPath } from 'routes/constants/routes-path';
import { Styled } from './quickbooks.style';
import { ReactComponent as QuickbooksButton } from 'assets/vendors/quickbooks_green_btn_med_hover.svg';

// TODO: sticking sentry here temporarily. need to reenable it in react-query-error-toasts.tsx
import * as Sentry from '@sentry/react';
import { sentryLog } from 'utils/sentryHelper';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';

export const QuickbooksConnect: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { ConnectQBButton, DisconnectQBButton } = Styled;
  const params = new URLSearchParams(window.location.search);

  // TODO: remove this when the feature is no longer gated
  const history = useHistory();
  const { data: business } = useBusiness();
  const showQuickbooks =
    isFeatureFlagEnabled(FeatureFlags.REACT_APP_QUICKBOOKS_SYNC_ENABLED) &&
    business?.Properties?.quickbooksEnabled;

  useEffect(() => {
    if (!showQuickbooks) {
      history.push(RoutesPath.pages.home.path);
    }
  }, [history, showQuickbooks]);
  // END TODO

  const { trackEvent } = useTracking<{
    eventName: TrackEventName;
    page: TrackPageName;
  }>({
    page: TrackPageName.SettingsQuickbooks,
  });

  const {
    data: quickbooksStatus,
    isLoading: isQuickbooksStatusLoading,
    refetch: refetchQuickbooksStatus,
    isFetching: isQuickbooksStatusFetching,
  } = useQuickbooksStatusCheck();

  const { refetch: beginQuickbooksConnect } = useConnectQuickbooks();

  const { mutate: syncQuickbooks, isLoading: isSyncingQuickbooks } =
    useSyncQuickbooks();

  const { mutate: disconnectQuickbooks, isLoading: isDisconnectingQuickbooks } =
    useDisconnectQuickbooks();

  useEffect(() => {
    handleOAuthRedirect();
  }, []);

  /*
   * Check if we are being redirected from the Quickbooks OAuth flow.
   * If we have a code, state, and realmId in the query parms, then we are.
   * If we are, automatically sync to Quickbooks.
   */
  const handleOAuthRedirect = () => {
    const redirectCode = params.get('code') || '';
    const redirectState = params.get('state') || '';
    const redirectRealmId = params.get('realmId') || '';

    const isOauthRedirect =
      window.location.pathname ===
        RoutesPath.pages.settings.pages.quickbooks.path &&
      redirectCode &&
      redirectState &&
      redirectRealmId;

    if (isOauthRedirect) {
      trackEvent({
        eventName: TrackEventName.QuickbooksConnectSuccess,
      });

      mutateSyncQuickbooks({
        code: redirectCode,
        state: redirectState,
        realmId: redirectRealmId,
      });

      // clear the query params once the initial sync has been executed
      // this prevents mutateSyncQuickbooks from being called again after
      // we've already done the initial sync
      params.delete('code');
      params.delete('state');
      params.delete('realmId');
      history.replace({
        search: params.toString(),
      });

      trackEvent({
        eventName: TrackEventName.QuickbooksInitialSync,
      });
    }
  };

  const mutateSyncQuickbooks = (
    obj: { code?: string; state?: string; realmId: string } = {
      code: '',
      state: '',
      realmId: '',
    },
  ) => {
    const { code = '', state = '', realmId } = obj;
    syncQuickbooks(
      {
        code,
        state,
        realm_id: realmId,
      },
      {
        onSuccess: (data: any) => {
          Toast.show(
            'success',
            'Your accounts have been synced to QuickBooks.',
          );
          refetchQuickbooksStatus();
          trackEvent({
            eventName: TrackEventName.QuickbooksSyncSuccess,
          });
        },
        onError: (err: any) => {
          Toast.show(
            'error',
            'Sorry, there was an error connecting your QuickBooks account. Please contact support.',
          );
          trackEvent({
            eventName: TrackEventName.QuickbooksSyncFailed,
          });
          sentryLog({ error: err, errorLevel: Sentry.Severity.Error });
        },
      },
    );
  };

  const mutateDisconnectQuickbooks = () => {
    disconnectQuickbooks(
      {},
      {
        onSuccess: (data: any) => {
          Toast.show('success', 'QuickBooks has been disconnected.');
          refetchQuickbooksStatus();
          trackEvent({
            eventName: TrackEventName.QuickbooksDisconnectSuccess,
          });
        },
        onError: (err: any) => {
          Toast.show(
            'error',
            'Sorry, there was an error disconnecting your QuickBooks account. Please contact support.',
          );
          trackEvent({
            eventName: TrackEventName.QuickbooksDisconnectFailed,
          });
          sentryLog({ error: err, errorLevel: Sentry.Severity.Error });
        },
      },
    );
  };

  const handleConnect = async () => {
    trackEvent({
      eventName: TrackEventName.QuickbooksConnectClicked,
    });

    try {
      const { data: quickbooksConnect } = await beginQuickbooksConnect({
        throwOnError: true,
      });

      if (quickbooksConnect?.redirect_uri) {
        window.location.href = quickbooksConnect.redirect_uri;
      } else {
        throw new Error('Error connecting to QuickBooks.');
      }
    } catch (e: any) {
      trackEvent({
        eventName: TrackEventName.QuickbooksConnectFailed,
      });
      sentryLog({ error: e, errorLevel: Sentry.Severity.Error });
    }
  };

  const handleSync = () => {
    mutateSyncQuickbooks({ realmId: quickbooksStatus.realmId });
    trackEvent({
      eventName: TrackEventName.QuickbooksSyncClicked,
    });
  };

  const handleDisconnect = () => {
    mutateDisconnectQuickbooks();
    trackEvent({
      eventName: TrackEventName.QuickbooksDisconnectClicked,
    });
  };

  if (isQuickbooksStatusLoading || isQuickbooksStatusFetching) {
    return <Spinner />;
  }

  return (
    <>
      {quickbooksStatus?.isActive && (
        <Typography variant={TypographyVariant.Body}>
          You are connected to QuickBooks (
          {business?.LegalName || business?.DBA}).
          <DisconnectQBButton
            onClick={handleDisconnect}
            disabled={isSyncingQuickbooks || isDisconnectingQuickbooks}
            variant="text"
          >
            Disconnect
          </DisconnectQBButton>
        </Typography>
      )}

      {quickbooksStatus?.isActive && (
        <Box>
          <Box py={1}>
            <Button
              onClick={handleSync}
              disabled={isSyncingQuickbooks || isDisconnectingQuickbooks}
              variant="contained"
            >
              {isSyncingQuickbooks ? 'Syncing' : 'Sync'} Accounts to QuickBooks
            </Button>
            {quickbooksStatus?.lastSync && (
              <Box py={1}>
                <Typography variant={TypographyVariant.SmallText}>
                  Last synced on:{' '}
                  {new Date(quickbooksStatus.lastSync).toLocaleDateString()}
                </Typography>
              </Box>
            )}
            {quickbooksStatus?.isActive &&
              quickbooksStatus?.realmId &&
              !quickbooksStatus?.lastSync && (
                <Box py={1}>
                  <Typography variant={TypographyVariant.SmallText}>
                    Initial sync completed
                  </Typography>
                </Box>
              )}
          </Box>
        </Box>
      )}

      {!quickbooksStatus?.isActive && (
        <Box>
          <Box py={1}>
            <Typography variant={TypographyVariant.Body}>
              Please connect to QuickBooks to grant Treasure permission to sync
              your accounts.
            </Typography>
          </Box>
          <ConnectQBButton onClick={handleConnect} variant="text">
            <QuickbooksButton title="Connect to QuickBooks" />
          </ConnectQBButton>
        </Box>
      )}
    </>
  );
};
