import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Heading } from 'components/core';
import { Styled } from './page-error-message.style';

interface PageErrorMessageProps {
  errorAction?: React.ReactNode;

  errorMessage: string;
}

export const PageErrorMessage: React.FC<PageErrorMessageProps> = ({
  errorAction,
  errorMessage,
}) => {
  return (
    <Box px={2} pt={18} display="flex" justifyContent="center">
      <Box display="flex" alignItems="flex-start">
        <Box mt={1} mr={2}>
          <Styled.CancelIcon fontSize="large" color="primary" />
        </Box>

        <div>
          <Box mb={4}>
            <Heading variant={1}>{errorMessage}</Heading>
          </Box>

          {errorAction}
        </div>
      </Box>
    </Box>
  );
};

PageErrorMessage.propTypes = {
  errorAction: PropTypes.func,
  errorMessage: PropTypes.string.isRequired,
};
