import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { PasswordFieldController } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/password';
import { useForm } from 'react-hook-form';
import firebase from 'utils/firebase';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import throttle from 'lodash.throttle';
import { getTokenByEmail } from 'modules/onboarding/v3-streamlined-onboarding/no-auth/api/get-token-by-email';
import { NoAuthContainer } from 'modules/2023-q4/no-auth/no-auth-container/no-auth-container';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { PasswordCriteriaCheck } from './components/password-criteria-check/password-criteria-check';

interface SetPasswordForm {
  password: string;
}
interface SetPasswordProps {
  email: string;

  onSuccessCallback?: () => void;
}

export const SetPassword: React.FC<SetPasswordProps> = ({
  email,
  onSuccessCallback,
}: SetPasswordProps) => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
    username: any;
  }>({
    page: TrackPageName.Password,
    username: email,
  });
  const { control, handleSubmit, watch } = useForm<SetPasswordForm>();
  const [emailToken, setEmailToken] = useState('');

  const password = watch('password', '');

  useEffect(() => {
    async function getFirebaseEmailAndResetToken() {
      try {
        const userEmail = email;
        if (userEmail) {
          getTokenByEmail(userEmail, (token: string) => {
            setEmailToken(token);
          });
        }
      } catch (error) {
        // console.error(error);
      }
    }
    getFirebaseEmailAndResetToken();
  }, [email]);

  const passwordReset = (token: string, resetPassword: string) => {
    return firebase.auth().confirmPasswordReset(token, resetPassword);
  };

  const signIn = (signInEmail: string, signInPassword: string) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(signInEmail, signInPassword);
  };

  const onSubmit = async (data: SetPasswordForm) => {
    try {
      trackEvent({
        eventName: TrackEventName.PasswordSet,
      });

      passwordReset(emailToken, data.password).then(() => {
        signIn(email, data.password).then(() => {
          if (onSuccessCallback && typeof onSuccessCallback === 'function') {
            onSuccessCallback();
          }
        });
      });
    } catch {
      //
    }
  };

  return (
    <NoAuthContainer>
      <Box mt={9.25} mb={3} textAlign="center">
        <Typography color="black19" variant={TypographyVariant.Header3}>
          Set Your Password
        </Typography>

        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Follow the password creation requirements below.
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(throttle(onSubmit, 1000))}>
        <PasswordFieldController
          control={control}
          label="Password"
          name="password"
        />

        <Box mt={3} mx="auto" maxWidth={500}>
          <PasswordCriteriaCheck password={password} />
        </Box>

        <Box mt={5} textAlign="center">
          <Button type="submit" fullWidth>
            <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
              <Typography color="white" variant={TypographyVariant.Body}>
                Continue
              </Typography>
            </ButtonContent>
          </Button>
        </Box>
      </form>
    </NoAuthContainer>
  );
};
