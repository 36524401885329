import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { ReactComponent as TreasureLogoWithText } from 'assets/logos/horizontal/treasure_logo_green.svg';
import { Button } from 'modules/2023-q3/components/button/button';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';

interface IDocumentCenterWrapper {
  children: React.ReactNode;
  signOut?: () => void;
}

export const DocumentCenterWrapper: React.FC<IDocumentCenterWrapper> = ({
  children,
  signOut,
}) => {
  const rightSide = (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography color="grey1" variant={TypographyVariant.Header3}>
          Documents Center
        </Typography>
      </Grid>

      {signOut && (
        <Grid item>
          <Box ml={2}>
            <Button onClick={signOut}>
              <Box px={2} py={0.5}>
                <Typography
                  color="white"
                  variant={TypographyVariant.Paragraph2}
                >
                  Sign Out
                </Typography>
              </Box>
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Box my={5}>
      <CenteredContainer>
        <Box mx="auto">
          <Box mb={10}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <TreasureLogoWithText />
              </Grid>

              <MobileView
                mobile={
                  <Grid item xs={12}>
                    {rightSide}
                  </Grid>
                }
              >
                <Grid item>{rightSide}</Grid>
              </MobileView>
            </Grid>
          </Box>

          {children}
        </Box>
      </CenteredContainer>
    </Box>
  );
};
