import styled from 'styled-components';
import { Select as SelectAlias } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from 'assets/forms/select/arrow.svg';

const Arrow = styled(ArrowIcon)`
  top: calc(50% - 5px);
`;

const Select = styled(SelectAlias)`
  .MuiSelect-select {
    border: 1px solid var(--color-gainsboro0);
    box-shadow: var(--box-shadow-01);
    border-radius: var(--border-radius-02);
  }

  .MuiOutlinedInput-input {
    padding-top: 10px;
    padding-bottom: 7px;
  }
`;

export const Styled = {
  Arrow,
  Select,
};
