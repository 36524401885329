import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface GrowthPromoState {
  closedPromo: boolean;
  resetClosedPromo: () => void;
  setClosedPromo: () => void;
}

export const useStoreGrowthPromo = create<GrowthPromoState>()(
  devtools((set) => ({
    closedPromo: false,
    resetClosedPromo: () => set((state) => ({ closedPromo: false })),
    setClosedPromo: () => set((state) => ({ closedPromo: true })),
  })),
);
