import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useStates, StateOption } from 'utils/hooks/useStates';
import {
  AutocompleteController,
  FormFieldContainer,
  FormFieldContainerVariant,
  TextFieldController,
  formFields,
} from '../';

export enum AddressFormInputsVariant {
  Business = 'Business',
  Residential = 'Residential',
}

export interface AddressForm {
  address1: string;
  address2?: string | null; // null is a workaround for react proptypes
  city: string;
  state: string;
  zipCode: string;
}

interface IAddressFormInputs {
  address?: AddressForm;
  control: any;
  isUS: boolean;
  variant: AddressFormInputsVariant;
}

export const AddressFormInputs: React.FC<IAddressFormInputs> = ({
  address: addressData,
  control,
  isUS,
  variant,
}) => {
  const { data: states = [] } = useStates();
  const stateOptions = states
    .sort((a: StateOption, b: StateOption) =>
      a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()),
    )
    .map((state: StateOption) => {
      return { label: state.Name, value: state.Code };
    });

  return (
    <>
      <FormFieldContainer>
        <FormFieldContainer variant={FormFieldContainerVariant.Small}>
          <TextFieldController
            {...formFields.address1}
            defaultValue={addressData?.address1}
            control={control}
          />
        </FormFieldContainer>

        <FormFieldContainer variant={FormFieldContainerVariant.Small}>
          <TextFieldController
            {...formFields.address2}
            defaultValue={addressData?.address2}
            control={control}
          />
        </FormFieldContainer>

        <FormFieldContainer variant={FormFieldContainerVariant.Small}>
          <TextFieldController
            {...formFields.city}
            defaultValue={addressData?.city}
            control={control}
          />
        </FormFieldContainer>

        {isUS ? (
          <FormFieldContainer variant={FormFieldContainerVariant.Small}>
            <AutocompleteController
              {...formFields.USAState}
              options={stateOptions}
              defaultValue={addressData?.state}
              control={control}
            />
          </FormFieldContainer>
        ) : (
          <FormFieldContainer variant={FormFieldContainerVariant.Small}>
            <TextFieldController
              {...formFields.nonUSAState}
              defaultValue={addressData?.state}
              control={control}
            />
          </FormFieldContainer>
        )}

        <FormFieldContainer variant={FormFieldContainerVariant.Small}>
          <TextFieldController
            {...(isUS ? formFields.USAZipCode : formFields.nonUSAZipCode)}
            defaultValue={addressData?.zipCode}
            gridProps={{ xs: 3 }}
            control={control}
          />
        </FormFieldContainer>
      </FormFieldContainer>
    </>
  );
};

AddressFormInputs.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
  }),
  control: PropTypes.object.isRequired,
  isUS: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([...Object.values(AddressFormInputsVariant)])
    .isRequired,
};
