import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Text } from 'components/core';
import { TopIndicators } from 'enums';
import { useSubIndicator } from 'modules/sub-indicators/hooks/get-subindicator';
import { LoadingSubIndicator } from 'components/dashboard/top-indicators/sub-indicators/loading/loading-sub-indicators';
import { Styled } from './runway-sub-indicator.style';

const RunwaySubIndicator: React.FC = () => {
  const { data: runway = {}, isLoading } = useSubIndicator(
    TopIndicators.Runway,
  );

  const getRunwayTrendText = (runwayPercentage: number) => {
    if (runwayPercentage === undefined) {
      return;
    }

    let text = 'Your business is at risk, monitor your finances closely!';

    if (runwayPercentage >= 100) {
      // 6+ months
      text =
        "You're a financial genius! Continue transferring into your Treasure Reserve and contact your account representative if you are interested in additional opportunities.";
    } else if (runwayPercentage >= 50) {
      // 3 months
      text =
        "You're doing great! Keep transferring your idle cash to your Treasure Reserve to continue growing your runway to 6 months or more.";
    }

    return text;
  };

  return (
    <>
      <Box mb={2}>
        <Text variant={1}>
          Your runway is the life expectancy of your business if revenue
          activities were shut off today (Total cash/projected monthly
          operational expenses). At Treasure, we recommend that every company
          maintains a 3 month+ cash reserve for minimal operational risk.
        </Text>
      </Box>

      <Styled.Divider />

      {isLoading && <LoadingSubIndicator />}

      {runway && runway.runwayPercentage && (
        <>
          <Box mt={4}>
            <Box mb={1}>
              <Grid container>
                <Grid item xs>
                  <Text color="gothic" variant={1}>
                    {`${runway.lower?.value} ${runway.lower?.unit}`}
                  </Text>
                </Grid>

                <Grid item xs>
                  <Box textAlign="center">
                    <Text color="gothic" variant={1}>
                      {`${runway.middle?.value} ${runway.middle?.unit}`}
                    </Text>
                  </Box>
                </Grid>

                <Grid item xs>
                  <Box textAlign="right">
                    <Text color="gothic" variant={1}>
                      {`${runway.upper?.value}+ ${runway.upper?.unit}`}
                    </Text>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Styled.LinearProgress
              variant="determinate"
              value={runway.runwayPercentage || 0}
            />
            <Styled.LinearProgressArrow value={runway.runwayPercentage || 0} />
          </Box>

          <Box mt={3} px={4}>
            <Grid container spacing={1}>
              <Grid item>
                <Box mr={2}>
                  <Styled.TrendCurrencyUpIcon />
                </Box>
              </Grid>

              <Grid item xs>
                <Text color="black" variant={1}>
                  {getRunwayTrendText(runway.runwayPercentage)}
                </Text>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export { RunwaySubIndicator };
