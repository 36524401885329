import {
  SelectFormFieldSpecs,
  TextFormFieldSpecs,
} from 'modules/form-helpers/index';

type FormFields = {
  transferAmount: TextFormFieldSpecs;
  fromAccount: SelectFormFieldSpecs;
  toAccount: SelectFormFieldSpecs;
};

export const formFields: FormFields = {
  transferAmount: {
    label: 'Amount',
    labelVariant: 3,
    leftLabel: true,
    name: 'transferAmount',
    placeholder: '$0.00',
    currency: true,
    validations: {
      required: true,
    },
    customMaxAmount: 500000,
  },
  fromAccount: {
    label: 'From',
    labelVariant: 3,
    leftLabel: true,
    name: 'fromAccount',
    placeholder: 'Select account',
    validations: {
      required: true,
    },
  },
  toAccount: {
    label: 'To',
    labelVariant: 3,
    leftLabel: true,
    name: 'toAccount',
    placeholder: 'Select account',
    validations: {
      required: true,
    },
  },
};
