import styled, { css } from 'styled-components';
import { Chip, Divider, TableCell as TableCellAlias } from '@material-ui/core';
import { Heading } from 'components/core';
import { colors, getColor } from 'styles/css-constants';

interface StyledStatus {
  bordercolor?: string;
}

const Status = styled(Chip)<StyledStatus>`
  border-color: ${(props) => {
    if (props.bordercolor)
      return getColor(props.bordercolor as keyof typeof colors);

    return css`var(--color-nero)`;
  }};

  border-radius: var(--border-radius-03);
`;

const TableCell = styled(TableCellAlias)`
  position: relative;
  border-bottom: 0 none;
  padding: var(--spacing-01);
  vertical-align: top;
`;

const TableHeadHeading = styled(Heading)`
  font-weight: 400;
  letter-spacing: 1.14286px;
`;

const TransactionTypeIconConnector = styled(Divider)`
  position: absolute;
  top: 50px;
  left: 30px;
  height: 23px;
`;

export const Styled = {
  Status,
  TableCell,
  TableHeadHeading,
  TransactionTypeIconConnector,
};
