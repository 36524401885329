import React, { useEffect, useState } from 'react';
import { ClickAnchor } from 'components/core';
import { useTracking, TrackEventName } from 'modules/tracking';
import { TimeFilter } from './components/time-filter/time-filter';

export enum TimeFilterOption {
  ALL = 'All Time',
  TWELVE_MONTH = '12M',
  SIX_MONTH = '6M',
  THREE_MONTH = '3M',
  ONE_MONTH = '1M',
}

interface TimeFiltersProps {
  defaultTimeFilter: TimeFilterOption;

  setTimeFilter: React.Dispatch<React.SetStateAction<TimeFilterOption>>;

  timeFilterOptions: TimeFilterOption[];
}

export const TimeFilters: React.FC<TimeFiltersProps> = ({
  defaultTimeFilter,
  setTimeFilter,
  timeFilterOptions,
}) => {
  const { trackEvent } = useTracking<{
    eventName: TrackEventName;
    timeFilter: TimeFilterOption;
  }>({
    eventName: TrackEventName.TimeFilterClicked,
  });

  const [selectedTimeFilter, setSelectedTimeFilter] =
    useState(defaultTimeFilter);

  const handleTimeFilterSelection = (event: any) => {
    const element = event.target as HTMLElement;
    setSelectedTimeFilter(element.innerText as TimeFilterOption);

    trackEvent({
      timeFilter: element.innerText as TimeFilterOption,
    });
  };

  useEffect(() => {
    setTimeFilter(selectedTimeFilter);
  }, [selectedTimeFilter, setTimeFilter]);

  return (
    <>
      {timeFilterOptions.map((timeFilter) => (
        <ClickAnchor key={timeFilter} onClick={handleTimeFilterSelection}>
          <TimeFilter selected={selectedTimeFilter === timeFilter}>
            {timeFilter}
          </TimeFilter>
        </ClickAnchor>
      ))}
    </>
  );
};
