import React from 'react';
import { Box, Divider, Grid, GridItemsAlignment } from '@material-ui/core';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';

export interface AllocationChartGridProps {
  alignItems?: GridItemsAlignment;

  allocations: Allocation;

  data: React.ReactNode[];

  hideChartDivider?: boolean;
}

interface AllocationChartCellProps {
  children: React.ReactNode;

  pr: number;
}

const AllocationChartCell: React.FC<AllocationChartCellProps> = ({
  children,
  pr,
}) => {
  return (
    <Grid item xs>
      <Box py={2} pr={pr}>
        {children}
      </Box>
    </Grid>
  );
};

export const AllocationChartGrid: React.FC<AllocationChartGridProps> = ({
  alignItems = 'center',
  allocations,
  data,
  hideChartDivider,
}) => {
  return (
    <Grid container alignItems={alignItems}>
      {[...Array(Object.keys(allocations).length + 1)].map((e, i) => {
        // the first column needs extra space for the reset button (remove pr)
        if (i === 0) {
          return <AllocationChartCell pr={0}>{data[i]}</AllocationChartCell>;
        }

        // after the treasure reserve column, add a border and some spacing
        if (i === 1) {
          return (
            <>
              <AllocationChartCell pr={3}>{data[i]}</AllocationChartCell>
              {!hideChartDivider && <Divider orientation="vertical" flexItem />}

              <Grid item>
                <Box p={1.5} />
              </Grid>
            </>
          );
        }

        return <AllocationChartCell pr={0}>{data[i]}</AllocationChartCell>;
      })}
    </Grid>
  );
};
