import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useAccountsConnect = (): UseMutationResult<any, any, any> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  const accountsConnect = ({
    public_token,
    institutionId,
  }: {
    public_token: string;
    institutionId: string;
  }) =>
    treasureApi().post('/accounts/connect', {
      public_token,
      institutionId,
    });

  return useMutation(accountsConnect, {
    onSuccess: () => {
      return queryClient.invalidateQueries([ReactQueryKeys.ConnectedAccounts]);
    },
  });
};
