import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { StepDots, Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';

import { Highlights } from './slides';
import { SweepConfirmation } from 'modules/ach/sweep-confirmation';

export interface WelcomeProps {
  /** onClose callback */
  onClose: () => void;

  /** transfer */
  transfer?: any;
}

enum Slides {
  SweepConfirmation = 'Transfer Confirmation',
  Highlights = 'Highlights',
}

const Welcome: React.FC<WelcomeProps> = ({ onClose, transfer }) => {
  const slides = transfer
    ? [Slides.SweepConfirmation, Slides.Highlights]
    : [Slides.Highlights];
  const initialSlide = 1;
  const [currentStep, setCurrentStep] = useState(initialSlide);
  const isLastSlide = currentStep === slides.length;

  const onNextStep = () => {
    if (isLastSlide) {
      onClose();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const getSlide = () => {
    switch (slides[currentStep - 1]) {
      case Slides.SweepConfirmation:
        return <SweepConfirmation transfer={transfer} />;
      case Slides.Highlights:
        return <Highlights />;
      default:
        break;
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        {getSlide()}
      </Box>
      {slides.length > 1 ? (
        <Box display="flex" py={2} justifyContent="center">
          <StepDots
            steps={slides.length}
            activeStep={currentStep}
            showHeading={false}
          />
        </Box>
      ) : (
        ''
      )}
      <Box display="flex" justifyContent="center" pt={2}>
        <Button onClick={onNextStep}>
          <ButtonContent>
            <Typography variant={TypographyVariant.Body} color="white">
              {isLastSlide ? 'Close' : 'Next'}
            </Typography>
          </ButtonContent>
        </Button>
      </Box>
    </>
  );
};

Welcome.propTypes = {
  onClose: PropTypes.func.isRequired,
  transfer: PropTypes.object,
};

export { Welcome };
