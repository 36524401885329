import React, { useState } from 'react';
import {
  ForgotPasswordForm,
  ForgotPasswordSent,
  TreasureLogoBox,
} from 'modules/no-auth';
import { RoutesPath } from 'routes/constants/routes-path';

export const ForgotPassword: React.FC = () => {
  const [forgotPasswordSent, setForgotPasswordSent] = useState(false);
  const footerLinks = [
    {
      path: RoutesPath.noAuth.signIn.path,
      text: 'Sign In',
    },
  ];

  return forgotPasswordSent ? (
    <TreasureLogoBox>
      <ForgotPasswordSent />
    </TreasureLogoBox>
  ) : (
    <TreasureLogoBox
      headingText="Forgot your password?"
      footerLinks={footerLinks}
    >
      <ForgotPasswordForm setForgotPasswordSent={setForgotPasswordSent} />
    </TreasureLogoBox>
  );
};
