import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useUserIP = (enableGet: boolean): UseQueryResult<any> => {
  return useQuery(
    ReactQueryKeys.UserIP,
    async () => {
      const response = await axios.get(
        'https://geolocation-db.com/json/e060dd40-4071-11ed-aa4b-239bb7229e90',
      );
      return response.data;
    },
    {
      enabled: enableGet,
      staleTime: Infinity,
    },
  );
};
