import styled from 'styled-components';
import { IconButton as IconButtonAlias } from '@material-ui/core';
import { TextField } from 'components/core/forms/text-field';
import { Visibility } from '@material-ui/icons';

const IconButton = styled(IconButtonAlias)`
  &:hover {
    background-color: transparent;
  }
`;

const IconVisibility = styled(Visibility)`
  color: var(--color-silver);
  font-size: 12px;
`;

const PasswordField = styled(TextField)`
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;

export const Styled = {
  IconButton,
  IconVisibility,
  PasswordField,
};
