import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Box } from '@material-ui/core';
import { Button, Text } from 'components/core';
import {
  PasswordFieldController,
  PasswordCriteriaCheck,
} from 'components/core/forms';

import { useForm } from 'react-hook-form';

import { useLoadingContext } from 'modules/no-auth/treasure-logo-box';

import { verifyPasswordResetCode } from 'modules/no-auth/api/verify-password-reset-code';
import { useResetPassword } from 'modules/no-auth/hooks/use-reset-password';
import { Toast } from 'components/layouts';

interface IResetPasswordForm {
  password: string;
}

export const ResetPasswordForm: React.FC = () => {
  const { resetPassword } = useResetPassword();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { control, handleSubmit, watch } = useForm<IResetPasswordForm>();
  const loadingContext = useLoadingContext();

  const password = watch('password', '');

  const onSubmit = async (data: IResetPasswordForm) => {
    const payload = {
      actionCode: token,
      newPassword: data.password,
    };

    loadingContext.setLoading(true);

    const email = await verifyPasswordResetCode(payload.actionCode);

    if (typeof email === 'string') {
      try {
        await resetPassword(email, payload);

        Toast.show(
          'success',
          `Password changed, you will be redirected to sign in`,
        );

        setTimeout(() => history.push(RoutesPath.noAuth.signIn.path), 3000);
      } catch (error) {
        const err = error as { response: { data: { message: string } } };

        Toast.show('error', err.response.data.message);

        loadingContext.setLoading(false);
      }
    } else {
      loadingContext.setLoading(false);
    }
  };

  return (
    <Box px={{ xs: 3, sm: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <PasswordFieldController
            control={control}
            label="New Password"
            name="password"
          />
        </Box>

        <Box mb={3}>
          <PasswordCriteriaCheck password={password} />
        </Box>

        <Box mt={3}>
          <Button type="submit" width={100}>
            <Box py={0.75}>
              <Text color="white" variant={3}>
                Reset password
              </Text>
            </Box>
          </Button>
        </Box>
      </form>
    </Box>
  );
};
