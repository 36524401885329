import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { useForm } from 'react-hook-form';
import firebase from 'utils/firebase';
import { useTracking, TrackEventName } from 'modules/tracking';
import { useSignIn } from 'modules/no-auth/hooks/use-sign-in';
import { useIsApiUser } from 'modules/2023-q4/documents/hooks/use-is-api-user';
import { DocumentCenterWrapper } from 'modules/2023-q4/documents/components/document-center-wrapper/document-center-wrapper';
import { DocumentFormEmail } from 'modules/2023-q4/documents/components/document-form-email/document-form-email';
import { DocumentFormPassword } from 'modules/2023-q4/documents/components/document-form-password/document-form-password';
import { DocumentsDisplay } from 'modules/2023-q4/documents/components/documents-display/documents-display';
import { DocumentsMFA } from 'modules/2023-q4/documents/components/documents-mfa/documents-mfa';

export const Documents = () => {
  const history = useHistory();
  const { signIn } = useSignIn();
  const { mutate: isApiUser } = useIsApiUser();
  const { trackEvent } = useTracking();
  const { control, handleSubmit, reset, setError } = useForm({
    defaultValues: { email: '', password: '' },
  });

  const [businessId, setBusinessId] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFirebaseUser, setIsFirebaseUser] = useState(false);
  const [isExternalUser, setIsExternalUser] = useState(false);

  const submitForm = async (data?: any) => {
    if (data.password) {
      await signIn(data);

      // send user to documents page
      history.push(RoutesPath.pages.documents.path);

      trackEvent({
        eventName: TrackEventName.SignInSucceeded,
        username: data.email,
      });
    } else if (data.email) {
      setEmail(data.email);
      setIsLoading(true);

      // if the user has a sign in method, that means they're a firebase user
      await firebase
        .auth()
        .fetchSignInMethodsForEmail(data.email)
        .then((result) => {
          if (result.length) {
            setIsFirebaseUser(true);
            setIsLoading(false);
          } else {
            isApiUser(
              { email: data.email },
              {
                onError: (error) => {
                  setError('email', {
                    type: 'custom',
                    message: 'Email not found',
                  });
                  setIsLoading(false);
                },
                onSuccess: (response) => {
                  setIsExternalUser(true);
                  setIsLoading(false);
                },
              },
            );
          }
        });
    }
  };

  const signOut = () => {
    reset();
    setBusinessId(undefined);
    setEmail(undefined);
    setIsExternalUser(false);
  };

  if (businessId) {
    return (
      <DocumentCenterWrapper signOut={signOut}>
        <DocumentsDisplay businessId={businessId} />
      </DocumentCenterWrapper>
    );
  }

  if (isExternalUser && email) {
    return (
      <DocumentCenterWrapper signOut={signOut}>
        <DocumentsMFA
          email={email}
          onPrevious={signOut}
          setBusinessId={setBusinessId}
        />
      </DocumentCenterWrapper>
    );
  }

  if (isFirebaseUser) {
    return (
      <DocumentCenterWrapper>
        <Box mx="auto" maxWidth={650}>
          <DocumentFormPassword
            control={control}
            handleSubmit={handleSubmit(submitForm)}
            isLoading={isLoading}
          />
        </Box>
      </DocumentCenterWrapper>
    );
  }

  return (
    <DocumentCenterWrapper>
      <Box mx="auto" maxWidth={650}>
        <DocumentFormEmail
          control={control}
          handleSubmit={handleSubmit(submitForm)}
          isLoading={isLoading}
        />
      </Box>
    </DocumentCenterWrapper>
  );
};
