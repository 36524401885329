import firebase from 'utils/firebase';
import { Toast } from 'components/layouts';

export const reauthenticate: (password: string) => Promise<boolean> = async (
  password,
) => {
  const { currentUser } = firebase.auth();

  if (!currentUser?.email) {
    throw new Error('User must be signed in to update password');
  }

  const credential = firebase.auth.EmailAuthProvider.credential(
    currentUser.email,
    password,
  );

  try {
    await currentUser.reauthenticateWithCredential(credential);

    return true;
  } catch (error) {
    const err = error as { code: string; message: string };
    let errorMessage = 'Oops! An error occurred, please try again later';

    if (err.code === 'auth/wrong-password') {
      errorMessage = 'The current password is invalid';
    } else if (err.code === 'auth/too-many-requests') {
      errorMessage = err.message;
    }

    Toast.show('error', errorMessage);

    return false;
  }
};
