import React from 'react';
import { useTranslation } from 'app/i18n';
import { i18nNamespaceMFAContent } from 'modules/2023-q3/components/mfa/mfa';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';
import { maskEmail } from 'modules/2023-q3/utils/mask-email/mask-email';

interface EnterVerificationCodeProps {
  customVerificationMessage?: string;
}

export const EnterVerificationCode: React.FC<EnterVerificationCodeProps> = ({
  customVerificationMessage,
}) => {
  const { t } = useTranslation(i18nNamespaceMFAContent);

  const { data: business } = useBusiness();
  const { data: user } = useUser(business?.Id);
  const userEmail = user?.email;

  return (
    <>
      <Box mb={1}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          {t('Enter Your Verification Code')}
        </Typography>
      </Box>

      <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
        {t(
          userEmail
            ? customVerificationMessage
              ? customVerificationMessage
              : 'A verification code has been sent to'
            : 'A verification code has been sent',
          {
            email: maskEmail(userEmail),
          },
        )}
      </Typography>
    </>
  );
};
