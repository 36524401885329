import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Container = styled(Box)`
  border: 1px solid var(--color-orange3);
  border-radius: var(--border-radius-06px);
`;

export const Styled = {
  Container,
};
