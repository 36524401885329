import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ClickAnchor } from 'components/core';

interface LinkProps {
  $error: boolean;
}

const Close = styled(CloseIcon)`
  font-size: 15px;
  position: relative;
  top: 3px;

  &:hover {
    cursor: pointer;
  }
`;

const Container = styled(Box)`
  background-color: var(--color-white);
  border: 1px solid var(--color-mischka2);
  border-radius: var(--border-radius-02);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);
`;

const Link = styled(ClickAnchor)<LinkProps>`
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius-02);
  display: block;

  ${(props) =>
    props.$error === true &&
    css`
      border-color: var(--color-amaranth);
    `};
`;

export const Styled = {
  Close,
  Container,
  Link,
};
