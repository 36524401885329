import React from 'react';
import { Grid } from '@material-ui/core';
import { Percentage, Typography, TypographyVariant } from 'components/core';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { getReturnRange } from 'modules/portfolio/allocation/components/allocation-modal/components/return-distribution/utils/get-return-range';
import { Styled } from './return-distribution-legend.style';

interface LegendBoxProps {
  color: string;

  label: string;

  percentage: number;
}

const ReturnDistributionLegendBox: React.FC<LegendBoxProps> = ({
  color,
  label,
  percentage,
}) => {
  return (
    <Grid item>
      <Styled.LegendBox p={1} textAlign="center">
        <Styled.LegendText
          color={color}
          variant={TypographyVariant.LargerLabel}
        >
          {label}
        </Styled.LegendText>

        <Typography variant={TypographyVariant.SmallLabel2}>
          <Percentage number={percentage} decimalScale={2} />
        </Typography>
      </Styled.LegendBox>
    </Grid>
  );
};

interface LegendProps {
  allocations: Allocation;

  product: keyof Allocation;
}

export const ReturnDistributionLegend: React.FC<LegendProps> = ({
  allocations,
  product,
}) => {
  const { lower, upper } = getReturnRange(allocations, product);

  return (
    <Grid container justifyContent="center">
      <ReturnDistributionLegendBox
        color="cinnabar"
        label="Low"
        percentage={lower}
      />

      <ReturnDistributionLegendBox color="blue" label="Hi" percentage={upper} />
    </Grid>
  );
};
