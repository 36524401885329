import styled from 'styled-components';
import { Typography } from 'components/core';

const TableText = styled(Typography)`
  line-height: 36px;
`;

const TableTextLarge = styled(TableText)`
  font-size: 18px;
`;

export const Styled = {
  TableText,
  TableTextLarge,
};
