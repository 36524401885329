import styled from 'styled-components';
import { Close } from '@material-ui/icons';

const MoreInformationCloseIcon = styled(Close)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const MoreInformationModal = styled.div`
  position: relative;
  width: 400px;
  min-height: 50px;
  background-color: var(--color-white);
  margin: 350px auto 0;
`;

export const Styled = {
  MoreInformationCloseIcon,
  MoreInformationModal,
};
