export enum OnboardingSteps {
  BusinessStructure = 'Business structure',
  BusinessDetails = 'Business details',
  BusinessRepresentative = 'Business representative',
  RareScenarios = 'Rare scenarios',
  BusinessOwners = 'Business owners',
  Disclosures = 'Disclosures',
  BankDetails = 'Bank details',
  SweepFunds = 'Transfer funds',
}
