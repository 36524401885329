import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useListScheduleTransfers = (): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  return useQuery([ReactQueryKeys.ScheduleTransfers], async () => {
    const response = await treasureApi().get('/transfer/list');

    return response.data?.data;
  });
};
