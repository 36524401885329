import React from 'react';
import { useTranslation } from 'app/i18n';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ReactComponent as IconExclamationCircleOrange } from 'assets/icons/checkmarks/exclamation-circle-orange.svg';
import { Button } from 'modules/2023-q3/components/button/button';
import { Styled } from 'modules/2024-q1/allocation-by-amount-or-percentage/pages/form/form.style';

interface FailedRetryProps {
  onRetry: (token?: string) => void;
}

export const FailedRetry: React.FC<FailedRetryProps> = ({ onRetry }) => {
  const { t } = useTranslation();

  return (
    <Box maxWidth={720} mt={5} mx="auto">
      <Styled.RoundedContainer p={3}>
        <Box mt={16} mb={12} textAlign="center">
          <Box mb={2}>
            <IconExclamationCircleOrange />
          </Box>

          <Typography color="black19" variant={TypographyVariant.Header3}>
            {t("We're sorry, something on our end went wrong.", { ns: 'app' })}
          </Typography>
        </Box>

        <Button fullWidth onClick={onRetry}>
          <Box px={2} py={1.5}>
            <Typography color="white" variant={TypographyVariant.Paragraph2}>
              {t('Retry', { ns: 'app' })}
            </Typography>
          </Box>
        </Button>
      </Styled.RoundedContainer>
    </Box>
  );
};
