import React from 'react';
import { i18nNamespace } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { Box, Grid } from '@material-ui/core';
import { AccordionSummary } from 'modules/2023-q3/components/accordion/accordion';
import { Typography, TypographyVariant } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrows/caret-down.svg';
import { LetterCircle } from './components/letter-circle/letter-circle';

interface LiquiditySummaryProps {
  isExpanded?: boolean;
}

export const LiquiditySummary: React.FC<LiquiditySummaryProps> = ({
  isExpanded,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const { data: business } = useBusiness();

  const companyName = business?.LegalName || business?.DBA;

  return (
    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Box mr={1.5}>
                <LetterCircle />
              </Box>
            </Grid>

            <Grid item>
              <Box>
                <Typography
                  color="black19"
                  variant={TypographyVariant.Paragraph2}
                >
                  {t('Portfolio Liquidity')}
                </Typography>
              </Box>

              <Typography color="grey4" variant={TypographyVariant.Paragraph2}>
                {companyName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            {t(isExpanded ? 'See less' : 'See more', { ns: 'app ' })}
          </Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};
