import React from 'react';
import { Box } from '@material-ui/core';
import { InviteUser, InviteUserLink, UserList } from 'modules/multi-user';
import { useBusinessMembers } from 'hooks/business/use-business-members';
import { useTracking, TrackPageName } from 'modules/tracking';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { UserPermissionsRequired } from 'modules/2023-q4/rbac/components/user-permissions-required/user-permissions-required';
import { NotificationContainer } from 'modules/2023-q3/banking-page/v2/components/bank-accounts/components/bank/components/bank-plaid-error/components/notification-container/notification-container';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';
import { TreasureFiEmails } from 'enums';

export const Team: React.FC = () => {
  useTracking<{
    page: TrackPageName;
  }>({
    page: TrackPageName.SettingsTeam,
  });

  const { data: businessMembers } = useBusinessMembers();

  const inviteUserLink = (
    <Box mb={10}>
      <InviteUserLink />
    </Box>
  );

  // show the invite user form if there is only 1 team member
  if (businessMembers?.length <= 1) {
    return (
      <FeatureFlag
        disabled={<InviteUser />}
        enabled={
          <UserPermissionsRequired roles={[UserRole.ADMIN]}>
            <InviteUser />
          </UserPermissionsRequired>
        }
        flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
      />
    );
  }

  // otherwise, add a link to invite a new user
  return (
    <>
      <FeatureFlag
        disabled={<>{inviteUserLink}</>}
        enabled={
          <UserPermissionsRequired roles={[UserRole.ADMIN]}>
            {inviteUserLink}

            <Box mb={4}>
              <NotificationContainer>
                To change a team member from Administrator to Read Only, please{' '}
                <ContactTreasureSupportLink subject="Team Member Read Only Request">
                  {TreasureFiEmails.Support}
                </ContactTreasureSupportLink>
              </NotificationContainer>
            </Box>
          </UserPermissionsRequired>
        }
        flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
      />
      <UserList />
    </>
  );
};
