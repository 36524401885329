import React, { useEffect } from 'react';
import { PersistentError } from 'modules/page-errors/persistent-error';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';

interface PlaidAuthErrorProps {
  handleOpenPlaidModal: (itemId: string) => Promise<void>;
  bankItemId: string;
  institutionName: string;
}

export const PlaidAuthError: React.FC<PlaidAuthErrorProps> = ({
  handleOpenPlaidModal,
  bankItemId,
  institutionName,
}) => {
  const { trackEvent } = useTracking<{
    contactSupportReason?: TrackEventName;
    eventName: TrackEventName;
    section: TrackPageSection;
  }>({
    section: TrackPageSection.PersistentError,
  });

  useEffect(() => {
    trackEvent({
      eventName: TrackEventName.PlaidAccountNotAuthenticated,
    });
  }, [trackEvent]);

  return (
    <PersistentError
      button={{
        action: async () => {
          trackEvent({
            eventName: TrackEventName.PlaidFixIssueClicked,
          });

          await handleOpenPlaidModal(bankItemId);
        },
        text: 'Fix Issue',
      }}
      errorMessage={`We're no longer able to connect to ${
        institutionName || 'your bank'
      }, please login again`}
    />
  );
};
