import React from 'react';
import {
  Percentage,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { MonthlyReturn } from 'modules/dashboard/treasure-reserve/returns/components/returns-chart/returns-chart';
import { Styled } from 'modules/dashboard/treasure-reserve/returns/components/returns-chart/components/returns-chart-tooltip/returns-chart-tooltip.style';

interface ProductPieChartTooltipProps
  extends TooltipProps<ValueType, NameType> {}

export const ProductPieChartTooltip: React.FC<ProductPieChartTooltipProps> = ({
  active,
  label,
  payload,
}) => {
  return (
    <Styled.TooltipContainer p={1}>
      <Typography variant={TypographyVariant.Header4}>
        {payload?.[0].payload.productName}:{' '}
        <Percentage number={payload?.[0].payload.productAllocation} />
      </Typography>
    </Styled.TooltipContainer>
  );
};
