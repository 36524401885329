import React from 'react';
import { Box } from '@material-ui/core';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import linspace from '@stdlib/array-linspace';
import pdf from '@stdlib/stats-base-dists-normal-pdf';
import {
  Allocation,
  AllocationProducts,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { getReturnRange } from 'modules/portfolio/allocation/components/allocation-modal/components/return-distribution/utils/get-return-range';
import { colors, getColor } from 'styles/css-constants';

interface ReturnDistributionChartProps {
  allocations: Allocation;

  product: keyof Allocation;
}

const ReturnDistributionChart: React.FC<ReturnDistributionChartProps> = ({
  allocations,
  product,
}) => {
  const chartDataPoints = 1000;

  // return range of the specificed product
  const currentReturnRange = getReturnRange(allocations, product);

  // base return range according to https://treasurefi.slab.com/posts/treasure-reserve-allocation-heuristic-dnbukl9n
  // is the pro (high yield) return range
  const proReturnRange = getReturnRange(allocations, AllocationProducts.smart);

  // calculate x axis values
  const x = Array.from(
    linspace(proReturnRange.lower, proReturnRange.upper, chartDataPoints),
  );

  // for every x axis value, calculate y axis values and build an object array (returnDistribution) that recharts expects
  // filter out empty objects
  const returnDistribution = x
    .map((value, index) => {
      const yVal =
        // 5 decimal places seemed to produce the best looking graphs
        parseFloat(
          pdf(value, currentReturnRange.mean, currentReturnRange.scale).toFixed(
            5,
          ),
        );
      if (yVal > 0) {
        return {
          name: index,
          xVal: parseFloat(value.toFixed(2)),
          yVal,
        };
      }
      return null;
    })
    .filter(Boolean);

  // the data key of the x axis is the "name" property of the objects in returnDistribution.
  // given an "x" value, this function attempts to grab the corresponding "name".
  const nameOfReturnRange = (xVal: string) => {
    // first look for the rounded number,
    // then try truncating,
    // lastly, round up?
    return (
      returnDistribution.find(
        (r: any) =>
          r.xVal ===
          parseFloat(
            currentReturnRange[xVal as keyof typeof currentReturnRange].toFixed(
              2,
            ),
          ),
      )?.name ||
      returnDistribution.find(
        (r: any) =>
          r.xVal ===
          Math.trunc(
            currentReturnRange[xVal as keyof typeof currentReturnRange] * 100,
          ) /
            100,
      )?.name ||
      returnDistribution.find(
        (r: any) =>
          r.xVal ===
          parseFloat(
            currentReturnRange[xVal as keyof typeof currentReturnRange].toFixed(
              2,
            ),
          ) +
            0.01,
      )?.name
    );
  };

  return (
    <Box width="100%" height="95px" mb="15px">
      <ResponsiveContainer>
        <LineChart data={returnDistribution}>
          <XAxis hide dataKey="name" />
          <YAxis hide dataKey="yVal" />
          <ReferenceLine
            x={nameOfReturnRange('lower')}
            stroke={getColor('cinnabar' as keyof typeof colors)}
            strokeDasharray="3 3"
          />
          <ReferenceLine
            x={nameOfReturnRange('upper')}
            stroke={getColor('blue' as keyof typeof colors)}
            strokeDasharray="3 3"
          />
          <Line
            type="monotone"
            dataKey="yVal"
            stroke={getColor('stromboli' as keyof typeof colors)}
            strokeWidth={4}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export { ReturnDistributionChart };
