import React from 'react';
import { Box } from '@material-ui/core';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { colors, getColor } from 'styles/css-constants';
import { ProductData } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/portfolio-view';
import { Product } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-positions/product-positions';
import { useTracking, TrackEventName } from 'modules/tracking';
import { ProductPieChartTooltip } from './components/product-pie-chart-tooltip/product-pie-chart-tooltip';

const pieChartValueMinimum = 0.05;
const RADIAN = Math.PI / 180;

const getFill = (productType: Product) => {
  switch (productType) {
    case Product.ManagedIncome:
      return '#333';
    default:
      return 'white';
  }
};

const renderCustomizedLabel = ({
  cx,
  cy,
  endAngle,
  midAngle,
  percent,
  productAllocation,
  productType,
  innerRadius,
  outerRadius,
  startAngle,
}: {
  cx: number;
  cy: number;
  endAngle: number;
  midAngle: number;
  percent: number;
  productAllocation: number;
  productType: Product;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
}) => {
  // label within pie section
  const inRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const inX = cx + inRadius * Math.cos(-midAngle * RADIAN);
  const inY = cy + inRadius * Math.sin(-midAngle * RADIAN);

  // label outside pie section
  /* const diffAngle = endAngle - startAngle;
  const delta = 360 - diffAngle - 1;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.9;
  const x = cx + (radius + delta) * Math.cos(-midAngle * RADIAN);
  const y = cy + (radius + delta * delta) * Math.sin(-midAngle * RADIAN);

  // line path
  let path = '';
  for (let i = 0; i < delta; i++) {
    path += `${cx + (radius + i) * Math.cos(-midAngle * RADIAN)},${
      cy + (radius + i * i) * Math.sin(-midAngle * RADIAN)
    } `;
  }*/

  if (percent >= pieChartValueMinimum) {
    return (
      <text
        x={inX}
        y={inY}
        fill={getFill(productType)}
        fontSize={13}
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${productAllocation.toFixed(0)}%`}
      </text>
    );
  }

  // TODO: this solution is close, but if the labels to display
  // off the chart are above or below, they aren't shown.
  // https://github.com/recharts/recharts/issues/490
  /* return (
    <g>
      <polyline points={path} stroke={'#333'} fill="none" />
      <text
        x={x}
        y={y}
        fill={'#333'}
        fontSize={13}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );*/

  return null;
};

interface PieChartProps {
  data: ProductData[];
}

export const ProductPieChart: React.FC<PieChartProps> = ({ data }) => {
  const { trackEvent } = useTracking<{
    chartLabel: string;
    chartValue: number;
    chartValueHidden: boolean;
    component: string;
    eventName: TrackEventName;
  }>({
    component: 'Portfolio Product Pie Chart',
  });

  const handleOnClick = ({
    percent,
    productName,
  }: {
    percent: number;
    productName: string;
  }) => {
    trackEvent({
      chartLabel: productName,
      chartValue: percent,
      chartValueHidden: percent < pieChartValueMinimum,
      eventName: TrackEventName.ChartClick,
    });
  };

  const handleOnMouseEnter = ({
    percent,
    productName,
  }: {
    percent: number;
    productName: string;
  }) => {
    trackEvent({
      chartLabel: productName,
      chartValue: percent,
      chartValueHidden: percent < pieChartValueMinimum,
      eventName: TrackEventName.ChartHover,
    });
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <ProductPieChartTooltip
          active={active}
          label={label}
          payload={payload}
        />
      );
    }

    return null;
  };

  return (
    <Box
      position="relative"
      height={250}
      width={{ xs: '100%', sm: 350 }}
      mx="auto"
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            animationBegin={0}
            data={data}
            dataKey="productAllocation"
            innerRadius="55%"
            labelLine={false}
            label={renderCustomizedLabel}
            onClick={handleOnClick}
            onMouseEnter={handleOnMouseEnter}
            outerRadius="100%"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${entry.productName}`}
                fill={getColor(entry.productColor as keyof typeof colors)}
                stroke={getColor(entry.productColor as keyof typeof colors)}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};
