import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { Styled as StyledCodeBox } from './components/mfa-code-box/mfa-code-box.style';
interface CodeContainerProps {
  error?: boolean;
}

const CodeBoxContainer = styled(Grid)``;

const CodeContainer = styled(Grid)<CodeContainerProps>`
  ${CodeBoxContainer} {
    border-color: var(--color-grey6);
    border-top: 1px solid var(--color-grey6);
    border-bottom: 1px solid var(--color-grey6);

    &:first-child {
      border-left-width: 1px;
      border-left-style: solid;
      border-top-left-radius: var(--border-radius-06px);
      border-bottom-left-radius: var(--border-radius-06px);
    }

    &:last-child {
      border-right-width: 1px;
      border-right-style: solid;
      border-top-right-radius: var(--border-radius-06px);
      border-bottom-right-radius: var(--border-radius-06px);

      ${StyledCodeBox.CodeInput} {
        border-right: 0 none;
      }
    }

    ${(props) => {
      if (props.error) {
        return css`
          border-color: var(--color-red2);
        `;
      }

      return null;
    }}
  }
`;

export const Styled = {
  CodeBoxContainer,
  CodeContainer,
};
