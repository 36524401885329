import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Heading } from 'components/core';
import { TrendDirection } from 'enums';
import { ReactComponent as ArrowIcon } from 'assets/icons/caret.svg';

interface StyledArrow {
  direction: TrendDirection;
}

interface StyledIndicatorContainer {
  truncateIndicator: boolean;
}

const Arrow = styled(ArrowIcon)<StyledArrow>`
  position: relative;
  top: 5px;

  ${(props) =>
    props.direction === TrendDirection.up &&
    `
    transform: rotate(180deg);
    `}
`;

const Circle = styled.div`
  position: absolute;
  left: -22px;
  background-color: var(--color-stromboli);
  width: 15px;
  height: 15px;
  border-radius: 100%;
`;

const CircleContainer = styled.div`
  position: relative;
`;

const HeadingEllipsis = styled(Heading)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IndicatorContainer = styled(({ truncateIndicator, ...rest }) => (
  <Grid {...rest} />
))<StyledIndicatorContainer>`
  ${(props) =>
    props.truncateIndicator &&
    `
    max-width: 70%; // TODO: better solution for arrows wrapping
    `}
`;

export const Styled = {
  Arrow,
  Circle,
  CircleContainer,
  HeadingEllipsis,
  IndicatorContainer,
};
