import React from 'react';
import { Text } from 'components/core';
import CheckBoxComp from 'components/core/forms/checkbox/checkbox';
import CustomSwitch from 'components/core/forms/custom-switch/custom-switch';
import {
  factoryFormFieldComponent,
  FormFieldSpecs,
} from '../utils/factory-form-field-component';
import { Styled } from './checkbox-controller.style';

export enum CheckboxFormFieldVariants {
  checkbox = 'checkbox',
  switch = 'switch',
}

export type CheckboxFormFieldSpecs = FormFieldSpecs<{
  variant?: CheckboxFormFieldVariants;
  helperText?: React.ReactNode;
}>;

export const CheckboxController =
  factoryFormFieldComponent<CheckboxFormFieldSpecs>(
    ({
      label,
      value,
      onBlur,
      onChange,
      error,
      variant = CheckboxFormFieldVariants.checkbox,
    }) => {
      switch (variant) {
        case CheckboxFormFieldVariants.switch: {
          return (
            <CustomSwitch
              checked={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message || !!error}
            />
          );
        }
        case CheckboxFormFieldVariants.checkbox:
        default: {
          return (
            <Styled.StyledFormLabel
              checked={value}
              control={<CheckBoxComp error={!!error} />}
              onChange={onChange}
              onBlur={onBlur}
              label={
                <Text color={error ? 'torchRed' : 'black'} variant={3}>
                  {label}
                </Text>
              }
            />
          );
        }
      }
    },
    { componentName: 'CheckboxController' },
  );
