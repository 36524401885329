import React from 'react';
import { Heading } from 'components/core';
import { i18n, useTranslation, Trans } from 'app/i18n';
import Content from './copy/sign-up.content.json';
import { Styled } from './bullets.style';

const i18nNamespaceSignUp = 'sign-up';
i18n.addResourceBundle('en', i18nNamespaceSignUp, Content);

export const Bullets: React.FC = () => {
  const { t } = useTranslation(i18nNamespaceSignUp);

  const bullets = [
    'Sign-up in 10 minutes',
    'Safety without compromising yield or liquidity',
    'Allocate your funds to government backed securities',
    'Registered Investment Advisor. SEC Regulated.',
    'Actively managed by Treasure Investment Team',
  ];

  return (
    <Styled.BulletsContainer>
      <Styled.BulletsFlexWrapper>
        <Heading className="header" variant={2}>
          <Trans t={t} i18nKey="Safeguard your capital with Treasure" />
        </Heading>
        <Styled.Bullets>
          {bullets.map((bulletKey, index) => {
            return (
              <li key={bulletKey}>
                <Trans t={t} i18nKey={bulletKey} />
              </li>
            );
          })}
        </Styled.Bullets>
      </Styled.BulletsFlexWrapper>
    </Styled.BulletsContainer>
  );
};
