import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { TreasureReserve } from 'types';

export const useTreasureReserve = (
  businessId: string,
): UseQueryResult<TreasureReserve> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery<TreasureReserve, Error>(
    [ReactQueryKeys.TreasureReserve],
    async () => {
      const response = await treasureApi().get(
        `/business/${businessId}/reserve-account`,
      );

      return response.data.result;
    },
    {
      enabled: !!businessId,
    },
  );
};
