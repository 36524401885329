import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Container = styled(Box)`
  background-color: var(--color-white);
  border-radius: var(--border-radius-02);
  box-shadow: 0px 12px 19px rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 550px;
  overflow: scroll;

  @media print {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    overflow: visible;
  }
`;

export const Styled = {
  Container,
};
