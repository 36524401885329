import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { Button } from 'components/core';

export const RequestDemoButton: React.FC = () => {
  return (
    <>
      <Hidden mdUp>
        <Box px={2}>
          <a
            href="https://go.treasurefi.com/request-a-demo"
            rel="noreferrer"
            target="_blank"
          >
            Request a Demo
          </a>
        </Box>
      </Hidden>

      <Hidden smDown>
        <Box px={2}>
          <a
            href="https://go.treasurefi.com/request-a-demo"
            rel="noreferrer"
            target="_blank"
          >
            <Button variant="contained">Request a Demo</Button>
          </a>
        </Box>
      </Hidden>
    </>
  );
};
