import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box } from '@material-ui/core';
import { Modal } from 'components/core';
import { Welcome } from 'modals';
import { ReactQueryKeys, Size } from 'enums';

export const WelcomeModal: React.FC = () => {
  const queryClient = useQueryClient();
  const initialSweep: any = queryClient.getQueryData(
    ReactQueryKeys.InitialOnboardingSweep,
  );
  const [showWelcome, setShowWelcome] = useState(!!initialSweep);

  const getTransfer = () => {
    let transfer: any = undefined;

    if (initialSweep?.amount) {
      transfer = {
        totalAmount: initialSweep.amount,
        accountFrom: {
          name: initialSweep?.accountName || 'your bank account',
        },
      };
    }

    return transfer;
  };

  const onClose = () => {
    queryClient.removeQueries(ReactQueryKeys.InitialOnboardingSweep);
    setShowWelcome(false);
  };

  return (
    <>
      <Modal
        open={showWelcome}
        onClose={onClose}
        hideCloseIcon={true}
        size={Size.Small}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <Box m={5}>
          <Welcome onClose={onClose} transfer={getTransfer()} />
        </Box>
      </Modal>
    </>
  );
};
