import React from 'react';
import { PaginationProps } from '@material-ui/lab';
import { usePagination } from '@material-ui/lab/Pagination';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrows/arrow-left.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrows/arrow-right.svg';
import { Text } from 'components/core';
import { useTracking, TrackEventName } from 'modules/tracking';

import { Styled } from './pagination.style';

const Pagination: React.FC<PaginationProps> = ({ ...props }) => {
  const { trackEvent } = useTracking<{
    eventName: TrackEventName;
    paginationPage: number;
    paginationButtonType: string;
  }>({
    eventName: TrackEventName.PaginationClicked,
  });

  const { items } = usePagination(props);

  const handleOnClick = (item: any, type: string, page: number) => {
    item.onClick();

    trackEvent({
      paginationPage: page,
      paginationButtonType: type,
    });
  };

  return (
    <nav>
      <Styled.Ul>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page' && selected) {
            children = (
              <Styled.Selected
                type="button"
                {...item}
                onClick={() => handleOnClick(item, type, page)}
              >
                <Text color="white" variant={3}>
                  {page}
                </Text>
              </Styled.Selected>
            );
          } else if (type === 'page') {
            children = (
              <Styled.Button
                type="button"
                style={{ fontWeight: selected ? 'bold' : undefined }}
                {...item}
                onClick={() => handleOnClick(item, type, page)}
              >
                <Text variant={3}>{page}</Text>
              </Styled.Button>
            );
          } else {
            children = (
              <Styled.Arrow
                type="button"
                {...item}
                onClick={() => handleOnClick(item, type, page)}
              >
                {type === 'previous' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
              </Styled.Arrow>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </Styled.Ul>
    </nav>
  );
};

export { Pagination };
