import treasureApi from 'api';
import { AxiosResponse } from 'axios';
import { PolicyKey } from 'enums';

export const postConsents = async ({
  policyKeys,
  businessId,
}: {
  policyKeys: PolicyKey[];
  businessId: string;
}): Promise<AxiosResponse> => {
  const response = await treasureApi('portal', { businessId }).post(
    'consents',
    {
      policyKeys,
    },
  );

  return response.data;
};
