import React from 'react';
import { Box } from '@material-ui/core';

export const SettingsHeader: React.FC = ({ children }) => {
  return (
    <Box mb={3}>
      {children}

      <hr />
    </Box>
  );
};
