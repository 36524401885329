import React from 'react';
import PropTypes from 'prop-types';
import { Styled } from './button.style';

export interface ButtonProps {
  /** aria label */
  ariaLabel?: string | undefined;

  /** the content of the button */
  children: React.ReactNode;

  /** className for styled-components */
  className?: string;

  /** disables the button */
  disabled?: boolean;

  /** The URL to link to when the button is clicked. If defined, an a element will be used as the root node. */
  href?: string | null;

  /** Function that gets triggered when the button is clicked */
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void) | null;

  type?: 'button' | 'submit' | 'reset' | undefined;

  /** material-ui variant */
  variant?: 'contained' | 'outlined' | 'text';

  width?: number;
}

const Button: React.FC<ButtonProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  href,
  onClick,
  type,
  variant,
  width,
}) => {
  return (
    <Styled.Button
      aria-label={ariaLabel}
      className={className}
      disabled={disabled}
      disableElevation
      href={href || undefined}
      onClick={onClick || undefined}
      type={type}
      variant={variant || 'contained'}
      $width={width}
    >
      {children}
    </Styled.Button>
  );
};

Button.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset', undefined]),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  width: PropTypes.number,
};

export { Button };
