import React from 'react';
import { Close } from '@material-ui/icons';
import { ClickAnchor } from 'components/core';
import { ProgressSpinner } from 'modules/2023-q3/components/progress-spinner/progress-spinner';
import { useDeleteScheduleTransfer } from './hooks/use-delete-schedule-transfer';

interface IDeleteScheduleTransfer {
  id: string;
}

export const DeleteScheduleTransfer: React.FC<IDeleteScheduleTransfer> = ({
  id,
}) => {
  const deleteScheduleTransfer = useDeleteScheduleTransfer();

  const handleDeleteScheduleTransfer = () => {
    deleteScheduleTransfer.mutate({ id });
  };

  if (deleteScheduleTransfer.isLoading) {
    return <ProgressSpinner />;
  }

  return (
    <ClickAnchor onClick={handleDeleteScheduleTransfer}>
      <Close />
    </ClickAnchor>
  );
};
