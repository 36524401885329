import styled from 'styled-components';
import { Styled as StyledTextField } from '../text-field/text-field.style';

const TextField = styled(StyledTextField.TextField)`
  .MuiAutocomplete-inputRoot {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const AutocompleteOption = styled.div`
  &.bankAccounts {
    .heading {
      font-weight: var(--font-weight-bold);
    }
  }
`;

export const Styled = {
  TextField,
  AutocompleteOption,
};
