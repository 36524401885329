import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { Typography } from 'components/core';

interface ContainerProps {
  selected: boolean;
}

const Container = styled(Box)<ContainerProps>`
  background-color: var(--color-grey7);
  border-radius: 100px;

  &:hover {
    background-color: var(--color-brown4a);
  }

  ${(props) => {
    if (props.selected) {
      return css`
        background-color: var(--color-black19);

        &:hover {
          background-color: var(--color-black19);
        }
      `;
    }
  }}
`;

const TimeFilterLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
`;

export const Styled = {
  Container,
  TimeFilterLabel,
};
