export enum TreasureReserveStatus {
  AwaitingSignatures = 'awaiting signatures',
  Closed = 'closed',
  CustodialReview = 'custodial review',
  DueDilligence = 'due dilligence',
  InternalReview = 'internal review',
  OpenAndUnfunded = 'open and unfunded',
  OpenAndFunded = 'open and funded',
  None = 'none',
}
