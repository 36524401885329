import styled from 'styled-components';
import { Styled as BankingAccountRow } from 'components/banking/v3/banking-account-row/banking-account-row.style';
import { Heading } from 'components/core';

const TreasureReserveHeading = styled(Heading)`
  font-weight: 700;
`;

export const Styled = {
  ...BankingAccountRow,
  TreasureReserveHeading,
};
