import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Heading, Spinner, Text } from 'components/core';
import { TreasureLogoHorizontal } from 'assets';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import {
  WhiteLabelLogo,
  WhiteLabelLogoVariant,
} from 'modules/2023-q4/white-label/components/white-label-logo/white-label-logo';

import { createCtx } from 'utils/create-ctx';

import { Styled } from './treasure-logo-box.style';

interface LoadingContext {
  loading: boolean;

  setLoading: (loading: boolean) => void;
}

export const [useLoadingContext, LoadingCtxProvider] =
  createCtx<LoadingContext>();

export interface TreasureLogoBoxProps {
  children: React.ReactNode;

  headingText?: string;

  footerLinks?: {
    path: string;
    text: string;
  }[];
}

export const TreasureLogoBox: React.FC<TreasureLogoBoxProps> = ({
  children,
  headingText,
  footerLinks,
}) => {
  const [loading, setLoading] = useState(false);

  const renderFooterLinks = () => {
    return footerLinks?.map((footerLink, index) => {
      return (
        <Box mt={1} textAlign="center" key={footerLink.text}>
          <Text color="hanPurple" variant={2}>
            <Styled.NavLink to={footerLink.path}>
              {footerLink.text}
              <Styled.IconChevronRight />
            </Styled.NavLink>
          </Text>
        </Box>
      );
    });
  };

  return (
    <Styled.TreasureBox mx="auto" mt={{ sm: 13 }} py={{ xs: 5, sm: 10 }}>
      <Box mb={1} textAlign="center">
        <FeatureFlag
          disabled={
            <img
              alt="Treasure logo"
              src={TreasureLogoHorizontal}
              width={158}
              height={25}
            />
          }
          enabled={
            <Box px={5}>
              <WhiteLabelLogo variant={WhiteLabelLogoVariant.Default} />
            </Box>
          }
          flag={FeatureFlags.REACT_APP_2023_Q4_WHITELABEL_ENABLED}
        />
      </Box>

      {headingText && (
        <Box mb={5} textAlign="center">
          <Heading variant={1}>{headingText}</Heading>
        </Box>
      )}

      {loading ? (
        <Spinner />
      ) : (
        <>
          <LoadingCtxProvider value={{ loading, setLoading }}>
            {children}
          </LoadingCtxProvider>

          {renderFooterLinks()}
        </>
      )}
    </Styled.TreasureBox>
  );
};

TreasureLogoBox.propTypes = {
  children: PropTypes.node.isRequired,
  headingText: PropTypes.string,
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ),
};
