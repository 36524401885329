import React, { useEffect, useState } from 'react';
import {
  DepositWithdrawalSteps,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useLocation } from 'react-router-dom';
import { Header } from 'modules/2023-q3/deposit-withdrawal/components/header/header';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { useTracking, TrackEventName } from 'modules/tracking';
import { ApexWireInstructions } from 'modules/2024-q3/grasshopper-sunset/deposit/deposit-apex-wire-instructions/components/apex-wire-instructions/apex-wire-instructions';

interface DepositProps {
  hideContainer?: boolean;
  hideHeader?: boolean;
  onStepChange?: (step: number) => void;
}

export const DepositApexWireInstructions: React.FC<DepositProps> = ({
  hideContainer,
  hideHeader,
  onStepChange,
}) => {
  const { state }: { state: any } = useLocation();
  const from = state?.from;

  const [step, setStep] = useState(DepositWithdrawalSteps.WireInstructions);

  const { Track, trackEvent } = useTracking<{
    eventName?: TrackEventName;
    step: string;
  }>({
    step: DepositWithdrawalSteps[step],
  });

  const nextStep = () => {
    switch (step) {
      default:
        return null;
    }
  };

  const previousStep = () => {
    switch (step) {
      default:
        return null;
    }
  };

  const displayStep = (step: DepositWithdrawalSteps) => {
    switch (step) {
      case DepositWithdrawalSteps.WireInstructions:
        return <ApexWireInstructions from={from} />;
      default:
        return <ApexWireInstructions from={from} />;
    }
  };

  useEffect(() => {
    if (typeof onStepChange !== 'undefined') {
      onStepChange(step);
    }

    trackEvent({
      eventName: TrackEventName.Viewed,
      step: DepositWithdrawalSteps[step],
    });
  }, [step]);

  return (
    <Track>
      {!hideHeader && (
        <Header
          from={from}
          previousStep={previousStep}
          step={step}
          variant={DepositWithdrawalVariant.Deposit}
        />
      )}

      {hideContainer ? (
        displayStep(step)
      ) : (
        <CenteredContainer>{displayStep(step)}</CenteredContainer>
      )}
    </Track>
  );
};
