import React from 'react';
import { Box } from '@material-ui/core';
import { TextFieldController } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { Typography, TypographyVariant } from 'components/core';
import {
  maxCompanyNameLength,
  maxEmailLength,
  isValidEmail,
} from 'utils/forms/validators';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { NoAuthContainer } from 'modules/2023-q4/no-auth/no-auth-container/no-auth-container';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { TreasureFiLinks } from 'enums';

interface SignUpFormProps {
  handleSubmit: any;
  control: any;
  isLoading: boolean;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({
  handleSubmit,
  control,
  isLoading,
}) => {
  const formFields = {
    businessName: {
      name: 'businessName',
      label: 'Business name',
      validations: {
        required: true,
        maxLength: maxCompanyNameLength,
      },
    },
    email: {
      name: 'email',
      label: 'Business email',
      validations: {
        required: true,
        maxLength: maxEmailLength,
        validate: (value: string) =>
          isValidEmail(value) || 'Please use a valid email address',
      },
    },
  };
  const { trackEvent } = useTracking();

  return (
    <NoAuthContainer>
      <Box mt={9.25} mb={3} textAlign="center">
        <Typography color="black19" variant={TypographyVariant.Header3}>
          Sign up for free
        </Typography>

        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Activate your idle cash today
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <TextFieldController {...formFields.businessName} control={control} />
        </Box>

        <Box mb={3}>
          <TextFieldController {...formFields.email} control={control} />
        </Box>

        <Box mb={3}>
          <Button type="submit" disabled={isLoading} fullWidth>
            <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
              <Typography color="white" variant={TypographyVariant.Body}>
                Continue
              </Typography>
            </ButtonContent>
          </Button>
        </Box>
      </form>

      <Box textAlign="center">
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Already have an account?{' '}
          <Link
            to={RoutesPath.noAuth.signIn.path}
            onClick={() =>
              trackEvent({ eventName: TrackEventName.SignUpSignInClicked })
            }
          >
            <Typography color="blue2" variant={TypographyVariant.Paragraph2}>
              Log in here
            </Typography>
          </Link>
        </Typography>

        <Box mt={2}>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            Questions about Treasure?
            <br />
            <a
              href="https://go.treasurefi.com/request-a-demo?utm_source=sign-up-page&utm_medium=demo-request&utm_campaign=sign-up-page"
              rel="noreferrer"
              target="_blank"
            >
              <Typography color="blue2" variant={TypographyVariant.Paragraph2}>
                Request a demo
              </Typography>
            </a>
          </Typography>
        </Box>

        <Box mt={10}>
          <Typography color="grey1" variant={TypographyVariant.Paragraph3}>
            For investment disclosures, view our{' '}
            <a
              target="_blank"
              href={TreasureFiLinks.TermsOfUse}
              rel="noreferrer"
            >
              <Typography color="blue2" variant={TypographyVariant.Paragraph3}>
                terms of service
              </Typography>
            </a>{' '}
            and our{' '}
            <a
              target="_blank"
              href={TreasureFiLinks.PrivacyPolicy}
              rel="noreferrer"
            >
              <Typography color="blue2" variant={TypographyVariant.Paragraph3}>
                privacy policy
              </Typography>
            </a>
          </Typography>
        </Box>
      </Box>
    </NoAuthContainer>
  );
};
