import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { ApexDocument } from 'types';

export const useDocuments = (
  businessId: string,
): UseQueryResult<ApexDocument[], Error> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  return useQuery<ApexDocument[], Error>(
    [ReactQueryKeys.Documents],
    async () => {
      try {
        const response = await treasureApi().get('/documents');

        return response.data.documents;
      } catch (error) {
        return [];
      }
    },
  );
};
