import styled from 'styled-components';
import { Container } from '@material-ui/core';

const LayoutContainer = styled(Container)`
  background-color: var(--color-whiteSmoke4);
  min-height: 100vh;
`;

export const Styled = {
  LayoutContainer,
};
