import React from 'react';
import { toast, TypeOptions } from 'react-toastify';
import { ToastMessage } from './components/toast-message/toast-message';
import { ToastCloseButton } from './components/toast-close-button/toast-close-button';

export default {
  show(type: TypeOptions, msg: string, options = {}) {
    return toast(<ToastMessage type={type} message={msg} />, {
      closeButton: <ToastCloseButton />,
      type,
      ...options,
    });
  },
  showError(error: any, options = {}) {
    const type = 'error';
    let msg = 'Oops! An error occurred, please try again later';

    msg =
      (error.response?.data?.message ||
        error.response?.data?.errors?.[0]?.error_message ||
        error.response?.data?.errors ||
        error.response?.data?.error ||
        error?.message) ??
      msg;

    return toast(<ToastMessage type={type} message={msg} />, {
      closeButton: <ToastCloseButton />,
      type,
      ...options,
    });
  },
};
