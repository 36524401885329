import React from 'react';
import { InputLabelProps } from '@material-ui/core/InputLabel';
import { Styled } from './input-label.style';

interface IInputLabel extends InputLabelProps {
  cursor?: string;
}

export const InputLabel: React.FC<IInputLabel> = ({
  cursor,
  children,
  ...rest
}) => {
  return (
    <Styled.InputLabel cursor={cursor} {...rest}>
      {children}
    </Styled.InputLabel>
  );
};
