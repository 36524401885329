import React from 'react';
import { Typography, TypographyVariant } from 'components/core';

interface SectionTitleProps {
  title: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => {
  return <Typography variant={TypographyVariant.Header4}>{title}</Typography>;
};
