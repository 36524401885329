export const calculate12MValueAndYield = ({
  amount,
  rate,
  fee,
}: {
  amount: number;
  rate: number;
  fee: number;
}) => {
  let valueSum = amount;

  const treasureNetRate = rate - fee;

  let i = 0;
  while (i < 12) {
    const monthValue = valueSum * ((1 + treasureNetRate / 100) ** (1 / 12) - 1);

    valueSum += monthValue;
    i += 1;
  }

  return {
    value: valueSum,
    yield: Number((valueSum - amount).toFixed(2)),
  };
};
