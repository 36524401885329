import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Currency, CurrencyVariant, ItemDefinition } from 'components/core';
import { DashboardType, TrendDirection } from 'enums';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from './top-indicator.style';

export enum IndicatorType {
  AvailableToSpend,
  CashReserve,
  IdleCash,
  NetCashFlow,
  Runway,
  TotalCash,
}

export interface IndicatorProps {
  handleClick?:
    | ((
        event: React.MouseEvent<HTMLDivElement>,
        indicator: IndicatorProps,
      ) => void)
    | null;

  label: string;

  type: IndicatorType;

  value: string | number | undefined;
}

export interface TopIndicatorProps {
  dashboard: DashboardType;

  indicator: IndicatorProps;

  isPopOut?: boolean;

  showCircle?: boolean;
}

const TopIndicator: React.FC<TopIndicatorProps> = ({
  dashboard,
  indicator,
  isPopOut,
  showCircle,
}) => {
  const { trackEvent } = useTracking();
  const showArrow = indicator.handleClick;

  function renderIndicatorValue(value: number | string | undefined) {
    if (typeof value === 'string') return value;

    if (typeof value === 'number')
      return (
        <Currency number={value || 0} variant={CurrencyVariant.NearestDollar} />
      );

    return '--';
  }

  useEffect(
    () => () => {
      isPopOut &&
        trackEvent({
          eventName: TrackEventName.TopIndicatorDrilldownClosed,
          indicator: indicator.label,
        });
    },
    [],
  );

  return (
    <Box
      py={2}
      px={6}
      display="inline-block"
      textAlign="left"
      width={dashboard === DashboardType.Dashboard ? '100%' : null}
    >
      <Styled.CircleContainer>
        {isPopOut && showCircle !== false && <Styled.Circle />}

        {/* safari spacing issue: https://github.com/mui-org/material-ui/issues/17142 */}
        <ItemDefinition
          label={indicator.label}
          value={
            <Grid container spacing={0} justifyContent="space-between">
              <Styled.IndicatorContainer
                item
                truncateIndicator={showArrow && showCircle}
              >
                <Styled.HeadingEllipsis variant={2}>
                  {renderIndicatorValue(indicator.value)}
                </Styled.HeadingEllipsis>
              </Styled.IndicatorContainer>

              {showArrow && (
                <Grid item>
                  <Box ml={0.5}>
                    <Styled.Arrow
                      direction={
                        isPopOut ? TrendDirection.up : TrendDirection.down
                      }
                      role="img"
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          }
        />
      </Styled.CircleContainer>
    </Box>
  );
};

TopIndicator.propTypes = {
  dashboard: PropTypes.oneOf(Object.values(DashboardType) as DashboardType[])
    .isRequired,
  indicator: PropTypes.shape({
    handleClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(IndicatorType) as IndicatorType[])
      .isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  isPopOut: PropTypes.bool,
  showCircle: PropTypes.bool,
};

export { TopIndicator };
