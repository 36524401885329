import React from 'react';
import PropTypes from 'prop-types';
import { TosLinks } from 'modules/sign-up/tos-links';
import { TreasureLogoHorizontal } from 'assets';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { Footer, FootnoteProvider } from 'components/layouts';
import { Styled } from './v2-layout.style';

interface V2LayoutProps {
  children?: React.ReactNode;

  hideFooter?: boolean;
}

export const V2Layout: React.FC<V2LayoutProps> = ({ children, hideFooter }) => {
  return (
    <FootnoteProvider>
      <Styled.Wrapper>
        <div id="img-container">
          <img
            alt="Treasure logo"
            src={TreasureLogoHorizontal}
            width={158}
            height={25}
          />
        </div>

        {children}

        {hideFooter !== true && (
          <>
            <TosLinks />

            <Styled.FooterContainer>
              <CenteredContainer>
                <Footer
                  dividerColor="hitGrey"
                  linkColor="hitGrey"
                  textColor="hitGrey"
                  topDivider={false}
                />
              </CenteredContainer>
            </Styled.FooterContainer>
          </>
        )}
      </Styled.Wrapper>
    </FootnoteProvider>
  );
};

V2Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
