import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import { Typography as TypographyAlias } from 'components/core';

const Plus = styled(AddIcon)`
  position: relative;
  top: 4px;
`;

const Typography = styled(TypographyAlias)`
  position: relative;
  top: -2px;
`;

export const Styled = {
  Plus,
  Typography,
};
