import React from 'react';
import PropTypes from 'prop-types';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { FormControl } from '../common/form-control';
import { Styled } from './text-field.style';

export interface TextFieldProps {
  currency?: boolean;

  disabled?: boolean;

  error?: boolean;

  helperText?: string;

  label?: string;

  labelAddon?: React.ReactNode;

  labelVariant?: number;

  leftLabel?: boolean;

  optional?: boolean;

  placeholder?: string;
}

const TextField: React.FC<TextFieldProps & MuiTextFieldProps> = ({
  currency,
  disabled,
  error,
  helperText,
  label,
  labelAddon,
  labelVariant,
  leftLabel,
  optional,
  placeholder,
  ...textFieldProps
}) => {
  return (
    <FormControl
      currency={currency}
      error={error}
      helperText={helperText}
      label={label}
      labelAddon={labelAddon}
      labelVariant={labelVariant}
      leftLabel={leftLabel}
      optional={optional}
    >
      <Styled.TextField
        error={error}
        placeholder={placeholder}
        variant="outlined"
        disabled={disabled}
        {...textFieldProps}
      />
    </FormControl>
  );
};

TextField.propTypes = {
  currency: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  labelAddon: PropTypes.node,
  labelVariant: PropTypes.number,
  leftLabel: PropTypes.bool,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
};

export { TextField };
