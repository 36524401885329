import React, { useEffect } from 'react';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';
import { TreasureFiEmails } from 'enums';
import { useTracking, TrackEventName } from 'modules/tracking';

interface BankPlaidDataStaleProps {
  bankName: string;
}

export const BankPlaidDataStale: React.FC<BankPlaidDataStaleProps> = ({
  bankName,
}) => {
  const { trackEvent } =
    useTracking<{
      eventName: TrackEventName;
    }>();

  useEffect(() => {
    trackEvent({ eventName: TrackEventName.PlaidStaleDataErrorShown });
  }, []);

  return (
    <>
      We haven&apos;t received recent transaction updates from {bankName}.
      Please{' '}
      <ContactTreasureSupportLink subject="Haven't received recent transcations">
        contact support
      </ContactTreasureSupportLink>{' '}
      if this is inaccurate.
    </>
  );
};
