import React, { useState } from 'react';
import { Modal } from 'components/core';
import { Size } from 'enums';
import { useBusiness } from 'hooks/business/use-business';
import { useTracking, TrackEventName } from 'modules/tracking';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';
import { useStoreGrowthPromo } from './stores/use-store-growth-promo';
import { GrowthPromoDrawer } from './components/growth-promo-drawer/growth-promo-drawer';
import { GrowthPromoModal } from './components/growth-promo-modal/growth-promo-modal';
import { GrowthPromoNotification } from './components/growth-promo-notification/growth-promo-notification';

export const GrowthPromo = () => {
  const { trackEvent, Track } = useTracking<{
    clickElement?: string;
    clickText?: string;
    component?: string;
    drawerDirection: string;
    eventName: TrackEventName;
    feature: string;
  }>({
    feature: '2023 December Growth Promo',
  });

  const { closedPromo, setClosedPromo } = useStoreGrowthPromo((state) => state);
  const { partnerId } = useStoreWhiteLabelPartner((state) => state);

  const { data: business } = useBusiness();
  const [openModal, setOpenModal] = useState(
    !business?.Properties?.claimed2023DecemberGrowthPromo && !closedPromo,
  );

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawer = ({
    clickElement,
    clickText,
    drawerDirection,
  }: {
    clickElement?: string;
    clickText?: string;
    drawerDirection: boolean;
  }) => {
    setOpenDrawer(drawerDirection);

    trackEvent({
      clickElement,
      clickText,
      component: 'Growth Promo Details',
      drawerDirection: drawerDirection ? 'open' : 'close',
      eventName: TrackEventName.Click,
    });
  };

  const closeModal = () => {
    setOpenModal(false);
    setClosedPromo();
  };

  const handleModalClose = ({ showDetails }: { showDetails?: boolean }) => {
    closeModal();

    if (showDetails) {
      setOpenDrawer(true);
    }

    trackEvent({
      clickElement: 'Close Button',
      component: 'Growth Promo Modal',
      eventName: TrackEventName.Click,
    });
  };

  const blockedCompanies = [
    'fe5bbdf5-e308-49c2-a6bc-45bec53d2bbb',
    'b04aeac9-e42e-4c5d-9df2-fe0e41e5eefb',
    '2b7067fa-62a7-4e6d-9cd4-3e78ada3ba54',
    '7954cfda-f0bd-4dd5-bbc2-f7782bb257b0',
    '14e6dd1e-c645-46fc-8a5d-eb8da7385d45',
  ];

  if (blockedCompanies.includes(business?.Id)) {
    return null;
  }

  if (business?.Properties?.blocked2023DecemberGrowthPromo) {
    return null;
  }

  // if white label partner, don't show Treasure Promo
  if (partnerId) {
    return null;
  }

  return (
    <Track>
      <GrowthPromoNotification handleDrawer={handleDrawer} />

      <GrowthPromoDrawer handleDrawer={handleDrawer} open={openDrawer} />

      <Modal
        hideCloseIcon
        open={openModal}
        onClose={closeModal}
        size={Size.Medium}
      >
        <GrowthPromoModal closeModal={handleModalClose} />
      </Modal>
    </Track>
  );
};
