import React from 'react';
import { Button } from 'components/core';
import { PageErrorMessage } from 'modules/page-errors/page-error-message';
import { sentryLog } from 'utils/sentryHelper';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState({
      hasError: true,
    });
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    sentryLog({
      error: error,
      customTag: errorInfo as string,
    });
  }

  componentDidMount() {
    // Add an event listener to catch unhandled promise rejections
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(
      'unhandledrejection',
      this.promiseRejectionHandler,
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageErrorMessage
          errorAction={
            <Button href="mailto:support@treasure.tech?subject=Contact Support">
              Contact Us
            </Button>
          }
          errorMessage="Oops! An error has occurred"
        />
      );
    }

    return this.props.children;
  }
}
