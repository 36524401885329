import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useStoreAuthentication, useStoreBusiness } from 'stores';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';
import { useQuery, UseQueryResult } from 'react-query';
import { useLoginRecord } from 'hooks/portal-gateway/use-login-record';
import { ReactQueryKeys } from 'enums';

export const useBusiness = (): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Business);
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);
  const setBusinessId = useStoreBusiness((state) => state.setBusinessId);
  const loginRecord = useLoginRecord();
  const { partnerId, setPartnerId } = useStoreWhiteLabelPartner(
    (state) => state,
  );

  return useQuery(
    [ReactQueryKeys.Business],
    async () => {
      const response = await treasureApi().get('/member');

      if (response.data.Id) {
        // set the business id in the store
        // this is used to set the x-business-id header in every api call
        setBusinessId(response.data.Id);

        // trigger lastLoggedIn
        // passing through businessId in case there's a race condition with above
        loginRecord.mutate({
          businessId: response.data.Id,
        });
      }

      // if the user is logging in from a partner url but their account
      // isn't associated with that partner, we need to set the partnerId
      // to the proper whitelabel
      // most users should have an undefined property which will show the Treasure UI
      setPartnerId(response.data.Properties.whiteLabelPartnerId);

      // because the css is loaded first, we need to reload it
      // hacky way is reloading the window.
      // TODO: find a better way (probably context from app.tsx) and refactor
      if (partnerId !== response.data.Properties.whiteLabelPartnerId) {
        window.location.reload();
      }

      return response.data;
    },
    {
      enabled: isSignedIn,
      staleTime: Infinity,
    },
  );
};
