import React, { useRef } from 'react';
import {
  i18nNamespace,
  IDepositWithdrawal,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { dayjs } from 'app/dayjs';
import { Box, FormControl, TextField } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { InputLabel } from 'modules/2023-q3/components/forms/components/input-label/input-label';
import { Styled } from 'modules/2023-q3/deposit-withdrawal/pages/form/components/input-amount/input-amount.style';
import { useTracking, TrackEventName } from 'modules/tracking';

export interface IInputDate extends IDepositWithdrawal {
  startDate: string;

  setStartDate: (startDate: string) => void;
}

export const InputDate: React.FC<IInputDate> = ({
  startDate,
  setStartDate,
  variant,
}) => {
  const dateRef = useRef<any>(null);
  const { trackEvent } = useTracking();
  const { t } = useTranslation(i18nNamespace);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;
  const depositOrWithdrawal = t(isDeposit ? 'Deposit' : 'Withdrawal');
  const depositOrWithdrawalLowercase = t(isDeposit ? 'deposit' : 'withdrawal');

  const label = t(`First ${depositOrWithdrawal} Date`);

  const handleDateClick = () => {
    dateRef.current?.showPicker();
  };

  const handleDateChange = (event: any) => {
    let newDate = event.target.value;
    if (!newDate) {
      newDate = dayjs().format('YYYY-MM-DD');
    }
    setStartDate(newDate);
    event.currentTarget.blur(); // hacky fix to take focus away from input
  };

  return (
    <FormControl fullWidth>
      <Box mb={1.5}>
        <InputLabel disableAnimation htmlFor="startDate" shrink>
          <Typography color="black19" variant={TypographyVariant.Header5}>
            {label}
          </Typography>
        </InputLabel>
      </Box>

      <TextField
        fullWidth
        id="startDate"
        InputProps={{
          disableUnderline: true,
          inputProps: {
            min: dayjs().format('YYYY-MM-DD'),
            onKeyDown: (e) => e.preventDefault(),
            ref: dateRef,
          },
        }}
        onChange={handleDateChange}
        onClick={handleDateClick}
        placeholder={`Date to initiate the ${depositOrWithdrawalLowercase}`}
        type="date"
        value={startDate}
      />
    </FormControl>
  );
};
