import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface WhiteLabelPartnerState {
  partnerId: string | undefined;
  setPartnerId: (partnerId: string) => void;
}

export const useStoreWhiteLabelPartner = create<WhiteLabelPartnerState>()(
  devtools(
    persist(
      (set) => ({
        partnerId: undefined,
        setPartnerId: (partnerId: string) => set({ partnerId }),
      }),
      {
        name: 'white-label-partner',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
