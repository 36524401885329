import React from 'react';
import { Link } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { Button, TypographyVariant } from 'components/core';
import { Styled } from './button-add-account.style';

export const ButtonAddAccount: React.FC = () => {
  return (
    <Link
      to={getVersionedPath({
        path: RoutesPath.pages.connectBankAccounts.path,
        version: 3,
      })}
    >
      <Button variant="outlined">
        <Styled.Typography
          color="stromboli"
          variant={TypographyVariant.BodySemiBold}
        >
          <Styled.Plus fontSize="small" /> Add account
        </Styled.Typography>
      </Button>
    </Link>
  );
};
