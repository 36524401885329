import { useState } from 'react';

// firebase
import firebase from 'utils/firebase';

// mixpanel
import mixpanel from 'modules/tracking/analytics/mixpanel';

// react-query
import { useQueryClient } from 'react-query';

// sentry
import { setSentryUser } from 'utils/sentryHelper';

// store
import {
  useStoreAuthentication,
  useStoreBusiness,
  useStoreLoginMFA,
  useStorePlaidBankError,
} from 'stores';
import { useStoreGrowthPromo } from 'modules/2023-q4/growth-promo/stores/use-store-growth-promo';

export const useAuthenticateUser = () => {
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(true);

  const { isSignedIn, signIn, signOut } = useStoreAuthentication(
    (state) => state,
  );

  const resetBusinessId = useStoreBusiness((state) => state.resetBusinessId);
  const { resetPassedMFA } = useStoreLoginMFA((state) => state);
  const { resetShowError } = useStorePlaidBankError((state) => state);
  const { resetClosedPromo } = useStoreGrowthPromo((state) => state);

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      if (!isSignedIn) {
        signIn();
      }

      mixpanel.identify(user.uid);
      mixpanel.people.set({
        $email: user.email,
        fbUid: user.uid,
      });

      setSentryUser({ email: user.email });
    } else if (isSignedIn) {
      // sign the user out
      signOut();
      // when the user is signed out, reset the business id
      // used in the x-business-id header for api calls
      resetBusinessId();

      // end mfa session (TODO: if/when we have more stores, end all sessions)
      resetPassedMFA();
      resetShowError();
      resetClosedPromo();

      // clear all the queries
      queryClient.clear();
    }

    setIsLoading(false);
  });

  return { isLoading };
};
