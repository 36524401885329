import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useStoreAuthentication } from 'stores';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useBusinesses = (): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Business);
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);

  return useQuery(
    [ReactQueryKeys.Businesses],
    async () => {
      const { data: businesses } = await treasureApi().get(
        '/member/businesses',
      );

      if (businesses.length) {
        // TODO: custodian's business/reserve-account/find returns data from treasure reserve
        // treasure reserve currently only has apex balances
        // we'll need a new call that returns the blended apex and grasshopper balance for multiple businesses
        /* const smbIds = Object.values(businesses).map(
          (business: any) => business.Id,
        );

        const { data: treasureReserves } = await treasureApi('custodian').post(
          'business/reserve-account/find',
          { search: { smbIds } },
        );

        businesses.map((business: any) => {
          business.treasureReserve = treasureReserves.find(
            (treasureReserve: any) =>
              treasureReserve.businessId === business.Id,
          );
        }); */

        // IRI-506 Patch
        /* loop through each business and call topIndicators to get the cashReserve balance
         * which for now has the blended apex and grasshopper balance
         */
        await businesses.forEach(async (business: any) => {
          const topIndicatorsResponse: any = await treasureApi({
            api: ApiKeys.Banking,
            businessId: business.Id,
          }).get('/accounts/indicators', {
            params: { id: business.Id },
          });

          business.balance = topIndicatorsResponse.data.cashReserve?.value;
        });
      }

      return businesses;
    },
    {
      enabled: isSignedIn,
      staleTime: Infinity,
    },
  );
};
