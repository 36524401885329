import React from 'react';
import PropTypes from 'prop-types';
import { ClickAnchor } from 'components/core';
import { useTracking, TrackEventName } from 'modules/tracking';
import { ReactQueryKeys } from 'enums';
import { useMultipleAccountContext } from 'components/layouts/contexts/multiple-account';
import { useStoreBusiness } from 'stores';

// react-query
import { useQueryClient } from 'react-query';

interface MultiAccountSelectProps {
  businessId: string;

  children: React.ReactNode;
}

export const MultiAccountSelect: React.FC<MultiAccountSelectProps> = ({
  businessId,
  children,
}) => {
  const { setBusinessId } = useStoreBusiness();
  const multipleAccountContext = useMultipleAccountContext();
  const queryClient = useQueryClient();
  const { trackEvent } = useTracking();

  const selectBusiness = (selectedBusinessId: string) => {
    // set the business id in the store
    setBusinessId(selectedBusinessId);

    // clear all the queries
    queryClient.clear();

    // close the modal
    multipleAccountContext.setAccountSelectionOpen(false);

    trackEvent({
      eventName: TrackEventName.MultipleAccountSelected,
    });
  };

  return (
    <ClickAnchor onClick={() => selectBusiness(businessId)} hoverUnderline>
      {children}
    </ClickAnchor>
  );
};

MultiAccountSelect.propTypes = {
  businessId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
