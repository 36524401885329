import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { colors, getColor } from 'styles/css-constants';

export interface PieChartData {
  color: string;
  name: string;
  value: number;
}

interface PieChartProps {
  data: PieChartData[];

  innerRadius?: string;

  innerText?: React.ReactNode;

  height?: string;

  width?: string;
}

const AllocationPieChart: React.FC<PieChartProps> = ({
  data,
  innerRadius,
  innerText,
  height,
  width,
}) => {
  return (
    <Box
      position="relative"
      height={height ?? '100px'}
      width={width ?? '100px'}
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            animationBegin={0}
            data={data}
            dataKey="value"
            innerRadius={innerRadius ?? '60%'}
            outerRadius="100%"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={getColor(entry.color as keyof typeof colors)}
                stroke={getColor(entry.color as keyof typeof colors)}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      {innerText && (
        <Box position="absolute" top="0" right="0" bottom="0" left="0">
          {innerText}
        </Box>
      )}
    </Box>
  );
};

AllocationPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

export { AllocationPieChart };
