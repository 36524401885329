import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export enum Period {
  'DAILY' = 'DAILY',
  'MONTHLY' = 'MONTHLY',
}

export const useAUMBalances = ({
  endDate,
  period,
  startDate,
}: {
  endDate: string;
  period: Period;
  startDate: string;
}): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  return useQuery(
    [ReactQueryKeys.AUMBalances, startDate, endDate, period],
    async () => {
      const response = await treasureApi().get(
        `/business/aum_balances?startDate=${startDate}&endDate=${endDate}&period=${period}`,
      );

      return response.data;
    },
  );
};
