import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useSendMFACode = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const sendMFACode = async () => {
    const { data } = await treasureApi().put('/sweeps/auth/email');

    return data;
  };

  return useMutation(sendMFACode);
};
