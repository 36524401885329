import React from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { Toast } from 'components/layouts';
import { useForm } from 'react-hook-form';
import { TextFieldController } from 'modules/form-helpers';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { UserPermissionsRequired } from 'modules/2023-q4/rbac/components/user-permissions-required/user-permissions-required';
import { Cusip } from '../types/cusip';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useAddRestrictedCusip } from '../hooks/use-add-restricted-cusip';

export interface RestrictedCusipForm {
  restrictedCusip: string;
}

interface AddRestrictedCusipFormProps {
  restrictedCusips: Cusip[];
}

export const AddRestrictedCusipForm: React.FC<AddRestrictedCusipFormProps> = ({
  restrictedCusips,
}) => {
  const { data: business } = useBusiness();

  const addRestrictedCusip = useAddRestrictedCusip();

  const maxRestrictedCusips = restrictedCusips && restrictedCusips.length >= 4;

  const { control, handleSubmit, reset } = useForm<RestrictedCusipForm>();

  const onSubmit = async (data: RestrictedCusipForm) => {
    if (maxRestrictedCusips) {
      Toast.show(
        'error',
        'A maximum of 4 restricted securities can be added. Please contact support if you have additional questions',
      );
      return;
    }

    try {
      await addRestrictedCusip.mutate(
        {
          businessId: business?.Id,
          cusip: data.restrictedCusip,
        },
        {
          onSuccess: () => reset({ restrictedCusip: '' }),
        },
      );
    } catch {
      //
    }
  };

  const restrictSecurityButton = (
    <Button disabled={maxRestrictedCusips} onClick={handleSubmit(onSubmit)}>
      <ButtonContent>Restrict Security</ButtonContent>
    </Button>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={3}>
        <TextFieldController
          control={control}
          label="Enter CUSIP"
          name="restrictedCusip"
          placeholder="example: 193128381"
          validations={{
            required: 'CUSIP is required',
            minLength: {
              value: 9,
              message: 'CUSIP is 9 characters long',
            },
            maxLength: {
              value: 9,
              message: 'CUSIP is 9 characters long',
            },
            pattern: {
              value: /^[a-zA-Z0-9*@#]{9}$/,
              message: 'Please enter a valid CUSIP',
            },
          }}
        />
      </Box>

      <FeatureFlag
        disabled={<>{restrictSecurityButton}</>}
        enabled={
          <UserPermissionsRequired roles={[UserRole.ADMIN]}>
            {restrictSecurityButton}
          </UserPermissionsRequired>
        }
        flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
      />

      {maxRestrictedCusips && (
        <Box mt={2}>
          <Text color="amaranth" variant={1}>
            A maximum of 4 restricted securities can be added. Please contact
            support if you have additional questions
          </Text>
        </Box>
      )}
    </form>
  );
};
