import React from 'react';
import { Footer as LayoutFooter } from 'components/layouts';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { useLoadingContext } from 'components/layouts/contexts/loading';

export const Footer = () => {
  const loadingContext = useLoadingContext();

  return (
    <>
      {!loadingContext.loading && (
        <CenteredContainer>
          <LayoutFooter textColor="hitGrey" topDivider />
        </CenteredContainer>
      )}
    </>
  );
};
