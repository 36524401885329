import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { Toast } from 'components/layouts';
import { InfoGrid } from 'modules/settings/components';
import { UpdatePasswordForm } from './update-password-form';

export const UpdatePassword: React.FC = () => {
  const [update, setUpdate] = useState(false);

  const handleOnUpdate = () => {
    setUpdate(false);
    Toast.show('success', 'Your password has been updated');
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={4}>
        {update ? (
          <UpdatePasswordForm onUpdate={handleOnUpdate} />
        ) : (
          <InfoGrid info={[{ label: 'Password', value: '********' }]} />
        )}
      </Grid>

      <Grid item>
        <Button onClick={() => setUpdate(!update)}>
          <ButtonContent>{update ? 'Cancel' : 'Update'}</ButtonContent>
        </Button>
      </Grid>
    </Grid>
  );
};
