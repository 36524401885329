import styled from 'styled-components';
import { Box as BoxAlias } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Box = styled(BoxAlias)`
  border: 1px solid var(--color-whiteSmoke2);
  box-shadow: var(--box-shadow-03);
`;

const RemoveIcon = styled(CloseIcon)`
  cursor: pointer;
`;

export const Styled = {
  Box,
  RemoveIcon,
};
