import styled from 'styled-components';
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';

const StyledFormLabel = styled(FormControlLabel)<FormControlLabelProps>`
  .MuiCheckbox-root {
    padding-right: 0;
    margin-right: var(--spacing-02);
  }
`;

export const Styled = {
  StyledFormLabel,
};
