import React from 'react';
import { i18n, useTranslation } from 'app/i18n';
import { useBusiness } from 'hooks/business/use-business';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';
import { Box } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { InstructionRow } from 'modules/2023-q3/deposit-withdrawal/pages/wire-instructions/components/instruction-row/instruction-row';
import WireInstructionsContent from 'modules/2023-q3/deposit-withdrawal/pages/wire-instructions/content/wire-instructions.content.json';

import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

const i18nNamespace = 'WireInstructions';
i18n.addResourceBundle('en', i18nNamespace, WireInstructionsContent);

export interface WireTransfer {
  label: string;
  value1: string;
  value2?: string;
  helperText?: string;
}

interface WireInstructionsProps {
  from?: { pathname: string };
  previousStep?: () => void;
}

export const ApexWireInstructions: React.FC<WireInstructionsProps> = ({
  from,
  previousStep,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: treasureReserve, isLoading: isLoadingTreasureReserve } =
    useTreasureReserve(business?.Id);

  const WireTransferData: WireTransfer[] = [
    {
      label: 'Name of Bank',
      value1: 'BMO Harris',
    },
    {
      label: 'Bank Address',
      value1: '111 W Monroe St',
      value2: 'Chicago, IL 60603',
    },
    {
      label: 'Routing Number',
      value1: '071000288',
    },
    {
      label: 'Account Number',
      value1: '3713286',
    },
    {
      label: 'Intermediary Name',
      value1: 'Apex Clearing',
    },
    {
      label: 'For Further Credit',
      value1: `${treasureReserve?.custodianAccountNumber}`,
    },
  ];

  const PreviousButton = ({ mobile }: { mobile?: boolean }) => {
    return (
      <Box mt={mobile ? 10 : undefined}>
        {previousStep ? (
          <Button
            borderRadius={mobile ? 0 : undefined}
            bottomFixed={mobile}
            fullWidth
            onClick={previousStep}
          >
            <ButtonContent iconStart={SupportedIcons.ArrowLeft} mobile={mobile}>
              {t('Previous', { ns: 'app' })}
            </ButtonContent>
          </Button>
        ) : (
          <Link to={from?.pathname || RoutesPath.pages.dashboard.path}>
            <Button
              borderRadius={mobile ? 0 : undefined}
              bottomFixed={mobile}
              fullWidth
            >
              <ButtonContent
                iconStart={SupportedIcons.ArrowLeft}
                mobile={mobile}
              >
                {t('Previous', { ns: 'app' })}
              </ButtonContent>
            </Button>
          </Link>
        )}
      </Box>
    );
  };

  return (
    <FormContainer>
      <Box mb={3}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          {t('Wire Instructions')}
        </Typography>
      </Box>

      {isLoadingBusiness || isLoadingTreasureReserve ? (
        <Box m={10}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Box mb={3}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              {t('Please use the following recipient information')}
            </Typography>
          </Box>

          <Box textAlign="center" mb={3}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph1}>
              <strong>
                Do not forget to add the For Further Credit number.
              </strong>
              <br />
              You can also add the For further credit in the memo line.
            </Typography>
          </Box>

          {WireTransferData.map((row) => (
            <React.Fragment key={row.label}>
              <Box mb={2}>
                <InstructionRow data={row} />
              </Box>
            </React.Fragment>
          ))}
        </>
      )}

      <MobileView mobile={<PreviousButton mobile />}>
        <Box mt={4}>
          <PreviousButton />
        </Box>
      </MobileView>
    </FormContainer>
  );
};
