import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import firebase from 'utils/firebase';
import { useStoreBusiness } from 'stores';

// TODO: eventually as the apis move to api-portal-gateway,
// we will susnet these API Keys and all calls will be routed
// through only api-portal-gateway

export enum ApiKeys {
  Authentication = 'auth',
  Banking = 'banking',
  Business = 'business',
  Custodian = 'custodian',
  PortalGateway = 'portalGateway',
}

export const apiUrls = {
  auth: window._env.REACT_APP_API_URL_AUTH,
  banking: window._env.REACT_APP_API_URL_BANKING,
  business: window._env.REACT_APP_API_URL_BUSINESS,
  custodian: window._env.REACT_APP_API_URL_CUSTODIAN,
  portalGateway: window._env.REACT_APP_API_URL_PORTAL,
};

export const API_CORS = window._env.REACT_APP_CORS || '*';

const api = (
  businessId: string | undefined,
  selectedApi: keyof typeof apiUrls,
  options?: {
    authToken?: string;
    responseType?: string;
  },
): AxiosInstance => {
  const type = 'application/json';
  const apiUrl = apiUrls[selectedApi];

  const headers: AxiosRequestHeaders = {
    Accept: `${type}`,
    'Access-Control-Allow-Origin': API_CORS,
    'Content-Type': `${type}`,
    'X-Requested-With': 'XMLHttpRequest',
  };

  if (businessId) {
    headers['X-Business-Id'] = businessId;
  }

  const axiosConfig: any = {
    baseURL: apiUrl,
    headers,
  };

  if (options?.responseType) {
    axiosConfig['responseType'] = options?.responseType;
  }

  const customAxios = axios.create(axiosConfig);

  // Add a request interceptor
  customAxios.interceptors.request.use(async (request) => {
    const userAuthToken = await firebase.auth().currentUser?.getIdToken();

    if (!request.headers) {
      request.headers = {};
    }

    if (options?.authToken || userAuthToken) {
      request.headers.Authorization = `Bearer ${
        options?.authToken || userAuthToken
      }`;
    }

    return request;
  });

  // Add a response interceptor
  customAxios.interceptors.response.use(
    // Return a successful response back to the calling service
    (response) => response,
    // Return any error which is not due to authentication back to the calling service
    (error) => {
      if (error.response?.status !== 403) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    },
  );

  return customAxios;
};

export const useTreasureApi = (selectedApi: keyof typeof apiUrls) => {
  const { businessId: storedBusinessId } = useStoreBusiness();

  const treasureApi = (options?: {
    api?: keyof typeof apiUrls;
    authToken?: string;
    businessId?: string;
    responseType?: string;
  }) =>
    api(
      options?.businessId || storedBusinessId,
      options?.api || selectedApi,
      options,
    );

  return { treasureApi };
};
