import React from 'react';
import {
  AllocationBreakdown,
  AllocationBreakdownVariant,
} from 'modules/portfolio/allocation/components/allocation-breakdown/allocation-breakdown';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { Container } from '../container/container';

interface TRBreakdownProps {
  allocation: Allocation;

  aum: number;
}

export const TreasureReserveBreakdown: React.FC<TRBreakdownProps> = ({
  allocation,
  aum,
}) => {
  return (
    <Container>
      <AllocationBreakdown
        allocation={allocation}
        aum={aum}
        variant={AllocationBreakdownVariant.Dashboard}
      />
    </Container>
  );
};
