import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export interface StateOption {
  Code: string;
  Name: string;
}

export const useStates = (): UseQueryResult<StateOption[]> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Business);

  return useQuery([ReactQueryKeys.States], async () => {
    const response = await treasureApi().get('/lookups/state');

    return response.data;
  });
};
