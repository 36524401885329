import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Tooltip } from 'modules/portfolio/allocation/components/allocation-modal/components';

interface AllocationTooltipProps {
  content?: React.ReactNode;

  eventLabel: string;

  header?: React.ReactNode;

  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export const AllocationTooltip: React.FC<AllocationTooltipProps> = ({
  content,
  eventLabel,
  header,
  placement,
}) => {
  return (
    <Tooltip
      content={
        <Box p={1}>
          {header && (
            <Box mb={content ? 1 : 0}>
              <Typography variant={TypographyVariant.BodyBold}>
                {header}
              </Typography>
            </Box>
          )}

          {content && (
            <Typography variant={TypographyVariant.Body}>{content}</Typography>
          )}
        </Box>
      }
      eventLabel={eventLabel}
      interactive
      placement={placement}
    />
  );
};
