import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Typography } from 'components/core';

const LegendBox = styled(Box)`
  background-color: var(--color-wildSand);
  border-radius: var(--border-radius-08);
`;

const LegendText = styled(Typography)`
  font-size: 10px;
  text-transform: uppercase;
`;

export const Styled = {
  LegendBox,
  LegendText,
};
