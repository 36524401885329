import React, { useEffect, useState } from 'react';
import { UseMutationResult } from 'react-query';
import { i18n } from 'app/i18n';
import MFAContent from 'modules/2023-q3/components/mfa/content/mfa.content.json';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Size } from 'enums';
import { useConfirmMFACode } from 'modules/2023-q3/components/mfa/hooks/use-confirm-mfa-code';
// TODO: create a generic MFA email? and change hook
import { useSendMFACode } from 'hooks/custodian/mfa/use-send-mfa-code';
import { useTracking, TrackEventName } from 'modules/tracking';
import { useLoginDevice } from 'modules/2023-q3/login-mfa/hooks/use-login-device';
import { InputCode } from './components/input-code/input-code';
import { VerifiedCode } from './components/verified-code/verified-code';
import { VerifyingCode } from './components/verifying-code/verifying-code';
import { MobileView } from '../mobile-view/mobile-view';

export const i18nNamespaceMFAContent = 'mfa-content';
i18n.addResourceBundle('en', i18nNamespaceMFAContent, MFAContent);

/* TODO: this component can get a refactor and assume all hooks are passed in rather than
 * having a these custom fields, onCustomMFASuccess and onMFASuccess can be combined
 * but left as is to not affect already implemented code
 */

enum MFASteps {
  InputCodeStep,
  VerifyingCodeStep,
  VerifiedCodeStep,
  VerifiedCodeFailedStep,
}

export interface ICustomSendMFA {
  mutationResult: UseMutationResult;

  variables?: {};
}

interface MFAProps {
  customConfirmMFA?: ICustomSendMFA;

  customSendMFA?: ICustomSendMFA;

  customVerificationMessage?: string;

  onCustomMFASuccess?: (response: any) => void;

  onMFASuccess: (token?: string) => void;

  onPrevious: () => void;

  showRememberDeviceCheckbox?: boolean;
}

export const MFA: React.FC<MFAProps> = ({
  customConfirmMFA,
  customSendMFA,
  customVerificationMessage,
  onCustomMFASuccess,
  onMFASuccess,
  onPrevious,
  showRememberDeviceCheckbox,
}) => {
  const { mutate: confirmMFACode } = useConfirmMFACode();
  const { mutate: sendMFACode } = useSendMFACode();
  const loginDevice = useLoginDevice();
  const { trackEvent } = useTracking();

  const initMFACode = ['', '', '', '', '', ''];

  const [mfaCode, setMFACode] = useState(initMFACode);
  const [mfaError, setMFAError] = useState(false);
  const [step, setStep] = useState(MFASteps.InputCodeStep);

  const [rememberDevice, setRememberDevice] = useState(false);

  const handleConfirmMFACode = (code: string[]) => {
    const codeAsString = code.join('');

    setStep(MFASteps.VerifyingCodeStep);

    if (customConfirmMFA?.mutationResult) {
      const customVariables = customConfirmMFA?.variables || {};

      customConfirmMFA.mutationResult.mutate(
        { ...customVariables, mfaCode: codeAsString },
        {
          onError: (error) => {
            setMFAError(true);
            setStep(MFASteps.InputCodeStep);

            trackEvent({
              eventName: TrackEventName.MFAFailed,
              error,
            });
          },
          onSuccess: (response) => {
            if (response) {
              setStep(MFASteps.VerifiedCodeStep);
              if (onCustomMFASuccess) {
                onCustomMFASuccess(response);
              }

              // if remember this device checkbox clicked,
              // save the device to be remembered
              if (showRememberDeviceCheckbox && rememberDevice) {
                loginDevice.rememberDevice();
              }
            }
          },
        },
      );
    } else {
      confirmMFACode(
        { mfaCode: codeAsString },
        {
          onError: (error) => {
            setMFAError(true);
            setStep(MFASteps.InputCodeStep);

            trackEvent({
              eventName: TrackEventName.MFAFailed,
              error,
            });
          },
          onSuccess: (response) => {
            if (response && response.token) {
              setStep(MFASteps.VerifiedCodeStep);
              onMFASuccess(response.token);

              // if remember this device checkbox clicked,
              // save the device to be remembered
              if (showRememberDeviceCheckbox && rememberDevice) {
                loginDevice.rememberDevice();
              }
            }
          },
        },
      );
    }
  };

  const handleOnPrevious = () => {
    setMFACode(initMFACode);

    onPrevious();
  };

  const displayStep = (stepToDisplay: MFASteps) => {
    switch (stepToDisplay) {
      case MFASteps.VerifiedCodeStep:
        return <VerifiedCode mfaCode={mfaCode} />;
      case MFASteps.VerifyingCodeStep:
        return <VerifyingCode mfaCode={mfaCode} />;
      default:
        return (
          <MobileView
            mobile={
              <InputCode
                customSendMFA={customSendMFA}
                customVerificationMessage={customVerificationMessage}
                handleConfirmMFACode={handleConfirmMFACode}
                mfaCode={mfaCode}
                mfaError={mfaError}
                mobile
                onPrevious={handleOnPrevious}
                setMFACode={setMFACode}
                setMFAError={setMFAError}
                showRememberDeviceCheckbox={showRememberDeviceCheckbox}
                setRememberDevice={setRememberDevice}
              />
            }
          >
            <InputCode
              customSendMFA={customSendMFA}
              customVerificationMessage={customVerificationMessage}
              handleConfirmMFACode={handleConfirmMFACode}
              mfaCode={mfaCode}
              mfaError={mfaError}
              onPrevious={handleOnPrevious}
              setMFACode={setMFACode}
              setMFAError={setMFAError}
              showRememberDeviceCheckbox={showRememberDeviceCheckbox}
              setRememberDevice={setRememberDevice}
            />
          </MobileView>
        );
    }
  };

  // send initial mfa code
  useEffect(() => {
    if (customSendMFA) {
      customSendMFA.mutationResult.mutate(customSendMFA.variables || {});
    } else {
      sendMFACode({});
    }
  }, []);

  return <FormContainer size={Size.Medium}>{displayStep(step)}</FormContainer>;
};
