import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { ReactQueryMutationKeys } from 'enums';

export const useLoginRecord = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const loginRecord = async ({ businessId }: { businessId: string }) => {
    const response = await treasureApi({ businessId }).post('/login/record');

    return response.data;
  };

  return useMutation(loginRecord, {
    mutationKey: ReactQueryMutationKeys.LoginRecord, // this mutation key is used by react-query-error-toasts so it will never show a user an error
  });
};
