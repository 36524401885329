import React from 'react';
import PropTypes from 'prop-types';
import { Percentage, TypographyVariant } from 'components/core';
import { Styled } from './yield-chip.style';

export interface YieldChipProps {
  className?: string;
  percentageYield: number;
}

const formatPercentageYield = (percentageYield: number) => {
  const sign = Math.sign(percentageYield) >= 0 ? '+' : '';
  return (
    <>
      {sign}
      <Percentage
        number={percentageYield}
        decimalScale={4}
        showExtremePrecision
      />
    </>
  );
};

const YieldChip: React.FC<YieldChipProps> = ({
  percentageYield,
  className,
}) => {
  return (
    <Styled.ChipContainer
      className={className}
      isNegative={Math.sign(percentageYield) < 0}
    >
      <Styled.PercentageYield variant={TypographyVariant.Body}>
        {formatPercentageYield(percentageYield)}
      </Styled.PercentageYield>
    </Styled.ChipContainer>
  );
};

YieldChip.propTypes = {
  className: PropTypes.string,
  percentageYield: PropTypes.number.isRequired,
};

export { YieldChip };
