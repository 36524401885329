import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
  from {
    transform: translateY(-40px);
  }
  to {
    transform: translateY(0);
  }
`;

const MenuContainer = styled.div`
  animation: ${slideDown} 250ms linear;
  min-width: 185px;
  min-height: 40px;
  border: 0.5px solid var(--color-alto);
  border-radius: 15px;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.15);
  background-color: var(--color-white);
  padding: 10px 0;
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: var(--z-index-header-dropdown);
`;

const MenuConainerArrow = styled.div`
  position: relative;
  top: 1px;
  left: -9px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid var(--color-white);
`;

const MenuConainerArrowBorder = styled.div`
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-alto);
`;

const Option = styled.li`
  font-family: Nunito Sans;
  width: 100%;
  padding: 0 30px;
  height: 40px;
  align-items: center;
  display: inherit;
  user-select: none;
  color: var(--color-legacyBlue400);
  :hover {
    background-color: var(--color-legacyBlue100);
    cursor: pointer;
    color: var(--color-white);
  }
`;

const Options = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Styled = {
  MenuContainer,
  MenuConainerArrow,
  MenuConainerArrowBorder,
  Option,
  Options,
};
