import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { Typography } from 'components/core';

const CompanyName = styled(Typography)`
  display: block;
  max-width: 150px;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;

const Container = styled(Box)`
  cursor: pointer;
  position: relative;
`;

const GridContainer = styled(Grid)`
  height: 55px;
`;

const HeaderMenu = styled(Box)`
  cursor: pointer;
  background-color: var(--color-grey7);
  border-radius: var(--border-radius-05);
  width: 200px;
  height: 38px;
`;

export const Styled = {
  CompanyName,
  Container,
  GridContainer,
  HeaderMenu,
};
