import React from 'react';
import { Typography, TypographyVariant } from 'components/core';

interface SectionContainerTitleProps {
  title: string;
}

export const SectionContainerTitle: React.FC<SectionContainerTitleProps> = ({
  title,
}) => {
  return <Typography variant={TypographyVariant.Header5}>{title}</Typography>;
};
