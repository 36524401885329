import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { ReactQueryMutationKeys } from 'enums';

export const useValidateRelationship = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const validateRelationship = async () => {
    const response = await treasureApi().get(
      '/validateBusinessUserRelationship',
    );

    return response.data;
  };

  return useMutation(validateRelationship, {
    mutationKey: ReactQueryMutationKeys.ValidateRelationship, // this mutation key is used by react-query-error-toasts so it will never show a user an error
  });
};
