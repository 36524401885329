import styled from 'styled-components';
import { Box } from '@material-ui/core';
import Line from 'assets/modules/2023-q4/growth-promo/pattern_background-waves.svg';

const GraphicsContainer = styled(Box)`
  background: var(--color-green0b) url(${Line}) repeat fixed;
  background-size: cover;
`;

export const Styled = {
  GraphicsContainer,
};
