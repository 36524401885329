import React from 'react';
import { Grid } from '@material-ui/core';
import { getTooltip } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import { rowHeader } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-typography/allocation-typography';
import { Trans } from 'app/i18n';
import { i18nNamespaceAllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';

export enum AllocationModalRowKeys {
  Allocation = 'allocation',
  Amount = 'amount',
  Liquidity = 'liquidity',
  Return = 'return',
  ReturnAmount = 'returnAmount',
  ExpectedNetReturnAmount = 'expectedNetReturnAmount',
}

export const getRowHeader = ({
  rowKey,
  hasTooltip,
}: {
  rowKey: AllocationModalRowKeys;
  hasTooltip?: boolean;
}) => {
  const header = rowHeader(
    <Trans i18nKey={String(rowKey)} ns={i18nNamespaceAllocationModal} />,
  );

  if (hasTooltip) {
    return (
      <Grid container spacing={1}>
        <Grid item>{header}</Grid>

        <Grid item>
          {getTooltip({
            content: (
              <Trans
                i18nKey={`${String(rowKey)}TooltipContent`}
                ns={i18nNamespaceAllocationModal}
              />
            ),
            eventLabel: String(rowKey),
          })}
        </Grid>
      </Grid>
    );
  }

  return header;
};
