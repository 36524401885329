import styled from 'styled-components';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const CancelIcon = styled(CancelRoundedIcon)`
  fill: var(--color-amaranth);
`;

export const Styled = {
  CancelIcon,
};
