import React from 'react';
import { Box } from '@material-ui/core';
import { useBankAccounts } from 'modules/2023-q3/banking-page/v2/hooks/use-bank-accounts';
import { Bank } from './components/bank/bank';

export const BankAccounts = () => {
  const { data: bankAccounts } = useBankAccounts();

  const FinancialDataAggregatorPlaid = 'PLAID';

  const institutions = [
    ...Array.from(
      new Set(
        bankAccounts
          ?.filter(
            (bankAccount: { FinancialDataAggregator: string }) =>
              bankAccount.FinancialDataAggregator ===
              FinancialDataAggregatorPlaid,
          )
          .map(
            (bankAccount: { institution: { name: string } }) =>
              bankAccount.institution.name,
          ),
      ),
    ),
  ].sort();

  const manuallyAddedBankAccounts = bankAccounts?.filter(
    (bankAccount: { FinancialDataAggregator: string }) =>
      bankAccount.FinancialDataAggregator !== FinancialDataAggregatorPlaid,
  );

  return (
    <>
      {institutions.map((institution) => (
        <Box key={institution} mb={10}>
          <Bank
            bankAccounts={bankAccounts
              ?.filter(
                (bankAccount: any) =>
                  bankAccount.institution.name === institution,
              )
              .sort((a: any, b: any) => a.Mask - b.Mask)}
            institution={institution}
          />
        </Box>
      ))}

      {manuallyAddedBankAccounts && manuallyAddedBankAccounts?.length > 0 && (
        <Bank
          bankAccounts={manuallyAddedBankAccounts}
          institution="Manually Added Bank Accounts"
          manual
        />
      )}
    </>
  );
};
