import styled from 'styled-components';
import { getColor } from 'styles/css-constants';
import { Styled as StyledCheckbox } from '../checkbox.style';

interface TickProps {
  disabled?: boolean;
  error?: boolean;
}

const TickContainer = styled.div<TickProps>`
  width: ${StyledCheckbox.CHECKBOX_WIDTH};
  height: ${StyledCheckbox.CHECKBOX_HEIGHT};
  position: relative;
  background-color: ${(props) =>
    props.disabled ? getColor('gainsboro0') : getColor('primary')};

  border: ${(props) => {
    if (props.error) {
      return `1px solid ${getColor('amaranth')}`;
    }
    if (props.disabled) {
      return `1px solid ${getColor('gainsboro0')}`;
    }
    return `1px solid ${getColor('primary')}`;
  }};
  border-radius: var(--border-radius-02);
`;

const TickIcon = styled.img`
  position: absolute;
  left: 11%;
  top: 20%;
  height: auto;
  border-radius: var(--border-radius-02);
  width: 78%;
`;

export const Styled = {
  TickContainer,
  TickIcon,
};
