import React from 'react';
import { Box } from '@material-ui/core';
import { TextFieldController } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { maxEmailLength, isValidEmail } from 'utils/forms/validators';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { Typography, TypographyVariant } from 'components/core';

interface IDocumentFormEmail {
  control: any;
  handleSubmit: any;
  isLoading: boolean;
}

export const DocumentFormEmail: React.FC<IDocumentFormEmail> = ({
  control,
  handleSubmit,
  isLoading,
}) => {
  const formFields = {
    email: {
      name: 'email',
      label: 'Business email',
      validations: {
        required: true,
        maxLength: maxEmailLength,
        validate: (value: string) =>
          isValidEmail(value) || 'Please use a valid email address',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={5}>
        <TextFieldController {...formFields.email} control={control} />
      </Box>

      <Box mb={3}>
        <Button type="submit" disabled={isLoading} fullWidth>
          <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
            <Typography color="white" variant={TypographyVariant.Body}>
              Continue
            </Typography>
          </ButtonContent>
        </Button>
      </Box>
    </form>
  );
};
