import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { NavLink as NavLinkAlias } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { breakpoints } from 'styles/css-constants';

const IconChevronRight = styled(ChevronRightIcon)`
  position: relative;
  top: 6px;
  font-size: 20px;
`;

const NavLink = styled(NavLinkAlias)`
  &:hover {
    border-bottom: 1px solid;
  }
`;

const TreasureBox = styled(Box)`
  background-color: var(--color-white);
  border-radius: var(--border-radius-05);
  max-width: 450px;
  box-shadow: rgb(0 0 0 / 6%) 0px 12px 19px;

  ${breakpoints.down('sm')} {
    border-radius: 0;
  }
`;

export const Styled = {
  IconChevronRight,
  NavLink,
  TreasureBox,
};
