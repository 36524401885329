import styled from 'styled-components';
import { Paper as PaperAlias } from '@material-ui/core';
import { Button as ButtonAlias } from 'components/core';

const Button = styled(ButtonAlias)`
  min-width: 175px;
`;

const LiquidSenseBanner = styled.div`
  position: absolute;
  top: -20px;
  left: -10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro0);
  box-shadow: var(--box-shadow-04);
  border-radius: 200px;
`;

const Paper = styled(PaperAlias)`
  position: relative;
  border: 1px solid var(--color-gainsboro0);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-02);
`;

export const Styled = {
  Button,
  LiquidSenseBanner,
  Paper,
};
