import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Text } from 'components/core';
import { Styled } from './password-criteria-check.style';

import {
  numberRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from '../password-validations';

interface PasswordCriteriaCheckProps {
  password: string;
}

export const PasswordCriteriaCheck: React.FC<PasswordCriteriaCheckProps> = ({
  password,
}) => {
  const passwordValidationCriteria = [
    {
      text: 'Eight characters minimum',
      isValid: password?.length > 7,
    },
    {
      text: 'One number',
      isValid: password?.match?.(numberRegex),
    },
    {
      text: 'One special character',
      isValid: password?.match?.(specialCharacterRegex),
    },
    {
      text: 'One upper-case letter',
      isValid: password?.match?.(upperCaseRegex),
    },
  ];

  return (
    <Styled.PasswordCriteriaBox p={2}>
      <ul>
        {passwordValidationCriteria.map((criteria) => {
          return (
            <li key={criteria.text}>
              <Box mb={0.5}>
                <Grid container>
                  <Grid item>
                    <Box mr={1}>
                      <Styled.IconCheck
                        visibility={criteria.isValid ? 'visible' : 'hidden'}
                      />
                    </Box>
                  </Grid>

                  <Grid item>
                    <Text color="gothic" variant={1}>
                      {criteria.text}
                    </Text>
                  </Grid>
                </Grid>
              </Box>
            </li>
          );
        })}
      </ul>
    </Styled.PasswordCriteriaBox>
  );
};

PasswordCriteriaCheck.propTypes = {
  password: PropTypes.string.isRequired,
};
