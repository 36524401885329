import treasureApi from 'api';
import { AxiosResponse } from 'axios';

export const patchPeople = async ({
  people,
  businessId,
}: {
  people: { ownershipPercentage: number; email: string; phoneNumber: string }[];
  businessId: string;
}): Promise<AxiosResponse> => {
  const response = await treasureApi('portal', { businessId }).patch('people', {
    people,
  });

  return response;
};
