import React from 'react';
import { Box } from '@material-ui/core';
import { Transaction } from 'modules/dashboard/treasure-reserve/transactions/components/transactions-table/transactions-table';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrows/arrow-left.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrows/arrow-right.svg';

const wrapArrow = (arrow: React.ReactElement) => {
  return (
    <Box display="inline" position="relative" top={5} mr={1}>
      {arrow}
    </Box>
  );
};

interface TransactionArrowProps {
  transaction: Transaction;
}

export const TransactionArrow: React.FC<TransactionArrowProps> = ({
  transaction,
}) => {
  // TODO: api should return direction
  if (transaction.description.includes('into')) {
    return wrapArrow(<ArrowForwardIcon />);
  }

  if (transaction.description.includes('out')) {
    return wrapArrow(<ArrowBackIcon />);
  }

  return null;
};
