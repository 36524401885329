import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { ReactComponent as IconXCircleGray } from 'assets/icons/checkmarks/x-circle-gray.svg';

const IconAdd = styled(IconXCircleGray)`
  transform: rotate(45deg);
`;

const IconCheckmarkWrapper = styled(Box)`
  svg rect {
    fill: var(--color-primary);
  }
`;

const PercentageInputContainer = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-input {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 12px;
  }

  .MuiInput-underline {
    &:after {
      border-bottom: initial; // turn off ripple effect
    }

    &:before {
      border-bottom-color: var(--color-grey6);
    }

    &:hover:before {
      border-bottom: 1px solid var(--color-grey1);
    }
  }
`;

export const Styled = {
  IconAdd,
  IconCheckmarkWrapper,
  PercentageInputContainer,
};
