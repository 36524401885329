import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';

export const getReturnRange = (
  allocations: Allocation,
  productKey: keyof Allocation,
  zscore: number = 2.6,
) => {
  const mean = allocations[productKey].return;
  const scale = allocations[productKey].volatility;
  const lower = mean - zscore * scale;
  const upper = mean + zscore * scale;

  return { lower, mean, upper, scale };
};
