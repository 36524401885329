import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Container = styled(Box)`
  background-color: var(--color-white);
  border: 1px solid var(--color-grey6);
  border-radius: var(--border-radius-06px);
  width: 100%;
  height: 550px;
  overflow: scroll;
  word-break: break-all;

  @media print {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: 0 none;

    overflow: visible;
  }
`;

export const Styled = {
  Container,
};
