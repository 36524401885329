import React from 'react';
import { Box } from '@material-ui/core';
import { i18n, Trans } from 'app/i18n';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
} from 'components/core';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { CustodianStatuses, TypographyVariant } from 'enums';
import { useBusiness } from 'hooks/business/use-business';
import { useCashAccount } from 'hooks/portal-gateway/use-cash-account';
import {
  NotificationBox,
  NotificationBoxVariant,
} from '../../../allocation-modal/components';
import Content from '../dashboard-entry.content.json';

i18n.addResourceBundle('en', 'dashboard-entry', Content);

interface Props {
  aum: number;

  pendingBalance: number;
}

export const AllocationNotificationNoPortfolio: React.FC<Props> = ({
  aum,
  pendingBalance,
}) => {
  const noFunds = aum === 0 && pendingBalance === 0;

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );
  const treasureCashRate = portfolios?.rates.cashRate * 100;
  const { data: cashAccount, isLoading: isLoadingCashAccount } = useCashAccount(
    business?.Id,
  );

  const accountNotOpen = cashAccount?.status !== CustodianStatuses.AccountOpen;

  let promptToShow;
  if (accountNotOpen && noFunds) {
    promptToShow = 'account set up no funds';
  } else if (accountNotOpen) {
    promptToShow = 'account_not_opened_alert';
  } else if (pendingBalance > 0) {
    promptToShow = 'funds_on_way_alert';
  }

  if (isLoadingBusiness || isLoadingPortfolios || isLoadingCashAccount) {
    return null;
  }

  return (
    <>
      {promptToShow && treasureCashRate && (
        <Box mb={3}>
          <NotificationBox variant={NotificationBoxVariant.Information}>
            <Typography variant={TypographyVariant.SubHeader1}>
              <Trans
                i18nKey={promptToShow}
                ns="dashboard-entry"
                components={{
                  pendingBalance: (
                    <Currency
                      number={pendingBalance}
                      variant={CurrencyVariant.Full}
                    />
                  ),
                  percentage: (
                    <Percentage number={treasureCashRate} decimalScale={2} />
                  ),
                }}
              />
            </Typography>
          </NotificationBox>
        </Box>
      )}
    </>
  );
};
