import React from 'react';
import { useTranslation } from 'app/i18n';
import { i18nNamespaceMFAContent } from 'modules/2023-q3/components/mfa/mfa';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { MFACodeBoxes } from 'modules/2023-q3/components/mfa/components/mfa-code-boxes/mfa-code-boxes';
import { ProgressSpinner } from 'modules/2023-q3/components/progress-spinner/progress-spinner';
import { Button } from 'modules/2023-q3/components/button/button';

interface VerifyingCodeProps {
  mfaCode: string[];
}

export const VerifyingCode: React.FC<VerifyingCodeProps> = ({ mfaCode }) => {
  const { t } = useTranslation(i18nNamespaceMFAContent);

  return (
    <Box textAlign="center">
      <Box mt={4} mb={1}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          {t('Verifying your code...')}
        </Typography>
      </Box>

      <Box mt={3.5} mb={2.5}>
        <MFACodeBoxes mfaCode={mfaCode} readonly />
      </Box>

      <Box mt={10}>
        <Grid container>
          <Grid item xs>
            <Button cursor="auto" fullWidth>
              <Box px={2} py={1.5}>
                <ProgressSpinner color="white" />
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
