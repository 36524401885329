import styled from 'styled-components';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const LogoHeight = 40;

const LogoWidth = 40;

const BankIcon = styled(AccountBalanceIcon)`
  width: ${LogoWidth}px;
  height: ${LogoHeight}px;
`;

export const Styled = {
  BankIcon,
  LogoHeight,
  LogoWidth,
};
