import React from 'react';
import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { AxiosResponse } from 'axios';

export const useUpdatePassword = () => {
  const { treasureApi } = useTreasureApi(ApiKeys.Authentication);

  const updatePassword = async ({
    userId,
    password,
  }: {
    userId: string;
    password: string;
  }): Promise<AxiosResponse> => {
    const { data } = await treasureApi().put(`/user/${userId}/settings`, {
      password,
    });

    return data;
  };

  return { updatePassword };
};
