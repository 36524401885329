export enum TypographyVariant {
  BoldLargeHeader = 'Bold Large Header',
  LargeHeader = 'Large Header',
  Header = 'Header',
  LegacyHeader2 = 'Legacy Header 2',
  HeaderExtraBold = 'Header Extra Bold',
  HeaderRegular = 'Header Regualar',
  SubHeader1 = 'Sub Header 1',
  SubHeader2 = 'Sub Header 2',
  SubHeader3 = 'Sun Header 3',
  SubLabel = 'Sub Label',
  Body = 'Body',
  BodyBold = 'Body Bold',
  BodySemiBold = 'Body Semi Bold',
  LargerLabel = 'Larger Label',
  LargeLabel = 'Large Label',
  SmallLabel2 = 'Small Label 2',
  SmallLabel3 = 'Small Label 3',
  SmallText = 'Small Text',
  HelpText = 'Help Text',
  DisplayHeader1 = 'Display Header 1',
  DisplayHeader2 = 'Display Header 2',
  Header1 = 'Header 1',
  Header2 = 'Header 2',
  Header3 = 'Header 3',
  Header4 = 'Header 4',
  Header5 = 'Header 5',
  Paragraph1 = 'Paragraph 1',
  Paragraph2 = 'Paragraph 2',
  Paragraph3 = 'Paragraph 3',
  Paragraph4 = 'Paragraph 4',
}
