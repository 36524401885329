import React, { useState, useEffect } from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import {
  InfoGrid,
  SettingsHeader,
  SettingsNav,
} from 'modules/settings/components';
import {
  Businesses,
  Team,
  TradeRestrictions,
  UpdatePassword,
  QuickbooksConnect,
} from 'modules/settings/pages';
import { InviteUser } from 'modules/multi-user';

// layout
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { Footer } from 'layouts/v3-portal-layout/components/footer';

// router
import { Route, Switch } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';
import { useBusinessMembers } from 'hooks/business/use-business-members';

import { Styled } from './settings.style';

export const Settings: React.FC = () => {
  const loadingContext = useLoadingContext();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();

  const { data: user, isLoading: isLoadingUser } = useUser(business?.Id);

  const { data: businessMembers, isLoading: isLoadingBusinessMembers } =
    useBusinessMembers();

  const [userDisplayName, setUserDisplayName] = useState<string>();

  useEffect(() => {
    loadingContext.setLoading(
      isLoadingBusiness || isLoadingBusinessMembers || isLoadingUser,
    );
  }, [
    isLoadingBusiness,
    isLoadingBusinessMembers,
    isLoadingUser,
    loadingContext,
  ]);

  // TODO: every user is Treasure User -.-
  useEffect(() => {
    if (user && businessMembers) {
      const currentUser = businessMembers.find(
        (member: { email: string }) => member.email === user.email,
      );

      setUserDisplayName(`${currentUser.firstName} ${currentUser.lastName}`);
    }
  }, [user, businessMembers]);
  // TODO: use shared Spinner
  if (loadingContext.loading) {
    return <Spinner />;
  }

  return (
    <>
      {/* push the footer down */}
      <Box display="table" height="inherit" width="100%">
        <Grid container>
          <SettingsNav />

          <Grid item xs>
            <CenteredContainer>
              <Box px={3} py={5}>
                <Switch>
                  <Route
                    exact
                    path={RoutesPath.pages.settings.pages.businesses.path}
                  >
                    <SettingsHeader>
                      <Typography variant={TypographyVariant.HeaderRegular}>
                        Businesses
                      </Typography>
                    </SettingsHeader>

                    <Businesses />
                  </Route>

                  <Route exact path={RoutesPath.pages.settings.pages.team.path}>
                    <SettingsHeader>
                      <Typography variant={TypographyVariant.HeaderRegular}>
                        Team
                      </Typography>
                    </SettingsHeader>

                    <Team />
                  </Route>

                  <Route
                    exact
                    path={RoutesPath.pages.settings.pages.teamInvite.path}
                  >
                    <SettingsHeader>
                      <Typography variant={TypographyVariant.HeaderRegular}>
                        Invite Team
                      </Typography>
                    </SettingsHeader>

                    <InviteUser />
                  </Route>

                  <Route
                    exact
                    path={
                      RoutesPath.pages.settings.pages.tradeRestrictions.path
                    }
                  >
                    {/* TODO: should probably redirect if a mobile user tries to access this url */}
                    <Hidden xsDown>
                      <SettingsHeader>
                        <Typography variant={TypographyVariant.HeaderRegular}>
                          Trade Restrictions
                        </Typography>
                      </SettingsHeader>

                      <Styled.WhiteSmokeBackground px={4}>
                        <TradeRestrictions />
                      </Styled.WhiteSmokeBackground>
                    </Hidden>
                  </Route>

                  <Route
                    exact
                    path={RoutesPath.pages.settings.pages.updatePassword.path}
                  >
                    <SettingsHeader>
                      <Typography variant={TypographyVariant.HeaderRegular}>
                        Update Password
                      </Typography>
                    </SettingsHeader>

                    <UpdatePassword />
                  </Route>
                  <Route
                    exact
                    path={RoutesPath.pages.settings.pages.quickbooks.path}
                  >
                    <SettingsHeader>
                      <Typography variant={TypographyVariant.HeaderRegular}>
                        QuickBooks
                      </Typography>
                    </SettingsHeader>
                    <QuickbooksConnect />
                  </Route>
                  <Route>
                    <SettingsHeader>
                      <Typography variant={TypographyVariant.HeaderRegular}>
                        Account Settings
                      </Typography>
                    </SettingsHeader>

                    <InfoGrid
                      info={[
                        {
                          label: 'Name',
                          value: userDisplayName || user?.displayName || '',
                        },
                        { label: 'Email', value: user?.email || '' },
                        {
                          label: 'Business Name',
                          value: business?.LegalName || business?.DBA || '',
                        },
                      ]}
                    />
                  </Route>
                </Switch>
              </Box>
            </CenteredContainer>
          </Grid>
        </Grid>
      </Box>

      {/* TODO: if we continue with this layout, this should be in the shared parent */}
      <Grid container>
        <Hidden xsDown>
          <Grid item xs={2}>
            <Styled.SettingsSidebarFooter />
          </Grid>
        </Hidden>

        <Grid item xs>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};
