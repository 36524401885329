export const einMask = '#########';

export const bankRoutingMask = '#########';

export const phoneNumberMask = '(###) ###-####';

export const USAZipCodeMask = '#####';

export const dateMask = '##/##/####';

export const ssnMask = '###-##-####';
