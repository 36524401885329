import styled, { css } from 'styled-components';
import { Accordion as MuiAccordion } from '@material-ui/core';
import { Typography } from 'components/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface StyledIconArrow {
  expanded: boolean;
}

const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    & .MuiAccordionDetails-root {
      display: block;
      padding: 0;
    }
  }
`;

const IconArrow = styled(KeyboardArrowDownIcon)<StyledIconArrow>`
  position: relative;
  top: 2px;
  left: -4px;
  font-size: 18px;

  transition: transform var(--animation-delay-01);

  ${(props) => {
    if (props.expanded) {
      return css`
        transform: rotate(180deg);
      `;
    }
  }}
`;

const LinkText = styled(Typography)`
  &:hover {
    cursor: pointer;
  }
`;

export const Styled = {
  Accordion,
  IconArrow,
  LinkText,
};
