import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  ItemDefinition,
  Text,
} from 'components/core';
import { Styled } from './net-cash-flow-sub-indicator.style';

interface NetCashFlowSubIndicatorProps {
  data: {
    inflows: string; // should be number but api returns string
    outflows: string; // should be number but api returns string
  };
}

const NetCashFlowSubIndicator: React.FC<NetCashFlowSubIndicatorProps> = ({
  data,
}) => {
  return (
    <Box mb={3}>
      <Box mb={3}>
        <Text variant={1}>
          We calculate net cash flow over the past 30 days so you accurately
          know where you are in real-time.
        </Text>
      </Box>

      <Box mb={3}>
        <Styled.Divider />
      </Box>

      <Styled.CenterGrid container spacing={10}>
        <Grid item>
          <ItemDefinition
            label="Inflows"
            value={
              <Text color="nero" variant={3}>
                <Currency
                  number={+data.inflows}
                  variant={CurrencyVariant.Full}
                />
              </Text>
            }
          />
        </Grid>

        <Grid item>
          <ItemDefinition
            label="Outflows"
            value={
              <Text color="nero" variant={3}>
                <Currency
                  number={+data.outflows}
                  variant={CurrencyVariant.Full}
                />
              </Text>
            }
          />
        </Grid>
      </Styled.CenterGrid>
    </Box>
  );
};

NetCashFlowSubIndicator.propTypes = {
  data: PropTypes.shape({
    inflows: PropTypes.string.isRequired,
    outflows: PropTypes.string.isRequired,
  }).isRequired,
};

export { NetCashFlowSubIndicator };
