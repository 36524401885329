import React from 'react';
import styled, { css } from 'styled-components';
import { Typography as TypographyAlias } from '@material-ui/core';
import { getFontProperties } from 'styles/css-constants';

interface StyledText {
  styledColor?: string;
  level: number;
}

const Typography = styled(({ styledColor, ...rest }) => (
  <TypographyAlias {...rest} />
))<StyledText>`
  font-family: var(--font-family);
  color: ${(props) => {
    if (props.styledColor) {
      return css`var(--color-${props.styledColor})`;
    }
    return 'var(--color-nero)';
  }};
  margin: 0;

  font-size: ${(props) => {
    if (props.level) {
      return getFontProperties.getBodyProperties(props.level - 1).fontSize;
    }
  }};
  font-weight: ${(props) => {
    if (props.level) {
      return getFontProperties.getBodyProperties(props.level - 1).fontWeight;
    }
  }};
`;

export const Styled = {
  Typography,
};
