import { useRef, useState, useEffect } from 'react';
import useScript from 'react-script-hook';
import { createLinkToken } from 'modules/plaid/api/create-link-token';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnExit,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkStableEvent,
  PlaidLinkOnEvent,
  PlaidLinkError,
  Plaid,
} from 'react-plaid-link';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';

import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';

export const PLAID_LINK_STABLE_URL =
  'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

const localStorageVariablePlaidLinkToken = 'plaidLinkToken';

export interface CustomPlaid extends Plaid {
  destroyed: boolean;
}

export enum PlaidModalState {
  idle,
  running,
}

enum PlaidModalCustomization {
  Default = 'staging',
  WithManual = 'with_manual',
}

export enum PlaidExitStatus {
  InstitutionNotFound = 'institution_not_found',
}

export type OpenPlaidModalSuccessResponse = {
  plaidPublicToken: string;
  plaidMetaData: PlaidLinkOnSuccessMetadata;
};

export type OpenPlaidModalErrorResponse = {
  error: PlaidLinkError | null;
  plaidMetaData: PlaidLinkOnExitMetadata;
};

export type OpenPlaidModal = (opts?: {
  bankItemId?: string;
  forceNewInstance?: boolean;
  forceOauthPopup?: boolean;
  withManualFlow?: boolean;
}) => Promise<OpenPlaidModalSuccessResponse | null>;
export type UsePlaidModal = () => OpenPlaidModal;

interface UsePlaidModalProps {
  setPlaidLoading?: () => void;
}

export const usePlaidModal: UsePlaidModal = () => {
  // 1. creates base variables and state
  const { data: business } = useBusiness();
  const { data: user } = useUser(business?.Id);

  const { trackEvent } = useTracking<{
    eventName: TrackEventName;
    plaidEvent?: any;
    plaidMetaData?: any;
    section: TrackPageSection;
  }>({
    eventName: TrackEventName.PlaidEvent,
    section: TrackPageSection.PlaidModal,
  });

  const [plaidScriptLoading, plaidScriptError] = useScript({
    src: PLAID_LINK_STABLE_URL,
    checkForExisting: true,
  });

  const [plaidModalState, setPlaidModalState] = useState<PlaidModalState>(
    PlaidModalState.idle,
  );

  const [plaidWithManualFlow, setPlaidWithManualFlow] =
    useState<boolean>(false);

  const plaidLinkTokenRef = useRef<string | null>(null);
  const plaidInstanceRef = useRef<CustomPlaid | null>(null);
  const resolverRef =
    useRef<null | ((data: null | OpenPlaidModalSuccessResponse) => void)>(null);
  const rejecterRef =
    useRef<null | ((data: null | OpenPlaidModalErrorResponse) => void)>(null);

  // 2. creates Plaid handlers
  // 2.1 success handler for successful connections
  const onSuccess: PlaidLinkOnSuccess = (plaidPublicToken, plaidMetaData) => {
    resolverRef.current?.({ plaidPublicToken, plaidMetaData });
  };

  // 2.2 exit handler for errors and incomplete flows
  // when plaidMetaData.status === PlaidExitStatus.InstitutionNotFound,
  //    treat this case as an error so the promise returned by openPlaidModal
  //    includes a rejection that we can catch.
  const onExit: PlaidLinkOnExit = (error, plaidMetaData) => {
    setPlaidModalState(PlaidModalState.idle);
    if (error || plaidMetaData.status === PlaidExitStatus.InstitutionNotFound) {
      rejecterRef.current?.({ error, plaidMetaData });
    }
    resolverRef.current?.(null);
  };

  // 2.3 event handler for:
  // - HANDOFF: fired after Plaid flow completes successfully
  const onEvent: PlaidLinkOnEvent = async (eventName, plaidMetaData) => {
    if (eventName === PlaidLinkStableEvent.HANDOFF) {
      setPlaidModalState(PlaidModalState.idle);
    }

    trackEvent({
      plaidEvent: eventName,
      plaidMetaData,
    });
  };

  // 3. function for Plaid Instances
  // 3.1 factory function
  const factoryPlaidInstance = async (options?: {
    bankItemId?: string;
    forceNewInstance?: boolean;
    forceOauthPopup?: boolean;
    withManualFlow?: boolean;
  }) => {
    // TODO: MF - this was blocking me locally
    // meant to block the code until plaid's script is loaded
    // but it was never returning false
    // even with this commented out, lines 102-104 would throw and error if the script isn't loaded
    /* if (plaidScriptLoading) {
      return null;
    } */

    if (plaidScriptError || !window.Plaid) {
      throw plaidScriptError || new Error('Error loading Plaid');
    }

    let plaidModalCustomization = PlaidModalCustomization.Default;
    if (options?.withManualFlow) {
      plaidModalCustomization = PlaidModalCustomization.WithManual;
    }

    let plaid_link_token = '';
    const currentUrl = window.location.href;
    const isRedirectUri = currentUrl.indexOf('oauth_state_id') > 1;

    // if this is a redirect uri from plaid,
    // check and see if there's a token in localStorage
    // if we're forcing a new instance or forcing the oauth popup,
    // then we want to create a new token anyway
    if (
      isRedirectUri &&
      !(options?.forceNewInstance || options?.forceOauthPopup)
    ) {
      const localStoragePlaidLinkToken = localStorage.getItem(
        localStorageVariablePlaidLinkToken,
      );

      if (localStoragePlaidLinkToken) {
        plaid_link_token = JSON.parse(localStoragePlaidLinkToken);
      }
    }

    if (!plaid_link_token) {
      const { link_token } = await createLinkToken(
        business?.Id,
        user?.Id,
        plaidModalCustomization,
        options?.bankItemId,
        options?.forceOauthPopup,
      );

      plaid_link_token = link_token;

      // if we're not forcing the oauth popup, save the token in localStorage
      if (!options?.forceOauthPopup) {
        localStorage.setItem(
          localStorageVariablePlaidLinkToken,
          JSON.stringify(link_token),
        );
      }
    }

    plaidLinkTokenRef.current = plaid_link_token;

    const plaidCreateConfig: {
      token: string;
      receivedRedirectUri?: string;
      onSuccess: PlaidLinkOnSuccess;
      onExit: PlaidLinkOnExit;
      onEvent: PlaidLinkOnEvent;
    } = {
      token: plaidLinkTokenRef.current,
      onSuccess,
      onExit,
      onEvent,
    };

    if (isRedirectUri) {
      plaidCreateConfig.receivedRedirectUri = currentUrl;
    }

    const plaidInstance = window.Plaid.create(plaidCreateConfig) as CustomPlaid;
    plaidInstance.destroyed = false;
    return plaidInstance;
  };

  // 3.2 function to close and destroy existing instance
  const destroyPlaidInstance = (plaidInstance: CustomPlaid | null): void => {
    if (plaidInstance) {
      plaidInstance.exit();
      plaidInstance.destroy();
      plaidInstance.destroyed = true;
    }
  };

  // 4. runs side effects
  // 4.1 effect responsible for recreating the Plaid instance when it is idle
  // OBS: it is important to do this while idle so that modal is ready to be opened
  // when the button is clicked, otherwise there will be a delay to build the modal
  useEffect(() => {
    const runEffect = async () => {
      if (!plaidScriptLoading && plaidModalState === PlaidModalState.idle) {
        destroyPlaidInstance(plaidInstanceRef.current);
        plaidInstanceRef.current = await factoryPlaidInstance({
          withManualFlow: plaidWithManualFlow,
        });
      }
    };

    runEffect();
  }, [business?.Id, user?.Id, plaidModalState, plaidScriptLoading]);

  // 5. creates hook artifacts
  // 5.1 function that invokes the modal
  const openPlaidModal: OpenPlaidModal = async ({
    bankItemId,
    forceNewInstance = false,
    withManualFlow = false,
    forceOauthPopup = false,
  } = {}) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      setPlaidModalState(PlaidModalState.running);
      setPlaidWithManualFlow(withManualFlow);

      resolverRef.current = resolve;
      rejecterRef.current = reject;

      if (
        forceNewInstance ||
        !plaidLinkTokenRef.current ||
        !plaidInstanceRef.current ||
        plaidInstanceRef.current?.destroyed
      ) {
        destroyPlaidInstance(plaidInstanceRef.current);
        plaidInstanceRef.current = await factoryPlaidInstance({
          bankItemId,
          forceNewInstance,
          forceOauthPopup,
          withManualFlow,
        });
      }

      plaidInstanceRef.current?.open();
    });
  };

  // 6. return hook artifacts
  return openPlaidModal;
};
