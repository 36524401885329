import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { useBusiness } from 'hooks/business/use-business';

export const GrowthPromoDepositConfirmation = () => {
  const { data: business } = useBusiness();
  const claimedOffer = business?.Properties?.claimed2023DecemberGrowthPromo;

  if (!claimedOffer) {
    return null;
  }

  return (
    <FeatureFlag
      enabled={
        <Box mt={4}>
          <Typography color="black19" variant={TypographyVariant.Header5}>
            Treasure Fees Discount
          </Typography>

          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            For all qualifying deposits, you&apos;ll see your fee reductions on
            your statement starting in January 2024. For more details on how the
            offer works, see our{' '}
            <a
              href="//www.treasurefi.com/promotions/50-percent-off-investment-management-fees"
              rel="noreferrer"
              target="_blank"
            >
              <Typography color="blue2" variant={TypographyVariant.Paragraph2}>
                Terms and Conditions
              </Typography>
            </a>
            .
          </Typography>
        </Box>
      }
      flag={FeatureFlags.REACT_APP_2023_Q4_GROWTH_PROMO_ENABLED}
    />
  );
};
