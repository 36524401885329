import React from 'react';
import { TreasureFiLinks } from 'enums';
import { Styled } from './sign-up.style';

export const TosLinks: React.FC = () => {
  return (
    <Styled.TosLinks>
      For investment disclosures, view our{' '}
      <a target="_blank" href={TreasureFiLinks.TermsOfUse} rel="noreferrer">
        terms of service
      </a>{' '}
      and our{' '}
      <a target="_blank" href={TreasureFiLinks.PrivacyPolicy} rel="noreferrer">
        privacy policy
      </a>
    </Styled.TosLinks>
  );
};
