import React from 'react';
import {
  i18nNamespace,
  DepositWithdrawalVariant,
  IDepositWithdrawal,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation, Trans } from 'app/i18n';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Box, Divider, Grid } from '@material-ui/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { Typography, TypographyVariant } from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Size } from 'enums';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { EstimatedArrivalBox } from 'modules/2023-q3/deposit-withdrawal/withdrawal/components/estimated-arrival-boxes/components/estimated-arrival-box/estimated-arrival-box';
import { GrowthPromoDepositConfirmation } from 'modules/2023-q4/growth-promo/deposit-confirmation/deposit-confirmation';
import { dayjs } from 'app/dayjs';
import isToday from 'dayjs/plugin/isToday';
dayjs.extend(isToday);

export interface IWithdrawalAmounts {
  oneDayAmount?: number;
  threeDayAmount?: number;
}

interface ConfirmationProps extends IDepositWithdrawal {
  from: { pathname: string };

  nextStep: () => void;

  startDate: string;

  withdrawalAmounts?: IWithdrawalAmounts;
}

export const Confirmation: React.FC<ConfirmationProps> = ({
  from,
  nextStep,
  variant,
  startDate,
  withdrawalAmounts,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;
  const DepositOrWithdrawal = t(isDeposit ? 'Deposit' : 'Withdrawal');

  const isScheduled = !dayjs(startDate).isToday();

  const buttonMakeAnother = (
    <Button fullWidth onClick={nextStep}>
      <ButtonContent>
        {t('Make Another', {
          DepositOrWithdrawal,
        })}
      </ButtonContent>
    </Button>
  );

  const buttonView = (
    <Link
      to={
        isScheduled
          ? `${RoutesPath.pages.dashboard.path}#scheduled-transfers`
          : `${RoutesPath.pages.dashboard.path}#pending-transactions`
      }
    >
      <Button fullWidth>
        <ButtonContent>
          {t('View', {
            DepositOrWithdrawal,
          })}
        </ButtonContent>
      </Button>
    </Link>
  );

  const buttonDone = (
    <Link to={from?.pathname || RoutesPath.pages.dashboard.path}>
      <Button fullWidth>
        <ButtonContent>{t('Done', { ns: 'app' })}</ButtonContent>
      </Button>
    </Link>
  );

  const mobileButtons = (
    <>
      <Box pb={2}>
        <Divider />
      </Box>

      <Grid item xs={12}>
        <Box pb={1.5} px={2}>
          {buttonMakeAnother}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box pb={1.5} px={2}>
          {buttonView}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box pb={2} px={2}>
          {buttonDone}
        </Box>
      </Grid>
    </>
  );

  return (
    <FormContainer size={Size.Medium}>
      <Box mb={3}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          {isScheduled
            ? t(isDeposit ? 'Deposit scheduled' : 'Withdrawal scheduled')
            : t(isDeposit ? 'Deposit initiated' : 'Withdrawal initiated')}
        </Typography>
      </Box>

      <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
        {t(isDeposit ? 'Deposit confirmation' : 'Withdrawal confirmation')}
      </Typography>

      <Box mt={5}>
        <Typography color="black19" variant={TypographyVariant.Header5}>
          {t('Timeline', {
            DepositOrWithdrawal,
          })}
        </Typography>

        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          <Trans
            t={t}
            i18nKey={
              isScheduled
                ? isDeposit
                  ? 'Deposit Timeline Explanation Scheduled'
                  : 'Withdrawal Timeline Explanation Scheduled'
                : isDeposit
                ? 'Deposit Timeline Explanation'
                : 'Withdrawal Timeline Explanation'
            }
            components={{
              supportEmail: (
                <Typography
                  color="blue2"
                  variant={TypographyVariant.Paragraph2}
                >
                  <a
                    href={`mailto:support@treasure.tech?subject=${DepositOrWithdrawal}`}
                  >
                    support@treasure.tech
                  </a>
                </Typography>
              ),
            }}
          />
        </Typography>

        {isDeposit && <GrowthPromoDepositConfirmation />}

        {!isDeposit && withdrawalAmounts && (
          <Box mt={3}>
            <Grid container spacing={2}>
              {typeof withdrawalAmounts?.oneDayAmount !== 'undefined' &&
                withdrawalAmounts?.oneDayAmount > 0 && (
                  <Grid item xs>
                    <EstimatedArrivalBox
                      amount={withdrawalAmounts?.oneDayAmount}
                      title={t('Est. Arrival is 1 Business Day')}
                    />
                  </Grid>
                )}

              {typeof withdrawalAmounts?.threeDayAmount !== 'undefined' &&
                withdrawalAmounts?.threeDayAmount > 0 && (
                  <Grid item xs>
                    <EstimatedArrivalBox
                      amount={withdrawalAmounts?.threeDayAmount}
                      title={t('Est. Arrival is 3-5 Business Days')}
                    />
                  </Grid>
                )}
            </Grid>
          </Box>
        )}
      </Box>

      <MobileView
        mobile={
          <>
            {/* buffer for the fixed buttons below */}
            <Box bgcolor="white" visibility="hidden" pt={5}>
              {mobileButtons}
            </Box>

            <Box bgcolor="white" position="fixed" bottom={0} left={0} right={0}>
              {mobileButtons}
            </Box>
          </>
        }
      >
        <Box mt={25}>
          <Grid container spacing={2}>
            <Grid item xs>
              {buttonMakeAnother}
            </Grid>

            <Grid item xs>
              {buttonView}
            </Grid>

            <Grid item xs>
              {buttonDone}
            </Grid>
          </Grid>
        </Box>
      </MobileView>
    </FormContainer>
  );
};
