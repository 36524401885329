import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ColumnType } from 'modules/dashboard/components';
import { Styled } from './table-header.style';

interface TableHeaderProps {
  columns: ColumnType[];
}

export const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => {
  return (
    <Box>
      <Styled.Header>
        <Grid container alignItems="flex-end">
          {columns.map((column, index) => {
            return (
              <Grid item key={index} xs={column.size ?? 1}>
                <Box p={1} textAlign={column.alignment}>
                  <Typography
                    color="grey4"
                    variant={TypographyVariant.Paragraph2}
                  >
                    {column.name ?? `\u00A0`} {/* whitespace */}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Styled.Header>

      <Styled.Divider />
    </Box>
  );
};
