import styled from 'styled-components';
import { breakpoints } from 'styles/css-constants';
import { Box } from '@material-ui/core';
import { PasswordCriteriaCheck as PasswordCriteriaCheckAlias } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/password';

const Container = styled(Box)`
  background-color: var(--color-white);
  border-radius: var(--border-radius-02);
  box-shadow: var(--box-shadow-06);
  width: 100%;

  ${breakpoints.up('md')} {
    width: 450px;
  }
`;

const PasswordCriteriaCheck = styled(PasswordCriteriaCheckAlias)`
  background-color: unset;
  border: 0 none;
  box-shadow: none;

  ${breakpoints.up('md')} {
    position: absolute;
    top: 30px;
    right: -160px;
  }
`;

export const Styled = {
  Container,
  PasswordCriteriaCheck,
};
