export enum TrackPageSection {
  // Layout
  NavBar = 'NavBar',
  SettingsMenu = 'SettingsMenu',
  UserMenu = 'UserMenu',

  // Modules
  AccountIntake = 'AccountIntake',
  PersistentError = 'PersistentError',
  PortfolioAllocation = 'PortfolioAllocation',

  // Modals
  MultipleAccountModal = 'Multiple account modal',
  PortfolioAllocationModal = 'Portfolio allocation modal',

  // Demo
  DemoPortfolioAllocation = 'Demo Portfolio Allocation',
  DemoPortfolioAllocationComparison = 'Demo Portfolio Allocation Comparison',

  // Global Issue
  GlobalServiceIssueNotification = 'GlobalServiceIssueNotification',
  GlobalServiceIssuePage = 'GlobalServiceIssuePage',

  // Plaid
  PlaidModal = 'PlaidModal',

  // Treasure Reserve Dashboard
  TreasureReserveDashboardPortfolio = 'Treasure Reserve Dashboard Portfolio',
  TreasureReserveDashboardReturns = 'Treasure Reserve Dashboard Returns',
  TreasureReserveDashboardTransactions = 'Treasure Reserve Dashboard Transactions',
}
