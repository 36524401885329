import React from 'react';
import PropTypes from 'prop-types';
import { dayjs } from 'app/dayjs';
import { Typography, TypographyVariant } from 'components/core';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { DateAndValue } from 'types';
import { colors, getColor } from 'styles/css-constants';
import { useProductYieldContext } from 'components/banking/product-yield';
import { Styled } from './line-chart-by-period.style';

interface LineChartByPeriodProps {
  highlightedColor?: string;
  data: DateAndValue[];
  comparisonData?: DateAndValue[];
}

interface ChartData {
  key: number;
  date: string;
  value: number;
  comparisonDate?: string;
  comparisonValue?: number;
}

const LineChartByPeriod: React.FC<LineChartByPeriodProps> = ({
  highlightedColor = 'nero',
  data = [],
  comparisonData = [],
}) => {
  const productYieldContext = useProductYieldContext();
  const hasData = data.length;
  const hasComparisonData = comparisonData.length;

  // fake data used to draw a default line when there is no data
  const chartNoData = [
    { date: '0', value: 3 },
    { date: '1', value: 9 },
    { date: '2', value: 12 },
    { date: '3', value: 13 },
  ];

  // process chart data and return a ChartData[]
  // if there is comparison data, append it to the ChartData
  // if comparisonData exists, it will always been the same length as data
  const processChartData = (): ChartData[] => {
    let chartData = data.map((data: DateAndValue, index: number) => {
      return {
        key: index,
        date: data.date,
        value: data.value,
      };
    });
    if (hasComparisonData) {
      const chartComparisonData = comparisonData.map((data: DateAndValue) => {
        return {
          comparisonDate: data.date,
          comparisonValue: data.value,
        };
      });

      chartData = chartData.map((data, index) => ({
        ...data,
        ...chartComparisonData[index],
      }));
    }
    return chartData;
  };

  const renderToolTip = ({ payload }: any) => {
    if (payload.length) {
      // the "active period" payload index here can be different depending
      // on if there is comparison data or not, since we draw 1 line if there
      // isn't comparison data and 2 if there is.
      // it will always be the last element in `payload`.
      const activePayloadIndex = payload.length - 1;
      productYieldContext.setTotalEarningsForPeriod(
        payload[activePayloadIndex].value,
      );
      if (hasComparisonData) {
        productYieldContext.setTotalEarningsForComparisonPeriod(
          payload[activePayloadIndex].payload.comparisonValue,
        );
      }
      const { date } = payload[activePayloadIndex].payload;
      if (date) {
        return (
          <Styled.TooltipContent px={1} py={0.75}>
            <Typography
              color={highlightedColor}
              variant={TypographyVariant.SmallLabel3}
            >
              {dayjs(date).format('M/DD/YY')}
            </Typography>
            {hasComparisonData ? (
              <Typography color="jumbo" variant={TypographyVariant.SmallLabel3}>
                {dayjs(
                  payload[activePayloadIndex].payload.comparisonDate,
                ).format('M/DD/YY')}
              </Typography>
            ) : (
              ''
            )}
          </Styled.TooltipContent>
        );
      }
    } else {
      return '';
    }
  };

  return (
    <ResponsiveContainer height={Styled.ChartHeight} width="100%">
      <LineChart
        data={hasData ? processChartData() : chartNoData}
        onMouseLeave={(data) => {
          productYieldContext.resetTotalEarningsForPeriod();
        }}
      >
        <XAxis
          dataKey="key"
          axisLine
          tick={false}
          stroke={Styled.xAxisLineStroke}
        />
        {hasComparisonData ? (
          <Line
            activeDot={false}
            dot={false}
            dataKey="comparisonValue"
            stroke={Styled.NoDataChartLineStroke}
            strokeWidth={2}
          />
        ) : (
          ''
        )}
        <Line
          activeDot={false}
          dot={false}
          dataKey="value"
          onAnimationEnd={() =>
            productYieldContext.resetTotalEarningsForPeriod()
          }
          stroke={
            hasData
              ? getColor(highlightedColor as keyof typeof colors)
              : Styled.NoDataChartLineStroke
          }
          strokeWidth={2}
          strokeDasharray={hasData ? '' : '10 10'}
        />
        {hasData ? (
          <Tooltip
            isAnimationActive={false}
            content={renderToolTip}
            position={{ y: 0 }}
          />
        ) : (
          ''
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

LineChartByPeriod.propTypes = {
  highlightedColor: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  comparisonData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ),
};

export { LineChartByPeriod };
