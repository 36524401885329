import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Checkbox } from '../checkbox';
import {
  factoryFormFieldComponent,
  FormFieldSpecs,
} from '../utils/factory-form-field-component';
import { Styled } from './checkbox-controller.style';

export type CheckboxFormFieldSpecs = FormFieldSpecs<{
  helperText?: React.ReactNode;
}>;

export const CheckboxController =
  factoryFormFieldComponent<CheckboxFormFieldSpecs>(
    ({ error, helperText, label, name, onBlur, onChange, value }) => {
      return (
        <>
          <Styled.StyledFormLabel
            checked={value}
            control={<Checkbox error={!!error} name={name} />}
            onChange={onChange}
            onBlur={onBlur}
            label={
              <Typography
                color={error ? 'torchRed' : 'nero'}
                variant={TypographyVariant.LargerLabel}
              >
                {label}
              </Typography>
            }
          />
          {helperText && (
            <Box ml={4}>
              <Typography color="midGray" variant={TypographyVariant.SubLabel}>
                {helperText}
              </Typography>
            </Box>
          )}
        </>
      );
    },
    { componentName: 'CheckboxController' },
  );
