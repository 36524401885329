import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTracking, TrackEventName } from 'modules/tracking';
import { sendGoogleTagManagerEvent } from 'modules/tracking/analytics/google-tag-manager';
import { AffiliateType } from 'enums';
import { useParams } from 'react-router-dom';
import { createCtx } from 'utils/create-ctx';
import { SignUpConfirmation } from './sign-up-confirmation';
import { SignUpForm } from './sign-up-form';
import { useGetAffiliate } from 'modules/sign-up/hooks/use-get-affiliate';
import { useSelfServeOnboarding } from 'modules/sign-up/hooks/use-self-serve-onboarding';
import { useSendOnboardingEmail } from 'modules/sign-up/hooks/use-send-onboarding-email';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { StreamlinedOnboardingSetPassword } from 'modules/onboarding/v3-streamlined-onboarding/no-auth/streamlined-onboarding-set-password';

enum Page {
  SignUp,
  Confirmation,
}

export interface Affiliate {
  Code: string | null;
  Type: string | null;
  Identifier: string | null;
  CreatedBy: AffiliateType | null;
}

interface SignUpContext {
  affiliate: Affiliate;
}

export const [useSignUpContext, SignUpCtxProvider] = createCtx<SignUpContext>();

export const SignUpContainer: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { handleSubmit, control } = useForm({
    defaultValues: { email: '', businessName: '' },
  });
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setPage] = useState(Page.SignUp);
  const [businessId, setBusinessId] = useState('');
  const [affiliate, setAffiliate] = useState<Affiliate>({
    Code: null,
    Type: null,
    Identifier: null,
    CreatedBy: null,
  });
  const [setPasswordEmail, setSetPasswordEmail] = useState<string>();
  const { trackEvent } = useTracking();
  const getAffiliate = useGetAffiliate();
  const selfServeOnboarding = useSelfServeOnboarding();
  const sendOnboardingEmail = useSendOnboardingEmail();
  const { affiliateCode } = useParams<{ affiliateCode: string }>();

  useEffect(() => {
    // if there is an affiliateCode in the url params,
    // check that it's valid and get back the affiliate object.
    const verifyAffiliate = () => {
      if (affiliateCode) {
        getAffiliate.mutate(
          { code: affiliateCode },
          {
            onSuccess: (response: Affiliate) => {
              if (response) {
                setAffiliate(response);
              }
            },
          },
        );
      }
    };

    verifyAffiliate();
  }, [affiliateCode]);

  const submitOnboardingData = async (data: any) => {
    const { email, businessName } = data;
    const requestData: any = {
      DBA: businessName,
      User: {
        Email: email,
      },
    };

    // affiliateCode is optional
    if (affiliateCode) {
      requestData.AffiliateCode = affiliateCode;
    }

    await selfServeOnboarding.mutate(requestData, {
      onSuccess: (resp) => {
        const responseBusinessId = resp.data.business.Id;
        setBusinessId(responseBusinessId);

        // Send data to Google Tag Manager for tracking
        sendGoogleTagManagerEvent('signup-completion', {
          category: 'signup',
          action: 'signup-completed',
        });

        if (
          isFeatureFlagEnabled(
            FeatureFlags.REACT_APP_ONBOARDING_SKIP_EMAIL_VERIFICATION,
          )
        ) {
          setSetPasswordEmail(email);
        } else {
          // Move to the confirmation page BEFORE calling the sendOnboardingEmail so that if email call fails, the confirmation page
          // allows the user to resend it
          setPage(Page.Confirmation);

          sendOnboardingEmail.mutate({ businessId: responseBusinessId });
        }
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  const submitForm = async (data?: any) => {
    if (data && !isLoading) {
      trackEvent({ eventName: TrackEventName.SignUpCreateAccountClicked });
      setLoading(true);

      submitOnboardingData(data);
    }
  };

  if (setPasswordEmail) {
    return <StreamlinedOnboardingSetPassword email={setPasswordEmail} />;
  }

  return (
    <SignUpCtxProvider
      value={{
        affiliate,
      }}
    >
      {currentPage === Page.SignUp ? (
        <SignUpForm
          handleSubmit={handleSubmit(submitForm)}
          control={control}
          isLoading={isLoading}
        />
      ) : (
        <SignUpConfirmation businessId={businessId} />
      )}
    </SignUpCtxProvider>
  );
};
