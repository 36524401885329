import React from 'react';
import { Styled } from './responsive-scroll-content.style';

interface Props {
  children: React.ReactNode;

  firstColumnMinWidth?: number;

  widthToSubtract?: number;
}

export const ResponsiveScrollContent: React.FC<Props> = ({
  children,
  firstColumnMinWidth,
  widthToSubtract,
}) => {
  return (
    <Styled.ScrollContainer widthToSubtract={widthToSubtract}>
      <Styled.ScrollBox firstColumnMinWidth={firstColumnMinWidth}>
        {children}
      </Styled.ScrollBox>
    </Styled.ScrollContainer>
  );
};
