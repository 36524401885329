import dayjs from 'dayjs';
// import { People } from '../types/people';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';

/*export const alphabeticalPeopleNameSort = (
  personA: People,
  personB: People,
): number =>
  `${personA.firstName} ${personA.lastName}`
    .toLowerCase()
    .localeCompare(`${personB.firstName} ${personB.lastName}`);*/

export const displayFormattedDateOfBirth: (dateOfBirth: string) => string = (
  dateOfBirth,
) => dayjs(dateOfBirth).format(DISPLAY_DATE_FORMAT);

export const formattedDateOfBirth: (dateOfBirth: string) => string = (
  dateOfBirth,
) => dayjs(dateOfBirth).format(DEFAULT_DATE_FORMAT);

export const formattedNationalIdentifier: (
  nationalIdentifier: string,
) => string = (nationalIdentifier) => nationalIdentifier.replace(/-/g, '');

export const displayFormattedSSN: (ssn: string) => string = (ssn) =>
  ssn.replace(/(\d{3})-?(\d{2})-?(\d{4})/, '$1-$2-$3');
