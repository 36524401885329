import { dayjs } from 'app/dayjs';
import { TimeFilterOption } from 'modules/dashboard/components';

export const getTimeFilterDateRange = (timeFilter: TimeFilterOption) => {
  // endDate should be the last day of the last month
  const endDate = dayjs()
    .subtract(1, 'month')
    .endOf('month')
    .format('YYYY-MM-DD');
  let startDate = dayjs()
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD');

  if (timeFilter === TimeFilterOption.ALL) {
    // TODO: this should be the first date of business data
    startDate = '2021-07-12';
  } else {
    const parseFilter = parseInt(timeFilter, 10);

    startDate = dayjs()
      .subtract(parseFilter, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  return { endDate, startDate };
};

export const isDateInTimeFilter = (
  date: string,
  timeFilter: TimeFilterOption,
) => {
  if (timeFilter === TimeFilterOption.ALL) {
    return true;
  }

  const parseFilter = parseInt(timeFilter, 10);

  return dayjs().subtract(parseFilter, 'month').isBefore(date);
};
