import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { ApexDocument } from 'types';

export const useCashDocuments = (date: any): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  return useQuery<any>(
    [ReactQueryKeys.CashDocument, date],
    async () => {
      try {
        const response = await treasureApi({
          responseType: 'blob',
        })
          .get(`/documents/cash_statement?date=${date}`)
          .then((response) => {
            window.open(URL.createObjectURL(response.data));
          });

        // return response;
      } catch (error) {
        return [];
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!date,
    },
  );
};
