import React from 'react';
import {
  AccordionProps,
  AccordionDetailsProps,
  AccordionSummaryProps,
} from '@material-ui/core';
import { Styled } from './accordion.style';

export const Accordion = (props: AccordionProps) => {
  return <Styled.Accordion {...props} elevation={0} />;
};

export const AccordionDetails = (props: AccordionDetailsProps) => {
  return <Styled.AccordionDetails {...props} />;
};

export const AccordionSummary = (props: AccordionSummaryProps) => {
  return <Styled.AccordionSummary {...props} />;
};
