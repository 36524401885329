import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { MultiAccountSelect, NoResults } from 'modules/multi-account';
import { useTracking, TrackEventName } from 'modules/tracking';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useBusinesses } from 'hooks/business/use-businesses';

interface Data {
  balance: number;
  id: string;
  name: string;
}

// Eventually, I think we'll upgrade to mui 5 and just use data grid

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aVal = a[orderBy];
  const bVal = b[orderBy];

  if (typeof aVal === 'string' && typeof bVal === 'string') {
    return aVal.localeCompare(bVal);
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'balance',
    numeric: true,
    disablePadding: false,
    label: 'Reserve Balance',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={KeyboardArrowDownIcon}
            >
              <Typography variant={TypographyVariant.SubHeader1}>
                {headCell.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell /> {/* action/switch to column */}
      </TableRow>
    </TableHead>
  );
};

interface MultiAccountTableProps {
  filterText: string;

  setFilterText: React.Dispatch<React.SetStateAction<string>>;
}

export const MultiAccountTable: React.FC<MultiAccountTableProps> = ({
  filterText,
  setFilterText,
}) => {
  const [data, setData] = useState<Data[]>([]);

  const { data: business } = useBusiness();
  const { data: businesses } = useBusinesses();

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('balance');

  const { trackEvent } = useTracking();

  useEffect(() => {
    const updateData = () => {
      const dataArray: Data[] = [];
      let hasAllBalances = true;

      businesses?.map((businessData: any) => {
        dataArray.push({
          balance: businessData.balance,
          id: businessData.Id,
          name: businessData.DBA || businessData.LegalName,
        });

        if (businessData.balance === undefined) {
          hasAllBalances = false;
        }

        return null;
      });

      setData(dataArray);

      // the balances are updated in the background, for now, check if we have them all, if not, refresh
      if (!hasAllBalances) {
        setTimeout(updateData, 3000);
      }
    };

    updateData();
  }, [businesses]);

  const filteredData = data.filter((dataf) => {
    return dataf.name?.toLowerCase().includes(filterText?.toLowerCase());
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    trackEvent({
      eventName: TrackEventName.MultipleAccountTableSorted,
      sortBy: property,
    });
  };

  // no results
  if (filteredData.length === 0) {
    return <NoResults setFilterText={setFilterText} />;
  }

  return (
    <TableContainer>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(filteredData, getComparator(order, orderBy)).map(
            (row, index) => {
              return (
                <TableRow tabIndex={-1} key={row.id}>
                  <TableCell component="th" scope="row">
                    <MultiAccountSelect businessId={row.id}>
                      {row.name}
                    </MultiAccountSelect>
                  </TableCell>

                  <TableCell align="right">
                    {row.balance === undefined ? (
                      <Typography
                        color="stromboli"
                        variant={TypographyVariant.Body}
                      >
                        <CircularProgress color="inherit" size={20} />
                      </Typography>
                    ) : (
                      <Currency
                        number={row.balance}
                        variant={CurrencyVariant.Full}
                      />
                    )}
                  </TableCell>

                  <TableCell align="right">
                    {row.id === business?.Id ? (
                      <i>Currently Viewing</i>
                    ) : (
                      <MultiAccountSelect businessId={row.id}>
                        <Button>
                          <ButtonContent>View as {row.name}</ButtonContent>
                        </Button>
                      </MultiAccountSelect>
                    )}
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MultiAccountTable.propTypes = {
  filterText: PropTypes.string.isRequired,
  setFilterText: PropTypes.func.isRequired,
};
