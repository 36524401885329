import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { V3PortalLayoutWithoutGrowthPromo } from 'layouts/v3-portal-layout/v3-portal-layout-without-growth-promo';
import { PageNotFound } from 'modules/page-errors/page-not-found';

import { Spinner } from 'components/core';

// idle detection
import { useIdleContext } from 'modules/polling/idle-detection/idle-detection';

// hooks
import { useStoreAuthentication, useStoreLoginMFA } from 'stores';
import { useAuthenticateUser } from 'hooks/portal/use-authenticate-user';
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';

// login user agent (for mfa)
import { useLoginDevice } from 'modules/2023-q3/login-mfa/hooks/use-login-device';

// routes
import authRequiredRoutes from './authRequiredRoutes';
import authNotRequiredRoutes from './authNotRequiredRoutes';
import AppRoute from './app-route';

const Routes: React.FC = () => {
  const { idleSignedOut } = useIdleContext();
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);
  const { passedMFA, setPassedMFA } = useStoreLoginMFA((state) => state);

  const { isLoading: isLoadingAuthenticateUser } = useAuthenticateUser();

  // trigger useBusiness to add businessId to the store
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  // user is needed for login device verification
  const { isLoading: isLoadingUser } = useUser(business?.Id);
  // IRI-2567 fixing race condition: checking for valid saved devices
  const [isLoadingValidDevice, setIsLoadingValidDevice] = useState<boolean>();
  const loginDevice = useLoginDevice();

  useEffect(() => {
    if (isSignedIn && !passedMFA && !isLoadingUser) {
      const getValidDevice = async () => {
        const isValidDevice = await loginDevice.isValidDevice();
        if (isValidDevice) {
          setPassedMFA();
        }

        setIsLoadingValidDevice(false);
      };

      getValidDevice();
    } else if (passedMFA) {
      setIsLoadingValidDevice(false);
    } else if (isSignedIn) {
      setIsLoadingValidDevice(true);
    }
  }, [isSignedIn, isLoadingUser, passedMFA]);

  if (
    isLoadingAuthenticateUser ||
    isLoadingBusiness ||
    isLoadingUser ||
    isLoadingValidDevice
  ) {
    return <Spinner />;
  }

  return (
    <Switch>
      {authRequiredRoutes.map((route) => (
        <AppRoute
          key={route.path}
          requireAuth
          allowNoAuth={route.allowNoAuth}
          idleSignedOut={idleSignedOut}
          isSignedIn={isSignedIn}
          {...route}
        />
      ))}

      {authNotRequiredRoutes.map((route) => (
        <AppRoute
          key={route.path}
          requireNoAuth
          isSignedIn={isSignedIn}
          {...route}
        />
      ))}

      <AppRoute
        layout={V3PortalLayoutWithoutGrowthPromo}
        component={PageNotFound}
      />
    </Switch>
  );
};

export default Routes;
