import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@material-ui/core';
import { ClickAnchor, Typography, TypographyVariant } from 'components/core';
import { NotificationBar } from './components/notification-bar/notification-bar';

interface Props {
  handleDrawer: ({
    clickElement,
    clickText,
    drawerDirection,
  }: {
    clickElement?: string | undefined;
    clickText?: string | undefined;
    drawerDirection: boolean;
  }) => void;
}

export const GrowthPromoNotification: React.FC<Props> = ({ handleDrawer }) => {
  return (
    <Box
      onClick={() =>
        handleDrawer({
          clickElement: 'Notification Bar',
          drawerDirection: true,
        })
      }
    >
      <NotificationBar>
        <Box textAlign="center">
          <ClickAnchor>
            <Typography color="white" variant={TypographyVariant.Paragraph2}>
              Get 50% Off Investment Management Fees for a Full Year When You
              Deposit $2M or More! / ENDS DEC 15, 2023
            </Typography>
          </ClickAnchor>
        </Box>
      </NotificationBar>
    </Box>
  );
};
