import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { ConnectedBankAccount } from 'types';

export const useConnectedAccounts = (): UseQueryResult<
  ConnectedBankAccount[]
> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  return useQuery<ConnectedBankAccount[], Error>(
    [ReactQueryKeys.ConnectedAccounts],
    async () => {
      const response = await treasureApi().get('/accounts');

      return response.data;
    },
  );
};
