import {
  SelectFormFieldSpecs,
  TextFormFieldSpecs,
} from 'modules/form-helpers/index';

import { bankRoutingMask } from 'utils/forms/masks';

import { bankRoutingRegExp } from 'utils/forms/regular-expressions';

import { isValidRoutingNumber } from 'utils/forms/validators';

export enum BankAccountType {
  Checking = 'Checking',
  Savings = 'Savings',
}

type FormFields = {
  accountNumber: TextFormFieldSpecs;
  accountType: SelectFormFieldSpecs;
  bankName: TextFormFieldSpecs;
  routingNumber: TextFormFieldSpecs;
};

export const formFields: FormFields = {
  accountNumber: {
    label: 'Account Number',
    name: 'accountNumber',
    placeholder: 'Account number',
    validations: {
      required: true,
    },
  },
  accountType: {
    label: 'Account Type',
    name: 'accountType',
    options: Object.entries(BankAccountType).map(([value, label]) => {
      return { label, value };
    }),
    placeholder: 'Checking or Savings',
    validations: {
      required: true,
    },
  },
  bankName: {
    label: 'Bank Name',
    name: 'bankName',
    placeholder: 'Name',
    validations: {
      required: true,
    },
  },
  routingNumber: {
    label: 'Routing Number',
    name: 'routingNumber',
    placeholder: '9 digit routing',
    mask: bankRoutingMask,
    validations: {
      required: true,
      pattern: bankRoutingRegExp,
      validate: (value: string) =>
        isValidRoutingNumber(value) ||
        `${formFields.routingNumber.label} must be valid`,
    },
  },
};
