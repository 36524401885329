import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { Toast } from 'components/layouts';

export const useTreasureReserveEarnings = (
  businessId: string,
  period: string,
) => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery<any, Error>(
    ReactQueryKeys.TreasureReserveEarnings,
    async () => {
      try {
        const queryParam = period === '' ? '' : `?startDate=${period}`;

        const response = await treasureApi().get(
          `/business/${businessId}/reserve-account/earnings${queryParam}`,
        );

        return response.data;
      } catch (error: any) {
        throw new Error(error);
      }
    },
    {
      enabled: !!businessId,
      onError: (error: any) => {
        Toast.showError(error);
      },
      retry: false,
    },
  );
};
