import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  ItemDefinition,
  Percentage,
  Text,
} from 'components/core';
import { Footnote } from 'components/layouts';
import { TopIndicators } from 'enums';
import { LoadingSubIndicator } from 'components/dashboard/top-indicators/sub-indicators/loading/loading-sub-indicators';
import { getRateScale } from 'utils/cash-rate-scale';
import { useTranslation } from 'app/i18n';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useSubIndicator } from 'modules/sub-indicators/hooks/get-subindicator';

import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Styled } from './treasure-reserve-sub-indicator.style';

const TreasureReserveSubIndicator: React.FC = () => {
  const { t } = useTranslation();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );
  const treasureCashRate = portfolios?.rates.cashRate * 100;
  const blendedRate =
    portfolios?.actualBlendedRate > 0
      ? portfolios?.actualBlendedRate * 100
      : treasureCashRate;
  const { data: cashReserve = {}, isLoading: subIndicatorsLoading } =
    useSubIndicator(TopIndicators.CashReserve);

  const isLoading =
    isLoadingBusiness || isLoadingPortfolios || subIndicatorsLoading;

  const currentlyEarning = (
    <FeatureFlag
      disabled={
        <Footnote
          description={t('high yield description', { ns: 'compliance' })}
          stayOnScreen
          symbol="*"
        >
          Currently Earning
        </Footnote>
      }
      enabled={<>Currently Earning</>}
      flag={FeatureFlags.REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED}
    />
  );

  const metrics = [
    {
      label: 'Net Deposits',
      value: (
        <Currency
          number={cashReserve.netDeposits}
          variant={CurrencyVariant.NearestDollar}
        />
      ),
    },
    {
      label: currentlyEarning,
      value: (
        <Percentage
          decimalScale={2}
          number={blendedRate}
          showExtremePrecision
        />
      ),
      hidden: blendedRate <= 0,
    },
    {
      label: 'Dividends Earned',
      value: (
        <Currency
          number={cashReserve.dividends}
          variant={CurrencyVariant.NearestDollar}
        />
      ),
    },
  ];

  if (isLoading) {
    return <LoadingSubIndicator />;
  }

  return (
    <>
      <Box mb={2}>
        <Text variant={1}>
          With your Treasure Reserve account, your money is now working for you.
          Treasure deploys the most advanced investing strategies to give you
          the same return as the most profitable corporations in the world.
        </Text>
      </Box>

      <Styled.Divider />

      {!!cashReserve.return && (
        <Box mt={2}>
          <Grid container spacing={3}>
            {metrics.map(function (metric, index) {
              return (
                !metric.hidden && (
                  <Grid item xs={4} key={index.toString()}>
                    <Box mb={0.5}>
                      <ItemDefinition
                        label={metric.label}
                        value={
                          <Text color="nero" variant={3}>
                            {metric.value}
                          </Text>
                        }
                      />
                    </Box>
                  </Grid>
                )
              );
            })}
          </Grid>
        </Box>
      )}
    </>
  );
};

export { TreasureReserveSubIndicator };
