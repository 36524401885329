import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTracking, TrackEventName } from 'modules/tracking';
import NumberFormat from 'react-number-format';
import { TextField } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/text-field';
import { Styled } from './allocation-slider.style';

const maxAmount = 100;

interface AllocationSliderProps {
  allocation: number;

  color: string;

  eventLabel: string;

  max?: number;

  onChange: (value: number) => void;
}

export const AllocationSlider: React.FC<AllocationSliderProps> = ({
  allocation,
  color,
  eventLabel,
  max,
  onChange,
}) => {
  const { trackEvent } =
    useTracking<{
      eventName: TrackEventName;
      label: string;
      value: number;
    }>();

  const handleOnChange = (value: number) => {
    onChange(value);

    trackEvent({
      eventName: TrackEventName.Slider,
      label: eventLabel,
      value,
    });
  };

  const handleInputOnChange = (value: number, isEvent: boolean) => {
    onChange(value);

    // inputs could change via the algorithm,
    // isEvent is true when the user changes the input
    if (isEvent) {
      trackEvent({
        eventName: TrackEventName.Input,
        label: eventLabel,
        value,
      });
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={5}>
        <NumberFormat
          allowNegative={false}
          customInput={TextField}
          decimalScale={1}
          isAllowed={(values) => {
            const { floatValue } = values;
            return floatValue ? floatValue < maxAmount : true;
          }}
          suffix="%"
          onValueChange={(values, sourceInfo) => {
            const isEvent = sourceInfo.source === 'event';

            handleInputOnChange(values.floatValue || 0, isEvent);
          }}
          value={allocation}
        />
      </Grid>

      <Grid item xs>
        <Box pl={2}>
          <Styled.Slider
            onChange={(event, value) => handleOnChange(value as number)}
            styledColor={color}
            value={allocation}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
