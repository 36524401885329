import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { getColor } from 'styles/css-constants';

interface CriteriaProps {
  $isValid: boolean;
}

const CriteriaBox = styled(Box)<CriteriaProps>`
  border: 1px solid
    ${(props) => (props.$isValid ? getColor('green2') : getColor('grey4'))};
  border-radius: 30px;
`;

export const Styled = {
  CriteriaBox,
};
