import styled from 'styled-components';

const Popover = styled.div`
  overflow-y: hidden;
  > div {
    position: relative;
    top: 4px;
    background-color: var(--color-electricViolet);
    box-shadow: var(--box-shadow-01);
    border-radius: var(--border-radius-02);

    &:after {
      content: '';
      position: absolute;
      top: -30%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent var(--color-electricViolet)
        transparent;
    }
  }
`;

export const Styled = {
  Popover,
};
