import styled from 'styled-components';
import { breakpoints } from 'styles/css-constants';

const InfoGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InfoGridLabel = styled.p`
  color: var(--color-gothic);
  font-family: Nunito Sans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2px;
  line-height: normal;
  margin-right: 8px;
  text-transform: uppercase;
  margin: 0;
  width: 100%;
  margin-bottom: 8px;

  ${breakpoints.up('sm')} {
    width: 172px;
    margin-bottom: 0px;
  }
`;

const InfoGridRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 16px 0;
`;

const InfoGridValue = styled.p`
  color: var(--color-regalBlue);
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2.15px;
  line-height: normal;
  margin: 0;
`;

const InfoGridComponent = styled.div`
  display: flex;
  flex: 1;
`;

export const Styled = {
  InfoGridContainer,
  InfoGridLabel,
  InfoGridRow,
  InfoGridValue,
  InfoGridComponent,
};
