import React from 'react';
import { Box } from '@material-ui/core';
import { Styled } from './section-container.style';

interface SectionContainerProps {
  children: React.ReactNode;

  p?: number;
}

export const SectionContainer: React.FC<SectionContainerProps> = ({
  children,
  p,
}) => {
  return (
    <Styled.Container height="100%">
      <Box height="100%" p={p ?? 2}>
        {children}
      </Box>
    </Styled.Container>
  );
};
