import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { Button, Heading, Typography } from 'components/core';

const MFAInputContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 0;
`;

const MFACTAContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ConfirmButton = styled(Button)`
  font-size: var(--font-size-body-04);
  height: 40px;
  width: 300px;
`;

const ResendButton = styled(Button)`
  font-size: var(--font-size-body-04);
  height: 40px;
  margin: 24px 0;
  width: 200px;
`;

const HelpLink = styled(Typography)`
  font-size: 13px;
`;

const CodeContainer = styled(Grid)`
  position: relative;
`;

const CodeError = styled(Heading)`
  text-transform: initial;
`;

const CodeLabel = styled(Typography)`
  font-size: 13px;
`;

const CodeInput = styled.input`
  width: 36px;
  height: 55px;
  border: 1px solid var(--color-lightGrey);
  border-radius: var(--border-radius-02);
  text-align: center;
`;

const CodePrefix = styled(Grid)`
  position: absolute;
  top: 5px;
  left: -35px;
`;

const TextEmphasis = styled.span`
  font-size: 16px;
  color: var(--color-nero);
`;

export const Styled = {
  CodeContainer,
  CodeError,
  CodeInput,
  CodePrefix,
  TextEmphasis,
  CodeLabel,
  MFAInputContainer,
  MFACTAContainer,
  ConfirmButton,
  ResendButton,
  HelpLink,
};
