export enum ApexDocumentType {
  AccountStatement = 'Account Statement',
  TreasureCashStatement = 'Treasure Cash Account Statement', // todo: generalize the ApexDcoumentType to not be apex specific, or create a new type for tcash docs
  '1099C' = '1099 & 1099 Corrections',
  '1099R' = '1099R',
  '1042S' = '1042-S',
  '5498FMV' = '5498 (used as the FMV)',
  '5498ESA' = '5498 ESA',
  FMV = 'FMV',
  '1099Q' = '1099Q',
  SDIRA = 'SDIRA',
  TradeConfirmation = 'TradeConfirmation',
}
