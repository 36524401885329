import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useMutateBusinessProperty = (): UseMutationResult<
  any,
  any,
  any
> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.Business);

  const putBusinessProperty = async ({
    businessId,
    name,
    value,
  }: {
    businessId: string;
    name: string;
    value: string;
  }) => {
    const response = await treasureApi().put(`/smb/${businessId}/properties`, {
      name,
      value,
    });

    return response.data;
  };

  return useMutation(putBusinessProperty, {
    onSuccess: () => {
      return queryClient.refetchQueries([ReactQueryKeys.Business]);
    },
  });
};
