import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { PolicyKey, TreasureFiLinks } from 'enums';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { useLegalEntityTypes } from 'modules/onboarding/v3-streamlined-onboarding/hooks/get-legal-entity-types';
import { WhiteLabelPartners } from 'modules/2023-q4/white-label/consts/white-label-partners';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';
import { numberRegex } from 'components/core/forms/password/password-validations';
import { formErrorFocus } from 'modules/onboarding/v3-streamlined-onboarding/utils';
import { PageTitle } from '../components/page-title';
import {
  AutocompleteController,
  CheckboxController,
  FormFieldContainer,
  SubmitButton,
  TextFieldController,
  formFields,
} from '../components/forms';
import {
  AddressForm,
  AddressFormSection,
  AddressFormSectionVariant,
} from '../components/forms/sections/address/address-form-section';

interface BusinessStructureForm extends AddressForm {
  accountAgreements: boolean;
  businessCountry: string;
  businessTypeId: string;
  phoneNumber: string;
}

type IdType = { Id: string; Name: string };

const unitedStatesCountryValue = 'USA';

const getOptions = (array: IdType[] = []) => {
  return array
    ?.sort((a: { Name: string }, b: { Name: string }) =>
      a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()),
    )
    .map?.((item: IdType) => {
      return { label: item.Name, value: item.Id };
    });
};

export const BusinessStructure: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.BusinessStructure,
  });

  const onboardingContext = useOnboardingContext();
  const savedOnboardingData = onboardingContext.onboardingResponse.data;
  const savedBusinessAddress = savedOnboardingData.business?.addresses?.[0];
  const savedAccountAgreements =
    savedOnboardingData.policiesKeysAgreed?.includes(
      PolicyKey.BankAccountAgreement,
    ) &&
    savedOnboardingData.policiesKeysAgreed?.includes(
      PolicyKey.InvestmentManagementAgreement,
    );

  const { partnerId } = useStoreWhiteLabelPartner((state) => state);

  const partnerData = WhiteLabelPartners.find(
    (partner) => partner.id === partnerId,
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    unregister,
  } = useForm<BusinessStructureForm>();

  // use the set citizenshipCountry and residencyCountry
  // or default to US if not set
  const [isUS, setIsUS] = useState(
    savedOnboardingData?.business.businessCountry ===
      unitedStatesCountryValue ||
      !savedOnboardingData?.business.businessCountry,
  );

  const isBusinessCountryUS = (selected: any) => {
    setIsUS(selected.target.value === unitedStatesCountryValue);

    unregister(['state', 'zipCode'], { keepTouched: true });

    return selected;
  };

  useEffect(() => {
    formErrorFocus(errors, isDirty);
  }, [errors, isDirty]);

  const { data: legalEntityTypes, isLoading: isLoadingLegalEntityTypes } =
    useLegalEntityTypes();

  const onSubmit = (data: BusinessStructureForm) => {
    const {
      accountAgreements,
      businessCountry,
      businessTypeId,
      phoneNumber,
      ...address
    } = data;

    onboardingContext.postOnboardingData({
      businessId: onboardingContext.businessId,
      data: {
        business: {
          addresses: [
            {
              ...address,
              country: businessCountry,
              id: savedBusinessAddress?.id,
            },
          ],
          businessTypeId,
          phoneNumber: phoneNumber
            ? phoneNumber.match(numberRegex)?.join('')
            : undefined,
          riskTolerance: 0,
        },
        policiesKeysAgreed: [
          PolicyKey.BankAccountAgreement,
          PolicyKey.InvestmentManagementAgreement,
        ],
      },
    });

    trackEvent({
      eventName: TrackEventName.IMAAgreed,
    });

    trackEvent({
      eventName: TrackEventName.BusinessClassificationCompleted,
    });

    trackEvent({
      eventName: TrackEventName.RiskProfileCompleted,
    });
  };

  useEffect(() => {
    onboardingContext.showLoading(isLoadingLegalEntityTypes);
  }, [isLoadingLegalEntityTypes]);

  if (!legalEntityTypes) {
    return null;
  }

  return (
    <>
      <PageTitle
        title="Business structure"
        description={
          <>
            This information about your business helps Treasure meet
            requirements from regulators and financial partners. By continuing
            you agree to our{' '}
            <a
              href={TreasureFiLinks.InvestmentManagementAgreement}
              onClick={() =>
                trackEvent({
                  eventName: TrackEventName.IMAOpened,
                })
              }
              rel="noreferrer"
              target="_blank"
            >
              Investment Management Agreement
            </a>
            ,{' '}
            {partnerData?.solicitationDisclosure && (
              <>
                <a
                  href={partnerData?.solicitationDisclosure}
                  onClick={() =>
                    trackEvent({
                      eventName: TrackEventName.SolicitationDisclosureOpened,
                    })
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  Solicitor Disclosure
                </a>
                ,{' '}
              </>
            )}
            <a
              href={TreasureFiLinks.BankAccountAgreement}
              onClick={() =>
                trackEvent({
                  eventName: TrackEventName.BankAccountAgreementOpened,
                })
              }
              rel="noreferrer"
              target="_blank"
            >
              Grasshopper Bank N.A. Account Agreement
            </a>
            ,{' '}
            <a
              href={TreasureFiLinks.PrivacyPolicy}
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href={TreasureFiLinks.TermsOfUse}
              rel="noreferrer"
              target="_blank"
            >
              Terms of Use
            </a>
            .
          </>
        }
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <CheckboxController
          {...formFields.accountAgreements}
          control={control}
          defaultValue={savedAccountAgreements || false}
        />

        <Box mt={3} mb={0.5}>
          <Typography color="nero" variant={TypographyVariant.LargerLabel}>
            Registered business address
          </Typography>
        </Box>

        <FormFieldContainer>
          <AutocompleteController
            {...formFields.businessCountry}
            control={control}
            defaultValue={
              savedOnboardingData.business?.businessCountry ||
              unitedStatesCountryValue
            }
            onAfterChange={isBusinessCountryUS}
          />
        </FormFieldContainer>

        <AddressFormSection
          address={savedBusinessAddress}
          control={control}
          isUS={isUS}
          variant={AddressFormSectionVariant.Business}
        />

        <Box mt={3}>
          <FormFieldContainer>
            <TextFieldController
              {...formFields.businessPhoneNumber}
              control={control}
              defaultValue={savedOnboardingData.business?.phoneNumber}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <AutocompleteController
              {...formFields.businessTypeId}
              control={control}
              defaultValue={savedOnboardingData.business?.businessTypeId}
              options={getOptions(legalEntityTypes)}
            />
          </FormFieldContainer>
        </Box>

        <SubmitButton />
      </form>
    </>
  );
};
