import React, { useState } from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Button, Typography, TypographyVariant } from 'components/core';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Toast } from 'components/layouts';
import { useSendOnboardingEmail } from 'modules/sign-up/hooks/use-send-onboarding-email';
import { Styled } from './sign-up.style';

interface ResendConfirmationEmailProps {
  businessId: string;
  eventName?: string;
  children: React.ReactChild;
}

export const ResendConfirmationEmail: React.FC<ResendConfirmationEmailProps> =
  ({ businessId, eventName, children }) => {
    const [confirmationText, setConfirmationText] = useState('');
    const [hasSentEmail, setHasSentEmail] = useState(false);
    const { trackEvent } = useTracking();
    const sendOnboardingEmail = useSendOnboardingEmail();

    const handleClick = async () => {
      // Prevent the user from sending multiple emails
      if (!hasSentEmail) {
        setConfirmationText('');

        if (eventName) trackEvent({ eventName });

        sendOnboardingEmail.mutate(
          { businessId },
          {
            onError: (error) => {
              Toast.showError(error);
            },
            onSuccess: () => {
              setConfirmationText('Success! Please check your email.');
              setHasSentEmail(true);
            },
          },
        );
      }
    };
    return (
      <Styled.ConfirmationContainer>
        {children}
        <Button disabled={hasSentEmail} onClick={handleClick}>
          <Typography color="white" variant={TypographyVariant.Body}>
            Resend Email
          </Typography>
        </Button>

        {confirmationText ? (
          <Styled.ConfirmationText>{confirmationText}</Styled.ConfirmationText>
        ) : (
          ''
        )}

        <Box mt={3}>
          Have an account?{' '}
          <Link
            to={RoutesPath.noAuth.signIn.path}
            onClick={() => {
              trackEvent({
                eventName: TrackEventName.ResendEmailSignInClicked,
              });
            }}
          >
            Sign in
          </Link>
        </Box>
      </Styled.ConfirmationContainer>
    );
  };

ResendConfirmationEmail.propTypes = {
  businessId: PropTypes.string.isRequired,
  eventName: PropTypes.string,
  children: PropTypes.any,
};
