import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { useMultipleAccountContext } from 'components/layouts/contexts/multiple-account';
import { RoutesPath } from 'routes/constants/routes-path';
import { TrackEventName } from 'modules/tracking';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { UserPermissionsProhibited } from 'modules/2023-q4/rbac/components/user-permissions-prohibited/user-permissions-prohibited';
import { UserPermissionsRequired } from 'modules/2023-q4/rbac/components/user-permissions-required/user-permissions-required';
import { SettingsNavLink } from '../settings-nav-link/settings-nav-link';
import { Styled } from './settings-nav.style';

export const SettingsNav: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const multipleAccountContext = useMultipleAccountContext();

  // TODO: remove this when the feature is no longer gated
  const { data: business } = useBusiness();
  const showQuickbooks =
    isFeatureFlagEnabled(FeatureFlags.REACT_APP_QUICKBOOKS_SYNC_ENABLED) &&
    business?.Properties?.quickbooksEnabled;

  // currently, Trade Restrictions is not on mobile
  const settingsWithoutTradeRestrictions = (
    <>
      <Box mb={3}>
        <Typography variant={TypographyVariant.HeaderRegular}>
          Settings
        </Typography>

        <hr />
      </Box>

      <SettingsNavLink
        eventName={TrackEventName.SettingsAccountClicked}
        to={RoutesPath.pages.settings.path}
      >
        Account
      </SettingsNavLink>

      <SettingsNavLink
        eventName={TrackEventName.SettingsPasswordClicked}
        to={RoutesPath.pages.settings.pages.updatePassword.path}
      >
        Password
      </SettingsNavLink>

      {multipleAccountContext.hasMultipleAccounts && (
        <SettingsNavLink
          eventName={TrackEventName.SettingsBusinessesClicked}
          to={RoutesPath.pages.settings.pages.businesses.path}
        >
          Businesses
        </SettingsNavLink>
      )}

      <UserPermissionsRequired roles={[UserRole.ADMIN]}>
        <UserPermissionsProhibited roles={[UserRole.WHITE_LABEL]}>
          <SettingsNavLink
            eventName={TrackEventName.SettingsTeamClicked}
            to={RoutesPath.pages.settings.pages.team.path}
          >
            Team
          </SettingsNavLink>
        </UserPermissionsProhibited>
      </UserPermissionsRequired>

      {showQuickbooks && (
        <SettingsNavLink
          eventName={TrackEventName.SettingsQuickbooksClicked}
          to={RoutesPath.pages.settings.pages.quickbooks.path}
        >
          QuickBooks
        </SettingsNavLink>
      )}
    </>
  );

  return (
    <>
      {/* mobile */}
      <Hidden smUp>
        <Box p={3}>{settingsWithoutTradeRestrictions}</Box>
      </Hidden>

      <Hidden xsDown>
        <Styled.SettingsSidebar item xs={2}>
          <Box p={3}>
            {settingsWithoutTradeRestrictions}

            <SettingsNavLink
              eventName={TrackEventName.SettingsTradeRestrictionsClicked}
              to={RoutesPath.pages.settings.pages.tradeRestrictions.path}
            >
              Trade Restrictions
            </SettingsNavLink>
          </Box>
        </Styled.SettingsSidebar>
      </Hidden>
    </>
  );
};
