import styled from 'styled-components';
import {
  TextField as TextFieldAlias,
  TextFieldProps,
  IconButton as IconButtonAlias,
} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';

const TextField = styled(TextFieldAlias)<TextFieldProps>`
  .MuiInputBase-root {
    background-color: var(--color-white);
    border-radius: var(--border-radius-02);
    font-size: var(--font-size-body-02);
    font-weight: var(--font-weight-regular);

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--color-lightGrey);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--color-lightGrey);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--color-lightGrey);
    }
  }

  .MuiOutlinedInput-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--color-mischka2);
  }
`;

const EyeIconButton = styled(IconButtonAlias)`
  &:hover {
    background-color: transparent;
  }
  padding-right: 0;
`;

const EyeIconVisibility = styled(Visibility)`
  color: var(--color-silver);
  font-size: 12px;
`;

export const Styled = {
  TextField,
  EyeIconButton,
  EyeIconVisibility,
};
