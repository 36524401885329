import React, { useEffect } from 'react';
import { WhiteLabelPartners } from 'modules/2023-q4/white-label/consts/white-label-partners';
import { useLocation, useParams } from 'react-router-dom';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';
import { RoutesPath } from 'routes/constants/routes-path';

export const WhiteLabelPartnerLanding = () => {
  const location = useLocation();
  const { partnerId } = useParams<{ partnerId: string }>();
  const { setPartnerId } = useStoreWhiteLabelPartner((state) => state);

  // using window.location instead of react-router-dom history in order to reload CssVariables

  useEffect(() => {
    if (WhiteLabelPartners.find((partner) => partner.id === partnerId)) {
      setPartnerId(partnerId);

      if (location.pathname.endsWith(RoutesPath.noAuth.signUp.path)) {
        window.location.href = RoutesPath.noAuth.signUp.path;
      } else {
        window.location.href = RoutesPath.pages.dashboard.path;
      }
    } else {
      window.location.href = RoutesPath.notFound.path;
    }
  }, [partnerId, setPartnerId]);

  return null;
};
