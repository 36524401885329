import React from 'react';
import { Box } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Spinner,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Styled } from './estimated-arrival-box.style';

interface EstimatedArrivalBoxProps {
  amount: number;
  title: string;
}

export const EstimatedArrivalBox: React.FC<EstimatedArrivalBoxProps> = ({
  amount,
  title,
}) => {
  return (
    <Styled.Container p={2}>
      <Box mb={1}>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {title}
        </Typography>
      </Box>

      <Typography color="green2" variant={TypographyVariant.Paragraph2}>
        <Currency number={amount} variant={CurrencyVariant.Full} />
      </Typography>
    </Styled.Container>
  );
};
