import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';

interface RememberDeviceProps {
  setRememberDevice: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RememberDevice: React.FC<RememberDeviceProps> = ({
  setRememberDevice,
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberDevice(event.target.checked);
  };

  return (
    <>
      <Box display="inline-block" mr={1} position="relative" top={2}>
        <input
          id="remember_this_device"
          type="checkbox"
          onChange={handleOnChange}
        />
      </Box>

      <label htmlFor="remember_this_device">
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Remember this device
        </Typography>
      </label>
    </>
  );
};
