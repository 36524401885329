import styled, { css } from 'styled-components';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface StyledHelpIcon {
  open?: boolean;
}

const HelpIcon = styled(HelpOutlineIcon)<StyledHelpIcon>`
  font-size: 12px;
  vertical-align: top;
  cursor: pointer;

  ${(props) =>
    props.open &&
    css`
      color: var(--color-hanPurple);
    `}
`;

const Popover = styled.div`
  > div {
    position: relative;
    margin-left: 20px;
    background-color: var(--color-hanPurple);
    box-shadow: var(--box-shadow-01);
    border-radius: var(--border-radius-02);

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -13px;
      margin-left: -13px;
      margin-top: -13px;
      border-width: 13px;
      border-style: solid;
      border-color: transparent var(--color-hanPurple) transparent transparent;
    }
  }
`;

export const Styled = {
  HelpIcon,
  Popover,
};
