import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Pagination, Typography, TypographyVariant } from 'components/core';
import {
  SectionContainer,
  SectionTitle,
  TimeFilterOption,
  TimeFilters,
  isDateInTimeFilter,
} from 'modules/dashboard/components';
import { ResponsiveScrollContent } from 'modules/dashboard/components/responsive-scroll-content/responsive-scroll-content';
import {
  Transaction,
  TransactionsTable,
} from 'modules/dashboard/treasure-reserve/transactions/components/transactions-table/transactions-table';
import { useTracking, TrackPageSection, TrackView } from 'modules/tracking';

const filterTransactionStatus = (
  transactionStatus: string,
  variant: TransactionsSectionVariant,
) => {
  if (variant === TransactionsSectionVariant.Pending) {
    // TODO: update TransactionStatus enum
    return (
      transactionStatus === 'IN_PROGRESS' || transactionStatus === 'PENDING'
    );
  }

  return transactionStatus !== 'IN_PROGRESS' && transactionStatus !== 'PENDING';
};

const getFilteredTransactionsByTime = (
  transactions: Transaction[],
  timeFilter: TimeFilterOption,
) => {
  return transactions.filter((transaction: Transaction) =>
    isDateInTimeFilter(transaction.date, timeFilter),
  );
};

// starting with a default of six months, find a time filter than has
// at least 1 transaction.
const getStartingTimeFilter = (
  transactions: Transaction[],
  timeFilter: TimeFilterOption,
): TimeFilterOption => {
  const getNextTimeFilterOption = (timeFilter: TimeFilterOption) => {
    switch (timeFilter) {
      case TimeFilterOption.ONE_MONTH:
        return TimeFilterOption.THREE_MONTH;
      case TimeFilterOption.THREE_MONTH:
        return TimeFilterOption.SIX_MONTH;
      case TimeFilterOption.SIX_MONTH:
        return TimeFilterOption.TWELVE_MONTH;
      case TimeFilterOption.TWELVE_MONTH:
        return TimeFilterOption.ALL;
      default:
        return TimeFilterOption.SIX_MONTH;
    }
  };

  const nextTimeFilterOption = getNextTimeFilterOption(timeFilter);

  const filteredTranscations = getFilteredTransactionsByTime(
    transactions,
    timeFilter,
  );

  if (filteredTranscations.length > 0) {
    return timeFilter;
  }

  return nextTimeFilterOption === TimeFilterOption.SIX_MONTH
    ? TimeFilterOption.SIX_MONTH
    : getStartingTimeFilter(transactions, nextTimeFilterOption);
};

const paginationSize = 15;

export enum TransactionsSectionVariant {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

interface TransactionsSectionProps {
  transactions: Transaction[];

  variant: TransactionsSectionVariant;
}

export const TransactionsSection: React.FC<TransactionsSectionProps> = ({
  transactions,
  variant,
}) => {
  const { Track } = useTracking<{
    section: TrackPageSection;
    sectionVariant: TransactionsSectionVariant;
  }>({
    section: TrackPageSection.TreasureReserveDashboardTransactions,
    sectionVariant: variant,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [timeFilter, setTimeFilter] = useState<TimeFilterOption>(
    TimeFilterOption.ALL,
  );

  const variantFilteredTransactions = transactions.filter(
    (transaction: Transaction) =>
      filterTransactionStatus(transaction.status, variant),
  );

  const timeFilteredTransactions = getFilteredTransactionsByTime(
    variantFilteredTransactions,
    timeFilter,
  );

  const paginatedTransactions = timeFilteredTransactions
    ?.sort(
      (a: Transaction, b: Transaction) =>
        Number(new Date(b.date)) - Number(new Date(a.date)),
    )
    .slice((currentPage - 1) * paginationSize, currentPage * paginationSize);

  let title = 'Historical Transactions';

  if (variant === TransactionsSectionVariant.Pending) {
    title = 'Pending Transactions';
  }

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [timeFilter]);

  if (variantFilteredTransactions.length < 1) {
    return null;
  }

  return (
    <Track>
      <Box mb={variant === TransactionsSectionVariant.Pending ? 7 : 0}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <SectionTitle title={title} />
            </Grid>

            <Grid item xs={12} sm>
              <Box textAlign="right">
                {variant === TransactionsSectionVariant.Completed && (
                  <TimeFilters
                    defaultTimeFilter={getStartingTimeFilter(
                      variantFilteredTransactions,
                      TimeFilterOption.SIX_MONTH,
                    )}
                    setTimeFilter={setTimeFilter}
                    timeFilterOptions={[
                      TimeFilterOption.ALL,
                      TimeFilterOption.TWELVE_MONTH,
                      TimeFilterOption.SIX_MONTH,
                      TimeFilterOption.THREE_MONTH,
                      TimeFilterOption.ONE_MONTH,
                    ]}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <SectionContainer>
          <TrackView />

          <ResponsiveScrollContent widthToSubtract={66}>
            <TransactionsTable data={paginatedTransactions} />
          </ResponsiveScrollContent>

          {!paginatedTransactions.length && (
            <Box p={10} textAlign="center">
              No Transactions
            </Box>
          )}
        </SectionContainer>

        {timeFilteredTransactions.length > paginatedTransactions.length && (
          <Box mt={3}>
            <Pagination
              count={Math.ceil(
                timeFilteredTransactions.length / paginationSize,
              )}
              onChange={handlePaginationChange}
              page={currentPage}
            />

            {/** TODO: this should be added to the pagination component **/}
            <Box textAlign="center" mt={2}>
              <Typography color="grey4" variant={TypographyVariant.SubLabel}>
                {paginationSize * currentPage - paginationSize + 1} -{' '}
                {Math.min(
                  paginationSize * currentPage,
                  timeFilteredTransactions.length,
                )}{' '}
                of {timeFilteredTransactions.length} transactions
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Track>
  );
};
