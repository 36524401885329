import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useAddRestrictedCusip = (): UseMutationResult<any, any, any> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const addRestrictedCusip = async ({
    businessId,
    cusip,
  }: {
    businessId: string;
    cusip: string;
  }) => {
    const { data } = await treasureApi().post(
      `/business/${businessId}/reserve-account/restricted-cusips`,
      {
        cusip,
      },
    );

    return data;
  };

  return useMutation(addRestrictedCusip, {
    onSuccess: () => {
      return queryClient.invalidateQueries([ReactQueryKeys.RestrictedCusips]);
    },
  });
};
