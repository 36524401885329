import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Text } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { useForm } from 'react-hook-form';
import {
  PasswordFieldController,
  PasswordCriteriaCheck,
} from 'components/core/forms';
import { reauthenticate } from '../api/reauthenticate-firebase';
import { useUpdatePassword } from '../hooks/use-update-password';
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';
import { useTracking, TrackEventName } from 'modules/tracking';

interface IUpdatePasswordForm {
  currentPassword: string;
  newPassword: string;
}

interface UpdatePasswordFormProps {
  onUpdate: () => void;
}

export const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = ({
  onUpdate,
}) => {
  const { updatePassword } = useUpdatePassword();
  const { data: business } = useBusiness();
  const { data: user } = useUser(business?.Id);
  const { control, handleSubmit, watch } = useForm<IUpdatePasswordForm>();
  const { trackEvent } = useTracking();

  const onSubmit = async (data: IUpdatePasswordForm) => {
    const result = await reauthenticate(data.currentPassword);

    if (result) {
      try {
        await updatePassword({ userId: user.Id, password: data.newPassword });

        trackEvent({
          eventName: TrackEventName.PasswordUpdatedSucceeded,
        });

        onUpdate();
      } catch {
        trackEvent({
          eventName: TrackEventName.PasswordUpdatedFailed,
        });
      }
    } else {
      trackEvent({
        eventName: TrackEventName.PasswordUpdatedFailed,
      });
    }
  };

  const password = watch('newPassword', '');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={3}>
        <PasswordFieldController
          control={control}
          label="Current Password"
          name="currentPassword"
        />
      </Box>

      <Box mb={3}>
        <PasswordFieldController
          control={control}
          label="New Password"
          name="newPassword"
        />
      </Box>

      <Box mb={3}>
        <PasswordCriteriaCheck password={password} />
      </Box>

      <Box mt={3}>
        <Button type="submit">
          <ButtonContent>
            <Text color="white" variant={3}>
              Update password
            </Text>
          </ButtonContent>
        </Button>
      </Box>
    </form>
  );
};

UpdatePasswordForm.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};
