import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { ReactQueryMutationKeys } from 'enums';

export const useConfirmAPIUserMFACode = (): UseMutationResult<
  any,
  any,
  any
> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const confirmAPIUserMFACode = async ({
    email,
    mfaCode,
  }: {
    email: string;
    mfaCode: string;
  }) => {
    const { data } = await treasureApi().post(
      '/internal/mfa/validate/api-customer',
      {
        code: mfaCode,
        email,
      },
    );

    return data;
  };

  return useMutation(confirmAPIUserMFACode, {
    mutationKey: ReactQueryMutationKeys.ConfirmMFACode, // this mutation key is used by react-query-error-toasts so it will never show a user an error
  });
};
