import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { FormFieldContainer } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { Checkbox } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/checkbox';
import { Styled } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/checkbox-controller/checkbox-controller.style';

interface Props {
  setShowBusinessOwners: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;

  showBusinessOwners?: boolean;
}

export const BusinessOwnershipQuestion: React.FC<Props> = ({
  setShowBusinessOwners,
  showBusinessOwners,
}) => {
  const [value, setValue] = useState<boolean | undefined>(showBusinessOwners);

  const onChange = (boolean: boolean) => {
    setValue(boolean);
    setShowBusinessOwners(boolean);
  };

  return (
    <>
      <Typography color="nero" variant={TypographyVariant.LargerLabel}>
        Does any single person own 10% or more of the company or controlling
        entity?
      </Typography>

      <Grid container>
        <Grid item>
          <FormFieldContainer>
            <Styled.StyledFormLabel
              checked={value === true}
              control={<Checkbox name="businessOwnership" />}
              onChange={() => onChange(true)}
              label={
                <Typography
                  color="nero"
                  variant={TypographyVariant.LargerLabel}
                >
                  Yes
                </Typography>
              }
            />
          </FormFieldContainer>
        </Grid>

        <Grid item>
          <Box ml={5}>
            <FormFieldContainer>
              <Styled.StyledFormLabel
                checked={value === false}
                control={<Checkbox name="businessOwnership" />}
                onChange={() => onChange(false)}
                label={
                  <Typography
                    color="nero"
                    variant={TypographyVariant.LargerLabel}
                  >
                    No
                  </Typography>
                }
              />
            </FormFieldContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
