import React from 'react';
import { Withdrawal as V1SweepWithdrawal } from './components/v1-custodian/withdrawal';
import { Withdrawal as V2SweepWithdrawal } from './components/v2-liquidation/withdrawal';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

export const Withdrawal = () => {
  return (
    <FeatureFlag
      disabled={<V1SweepWithdrawal />}
      enabled={<V2SweepWithdrawal />}
      flag={FeatureFlags.REACT_APP_TRANSFER_LIQUIDITY_WITHDRAWAL_ENABLED}
    />
  );
};
