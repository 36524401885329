import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { useMutateOnboarding } from 'modules/onboarding/v3-streamlined-onboarding/hooks/mutate-onboarding';
import { Box } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { PageTitle } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/page-title';
import { PersonFormSection } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/sections/person/person-form-section';
import { Person } from 'modules/onboarding/v3-streamlined-onboarding/types/person';
import { PersonVariant } from 'modules/onboarding/v3-streamlined-onboarding/enums';
import {
  formattedDateOfBirth,
  formattedNationalIdentifier,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/utils';
import { numberRegex } from 'components/core/forms/password/password-validations';
import { useTracking, TrackEventName } from 'modules/tracking';

interface PersonModalProps {
  onClose: () => void;

  person: Person | undefined;

  resetMinimumError: (variant: PersonVariant) => void;

  variant: PersonVariant;
}

export const PersonModal: React.FC<PersonModalProps> = ({
  onClose,
  person,
  resetMinimumError,
  variant,
}) => {
  // because there is a slight animation on modal close
  // the user sees a flash of the form after submission
  // workaround: set loading to true and only remove it
  // if there is an error from submission.
  // we could remove isLoading altogether if we wish
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();

  const isNewPerson = person === undefined;
  const variantText =
    variant === PersonVariant.Owner ? ' business owner' : ' business officer';
  const modalTitle = (isNewPerson ? 'Add' : 'Edit') + variantText;
  const modalButton = (isNewPerson ? 'Add' : 'Save') + variantText;

  const onboardingContext = useOnboardingContext();

  const { control, handleSubmit, register, setError, unregister } =
    useForm<Person>({
      mode: 'all',
    });

  if (
    !isNewPerson &&
    variant === PersonVariant.Owner &&
    person.ownershipPercentage === null
  ) {
    register('ownershipPercentage', { required: true });
    setError('ownershipPercentage', {
      type: 'custom',
      message: 'Ownership percentage is required',
    });
  }

  const { isError, isLoading, mutate } = useMutateOnboarding(
    onboardingContext.businessId,
    onClose,
  );

  const onSubmit = (data: Person) => {
    const {
      address1,
      address2,
      city,
      state,
      zipCode,
      dateOfBirth,
      nationalIdentifier,
      phoneNumber,
      ...rest
    } = data;

    const peopleData = {
      ...rest,
      dateOfBirth: formattedDateOfBirth(dateOfBirth),
      isBusinessOwner:
        person?.isBusinessOwner || variant === PersonVariant.Owner,
      isEntityOfficer:
        person?.isEntityOfficer || variant === PersonVariant.Officer,
      nationalIdentifier: formattedNationalIdentifier(nationalIdentifier),
      phoneNumber: phoneNumber
        ? phoneNumber.match(numberRegex)?.join('')
        : undefined,
      addresses: [
        {
          address1,
          address2,
          city,
          state,
          zipCode,
          id: person?.addresses?.[0]?.id,
        },
      ],
      id: person?.id,
    };

    setLoading(true);

    mutate({
      businessId: onboardingContext.businessId,
      data: {
        people: [peopleData],
      },
    });

    resetMinimumError(variant);

    if (isNewPerson) {
      trackEvent({
        eventName:
          variant === PersonVariant.Owner
            ? TrackEventName.AdditionalUBOAdded
            : TrackEventName.AdditionalEOAdded,
      });
    } else {
      trackEvent({
        eventName: TrackEventName.ContinueClickedEditPersonDetail,
      });
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [isError]);

  return (
    <Box px={3} py={2}>
      <PageTitle title={modalTitle} />

      {isLoading || loading ? (
        <Box py={5}>
          <Spinner />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <PersonFormSection
            control={control}
            person={person}
            unregister={unregister}
            variant={variant}
          />

          <Box mt={5} mb={1.5}>
            <Button type="submit" fullWidth>
              <ButtonContent>
                <Typography color="white" variant={TypographyVariant.Body}>
                  {modalButton}
                </Typography>
              </ButtonContent>
            </Button>
          </Box>

          <Box textAlign="center">
            <Button
              bgColor="white"
              bgHoverColor="white"
              onClick={() => onClose()}
            >
              <Typography
                color="black19"
                variant={TypographyVariant.Paragraph2}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};
