import React from 'react';
import { i18n, useTranslation } from 'app/i18n';
import { useBusiness } from 'hooks/business/use-business';
import { useCashAccount } from 'hooks/portal-gateway/use-cash-account';
import { useQueryOnboarding } from 'modules/onboarding/v3-streamlined-onboarding/hooks/query-onboarding';
import { Box } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { InstructionRow } from './components/instruction-row/instruction-row';
import WireInstructionsContent from './content/wire-instructions.content.json';

const i18nNamespace = 'WireInstructions';
i18n.addResourceBundle('en', i18nNamespace, WireInstructionsContent);

export interface WireTransfer {
  label: string;
  value1: string;
  value2?: string;
  helperText?: string;
}

interface WireInstructionsProps {
  previousStep: () => void;
}

export const WireInstructions: React.FC<WireInstructionsProps> = ({
  previousStep,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: onboardingData, isLoading: isLoadingOnboarding } =
    useQueryOnboarding(business?.Id);
  const { data: cashAccount, isLoading: isLoadingCashAccount } = useCashAccount(
    business?.Id,
  );

  const WireTransferData: WireTransfer[] = [
    {
      label: 'Name of Bank',
      value1: 'Grasshopper Bank, N.A.',
      helperText: 'Grasshopper  Bank, N.A. is Treasure’s partner bank',
    },
    {
      label: 'Bank Address',
      value1: '261 5th Ave, Suite 610',
      value2: 'New York, NY 10016',
      helperText: 'Prepopulated address does not have to match this',
    },
    {
      label: 'Routing Number',
      value1: `${cashAccount?.wireRoutingNumber} `,
      helperText:
        'Some banks pre-populate routing number, you may not have to enter it',
    },
    {
      label: 'Account Number',
      value1: `${cashAccount?.accountNumber} `,
    },
    {
      label: 'Beneficiary Name',
      value1: `${business?.LegalName}`,
    },
    {
      label: 'Beneficiary Address',
      value1: `${onboardingData?.data.business.addresses[0].address1}${
        onboardingData?.data.business.addresses[0].address2
          ? ' ' + onboardingData?.data.business.addresses[0].address2
          : ''
      }`,
      value2: `${onboardingData?.data.business.addresses[0].city} ${onboardingData?.data.business.addresses[0].zipCode} `,
    },
    {
      label: 'Originator to Beneficiary',
      value1: 'Treasure',
    },
  ];

  const PreviousButton = ({ mobile }: { mobile?: boolean }) => {
    return (
      <Box mt={mobile ? 10 : undefined}>
        <Button
          borderRadius={mobile ? 0 : undefined}
          bottomFixed={mobile}
          fullWidth
          onClick={previousStep}
        >
          <ButtonContent iconStart={SupportedIcons.ArrowLeft} mobile={mobile}>
            {t('Previous', { ns: 'app' })}
          </ButtonContent>
        </Button>
      </Box>
    );
  };

  return (
    <FormContainer>
      <Box mb={3}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          {t('Wire Instructions')}
        </Typography>
      </Box>

      {isLoadingBusiness || isLoadingOnboarding || isLoadingCashAccount ? (
        <Box m={10}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Box mb={3}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              {t('Please use the following recipient information')}
            </Typography>
          </Box>

          {WireTransferData.map((row) => (
            <React.Fragment key={row.label}>
              <Box mb={2}>
                <InstructionRow data={row} />
              </Box>
            </React.Fragment>
          ))}
        </>
      )}

      <MobileView mobile={<PreviousButton mobile />}>
        <Box mt={4}>
          <PreviousButton />
        </Box>
      </MobileView>
    </FormContainer>
  );
};
