import React, { useEffect } from 'react';
import { CashDirection } from 'enums';
import { Box, Hidden } from '@material-ui/core';
import { Text } from 'components/core';

// layout
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { DashboardContainer } from 'layouts/v3-portal-layout/components';

// charts
import { BarChartByMonth, CashFlowTrends } from 'components/charts/v3';
import { CashFlowTrendsLegend } from 'components/charts/v3/cash-flow-trends/cash-flow-trends-legend';

// dashboard
import {
  DashboardTopIndicators,
  GridCard,
  GridCardContainer,
} from 'components/dashboard';

import {
  Activity,
  ConnectBankAccountsCard,
  MonthToDateCard,
} from 'components/dashboard/v3';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { useCashSnapshot } from 'hooks/banking/use-cash-snapshot';
import { useChartData } from 'hooks/banking/use-chart-data';
import { useDashboardTransactions } from 'hooks/banking/use-dashboard-transactions';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useTopIndicators } from 'hooks/banking/use-top-indicators';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

import { DashboardActivity } from 'modules/transactions/dashboard-activity/dashboard-activity';
import { DashboardActivity as BankingDashboardActivity } from 'modules/transactions/dashboard-activity/banking/dashboard-activity';

export const Insights: React.FC = () => {
  const loadingContext = useLoadingContext();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();

  const { data: accounts, isLoading: isLoadingBankAccounts } = useBankAccounts(
    business && business.Id,
  );

  const { data: cashSnapshot, isLoading: isLoadingCashSnapshot } =
    useCashSnapshot();

  const { data: chartData, isLoading: isLoadingChartData } =
    useChartData('revenue');

  const {
    data: dashboardTransactions,
    isLoading: isLoadingDashboardTransactions,
  } = useDashboardTransactions();

  const { data: portfolio, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );

  const { data: topIndicators, isLoading: isLoadingTopIndicators } =
    useTopIndicators(business && business.Id);

  const plaidDataReady =
    accounts &&
    accounts.every(
      (account: { PlaidDataReady: boolean }) => account.PlaidDataReady === true,
    );

  useEffect(() => {
    loadingContext.setLoading(
      isLoadingBankAccounts ||
        isLoadingBusiness ||
        isLoadingCashSnapshot ||
        isLoadingChartData ||
        isLoadingDashboardTransactions ||
        isLoadingPortfolios ||
        isLoadingTopIndicators,
    );
  }, [
    isLoadingBankAccounts,
    isLoadingBusiness,
    isLoadingCashSnapshot,
    isLoadingChartData,
    isLoadingDashboardTransactions,
    isLoadingPortfolios,
    isLoadingTopIndicators,
    loadingContext,
  ]);

  return (
    <>
      {/* should be a variable height which is the height of the header */}
      {!loadingContext.loading && (
        <Hidden smDown>
          <Box mb={5}>
            {plaidDataReady && (
              <DashboardTopIndicators
                plaidDataReady={plaidDataReady}
                indicators={topIndicators}
                totalBalance={portfolio?.amountsInDollars?.totalAmount}
              />
            )}
          </Box>
        </Hidden>
      )}

      {cashSnapshot && (
        <DashboardContainer>
          <GridCardContainer>
            <GridCard xs={12} sm={6}>
              <ConnectBankAccountsCard />
            </GridCard>

            <GridCard
              title="Cash Flow Trends"
              xs={12}
              sm={6}
              headerContent={<CashFlowTrendsLegend />}
            >
              {chartData && <CashFlowTrends data={chartData} />}
            </GridCard>
          </GridCardContainer>

          <GridCardContainer>
            <GridCard xs={12} sm={6}>
              {cashSnapshot.income && (
                <MonthToDateCard
                  data={cashSnapshot.income}
                  variant={CashDirection.Inflows}
                />
              )}
            </GridCard>

            <GridCard title="Inflows" xs={12} sm={6}>
              {cashSnapshot.income?.pastYear && (
                <>
                  <Box ml={2}>
                    <Text variant={1}>Past 12 Months</Text>
                  </Box>
                  <BarChartByMonth
                    data={cashSnapshot.income.pastYear}
                    variant={CashDirection.Inflows}
                  />
                </>
              )}
            </GridCard>
          </GridCardContainer>

          <GridCardContainer>
            <GridCard xs={12} sm={6}>
              {cashSnapshot.expenses && (
                <MonthToDateCard
                  data={cashSnapshot.expenses}
                  variant={CashDirection.Outflows}
                />
              )}
            </GridCard>

            <GridCard title="Outflows" xs={12} sm={6}>
              {cashSnapshot.expenses?.pastYear && (
                <>
                  <Box ml={2}>
                    <Text variant={1}>Past 12 Months</Text>
                  </Box>
                  <BarChartByMonth
                    data={cashSnapshot.expenses.pastYear}
                    variant={CashDirection.Outflows}
                  />
                </>
              )}
            </GridCard>
          </GridCardContainer>

          <GridCardContainer>
            <GridCard title="Recent Transactions" xs={12} sm={6}>
              {dashboardTransactions &&
                dashboardTransactions.totals !== undefined && (
                  <Activity data={dashboardTransactions} />
                )}
            </GridCard>

            <GridCard title="Transfer Activity" xs={12} sm={6}>
              <FeatureFlag
                disabled={<BankingDashboardActivity />}
                enabled={<DashboardActivity />}
                flag={
                  FeatureFlags.REACT_APP_GATEWAY_ACTIVITIES_ENDPOINT_ENABLED
                }
              />
            </GridCard>
          </GridCardContainer>
        </DashboardContainer>
      )}
    </>
  );
};
