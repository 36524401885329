import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { colors, getColor } from 'styles/css-constants';

interface ProgressSpinnerProps {
  spinnercolor?: string;
}

const ProgressSpinner = styled(CircularProgress)<ProgressSpinnerProps>`
  &.MuiCircularProgress-colorPrimary {
    color: ${getColor('primary')};

    ${(props) => {
      if (props.spinnercolor) {
        return css`
          color: ${getColor(props.spinnercolor as keyof typeof colors)};
        `;
      }

      return null;
    }};
`;

export const Styled = {
  ProgressSpinner,
};
