import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useBankAccounts = (businessId: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  return useQuery(
    [ReactQueryKeys.BankAccounts, businessId],
    async () => {
      const response = await treasureApi().get('/accounts/dashboard', {
        params: { id: businessId },
      });

      // TODO: BFF
      response.data.forEach(
        (acc: {
          balance?: number;
          available?: number;
          mask?: string;
          Balance: string;
          Available: string;
          Mask: string;
        }) => {
          // parse values
          acc.balance = Number(acc.balance) || Number(acc.Balance) || 0;
          acc.available = Number(acc.available) || Number(acc.Available) || 0;
          acc.mask = acc.mask || acc.Mask;
        },
      );

      return response.data;
    },
    {
      enabled: !!businessId,
    },
  );
};
