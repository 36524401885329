import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useTreasureReservePositions = (
  businessId: string,
): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery(
    [ReactQueryKeys.TreasureReservePositions, businessId],
    async () => {
      const response = await treasureApi().get(
        `/business/${businessId}/reserve-account/positions`,
      );

      return response.data;
    },
    {
      enabled: !!businessId,
    },
  );
};
