import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { InviteUserForm } from 'modules/multi-user/components/invite-user-form/invite-user-form';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';
import { TreasureFiEmails } from 'enums';

export const InviteUser: React.FC = () => {
  return (
    <>
      <Box mb={3}>
        <Typography variant={TypographyVariant.SubHeader3}>
          Invite additional team members to access this account, request
          transfers, and view cash flow insights. These users will receive an
          invitation to their email to setup their access.{' '}
          <strong>
            These additional users will have the same access permissions as this
            account.
          </strong>{' '}
          After inviting a user, to change a team member from Administrator to
          Read Only, please{' '}
          <ContactTreasureSupportLink subject="Team Member Read Only Request">
            {TreasureFiEmails.Support}
          </ContactTreasureSupportLink>
          .
        </Typography>
      </Box>

      <InviteUserForm />
    </>
  );
};
