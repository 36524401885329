import { getColor, getFontProperties } from 'styles/css-constants';

export const CartesianGridStroke = getColor('gainsboro0');

export const ChartHeight = 200;

export const TickStyle = {
  fill: getColor('gothic'),
  fontFamily: getFontProperties.fontFamily,
  fontSize: getFontProperties.getBodyProperties(0).fontSize,
  fontWeight: getFontProperties.getBodyProperties(0).fontWeight,
};
