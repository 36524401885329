import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Popover, TypographyVariant } from 'components/core';
import { BankAccount } from 'types';
import { BankAccountPropTypes } from 'types/prop-types';
import { useTracking, TrackEventName } from 'modules/tracking';
import { useOnboardingTransferContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { useConfirmMFACode } from 'hooks/custodian/mfa/use-confirm-mfa-code';
import { useSendMFACode } from 'hooks/custodian/mfa/use-send-mfa-code';
import { Styled } from './mfa.style';

export interface MFAProps {
  /** account */
  bankAccount: BankAccount;

  /** transfer amount */
  amount: number;

  customSendMFA?: () => void;
  customConfirmMFA?: () => void;
  onMFASuccess: (token?: string) => void;
}

const MFA: React.FC<MFAProps> = ({
  bankAccount,
  amount,
  customSendMFA,
  customConfirmMFA,
  onMFASuccess,
}) => {
  const { trackEvent } = useTracking();
  const { mutate: confirmMFACode } = useConfirmMFACode();
  const { mutate: sendMFACode } = useSendMFACode();
  const resetCode = ['', '', '', '', '', ''];
  const [mfaState, setMfaState] = useState({
    code: resetCode,
    error: false,
  });
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const transferContext = useOnboardingTransferContext();

  // const refs = [...Array(6)].map(r => useRef(null));
  // React Hook "useRef" cannot be called inside a callback. React Hooks must be called in a React function component or a custom React Hook function  react-hooks/rules-of-hooks
  const refs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const handleChange = (
    event: React.FormEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { code } = mfaState;
    code[index] = (event.target as HTMLInputElement).value;
    setMfaState({ code, error: false });

    if (refs[index + 1] !== undefined) {
      refs[index + 1].current?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent, index: number) => {
    const { code } = mfaState;
    let focusPosition = 0;
    let paste = event.clipboardData.getData('Text');
    // TODO: this should be a variable that's used in the email template to correspond with what the user may copy
    // could also do a search to find if first instance exists in case more text is copied before TR-
    paste = paste.replace('TR-', '');

    for (let i = 0; i + index < 6; i++) {
      const value = paste.charAt(i);

      code[i + index] = paste.charAt(i);

      if (value) {
        focusPosition = i + index + 1;
        focusPosition = focusPosition > 5 ? 5 : focusPosition;
      }
    }

    setMfaState({ code, error: false });

    refs[focusPosition].current?.focus();

    event.preventDefault();
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const ARROW_LEFT = 37;
    const ARROW_RIGHT = 39;
    const BACKSPACE_KEY = 8;
    const COMMAND = 91;
    const ENTER = 13;
    const SHIFT = 16;
    const TAB = 9;

    if (
      ![ARROW_LEFT, ARROW_RIGHT, COMMAND, ENTER, SHIFT, TAB].includes(
        event.keyCode,
      )
    ) {
      const { code } = mfaState;
      code[index] = '';

      if (
        event.keyCode === BACKSPACE_KEY &&
        !event.currentTarget.value &&
        refs[index - 1] !== undefined
      ) {
        code[index - 1] = '';
      }

      setMfaState({ code, error: false });
    }

    if (event.keyCode === ENTER) {
      handleConfirmCode();
      event.preventDefault();
    }

    if (
      ((event.keyCode === BACKSPACE_KEY && !event.currentTarget.value) ||
        event.keyCode === ARROW_LEFT) &&
      refs[index - 1] !== undefined
    ) {
      refs[index - 1].current?.focus();
      event.preventDefault();
    } else if (event.keyCode === ARROW_RIGHT && refs[index + 1] !== undefined) {
      refs[index + 1].current?.focus();
      event.preventDefault();
    }
  };

  const handleSendMFACode = () => {
    if (transferContext.mfaSent) {
      return;
    }

    if (customSendMFA) {
      customSendMFA();
    } else {
      sendMFACode({});
    }

    transferContext.setMFASent(true);

    trackEvent({
      eventName: TrackEventName.MFAInitiated,
    });
  };

  const handleConfirmCode = async (
    event?: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (customConfirmMFA) {
      await customConfirmMFA();
      await onMFASuccess();
    } else {
      const code = mfaState.code.join('');
      confirmMFACode(
        { mfaCode: code },
        {
          onError: (error) => {
            setMfaState({ code: mfaState.code, error: true });

            trackEvent({
              eventName: TrackEventName.MFAFailed,
              error,
            });
          },
          onSuccess: (response) => {
            if (response && response.token) {
              onMFASuccess(response.token);
            }
          },
        },
      );
    }
  };

  const handleResendCode = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent({
      eventName: TrackEventName.ClickResendCode,
    });

    if (customSendMFA) {
      customSendMFA();
    } else {
      sendMFACode({});
    }

    setAnchorEl(event.currentTarget);
    setShowPopover(true);

    setTimeout(() => {
      setShowPopover(false);
    }, 1500); // TODO: variable on theme?
  };

  // after render, send MFA code
  useEffect(handleSendMFACode, []);

  return (
    <Box>
      <Styled.MFAInputContainer>
        <Box display="inline-block" textAlign="left">
          <Box mb={0.5}>
            <label htmlFor="mfa-code">
              <Styled.CodeLabel
                color="nero"
                variant={TypographyVariant.SubHeader2}
              >
                Enter 6 digit code
              </Styled.CodeLabel>
            </label>
          </Box>

          <Styled.CodeContainer container spacing={1}>
            {[...Array(6)].map(function (item, i) {
              return (
                <Grid item key={i.toString()}>
                  <Styled.CodeInput
                    autoComplete="off"
                    id={i === 0 ? 'mfa-code' : undefined}
                    ref={refs[i]}
                    value={mfaState.code[i]}
                    type="text"
                    minLength={1}
                    maxLength={1}
                    onChange={(event) => handleChange(event, i)}
                    onKeyDown={(event) => handleKeyDown(event, i)}
                    onPaste={(event) => handlePaste(event, i)}
                  />
                </Grid>
              );
            })}
          </Styled.CodeContainer>

          {mfaState.error && (
            <Box mt={1}>
              <Styled.CodeError color="torchRed" variant={5}>
                Sorry, your code is incorrect.
              </Styled.CodeError>
            </Box>
          )}
        </Box>
      </Styled.MFAInputContainer>

      <Styled.MFACTAContainer>
        <Styled.ConfirmButton onClick={handleConfirmCode}>
          Authorize transfer
        </Styled.ConfirmButton>

        <Styled.ResendButton onClick={handleResendCode} variant="outlined">
          Resend code
        </Styled.ResendButton>

        <Popover
          anchorEl={anchorEl}
          open={showPopover}
          text="New code sent to your email."
        />

        <a
          href="mailto:support@treasure.tech?subject=Treasure MFA issue"
          onClick={() => {
            trackEvent({
              eventName: TrackEventName.ClickMFAContactUs,
            });
          }}
        >
          <Styled.HelpLink color="hanPurple" variant={TypographyVariant.Body}>
            Need help? Contact us
          </Styled.HelpLink>
        </a>
      </Styled.MFACTAContainer>
    </Box>
  );
};

MFA.propTypes = {
  bankAccount: BankAccountPropTypes.isRequired,
  amount: PropTypes.number.isRequired,
  customSendMFA: PropTypes.func,
  customConfirmMFA: PropTypes.func,
  onMFASuccess: PropTypes.func.isRequired,
};

export { MFA };
