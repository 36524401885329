import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';

const TooltipContainer = styled(Box)`
  position: absolute;
  right: 0;
`;

export const Styled = {
  TooltipContainer,
};
