import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useSendApiUserMFACode = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const sendApiUserMFACode = async ({ email }: { email: string }) => {
    const { data } = await treasureApi().put(
      '/internal/mfa/generate/api-customer',
      {
        email,
      },
    );

    return data;
  };

  return useMutation(sendApiUserMFACode);
};
