import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Button, Typography, TypographyVariant } from 'components/core';
import { ReactQueryKeys } from 'enums';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useTranslation } from 'app/i18n';
import {
  useOnboardingContext,
  useOnboardingTransferContext,
} from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { TransferWidget } from 'modules/ach/transfer-widget-v2';
import { useConnectedAccounts, useTreasureReserve } from './api';
import { Styled } from './sweep-funds.style';
import { useQueryClient } from 'react-query';

// hooks
import { useAddedInitialSweep } from 'modules/onboarding/v3-streamlined-onboarding/hooks/mutate-added-initial-sweep';
import { useBusiness } from 'hooks/business/use-business';

export const SweepFunds: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.OnboaringSweep,
  });
  const { t } = useTranslation();

  const onboardingContext = useOnboardingContext();
  const transferContext = useOnboardingTransferContext();
  const queryClient = useQueryClient();
  const mutateAddedInitialSweep = useAddedInitialSweep();

  const [loading, setLoading] = useState(false);

  const { data: business } = useBusiness();

  const { data: accounts = [], isLoading: isConnectedAccountsLoading } =
    useConnectedAccounts();
  const { data: treasureReserve, isLoading: isTreasureReserveLoading } =
    useTreasureReserve(business?.Id);

  useEffect(() => {
    setLoading(isConnectedAccountsLoading && isTreasureReserveLoading);
  }, [isConnectedAccountsLoading, isTreasureReserveLoading]);

  const onSkip = () => {
    queryClient.setQueryData(ReactQueryKeys.InitialOnboardingSweep, {});

    mutateAddedInitialSweep.mutate({ addedInitialSweep: false });

    setLoading(true);

    trackEvent({
      eventName: TrackEventName.OnboardingSweepSkipped,
    });
  };

  useEffect(() => {
    onboardingContext.showLoading(loading || !treasureReserve);
  }, [loading, treasureReserve]);

  if (!treasureReserve) {
    return null;
  }

  return (
    <>
      <Box bgcolor="white" position="absolute" right={0} mx={3}>
        <Button variant="outlined" onClick={onSkip}>
          Skip Transfer funds
        </Button>
      </Box>
      <Styled.WidgetContainer bgcolor="white">
        <TransferWidget accounts={accounts} treasureReserve={treasureReserve} />
      </Styled.WidgetContainer>

      <Box mt={3}>
        <Typography variant={TypographyVariant.SmallLabel3}>
          {t('account not opened sweep', { ns: 'compliance' })}
        </Typography>
      </Box>
    </>
  );
};
