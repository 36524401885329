import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Modal,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { Size } from 'enums';
import { MANGED_TREASURIES_MINIMUM } from 'modules/2023-q3/allocation/interface/product-allocation';

export const ManagedTreasuriesMinimumModal = ({
  managedTreasuriesAmount,
  nextStep,
  showModal,
  setShowModal,
}: {
  managedTreasuriesAmount: number;
  nextStep: () => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleClose} size={Size.Small}>
      <Box mt={5} p={5}>
        <Typography color="grey19" variant={TypographyVariant.Paragraph2}>
          Managed Treasuries requires a minimum amount of{' '}
          <Currency
            number={MANGED_TREASURIES_MINIMUM}
            variant={CurrencyVariant.NearestDollar}
          />
          . The{' '}
          <Currency
            number={managedTreasuriesAmount}
            variant={CurrencyVariant.Full}
          />{' '}
          allocated will be added to Managed Money Market.
        </Typography>

        <Box mt={5}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Button
                bgColor="brown6"
                bgHoverColor="brown4b"
                fullWidth
                onClick={handleClose}
              >
                <ButtonContent>
                  <Typography
                    color="black19"
                    variant={TypographyVariant.Paragraph2}
                  >
                    Cancel
                  </Typography>
                </ButtonContent>
              </Button>
            </Grid>

            <Grid item xs>
              <Button fullWidth onClick={nextStep}>
                <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
                  Continue
                </ButtonContent>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
