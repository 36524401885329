import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { BankAccount, TreasureReserve } from 'types';
import {
  BankAccountPropTypes,
  TreasureReservePropTypes,
} from 'types/prop-types';
import { useOnboardingTransferContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { TrackEventName } from 'modules/tracking';
import { TransferSteps } from 'pages/v3-portal/transfer/enums/transfer-steps';
import { TransferWidgetForm } from './components';
import { Styled } from './transfer-widget.style';

export interface TransferWidgetProps {
  accounts: BankAccount[];
  treasureReserve: TreasureReserve;
  customSendMFA?: () => void;
  customConfirmMFA?: () => void;
}

const stepMap: any = {};
stepMap[TransferSteps.TransferSettings] = {
  step: TransferSteps.TransferSettings,
  nextStep: TransferSteps.TransferReview,
  submitCTA: 'Review transfer',
  submitDisabledDefault: false,
  trackEvent: TrackEventName.ClickReviewTransfer,
};
stepMap[TransferSteps.TransferReview] = {
  prevStep: TransferSteps.TransferSettings,
  step: TransferSteps.TransferReview,
  nextStep: TransferSteps.TransferSMS,
  submitCTA: 'Initiate transfer',
  submitDisabledDefault: false,
  trackEvent: TrackEventName.ClickStartTransfer,
};
stepMap[TransferSteps.TransferSMS] = {
  prevStep: TransferSteps.TransferReview,
  step: TransferSteps.TransferSMS,
  nextStep: TransferSteps.TransferConfirmation,
  trackEvent: TrackEventName.ViewTransferMFA,
};
stepMap[TransferSteps.TransferConfirmation] = {
  prevStep: TransferSteps.TransferSMS,
  step: TransferSteps.TransferConfirmation,
  nextStep: TransferSteps.TransferSettings,
  submitCTA: 'Close',
  submitDisabledDefault: false,
  trackEvent: TrackEventName.ClickConfirmTransfer,
};

const TransferWidget: React.FC<TransferWidgetProps> = ({
  accounts,
  treasureReserve,
  customSendMFA,
  customConfirmMFA,
}) => {
  const transferContext = useOnboardingTransferContext();

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);

  useEffect(() => {
    initTransferStep();
  }, [transferContext.currentTransferStep]);

  const initTransferStep = () => {
    switch (transferContext.currentTransferStep) {
      case TransferSteps.TransferSettings:
        setFieldsDisabled(false);
        setSubmitDisabled(
          stepMap[transferContext.currentTransferStep].submitDisabledDefault,
        );
        transferContext.setMFASent(false);
        break;
      case TransferSteps.TransferReview:
        setFieldsDisabled(true);
        setSubmitDisabled(
          stepMap[transferContext.currentTransferStep].submitDisabledDefault,
        );
        break;
      case TransferSteps.TransferSMS:
        setSubmitDisabled(
          stepMap[transferContext.currentTransferStep].submitDisabledDefault,
        );
        break;
      case TransferSteps.TransferConfirmation:
        break;
      default:
        break;
    }
  };

  const renderWidgetHeading = () => {
    return (
      <Styled.TransferWidgetHeading variant={2}>
        Transfer funds
        {stepMap[transferContext.currentTransferStep]?.prevStep ? (
          <Styled.IconContainer>
            <IconButton
              onClick={() => {
                if (
                  transferContext.currentTransferStep ===
                  TransferSteps.TransferConfirmation
                ) {
                  transferContext.setTransferStep(
                    stepMap[transferContext.currentTransferStep].nextStep,
                  );
                } else {
                  transferContext.setTransferStep(
                    stepMap[transferContext.currentTransferStep].prevStep,
                  );
                }
                transferContext.trackEvent({
                  eventName: TrackEventName.ClickTransferBack,
                });
              }}
              size="medium"
            >
              <CancelIcon />
            </IconButton>
          </Styled.IconContainer>
        ) : (
          ''
        )}
      </Styled.TransferWidgetHeading>
    );
  };

  return (
    <Styled.TransferWidgetContainer>
      {renderWidgetHeading()}
      {
        <TransferWidgetForm
          accounts={accounts}
          treasureReserve={treasureReserve}
          fieldsDisabled={fieldsDisabled}
          submitDisabled={submitDisabled}
          currentStep={stepMap[transferContext.currentTransferStep]}
          setNextStep={(step) => transferContext.setTransferStep(step)}
          setSubmitDisabled={(disabled) => setSubmitDisabled(disabled)}
          customSendMFA={customSendMFA ? () => customSendMFA() : undefined}
          customConfirmMFA={
            customConfirmMFA ? () => customConfirmMFA() : undefined
          }
        />
      }
    </Styled.TransferWidgetContainer>
  );
};

TransferWidget.propTypes = {
  accounts: PropTypes.arrayOf(BankAccountPropTypes.isRequired).isRequired,
  treasureReserve: TreasureReservePropTypes.isRequired,
  customSendMFA: PropTypes.func,
  customConfirmMFA: PropTypes.func,
};

export { TransferWidget };
