import React from 'react';
import PropTypes from 'prop-types';
import { NumberDisplay, NumberDisplayVariant } from '.';

interface PercentageProps {
  decimalScale?: number;

  /** the number to display as a percentage */
  number: number;

  /** function to allow different rendering of the output */
  renderText?: (formattedValue: string) => React.ReactNode;

  showExtremePrecision?: boolean;
}

const Percentage: React.FC<PercentageProps> = ({
  decimalScale,
  number,
  showExtremePrecision,
  renderText,
}) => {
  const handlePrecision = () => {
    const between0And1 = number > 0 && number < 1;
    const between99And100 = number > 99 && number < 100;

    const precision = {
      number,
      prefix: '',
    };

    if (showExtremePrecision) {
      return precision;
    }

    if (between0And1) {
      precision.number = 1;
      precision.prefix = '<';
    } else if (between99And100) {
      precision.number = 99;
      precision.prefix = '>';
    }

    return precision;
  };

  return (
    <NumberDisplay
      decimalScale={decimalScale || 0}
      number={handlePrecision().number}
      prefix={handlePrecision().prefix}
      renderText={renderText}
      variant={NumberDisplayVariant.percentage}
    />
  );
};

Percentage.propTypes = {
  decimalScale: PropTypes.number,
  number: PropTypes.number.isRequired,
  renderText: PropTypes.func,
  showExtremePrecision: PropTypes.bool,
};

export { Percentage };
