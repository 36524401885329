import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { Button } from 'components/core';
import { useHistory, useLocation } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';

interface SweepButtonProps {
  canSweep?: boolean;
}

export const SweepButton: React.FC<SweepButtonProps> = ({
  canSweep = true,
}) => {
  const history = useHistory();
  const location = useLocation();
  const isSweeping = location.pathname.includes(
    getVersionedPath({ path: RoutesPath.pages.transfer.path, version: 3 }),
  );
  return (
    <Hidden mdDown>
      <Box px={2}>
        <Button
          variant={isSweeping ? 'outlined' : 'contained'}
          onClick={() => {
            if (isSweeping) {
              history.push(
                getVersionedPath({
                  path: RoutesPath.pages.dashboard.path,
                  version: 3,
                }),
              );
            } else {
              history.push(
                getVersionedPath({
                  path: RoutesPath.pages.transfer.path,
                  version: 3,
                }),
              );
            }
          }}
        >
          {isSweeping ? 'Exit' : 'Transfer Funds'}
        </Button>
      </Box>
    </Hidden>
  );
};
