import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { GridCard } from 'components/dashboard';
import { BarChartByMonth, CashFlowTrends } from 'components/charts/v3';
import { CashFlowTrendsLegend } from 'components/charts/v3/cash-flow-trends/cash-flow-trends-legend';
import { CashDirection } from 'enums';
import { Link } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

// layout
import { useLoadingContext } from 'components/layouts/contexts/loading';

// hooks
import { useCashSnapshot } from 'hooks/banking/use-cash-snapshot';
import { useChartData } from 'hooks/banking/use-chart-data';

export const DashboardColumnAccountInsights: React.FC = () => {
  const loadingContext = useLoadingContext();

  const { data: cashSnapshot, isLoading: isLoadingCashSnapshot } =
    useCashSnapshot();

  const { data: chartData, isLoading: isLoadingChartData } =
    useChartData('revenue');

  return (
    <>
      <Box mb={3}>
        <Typography color="nero" variant={TypographyVariant.HeaderExtraBold}>
          Account Insights
        </Typography>
      </Box>

      {chartData && (
        <GridCard
          title="Cash Flow Trends"
          headerContent={<CashFlowTrendsLegend />}
        >
          <CashFlowTrends data={chartData} />
        </GridCard>
      )}

      {cashSnapshot?.income?.pastYear && (
        <GridCard title="Inflows">
          <Box ml={2}>
            <Typography color="nero" variant={TypographyVariant.SmallText}>
              Past 12 Months
            </Typography>
          </Box>

          <BarChartByMonth
            data={cashSnapshot.income.pastYear}
            variant={CashDirection.Inflows}
          />
        </GridCard>
      )}

      {cashSnapshot?.expenses?.pastYear && (
        <GridCard title="Outflows">
          <Box ml={2}>
            <Typography color="nero" variant={TypographyVariant.SmallText}>
              Past 12 Months
            </Typography>
          </Box>

          <BarChartByMonth
            data={cashSnapshot.expenses.pastYear}
            variant={CashDirection.Outflows}
          />
        </GridCard>
      )}

      <FeatureFlag
        enabled={
          <Link
            to={getVersionedPath({
              path: RoutesPath.pages.insights.path,
              version: 3,
            })}
          >
            View all account insights
          </Link>
        }
        flag={FeatureFlags.REACT_APP_2023_Q3_DISPLAY_INSIGHTS_ENABLED}
      />
    </>
  );
};
