import React, { useEffect } from 'react';
import { IHeaderButtons } from 'modules/dashboard/header/components/header-buttons/header-buttons';
import { useHistory, useLocation } from 'react-router-dom';
import { useMultipleAccountContext } from 'components/layouts/contexts/multiple-account';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { Box, Hidden } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { TrackEventName } from 'modules/tracking';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import { useUser } from 'hooks/auth/use-user';
import { isAllowedRole } from 'modules/2023-q4/rbac/utils/is-allowed-role';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { MenuOptions } from '../menu-options/menu-options';
import { Styled } from './app-menu-button.style';

interface MenuButtonProps {
  menuStatuses: IHeaderButtons;

  setMenuStatuses: React.Dispatch<React.SetStateAction<IHeaderButtons>>;
}

export const AppMenuButton: React.FC<MenuButtonProps> = ({
  menuStatuses,
  setMenuStatuses,
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const allocationEnabled = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2023_Q3_ALLOCATION_ENABLED,
  );
  const userRolesEnabled = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED,
  );

  const { data: business } = useBusiness();
  const { data: user } = useUser(business?.Id);

  const isAdmin = userRolesEnabled
    ? isAllowedRole({
        allowedRoles: [UserRole.ADMIN],
        role: user.role,
      })
    : true;

  let timeout: ReturnType<typeof setTimeout>;

  const handleShowMenu = () => {
    setMenuStatuses({
      appMenuButton: true,
      depositWithdrawalButton: false,
    });

    clearTimeout(timeout);
  };

  const hideMenu = () => {
    setMenuStatuses((previousState) => ({
      ...previousState,
      appMenuButton: false,
    }));
  };

  const handleHideMenu = () => {
    timeout = setTimeout(() => {
      hideMenu();
    }, 500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [menuStatuses.appMenuButton]);

  const { signOut } = useSignOut();
  const multipleAccountContext = useMultipleAccountContext();
  const history = useHistory();
  const location = useLocation();

  let companyName = business?.LegalName || business?.DBA;

  if (companyName === 'Bridge Building Inc' && business?.DBA) {
    companyName = business?.DBA;
  }

  // remove all non-alphanumeric or space characters
  const cleanedCompanyName = companyName?.replace(/[^a-zA-Z0-9 ]/g, '');

  // extract into tokens by splitting on spaces
  const companyNameTokens = cleanedCompanyName?.split(' ');

  // extract first character of first and last token
  // if the company names is 1 word, extract first and 2nd character
  const companyInitials = `${companyNameTokens?.[0][0]}${
    companyNameTokens?.[companyNameTokens?.length - 1][
      companyNameTokens?.length - 1 === 0 ? 1 : 0
    ]
  }`;

  const menuOptions = [
    {
      description: 'Settings',
      action: () => {
        history.push({
          pathname: getVersionedPath({
            path: RoutesPath.pages.settings.path,
            version: 3,
          }),
          state: { from: location },
        });
      },
      eventName: TrackEventName.SettingsClicked,
      path: RoutesPath.pages.settings.path,
    },
    {
      description: 'Documents',
      action: () => {
        history.push({
          pathname: getVersionedPath({
            path: RoutesPath.pages.documents.path,
            version: 3,
          }),
          state: { from: location },
        });
      },
      eventName: TrackEventName.DocumentsClicked,
      path: RoutesPath.pages.documents.path,
    },
    {
      description: 'Sign Out',
      action: () => {
        signOut();
      },
      eventName: TrackEventName.SignOutClicked,
    },
  ];

  if (multipleAccountContext.hasMultipleAccounts) {
    menuOptions.unshift({
      description: 'Switch Business',
      action: () => {
        multipleAccountContext.setAccountSelectionOpen(true);
        handleHideMenu();
      },
      eventName: TrackEventName.SwitchBusinessClicked,
    });
  }

  const menuOptionsDashboardAndBanking = [
    {
      description: 'Dashboard',
      action: () => {
        history.push({
          pathname: getVersionedPath({
            path: RoutesPath.pages.home.path,
            version: 3,
          }),
          state: { from: location },
        });
      },
      eventName: TrackEventName.AppMenuDashboardClicked,
      path: RoutesPath.pages.home.path,
    },
    {
      description: 'Banking',
      action: () => {
        history.push({
          pathname: getVersionedPath({
            path: RoutesPath.pages.banking.path,
            version: 3,
          }),
          state: { from: location },
        });
      },
      eventName: TrackEventName.AppMenuBankingClicked,
      path: RoutesPath.pages.banking.path,
    },
  ];

  const menuOptionsAllocate = [
    {
      description: 'Allocate',
      action: () => {
        history.push({
          pathname: RoutesPath.tasks.allocation.path,
          state: { from: location },
        });
      },
      eventName: TrackEventName.AppMenuDashboardClicked,
      path: RoutesPath.tasks.allocation.path,
    },
  ];

  const menuOptionsWithNavigation = [
    ...menuOptionsDashboardAndBanking,
    ...(allocationEnabled && isAdmin ? menuOptionsAllocate : []),
    ...menuOptions,
  ];

  const menuOptionsWithNavigationAndInsights = [
    ...menuOptionsDashboardAndBanking,
    {
      description: 'Insights',
      action: () => {
        history.push({
          pathname: getVersionedPath({
            path: RoutesPath.pages.insights.path,
            version: 3,
          }),
          state: { from: location },
        });
      },
      eventName: TrackEventName.AppMenuInsightsClicked,
      path: RoutesPath.pages.insights.path,
    },
    ...(allocationEnabled && isAdmin ? menuOptionsAllocate : []),
    ...menuOptions,
  ];

  return (
    <>
      {menuStatuses.appMenuButton && (
        <Box
          onMouseEnter={handleShowMenu}
          onMouseLeave={handleHideMenu}
          position="relative"
          zIndex={200}
        >
          <Box position="absolute" top={52} right={0}>
            <Hidden lgUp>
              <FeatureFlag
                disabled={
                  <MenuOptions
                    onClickAway={hideMenu}
                    options={menuOptionsWithNavigation}
                  />
                }
                enabled={
                  <MenuOptions
                    onClickAway={hideMenu}
                    options={menuOptionsWithNavigationAndInsights}
                  />
                }
                flag={FeatureFlags.REACT_APP_2023_Q3_DISPLAY_INSIGHTS_ENABLED}
              />
            </Hidden>

            <Hidden mdDown>
              <MenuOptions onClickAway={hideMenu} options={menuOptions} />
            </Hidden>
          </Box>
        </Box>
      )}

      <MobileView
        mobile={
          <Styled.Button
            bgColor="brown6"
            bgHoverColor="brown4b"
            cursor="auto"
            onMouseEnter={handleShowMenu}
            onMouseLeave={handleHideMenu}
            onMouseUp={handleShowMenu}
          >
            <Box px={1.5} py={1}>
              <Typography
                color="black19"
                variant={TypographyVariant.Paragraph2}
              >
                {companyInitials}
              </Typography>
            </Box>
          </Styled.Button>
        }
      >
        <Styled.Button
          bgColor="brown6"
          bgHoverColor="brown4b"
          cursor="auto"
          onMouseEnter={handleShowMenu}
          onMouseLeave={handleHideMenu}
        >
          <Box px={2} py={1}>
            <Typography color="black19" variant={TypographyVariant.Paragraph2}>
              <Box
                component="div"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                maxWidth={90}
              >
                {companyName}
              </Box>
            </Typography>
          </Box>
        </Styled.Button>
      </MobileView>
    </>
  );
};
