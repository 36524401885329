import styled from 'styled-components';

const WhiteLabelLogo = styled.img`
  max-width: 100%;
`;

const WhiteLabelLogoHeader = styled.img`
  max-height: 30px;
`;

const WhiteLabelLogoHeaderMobile = styled.img`
  max-height: 36px;
`;

export const Styled = {
  WhiteLabelLogo,
  WhiteLabelLogoHeader,
  WhiteLabelLogoHeaderMobile,
};
