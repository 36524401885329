import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Text } from 'components/core';

type ChartLegendProps = {
  subtitle: string;
  color: string;
  line?: boolean;
};

export const ChartLegend: React.FC<ChartLegendProps> = ({
  subtitle,
  color,
  line,
}) => {
  const boxWidth = line ? 23 : 20;
  const boxHeight = line ? 2 : 20;

  return (
    <Box display="flex" alignItems="center">
      <Box
        width={boxWidth}
        height={boxHeight}
        bgcolor={color}
        mr={1}
        borderRadius={3}
      />

      <Text color="gothic" variant={2}>
        {subtitle}
      </Text>
    </Box>
  );
};

ChartLegend.propTypes = {
  subtitle: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  line: PropTypes.bool,
};
