import React from 'react';
import { useInterval } from 'hooks/utils';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { useStoreAuthentication } from 'stores';
import { useValidateRelationship } from 'hooks/portal-gateway/use-validate-relationship';

export const AuthenticationCheck: React.FC = () => {
  const fiveMinutes = 300000; // delay in ms
  const { signOut } = useSignOut();
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);
  const validateRelationship = useValidateRelationship();

  useInterval(() => {
    if (isSignedIn) {
      validateRelationship.mutate(
        {},
        {
          onSettled: (data) => {
            // because we're checking for unauthorized error in react-query-error-toasts.tsx, this should never get called
            if (!data.activeRelationship) {
              signOut();
            }
          },
        },
      );
    }
  }, fiveMinutes);

  return null;
};
