import React, { useEffect } from 'react';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import {
  SelectSwitch,
  SelectSwitchOption,
} from 'modules/2023-q3/components/forms/components/select-switch/select-switch';
import { Styled } from 'modules/2023-q3/deposit-withdrawal/pages/form/components/select-switch-ach-bank/select-switch-ach-bank.style';

export const FrequencyOptions: SelectSwitchOption[] = [
  {
    label: 'One-Time',
    value: 'ONCE',
  },
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  {
    label: 'Every Other Week',
    value: 'BIWEEKLY',
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    label: 'Quarterly',
    value: 'QUARTERLY',
  },
];

export interface ISelectFrequency {
  frequency: SelectSwitchOption;

  setFrequency: (frequency: SelectSwitchOption) => void;
}

interface ISelectSwitchFrequency extends ISelectFrequency {
  isSelecting: boolean;

  setIsSelecting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectFrequency: React.FC<ISelectSwitchFrequency> = ({
  frequency,
  isSelecting,
  setIsSelecting,
  setFrequency,
}) => {
  const getSelectSwitchOptions = () => {
    const options: SelectSwitchOption[] = [];

    FrequencyOptions?.map((frequency: SelectSwitchOption) => {
      options.push(frequency);
    });

    return options;
  };

  const handleOnChange = (selectedOption: SelectSwitchOption) => {
    if (!setFrequency) {
      return;
    }

    setFrequency(selectedOption);
  };

  const selectSwitch = (
    <SelectSwitch
      isSelecting={isSelecting}
      label="Frequency"
      onChange={handleOnChange}
      options={getSelectSwitchOptions()}
      selectedOption={frequency}
      setIsSelecting={setIsSelecting}
    />
  );

  return (
    <MobileView mobile={selectSwitch}>
      <Styled.SelectSwitchContainer isSelecting={isSelecting}>
        {selectSwitch}
      </Styled.SelectSwitchContainer>
    </MobileView>
  );
};
