import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { TransactionPropTypes } from 'types/prop-types';
import { ActivityList } from './activity-list';
import { IActivityListItem } from './activity-list-item';
import { ActivityTotals, IActivityTotals } from './activity-totals';

export interface ActivityProps {
  data: {
    totals: IActivityTotals;

    transactions: IActivityListItem[];
  };
}

const Activity: React.FC<ActivityProps> = ({ data }) => {
  return (
    <>
      <ActivityTotals data={data.totals} />
      <Box mt={1}>
        <ActivityList data={data.transactions} />
      </Box>
    </>
  );
};

Activity.propTypes = {
  data: PropTypes.shape({
    totals: PropTypes.shape({
      credit: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        trend: PropTypes.string.isRequired,
      }),
      operating: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        trend: PropTypes.string.isRequired,
      }),
      sweepsIn: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        trend: PropTypes.string.isRequired,
      }),
      sweepsOut: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        trend: PropTypes.string.isRequired,
      }),
      total: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        trend: PropTypes.string.isRequired,
      }).isRequired,
      transfers: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        trend: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,

    transactions: PropTypes.arrayOf(TransactionPropTypes.isRequired).isRequired,
  }).isRequired,
};

export { Activity };
