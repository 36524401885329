import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Styled } from './password-criteria-check.style';

import {
  numberRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/password/password-validations';

interface PasswordCriteriaCheckProps {
  className?: string;

  password: string;
}

export const PasswordCriteriaCheck: React.FC<PasswordCriteriaCheckProps> = ({
  className,
  password,
}) => {
  const passwordHasNumber = password?.match?.(numberRegex);
  const passwordHasSpecialCharacter = password?.match?.(specialCharacterRegex);
  const passwordHasUpperCaseCharacter = password?.match?.(upperCaseRegex);
  const passwordValidationCriteria = [
    {
      text: '8 Characters',
      isValid: password?.length > 7,
    },
    {
      text: '1 Number',
      isValid: passwordHasNumber !== null && passwordHasNumber !== undefined,
    },
    {
      text: '1 Special Character',
      isValid:
        passwordHasSpecialCharacter !== null &&
        passwordHasSpecialCharacter !== undefined,
    },
    {
      text: '1 Upper-Case Letter',
      isValid:
        passwordHasUpperCaseCharacter !== null &&
        passwordHasUpperCaseCharacter !== undefined,
    },
  ];

  return (
    <Grid container justifyContent="space-around">
      {passwordValidationCriteria.map((criteria) => {
        return (
          <Grid item key={criteria.text}>
            <Styled.CriteriaBox
              mb={1.5}
              px={1.5}
              py={0.75}
              $isValid={criteria.isValid}
            >
              <Typography
                color={criteria.isValid ? 'green2' : 'grey4'}
                variant={TypographyVariant.Paragraph2}
              >
                {criteria.text}
              </Typography>
            </Styled.CriteriaBox>
          </Grid>
        );
      })}
    </Grid>
  );
};
