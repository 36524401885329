import styled from 'styled-components';
import { ReactComponent as TrendCurrencyUp } from 'assets/icons/trend_currency_up.svg';
import { Divider } from '../sub-indicators.common.style';

const TrendCurrencyUpIcon = styled(TrendCurrencyUp)`
  transform: scale(1.5);
`;

export const Styled = {
  Divider,
  TrendCurrencyUpIcon,
};
