import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { TypographyVariant } from 'components/core';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Styled } from './navigation.style';

const NavLink = (path: string, text: string) => {
  return (
    <Grid item>
      <Styled.NavigationLink exact to={path} activeClassName="selected">
        <Styled.NavigationTypography
          color="stack"
          variant={TypographyVariant.Body}
        >
          <Box pt={2} px={3} pb={1}>
            {text}
          </Box>
        </Styled.NavigationTypography>
      </Styled.NavigationLink>
    </Grid>
  );
};

export const Navigation = () => {
  return (
    <FeatureFlag
      disabled={
        <Grid container item justifyContent="center" xs>
          {NavLink(
            getVersionedPath({
              path: RoutesPath.pages.dashboard.path,
              version: 3,
            }),
            'Dashboard',
          )}

          {NavLink(
            getVersionedPath({
              path: RoutesPath.pages.banking.path,
              version: 3,
            }),
            'Banking',
          )}
        </Grid>
      }
      enabled={
        <Grid container item justifyContent="center" xs>
          {NavLink(
            getVersionedPath({
              path: RoutesPath.pages.dashboard.path,
              version: 3,
            }),
            'Dashboard',
          )}

          {NavLink(
            getVersionedPath({
              path: RoutesPath.pages.banking.path,
              version: 3,
            }),
            'Banking',
          )}

          {NavLink(
            getVersionedPath({
              path: RoutesPath.pages.insights.path,
              version: 3,
            }),
            'Insights',
          )}
        </Grid>
      }
      flag={FeatureFlags.REACT_APP_2023_Q3_DISPLAY_INSIGHTS_ENABLED}
    />
  );
};
