import styled from 'styled-components';
import { Box } from '@material-ui/core';

const IconCheckmarkWrapper = styled(Box)`
  svg rect {
    fill: var(--color-primary);
  }
`;

export const Styled = {
  IconCheckmarkWrapper,
};
