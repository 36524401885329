export enum TrackEventName {
  // Generic
  Click = 'Click',
  Viewed = 'Viewed',

  ConnectAccountsClicked = 'Connect accounts clicked',
  LiquidSenseActionClicked = 'Liquid sense action clicked',
  LiquidSenseDisplayed = 'Liquid sense displayed',
  MFAFailed = 'MFA failed',
  MFAInitiated = 'MFA initiated',
  MFASucceeded = 'MFA succeeded',
  MFAResendCodeInitiated = 'MFA Resend Code Initiated',
  MFAResendCodeFailed = 'MFA Resend Code Failed',
  MFAResendCodeSucceeded = 'MFA Resend Code Succeeded',
  Pageview = 'Pageview',
  SignInSucceeded = 'Sign in succeeded',
  SignInFailed = 'Sign in failed',
  SweepInClicked = 'Transfer in clicked',
  TopIndicatorDrilldownClosed = 'Top indicator drilldown closed',
  TopIndicatorDrilldownOpened = 'Top indicator drilldown opened',
  WithdrawalClicked = 'Withdrawal clicked',
  WithdrawalInitiated = 'Withdrawal initiated',
  WithdrawalSubmitted = 'Withdrawal submitted',

  // App
  AppMenuDashboardClicked = 'App Menu Dashboard Clicked',
  AppMenuBankingClicked = 'App Menu Banking Clicked',
  AppMenuInsightsClicked = 'App Menu Insights Clicked',
  DocumentsClicked = 'Documents Clicked',
  SettingsClicked = 'Settings Clicked',
  SignOutClicked = 'Sign out Clicked',
  ContactSupportClicked = 'Contact support clicked',
  LoadedApplication = 'loaded application',
  RefreshedExistingAuthentication = 'Refreshed existing authentication.',
  ToastNotificationShown = 'Toast notification shown',
  ToastNotificationClosed = 'Toast notification closed',
  GlobalServiceIssue = 'Global service issue',
  GlobalServiceIssueNotificationShown = 'Global service issue notification shown',
  SignOutIdleTimeout = 'Idle timeout triggered sign out',

  // Inputs
  Checkbox = 'Checkbox',
  Input = 'Input',
  Slider = 'Slider',
  Tooltip = 'Tooltip',

  // Buttons
  AcceptButtonClicked = 'Accept button clicked',
  BackButtonClicked = 'Back button clicked',
  CancelButtonClicked = 'Cancel button clicked',
  ConfirmButtonClicked = 'Confirm button clicked',
  ResetButtonClicked = 'Reset button clicked',

  // Charts
  ChartClick = 'Chart Click',
  ChartHover = 'Chart Hover',

  // Modals
  ModalBackdropCloseClicked = 'Modal backdrop close clicked',
  ModalCloseIconClicked = 'Modal close icon clicked',

  // Onboarding intake
  IMAOpened = 'IMA Opened',
  SolicitationDisclosureOpened = 'Solicitation Disclosure Opened',
  GetStartedClicked = 'Get Started Clicked',
  PrintAgreementClicked = 'Print Agreement Clicked',
  IMAAgreed = 'IMA Agreed',
  PasswordSet = 'Password set',
  RedeemInviteToken = 'Invite token redeemed',
  SSNTooltipOpened = 'SSN Tooltip Opened',
  UserPersonalInfoCompleted = 'User Personal Info Completed',
  RareScenariosCompleted = 'Rare Scenarios Completed',
  PublicCompanyControlCompleted = 'Public Company Control Completed',
  FINRAAssociationCompleted = 'FINRA Association Completed',
  InterestedPartiesCompleted = 'Interested Parties Completed',
  PoliticalExposureTooltipOpened = 'Political Exposure Tooltip Opened',
  PoliticalExposureConfirmed = 'Political Exposure Confirmed',
  PoliticalExposureCompleted = 'Political Exposure Completed',
  PoloticalExposuresProvided = 'Polotical Exposures Provided',
  ConnectAccountsTooltipOpened = 'Connect Accounts Tooltip Opened',
  ConnectFlowStarted = 'Connect Flow Started',
  AdditionalInstitutionsConnected = 'Additional Institutions Connected',
  ConnectBanksCompleted = 'Connect Banks Completed',
  BankAccountExcluded = 'Bank Account Excluded',
  BankAccountIncluded = 'Bank Account Included',
  BusinessDetailsCompleted = 'Business Details Completed',
  BusinessClassificationCompleted = 'Business Classification Completed',
  RiskProfileSelected = 'Risk profile selected',
  RiskProfileCompleted = 'Risk Profile Completed',
  UBOSelfIdentified = 'UBO Self Identified',
  UBONotSelfIdentified = 'UBO Not Self Identified',
  EOSelfIdentified = 'EO Self Identified',
  EONotSelfIdentified = 'EO Not Self Identified',
  AdditionalUBOAdded = 'Additional UBO Added',
  AdditionalEOAdded = 'Additional EO Added',
  PersonEdited = 'Person Edited',
  PersonDeleted = 'Person Deleted',
  OwnersAndOfficersCompleted = 'Owners and Officers Completed',
  AuthorizedSignerStatusUpdated = 'Authorized Signer Status Updated',
  CompletedPageRevisited = 'Completed Page Revisited',
  InformationSummaryClosed = 'Information Summary Closed',
  IncompletePageRevisited = 'Incomplete Page Revisited',
  ApexAgreementsCompleted = 'Apex Agreements Completed',
  ApexAgreementsPrinted = 'Apex Agreements Printed',
  OnboardingScheduled = 'Onboarding Scheduled',
  HubspotChatOpened = 'Hubspot Chat Opened',
  PageSkipped = 'Page Skipped',
  ProgressIndicatorClicked = 'Progress Indicator Clicked',
  BackNavigationClicked = 'Back Navigation Clicked',
  CreateNewOwnerClicked = 'New Owner Opened',
  CreateNewOfficerClicked = 'New Officer Opened',
  ContinueClickedEditPersonDetail = 'Person Updated',
  ExisingPersonSelected = 'Existing Person Selected',
  IntakeSignOutClicked = 'Intake user signed out',

  // Streamlined Onboarding
  BankAccountAgreementOpened = 'Grasshopper Bank N.A. Account Agreement opened',
  PlaidOrManualIntersitialDisplayed = 'Plaid or Manual Intersitial Displayed',
  ManualACHFormDisplayed = 'Manual ACH Form Displayed',
  ManualACHFormCompleted = 'Manual ACH Form Completed',
  OnboardingSweepSkipped = 'Onboarding Transfer Skipped',
  OnboardingSweepCompleted = 'Onboarding Transfer Completed',

  // Self serve sign up
  SignUpCreateAccountClicked = 'Sign-up create account clicked',
  SignUpSignInClicked = 'Sign-up existing sign in clicked',
  SignUpResendEmail = 'Sign-up resend email clicked',
  ExpiredLinkResendClicked = 'Sign-up expired resend clicked',
  ResendEmailSignInClicked = 'Resend email sign in clicked',

  // ACH
  ClickTransfer = 'Dashboard card transfer clicked',
  ClickAddNewAccount = 'Add new account clicked',
  ClickWireTransferInstructions = 'Wire transfer instructions opened',
  CloseWireInstructions = 'Wire transfer instructions closed',
  ClickReviewTransfer = 'Review transfer clicked',
  ClickStartTransfer = 'Transfer clicked',
  ViewTransferMFA = 'Transfer MFA loaded',
  ClickResendCode = 'Resend MFA code clicked',
  ClickMFAContactUs = 'MFA contact us clicked ',
  ClickTransferBack = 'Back button clicked',
  ClickConfirmTransfer = 'Transfer confirmation clicked',
  TransferFromAccountChanged = 'Transfer from account changed',

  // Wire Instructions viewed
  WireInstructionsViewed = 'Wire instructions viewed',

  // Multi Account
  SwitchBusinessClicked = 'Switch business clicked',
  MultipleAccountSelected = 'Multiple account selected',
  MultipleAccountTableFilterFocus = 'Multiple account table filter focused',
  MultipleAccountTableSorted = 'Multiple account table sorted',

  // Multi User
  InviteTeamMemberButtonClicked = 'Invite team member button clicked',
  TeamMemberInviteFormSubmitted = 'Team member invite form submitted',
  TeamMemberInviteFormSubmitSucceeded = 'Team member invite form submit succeeded',

  // Password
  PasswordResetRequested = 'Password reset requested',
  PasswordResetSucceeded = 'Password reset succeeded',
  PasswordUpdatedFailed = 'Password updated failed',
  PasswordUpdatedSucceeded = 'Password updated succeeded',

  // PortfolioAllocation
  AcceptAllocationButtonClicked = 'Accept allocation button clicked',
  PortfolioAllocationNoFundsShown = 'Portfolio allocation no funds shown',
  PortfolioAllocationPendingAllocationNoFundsShown = 'Portfolio allocation pending allocation no funds shown',
  PortfolioAllocationPendingAllocationShown = 'Portfolio allocation pending allocation shown',
  PortfolioAllocationRealizedAllocationShown = 'Portfolio allocation realized allocation shown',
  PortfolioAllocationRealizedAllocationError = 'Portfolio allocation realized allocation error',
  PortfolioAllocationSetAllocationShown = 'Portfolio allocation set allocation shown',
  ReturnDistributionCharts = 'Return distribution charts',
  SeeRecommendedPortfolioButtonClicked = 'See recommended portfolio button clicked',
  TransferFundsButtonClicked = 'Transfer funds button clicked',
  BankingDashboardLinkClicked = 'Banking dashboard link clicked',

  // Firebase
  FirebaseSignInFailed = 'Firebase: Sign in failed',
  FirebaseSignInSucceeded = 'Firebase: Sign in succeeded',
  FirebaseSignOutSucceeded = 'Firebase: Sign out succeeded',

  // Plaid
  PlaidAccountConnectionFailure = 'Plaid account connection failure',
  PlaidAccountConnectionSucceeded = 'Plaid account connection succeeded',
  PlaidAccountNotAuthenticated = 'Plaid account is not authenticated notification shown',
  PlaidModalOpened = 'Plaid modal opened',
  PlaidLinkModalOpened = 'Plaid link modal opened',
  PlaidLinkUpdateFailure = 'Plaid link update failure',
  PlaidLinkUpdateSucceeded = 'Plaid link update succeeded',
  PlaidStaleDataErrorShown = 'Plaid stale data error shown',
  PlaidFixIssueClicked = 'Plaid fix issue clicked',
  PlaidEvent = 'Plaid Event',

  // Settings
  SettingsAccountClicked = 'Settings - Account clicked',
  SettingsBusinessesClicked = 'Settings Businesses clicked',
  SettingsPasswordClicked = 'Settings - Password clicked',
  SettingsTeamClicked = 'Settings - Team clicked',
  SettingsTradeRestrictionsClicked = 'Settings - Trade Restrictions clicked',
  SettingsQuickbooksClicked = 'Settings - Quickbooks clicked',

  // Quickbooks
  QuickbooksConnectClicked = 'Quickbooks connect clicked',
  QuickbooksConnectFailed = 'Quickbooks connect failed',
  QuickbooksConnectSuccess = 'Quickbooks connect success',
  QuickbooksInitialSync = 'Quickbooks initial sync',
  QuickbooksSyncClicked = 'Quickbooks sync clicked',
  QuickbooksSyncSuccess = 'Quickbooks sync success',
  QuickbooksSyncFailed = 'Quickbooks sync failed',
  QuickbooksDisconnectClicked = 'Quickbooks disconnect clicked',
  QuickbooksDisconnectSuccess = 'Quickbooks disconnect success',
  QuickbooksDisconnectFailed = 'Quickbooks disconnect failed',

  // Pagination
  PaginationClicked = 'Pagination clicked',

  // Time Filter
  TimeFilterClicked = 'Time filter clicked',
}
