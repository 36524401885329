import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Text, Typography, TypographyVariant } from 'components/core';
import { TransactionPropTypes } from 'types/prop-types';
import { ActivityListItem, IActivityListItem } from './activity-list-item';
import { Styled } from './activity-list.style';

export interface ActivityListProps {
  data: IActivityListItem[];
}

const getHeading = (text: string) => {
  return (
    <Typography color="gothic" variant={TypographyVariant.SmallLabel2}>
      {text}
    </Typography>
  );
};

const ActivityList: React.FC<ActivityListProps> = ({ data }) => (
  <Box>
    <Table>
      <TableHead>
        <TableRow>
          <Styled.TableCell />
          <Styled.TableCell>{getHeading('Description')}</Styled.TableCell>
          <Styled.TableCell>{getHeading('Bank')}</Styled.TableCell>
          <Styled.TableCell>{getHeading('Amount')}</Styled.TableCell>
          <Styled.TableCell>{getHeading('Status')}</Styled.TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {data.length ? (
          data.map((row, index) => (
            <ActivityListItem
              key={`activity-list-item-${index.toString()}`}
              data={row}
              showDivider={index < data.length - 1}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={5}>
              <Box textAlign="center">
                <Text variant={2}>No activity during this period</Text>
              </Box>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Box>
);

ActivityList.propTypes = {
  data: PropTypes.arrayOf(TransactionPropTypes.isRequired).isRequired,
};

export { ActivityList };
