import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { DashboardContainer } from 'layouts/v3-portal-layout/components';
import {
  DashboardColumnAccountInsights,
  DashboardColumnPerformanceCharts,
  DashboardColumnTreasureReserve,
  WelcomeModal,
} from 'pages/v3-portal/dashboard/components';
import { PortfolioAllocationDashboardEntryPoint } from 'modules/portfolio/allocation/components/dashboard-entry-point/portfolio-allocation-dashboard-entry-point';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import { Transactions } from 'modules/dashboard/treasure-reserve';
import { PortfolioTransition } from 'modules/dashboard/treasure-reserve/portfolio-transition/portfolio-transition';
import { useBusiness } from 'hooks/business/use-business';

export const DashboardTransition: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const loadingContext = useLoadingContext();

  const portfolioAllocationEnabled = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_ALLOCATION_ENABLED,
  );

  // currently, we don't want to show the portfolio entry point for all users
  // the logic to determine that is within <PortfolioAllocationDashboardEntryPoint />
  // and will update this external state
  const [showPortfolio, setShowPortfolio] = useState<boolean>();

  if (portfolioAllocationEnabled && showPortfolio !== false) {
    return (
      <DashboardContainer>
        <Box mt={4}>
          <Box mb={5}>
            <FeatureFlag
              disabled={
                <PortfolioAllocationDashboardEntryPoint
                  setShowPortfolio={setShowPortfolio}
                />
              }
              enabled={<PortfolioTransition />}
              flag={FeatureFlags.REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED}
            />
          </Box>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <DashboardColumnPerformanceCharts />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DashboardColumnAccountInsights />
            </Grid>
          </Grid>
        </Box>

        <FeatureFlag
          enabled={<Transactions />}
          flag={FeatureFlags.REACT_APP_DASHBOARD_TREASURE_RESERVE_ENABLED}
        />

        <WelcomeModal />
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
      <Box mt={4}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <DashboardColumnTreasureReserve />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DashboardColumnAccountInsights />
          </Grid>
        </Grid>
      </Box>
      <WelcomeModal />
    </DashboardContainer>
  );
};
