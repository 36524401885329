import React from 'react';
import { Box } from '@material-ui/core';
import { WhiteLabelPartners } from 'modules/2023-q4/white-label/consts/white-label-partners';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';
import { DefaultTreasureLogos } from './components/default-treasure-logos/default-treasure-logos';
import { PoweredByTreasure } from './components/powered-by-treasure/powered-by-treasure';
import { Styled } from './white-label-logo.style';

export enum WhiteLabelLogoVariant {
  Default,
  Header,
  HeaderMobile,
  TaskHeader,
}

interface IWhiteLabelLogo {
  variant: WhiteLabelLogoVariant;
}

export const WhiteLabelLogo: React.FC<IWhiteLabelLogo> = ({ variant }) => {
  const { partnerId } = useStoreWhiteLabelPartner((state) => state);

  const partnerData = WhiteLabelPartners.find(
    (partner) => partner.id === partnerId,
  );

  if (partnerData) {
    const companyName = partnerData.name;
    const companyLogo = partnerData.logos.default;
    const companyLogoMobile = partnerData.logos.mobile;

    switch (variant) {
      case WhiteLabelLogoVariant.Header:
        return (
          <>
            <Styled.WhiteLabelLogoHeader
              alt={`${companyName} logo`}
              src={companyLogo}
            />
            <PoweredByTreasure variant={variant} />
          </>
        );

      case WhiteLabelLogoVariant.HeaderMobile:
        return (
          <Box textAlign="center">
            <Styled.WhiteLabelLogoHeaderMobile
              alt={`${companyName} logo`}
              src={companyLogoMobile || companyLogo}
            />
            <PoweredByTreasure variant={variant} />
          </Box>
        );

      case WhiteLabelLogoVariant.TaskHeader:
        return (
          <Styled.WhiteLabelLogoHeaderMobile
            alt={`${companyName} logo`}
            src={companyLogoMobile || companyLogo}
          />
        );

      default:
        return (
          <>
            <Styled.WhiteLabelLogo
              alt={`${companyName} logo`}
              src={companyLogo}
            />
            <PoweredByTreasure variant={variant} />
          </>
        );
    }
  }

  return <DefaultTreasureLogos variant={variant} />;
};
