import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Text } from 'components/core';
import { Allocation } from 'components/banking';
import { TopIndicators } from 'enums';
import { useSubIndicator } from 'modules/sub-indicators/hooks/get-subindicator';
import { LoadingSubIndicator } from 'components/dashboard/top-indicators/sub-indicators/loading/loading-sub-indicators';
import { Styled } from './total-cash-sub-indicator.style';

interface TotalCashSubIndicatorProps {
  totalCash: number;
}

const TotalCashSubIndicator: React.FC<TotalCashSubIndicatorProps> = ({
  totalCash,
}) => {
  const { data: allocation = [], isLoading } = useSubIndicator(
    TopIndicators.Allocation,
  );

  if (isLoading) {
    return <LoadingSubIndicator />;
  }

  if (allocation?.length === 0) {
    return (
      <Box mb={2}>
        <Text variant={1}>
          Your cash reserves are what give you the confidence to know you can
          survive anything the economy throws at you. With your Treasure Reserve
          account, your money is being put to work for you, earning a return to
          boost your runway
        </Text>
      </Box>
    );
  }

  if (totalCash === 0) {
    return (
      <Box mb={2}>
        <Text variant={1}>
          Either you&apos;re out of cash or you may need to connect more
          accounts to Treasure in order for us to provide you with powerful cash
          management insights.
        </Text>
      </Box>
    );
  }

  return (
    <>
      {allocation.length > 0 && (
        <>
          <Box mb={2}>
            <Text color="black" variant={1}>
              Your cash overview in real-time; keeping you informed when you
              need to take action.
            </Text>
          </Box>

          <Box mb={3}>
            <Styled.Divider />
          </Box>

          <Allocation accounts={allocation} />
        </>
      )}
    </>
  );
};

TotalCashSubIndicator.propTypes = {
  totalCash: PropTypes.number.isRequired,
};

export { TotalCashSubIndicator };
