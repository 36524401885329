import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useBankAccountTransactions = ({
  bankAccountId,
  page,
  pageSize,
}: {
  bankAccountId: string;
  page: number;
  pageSize: number;
}): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Banking);

  return useQuery(
    [ReactQueryKeys.BankAccountTransactions, bankAccountId, pageSize, page],
    async () => {
      const response = await treasureApi().get(
        `/accounts/${bankAccountId}/transactions`,
        {
          params: {
            page,
            pageSize,
          },
        },
      );

      return response.data;
    },
  );
};
