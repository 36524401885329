import styled from 'styled-components';
import { Typography } from 'components/core';

const CellText = styled(Typography)`
  font-size: 18px;
`;

const TooltipList = styled.div`
  ul > li:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const UpperCase = styled(Typography)`
  text-transform: uppercase;
`;

export const Styled = {
  CellText,
  TooltipList,
  UpperCase,
};
