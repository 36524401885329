import React from 'react';
import { Currency, CurrencyVariant } from 'components/core';
import { MANGED_TREASURIES_MINIMUM } from 'modules/2024-q1/allocation-by-amount-or-percentage/interface/product-allocation';
import {
  IDepositWithdrawal,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';

export const ManagedTreasuriesMinimumMessage: React.FC<IDepositWithdrawal> = ({
  variant,
}) => {
  if (variant === DepositWithdrawalVariant.Deposit) {
    return (
      <>
        Managed Treasuries requires{' '}
        <Currency
          number={MANGED_TREASURIES_MINIMUM}
          variant={CurrencyVariant.NearestDollar}
        />{' '}
        minimum. Any allocated funds under{' '}
        <Currency
          number={MANGED_TREASURIES_MINIMUM}
          variant={CurrencyVariant.NearestDollar}
        />{' '}
        will be added to Managed Money Market.
      </>
    );
  }

  if (variant === DepositWithdrawalVariant.Withdrawal) {
    return (
      <>
        Managed Treasuries require a minimum trade of{' '}
        <Currency
          number={MANGED_TREASURIES_MINIMUM}
          variant={CurrencyVariant.NearestDollar}
        />
        . Any withdrawal request might require that the Managed Treasuries
        portion of the withdrawal be rounded to{' '}
        <Currency
          number={MANGED_TREASURIES_MINIMUM}
          variant={CurrencyVariant.NearestDollar}
        />{' '}
        and the remaining funds will be invested in Managed Money Market.
      </>
    );
  }

  return null;
};
