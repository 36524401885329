import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';

export const isAllowedRole = ({
  allowedRoles,
  role,
}: {
  allowedRoles: UserRole[];
  role: UserRole;
}) => {
  return allowedRoles.includes(role);
};
