import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Styled } from './bullet.style';

interface IBullet {
  children: React.ReactNode;
  color?: string;
  number: number;
}

export const Bullet: React.FC<IBullet> = ({ children, color, number }) => {
  return (
    <Grid container alignItems="baseline">
      <Grid item>
        <Styled.Bullet color={color} textAlign="center" mr={1.5}>
          <Typography
            color={color === 'green1' ? 'white' : 'grey1'}
            variant={TypographyVariant.Paragraph2}
          >
            {number}
          </Typography>
        </Styled.Bullet>
      </Grid>

      <Grid item xs>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};
