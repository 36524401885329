import styled from 'styled-components';

const RightArrow = styled.span`
  border-color: inherit;
  border-style: solid;
  border-width: 1px 1px 0 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
`;

export const Styled = {
  RightArrow,
};
