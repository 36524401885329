import React from 'react';
import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { AxiosResponse } from 'axios';
import { useSendEvent, TrackEventName } from 'modules/tracking';

interface ResetPasswordFields {
  actionCode: string;
  newPassword: string;
}

export const useResetPassword = () => {
  const { sendEvent } = useSendEvent();
  const { treasureApi } = useTreasureApi(ApiKeys.Authentication);

  const resetPassword: (
    email: string,
    payload: ResetPasswordFields,
  ) => Promise<AxiosResponse> = async (email, payload) => {
    const { data } = await treasureApi().post('/user/resetPassword', payload);

    sendEvent(TrackEventName.PasswordResetSucceeded, { username: email });

    return data;
  };

  return { resetPassword };
};
