import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ReactQueryKeys } from 'enums';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useTranslation } from 'app/i18n';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { useQueryClient } from 'react-query';

// hooks
import { useAddedInitialSweep } from 'modules/onboarding/v3-streamlined-onboarding/hooks/mutate-added-initial-sweep';

import { Deposit as DepositFlow } from 'modules/2023-q3/deposit-withdrawal/deposit/deposit';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { DepositWithdrawalSteps } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';

export const Deposit: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.OnboaringSweep,
  });
  const { t } = useTranslation();

  const onboardingContext = useOnboardingContext();
  const queryClient = useQueryClient();
  const mutateAddedInitialSweep = useAddedInitialSweep();

  const [loading, setLoading] = useState(false);
  const [depositStep, setDepositStep] = useState(0);

  const showBottomContent = depositStep === DepositWithdrawalSteps.Form;
  const showDepositFlow = depositStep !== DepositWithdrawalSteps.Confirmation;

  const onSkip = () => {
    queryClient.setQueryData(ReactQueryKeys.InitialOnboardingSweep, {});

    mutateAddedInitialSweep.mutate({ addedInitialSweep: false });

    setLoading(true);

    trackEvent({
      eventName: TrackEventName.OnboardingSweepSkipped,
    });
  };

  const bottomContent = (
    <>
      <Typography variant={TypographyVariant.SmallLabel3}>
        {t('account not opened sweep', { ns: 'compliance' })}
      </Typography>

      <Box textAlign="center" mt={3}>
        <Button
          bgColor="white"
          bgHoverColor="white"
          size="small"
          variant="outlined"
          onClick={onSkip}
        >
          <ButtonContent>
            <Typography color="grey1" variant={TypographyVariant.SmallLabel3}>
              Skip Deposit
            </Typography>
          </ButtonContent>
        </Button>
      </Box>
    </>
  );

  const onStepChange = (step: number) => {
    setDepositStep(step);
    // setShowBottomContent(step === DepositWithdrawalSteps.Form);

    if (step === DepositWithdrawalSteps.Confirmation) {
      mutateAddedInitialSweep.mutate({ addedInitialSweep: true });
    }
  };

  useEffect(() => {
    onboardingContext.showLoading(loading || !showDepositFlow);
  }, [loading, showDepositFlow]);

  if (!showDepositFlow) {
    return null;
  }

  return (
    <>
      <DepositFlow hideContainer hideHeader onStepChange={onStepChange} />

      {showBottomContent && (
        <MobileView
          mobile={
            <Box position="absolute" bottom={100}>
              {bottomContent}
            </Box>
          }
        >
          <Box mt={3}>{bottomContent}</Box>
        </MobileView>
      )}
    </>
  );
};
