import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {
  ListItem as MuiListItem,
  Divider as MuiDivider,
  List as MuiList,
} from '@material-ui/core';

const List = styled(MuiList)`
  padding: 0 0 var(--spacing-04) 0;
`;

const ListItem = styled(MuiListItem)`
  padding: var(--spacing-02) var(--spacing-03);
  margin-bottom: var(--spacing-02);
  min-width: 260px;
  color: var(--color-nero);
`;

const CloseButton = styled(CloseIcon)`
  width: 40px;
  height: 40px;
`;

const CloseButtonWrapper = styled.div`
  text-align: right;
  padding: var(--spacing-01);
`;

const Divider = styled(MuiDivider)`
  margin-bottom: var(--spacing-02);
`;

const Menu = styled(MenuIcon)`
  margin-right: var(--spacing-02);
`;

export const Styled = {
  CloseButtonWrapper,
  CloseButton,
  ListItem,
  Divider,
  Menu,
  List,
};
