import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Text } from 'components/core';
import {
  IndicatorProps,
  IndicatorType,
} from 'components/dashboard/top-indicators/top-indicator';
import { getLiquidSenseContent } from 'modules/liquid-sense';
import { ReactComponent as LiquidSenseLogo } from 'assets/logos/liquid_sense.svg';
import { useTracking, TrackEventName } from 'modules/tracking';
import { TreasureReserve } from 'types';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';

import { Styled } from './liquid-sense.style';

interface LiquidSenseProps {
  indicator: IndicatorProps;
}

const LiquidSense: React.FC<LiquidSenseProps> = ({ indicator }) => {
  const { trackEvent } = useTracking();

  const { data: business } = useBusiness();

  const { data: accounts } = useBankAccounts(business && business.Id);

  const { data: treasureReserve } = useTreasureReserve(business && business.Id);

  const {
    action,
    bankAccount,
    description,
    liquidSenseContentType,
    sweepType,
  } = getLiquidSenseContent(
    accounts,
    treasureReserve || ({} as TreasureReserve),
  );

  useEffect(() => {
    if (action && description) {
      trackEvent({
        eventName: TrackEventName.LiquidSenseDisplayed,
        liquidSenseAction: sweepType,
        liquidSenseType: liquidSenseContentType,
      });
    }
  }, [liquidSenseContentType]);

  // do not show Liquid Sense on Net Cash Flow or Total Cash indicators
  if (
    indicator.type === IndicatorType.TotalCash ||
    indicator.type === IndicatorType.NetCashFlow
  ) {
    return null;
  }

  return (
    <Styled.Paper>
      <Styled.LiquidSenseBanner>
        <Box ml={0.5}>
          <Grid container>
            <Grid item>
              <LiquidSenseLogo />
            </Grid>
            <Grid item>
              <Box mt={0.8} ml={2} mr={1}>
                <Text color="nero" variant={2}>
                  AI-powered recommendations
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Styled.LiquidSenseBanner>

      <Box px={2} py={3}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Text variant={1}>{description}</Text>
          </Grid>

          <Grid item xs={6}>
            <Box textAlign="right">{action}</Box>
          </Grid>
        </Grid>
      </Box>
    </Styled.Paper>
  );
};

LiquidSense.propTypes = {
  indicator: PropTypes.shape({
    handleClick: PropTypes.func.isRequired, // TODO: fix me (undefined is allowed)
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(IndicatorType) as IndicatorType[])
      .isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};

export { LiquidSense };
