import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TransactionType } from 'enums';
import { ReactComponent as BankDebitIcon } from 'assets/icons/transactions/bank-debit.svg';
import { ReactComponent as CreditIcon } from 'assets/icons/transactions/credit.svg';
import { ReactComponent as DebitIcon } from 'assets/icons/transactions/debit.svg';
import { ReactComponent as TransferIcon } from 'assets/icons/transactions/transfer.svg';

interface TransactionTypeIconProps {
  type: TransactionType;
}

const TransactionTypeIconComponent: React.FC<TransactionTypeIconProps> = ({
  type,
}) => {
  switch (type) {
    case TransactionType.BankDebit:
      return <BankDebitIcon />;
    case TransactionType.Credit:
      return <CreditIcon />;
    case TransactionType.Debit:
      return <DebitIcon />;
    case TransactionType.Transfer:
      return <TransferIcon />;
    default:
      return null;
  }
};

TransactionTypeIconComponent.propTypes = {
  type: PropTypes.oneOf([...Object.values(TransactionType)]).isRequired,
};

export const TransactionTypeIcon = memo(TransactionTypeIconComponent);
