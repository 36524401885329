import React from 'react';
import { Box, Divider } from '@material-ui/core';

import { Text } from 'components/core';
import { Allocation } from 'components/banking';
import { TopIndicators } from 'enums';
import { useSubIndicator } from 'modules/sub-indicators/hooks/get-subindicator';
import { LoadingSubIndicator } from 'components/dashboard/top-indicators/sub-indicators/loading/loading-sub-indicators';

const IdleCashSubIndicator: React.FC = () => {
  const { data: idleDistribution, isLoading } = useSubIndicator(
    TopIndicators.IdleDistribution,
  );

  return (
    <>
      {isLoading && <LoadingSubIndicator />}

      {idleDistribution && idleDistribution.length > 0 && (
        <>
          <Box mb={2}>
            <Text color="black" variant={2}>
              Allocation
            </Text>
          </Box>

          <Box mb={3}>
            <Allocation accounts={idleDistribution} isIdle />
          </Box>
        </>
      )}

      <Box mb={3}>
        <Divider />
      </Box>

      <Text color="black" variant={1}>
        When your money sits unused in your bank accounts, it is a drag on your
        business, losing value every year. Instead of accruing more losses,
        start generating new income by moving your idle cash to your Treasure
        Reserve.
      </Text>
    </>
  );
};

export { IdleCashSubIndicator };
