import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { breakpoints } from 'styles/css-constants';

interface FormBlocksProps {
  disableLastRoundedCorners?: boolean;
}

const FormBlock = styled(Box)`
  border: 1px solid var(--color-grey6);
  border-bottom-width: 0;

  ${breakpoints.down('sm')} {
    border-left: 0 none;
    border-right: 0 none;
  }
`;

const FormBlocks = styled(({ disableLastRoundedCorners, ...rest }) => (
  <Box {...rest} />
))<FormBlocksProps>`
  ${FormBlock}:first-of-type {
    border-top-left-radius: var(--border-radius-12px);
    border-top-right-radius: var(--border-radius-12px);

    ${breakpoints.down('sm')} {
      border-top: 0 none;
    }
  }

  ${FormBlock}:last-of-type {
    border-bottom-left-radius: var(--border-radius-12px);
    border-bottom-right-radius: var(--border-radius-12px);
    border-bottom-width: 1px;

    ${(props) =>
      props.disableLastRoundedCorners &&
      css`
        border-radius: 0;
      `}

    ${breakpoints.down('sm')} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const SaveBankButton = styled(Button)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const Styled = {
  FormBlock,
  FormBlocks,
  SaveBankButton,
};
