import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { BankAccount } from 'types';

interface Props {
  bankAccount: BankAccount;
}

export const SelectSwitchACHBankOption: React.FC<Props> = ({ bankAccount }) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {bankAccount.institution?.name}
        </Typography>
      </Grid>

      <Grid item>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {bankAccount.Name} / {bankAccount.Mask}
        </Typography>
      </Grid>
    </Grid>
  );
};
