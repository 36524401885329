import React, { useEffect } from 'react';
import { ManualBankAccount } from 'types';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { useForm } from 'react-hook-form';
import { useTracking, TrackEventName } from 'modules/tracking';

import { useManualBankAccountConnect } from 'modules/onboarding/v3-streamlined-onboarding/pages/bank-details/components/manual-ach/manual-ach-form/hooks/use-manual-bank-account-connect';

import {
  AutocompleteController,
  TextFieldController,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { formErrorFocus } from 'modules/onboarding/v3-streamlined-onboarding/utils';

import {
  formFields,
  BankAccountType,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/bank-details/components/manual-ach/manual-ach-form/manual-ach-form-fields';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Button } from 'modules/2023-q3/components/button/button';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Styled } from 'modules/2023-q3/deposit-withdrawal/pages/form/form.style';

interface Props {
  bankAccount: ManualBankAccount | undefined;
  nextStep: () => void;
}

export const Confirmation: React.FC<Props> = ({ bankAccount, nextStep }) => {
  const content = (
    <>
      <Box mb={3}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          Bank Account ACH Information
        </Typography>
      </Box>

      <Box mb={10}>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {`${bankAccount?.accountType
            .charAt(0)
            .toUpperCase()}${bankAccount?.accountType.slice(1)}`}{' '}
          account ending in {bankAccount?.accountNumber.toString().slice(-4)}{' '}
          has been added. You can now transfer funds to and from your bank.
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs>
          <Button fullWidth onClick={nextStep}>
            <ButtonContent iconStart={SupportedIcons.Add}>
              Add another
            </ButtonContent>
          </Button>
        </Grid>

        <Grid item xs>
          <Button fullWidth>
            <Link to={RoutesPath.pages.connectBankAccounts.path}>
              <ButtonContent>Done</ButtonContent>
            </Link>
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const error = (
    <>
      <Box mb={3}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          Bank Account ACH Information
        </Typography>
      </Box>

      <Box mb={10}>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Something went wrong, please try again.
        </Typography>
      </Box>

      <Button fullWidth onClick={nextStep}>
        <ButtonContent iconStart={SupportedIcons.Add}>
          Add Bank Account
        </ButtonContent>
      </Button>
    </>
  );

  const display = typeof bankAccount === 'undefined' ? error : content;

  return (
    <MobileView mobile={<Box p={2.5}>{display}</Box>}>
      <FormContainer>{display}</FormContainer>
    </MobileView>
  );
};
