import React from 'react';
import { Box } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import { SectionContainer, SectionTitle } from 'modules/dashboard/components';
import { useListScheduleTransfers } from 'modules/2024-q1/schedule-transfer/list-schedule-transfers/hooks/use-list-schedule-transfers';
import { ScheduleTransferTable } from 'modules/2024-q1/schedule-transfer/list-schedule-transfers/components/schedule-transfer-table/schedule-transfer-table';

export const DashboardListScheduleTransfers = () => {
  const { data: scheduleTransfers, isLoading: isLoadingScheduleTransfers } =
    useListScheduleTransfers();

  if (isLoadingScheduleTransfers) {
    return <Spinner />;
  }

  if (scheduleTransfers.length > 0) {
    return (
      <Box mb={10}>
        <Box mb={2}>
          <SectionTitle title="Scheduled Transfers" />
        </Box>

        <SectionContainer>
          <ScheduleTransferTable
            scheduleTransfers={scheduleTransfers}
            variant="DepositAndWithdrawal"
          />
        </SectionContainer>
      </Box>
    );
  }

  return null;
};
