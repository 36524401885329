const isDevEnv = ['development', 'local'].includes(process.env.NODE_ENV);

const noop = () => undefined;

export type DebugLogger = {
  log: typeof console.log | typeof noop;
  debug: typeof console.debug | typeof noop;
  warn: typeof console.warn | typeof noop;
  error: typeof console.error | typeof noop;
  info: typeof console.log | typeof noop;
};

export const debugLogger: DebugLogger = {
  log: isDevEnv ? console.log : noop,
  debug: isDevEnv ? console.debug : noop,
  warn: isDevEnv ? console.warn : noop,
  error: isDevEnv ? console.error : noop,
  info: isDevEnv ? console.info : noop,
};
