import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { Typography } from 'components/core';

interface StatusProps {
  status: string;
}

const Status = styled(Typography)<StatusProps>`
  color: var(--color-brown2);
  font-weight: 400;

  ${(props) => {
    if (props.status === 'CANCELED') {
      return css`
        color: var(--color-dallas);
      `;
    }
  }}

  ${(props) => {
    if (props.status === 'COMPLETED') {
      return css`
        color: var(--color-green2);
      `;
    }
  }}

  ${(props) => {
    if (props.status === 'FAILED') {
      return css`
        color: var(--color-lividBrown);
      `;
    }
  }}

  ${(props) => {
    if (props.status === 'PENDING' || props.status === 'IN_PROGRESS') {
      return css`
        color: var(--color-brown2);
      `;
    }
  }}
`;

const StatusContainer = styled(Box)<StatusProps>`
  background-color: var(--color-grey7);
  border-radius: var(--border-radius-05);
  max-width: 80px;

  ${(props) => {
    if (props.status === 'CANCELED') {
      return css`
        background-color: var(--color-albescentWhite);
      `;
    }
  }}

  ${(props) => {
    if (props.status === 'COMPLETED') {
      return css`
        background-color: var(--color-green7);
      `;
    }
  }}

  ${(props) => {
    if (props.status === 'FAILED') {
      return css`
        background-color: var(--color-bizarre);
      `;
    }
  }}

  ${(props) => {
    if (props.status === 'PENDING' || props.status === 'IN_PROGRESS') {
      return css`
        background-color: var(--color-brown6a);
      `;
    }
  }}
`;

export const Styled = {
  Status,
  StatusContainer,
};
