import React, { useEffect, useState } from 'react';
import { ICustomSendMFA } from 'modules/2023-q3/components/mfa/mfa';
import { useSendMFACode } from 'hooks/custodian/mfa/use-send-mfa-code';
import { useTracking, TrackEventName } from 'modules/tracking';
import { MFACodeEmailSent } from './components/mfa-code-email-sent/mfa-code-email-sent';
import { MFAResendCode } from './components/mfa-resend-code/mfa-resend-code';
import { MFAResendingCode } from './components/mfa-resending-code/mfa-resending-code';
import { MFATryAgain } from './components/mfa-try-again/mfa-try-again';

enum ResendMFACodeSteps {
  ResendCode,
  SendingCode,
  CodeSent,
  TryAgainTimer,
}

const timeToTryAgain = 150; // 2 mins, 30 seconds

interface ResendMFACodeProps {
  customSendMFA?: ICustomSendMFA;
}

export const ResendMFACode: React.FC<ResendMFACodeProps> = ({
  customSendMFA,
}) => {
  const { mutate: sendMFACode } = useSendMFACode();
  const { trackEvent } = useTracking();

  const [step, setStep] = useState(ResendMFACodeSteps.ResendCode);

  const onMFAError = (error: any) => {
    setStep(ResendMFACodeSteps.TryAgainTimer);

    trackEvent({
      eventName: TrackEventName.MFAResendCodeFailed,
      error,
    });
  };

  const onMFASuccess = () => {
    setStep(ResendMFACodeSteps.CodeSent);

    trackEvent({
      eventName: TrackEventName.MFAResendCodeSucceeded,
    });
  };

  const handleSendMFACode = () => {
    setStep(ResendMFACodeSteps.SendingCode);

    if (customSendMFA) {
      customSendMFA.mutationResult.mutate(customSendMFA.variables || {}, {
        onError: (error) => {
          onMFAError(error);
        },
        onSuccess: () => {
          onMFASuccess();
        },
      });
    } else {
      sendMFACode(
        {},
        {
          onError: (error) => {
            onMFAError(error);
          },
          onSuccess: () => {
            onMFASuccess();
          },
        },
      );
    }

    trackEvent({
      eventName: TrackEventName.MFAResendCodeInitiated,
    });
  };

  useEffect(() => {
    if (step === ResendMFACodeSteps.CodeSent) {
      const timer = setTimeout(() => {
        setStep(ResendMFACodeSteps.TryAgainTimer);
      }, 5 * 1000); // 5 seconds
      return () => clearTimeout(timer);
    }

    if (step === ResendMFACodeSteps.TryAgainTimer) {
      const timer = setTimeout(() => {
        setStep(ResendMFACodeSteps.ResendCode);
      }, timeToTryAgain * 1000);
      return () => clearTimeout(timer);
    }

    return;
  }, [step]);

  if (step === ResendMFACodeSteps.SendingCode) {
    return <MFAResendingCode />;
  }

  if (step === ResendMFACodeSteps.CodeSent) {
    return <MFACodeEmailSent />;
  }

  if (step === ResendMFACodeSteps.TryAgainTimer) {
    return (
      <MFATryAgain
        handleSendMFACode={handleSendMFACode}
        time={timeToTryAgain}
      />
    );
  }

  return <MFAResendCode handleSendMFACode={handleSendMFACode} />;
};
