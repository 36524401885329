import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// currency formatter
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface Props {
  data: any;
}

export const ComparisonChart: React.FC<Props> = ({ data }) => {
  return (
    <ResponsiveContainer width={1080} height={300}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 15,
          left: 70,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          domain={[
            (dataMin: any) =>
              dataMin - Math.abs(data[0].Treasure - data[0].Current),
            (dataMax: any) =>
              dataMax + Math.abs(data[0].Treasure - data[0].Current),
          ]}
          tickFormatter={(value) => formatter.format(value).slice(0, -3)}
        />
        <Tooltip formatter={(value: any) => formatter.format(value)} />
        <Legend />
        <Bar dataKey="Current" fill="#ACACAC" />
        <Bar dataKey="Treasure" fill="#335443" />
      </BarChart>
    </ResponsiveContainer>
  );
};
