import React, { useEffect, useState } from 'react';
import { useTranslation } from 'app/i18n';
import {
  i18nNamespaceMFAContent,
  ICustomSendMFA,
} from 'modules/2023-q3/components/mfa/mfa';
import { Box, Grid } from '@material-ui/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { MFACodeBoxes } from 'modules/2023-q3/components/mfa/components/mfa-code-boxes/mfa-code-boxes';
import { ResendMFACode } from 'modules/2023-q3/components/mfa/components/resend-mfa-code/resend-mfa-code';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { EnterVerificationCode } from './components/enter-verification-code/enter-verification-code';
import { RememberDevice } from './components/remember-device/remember-device';
import { VerificationFailed } from './components/verification-failed/verification-failed';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';

interface InputCodeProps {
  customSendMFA?: ICustomSendMFA;

  customVerificationMessage?: string;

  handleConfirmMFACode: (code: string[]) => void;

  mfaCode: string[];

  mfaError: boolean;

  mobile?: boolean;

  onPrevious: () => void;

  setMFACode: React.Dispatch<React.SetStateAction<string[]>>;

  setMFAError: React.Dispatch<React.SetStateAction<boolean>>;

  showRememberDeviceCheckbox?: boolean;

  setRememberDevice: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InputCode: React.FC<InputCodeProps> = ({
  customSendMFA,
  customVerificationMessage,
  handleConfirmMFACode,
  mfaCode,
  mfaError,
  mobile,
  onPrevious,
  setMFACode,
  setMFAError,
  showRememberDeviceCheckbox,
  setRememberDevice,
}) => {
  const { t } = useTranslation(i18nNamespaceMFAContent);

  const [disableVerifyButton, setDisableVerifyButton] = useState(true);

  const handleVerifyCode = () => {
    if (!disableVerifyButton) {
      handleConfirmMFACode(mfaCode);
    }
  };

  useEffect(() => {
    const mfaCodeHasEmptyInput = mfaCode.some((value) => !value);

    setDisableVerifyButton(mfaCodeHasEmptyInput);
  }, [mfaCode]);

  return (
    <Box textAlign="center">
      <Box mt={mobile ? 2 : 4}>
        <Box px={mobile ? 4 : 2}>
          {mfaError ? (
            <VerificationFailed />
          ) : (
            <EnterVerificationCode
              customVerificationMessage={customVerificationMessage}
            />
          )}
        </Box>

        <Box mt={mobile ? 5 : 3.5} mb={2.5}>
          <MFACodeBoxes
            handleVerifyCode={handleVerifyCode}
            mfaCode={mfaCode}
            mfaError={mfaError}
            setMFACode={setMFACode}
          />
        </Box>

        <ResendMFACode customSendMFA={customSendMFA} />

        {showRememberDeviceCheckbox && (
          <Box mt={5}>
            <RememberDevice setRememberDevice={setRememberDevice} />
          </Box>
        )}

        <MobileView
          mobile={
            <Button
              borderRadius={0}
              disabled={disableVerifyButton}
              fullWidth
              onClick={handleVerifyCode}
              bottomFixed
            >
              <ButtonContent iconEnd={SupportedIcons.Checkmark} mobile>
                {t(mfaError ? 'Retry Verification' : 'Verify')}
              </ButtonContent>
            </Button>
          }
        >
          <Box mt={showRememberDeviceCheckbox ? 5 : 10}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Button onClick={onPrevious} fullWidth>
                  <ButtonContent iconStart={SupportedIcons.ArrowLeft}>
                    {t('Previous')}
                  </ButtonContent>
                </Button>
              </Grid>

              <Grid item xs>
                <Button
                  disabled={disableVerifyButton}
                  fullWidth
                  onClick={handleVerifyCode}
                >
                  <ButtonContent iconEnd={SupportedIcons.Checkmark}>
                    {t(mfaError ? 'Retry Verification' : 'Verify')}
                  </ButtonContent>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </MobileView>
      </Box>
    </Box>
  );
};
