import React from 'react';
import { TypographyVariant } from 'components/core';
import { Styled } from './time-filter.style';

interface TimeFilterProps {
  children: React.ReactNode;

  selected: boolean;
}

export const TimeFilter: React.FC<TimeFilterProps> = ({
  children,
  selected,
}) => {
  return (
    <Styled.Container
      display="inline-block"
      mr={1}
      px={1.25}
      py={0.5}
      selected={selected}
    >
      <Styled.TimeFilterLabel
        color={selected ? 'white' : 'grey1'}
        variant={TypographyVariant.Paragraph2}
      >
        {children}
      </Styled.TimeFilterLabel>
    </Styled.Container>
  );
};
