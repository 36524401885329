import React from 'react';
import { ToggleButtonGroup } from '@material-ui/lab';
import { Typography, TypographyVariant } from 'components/core';
import { Styled } from './amount-percentage-toggle.style';

interface IAmountPercentageToggle {
  setShowEditPercentage: React.Dispatch<React.SetStateAction<boolean>>;
  showEditPercentage: boolean;
}

export const AmountPercentageToggle: React.FC<IAmountPercentageToggle> = ({
  setShowEditPercentage,
  showEditPercentage,
}) => {
  return (
    <ToggleButtonGroup value={showEditPercentage}>
      <Styled.ButtonToggle
        onClick={() => setShowEditPercentage(false)}
        value={false}
      >
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Edit Amount
        </Typography>
      </Styled.ButtonToggle>

      <Styled.ButtonToggle
        onClick={() => setShowEditPercentage(true)}
        value={true}
      >
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          Edit Percentage
        </Typography>
      </Styled.ButtonToggle>
    </ToggleButtonGroup>
  );
};
