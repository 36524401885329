import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { Container } from 'modules/2024-q1/allocation-by-amount-or-percentage/components/container/container';
import { FooterButton } from 'modules/2024-q1/allocation-by-amount-or-percentage/components/footer/footer';
import { Styled } from 'modules/2024-q1/allocation-by-amount-or-percentage/pages/form/form.style';

interface ConfirmationProps {
  from: { pathname: string };
}

export const Confirmation: React.FC<ConfirmationProps> = ({ from }) => {
  return (
    <Box maxWidth={720} mt={{ xs: 0, sm: 5 }} mx="auto">
      <Container>
        <Box p={{ xs: 0, sm: 3 }}>
          <Box mb={1.5}>
            <Typography color="black19" variant={TypographyVariant.Header3}>
              Allocation Saved
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              Great work. 👍 Your allocation request has been submitted.
            </Typography>
          </Box>

          <Box mb={1}>
            <Typography color="black19" variant={TypographyVariant.Header5}>
              Allocation Timeline
            </Typography>
          </Box>

          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            The allocation process may take up to 3 business days to complete.
            Please contact support if you have any questions.
          </Typography>

          <MobileView
            mobile={
              <Box
                bgcolor="white"
                position="fixed"
                bottom={0}
                left="0"
                right="0"
              >
                <Link to={from?.pathname || RoutesPath.pages.dashboard.path}>
                  <FooterButton>
                    <ButtonContent>Done</ButtonContent>
                  </FooterButton>
                </Link>
              </Box>
            }
          >
            <Box mt={25}>
              <Link to={from?.pathname || RoutesPath.pages.dashboard.path}>
                <Button fullWidth>
                  <ButtonContent>Done</ButtonContent>
                </Button>
              </Link>
            </Box>
          </MobileView>
        </Box>
      </Container>
    </Box>
  );
};
