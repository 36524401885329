import React from 'react';
import { Box } from '@material-ui/core';
import { Spinner } from 'components/core';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { Container } from 'modules/2023-q3/allocation/components/container/container';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { OnboardingSteps } from 'modules/onboarding/v3-streamlined-onboarding/enums';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { Header } from './components/header/header';
import { Styled } from './onboarding-task-style.style';

export const OnboardingTaskStyle: React.FC = ({ children }) => {
  const onboardingContext = useOnboardingContext();

  return (
    <>
      <Header
        previousStep={onboardingContext.previousStep}
        step={onboardingContext.currentStep}
      />

      {onboardingContext.isLoading && (
        <MobileView
          mobile={
            <Box
              bgcolor="white"
              position="absolute"
              top={122}
              right={0}
              bottom={0}
              left={0}
              zIndex={1}
            >
              <Spinner />
            </Box>
          }
        >
          <Box
            bgcolor="white"
            position="absolute"
            top={78}
            right={0}
            bottom={0}
            left={0}
            zIndex={1}
          >
            <Spinner />
          </Box>
        </MobileView>
      )}

      <Box visibility={onboardingContext.isLoading ? 'hidden' : 'visible'}>
        <CenteredContainer>
          <Box maxWidth={550} mt={{ xs: 0, sm: 5 }} mb={5} mx="auto">
            {onboardingContext.currentStep === OnboardingSteps.BankDetails ||
            onboardingContext.currentStep === OnboardingSteps.SweepFunds ? (
              children
            ) : (
              <Container>
                <Styled.OverrideBox p={{ sm: 2.5 }}>
                  {children}
                </Styled.OverrideBox>
              </Container>
            )}
          </Box>
        </CenteredContainer>
      </Box>
    </>
  );
};
