import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Button, Typography, TypographyVariant } from 'components/core';
import { PasswordFieldController } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/password';
import { useForm } from 'react-hook-form';
import firebase from 'utils/firebase';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { getTokenByEmail } from '../api/get-token-by-email';
import { Styled } from './set-password.style';

interface SetPasswordForm {
  password: string;
}
interface SetPasswordProps {
  email: string;

  onSuccessCallback?: () => void;
}

export const SetPassword: React.FC<SetPasswordProps> = ({
  email,
  onSuccessCallback,
}: SetPasswordProps) => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
    username: any;
  }>({
    page: TrackPageName.Password,
    username: email,
  });
  const { control, handleSubmit, watch } = useForm<SetPasswordForm>();
  const [emailToken, setEmailToken] = useState('');

  const password = watch('password', '');

  useEffect(() => {
    async function getFirebaseEmailAndResetToken() {
      try {
        const userEmail = email;
        if (userEmail) {
          getTokenByEmail(userEmail, (token: string) => {
            setEmailToken(token);
          });
        }
      } catch (error) {
        // console.error(error);
      }
    }
    getFirebaseEmailAndResetToken();
  }, [email]);

  const passwordReset = (token: string, resetPassword: string) => {
    return firebase.auth().confirmPasswordReset(token, resetPassword);
  };

  const signIn = (signInEmail: string, signInPassword: string) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(signInEmail, signInPassword);
  };

  const onSubmit = async (data: SetPasswordForm) => {
    try {
      trackEvent({
        eventName: TrackEventName.PasswordSet,
      });

      passwordReset(emailToken, data.password).then(() => {
        signIn(email, data.password).then(() => {
          if (onSuccessCallback && typeof onSuccessCallback === 'function') {
            onSuccessCallback();
          }
        });
      });
    } catch {
      //
    }
  };

  return (
    <Box mt={3} mx="auto" position="relative">
      <Styled.Container p={5}>
        <Box mb={3}>
          <Typography color="nero" variant={TypographyVariant.Header}>
            Set up your password
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(throttle(onSubmit, 1000))}>
          <PasswordFieldController
            control={control}
            label="Password"
            name="password"
          />

          <Box mt={5} textAlign="center">
            <Button type="submit" width={100}>
              <Typography color="white" variant={TypographyVariant.Body}>
                Set password
              </Typography>
            </Button>
          </Box>
        </form>
      </Styled.Container>

      <Styled.PasswordCriteriaCheck password={password} />
    </Box>
  );
};

SetPassword.propTypes = {
  onSuccessCallback: PropTypes.func,
};

SetPassword.defaultProps = {
  onSuccessCallback: () => {},
};
