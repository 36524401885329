import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { UserStatus } from 'modules/multi-user/enums/user-status';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Styled } from './user-row.style';

interface UserRowProps {
  user: {
    email: string;
    firstName: string;
    inviteStatus: UserStatus;
    lastName: string;
    role: UserRole;
  };
}

export const UserRow: React.FC<UserRowProps> = ({ user }) => {
  return (
    <Box mb={3}>
      <Grid container alignItems="center">
        <Grid item sm={1}>
          <Box mr={3}>
            <Styled.UserAvatar />
          </Box>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Typography variant={TypographyVariant.SubHeader1}>
            {`${user.firstName ? user.firstName : ''} ${
              user.lastName ? user.lastName : ''
            }`}
          </Typography>

          <Typography variant={TypographyVariant.SubHeader3}>
            {user.email}
          </Typography>
        </Grid>

        <FeatureFlag
          enabled={
            <Grid item xs={6} sm={2}>
              <Box textAlign="center">
                {user.role === UserRole.READ_ONLY
                  ? 'Read Only'
                  : 'Administrator'}
              </Box>
            </Grid>
          }
          flag={FeatureFlags.REACT_APP_2023_Q4_USER_ROLES_ENABLED}
        />

        <Grid item xs={6} sm={2}>
          <Box textAlign="center">
            {user.inviteStatus === UserStatus.Invited ? 'Invited' : 'Accepted'}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
