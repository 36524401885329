import React, { useState } from 'react';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { SweepType } from 'enums';
import { Box } from '@material-ui/core';
import { Spinner } from 'components/core';
import { TransferWidgetGrasshopper } from './components';
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { BankAccount } from 'types';
import { createCtx } from 'utils/create-ctx';
import { TransferSteps } from 'pages/v3-portal/transfer/enums/transfer-steps';
import { useLocation } from 'react-router-dom';
import { GHBWireDeposit } from './components/wire-deposit/ghb-wire-deposit';

// hooks
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { useBusiness } from 'hooks/business/use-business';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';

interface TransferContext {
  showWireDepositModal: boolean;
  setShowWireDepositModal: (show: boolean) => void;
  defaultAccountSelection: BankAccount | undefined;
  setDefaultAccountSelection: (account: BankAccount) => void;
  trackEvent: (event: any) => void;
  mfaSent: boolean;
  setMFASent: (show: boolean) => void;
  currentTransferStep: TransferSteps;
  setTransferStep: (step: TransferSteps) => void;
  transferDirection: SweepType | null;
}

export const [useTransferContext, TransferCtxProvider] =
  createCtx<TransferContext>();

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Transfer: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.Transfer,
  });

  const loadingContext = useLoadingContext();

  const { data: business } = useBusiness();
  const { data: accounts, isLoading: isLoadingBankAccounts } = useBankAccounts(
    business?.Id,
  );
  const { data: treasureReserve, isLoading: isLoadingTreasureReserve } =
    useTreasureReserve(business?.Id);

  const [isShowWireDepositModal, setShowWireDepositModal] = useState(false);

  const [mfaSent, setMFASent] = useState(false);
  const [defaultAccountSelection, setDefaultAccountSelection] =
    useState<BankAccount | undefined>(undefined);
  const [currentTransferStep, setTransferStep] = useState(
    TransferSteps.TransferSettings,
  );

  const renderGrasshopperFlow = () => {
    return (
      <>
        <Box pt={8}>
          {treasureReserve && (
            <TransferWidgetGrasshopper
              accounts={accounts}
              treasureReserve={treasureReserve}
            />
          )}
        </Box>
        <Box py={4}>
          <GHBWireDeposit />
        </Box>
      </>
    );
  };

  if (
    loadingContext.loading ||
    isLoadingBankAccounts ||
    isLoadingTreasureReserve
  ) {
    return <Spinner />;
  }

  return (
    <TransferCtxProvider
      value={{
        showWireDepositModal: isShowWireDepositModal,
        setShowWireDepositModal,
        defaultAccountSelection,
        setDefaultAccountSelection,
        trackEvent,
        mfaSent,
        setMFASent,
        currentTransferStep,
        setTransferStep,
        transferDirection: null,
      }}
    >
      <Box display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column" width={375}>
          {renderGrasshopperFlow()}
        </Box>
      </Box>
    </TransferCtxProvider>
  );
};

export { Transfer };
