import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface AuthenticationState {
  isSignedIn: boolean;
  signIn: () => void;
  signOut: () => void;
}

export const useStoreAuthentication = create<AuthenticationState>()(
  devtools((set) => ({
    isSignedIn: false,
    signIn: () => set((state) => ({ isSignedIn: true })),
    signOut: () => set((state) => ({ isSignedIn: false })),
  })),
);
