import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { SweepType } from 'enums';

export const useSweep = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const postSweep = async ({
    amount,
    bankAccountId,
    businessId,
    direction = SweepType.Deposit,
    idempotencyKey,
  }: {
    amount: number;
    bankAccountId: string;
    businessId: string;
    direction?: SweepType | null;
    idempotencyKey: string;
  }) => {
    const endpoint =
      direction === SweepType.Deposit ? '/sweep/deposit' : '/sweep/withdrawal';

    const response = await treasureApi().post(endpoint, {
      amount: amount * 100, // api expects the amount to be in cents
      bankAccountId,
      idempotencyKey,
    });

    response.data.amount /= 100; // api returns the amount in cents

    return response.data;
  };

  return useMutation(postSweep);
};
