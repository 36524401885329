import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';
import { ReactQueryMutationKeys } from 'enums';

export const useIsApiUser = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const isApiUser = async ({ email }: { email: string }) => {
    const { data } = await treasureApi().post(
      '/internal/people/api-customer/email',
      {
        email,
      },
    );

    return data;
  };

  return useMutation(isApiUser, {
    mutationKey: ReactQueryMutationKeys.IsApiUser, // this mutation key is used by react-query-error-toasts so it will never show a user an error
  });
};
