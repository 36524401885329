import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';

interface Props {
  allocationPercentage: number;
  allocationValue: number;
  productName: string;
}

export const PortfolioAllocationListItem: React.FC<Props> = ({
  allocationPercentage,
  allocationValue,
  productName,
}) => {
  return (
    <>
      <Box mb={1.5}>
        <Typography color="grey4" variant={TypographyVariant.Paragraph2}>
          {productName}
        </Typography>
      </Box>

      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            <Percentage
              decimalScale={2}
              number={allocationPercentage}
              showExtremePrecision
            />
          </Typography>
        </Grid>

        <Grid item>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            <Currency number={allocationValue} variant={CurrencyVariant.Full} />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
