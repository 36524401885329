import React, { useState } from 'react';
import { dayjs } from 'app/dayjs';
import { Box, Collapse } from '@material-ui/core';
import { Currency, CurrencyVariant } from 'components/core';
import {
  ColumnType,
  TimeFilterOption,
  isDateInTimeFilter,
  wrapTableText,
} from 'modules/dashboard/components';
import { TableBody } from 'modules/dashboard/components/dashboard-table/components/table-body/table-body';
import { TableHeader } from 'modules/dashboard/components/dashboard-table/components/table-header/table-header';
import { useTracking, TrackEventName } from 'modules/tracking';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';

// TODO: take divider out into a common component or styled file
import { Styled } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-table/product-table.style';

let columnsReturnsTable: ColumnType[] = [
  { name: 'Time Period', size: 6 },
  { alignment: 'center', name: 'Total Return', size: 2 },
  { alignment: 'center', name: 'Cash Return', size: 2 },
  { alignment: 'center', name: 'Managed Return', size: 2 },
];

enum MonthlyReturnType {
  Cash = 'cash',
  Fee = 'fee',
  Investments = 'investments',
}

export interface MonthlyReturn {
  date: string;
  [MonthlyReturnType.Cash]: {
    totalReturns: number;
  };
  [MonthlyReturnType.Investments]: {
    totalReturns: number;
  };
  [MonthlyReturnType.Fee]: {
    totalReturns: number;
  };
}

const getTimeFilterTimePeriodText = (timeFilter: TimeFilterOption) => {
  switch (timeFilter) {
    case TimeFilterOption.THREE_MONTH:
      return 'Past 3 Months';
    case TimeFilterOption.SIX_MONTH:
      return 'Past 6 Months';
    case TimeFilterOption.TWELVE_MONTH:
      return 'Past 12 Months';
    case TimeFilterOption.ALL:
      return 'All Time';
  }

  return null;
};

const getTimeFilterTotalReturns = (
  data: MonthlyReturn[],
  timeFilter: TimeFilterOption,
  property:
    | MonthlyReturnType.Cash
    | MonthlyReturnType.Fee
    | MonthlyReturnType.Investments,
) => {
  // filter out dates
  // because we're not showing the current month, we need to shift 1 month
  return data
    ?.filter((monthlyReturn: MonthlyReturn) =>
      isDateInTimeFilter(
        dayjs(monthlyReturn.date).add(1, 'month').format('YYYY-MM-DD'),
        timeFilter,
      ),
    )
    .reduce(
      (sum: number, a: MonthlyReturn) =>
        sum + Number(a[property].totalReturns || 0),
      0,
    );
};

const showDashInsteadOfZero = (number: number) => {
  if (number > 0) {
    return <Currency number={number} variant={CurrencyVariant.Full} />;
  }

  return '-';
};

interface ReturnsTableProps {
  data: MonthlyReturn[];

  timeFilter: TimeFilterOption;
}

export const ReturnsTable: React.FC<ReturnsTableProps> = ({
  data,
  timeFilter,
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isShowFeesEnabled = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2024_Q2_SHOW_FEES,
  );

  if (isShowFeesEnabled) {
    columnsReturnsTable = [
      { name: 'Time Period', size: 4 },
      { alignment: 'center', name: 'Total Return', size: 2 },
      { alignment: 'center', name: 'Cash Return', size: 2 },
      { alignment: 'center', name: 'Managed Return', size: 2 },
      { alignment: 'center', name: 'Fee', size: 2 },
    ];
  }

  const { trackEvent } = useTracking<{
    clickText: string;
    component: string;
    eventName: TrackEventName;
  }>({
    component: 'Return Table',
  });

  const handleExpandClick = () => {
    setCollapsed(!collapsed);

    trackEvent({
      clickText: 'Collapse/Expand Data',
      eventName: TrackEventName.Click,
    });
  };

  const [collapsed, setCollapsed] = useState(false);

  const tableData = [];

  const sortedData = data?.sort(
    (a: MonthlyReturn, b: MonthlyReturn) =>
      Number(new Date(b.date)) - Number(new Date(a.date)),
  );

  if (
    timeFilter === TimeFilterOption.THREE_MONTH ||
    timeFilter === TimeFilterOption.SIX_MONTH ||
    timeFilter === TimeFilterOption.TWELVE_MONTH ||
    timeFilter === TimeFilterOption.ALL
  ) {
    const cashRollup = getTimeFilterTotalReturns(
      sortedData,
      timeFilter,
      MonthlyReturnType.Cash,
    );
    const investmentsRollup = getTimeFilterTotalReturns(
      sortedData,
      timeFilter,
      MonthlyReturnType.Investments,
    );
    const feeRollup = getTimeFilterTotalReturns(
      sortedData,
      timeFilter,
      MonthlyReturnType.Fee,
    );

    const totalRollup = cashRollup + investmentsRollup;

    const row = [
      wrapTableText(getTimeFilterTimePeriodText(timeFilter)),
      wrapTableText(showDashInsteadOfZero(totalRollup)),
      wrapTableText(showDashInsteadOfZero(cashRollup)),
      wrapTableText(showDashInsteadOfZero(investmentsRollup)),
    ];

    if (isShowFeesEnabled) {
      row.push(wrapTableText(showDashInsteadOfZero(feeRollup)));
    }

    tableData.push(row);
  }

  sortedData?.map((monthlyReturn: MonthlyReturn, index: number) => {
    // filter out dates
    // because we're not showing the current month, we need to shift 1 month
    if (
      !isDateInTimeFilter(
        dayjs(monthlyReturn.date).add(1, 'month').format('YYYY-MM-DD'),
        timeFilter,
      )
    ) {
      return;
    }

    const row = [
      wrapTableText(dayjs(monthlyReturn.date).format('MMMM YYYY')),
      wrapTableText(
        showDashInsteadOfZero(
          monthlyReturn.cash.totalReturns +
            monthlyReturn.investments.totalReturns,
        ),
      ),
      wrapTableText(showDashInsteadOfZero(monthlyReturn.cash.totalReturns)),
      wrapTableText(
        showDashInsteadOfZero(monthlyReturn.investments.totalReturns),
      ),
    ];

    if (isShowFeesEnabled) {
      row.push(
        wrapTableText(showDashInsteadOfZero(monthlyReturn.fee.totalReturns)),
      );
    }

    tableData.push(row);
  });
  const showCollapsed = tableData.length > 5;

  return (
    <>
      <TableHeader columns={columnsReturnsTable} />

      <TableBody
        columns={columnsReturnsTable}
        data={showCollapsed ? tableData.slice(0, 2) : tableData}
      />

      {showCollapsed && (
        <>
          <Collapse in={collapsed}>
            <TableBody
              columns={columnsReturnsTable}
              data={tableData.slice(2)}
            />
          </Collapse>

          <Styled.Divider />

          <Box textAlign="center">
            <Styled.CollapseActionBox
              display="inline-block"
              mt={3}
              onClick={handleExpandClick}
            >
              {collapsed ? 'Collapse' : 'Expand'} Data{' '}
              <Box display="inline-block" ml={1} position="relative" top={-2}>
                <Styled.AccordionIcon collapsed={collapsed} />
              </Box>
            </Styled.CollapseActionBox>
          </Box>
        </>
      )}
    </>
  );
};
