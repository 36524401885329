import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { useForm } from 'react-hook-form';
import { useTracking, TrackEventName } from 'modules/tracking';

import { ManualBankAccount } from 'types';
import { useManualBankAccountConnect } from './hooks/use-manual-bank-account-connect';

import {
  AutocompleteController,
  TextFieldController,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { formErrorFocus } from 'modules/onboarding/v3-streamlined-onboarding/utils';

import { formFields, BankAccountType } from './manual-ach-form-fields';

const gridContainerSpacing = 3;
const gridItemSize = 12;

export const ManualACHForm: React.FC = () => {
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();

  const onboardingContext = useOnboardingContext();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<ManualBankAccount>();
  const manualBankAccountConnect = useManualBankAccountConnect();

  useEffect(() => {
    formErrorFocus(errors, isDirty);
  }, [errors, isDirty]);

  const onSubmit = (data: ManualBankAccount) => {
    data.accountType = data.accountType.toLowerCase();
    manualBankAccountConnect.mutate(data, {
      onSuccess: () => {
        onboardingContext.nextStep();
      },
    });

    trackEvent({
      eventName: TrackEventName.ManualACHFormCompleted,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={gridContainerSpacing}>
        <Grid item xs={gridItemSize}>
          <TextFieldController {...formFields.bankName} control={control} />
        </Grid>

        <Grid item xs={gridItemSize}>
          <TextFieldController
            {...formFields.routingNumber}
            control={control}
          />
        </Grid>

        <Grid item xs={gridItemSize}>
          <TextFieldController
            {...formFields.accountNumber}
            control={control}
          />
        </Grid>

        <Grid item xs={gridItemSize}>
          <AutocompleteController
            {...formFields.accountType}
            control={control}
          />
        </Grid>
      </Grid>

      <Box mt={5}>
        <Button fullWidth type="submit">
          <ButtonContent>
            <Typography variant={TypographyVariant.Body} color="white">
              Connect account
            </Typography>
          </ButtonContent>
        </Button>
      </Box>
    </form>
  );
};
