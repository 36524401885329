import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  ProductLabel,
  ProductLabelVariant,
} from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-table/components/product-label/product-label';
import { ReactComponent as LineGraph } from 'assets/icons/legend/line-graph.svg';

export const ReturnsChartKey = () => {
  return (
    <Grid alignItems="center" container justifyContent="center" spacing={5}>
      <Grid item>
        <ProductLabel
          icon={<LineGraph />}
          name="Total Balance"
          helperText="Available Balance + Pending Balance"
          variant={ProductLabelVariant.Vertical}
        />
      </Grid>

      <Grid item>
        <ProductLabel
          color="brown4a"
          name="Treasure Cash Return"
          helperText="Shown in the month it was accrued"
          variant={ProductLabelVariant.Vertical}
        />
      </Grid>

      <Grid item>
        <ProductLabel
          color="primary"
          name="Treasure Managed Return"
          helperText="Includes dividends reinvested and market movements"
          variant={ProductLabelVariant.Vertical}
        />
      </Grid>
    </Grid>
  );
};
