import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { NotificationContainer } from 'modules/2023-q3/banking-page/v2/components/bank-accounts/components/bank/components/bank-plaid-error/components/notification-container/notification-container';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';

export const AttentionNotification = () => {
  return (
    <NotificationContainer>
      <Box ml={2} textAlign="justify">
        <Typography color="grey1" variant={TypographyVariant.Header4}>
          We have made the decision to discontinue our Treasure Cash product,
          effective January 16th, 2025. You can find more details in your email.
          Please let us know if you have any questions by emailing{' '}
          <ContactTreasureSupportLink subject="Treasure Cash">
            support@treasure.tech
          </ContactTreasureSupportLink>
          .
        </Typography>
      </Box>
    </NotificationContainer>
  );
};
