import React from 'react';
import { useHistory } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { SetPassword } from './set-password';

interface Props {
  email: string;
}

export const SetPasswordContainer: React.FC<Props> = ({ email }) => {
  const history = useHistory();

  return (
    <SetPassword
      email={email}
      onSuccessCallback={() => {
        // This needs to be wrapped in setTimeout so that it goes to the end of the event queue (and allows for the loggedin state to be set)
        setTimeout(() => {
          history.push(RoutesPath.onboarding.accountIntake.path);
        }, 0);
      }}
    />
  );
};
