import React, { useEffect, useState } from 'react';
import { dayjs } from 'app/dayjs';
import { Box, Collapse } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Pagination,
  Spinner,
} from 'components/core';
import { ColumnType, wrapTableText } from 'modules/dashboard/components';
import { TableBody } from 'modules/dashboard/components/dashboard-table/components/table-body/table-body';
import { TableHeader } from 'modules/dashboard/components/dashboard-table/components/table-header/table-header';
import { BankAccount } from 'modules/2023-q3/banking-page/v2/types/bank-account';
import { useTracking, TrackEventName } from 'modules/tracking';
import { ResponsiveScrollContent } from 'modules/dashboard/components/responsive-scroll-content/responsive-scroll-content';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { useBankAccountTransactions } from './hooks/use-bank-account-transactions';

// TODO: take divider out into a common component or styled file
import { Styled } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-table/product-table.style';

const columnsTransactions: ColumnType[] = [
  { name: 'Date', size: 3 },
  { name: 'Description', size: 4 },
  { name: 'Category', size: 3 },
  { alignment: 'right', name: 'Amount', size: 2 },
];

type TranscationTableData = [
  React.ReactElement,
  React.ReactElement,
  React.ReactElement,
  React.ReactElement,
];

interface Transaction {
  Amount: string;
  CategoryId: string;
  Currency: string;
  Description: string;
  TransactionDate: string;
}

interface TransactionsTableProps {
  bankAccount: BankAccount;
}

export const BankAccountTransactions: React.FC<TransactionsTableProps> = ({
  bankAccount,
}) => {
  const { trackEvent } = useTracking<{
    clickText: string;
    component: string;
    eventName: TrackEventName;
  }>({
    component: 'Bank Account Transactions',
  });

  const [collapsed, setCollapsed] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [page, setPage] = useState(1);

  const { data: transactions, isLoading } = useBankAccountTransactions({
    bankAccountId: bankAccount.AccountId,
    page,
    pageSize: 8,
  });

  const totalNumberOfTransactions = transactions?.pagination.totals.data;

  const handleExpandClick = () => {
    setCollapsed(!collapsed);

    trackEvent({
      clickText: 'Collapse/Expand Transactions',
      eventName: TrackEventName.Click,
    });
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const tableData: TranscationTableData[] = [];

  transactions?.data.map((transaction: Transaction) => {
    return tableData.push([
      wrapTableText(dayjs(transaction.TransactionDate).format('MMM D, YYYY')),
      wrapTableText(transaction.Description),
      wrapTableText(transaction.CategoryId.replaceAll(',', ', ')),
      wrapTableText(
        <Currency
          number={+transaction.Amount}
          variant={CurrencyVariant.Full}
        />,
      ),
    ]);
  });

  const collapsedTransactionsToShow = 2;
  const showCollapsed = totalNumberOfTransactions > collapsedTransactionsToShow;

  useEffect(() => {
    if (initialLoad && totalNumberOfTransactions > 0) {
      setInitialLoad(false);
    }
  }, [transactions]);

  if (initialLoad || totalNumberOfTransactions === 0) {
    return null;
  }

  return (
    <Box mt={5}>
      <ResponsiveScrollContent firstColumnMinWidth={100} widthToSubtract={66}>
        <Box width="100%">
          <TableHeader columns={columnsTransactions} />

          {isLoading && (
            <Box p={10}>
              <Spinner />
            </Box>
          )}

          <TableBody
            columns={columnsTransactions}
            data={
              showCollapsed
                ? tableData.slice(0, collapsedTransactionsToShow)
                : tableData
            }
          />

          {showCollapsed && (
            <Collapse in={collapsed}>
              <TableBody
                columns={columnsTransactions}
                data={tableData.slice(collapsedTransactionsToShow)}
              />
            </Collapse>
          )}
        </Box>
      </ResponsiveScrollContent>

      {showCollapsed && (
        <>
          {collapsed && (
            <Box my={1}>
              <MobileView
                mobile={
                  <Pagination
                    boundaryCount={1}
                    count={transactions?.pagination.totals.pages}
                    onChange={handlePaginationChange}
                    page={page}
                    siblingCount={0}
                  />
                }
              >
                <Pagination
                  count={transactions?.pagination.totals.pages}
                  onChange={handlePaginationChange}
                  page={page}
                />
              </MobileView>
            </Box>
          )}

          <Styled.Divider />

          <Box textAlign="center">
            <Styled.CollapseActionBox
              display="inline-block"
              mt={1}
              onClick={handleExpandClick}
            >
              {collapsed ? 'Collapse' : 'Expand'} Transactions{' '}
              <Box display="inline-block" ml={1} position="relative" top={-2}>
                <Styled.AccordionIcon collapsed={collapsed} />
              </Box>
            </Styled.CollapseActionBox>
          </Box>
        </>
      )}
    </Box>
  );
};
