import React from 'react';
import { Box } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';
import { CustodianStatuses } from 'enums';
import {
  NotificationBox,
  NotificationBoxVariant,
} from 'modules/portfolio/allocation/components/allocation-modal/components';
import { i18n, Trans } from 'app/i18n';
import { Link } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { useTracking, TrackEventName } from 'modules/tracking';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useCashAccount } from 'hooks/portal-gateway/use-cash-account';
import { useDashboardActivity } from 'hooks/banking/use-dashboard-activity';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { PortfolioState } from 'modules/portfolio/enums/portfolio-state';
import Content from './portfolio-allocation.content.json';

i18n.addResourceBundle('en', 'portfolio-allocation', Content);

interface AllocationNotificationProps {
  returnPercentage: number;
}

export const AllocationNotification: React.FC<AllocationNotificationProps> = ({
  returnPercentage,
}) => {
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();

  const { data: business } = useBusiness();
  const { data: cashAccount } = useCashAccount(business?.Id);
  const { data: dashboardActivity, isLoading: isLoadingDashboardActivity } =
    useDashboardActivity();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );

  const accountOpen = cashAccount?.status === CustodianStatuses.AccountOpen;
  const portfolioPending =
    portfolios?.portfolios[0]?.state === PortfolioState.Pending;
  const nameSpace = 'portfolio-allocation';

  // sum of pending deposits
  const pendingBalance = dashboardActivity?.transactions.reduce(
    (a: number, b: { accountType: string; amount: string; status: string }) =>
      a +
      (b.accountType === 'depository' && b.status === 'PENDING'
        ? Number(b.amount)
        : 0),
    0,
  );

  if (isLoadingDashboardActivity || isLoadingPortfolios) {
    return null;
  }

  if (accountOpen && pendingBalance <= 0 && !portfolioPending) {
    return null;
  }

  let message;
  if (!accountOpen && pendingBalance === 0) {
    message = 'account set up no funds';
  } else if (!accountOpen) {
    message = 'account_not_opened_after_portfolio_setup_alert';
  } else if (pendingBalance > 0) {
    message = 'funds_on_way_alert_after_portfolio_setup_alert';
  } else if (portfolioPending) {
    message = 'investment_pending_execution_after_portfolio_setup_alert';
  }

  if (message) {
    return (
      <Box mb={portfolioPending ? 1 : 4.75}>
        <NotificationBox variant={NotificationBoxVariant.Information}>
          <Typography variant={TypographyVariant.Body}>
            <strong>
              <Trans
                i18nKey={message}
                ns={nameSpace}
                components={{
                  bankingLink: (
                    <Link
                      onClick={() =>
                        trackEvent({
                          eventName: TrackEventName.BankingDashboardLinkClicked,
                        })
                      }
                      to={getVersionedPath({
                        path: RoutesPath.pages.banking.path,
                        version: 3,
                      })}
                    />
                  ),
                  pendingBalance: (
                    <Currency
                      number={pendingBalance}
                      variant={CurrencyVariant.Full}
                    />
                  ),
                  percentage: (
                    <Percentage number={returnPercentage} decimalScale={2} />
                  ),
                  transferFundsLink: (
                    <FeatureFlag
                      disabled={
                        <Link
                          onClick={() =>
                            trackEvent({
                              eventName:
                                TrackEventName.TransferFundsButtonClicked,
                            })
                          }
                          to={getVersionedPath({
                            path: RoutesPath.pages.transfer.path,
                            version: 3,
                          })}
                        >
                          Transfer funds
                        </Link>
                      }
                      enabled={
                        <Link
                          onClick={() =>
                            trackEvent({
                              eventName:
                                TrackEventName.TransferFundsButtonClicked,
                            })
                          }
                          to={RoutesPath.tasks.deposit.path}
                        >
                          Transfer funds
                        </Link>
                      }
                      flag={
                        FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED
                      }
                    />
                  ),
                }}
              />
            </strong>
          </Typography>
        </NotificationBox>
      </Box>
    );
  }

  return null;
};
