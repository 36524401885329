import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { BlankLayout, NoAuthLayout, V2Layout, V3BlankLayout } from 'layouts';
import {
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignInAccept,
} from 'pages/no-auth';
import { SignUp } from 'pages/onboarding';
import { StreamlinedOnboardingToken } from 'modules/onboarding/v3-streamlined-onboarding/no-auth';
import { TrackPageName } from 'modules/tracking';
import { SignUpTransition } from 'modules/2023-q4/no-auth/sign-up/sign-up-transition';
import { WhiteLabelPartnerLanding } from 'modules/2023-q4/white-label/white-label-partner-landing/white-label-partner-landing';
import { DocumentsTransition } from 'modules/2023-q4/documents/documents-transition';

export default [
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.onboarding.accountIntakeToken.path,
      version: 3,
    }),
    layout: BlankLayout,
    component: StreamlinedOnboardingToken,
    title: TrackPageName.GetStarted,
  },
  {
    exact: true,
    path: RoutesPath.noAuth.signIn.path,
    layout: NoAuthLayout,
    component: SignIn,
    title: TrackPageName.SignIn,
  },
  {
    exact: true,
    path: RoutesPath.noAuth.signInAccept.path,
    layout: BlankLayout,
    component: SignInAccept,
    title: 'Sign In',
  },
  {
    exact: true,
    path: RoutesPath.noAuth.forgotPassword.path,
    layout: NoAuthLayout,
    component: ForgotPassword,
    title: TrackPageName.ForgotPassword,
  },
  {
    exact: true,
    path: RoutesPath.noAuth.resetPassword.path,
    layout: NoAuthLayout,
    component: ResetPassword,
    title: TrackPageName.ResetPassword,
  },
  {
    exact: true,
    path: RoutesPath.noAuth.signUp.path,
    layout: V3BlankLayout,
    component: SignUpTransition,
    title: TrackPageName.SignUp,
  },
  {
    exact: true,
    path: RoutesPath.noAuth.signUpWithAffCode.path,
    layout: V3BlankLayout,
    component: SignUpTransition,
    title: TrackPageName.SignUp,
  },
  {
    exact: true,
    path: RoutesPath.whiteLabel.landing.path,
    layout: V3BlankLayout,
    component: WhiteLabelPartnerLanding,
  },
  {
    exact: true,
    path: RoutesPath.whiteLabel.signUp.path,
    layout: V3BlankLayout,
    component: WhiteLabelPartnerLanding,
  },
  {
    exact: true,
    path: RoutesPath.noAuth.documents.path,
    layout: V3BlankLayout,
    component: DocumentsTransition,
    title: TrackPageName.Documents,
  },
];
