import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
  YieldChip,
} from 'components/core';
import { Footnote } from 'components/layouts';
import { LineChartByPeriod } from 'components/charts/v3';
import { createCtx } from 'utils/create-ctx';
import { Styled } from './product-yield.style';
import { useTranslation } from 'app/i18n';

enum ProductType {
  cash = 'cash',
  highYield = 'highYield',
  cashAndHighYield = 'cashAndHighYield',
}

const ProductTypeMap = {
  [ProductType.cash]: 'Cash',
  [ProductType.highYield]: 'Securities',
  [ProductType.cashAndHighYield]: 'Total',
};

interface ProductYieldItem {
  date: string;
  cash: number;
  highYield: number;
  cashAndHighYield: number;
}

interface ProductYield {
  noCashEarnings: boolean;
  noHighYieldEarnings: boolean;
  data: ProductYieldItem[];
}

interface EarningRates {
  [key: string]: number;
}

interface ProductYieldData {
  earningRates: EarningRates;
  lastUpdated: string;
  earnings: ProductYield;
  comparisonEarnings: ProductYield;
}

interface ProductYieldProps {
  className?: string;
  data: ProductYieldData;
  type: ProductType;
}

interface ProductYieldContext {
  totalEarningsForPeriod: number;
  setTotalEarningsForPeriod: (totalEarningsForPeriod: number) => void;
  totalEarningsForComparisonPeriod: number;
  setTotalEarningsForComparisonPeriod: (totalEarningsForPeriod: number) => void;
  resetTotalEarningsForPeriod: () => void;
}

const [useProductYieldContext, ProductYieldCtxProvider] =
  createCtx<ProductYieldContext>();

const getChartData = (data: ProductYield, type: ProductType) => {
  const earnings = data.data;
  let chartData: any[] = [];
  if (getHasEarningsForType(data, type)) {
    chartData = earnings.map((earning: ProductYieldItem) => ({
      date: earning.date,
      value: earning[type],
    }));
  }
  return chartData;
};

const getTotalEarningsForPeriod = (data: ProductYield, type: ProductType) => {
  const earnings = data.data;
  if (earnings.length) {
    const lastEarningForPeriod = earnings[earnings.length - 1];
    return lastEarningForPeriod[type];
  }

  return 0;
};

const getHasEarningsForType = (data: ProductYield, type: ProductType) => {
  if (type === ProductType.cash) {
    return !data.noCashEarnings;
  }
  if (type === ProductType.highYield) {
    return !data.noHighYieldEarnings;
  }
  if (type === ProductType.cashAndHighYield) {
    return !data.noCashEarnings || !data.noHighYieldEarnings;
  }

  return false;
};

const ProductYield: React.FC<ProductYieldProps> = ({
  className,
  data,
  type,
}) => {
  const { t } = useTranslation();

  const hasEarnings = getHasEarningsForType(data.earnings, type);
  const hasComparisonEarnings = getHasEarningsForType(
    data.comparisonEarnings,
    type,
  );
  const defaultTotalEarningsForPeriod = getTotalEarningsForPeriod(
    data.earnings,
    type,
  );
  const [totalEarningsForPeriod, setTotalEarningsForPeriod] = useState(
    defaultTotalEarningsForPeriod,
  );
  const defaultTotalEarningsForComparisonPeriod = getTotalEarningsForPeriod(
    data.comparisonEarnings,
    type,
  );
  const [
    totalEarningsForComparisonPeriod,
    setTotalEarningsForComparisonPeriod,
  ] = useState(defaultTotalEarningsForComparisonPeriod);

  const resetTotalEarningsForPeriod = () => {
    setTotalEarningsForPeriod(defaultTotalEarningsForPeriod);
    setTotalEarningsForComparisonPeriod(
      defaultTotalEarningsForComparisonPeriod,
    );
  };

  return (
    <ProductYieldCtxProvider
      value={{
        totalEarningsForPeriod,
        setTotalEarningsForPeriod,
        totalEarningsForComparisonPeriod,
        setTotalEarningsForComparisonPeriod,
        resetTotalEarningsForPeriod,
      }}
    >
      <Styled.ProductYieldWidget
        className={className}
        variant={type}
        hasEarnings={hasEarnings}
      >
        <Box display="flex">
          <Box display="flex" flexDirection="column" flex="1">
            <Box width="100%">
              <Typography variant={TypographyVariant.Body}>
                {type === ProductType.cash ? (
                  <Footnote
                    description={t('cash earnings disclaimer', {
                      ns: 'compliance',
                    })}
                    symbol="*"
                  >
                    {ProductTypeMap[type]} earnings
                  </Footnote>
                ) : (
                  <>{ProductTypeMap[type]} earnings</>
                )}
              </Typography>

              {hasEarnings && data.earningRates?.[type] ? (
                <Box display="inline" ml={1.25}>
                  <YieldChip percentageYield={data.earningRates[type]} />
                </Box>
              ) : (
                ''
              )}
            </Box>
            <Box display="flex" width="100%">
              <Styled.EarningsAmount variant={TypographyVariant.Body}>
                {hasEarnings ? (
                  <Currency
                    number={totalEarningsForPeriod}
                    variant={CurrencyVariant.Full}
                  />
                ) : (
                  '--'
                )}
              </Styled.EarningsAmount>
              {hasComparisonEarnings ? (
                <Box display="flex" flex="1" justifyContent="flex-end">
                  <Styled.Amount color="jumbo" variant={TypographyVariant.Body}>
                    <Currency
                      number={totalEarningsForComparisonPeriod}
                      variant={CurrencyVariant.Full}
                    />
                  </Styled.Amount>
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
        <LineChartByPeriod
          highlightedColor={
            type === ProductType.cashAndHighYield ? 'hanPurple' : 'nero'
          }
          data={getChartData(data.earnings, type)}
          comparisonData={getChartData(data.comparisonEarnings, type)}
        />
      </Styled.ProductYieldWidget>
    </ProductYieldCtxProvider>
  );
};

ProductYield.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    earningRates: PropTypes.shape({
      cash: PropTypes.number.isRequired,
      highYield: PropTypes.number.isRequired,
      cashAndHighYield: PropTypes.number.isRequired,
    }).isRequired,
    lastUpdated: PropTypes.string.isRequired,
    earnings: PropTypes.shape({
      noCashEarnings: PropTypes.bool.isRequired,
      noHighYieldEarnings: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          cash: PropTypes.number.isRequired,
          highYield: PropTypes.number.isRequired,
          cashAndHighYield: PropTypes.number.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    comparisonEarnings: PropTypes.shape({
      noCashEarnings: PropTypes.bool.isRequired,
      noHighYieldEarnings: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          cash: PropTypes.number.isRequired,
          highYield: PropTypes.number.isRequired,
          cashAndHighYield: PropTypes.number.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
  type: PropTypes.oneOf([...Object.values(ProductType)]).isRequired,
};

export {
  ProductYield,
  ProductType,
  useProductYieldContext,
  ProductYieldCtxProvider,
};
