import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { BankAccount } from 'types';
import { PlaidAccountStatus } from 'enums';
import { NotificationContainer } from 'modules/2023-q3/banking-page/v2/components/bank-accounts/components/bank/components/bank-plaid-error/components/notification-container/notification-container';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconXCircleGray } from 'assets/icons/checkmarks/x-circle-gray.svg';
import { useStorePlaidBankError } from 'stores';

export const BankAccountsPlaidErrors = () => {
  const { data: business } = useBusiness();
  const { data: accounts } = useBankAccounts(business?.Id);

  const { showError, hideError } = useStorePlaidBankError((state) => state);

  // check if any plaid account has an AUTH_ERROR
  const hasPlaidAuthError = accounts?.find(
    (account: BankAccount) =>
      account.PlaidStatus === PlaidAccountStatus.AUTH_ERROR,
  );

  // check if any plaid account's data is stale and hasn't just been re-authed (PENDING_READY).
  const isPlaidDataStale = accounts?.find(
    (account: BankAccount) =>
      account.isPlaidDataStale &&
      account.PlaidStatus !== PlaidAccountStatus.PENDING_READY,
  );

  const handleCloseNotification = () => {
    hideError();
  };

  if ((hasPlaidAuthError || isPlaidDataStale) && showError) {
    return (
      <Box mb={5} textAlign="center">
        <NotificationContainer display="inline-block" position="relative">
          <SVGBox
            cursor="pointer"
            position="absolute"
            top={-15}
            right={-10}
            width={20}
          >
            <IconXCircleGray onClick={handleCloseNotification} />
          </SVGBox>

          <Typography color="grey1" variant={TypographyVariant.Header4}>
            One or more of your bank accounts below need attention
          </Typography>
        </NotificationContainer>
      </Box>
    );
  }

  return null;
};
