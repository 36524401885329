import styled from 'styled-components';
import { Button, Text } from 'components/core';

const DocumentName = styled(Text)`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentGridItem = styled.div`
  width: 150px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro0);
  box-shadow: var(--box-shadow-03);
`;

const DocumentButtonWrapper = styled(Button)`
  border: 0px;
  padding: 0px;
`;

export const Styled = {
  DocumentName,
  DocumentGridItem,
  DocumentButtonWrapper,
};
