import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult } from 'react-query';

export const useSelfServeOnboarding = (): UseMutationResult<any, any, any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  const selfServeOnboarding = async ({
    AffiliateCode,
    DBA,
    Properties,
    User,
  }: {
    AffiliateCode?: string;
    DBA: string;
    Properties?: object;
    User: { Email: string };
  }) => {
    const requestData: any = {
      DBA,
      User,
    };

    if (AffiliateCode) {
      requestData.AffiliateCode = AffiliateCode;
    }

    if (Properties) {
      requestData.Properties = Properties;
    }

    return treasureApi().post(
      'onboarding/self-serve/businesses/users',
      requestData,
    );
  };

  return useMutation(selfServeOnboarding);
};
