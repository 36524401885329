import React from 'react';
import firebase from 'utils/firebase';
import { useSendEvent, TrackEventName } from 'modules/tracking';

export const useSignOut = () => {
  const { sendEvent } = useSendEvent();

  const signOut: () => void = () => {
    // sign out of firebase
    firebase.auth().signOut();

    sendEvent(TrackEventName.FirebaseSignOutSucceeded);
  };

  return { signOut };
};
