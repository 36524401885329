import React from 'react';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { Typography, TypographyVariant } from 'components/core';
import { WhiteLabelLogoVariant } from 'modules/2023-q4/white-label/components/white-label-logo/white-label-logo';
import { TreasureLogoHorizontal } from 'assets';
import { Styled } from './powered-by-treasure.style';

interface IDefaultTreasureLogos {
  variant: WhiteLabelLogoVariant;
}

export const PoweredByTreasure: React.FC<IDefaultTreasureLogos> = ({
  variant,
}) => {
  const mobileView = variant === WhiteLabelLogoVariant.HeaderMobile;

  return (
    <Styled.LineHeight mt={mobileView ? 0.5 : 0}>
      <Typography color="black19" variant={TypographyVariant.Paragraph4}>
        Powered By
        {mobileView ? <br /> : ' '}
        <SVGBox
          display="inline-block"
          position="relative"
          top={1}
          ml={mobileView ? 0 : 0.25}
        >
          <img alt="Treasure logo" src={TreasureLogoHorizontal} height={10} />
        </SVGBox>
      </Typography>
    </Styled.LineHeight>
  );
};
