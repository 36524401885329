import styled from 'styled-components';

const FormContainer = styled.div`
  max-width: 461px;
  background: var(--color-white);
  box-shadow: 0px 12px 19px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  text-align: center;
  margin: 0 auto;
  padding: 35px 45px;
  position: relative;
  button {
    min-width: 100%;
    margin: 40px 0px;
  }
  .header {
    text-align: left;
    font-size: 22px;
  }
  p {
    margin: 0px;
  }
  form > div {
    margin-top: 15px;
    text-align: left;
  }
`;

const TosLinks = styled.p`
  width: 100vw;
  text-align: center;
  font-size: 12px;
  color: var(--color-jumbo);
`;

const ChildContainer = styled.div`
  width: 100%;
  max-width: 461px;
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
`;

const ConfirmationContainer = styled.div`
  height: 340px;
  margin: 0 auto;
  margin-top: 70px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  svg {
    margin-bottom: 12px;
    min-height: 94px;
  }
  .cta {
    color: var(--color-greyDark);
    font-size: 29px;
    font-weight: 700;
    margin: 0;
    line-height: 35px;
    text-align: center;
  }
  .resend-text {
    margin-top: 45px;
    margin-bottom: 15px;
  }
  button {
    width: 60%;
    max-width: 360px;
    min-width: 200px;
  }
`;

const ConfirmationText = styled.p`
  color: var(--color-turtle);
  min-height: 20px;
`;

export const Styled = {
  FormContainer,
  TosLinks,
  ChildContainer,
  ConfirmationContainer,
  ConfirmationText,
};
