import React from 'react';
import { Box } from '@material-ui/core';
import { useBusiness } from 'hooks/business/use-business';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { PlaidAccountStatus } from 'enums';
import { BankAccount } from 'types';
import { BankAccount as BankAccountType } from 'modules/2023-q3/banking-page/v2/types/bank-account';
import { NotificationContainer } from './components/notification-container/notification-container';
import { BankPlaidAuthError } from './components/bank-plaid-auth-error/bank-plaid-auth-error';
import { BankPlaidDataStale } from './components/bank-plaid-data-stale/bank-plaid-data-stale';

interface BankPlaidErrorProps {
  bankAccounts: BankAccountType[] | undefined;
}

export const BankPlaidError: React.FC<BankPlaidErrorProps> = ({
  bankAccounts,
}) => {
  const { data: business } = useBusiness();
  const { data: accounts } = useBankAccounts(business?.Id);

  const bankItemId = bankAccounts?.[0].BankItemId || '';
  const bankName = bankAccounts?.[0].institution.name;
  const bankBankAccounts = accounts?.filter(
    (bankAccount: BankAccount) => (bankAccount.BankItemId = bankItemId),
  );

  // check if any plaid account has an AUTH_ERROR
  const hasPlaidAuthError = bankBankAccounts?.find(
    (account: BankAccount) =>
      account.PlaidStatus === PlaidAccountStatus.AUTH_ERROR,
  );

  // check if any plaid account's data is stale and hasn't just been re-authed (PENDING_READY).
  const isPlaidDataStale = bankBankAccounts?.find(
    (account: BankAccount) =>
      account.isPlaidDataStale &&
      account.PlaidStatus !== PlaidAccountStatus.PENDING_READY,
  );

  if (hasPlaidAuthError && bankItemId) {
    return (
      <Box mt={2} mb={5}>
        <NotificationContainer>
          <BankPlaidAuthError bankItemId={bankItemId} bankName={bankName} />
        </NotificationContainer>
      </Box>
    );
  }

  if (isPlaidDataStale && bankName) {
    return (
      <Box mt={2} mb={5}>
        <NotificationContainer>
          <BankPlaidDataStale bankName={bankName} />
        </NotificationContainer>
      </Box>
    );
  }

  return null;
};
