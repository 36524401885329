import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Heading,
  ItemDefinition,
  Text,
} from 'components/core';
import { PositionsAssetTable } from 'modules/treasure-reserve-positions/components/positions-asset-table/positions-asset-table';
import { ValueType } from 'modules/treasure-reserve-positions/enums/value-type';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useTreasureReservePositions } from 'modules/treasure-reserve-positions/hooks/use-treasure-reserve-positions';

const treasuryBillTickerStartsWith = 'US T Bill';
const treasuryTIPSTickerStartsWith = 'TIPS';

const TreasureReservePositions: React.FC = () => {
  const { data: business } = useBusiness();
  const { data: portfolios } = usePortfolios(business?.Id);
  const productAmounts = portfolios?.amountsInDollars.productAmounts;
  const { data: treasureReservePositions } = useTreasureReservePositions(
    business?.Id,
  );

  // TODO: update the following filters by type
  const securities = treasureReservePositions.filter(
    (position: { ticker: string }) =>
      !position.ticker?.startsWith(treasuryBillTickerStartsWith) &&
      !position.ticker?.startsWith(treasuryTIPSTickerStartsWith),
  );

  const treasuryBills = treasureReservePositions.filter(
    (position: { ticker: string }) =>
      position.ticker?.startsWith(treasuryBillTickerStartsWith) ||
      position.ticker?.startsWith(treasuryTIPSTickerStartsWith),
  );

  // differentialte between tbills and tips
  // add value at maturity to tbills
  // add + inflation to tips
  treasuryBills.forEach(
    (tbill: {
      cusip: string;
      shares: string;
      ticker: string;
      valueAtMaturity: number;
    }) => {
      // TODO: replace with fund_type
      if (
        tbill.cusip.startsWith('912795') ||
        tbill.cusip.startsWith('912796') ||
        tbill.cusip.startsWith('912797')
      ) {
        tbill.valueAtMaturity = Number(tbill.shares);
      } else {
        tbill.ticker = tbill.ticker.replace(
          treasuryBillTickerStartsWith,
          treasuryTIPSTickerStartsWith,
        );
      }
    },
  );

  const columnsSecurities = [
    {
      label: 'Ticker Symbol',
      type: ValueType.TickerSymbol,
      value: 'ticker',
    },
    { label: 'Shares', type: ValueType.Number, value: 'shares' },
    {
      label: 'Current Value',
      type: ValueType.CurrencyValue,
      value: 'dollarValue',
    },
  ];

  const columnsTreasuryBills = [
    {
      label: 'Bills & TIPS',
      type: ValueType.TickerSymbol,
      value: 'ticker',
    },
    {
      label: 'Value at Maturity',
      type: ValueType.CurrencyValue,
      value: 'valueAtMaturity',
    },
    {
      label: 'Current Value',
      type: ValueType.CurrencyValue,
      value: 'dollarValue',
    },
  ];

  return (
    <Box p={3}>
      <Box mb={2}>
        <Heading color="regalBlue" variant={2}>
          Treasure Reserve Positions
        </Heading>
      </Box>

      <Box mb={3}>
        <Grid container spacing={3}>
          <Grid item>
            <ItemDefinition
              label="Cash"
              value={
                <Text variant={3}>
                  <Currency
                    number={productAmounts?.cash}
                    variant={CurrencyVariant.Full}
                  />
                </Text>
              }
            />
          </Grid>

          <Grid item>
            <ItemDefinition
              label="Mutual Funds"
              value={
                <Text variant={3}>
                  <Currency
                    number={
                      productAmounts?.moneyMarket +
                      productAmounts?.managedIncome
                    }
                    variant={CurrencyVariant.Full}
                  />
                </Text>
              }
            />
          </Grid>

          <Grid item>
            <ItemDefinition
              label="Treasuries"
              value={
                <Text variant={3}>
                  <Currency
                    number={productAmounts?.managedTreasuries}
                    variant={CurrencyVariant.Full}
                  />
                </Text>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb={3}>
        <Divider />
      </Box>

      {securities.length > 0 && (
        <Box mb={5}>
          <Box mb={1.5}>
            <Heading color="regalBlue" variant={3}>
              Mutual Funds
            </Heading>
          </Box>

          <PositionsAssetTable columns={columnsSecurities} data={securities} />
        </Box>
      )}

      {treasuryBills.length > 0 && (
        <Box mb={5}>
          <Box mb={1.5}>
            <Heading color="regalBlue" variant={3}>
              Treasuries
            </Heading>
          </Box>

          <PositionsAssetTable
            columns={columnsTreasuryBills}
            data={treasuryBills}
          />
        </Box>
      )}
    </Box>
  );
};

export { TreasureReservePositions };
