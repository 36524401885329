import styled from 'styled-components';
import {
  CartesianGridStroke,
  ChartHeight,
  TickStyle,
} from 'components/charts/v3/common/charts.common.style';
import { getColor } from 'styles/css-constants';

const BarFillInflows = getColor('stromboli');

const BarFillOutflows = getColor('stromboli');

const ChartContainer = styled.div`
  & .recharts-yAxis {
    .recharts-cartesian-axis-tick:last-child {
      display: none;
    }
  }

  & .recharts-cartesian-grid {
    line:last-child {
      display: none;
    }
  }
`;

export const Styled = {
  BarFillInflows,
  BarFillOutflows,
  CartesianGridStroke,
  ChartContainer,
  ChartHeight,
  TickStyle,
};
