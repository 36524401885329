import styled from 'styled-components';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const Add = styled(AddIcon)`
  position: relative;
  top: 4px;
`;

const Container = styled(Box)`
  background: var(--color-white);
  border: 1px solid var(--color-gainsboro0);
  border-radius: var(--border-radius-02);
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.07);
  width: 565px;
`;

export const Styled = {
  Add,
  Container,
};
