import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

interface PageTitleProps {
  title: string;

  description?: string | React.ReactNode;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, description }) => {
  const v1 = (
    <Box mb={2}>
      <Typography color="nero" variant={TypographyVariant.BoldLargeHeader}>
        {title}
      </Typography>

      {description && (
        <Typography color="nero" variant={TypographyVariant.Body}>
          {description}
        </Typography>
      )}
    </Box>
  );

  const v2 = (
    <Box mb={3}>
      <Typography
        capitalize
        color="black19"
        variant={TypographyVariant.Header3}
      >
        {title}
      </Typography>

      {description && (
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {description}
        </Typography>
      )}
    </Box>
  );

  return (
    <FeatureFlag
      disabled={v1}
      enabled={v2}
      flag={FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED}
    />
  );
};
