import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoints } from 'styles/css-constants';

const SignUpCopy = styled(Grid)`
  background-color: var(--color-brown6b);

  ${breakpoints.down('sm')} {
    order: 2;
  }
`;

export const Styled = {
  SignUpCopy,
};
