import React, { useEffect, useRef } from 'react';
import { MFACodeBox } from './components/mfa-code-box/mfa-code-box';
import { Box } from '@material-ui/core';
import { Styled } from './mfa-code-boxes.style';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';

interface MFACodeBoxProps {
  handleVerifyCode?: () => void;

  mfaCode: string[];

  mfaError?: boolean;

  readonly?: boolean;

  setMFACode?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const MFACodeBoxes: React.FC<MFACodeBoxProps> = ({
  handleVerifyCode,
  mfaCode,
  mfaError,
  readonly,
  setMFACode,
}) => {
  // const refs = [...Array(6)].map(r => useRef(null));
  // React Hook "useRef" cannot be called inside a callback. React Hooks must be called in a React function component or a custom React Hook function  react-hooks/rules-of-hooks
  const refs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const handleChange = (
    event: React.FormEvent<HTMLInputElement>,
    index: number,
  ) => {
    const changeValue = (event.target as HTMLInputElement).value;
    const code = mfaCode;

    code[index] = changeValue;

    if (setMFACode) {
      setMFACode([...code]);
    }

    if (changeValue !== '' && refs[index + 1] !== undefined) {
      setTimeout(() => refs[index + 1].current?.focus());
    } else {
      setTimeout(() => refs[index].current?.focus());
    }
  };

  const handlePaste = (event: React.ClipboardEvent, index: number) => {
    const code = mfaCode;
    let focusPosition = 0;
    let paste = event.clipboardData.getData('Text');
    // TODO: this should be a variable that's used in the email template to correspond with what the user may copy
    // could also do a search to find if first instance exists in case more text is copied before TR-
    paste = paste.replace('TR-', '');

    for (let i = 0; i + index < 6; i++) {
      const value = paste.charAt(i);

      code[i + index] = paste.charAt(i);

      if (value) {
        focusPosition = i + index + 1;
        focusPosition = focusPosition > 5 ? 5 : focusPosition;
      }
    }

    if (setMFACode) {
      setMFACode([...code]);
    }

    setTimeout(() => refs[focusPosition].current?.focus());

    event.preventDefault();
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const ARROW_LEFT = 37;
    const ARROW_RIGHT = 39;
    const BACKSPACE_KEY = 8;
    const ENTER = 13;

    if (event.keyCode === ENTER && handleVerifyCode) {
      handleVerifyCode();

      event.preventDefault();
    }

    if (
      ((event.keyCode === BACKSPACE_KEY && !event.currentTarget.value) ||
        event.keyCode === ARROW_LEFT) &&
      refs[index - 1] !== undefined
    ) {
      setTimeout(() => refs[index - 1].current?.focus());

      event.preventDefault();
    } else if (event.keyCode === ARROW_RIGHT && refs[index + 1] !== undefined) {
      setTimeout(() => refs[index + 1].current?.focus());

      event.preventDefault();
    }
  };

  useEffect(() => {
    setTimeout(() => refs[0].current?.focus());
  }, []);

  return (
    <Styled.CodeContainer
      container
      error={mfaError ? mfaError : undefined}
      justifyContent="center"
    >
      {[...Array(6)].map((item, i) => (
        <Styled.CodeBoxContainer item xs={2} key={i}>
          <MobileView
            mobile={
              <MFACodeBox
                forwardRef={refs[i]}
                onChange={(event) => handleChange(event, i)}
                onKeyDown={(event) => handleKeyDown(event, i)}
                onPaste={(event) => handlePaste(event, i)}
                readonly={!!readonly}
                value={mfaCode[i]}
                mobile
              />
            }
          >
            <MFACodeBox
              forwardRef={refs[i]}
              onChange={(event) => handleChange(event, i)}
              onKeyDown={(event) => handleKeyDown(event, i)}
              onPaste={(event) => handlePaste(event, i)}
              readonly={!!readonly}
              value={mfaCode[i]}
            />
          </MobileView>
        </Styled.CodeBoxContainer>
      ))}
    </Styled.CodeContainer>
  );
};
