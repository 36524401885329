import React from 'react';
import { useTranslation } from 'app/i18n';
import { i18nNamespaceMFAContent } from 'modules/2023-q3/components/mfa/mfa';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { MFACodeBoxes } from 'modules/2023-q3/components/mfa/components/mfa-code-boxes/mfa-code-boxes';
import { ReactComponent as IconCheckmarkCircleGreen } from 'assets/icons/checkmarks/checkmark-circle-green.svg';

interface VerifiedCodeProps {
  mfaCode: string[];
}

export const VerifiedCode: React.FC<VerifiedCodeProps> = ({ mfaCode }) => {
  const { t } = useTranslation(i18nNamespaceMFAContent);

  return (
    <Box textAlign="center">
      <Box mt={4} mb={1}>
        <Box mb={1}>
          <IconCheckmarkCircleGreen />
        </Box>

        <Typography color="black19" variant={TypographyVariant.Header3}>
          {t('Verified Successfully')}
        </Typography>
      </Box>

      <Box mt={3.5} mb={2.5}>
        <MFACodeBoxes mfaCode={mfaCode} readonly />
      </Box>
    </Box>
  );
};
