import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Typography } from 'components/core';

const AUM = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
`;

const ReturnAmount = styled(Typography)`
  font-size: 13px;
  text-transform: none;
`;

const ReturnBox = styled(Box)`
  background-color: var(--color-salem);
  border-radius: var(--border-radius-02);
`;

const ReturnNumber = styled(Typography)`
  font-size: 18px;
  font-weight: 800px;
`;

const ReturnTitle = styled(Typography)`
  font-weight: 800;
`;

export const Styled = {
  AUM,
  ReturnAmount,
  ReturnBox,
  ReturnNumber,
  ReturnTitle,
};
