import React from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import { Currency, CurrencyVariant } from 'components/core';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Label,
} from 'recharts';
import { getColor } from 'styles/css-constants';

import { dayjs } from 'app/dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Styled } from './cash-flow-trends.style';

dayjs.extend(isSameOrBefore);

interface DataPoint {
  amount: number;
  date: string;
}

interface CashFlowTrendsChartDataPoint {
  label: string;
  'Prior Year'?: number;
  Projected?: number;
  Actuals?: number;
}

interface ChartDataProps {
  future: DataPoint[];
  past: DataPoint[];
  present: DataPoint[];
}

interface CashFlowTrendsProps {
  data: ChartDataProps;
}

const moneyFormatter = (value: number, currencyVariant: CurrencyVariant) => {
  return renderToString(
    <Currency
      number={value}
      renderText={(value) => <>{value}</>}
      variant={currencyVariant}
    />, // TODO: is this the best approach?
  );
};

const tooltipFormatter = (value: string) => {
  return typeof value === 'number'
    ? moneyFormatter(value, CurrencyVariant.Full)
    : value;
};

const averageAmounts = (dataPoints: DataPoint[]) => {
  if (!dataPoints.length) {
    return 0;
  }

  return (
    dataPoints.reduce(
      (sum: number, point: DataPoint) => sum + point.amount,
      0,
    ) / dataPoints.length
  );
};

/*
 * Determines the earliest and latest dates and duration in a set of points
 */
const getChartTimespan = (dataPoints: DataPoint[]) => {
  const sortedPresentFuture = dataPoints
    .map((dataPoint: DataPoint) => dataPoint.date)
    .sort();
  const chartStartDate = dayjs.utc(sortedPresentFuture[0]);
  const chartEndDate = dayjs.utc(
    sortedPresentFuture[sortedPresentFuture.length - 1],
  );

  return { chartStartDate, chartEndDate };
};

const formatChartData = ({ future, past, present }: ChartDataProps) => {
  // Chart timespan is based on present and future points only
  const { chartStartDate, chartEndDate } = getChartTimespan([
    ...present,
    ...future,
  ]);

  // Actuals includes present and past data points (relevant for prior year for future data point)
  const actuals = [...present, ...past];

  // This object will be populated with data formatted for the chart
  const chartData = [];

  let monthIterator = dayjs.utc(chartStartDate);
  while (monthIterator.isSameOrBefore(chartEndDate)) {
    const dataPoint: CashFlowTrendsChartDataPoint = {
      label: monthIterator.format('MMM YYYY'),
    };
    const currentMonthIterator = monthIterator;

    const presentPoints = present.filter((point: DataPoint) =>
      dayjs.utc(point.date).isSame(currentMonthIterator, 'month'),
    );
    if (presentPoints.length) {
      dataPoint.Actuals = averageAmounts(presentPoints);
    }

    const futurePoints = future.filter((point: DataPoint) =>
      dayjs.utc(point.date).isSame(currentMonthIterator, 'month'),
    );
    if (futurePoints.length) {
      dataPoint.Projected = averageAmounts(futurePoints);
    }

    const pastPoints = actuals.filter((point: DataPoint) =>
      dayjs
        .utc(point.date)
        .isSame(dayjs(currentMonthIterator).subtract(1, 'year'), 'month'),
    );
    if (pastPoints.length) {
      dataPoint['Prior Year'] = averageAmounts(pastPoints);
    }

    chartData.push(dataPoint);

    monthIterator = monthIterator.add(1, 'month');
  }

  return chartData;
};

const hasValidDataForChart = (data: any) => {
  return Array.isArray(data) && data.every((d) => d);
};

const getReferenceLinePosition = () => {
  const yearReference = new Date().getFullYear();
  const monthReference = new Date().toLocaleString('en-US', { month: 'short' });
  return `${monthReference} ${yearReference}`;
};

const CashFlowTrends: React.FC<CashFlowTrendsProps> = ({ data }) => {
  const { future, past, present } = data;
  const futureHasInvalidData = !hasValidDataForChart(future);
  const presentHasInvalidData = !hasValidDataForChart(present);
  const pastHasInValidData = !hasValidDataForChart(past);
  const referenceLinePosition = getReferenceLinePosition();

  if (futureHasInvalidData || presentHasInvalidData || pastHasInValidData) {
    return null;
  }

  const chartData = formatChartData(data);

  return (
    <Styled.ChartContainer pt={3} pr={2}>
      <ResponsiveContainer height={Styled.ChartHeight} width="100%">
        <ComposedChart data={chartData}>
          <ReferenceLine
            x={referenceLinePosition}
            stroke={getColor('gainsboro0')}
            strokeDasharray="3 3"
            label={<Label position="insideTop">This month</Label>}
          />

          <CartesianGrid vertical={false} stroke={Styled.CartesianGridStroke} />

          <XAxis
            dataKey="label"
            scale="point"
            stroke="none"
            tick={Styled.TickStyle}
            tickLine={false}
            type="category"
          />

          <YAxis
            padding={{ top: 20 }}
            stroke="none"
            tick={Styled.TickStyle}
            tickFormatter={(value) =>
              moneyFormatter(value, CurrencyVariant.Truncated)
            }
            tickLine={false}
            domain={[
              (dataMin: number) => Math.min(0, dataMin),
              (dataMax: number) => Math.max(0, dataMax),
            ]}
          />

          <Area
            dataKey="Actuals"
            fill={Styled.FillActuals}
            fillOpacity={1}
            stroke="none"
          />

          <Area
            dataKey="Projected"
            fill={Styled.FillProjected}
            fillOpacity={1}
            stroke="none"
          />

          <Line
            dataKey="Prior Year"
            dot={false}
            stroke={Styled.PastLineStroke}
            strokeWidth={2}
          />

          <Tooltip
            formatter={tooltipFormatter}
            isAnimationActive={false}
            itemStyle={{ color: 'black' }}
            position={{ y: 0 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Styled.ChartContainer>
  );
};

CashFlowTrends.propTypes = {
  data: PropTypes.shape({
    future: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    past: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    present: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export { CashFlowTrends };
