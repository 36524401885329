import React from 'react';
import { useDashboardActivity } from 'hooks/banking/use-dashboard-activity';
import { Activity } from 'components/dashboard/v3';

export const DashboardActivity = () => {
  const { data: dashboardActivity, isLoading: isLoadingDashboardActivity } =
    useDashboardActivity();

  if (isLoadingDashboardActivity || dashboardActivity?.totals === undefined) {
    return null;
  }

  return <Activity data={dashboardActivity} />;
};
