import {
  AllocationModalRowKeys,
  getRowHeader,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import {
  Allocation,
  getAllocationAmount,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import {
  cellText,
  currency,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-typography/allocation-typography';

export const getAmounts = ({
  allocations,
  aum,
}: {
  allocations: Allocation;
  aum: number;
}) => {
  const showAmount = (allocation: number, aum: number) => {
    return cellText(
      currency(
        getAllocationAmount({
          allocation,
          aum,
        }),
      ),
    );
  };

  const data = [getRowHeader({ rowKey: AllocationModalRowKeys.Amount })];

  Object.entries(allocations).map(([productKey, product]) => {
    return data.push(showAmount(product.allocation, aum));
  });

  return data;
};
