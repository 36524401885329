import React, { useEffect, useState } from 'react';
import {
  i18nNamespace,
  DepositWithdrawalVariant,
  IDepositWithdrawal,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { Box, Divider } from '@material-ui/core';
import { ProgressSpinner } from 'modules/2023-q3/components/progress-spinner/progress-spinner';
import { Typography, TypographyVariant } from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Size, TreasureFiEmails } from 'enums';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';

export const Processing: React.FC<IDepositWithdrawal> = ({ variant }) => {
  const { t } = useTranslation(i18nNamespace);

  const [stillProcessing, setStillProcessing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStillProcessing(true);
    }, 10000); // 10 seconds
  }, []);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;
  const depositOrWithdrawal = t(isDeposit ? 'deposit' : 'withdrawal');
  const DepositOrWithdrawal = t(isDeposit ? 'Deposit' : 'Withdrawal');

  const processingText = t('Processing', {
    DepositOrWithdrawal,
  });

  return (
    <FormContainer size={Size.Medium}>
      <Box mx={3}>
        <Box my={12} textAlign="center">
          <Box mb={1.5}>
            <ProgressSpinner />
          </Box>

          <Box mb={1.5}>
            <Typography color="black19" variant={TypographyVariant.Header3}>
              {processingText}
            </Typography>
          </Box>

          {stillProcessing ? (
            <>
              <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
                {t(
                  'Still waiting? Give it another minute. If this continues for much longer, reach out to our support team via the link below.',
                  {
                    ns: 'app',
                  },
                )}
              </Typography>

              <Box m={2}>
                <Divider />
              </Box>

              <ContactTreasureSupportLink subject={processingText}>
                <Typography
                  color="blue2"
                  variant={TypographyVariant.Paragraph2}
                >
                  {t('Contact', { ns: 'app' })} {TreasureFiEmails.Support}
                </Typography>
              </ContactTreasureSupportLink>
            </>
          ) : (
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              {t('Sit tight while we process', {
                depositOrWithdrawal,
              })}
            </Typography>
          )}
        </Box>
      </Box>
    </FormContainer>
  );
};
