import mixpanel, { Dict } from 'mixpanel-browser';

mixpanel.init(window._env.REACT_APP_MIXPANEL_TOKEN);

const track: typeof mixpanel.track = (
  eventName,
  properties,
  optionsOrCallback,
  callback,
) => {
  mixpanel.track(eventName, properties, optionsOrCallback, callback);
};

const identify: typeof mixpanel.identify = (uniqueId) => {
  mixpanel.identify(uniqueId);
};

// TODO: find a better way to define the method that retains the original signature of mixpanel.people.set
// mixpanel.people.set is overloaded, so TS doesn't like people: { set: typeof mixpanel.people.set }
// This changes the function signature of mixpanel.people.set, which is not ideal
const people: { set: (prop: Dict) => void } = {
  set: (properties) => mixpanel.people.set(properties as Dict),
};

export default {
  track,
  identify,
  people,
};
