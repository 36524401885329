import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { SectionContainer } from 'modules/dashboard/components';

interface BankAccountProps {
  bankAccount: any;
}

export const BankAccountManual: React.FC<BankAccountProps> = ({
  bankAccount,
}) => {
  return (
    <SectionContainer>
      <Box mb={3}>
        <Typography
          color="black19"
          variant={TypographyVariant.Header4}
        >{`${bankAccount.Name} (...${bankAccount.Mask})`}</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant={TypographyVariant.Paragraph2}>
            Account Type
          </Typography>

          <Box>
            <Typography
              capitalize
              color="grey1"
              variant={TypographyVariant.Paragraph1}
            >
              {bankAccount.Type}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};
