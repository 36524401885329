import styled, { css } from 'styled-components';
import { Grid, List as MuiList } from '@material-ui/core';
import { breakpoints, colors, getColor } from 'styles/css-constants';

interface StyledLegendSquare {
  color?: string;
}

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

const List = styled(MuiList)`
  display: flex;

  ${breakpoints.up('md')} {
    display: block;
  }
`;

const LegendSquare = styled.div<StyledLegendSquare>`
  width: 16px;
  height: 16px;
  background: ${(props) => {
    if (props.color) return getColor(props.color as keyof typeof colors);

    return css`var(--color-nero)`;
  }};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
`;

export const Styled = {
  FullHeightGrid,
  LegendSquare,
  List,
};
