import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import {
  FormFieldContainer,
  FormFieldContainerVariant,
  TextFieldController,
  formFields,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { Person } from 'modules/onboarding/v3-streamlined-onboarding/types/person';

const getPersonNameAndDetails = (person: Person) => {
  let personDetails = '';

  if (person.isBusinessOwner || person.isEntityOfficer) {
    personDetails += ' - ';
  }

  if (person.isBusinessOwner) {
    personDetails += 'Owner';
  }

  if (person.isBusinessOwner && person.isEntityOfficer) {
    personDetails += ' & ';
  }

  if (person.isEntityOfficer) {
    personDetails += 'Officer';
  }

  return `${person?.firstName} ${person?.lastName}${personDetails}`;
};

interface IPersonFormProps {
  person: Person;
  control: any;
  register: any;
}

export const PersonForm: React.FC<IPersonFormProps> = ({
  person,
  control,
  register,
}) => {
  const renderPersonFields = () => {
    return (
      <>
        <Box mb={1} mt={3}>
          <Typography variant={TypographyVariant.SubHeader1}>
            {getPersonNameAndDetails(person)}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormFieldContainer variant={FormFieldContainerVariant.Small}>
              <TextFieldController
                {...formFields.jobTitle}
                name={`people[${person.id}].jobTitle`}
                defaultValue={person?.jobTitle}
                control={control}
              />
            </FormFieldContainer>
          </Grid>

          {person?.isBusinessOwner && (
            <Grid item xs>
              <FormFieldContainer variant={FormFieldContainerVariant.Small}>
                <TextFieldController
                  {...formFields.ownershipPercentage}
                  name={`people[${person.id}].ownershipPercentage`}
                  placeholder="%"
                  defaultValue={person?.ownershipPercentage}
                  control={control}
                />
              </FormFieldContainer>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs>
            <FormFieldContainer variant={FormFieldContainerVariant.Small}>
              <TextFieldController
                {...formFields.phoneNumber}
                name={`people[${person.id}].phoneNumber`}
                placeholder=""
                defaultValue={person?.phoneNumber}
                control={control}
              />
            </FormFieldContainer>
          </Grid>
          <Grid item xs>
            <FormFieldContainer variant={FormFieldContainerVariant.Small}>
              <TextFieldController
                {...formFields.email}
                name={`people[${person.id}].email`}
                placeholder=""
                defaultValue={person?.email}
                control={control}
              />
            </FormFieldContainer>
          </Grid>
        </Grid>
      </>
    );
  };

  return <Box>{renderPersonFields()}</Box>;
};
