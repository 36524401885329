import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';

interface MenuOptionProps {
  children: React.ReactNode;

  className?: string;

  onClick: () => void;

  onTouchStart: () => void;

  path?: string;
}

export const MenuOption: React.FC<MenuOptionProps> = ({
  children,
  className,
  onClick,
  onTouchStart,
  path,
}) => {
  const location = useLocation();

  const isCurrentPage = location.pathname === path;

  return (
    <div className={className} onClick={onClick} onTouchStart={onTouchStart}>
      <Box p={2}>
        <Typography
          color={isCurrentPage ? 'brown4a' : 'white'}
          variant={TypographyVariant.Paragraph2}
        >
          {children}
        </Typography>
      </Box>
    </div>
  );

  /*return (
    <Link
      className={className}
      to={{ pathname: path, state: { from: location } }}
    >
      <Box p={2}>
        <Typography
          color={isCurrentPage ? 'brown4a' : 'white'}
          variant={TypographyVariant.Paragraph2}
        >
          {children}
        </Typography>
      </Box>
    </Link>
  );*/
};
