import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useQueryOnboarding = (businessId: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery(
    [ReactQueryKeys.Onboarding, businessId],
    async () => {
      const response = await treasureApi().get(`/onboarding/${businessId}`);

      return response.data;
    },
    {
      enabled: !!businessId,
    },
  );
};
