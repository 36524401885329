import React, { useEffect } from 'react';
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { useBankAccounts } from 'modules/2023-q3/banking-page/v2/hooks/use-bank-accounts';
import { Box } from '@material-ui/core';
import { DashboardContainer } from 'layouts/v3-portal-layout/components';
import { BankAccountsPlaidErrors } from './components/bank-accounts-plaid-errors/bank-accounts-plaid-errors';
import { Overview } from './components/overview/overview';
import { BankAccounts } from './components/bank-accounts/bank-accounts';

export const BankingPage = () => {
  const loadingContext = useLoadingContext();

  const { data: bankAccounts, isLoading: isLoadingBankAccounts } =
    useBankAccounts();

  useEffect(() => {
    loadingContext.setLoading(isLoadingBankAccounts);
  }, [isLoadingBankAccounts]);

  return (
    <DashboardContainer>
      <Box mt={5} mb={10}>
        <BankAccountsPlaidErrors />
        <Overview />
      </Box>

      <BankAccounts />
    </DashboardContainer>
  );
};
