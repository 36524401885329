import styled from 'styled-components';
import {
  AccordionDetails as AccordionDetailsAlias,
  AccordionSummary as AccordionSummaryAlias,
  Box,
} from '@material-ui/core';
import { Heading } from 'components/core';
import { ExpandMore } from '@material-ui/icons';

const AccordionDetails = styled(AccordionDetailsAlias)`
  box-shadow: var(--box-shadow-01);
  padding: var(--spacing-02) var(--spacing-05);
`;

// TODO: copied from old style, a new design will come soon
const AccordionHeading = styled(Heading)`
  font-weight: normal;
  letter-spacing: 2.62px;
  text-transform: uppercase;
`;

const AccordionSummary = styled(AccordionSummaryAlias)`
  box-shadow: var(--box-shadow-01);
  padding: var(--spacing-02) var(--spacing-05);
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: var(--color-stromboli);
`;

const SettingsSidebarFooter = styled.div`
  background-color: var(--color-gainsboro0);
  height: 100%;
`;

const WhiteSmokeBackground = styled(Box)`
  background-color: var(--color-whiteSmoke0);
`;

export const Styled = {
  AccordionDetails,
  AccordionHeading,
  AccordionSummary,
  ExpandMoreIcon,
  SettingsSidebarFooter,
  WhiteSmokeBackground,
};
