import React, { useState } from 'react';
import { dayjs } from 'app/dayjs';
import { renderToString } from 'react-dom/server';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { TimeFilterOption } from 'modules/dashboard/components';
import { useTracking, TrackEventName } from 'modules/tracking';
import { ReturnsChartTooltip } from './components/returns-chart-tooltip/returns-chart-tooltip';
import { Styled } from './returns-chart.style';

export interface MonthlyReturn {
  cash: {
    totalReturns: number;
  };
  date: string;
  investments: {
    totalReturns: number;
  };
}

const getXAxisTickFormat = (timeFilter: TimeFilterOption) => {
  if (timeFilter === TimeFilterOption.ALL) {
    return 'MMM YYYY';
  }

  return 'MMMM';
};

const moneyFormatter = (value: number, currencyVariant: CurrencyVariant) => {
  return renderToString(
    <Currency
      decimalScale={0}
      number={value}
      renderText={(renderValue) => renderValue}
      variant={currencyVariant}
    />, // TODO: is this the best approach?
  );
};

interface ReturnsChartProps {
  dailyAUMData: {
    amount: number;
    date: string;
  }[];

  monthlyReturnData: MonthlyReturn[];

  timeFilter: TimeFilterOption;
}

export const ReturnsChart: React.FC<ReturnsChartProps> = ({
  dailyAUMData,
  monthlyReturnData,
  timeFilter,
}) => {
  const { trackEvent } = useTracking<{
    component: string;
    eventName: TrackEventName;
    month: string;
  }>({
    component: 'Returns Chart',
  });
  const [trackTooltipMonth, setTrackTooltipMonth] = useState<string>();

  // sort dates so newest is to the right of the chart
  dailyAUMData?.sort((a, b) => (a.date > b.date ? 1 : -1));
  monthlyReturnData?.sort((a, b) => (a.date > b.date ? 1 : -1));

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      const month = dayjs(payload?.[0].payload.date).format('MMM YYYY');

      if (month !== trackTooltipMonth) {
        trackEvent({ eventName: TrackEventName.Tooltip, month });

        setTrackTooltipMonth(month);
      }

      return (
        <ReturnsChartTooltip
          active={active}
          label={label}
          monthlyReturnData={monthlyReturnData}
          payload={payload}
        />
      );
    }

    return null;
  };

  // recharts doesn't seem to support stacked bar graphs with a line graph of a different timespan
  // it can be rendered, but tooltips won't work quite right
  // this workaround renders 2 charts on top of each other
  // with the same x and y axis, they will render the same width and height
  // the first graph has teh cartesian grid while the 2nd has the tooltip
  const barSize = 100;
  const barStackId = 'a';
  const barXAxisId = 1;
  const barYAxisId = 'left';
  const composedChartMargin = {
    top: 15,
    right: 20,
    left: 20,
    bottom: 15,
  };
  const composedChartWidth = 500;

  const xAxis = ({ hideTick }: { hideTick?: boolean }) => (
    <XAxis
      dataKey="date"
      stroke={Styled.XAxisStroke}
      tick={Styled.TickStyle}
      tickFormatter={(value) =>
        hideTick ? '' : dayjs(value).format(getXAxisTickFormat(timeFilter))
      }
      tickLine={false}
      type="category"
      xAxisId={1}
    />
  );

  const yAxis1 = (
    <YAxis
      domain={['dataMin', (dataMax: number) => dataMax * 2]}
      stroke="none"
      tick={Styled.TickStyle}
      tickFormatter={(value) =>
        moneyFormatter(value, CurrencyVariant.Truncated)
      }
      tickLine={false}
      yAxisId="left"
    />
  );

  const yAxis2 = (
    <YAxis
      domain={['dataMin', 'dataMax']}
      orientation="right"
      stroke="none"
      tick={Styled.TickStyle}
      tickFormatter={(value) =>
        moneyFormatter(value, CurrencyVariant.Truncated)
      }
      tickLine={false}
      yAxisId="right"
    />
  );

  return (
    <Styled.RechartsContainer
      position="relative"
      py={3}
      height={300}
      width="100%"
    >
      <ResponsiveContainer>
        <ComposedChart
          width={composedChartWidth}
          data={monthlyReturnData}
          margin={composedChartMargin}
        >
          <CartesianGrid
            stroke={Styled.CartesianGridStroke}
            strokeDasharray="6"
            vertical={false}
          />
          {xAxis({})}
          {yAxis1}
          {yAxis2}
          <Bar
            barSize={barSize}
            dataKey="cash.totalReturns"
            stackId={barStackId}
            fill={Styled.BarFillCash}
            xAxisId={barXAxisId}
            yAxisId={barYAxisId}
          />
          <Bar
            barSize={barSize}
            dataKey="investments.totalReturns"
            stackId={barStackId}
            fill={Styled.BarFillManagedInvestments}
            xAxisId={barXAxisId}
            yAxisId={barYAxisId}
          />
        </ComposedChart>
      </ResponsiveContainer>

      <ResponsiveContainer>
        <ComposedChart
          width={composedChartWidth}
          data={dailyAUMData}
          margin={composedChartMargin}
        >
          {xAxis({ hideTick: true })}
          <XAxis hide xAxisId={2} scale="band" />
          {yAxis1}
          {yAxis2}
          <Line
            dot={false}
            type="monotone"
            data={dailyAUMData}
            dataKey="amount"
            stroke={Styled.AUMLineStroke}
            strokeWidth={2}
            activeDot={false}
            xAxisId={2}
            yAxisId="right"
          />
          <Tooltip content={<CustomTooltip />} />
        </ComposedChart>
      </ResponsiveContainer>
    </Styled.RechartsContainer>
  );
};
