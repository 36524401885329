import React from 'react';
import { Spinner } from 'components/core';
import { useHistory, useParams } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { V2Layout } from 'layouts';
import { SetPassword } from 'modules/onboarding/v3-streamlined-onboarding/no-auth/pages/set-password';
import { InvalidToken } from 'pages/onboarding';
import { useStoreAuthentication } from 'stores';
import { useRedeemToken } from 'modules/no-auth/hooks/use-redeem-token';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { useSendEvent, TrackEventName } from 'modules/tracking';
import { useValidateToken } from './hooks/use-validate-token';

export const SignInAccept: React.FC = () => {
  const { sendEvent } = useSendEvent();
  const { signOut } = useSignOut();
  const { redeemToken } = useRedeemToken();
  const history = useHistory();

  const { token } = useParams<{ token: string }>();
  const { data: validateTokenResponse, isLoading: isLoadingValidateToken } =
    useValidateToken(token);

  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);

  const emailFromToken = validateTokenResponse?.email;

  // if a user is already logged in and reaches this page, sign them out
  if (isSignedIn) {
    signOut();
  }

  if (isLoadingValidateToken) {
    return <Spinner />;
  }

  if (emailFromToken) {
    return (
      <V2Layout hideFooter>
        <SetPassword
          email={emailFromToken}
          onSuccessCallback={() => {
            // redeem invite token
            redeemToken(token).then(() => {
              sendEvent(TrackEventName.RedeemInviteToken, { token });
            });

            // This needs to be wrapped in setTimeout so that it goes to the end of the event queue (and allows for the loggedin state to be set)
            setTimeout(
              () =>
                history.push(
                  getVersionedPath({
                    path: RoutesPath.pages.home.path,
                    version: 3,
                  }),
                ),
              0,
            );
          }}
        />
      </V2Layout>
    );
  }

  return <InvalidToken />;
};
