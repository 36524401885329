import React, { useEffect } from 'react';
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { Box } from '@material-ui/core';
import { useDashboardActivity } from 'hooks/portal-gateway/use-dashboard-activity';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { DashboardListScheduleTransfers } from 'modules/2024-q1/schedule-transfer/list-schedule-transfers/dashboard-list-schedule-transfers/dashboard-list-schedule-transfers';
import {
  TransactionsSection,
  TransactionsSectionVariant,
} from './components/transactions-section/transactions-section';

export const Transactions: React.FC = () => {
  const loadingContext = useLoadingContext();

  const { data: dashboardActivity, isLoading: isLoadingDashboardActivity } =
    useDashboardActivity();

  useEffect(() => {
    loadingContext.setLoading(isLoadingDashboardActivity);
  }, [isLoadingDashboardActivity]);

  if (
    isLoadingDashboardActivity ||
    !dashboardActivity?.transactions ||
    dashboardActivity.transactions.length === 0
  ) {
    return null;
  }

  return (
    <Box mt={4}>
      <FeatureFlag
        enabled={
          <section id="scheduled-transfers">
            <DashboardListScheduleTransfers />
          </section>
        }
        flag={FeatureFlags.REACT_APP_2024_Q1_SCHEDULED_TRANSFERS}
      />

      <section id="pending-transactions">
        <TransactionsSection
          transactions={dashboardActivity.transactions}
          variant={TransactionsSectionVariant.Pending}
        />
      </section>

      <TransactionsSection
        transactions={dashboardActivity.transactions}
        variant={TransactionsSectionVariant.Completed}
      />
    </Box>
  );
};
