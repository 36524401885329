import React from 'react';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { useTracking, TrackEventName } from 'modules/tracking';

export const InviteUserLink = () => {
  const { trackEvent } = useTracking<{
    eventName: TrackEventName;
  }>({
    eventName: TrackEventName.InviteTeamMemberButtonClicked,
  });

  const handleClick = () => {
    trackEvent({});
  };

  return (
    <Link
      onClick={handleClick}
      to={RoutesPath.pages.settings.pages.teamInvite.path}
    >
      <Button>
        <ButtonContent>Invite Team Member</ButtonContent>
      </Button>
    </Link>
  );
};
