import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconXCircleGray } from 'assets/icons/checkmarks/x-circle-gray.svg';
import { ReactComponent as Plus } from 'assets/icons/other/plus.svg';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Bullet } from './components/bullet/bullet';
import { DepositButton } from './components/deposit-button/deposit-button';
import { Styled } from './growth-promo-details.style';

interface Props {
  handleDrawer: ({
    clickElement,
    clickText,
    drawerDirection,
  }: {
    clickElement?: string | undefined;
    clickText?: string | undefined;
    drawerDirection: boolean;
  }) => void;
}

export const GrowthPromoDetails: React.FC<Props> = ({ handleDrawer }) => {
  const { trackEvent, Track } = useTracking<{
    component: string;
    eventName: TrackEventName;
  }>({
    component: 'Growth Modal Details',
  });

  return (
    <Track>
      <Box px={2.5}>
        <Box
          bgcolor="white"
          height={20}
          position="sticky"
          top={0}
          left={0}
          right={0}
        />

        <Box mb={2}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography color="grey4" variant={TypographyVariant.Paragraph2}>
                Ending Dec 15, 2023
              </Typography>
            </Grid>

            <Grid item>
              <SVGBox
                cursor="pointer"
                onClick={() =>
                  handleDrawer({
                    clickElement: 'Close Button',
                    drawerDirection: false,
                  })
                }
              >
                <Styled.X>
                  <Plus />
                </Styled.X>
              </SVGBox>
            </Grid>
          </Grid>
        </Box>

        <Typography color="green1" variant={TypographyVariant.Header3}>
          Get 50% Off Investment Management Fees For a Full Year When You
          Deposit $2M+
        </Typography>

        <Box my={3}>
          <Box mb={1}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              Take control of your business&apos;s financial destiny! Join
              Treasure and deposit a minimum of $2 million before December 15th
              to unlock your 50% discount.*
            </Typography>
          </Box>

          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            * 50% discount applies to funds deposited between now and December
            15th, 2023.
          </Typography>
        </Box>

        <Divider />

        <Box my={3}>
          <Typography color="black19" variant={TypographyVariant.Header4}>
            How It Works
          </Typography>
        </Box>

        <Box mb={2}>
          <Bullet number={1}>
            New Customers create a Treasure account in &lt; 10 minutes. Existing
            Customers log in to claim offer.
          </Bullet>
        </Box>

        <Box mb={2}>
          <Bullet number={2}>
            Deposit a minimum of $2,000,000.00 by 12/15/23.
          </Bullet>
        </Box>

        <Box mb={2}>
          <Bullet number={3}>
            Discount is applied to the AUM deposited between time of offer and
            12/15/23 (the &quot;Discounted AUM&quot;).
          </Bullet>
        </Box>

        <Box mb={2}>
          <Bullet number={4}>
            Discount active through 12/31/24 as long as a minimum Discounted AUM
            balance of $1,000,000.00 is maintained.
          </Bullet>
        </Box>

        {/* adds buffer space for the absolute position below */}
        <Box pt={1} pb={2.5} visibility="hidden">
          <DepositButton />
        </Box>

        <Box
          bgcolor="white"
          position="absolute"
          bottom={0}
          left={20}
          right={20}
        >
          <Box pt={1} pb={2.5}>
            <DepositButton />
          </Box>
        </Box>

        <Box
          bgcolor="white"
          height={20}
          position="sticky"
          bottom={0}
          left={0}
          right={0}
        />
      </Box>
    </Track>
  );
};
