import React, { useEffect } from 'react';
import { displayHeader } from 'pages/v3-portal/dashboard/components/dashboard-column-treasure-reserve/dashboard-column-performance-charts';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useDashboardActivity } from 'hooks/banking/use-dashboard-activity';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';

import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';

import {
  applyExistingAllocation,
  applyFee,
  applyRates,
  initialAllocation,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { PortfolioState } from 'modules/portfolio/enums/portfolio-state';
import { useProducts } from 'hooks/products/use-products';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { Transaction } from 'modules/dashboard/treasure-reserve/transactions/components/transactions-table/transactions-table';
import { RealizedTreasureReserveBreakdown } from './components/realized-treasure-reserve-breakdown/realized-treasure-reserve-breakdown';
import { SetTreasureReserveAllocation } from './components/set-treasure-reserve-allocation/set-treasure-reserve-allocation';
import { TreasureReserveBreakdown } from './components/treasure-reserve-breakdown/treasure-reserve-breakdown';

interface Props {
  setShowPortfolio: (showPortfolio: boolean) => void;
}

export const PortfolioAllocationDashboardEntryPoint: React.FC<Props> = ({
  setShowPortfolio,
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { Track, trackEvent } = useTracking<{
    eventName: TrackEventName;
    section: TrackPageSection;
  }>({
    section: TrackPageSection.PortfolioAllocation,
  });

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const {
    data: portfolios,
    isError: isErrorPortfolios,
    isLoading: isLoadingPortfolios,
  } = usePortfolios(business?.Id);
  const { data: treasureReserve, isLoading: isLoadingTreasureReserve } =
    useTreasureReserve(business?.Id);
  const { data: products } = useProducts(business?.Id);
  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);
  const treasureFee = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED,
  )
    ? products?.treasureHighYieldFee || 0
    : 0;

  // TODO: temporarily use transactions data while account balances gets fixed
  const { data: dashboardActivity, isLoading: isLoadingDashboardActivity } =
    useDashboardActivity();
  const pendingTransactions = dashboardActivity?.transactions?.filter(
    (transaction: Transaction) =>
      transaction.status === 'IN_PROGRESS' || transaction.status === 'PENDING',
  );

  const totalBalance = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2023_Q3_AGGREGATE_BALANCES_ON_DASH,
  )
    ? aggregateBalances?.aum?.total?.amount
    : portfolios?.amountsInDollars?.totalAmount;

  const pendingDeposits = pendingTransactions?.reduce(
    (sum: number, pendingTransaction: Transaction) =>
      sum +
      (pendingTransaction.description.includes('into')
        ? Number(pendingTransaction.amount)
        : 0),
    0,
  );

  const totalBalanceAndPendingDeposits = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2023_Q3_AGGREGATE_BALANCES_ON_DASH,
  )
    ? Number(totalBalance)
    : Number(totalBalance) + Number(pendingDeposits);

  const treasureReserveHighYieldBalanceAsNumber = Number(
    treasureReserve?.treasureHighYieldBalance,
  ); // TODO: (API) should be number but api returns string

  const showNoFunds = totalBalance === 0 && pendingDeposits === 0;

  const allBalanceIsInCash = treasureReserveHighYieldBalanceAsNumber === 0;

  const showSetAllocation =
    (pendingDeposits > 0 && allBalanceIsInCash) ||
    (allBalanceIsInCash && !showNoFunds);

  const showTreasureReserveBreakdown =
    portfolios !== undefined && portfolios.portfolios[0]?.allocations;

  const showRealizedTreasureReserveBreakdown =
    showTreasureReserveBreakdown &&
    portfolios?.portfolios[0]?.state === PortfolioState.Active;

  useEffect(() => {
    if (isErrorPortfolios) {
      setShowPortfolio(false);
    }

    if (portfolios?.rates) {
      applyRates(portfolios.rates);
    }
  }, [isErrorPortfolios, portfolios, setShowPortfolio]);

  useEffect(() => {
    applyFee(treasureFee);
  }, [treasureFee]);

  if (
    isLoadingBusiness ||
    isLoadingDashboardActivity ||
    isLoadingPortfolios ||
    isLoadingTreasureReserve ||
    isLoadingAggregateBalances
  ) {
    return null;
  }

  // TODO: show custom rates or don't show at all
  // if the user has an allocation saved, show that allocation
  // if the user is currently all cash, show select allocation
  // if the user has a pending sweep, show select allocation
  // if the user doesn't fall into any of these buckets, show old ui
  const showEntryPoint = () => {
    if (showRealizedTreasureReserveBreakdown) {
      return (
        <RealizedTreasureReserveBreakdown setShowPortfolio={setShowPortfolio} />
      );
    }

    if (
      showTreasureReserveBreakdown &&
      showNoFunds &&
      business?.Properties?.portfolioAllocationNoFundsAUM
    ) {
      trackEvent({
        eventName:
          TrackEventName.PortfolioAllocationPendingAllocationNoFundsShown,
      });

      return (
        <TreasureReserveBreakdown
          allocation={applyExistingAllocation(portfolios?.portfolios[0])}
          aum={business?.Properties?.portfolioAllocationNoFundsAUM}
        />
      );
    }

    if (showTreasureReserveBreakdown) {
      trackEvent({
        eventName: TrackEventName.PortfolioAllocationPendingAllocationShown,
      });

      return (
        <TreasureReserveBreakdown
          allocation={applyExistingAllocation(portfolios?.portfolios[0])}
          aum={totalBalanceAndPendingDeposits}
        />
      );
    }

    if (showSetAllocation) {
      trackEvent({
        eventName: TrackEventName.PortfolioAllocationSetAllocationShown,
      });

      return (
        <SetTreasureReserveAllocation
          aum={totalBalanceAndPendingDeposits}
          pendingBalance={pendingDeposits}
        />
      );
    }

    if (showNoFunds) {
      trackEvent({
        eventName: TrackEventName.PortfolioAllocationNoFundsShown,
      });

      return <SetTreasureReserveAllocation aum={0} pendingBalance={0} />;
    }

    return setShowPortfolio(false);
  };

  return (
    <Track>
      {displayHeader('Treasure Reserve')}

      {showEntryPoint()}
    </Track>
  );
};
