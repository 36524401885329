import React, { useEffect, useState } from 'react';
import { useTranslation } from 'app/i18n';
import { i18nNamespaceMFAContent } from 'modules/2023-q3/components/mfa/mfa';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { MFAResendCode } from '../mfa-resend-code/mfa-resend-code';

const displayPlural = (number: number, text: string) => {
  return number > 1 ? `${text}s` : text;
};

interface MFATryAgainProps {
  handleSendMFACode: () => void;

  time: number;
}

export const MFATryAgain: React.FC<MFATryAgainProps> = ({
  handleSendMFACode,
  time,
}) => {
  const { t } = useTranslation(i18nNamespaceMFAContent);

  const [timeRemaining, setTimeRemaining] = useState(time);

  const timeRemainingMinutes = Math.floor(timeRemaining / 60);
  const timeRemainingSeconds = Math.floor(timeRemaining % 60);

  let timeRemainingText;

  // TODO: there's a better way to do the below, combine with 'Try again in'
  if (timeRemaining % 60 === 0) {
    timeRemainingText = `${timeRemainingMinutes} ${t('minute', {
      count: timeRemainingMinutes,
    })}`;
  } else if (timeRemaining < 60) {
    timeRemainingText = `${timeRemainingSeconds} ${t('second', {
      count: timeRemainingSeconds,
    })}`;
  } else {
    timeRemainingText = `${timeRemainingMinutes} ${t('min', {
      count: timeRemainingMinutes,
    })} ${t('and')} ${timeRemainingSeconds} ${t('second', {
      count: timeRemainingSeconds,
    })}`;
  }

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(
        () => setTimeRemaining(timeRemaining - 1),
        1000,
      );
      return () => clearInterval(timer);
    }

    return;
  }, [timeRemaining]);

  if (timeRemaining > 0) {
    return (
      <>
        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {t('Still not seeing the email?')}
        </Typography>

        <Box mt={0.5}>
          <Typography color="grey4" variant={TypographyVariant.Paragraph2}>
            {t('Try again in')} {timeRemainingText}
          </Typography>
        </Box>
      </>
    );
  }

  return <MFAResendCode handleSendMFACode={handleSendMFACode} />;
};
