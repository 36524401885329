import React from 'react';
import { Box } from '@material-ui/core';
import { Footer as LayoutFooter } from 'components/layouts';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { useLoadingContext } from 'components/layouts/contexts/loading';

interface FooterProps {
  showProductsDisclosure?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ showProductsDisclosure }) => {
  const loadingContext = useLoadingContext();

  return (
    <>
      {!loadingContext.loading && (
        <Box pt={{ xs: 5, lg: 7 }}>
          <CenteredContainer>
            <LayoutFooter
              showProductsDisclosure={showProductsDisclosure}
              textColor="hitGrey"
              topDivider
            />
          </CenteredContainer>
        </Box>
      )}
    </>
  );
};
