import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface BusinessState {
  businessId: string | undefined;
  resetBusinessId: () => void;
  setBusinessId: (businessId: string) => void;
}

export const useStoreBusiness = create<BusinessState>()(
  devtools((set) => ({
    businessId: undefined,
    resetBusinessId: () => set({ businessId: undefined }),
    setBusinessId: (businessId: string) => set({ businessId }),
  })),
);
