import styled from 'styled-components';
import { CloseSharp } from '@material-ui/icons/';

export const ToastCloseButton = styled(CloseSharp)`
  color: var(--color-hitGrey);
`;

export const Styled = {
  ToastCloseButton,
};
