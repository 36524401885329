import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { Size } from 'enums';
import { breakpoints } from 'styles/css-constants';

interface FormContainerProps {
  size?: Size;
}

const FormContainer = styled(Box)<FormContainerProps>`
  border: 1px solid var(--color-grey6);
  border-radius: var(--border-radius-06px);
  width: 548px;

  .MuiInputBase-input {
    font-family: Nunito Sans;
  }

  ${(props) => {
    if (props.size) {
      switch (props.size) {
        case Size.Medium:
          return css`
            width: 730px;
          `;
        default:
      }
    }

    return null;
  }}

  ${breakpoints.down('sm')} {
    border: 0 none;
    width: 100%;
  }
`;

export const Styled = {
  FormContainer,
};
