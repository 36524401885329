import React from 'react';
import { Hidden } from '@material-ui/core';
import { Spinner } from 'components/core';
import { useLoadingContext } from 'components/layouts/contexts/loading';
import { DashboardErrors } from 'components/dashboard';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

export const DashboardContainer: React.FC = ({ children }) => {
  const loadingContext = useLoadingContext();

  // using the hidden component (css implementation) to load components
  // in the background. otherwise, returning the spinner instead of the components
  // would keep loading/unloading them which puts loadingContext in a loop
  return (
    <>
      {loadingContext.loading && <Spinner />}

      <Hidden implementation="css" xsUp={loadingContext.loading}>
        <FeatureFlag
          disabled={<DashboardErrors />}
          flag={FeatureFlags.REACT_APP_2023_Q3_BANKING_PAGE_ENABLED}
        />

        <CenteredContainer>{children}</CenteredContainer>
      </Hidden>
    </>
  );
};
