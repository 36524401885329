import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useCashAccount = (businessId: string): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  return useQuery([ReactQueryKeys.CashAccount, businessId], async () => {
    const response = await treasureApi().get('/cash-account', {});

    return response.data;
  });
};
