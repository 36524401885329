import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import { ReactComponent as TreasureLogoWithText } from 'assets/logos/horizontal/treasure_logo_green.svg';
import { ReactComponent as TreasureLogoSymbol } from 'assets/logos/symbol/treasure_logo_green.svg';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import {
  WhiteLabelLogo,
  WhiteLabelLogoVariant,
} from 'modules/2023-q4/white-label/components/white-label-logo/white-label-logo';

export const TreasureLogo = () => {
  return (
    <Box position="relative" top={1}>
      <Link
        to={getVersionedPath({
          path: RoutesPath.pages.dashboard.path,
          version: 3,
        })}
      >
        <Hidden smUp>
          <FeatureFlag
            disabled={<TreasureLogoSymbol />}
            enabled={
              <WhiteLabelLogo variant={WhiteLabelLogoVariant.HeaderMobile} />
            }
            flag={FeatureFlags.REACT_APP_2023_Q4_WHITELABEL_ENABLED}
          />
        </Hidden>

        <Hidden xsDown>
          <FeatureFlag
            disabled={<TreasureLogoWithText />}
            enabled={<WhiteLabelLogo variant={WhiteLabelLogoVariant.Header} />}
            flag={FeatureFlags.REACT_APP_2023_Q4_WHITELABEL_ENABLED}
          />
        </Hidden>
      </Link>
    </Box>
  );
};
