import styled from 'styled-components';
import { Box } from '@material-ui/core';

const OverrideBox = styled(Box)`
  .MuiInputBase-input,
  .MuiAutocomplete-paper {
    font-family: var(--font-family);
    font-size: var(--font-size-body-02);
  }
`;

export const Styled = {
  OverrideBox,
};
