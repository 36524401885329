import React, { useEffect } from 'react';
import {
  i18nNamespace,
  DepositWithdrawalVariant,
  IDepositWithdrawal,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useConfirmNavigateAwayContext } from 'modules/2023-q3/components/confirm-navigate-away/confirm-navigate-away';
import { useTranslation } from 'app/i18n';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ReactComponent as IconXCircleGray } from 'assets/icons/checkmarks/x-circle-gray.svg';
import { useTracking, TrackEventName } from 'modules/tracking';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { Styled } from './confirmation-modal.style';

export const ConfirmationModal: React.FC<IDepositWithdrawal> = ({
  variant,
}) => {
  const { trackEvent } = useTracking<{
    clickElement?: string;
    clickText?: string;
    component: string;
    eventName: TrackEventName;
  }>({
    component: 'Confirm Navigate Away Modal',
  });

  const confirmNavigateAwayContext = useConfirmNavigateAwayContext();
  const { t } = useTranslation(i18nNamespace);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;
  const depositOrWithdrawal = t(isDeposit ? 'deposit' : 'withdrawal');
  const DepositOrWithdrawal = t(isDeposit ? 'Deposit' : 'Withdrawal');

  const confirmButtonText = t('Yes, Cancel', { DepositOrWithdrawal });
  const cancelButtonText = t('No, Continue', { DepositOrWithdrawal });

  const handleCancelButtonClick = () => {
    confirmNavigateAwayContext.cancel();
  };

  const handleCloseIconClick = () => {
    confirmNavigateAwayContext.cancel();

    trackEvent({
      clickElement: 'Close Icon',
      eventName: TrackEventName.Click,
    });
  };

  const handleConfirmButtonClick = () => {
    confirmNavigateAwayContext.confirm();
  };

  const confirmationModalBody = (
    <>
      <Box textAlign="right">
        <IconXCircleGray cursor="pointer" onClick={handleCloseIconClick} />
      </Box>

      <Box py={15} textAlign="center">
        <Typography color="black19" variant={TypographyVariant.Header3}>
          {t('Are you sure you want to cancel?', {
            depositOrWithdrawal,
          })}
        </Typography>

        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          {t('Cancelling message', {
            depositOrWithdrawal,
          })}
        </Typography>
      </Box>

      <Box>
        <Button
          bgColor="grey1"
          bgHoverColor="grey1"
          fullWidth
          onClick={handleConfirmButtonClick}
        >
          <Box px={2} py={1.5}>
            <Typography color="white" variant={TypographyVariant.Paragraph2}>
              {confirmButtonText}
            </Typography>
          </Box>
        </Button>

        <Box pt={2} textAlign="center">
          <Button
            bgColor="white"
            bgHoverColor="white"
            onClick={handleCancelButtonClick}
          >
            <Typography color="black19" variant={TypographyVariant.Paragraph2}>
              {cancelButtonText}
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );

  useEffect(() => {
    trackEvent({
      eventName: TrackEventName.Viewed,
    });
  }, []);

  return (
    <MobileView
      mobile={
        <Styled.ConfirmationModalContainer
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mobile
          p={3}
        >
          {confirmationModalBody}
        </Styled.ConfirmationModalContainer>
      }
    >
      <Styled.ConfirmationModalContainer
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={3}
      >
        {confirmationModalBody}
      </Styled.ConfirmationModalContainer>
    </MobileView>
  );
};
