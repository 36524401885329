import styled from 'styled-components';
import { Typography } from 'components/core';

const HelperText = styled(Typography)`
  font-size: 12px;
  font-style: italic;
`;

export const Styled = {
  HelperText,
};
