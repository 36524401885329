import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import {
  NotificationBox,
  NotificationBoxVariant,
} from 'modules/portfolio/allocation/components/allocation-modal/components';
import {
  AllocationBreakdown,
  AllocationBreakdownVariant,
} from 'modules/portfolio/allocation/components/allocation-breakdown/allocation-breakdown';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { Trans } from 'app/i18n';
import { i18nNamespaceAllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';

interface AllocationConfirmationProps {
  allocation: Allocation;

  aum: number;

  noFunds: boolean;
}
// THIS IS RENDERED BY ALLOCATION MODAL WHICH ALREADY SETS THE FEE

export const AllocationConfirmation: React.FC<AllocationConfirmationProps> = ({
  allocation,
  aum,
  noFunds,
}) => {
  return (
    <>
      <Box mb={5}>
        <NotificationBox
          variant={
            noFunds
              ? NotificationBoxVariant.Information
              : NotificationBoxVariant.Warning
          }
        >
          <Typography variant={TypographyVariant.Body}>
            <Trans
              i18nKey={
                noFunds
                  ? 'Portfolio Allocation Saved'
                  : 'Please confirm your allocations'
              }
              ns={i18nNamespaceAllocationModal}
            />
          </Typography>
        </NotificationBox>
      </Box>

      <AllocationBreakdown
        allocation={allocation}
        aum={aum}
        variant={AllocationBreakdownVariant.Modal}
      />
    </>
  );
};
