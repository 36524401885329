export enum Country {
  ALB = 'Albania',
  DZA = 'Algeria',
  ASM = 'American Samoa',
  AND = 'Andorra',
  AGO = 'Angola',
  AIA = 'Anguilla',
  ATA = 'Antarctica',
  ATG = 'Antigua and Barbuda',
  ARG = 'Argentina',
  ARM = 'Armenia',
  ABW = 'Aruba',
  AUS = 'Australia',
  AUT = 'Austria',
  AZE = 'Azerbaijan',
  BHS = 'Bahamas',
  BHR = 'Bahrain',
  BGD = 'Bangladesh',
  BRB = 'Barbados',
  BEL = 'Belgium',
  BLZ = 'Belize',
  BEN = 'Benin',
  BMU = 'Bermuda',
  BTN = 'Bhutan',
  BOL = 'Bolivia',
  BIH = 'Bosnia and Herzegovina',
  BWA = 'Botswana',
  BRA = 'Brazil',
  IOT = 'British Indian Ocean Territory',
  VGB = 'British Virgin Islands',
  BRN = 'Brunei',
  BGR = 'Bulgaria',
  BFA = 'Burkina Faso',
  BDI = 'Burundi',
  KHM = 'Cambodia',
  CMR = 'Cameroon',
  CAN = 'Canada',
  CPV = 'Cape Verde',
  CYM = 'Cayman Islands',
  CAF = 'Central African Republic',
  TCD = 'Chad',
  CHL = 'Chile',
  CHN = 'China',
  CXR = 'Christmas Island',
  CCK = 'Cocos Islands',
  COL = 'Colombia',
  COM = 'Comoros',
  COK = 'Cook Islands',
  CRI = 'Costa Rica',
  HRV = 'Croatia',
  CUW = 'Curacao',
  CYP = 'Cyprus',
  CZE = 'Czech Republic',
  COD = 'Democratic Republic of the Congo',
  DNK = 'Denmark',
  DJI = 'Djibouti',
  DMA = 'Dominica',
  DOM = 'Dominican Republic',
  TLS = 'East Timor',
  ECU = 'Ecuador',
  EGY = 'Egypt',
  SLV = 'El Salvador',
  GNQ = 'Equatorial Guinea',
  ERI = 'Eritrea',
  EST = 'Estonia',
  ETH = 'Ethiopia',
  FLK = 'Falkland Islands',
  FRO = 'Faroe Islands',
  FJI = 'Fiji',
  FIN = 'Finland',
  FRA = 'France',
  PYF = 'French Polynesia',
  GAB = 'Gabon',
  GMB = 'Gambia',
  GEO = 'Georgia',
  DEU = 'Germany',
  GHA = 'Ghana',
  GIB = 'Gibraltar',
  GRC = 'Greece',
  GRL = 'Greenland',
  GRD = 'Grenada',
  GUM = 'Guam',
  GTM = 'Guatemala',
  GGY = 'Guernsey',
  GIN = 'Guinea',
  GNB = 'Guinea-Bissau',
  GUY = 'Guyana',
  HTI = 'Haiti',
  HND = 'Honduras',
  HKG = 'Hong Kong',
  HUN = 'Hungary',
  ISL = 'Iceland',
  IND = 'India',
  IDN = 'Indonesia',
  IRQ = 'Iraq',
  IRL = 'Ireland',
  IMN = 'Isle of Man',
  ISR = 'Israel',
  ITA = 'Italy',
  CIV = 'Ivory Coast',
  JAM = 'Jamaica',
  JPN = 'Japan',
  JEY = 'Jersey',
  JOR = 'Jordan',
  KAZ = 'Kazakhstan',
  KEN = 'Kenya',
  KIR = 'Kiribati',
  XKX = 'Kosovo',
  KWT = 'Kuwait',
  KGZ = 'Kyrgyzstan',
  LAO = 'Laos',
  LVA = 'Latvia',
  LBN = 'Lebanon',
  LSO = 'Lesotho',
  LBR = 'Liberia',
  LBY = 'Libya',
  LIE = 'Liechtenstein',
  LTU = 'Lithuania',
  LUX = 'Luxembourg',
  MAC = 'Macau',
  MKD = 'Macedonia',
  MDG = 'Madagascar',
  MWI = 'Malawi',
  MYS = 'Malaysia',
  MDV = 'Maldives',
  MLI = 'Mali',
  MLT = 'Malta',
  MHL = 'Marshall Islands',
  MRT = 'Mauritania',
  MUS = 'Mauritius',
  MYT = 'Mayotte',
  MEX = 'Mexico',
  FSM = 'Micronesia',
  MDA = 'Moldova',
  MCO = 'Monaco',
  MNG = 'Mongolia',
  MNE = 'Montenegro',
  MSR = 'Montserrat',
  MAR = 'Morocco',
  MOZ = 'Mozambique',
  MMR = 'Myanmar',
  NAM = 'Namibia',
  NRU = 'Nauru',
  NPL = 'Nepal',
  NLD = 'Netherlands',
  ANT = 'Netherlands Antilles',
  NCL = 'New Caledonia',
  NZL = 'New Zealand',
  NIC = 'Nicaragua',
  NER = 'Niger',
  NGA = 'Nigeria',
  NIU = 'Niue',
  MNP = 'Northern Mariana Islands',
  NOR = 'Norway',
  OMN = 'Oman',
  PAK = 'Pakistan',
  PLW = 'Palau',
  PSE = 'Palestine',
  PAN = 'Panama',
  PNG = 'Papua New Guinea',
  PRY = 'Paraguay',
  PER = 'Peru',
  PHL = 'Philippines',
  PCN = 'Pitcairn',
  POL = 'Poland',
  PRT = 'Portugal',
  PRI = 'Puerto Rico',
  QAT = 'Qatar',
  COG = 'Republic of the Congo',
  REU = 'Reunion',
  ROU = 'Romania',
  RWA = 'Rwanda',
  BLM = 'Saint Barthelemy',
  SHN = 'Saint Helena',
  KNA = 'Saint Kitts and Nevis',
  LCA = 'Saint Lucia',
  MAF = 'Saint Martin',
  SPM = 'Saint Pierre and Miquelon',
  VCT = 'Saint Vincent and the Grenadines',
  WSM = 'Samoa',
  SMR = 'San Marino',
  STP = 'Sao Tome and Principe',
  SAU = 'Saudi Arabia',
  SEN = 'Senegal',
  SRB = 'Serbia',
  SYC = 'Seychelles',
  SLE = 'Sierra Leone',
  SGP = 'Singapore',
  SXM = 'Sint Maarten',
  SVK = 'Slovakia',
  SVN = 'Slovenia',
  SLB = 'Solomon Islands',
  SOM = 'Somalia',
  ZAF = 'South Africa',
  KOR = 'South Korea',
  SSD = 'South Sudan',
  ESP = 'Spain',
  LKA = 'Sri Lanka',
  SDN = 'Sudan',
  SUR = 'Suriname',
  SJM = 'Svalbard and Jan Mayen',
  SWZ = 'Swaziland',
  SWE = 'Sweden',
  CHE = 'Switzerland',
  TWN = 'Taiwan',
  TJK = 'Tajikistan',
  TZA = 'Tanzania',
  THA = 'Thailand',
  TGO = 'Togo',
  TKL = 'Tokelau',
  TON = 'Tonga',
  TTO = 'Trinidad and Tobago',
  TUN = 'Tunisia',
  TUR = 'Turkey',
  TKM = 'Turkmenistan',
  TCA = 'Turks and Caicos Islands',
  TUV = 'Tuvalu',
  VIR = 'U.S. Virgin Islands',
  UGA = 'Uganda',
  UKR = 'Ukraine',
  ARE = 'United Arab Emirates',
  GBR = 'United Kingdom',
  USA = 'United States',
  URY = 'Uruguay',
  UZB = 'Uzbekistan',
  VUT = 'Vanuatu',
  VAT = 'Vatican',
  VNM = 'Vietnam',
  WLF = 'Wallis and Futuna',
  ESH = 'Western Sahara',
  YEM = 'Yemen',
  ZMB = 'Zambia',
  ZWE = 'Zimbabwe',
}
