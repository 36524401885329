import React from 'react';
import { useSignOut } from 'modules/no-auth/hooks/use-sign-out';
import { ClickAnchor } from 'components/core';
import { useTracking, TrackEventName } from 'modules/tracking';
// import { useOnboardingContext } from '../onboarding-wizard/onboarding-wizard';

export const SignOutLink: React.FC = ({ children }) => {
  const { signOut } = useSignOut();
  // const { currentPage } = useOnboardingContext();
  const { trackEvent } = useTracking();

  return (
    <ClickAnchor
      onClick={(e) => {
        return new Promise<void>((resolve) => {
          // finishes sign out processs
          signOut();
          resolve();
        }).then(() => {
          // and then track the event
          trackEvent({
            eventName: TrackEventName.IntakeSignOutClicked,
            // page: currentPage,
          });
        });
      }}
    >
      {children}
    </ClickAnchor>
  );
};
