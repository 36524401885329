import React from 'react';
import { Box } from '@material-ui/core';
import { FootnoteProvider, LoadingProvider } from 'components/layouts';
import { Footer } from './components/footer/footer';

export const NoAuthLayout: React.FC = ({ children }) => {
  return (
    <FootnoteProvider>
      <LoadingProvider>
        {/* push the footer down */}
        <Box bgcolor="white" display="table" height="inherit" width="100%">
          {children}
        </Box>

        <Box bgcolor="white">
          <Footer />
        </Box>
      </LoadingProvider>
    </FootnoteProvider>
  );
};
