import styled from 'styled-components';
import {
  TableCell as TableCellAlias,
  TableContainer as TableContainerAlias,
} from '@material-ui/core';
import { Text } from 'components/core';

const TableCell = styled(TableCellAlias)`
  padding: var(--spacing-01);
`;

const TableContainer = styled(TableContainerAlias)`
  max-height: 320px; // TODO: force show scrollbar
`;

const TableHeadCell = styled(TableCell)`
  background-color: var(--color-white);
`;

const TableHeadText = styled(Text)`
  font-weight: 700;
`;

export const Styled = {
  TableCell,
  TableContainer,
  TableHeadCell,
  TableHeadText,
};
