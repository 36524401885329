import React from 'react';
import styled, { css } from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
} from '@material-ui/core';
import { Typography } from 'components/core';
import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrows/caret-down.svg';

interface ArrowDropDownIconProps {
  collapsed?: boolean;
}

const AccordionIcon = styled(({ collapsed, ...rest }) => (
  <ArrowDropDownIcon {...rest} />
))<ArrowDropDownIconProps>`
  ${(props) =>
    props.collapsed &&
    css`
      transform: rotate(180deg); ;
    `};
`;

const Accordion = styled(MuiAccordion)`
  position: initial; // removes border

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;

    ${AccordionIcon} {
      transform: rotate(180deg);
    }
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  background-color: var(--color-ecruWhite);
  display: inherit;
  padding: 0;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0;
  user-select: auto;

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: inherit;
  }

  & .MuiAccordionSummary-content {
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

const CollapseActionBox = styled(Box)`
  cursor: pointer;
`;

const Divider = styled.hr`
  border: 0.5px solid var(--color-grey6);
`;

const TableText = styled(Typography)`
  line-height: 36px;
`;

export const Styled = {
  Accordion,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
  CollapseActionBox,
  Divider,
  TableText,
};
