import styled, { css } from 'styled-components';
import { InputLabel as InputLabelAlias } from '@material-ui/core';

interface InputLabelProps {
  cursor?: string;
}

const InputLabel = styled(InputLabelAlias)<InputLabelProps>`
  position: relative;
  transform: initial;

  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
`;

export const Styled = {
  InputLabel,
};
