import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useInviteUser = (): UseMutationResult<any, any, any> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const inviteUser = async ({
    addresses,
    citizenshipCountry,
    dateOfBirth,
    email,
    firstName,
    jobTitle,
    lastName,
    nationalIdentifier,
    residencyCountry,
  }: {
    addresses: {
      address1: string;
      address2?: string;
      city: string;
      state: string;
      zipCode: string;
    }[];
    citizenshipCountry: string;
    dateOfBirth: string;
    email: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    nationalIdentifier: string;
    residencyCountry: string;
  }) => {
    const { data } = await treasureApi().post('/invite-user', {
      person: {
        addresses,
        citizenshipCountry,
        dateOfBirth,
        email,
        firstName,
        jobTitle,
        lastName,
        nationalIdentifier,
        residencyCountry,
      },
    });

    return data;
  };

  return useMutation(inviteUser, {
    onSuccess: () => {
      queryClient.refetchQueries(ReactQueryKeys.BusinessMembers);
    },
  });
};
