import React from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import { Currency, CurrencyVariant } from 'components/core';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { dayjs } from 'app/dayjs';
import { CashDirection } from 'enums';
import { DateAndValue } from 'types';
import { Styled } from './bar-chart-by-month.style';

interface BarChartByMonthProps {
  data: DateAndValue[];

  variant?: CashDirection;
}

const tooltipFormatter = (value: any) => {
  return [
    typeof value === 'number' ? (
      <Currency number={value} variant={CurrencyVariant.Full} />
    ) : (
      value
    ),
    'Transactions',
  ];
};

const tooltipLabelFormatter = (label: string) => {
  return dayjs.utc(label).format('MMM');
};

const dateFormatter = (value: string) => dayjs.utc(value).format('MMM');
const moneyFormatter = (value: number) =>
  renderToString(
    <Currency
      number={value}
      renderText={(value) => <>{value}</>}
      variant={CurrencyVariant.Truncated}
    />,
  ); // TODO: is this the best approach?

const BarChartByMonth: React.FC<BarChartByMonthProps> = ({ data, variant }) => {
  const sortedData = data.sort((a, b) => (a.date > b.date ? 1 : -1));

  return (
    <Styled.ChartContainer>
      <ResponsiveContainer height={Styled.ChartHeight} width="100%">
        <BarChart data={sortedData}>
          <CartesianGrid vertical={false} stroke={Styled.CartesianGridStroke} />

          <XAxis
            axisLine={false}
            dataKey="date"
            interval="preserveEnd"
            tick={Styled.TickStyle}
            tickFormatter={dateFormatter}
            tickLine={false}
            type="category"
          />

          <YAxis
            interval="preserveEnd"
            stroke="none"
            tick={Styled.TickStyle}
            tickFormatter={moneyFormatter}
            tickLine={false}
          />

          <Tooltip
            cursor={false}
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
          />

          <Bar dataKey="value" barSize={10} radius={2}>
            {sortedData.map((entry, index) => {
              return (
                <Cell
                  key={`bar-cell-${index}`}
                  fill={
                    variant === CashDirection.Outflows
                      ? Styled.BarFillOutflows
                      : Styled.BarFillInflows
                  }
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Styled.ChartContainer>
  );
};

BarChartByMonth.propTypes = {
  data: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(Object.values(CashDirection) as CashDirection[]),
};

export { BarChartByMonth };
