import styled, { css } from 'styled-components';
import { Backdrop as BackdropAlias } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { CSSTransition as CSSTransitionAlias } from 'react-transition-group';
import { Size } from 'enums';
import { breakpoints } from 'styles/css-constants';
import { Styled as MoreInformation } from './more-information-modal.style';
interface StyledModal {
  size?: string;
}

const Backdrop = styled(BackdropAlias)`
  background: rgba(66, 69, 69, 0.5);
  backdrop-filter: blur(2px);
`;

const CloseIcon = styled(Close)`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;

const CSSTransition = styled(CSSTransitionAlias)`
  &.animate-modal- {
    &appear {
      opacity: 0;
      top: -20px;
    }

    &appear-active {
      transition: 500ms;
      opacity: 1;
      top: 0;
    }

    &exit {
      opacity: 1;
    }

    &exit-active {
      transition: opacity 200ms;
      opacity: 0;
    }
  }
`;

const Modal = styled.div<StyledModal>`
  position: relative;
  max-width: ${(props) => {
    if (props.size === Size.ExtraSmall) {
      return '400px';
    }
    if (props.size === Size.Small) {
      return '500px';
    }
    if (props.size === Size.Medium) {
      return '550px';
    }
    if (props.size === Size.Large) {
      return '800px';
    }
    if (props.size === Size.ExtraLarge) {
      return '1250px';
    }
    return '700px';
  }};
  min-height: 50px;
  max-height: ${(props) => (props.size === 'small' ? '515px' : '100%')};
  background-color: var(--color-white);
  border-radius: var(--border-radius-05);
  overflow: auto;
  margin: 40px auto;
  max-height: calc(100vh - 80px);

  @media screen and (min-height: 768px) {
    margin: 5% auto 5% auto;
    max-height: calc(100vh - 20%);
  }

  ${(props) => {
    if (props.size === Size.FullScreen) {
      return css`
        min-height: 100vh;
        min-height: -webkit-fill-available;
        max-height: none;
        margin: 0;
        border-radius: 0;
      `;
    }
  }}
`;

export const Styled = {
  Backdrop,
  CloseIcon,
  CSSTransition,
  Modal,
  ...MoreInformation,
};
