import styled from 'styled-components';
import { Box, Slider as SliderAlias } from '@material-ui/core';

const Slider = styled(SliderAlias)`
  position: relative;
  top: 2px;

  &.MuiSlider-root {
    color: var(--color-brown4b);

    .MuiSlider-track {
      background-color: var(--color-primary);
    }

    .MuiSlider-thumb {
      background-color: var(--color-white);
      border: 2px solid var(--color-primary);

      &.MuiSlider-active {
        box-shadow: 0px 0px 0px 11px rgba(63, 81, 181, 0.16);
      }
    }
  }
`;

const SliderContainer = styled(Box)`
  background-color: var(--color-brown7);
  border-radius: var(--border-radius-06);
`;

export const Styled = {
  Slider,
  SliderContainer,
};
