import { TextFormFieldSpecs } from 'modules/form-helpers/index';

type FormFields = {
  email: TextFormFieldSpecs;
  password: TextFormFieldSpecs;
};

export const formFields: FormFields = {
  email: {
    label: 'Email',
    name: 'email',
    placeholder: 'Enter your email',
    validations: {
      required: true,
    },
  },
  password: {
    label: 'Password',
    name: 'password',
    placeholder: 'Enter your password',
    validations: {
      required: true,
    },
  },
};
