import {
  AllocationModalRowKeys,
  getRowHeader,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import {
  Allocation,
  getAllocationAmount,
  getAllocationReturn,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import {
  cellText,
  currency,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-typography/allocation-typography';

export const getReturnAmounts = ({
  allocations,
  aum,
}: {
  allocations: Allocation;
  aum: number;
}) => {
  const showReturnAmount = (
    allocation: number,
    allocationReturn: number,
    aum: number,
  ) => {
    return cellText(
      currency(
        getAllocationReturn({
          allocationAmount: getAllocationAmount({
            allocation,
            aum,
          }),
          allocationReturn,
        }),
      ),
      'salem',
    );
  };

  const data = [
    getRowHeader({
      rowKey: AllocationModalRowKeys.ExpectedNetReturnAmount,
      hasTooltip: true,
    }),
  ];

  Object.entries(allocations).map(([productKey, product]) => {
    return data.push(
      showReturnAmount(product.allocation, product.return - product.fee, aum),
    );
  });

  return data;
};
