import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { ApexDocument } from 'types';

export const useDocuments = (
  businessId: string,
  postData: any = {},
): UseQueryResult<ApexDocument[], Error> => {
  const { treasureApi } = useTreasureApi(ApiKeys.Custodian);

  return useQuery<ApexDocument[], Error>(
    [ReactQueryKeys.Documents, businessId],
    async () => {
      try {
        const response = await treasureApi().post(
          `/business/${businessId}/reserve-account/custodian-documents`,
          postData,
        );

        return response.data.items;
      } catch (error) {
        return [];
      }
    },
    {
      enabled: !!businessId,
    },
  );
};
