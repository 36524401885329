import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Tooltip = styled(Box)`
  border-radius: var(--border-radius-08);
  box-shadow: 0 8px 16px -2px rgba(27, 33, 44, 0.12);

  & + .MuiTooltip-arrow {
    color: var(--color-white);
  }
`;

export const Styled = {
  Tooltip,
};
