import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFootnoteContext } from '.';

export interface FootnoteProps {
  children?: React.ReactNode;

  description: string | React.ReactNode;

  stayOnScreen?: boolean;

  symbol: string;
}

export const Footnote: React.FC<FootnoteProps> = ({
  children,
  description,
  stayOnScreen,
  symbol,
}) => {
  const { register } = useFootnoteContext();

  const footnote = useMemo(
    () => ({ description, stayOnScreen, symbol }),
    [description, stayOnScreen, symbol],
  );

  useEffect(() => {
    const unregister = register(footnote);

    return () => unregister();
  }, [footnote]);

  return (
    <>
      {children ?? ''}
      {symbol}
    </>
  );
};

Footnote.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  stayOnScreen: PropTypes.bool,
  symbol: PropTypes.string.isRequired,
};
