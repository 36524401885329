import React from 'react';
import { Styled } from './task-progress-step.style';

interface TaskProgressStepProps {
  active?: boolean;
}

export const TaskProgressStep: React.FC<TaskProgressStepProps> = ({
  active,
}) => {
  return <Styled.TaskProgressStep active={active} />;
};
