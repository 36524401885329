import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Spinner } from 'components/core';
import { createCtx } from 'utils/create-ctx';

// react-router
import { useHistory } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

// react-querry
import { useQueryClient } from 'react-query';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { useMutateOnboarding } from 'modules/onboarding/v3-streamlined-onboarding/hooks/mutate-onboarding';

// sweeps
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { TransferSteps } from 'pages/v3-portal/transfer/enums/transfer-steps';
import { BankAccount } from 'types';

// feature flags
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import { useMutateBusinessProperty } from 'hooks/business/use-mutate-business-property';
import { OnboardingTaskStyle } from 'modules/2023-q4/onboarding/v1-style-overlay/onboarding-task-style';

import { OnboardingStatus, ReactQueryKeys } from 'enums';
import { OnboardingSteps } from './enums';
import {
  BankDetails,
  BusinessDetails,
  BusinessOwners,
  BusinessRepresentative,
  BusinessStructure,
  Legalese,
  RareScenarios,
  SweepFunds,
} from './pages';

import { Header } from './layout/header';
import { VerticalStepBar } from './layout/status-bar/components';
import { useQueryOnboarding } from './hooks/query-onboarding';

interface OnboardingContext {
  businessId: string;

  currentStep: OnboardingSteps;

  nextStep: () => void;

  onboardingResponse: any;

  postOnboardingData: any;

  previousStep: () => void;

  showLoading: (loading: boolean) => void;

  isLoading: boolean;
}

interface OnboardingTransferContext {
  defaultAccountSelection: BankAccount | undefined;
  setDefaultAccountSelection: (account: BankAccount) => void;
  trackEvent: (event: any) => void;
  mfaSent: boolean;
  setMFASent: (show: boolean) => void;
  currentTransferStep: TransferSteps;
  setTransferStep: (step: TransferSteps) => void;
}

export const [useOnboardingContext, OnboardingCtxProvider] =
  createCtx<OnboardingContext>();

export const [useOnboardingTransferContext, OnboardingTransferCtxProvider] =
  createCtx<OnboardingTransferContext>();

const onboardingStepOrder = [
  OnboardingSteps.BusinessStructure,
  OnboardingSteps.BusinessDetails,
  OnboardingSteps.BusinessRepresentative,
  OnboardingSteps.RareScenarios,
  OnboardingSteps.BusinessOwners,
  OnboardingSteps.Disclosures,
  OnboardingSteps.BankDetails,
  OnboardingSteps.SweepFunds,
];

export const StreamlinedOnboarding: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { mutate: mutateBusinessProperty } = useMutateBusinessProperty();
  const claimGrothPromo = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2023_Q4_GROWTH_PROMO_ENABLED,
  );

  const history = useHistory();
  const queryClient = useQueryClient();

  const { data: business } = useBusiness();

  const { data: onboardingResponse } = useQueryOnboarding(business?.Id);

  const [currentStep, setCurrentStep] = useState(
    OnboardingSteps.BusinessStructure,
  );

  const [defaultAccountSelection, setDefaultAccountSelection] =
    useState<BankAccount | undefined>(undefined);
  const [currentTransferStep, setTransferStep] = useState(
    TransferSteps.TransferSettings,
  );
  const [mfaSent, setMFASent] = useState(false);

  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.Transfer,
  });

  const [loading, showLoading] = useState(true);

  const displayStep = (step: OnboardingSteps) => {
    switch (step) {
      case OnboardingSteps.BusinessDetails:
        return <BusinessDetails />;
      case OnboardingSteps.BusinessRepresentative:
        return <BusinessRepresentative />;
      case OnboardingSteps.RareScenarios:
        return <RareScenarios />;
      case OnboardingSteps.BusinessOwners:
        return <BusinessOwners />;
      case OnboardingSteps.BankDetails:
        return <BankDetails />;
      case OnboardingSteps.SweepFunds:
        return <SweepFunds />;
      case OnboardingSteps.Disclosures:
        return (
          <Legalese
            policyKeys={onboardingResponse.validationLayers.apexForms}
          />
        );
      case OnboardingSteps.BusinessStructure:
      default:
        return <BusinessStructure />;
    }
  };

  const nextStep = useCallback(() => {
    const stepOrder = onboardingStepOrder;
    const currentStepPosition = stepOrder.indexOf(currentStep) + 1;

    if (currentStepPosition === stepOrder.length) {
      showLoading(true);
      // when the user completes the flow the backend updates the smb member status
      // app-route redirects checking business?.OnboardingStatus
      queryClient.refetchQueries(ReactQueryKeys.Business);
    } else if (
      stepOrder[currentStepPosition] === OnboardingSteps.BankDetails &&
      onboardingResponse.data.accounts.length > 0
    ) {
      // if the user already has accounts connected, skip bank details step
      setCurrentStep(stepOrder[currentStepPosition + 1]);
    } else {
      setCurrentStep(stepOrder[currentStepPosition]);
    }
  }, [currentStep]);

  const previousStep = () => {
    const stepOrder = onboardingStepOrder;
    const currentStepPosition = stepOrder.indexOf(currentStep) - 1;
    setCurrentStep(stepOrder[currentStepPosition]);
  };

  // make the call to update onboarding and proceed to the next step
  const { isLoading: isLoadingMutateOnboarding, mutate } = useMutateOnboarding(
    business?.Id,
    nextStep,
  );

  const streamlinedOnboardingLoading = isLoadingMutateOnboarding || loading;

  useEffect(() => {
    if (onboardingResponse) {
      // check business member status
      if (business?.OnboardingStatus === OnboardingStatus.COMPLETE) {
        if (business?.AddedInitialSweep === null) {
          // PD-1100 - finish onboarding at the disclosure consent
          // The user can still finish adding accounts and deposit, but they can't go back to edit data
          if (onboardingResponse.data.accounts.length > 0) {
            setCurrentStep(OnboardingSteps.SweepFunds);
          } else {
            setCurrentStep(OnboardingSteps.BankDetails);
          }
        } else {
          // if the user has completed onboarding, redirect them home
          // onboardingComplete is a new path for SEO purposes (displays same as Dashboard)
          history.push(RoutesPath.pages.onboardingComplete.path);
        }
      }

      // nothing else to do, show the form
      showLoading(false);
    }
  }, [
    history,
    business?.OnboardingStatus,
    business?.AddedInitialSweep,
    onboardingResponse,
  ]);

  useEffect(() => {
    // scroll the user back to the top of the page
    // for longer forms

    window.scrollTo({ top: 0 });
  }, [streamlinedOnboardingLoading]);

  useEffect(() => {
    // claim growth promo
    // bit hacky since this will include people who started onbaording before promo but didn't finish
    if (claimGrothPromo) {
      mutateBusinessProperty({
        businessId: business?.Id,
        name: 'claimed2023DecemberGrowthPromo',
        value: true,
      });
    }
  }, []);

  if (!onboardingResponse) {
    return <Spinner />;
  }

  return (
    <FeatureFlag
      disabled={
        <>
          <Box position="fixed" width="100%" zIndex="1">
            <Header />
          </Box>

          <Box mt={18}>
            <OnboardingCtxProvider
              value={{
                businessId: business?.Id,
                currentStep,
                nextStep,
                onboardingResponse,
                postOnboardingData: mutate,
                previousStep,
                showLoading,
                isLoading: streamlinedOnboardingLoading,
              }}
            >
              <OnboardingTransferCtxProvider
                value={{
                  defaultAccountSelection,
                  setDefaultAccountSelection,
                  trackEvent,
                  mfaSent,
                  setMFASent,
                  currentTransferStep,
                  setTransferStep,
                }}
              >
                {streamlinedOnboardingLoading && (
                  <Box
                    position="fixed"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    zIndex={1}
                  >
                    <Spinner />
                  </Box>
                )}

                <Grid container>
                  <Grid item>
                    <Box position="sticky" top={150} ml={4} mr={10} mb={5}>
                      <VerticalStepBar
                        activeStep={currentStep}
                        steps={Object.values(OnboardingSteps)}
                      />
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box
                      maxWidth={420}
                      mx={3}
                      visibility={
                        streamlinedOnboardingLoading ? 'hidden' : 'visible'
                      }
                    >
                      {displayStep(currentStep)}
                    </Box>
                  </Grid>
                </Grid>
              </OnboardingTransferCtxProvider>
            </OnboardingCtxProvider>
          </Box>
        </>
      }
      enabled={
        <OnboardingCtxProvider
          value={{
            businessId: business?.Id,
            currentStep,
            nextStep,
            onboardingResponse,
            postOnboardingData: mutate,
            previousStep,
            showLoading,
            isLoading: streamlinedOnboardingLoading,
          }}
        >
          <OnboardingTransferCtxProvider
            value={{
              defaultAccountSelection,
              setDefaultAccountSelection,
              trackEvent,
              mfaSent,
              setMFASent,
              currentTransferStep,
              setTransferStep,
            }}
          >
            <OnboardingTaskStyle>
              {displayStep(currentStep)}
            </OnboardingTaskStyle>
          </OnboardingTransferCtxProvider>
        </OnboardingCtxProvider>
      }
      flag={FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED}
    />
  );
};
