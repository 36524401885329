import styled, { css } from 'styled-components';

interface LinkProps {
  hoverUnderline?: boolean;
}

const Link = styled.a<LinkProps>`
  cursor: pointer;

  ${(props) =>
    props.hoverUnderline &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const Styled = {
  Link,
};
