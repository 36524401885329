import React, { useState } from 'react';
import { ManualBankAccount } from 'types';
import { BankAccountACHInformationForm } from './components/bank-account-ach-information-form/bank-account-ach-information-form';
import { Confirmation } from './components/confirmation/confirmation';

enum ACHSteps {
  Form,
  Confirmation,
}

export const AddBankAccountACHInformation = () => {
  const [step, setStep] = useState(ACHSteps.Form);
  const [bankAccount, setBankAccount] = useState<ManualBankAccount>();

  if (step === ACHSteps.Confirmation) {
    return (
      <Confirmation
        bankAccount={bankAccount}
        nextStep={() => setStep(ACHSteps.Form)}
      />
    );
  }

  return (
    <BankAccountACHInformationForm
      nextStep={() => setStep(ACHSteps.Confirmation)}
      setBankAccount={setBankAccount}
    />
  );
};
