import styled, { css } from 'styled-components';
import { Typography } from 'components/core';
import { ProductType } from './product-yield';

interface ProductYieldWidgetProps {
  variant: string;
  hasEarnings: boolean;
}

const Amount = styled(Typography)`
  font-size: 22px;
`;

const EarningsAmount = styled(Amount)``;

const ProductYieldWidget = styled.div<ProductYieldWidgetProps>`
  ${(props) => {
    if (props.hasEarnings && props.variant === ProductType.cashAndHighYield) {
      return css`
        ${EarningsAmount} {
          color: var(--color-hanPurple);
        }
      `;
    }
  }}
`;

export const Styled = {
  Amount,
  EarningsAmount,
  ProductYieldWidget,
};
