import styled from 'styled-components';
import { Box } from '@material-ui/core';

const WidgetContainer = styled(Box)`
  box-shadow: var(--box-shadow-06);
`;

export const Styled = {
  WidgetContainer,
};
