import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { GrasshopperSunsetOptions } from './grasshopper-sunset-options/grasshopper-sunset-options';

export const GrasshopperSunset = () => {
  return (
    <FeatureFlag
      enabled={<GrasshopperSunsetOptions />}
      flag={FeatureFlags.REACT_APP_2024_Q3_GRASSHOPPER_SUNSET}
    />
  );
};
