import React, { useEffect } from 'react';
import {
  i18nNamespace,
  IDepositWithdrawal,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { Spinner } from 'components/core';
import {
  SelectSwitch,
  SelectSwitchOption,
} from 'modules/2023-q3/components/forms/components/select-switch/select-switch';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { useBusiness } from 'hooks/business/use-business';
import { BankAccount } from 'types';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { SelectSwitchACHBankOption } from './components/select-switch-ach-bank-option/select-switch-ach-bank-option';
import { Styled } from './select-switch-ach-bank.style';

export interface ISelectACHBank extends IDepositWithdrawal {
  bank: BankAccount | undefined;

  setBank: (bank: BankAccount | undefined) => void;
}

interface ISelectSwitchACHBank extends ISelectACHBank {
  isSelectingBank: boolean;

  setIsSelectingBank: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectSwitchACHBank: React.FC<ISelectSwitchACHBank> = ({
  bank,
  isSelectingBank,
  setBank,
  setIsSelectingBank,
  variant,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;

  const { data: business } = useBusiness();
  const { data: accounts, isLoading: isLoadingBankAccounts } = useBankAccounts(
    business?.Id,
  );

  const achBankAccounts = accounts?.filter((account: BankAccount) =>
    ['checking', 'savings'].includes(account.Type),
  );

  const getSelectSwitchOptions = () => {
    const options: SelectSwitchOption[] = [];

    achBankAccounts?.map((account: BankAccount) => {
      options.push({
        label: <SelectSwitchACHBankOption bankAccount={account} />,
        value: account.Id,
      });
    });

    return options;
  };

  const handleOnChange = (selectedOption: SelectSwitchOption) => {
    setBank(
      achBankAccounts?.find(
        (bank: BankAccount) => bank.Id === selectedOption.value,
      ),
    );
  };

  const selectSwitch = (
    <SelectSwitch
      isSelecting={isSelectingBank}
      label={t(isDeposit ? 'Source Bank' : 'Destination Bank', {
        count: achBankAccounts?.length,
      })}
      onChange={handleOnChange}
      options={getSelectSwitchOptions()}
      selectedOption={getSelectSwitchOptions().find(
        (option) => option.value === bank?.Id,
      )}
      setIsSelecting={setIsSelectingBank}
    />
  );

  // default the first bank account
  useEffect(() => {
    if (!bank?.Id && achBankAccounts?.length) {
      const firstBank = achBankAccounts?.find(
        (bank: BankAccount) => bank.Id === achBankAccounts[0].Id,
      );

      setBank(firstBank);
    }
  }, [achBankAccounts]);

  if (isLoadingBankAccounts) {
    return <Spinner />;
  }

  return (
    <MobileView mobile={selectSwitch}>
      <Styled.SelectSwitchContainer isSelecting={isSelectingBank}>
        {selectSwitch}
      </Styled.SelectSwitchContainer>
    </MobileView>
  );
};
