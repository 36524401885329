import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useAddedInitialSweep = (): UseMutationResult<any, any, any> => {
  const queryClient = useQueryClient();
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const patchAddedInitialSweep = ({
    addedInitialSweep,
  }: {
    addedInitialSweep: boolean;
  }) =>
    treasureApi().patch('/added_initial_sweep', {
      addedInitialSweep,
    });

  return useMutation(patchAddedInitialSweep, {
    onSuccess: () => {
      return queryClient.invalidateQueries([ReactQueryKeys.Business]);
    },
  });
};
