import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Styled } from './alert-placeholder.style';

interface AlertPlaceholderProps {
  message: React.ReactNode;
}

export const AlertPlaceholder: React.FC<AlertPlaceholderProps> = ({
  message,
}) => {
  return (
    <Box textAlign="center">
      <Styled.IconAlert />
      <Box>
        <Typography color="nero" variant={TypographyVariant.BodySemiBold}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

AlertPlaceholder.propTypes = {
  message: PropTypes.node.isRequired,
};
