import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Modal as MuiModal } from '@material-ui/core';
import { Size } from 'enums';
import { ModalContext } from '.';
import { Styled } from './modal.style';

export enum ModalVariant {
  Legacy = 'Legacy',
  MoreInformation = 'MoreInformation',
}

export interface ModalProps {
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  hideCloseIcon?: boolean;
  onClose: () => void;
  open?: boolean;
  children: React.ReactNode;
  size?: Size;
  variant?: ModalVariant;
}

const Modal = forwardRef<unknown, ModalProps>(function ModalRef(props, ref) {
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();

  const handleBackdropClicked = () => {
    props.onClose();

    trackEvent({ eventName: TrackEventName.ModalBackdropCloseClicked });
  };

  const handleOnClose = () => {
    props.onClose();

    trackEvent({ eventName: TrackEventName.ModalCloseIconClicked });
  };

  const getModalVariant = (props: ModalProps) => {
    switch (props.variant) {
      case ModalVariant.MoreInformation:
        return (
          <Styled.MoreInformationModal>
            {!props.hideCloseIcon && (
              <Styled.MoreInformationCloseIcon onClick={handleOnClose} />
            )}
            <ModalContext.Provider value={{ onClose: props.onClose }}>
              {props.children}
            </ModalContext.Provider>
          </Styled.MoreInformationModal>
        );
      default:
        return (
          <Styled.Modal size={props.size}>
            {!props.hideCloseIcon && (
              <Styled.CloseIcon onClick={handleOnClose} />
            )}
            <ModalContext.Provider value={{ onClose: props.onClose }}>
              {props.children}
            </ModalContext.Provider>
          </Styled.Modal>
        );
    }
  };

  return (
    <MuiModal
      ref={ref}
      tabIndex={-1}
      open={!!props.open}
      onClose={handleBackdropClicked}
      BackdropComponent={Styled.Backdrop}
      disableBackdropClick={props.disableBackdropClick}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
    >
      <Styled.CSSTransition
        in={!!props.open}
        timeout={500}
        classNames="animate-modal"
        appear
      >
        {getModalVariant(props)}
      </Styled.CSSTransition>
    </MuiModal>
  );
});

Modal.propTypes = {
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf([...Object.values(Size)]),
  variant: PropTypes.oneOf([...Object.values(ModalVariant)]),
};

export { Modal };
