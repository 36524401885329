import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { breakpoints } from 'styles/css-constants';

interface ScrollBoxProps {
  firstColumnMinWidth?: number;
}

interface ScrollContainerProps {
  widthToSubtract?: number;
}

const ScrollBox = styled(({ firstColumnMinWidth, ...rest }) => (
  <Box {...rest} />
))<ScrollBoxProps>`
  ${breakpoints.down('sm')} {
    width: max-content;

    & .MuiGrid-container .MuiGrid-item:first-child {
      min-width: ${(props) =>
        props.firstColumnMinWidth ||
        200}px; // TODO: terrible hack to get some spacing
    }
  }
`;

const ScrollContainer = styled(({ widthToSubtract, ...rest }) => (
  <Box {...rest} />
))<ScrollContainerProps>`
  ${breakpoints.down('sm')} {
    overflow-x: scroll;
    width: calc(
      100vw - ${(props) => props.widthToSubtract || 0}px
    ); // padding + border
  }
`;

export const Styled = {
  ScrollBox,
  ScrollContainer,
};
