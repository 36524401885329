import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabPanel } from './tab-panel';

import { Styled } from './tabs.style';

interface TabsProps {
  tabData: {
    label: string;
    content: React.ReactNode;
  }[];

  onChange?: (number: number) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabData, onChange }) => {
  const [value, setValue] = useState(0);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <>
      <Styled.Tabs
        value={value}
        onChange={handleChange}
        aria-label="ant example"
      >
        {tabData.map((tab, index) => {
          return (
            <Styled.Tab
              disableRipple
              label={tab.label}
              key={index.toString()}
            />
          );
        })}
      </Styled.Tabs>

      {tabData.map((tab, index) => {
        return (
          <TabPanel value={value} index={index} key={index.toString()}>
            {tab.content}
          </TabPanel>
        );
      })}
    </>
  );
};

Tabs.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
  onChange: PropTypes.func,
};

export { Tabs };
