import React, { useState } from 'react';
import { Portfolio } from 'modules/dashboard/treasure-reserve';
import { PortfolioAllocationDashboardEntryPoint } from 'modules/portfolio/allocation/components/dashboard-entry-point/portfolio-allocation-dashboard-entry-point';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { PortfolioState } from 'modules/portfolio/enums/portfolio-state';

/*
 * This component is used as a transition/migraton to the new Portfolio view
 * Design will implement a new entry point for portfolio but in the meantime,
 * we will use the old entry point until the user either has positions without
 * a portfolio or has an active portfolio.
 */
export const PortfolioTransition: React.FC = () => {
  const [showPortfolio, setShowPortfolio] = useState<boolean>();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const {
    data: portfolios,
    isError: isErrorPortfolios,
    isLoading: isLoadingPortfolios,
  } = usePortfolios(business?.Id);

  if (isLoadingBusiness || isLoadingPortfolios || isErrorPortfolios) {
    return null;
  }

  // if the business has an active portfolio and/or positions other than cash
  // show the Treasure Reserve Dashboard Portfolio
  if (
    portfolios.portfolios[0]?.state === PortfolioState.Active ||
    portfolios.productAmounts.moneyMarket > 0 ||
    portfolios.productAmounts.managedTreasuries > 0 ||
    portfolios.productAmounts.managedIncome > 0 ||
    portfolios.productAmounts.otherCash > 0 ||
    portfolios.productAmounts.otherPositions > 0
  ) {
    return <Portfolio />;
  }

  // otherwise, show the Portfolio Allocation Dashboard Entry Point
  // for now, setShowPortfolio won't actually do anything
  // leaving it for the disabled Feature Flag fallback
  return (
    <PortfolioAllocationDashboardEntryPoint
      setShowPortfolio={setShowPortfolio}
    />
  );
};
