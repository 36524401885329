import React from 'react';
import PropTypes from 'prop-types';
import { V2Layout } from 'layouts';
import { TrackEventName } from 'modules/tracking';
import { ReactComponent as ExpiredIcon } from 'assets/icons/expired_icon.svg';
import { ResendConfirmationEmail } from './resend-confirmation-email';

interface InvalidTokenSelfServeProps {
  businessId: string;
}
export const InvalidTokenSelfServe: React.FC<InvalidTokenSelfServeProps> = ({
  businessId,
}) => (
  <V2Layout>
    <ResendConfirmationEmail
      businessId={businessId}
      eventName={TrackEventName.ExpiredLinkResendClicked}
    >
      <>
        <ExpiredIcon />
        <p className="cta">This link has expired</p>
      </>
    </ResendConfirmationEmail>
  </V2Layout>
);

InvalidTokenSelfServe.propTypes = {
  businessId: PropTypes.string.isRequired,
};
