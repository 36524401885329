import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { useBusiness } from 'hooks/business/use-business';
import { useMutateBusinessProperty } from 'hooks/business/use-mutate-business-property';
import { SVGBox } from 'modules/2023-q3/components/svg-box/svg-box';
import { ReactComponent as IconXCircleGray } from 'assets/icons/checkmarks/x-circle-gray.svg';
import { ReactComponent as FiftyPercentOff } from 'assets/modules/2023-q4/growth-promo/graphic_50-percent-off.svg';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from './growth-promo-modal.style';

interface GrowthPromoModalProps {
  closeModal: ({ showDetails }: { showDetails?: boolean | undefined }) => void;
}

export const GrowthPromoModal: React.FC<GrowthPromoModalProps> = ({
  closeModal,
}) => {
  const { trackEvent, Track } = useTracking<{
    component: string;
    eventName: TrackEventName;
  }>({
    component: 'Growth Modal Promo',
  });
  const { data: business } = useBusiness();
  const { isLoading, mutate: mutateBusinessProperty } =
    useMutateBusinessProperty();

  const handleClaimOffer = () => {
    mutateBusinessProperty(
      {
        businessId: business.Id,
        name: 'claimed2023DecemberGrowthPromo',
        value: true,
      },
      {
        onSettled: () => {
          closeModal({ showDetails: true });
        },
      },
    );
  };

  useEffect(() => {
    trackEvent({
      eventName: TrackEventName.Viewed,
    });
  }, []);

  return (
    <Track>
      <Styled.GraphicsContainer textAlign="center" p={2.5}>
        <Box position="absolute" top={20} right={20}>
          <SVGBox
            cursor="pointer"
            onClick={() =>
              closeModal({
                showDetails: false,
              })
            }
          >
            <IconXCircleGray />
          </SVGBox>
        </Box>

        <SVGBox py={5} maxWidth="100%">
          <FiftyPercentOff />
        </SVGBox>
      </Styled.GraphicsContainer>

      <Box mt={2} mb={5} px={3} textAlign="center">
        <Typography color="grey4" variant={TypographyVariant.Paragraph3}>
          Ends Dec. 15, 2023
        </Typography>

        <Box mt={1}>
          <Typography color="black19" variant={TypographyVariant.Header3}>
            Get 50% Off Investment Management Fees For a Full Year When You
            Deposit $2M or More.
          </Typography>
        </Box>
      </Box>

      <Box p={2.5}>
        {isLoading ? (
          <Button disabled fullWidth>
            <Box p={1} position="relative" top={3}>
              <Typography
                color="black19"
                variant={TypographyVariant.Paragraph2}
              >
                <CircularProgress color="inherit" size={25} />
              </Typography>
            </Box>
          </Button>
        ) : (
          <Button fullWidth onClick={handleClaimOffer}>
            <ButtonContent>
              <Typography color="white" variant={TypographyVariant.Paragraph2}>
                Claim Offer
              </Typography>
            </ButtonContent>
          </Button>
        )}
      </Box>
    </Track>
  );
};
