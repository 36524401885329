import { OnboardingStatus } from 'enums';

export const forceAccountIntake = (business: {
  AddedInitialSweep: boolean | null;
  OnboardingStatus: OnboardingStatus;
}) => {
  const completedOnboardingForm =
    business?.OnboardingStatus === OnboardingStatus.COMPLETE;
  const passedInitialSweep = business?.AddedInitialSweep !== null;

  const canAccessDashboard = completedOnboardingForm && passedInitialSweep;

  return !canAccessDashboard;
};
