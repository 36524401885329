import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { ReactComponent as IconXCircleGray } from 'assets/icons/checkmarks/x-circle-gray.svg';

const ButtonAdd = styled(IconXCircleGray)`
  transform: rotate(45deg);
`;

const LabelContainer = styled(Box)``;

const Cursor = styled(Box)`
  cursor: pointer;

  &:hover {
    ${ButtonAdd} rect {
      fill: var(--color-brown4b);
    }

    ${LabelContainer} {
      // TODO: theres a better way to do this
      .MuiTypography-body1 {
        color: var(--color-blue2);
      }
    }
  }
`;

export const Styled = { ButtonAdd, Cursor, LabelContainer };
