import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Button } from 'components/core';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { PolicyKey } from 'enums';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PageTitle } from '../components/page-title';
import { usePolicies } from './hooks/get-policies';
import { Styled } from './legalese.style';

interface LegaleseProps {
  policyKeys: string[];
}

export const Legalese: React.FC<LegaleseProps> = ({ policyKeys }) => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.ApexAgreements,
  });

  const onboardingContext = useOnboardingContext();
  const { handleSubmit } = useForm();

  // TODO: does the order of the forms matter?
  const policyKeysEnum = Object.values(PolicyKey).filter((policyKey) =>
    policyKeys.includes(policyKey),
  );
  const { data: policies = [], isLoading: isLoadingPolicies } =
    usePolicies(policyKeysEnum);

  const handlePrint: React.MouseEventHandler = (e) => {
    e.preventDefault();
    window.print();

    trackEvent({
      eventName: TrackEventName.PrintAgreementClicked,
    });
  };

  const onSubmit = (data: any) => {
    onboardingContext.postOnboardingData({
      businessId: onboardingContext.businessId,
      data: {
        policiesKeysAgreed: policyKeysEnum,
      },
    });

    trackEvent({
      eventName: TrackEventName.ApexAgreementsCompleted,
    });
  };

  useEffect(() => {
    onboardingContext.showLoading(isLoadingPolicies);
  }, [isLoadingPolicies]);

  return (
    <>
      <PageTitle title="Legal disclosures" />

      <Styled.Container px={3} mb={3}>
        {policies.map((policy: { key: string; content: string }) => {
          return (
            <ReactMarkdown
              key={policy.key}
              linkTarget="_blank"
              remarkPlugins={[remarkGfm]}
            >
              {policy.content}
            </ReactMarkdown>
          );
        })}
      </Styled.Container>

      <Box displayPrint="none">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button type="submit" width={100}>
            I agree, submit application
          </Button>
        </form>

        <Box mt={3} textAlign="center" width="100%">
          <Button onClick={handlePrint} variant="outlined">
            Print agreement
          </Button>
        </Box>
      </Box>
    </>
  );
};
