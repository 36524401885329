import styled from 'styled-components';
import { Styled as StyledTextField } from '../text-field/text-field.style';

const TextField = styled(StyledTextField.TextField)`
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding-top: 1px;
    padding-bottom: 1px;
  }
`;

const AutocompleteOption = styled.div`
  &.bankAccounts {
    .heading {
      font-weight: var(--font-weight-bold);
    }
  }
`;

// seems like just adding this empty wrapper
// moves the popper down enough so it doesn't cover
// the select input
const AutocompleteWrapper = styled.div``;

export const Styled = {
  TextField,
  AutocompleteOption,
  AutocompleteWrapper,
};
