// only execute a function once per delay

let throttling: string[] = [];

export const throttle = (name: string, fn: Function, delay?: number) => {
  if (throttling.includes(name)) {
    return;
  }

  throttling.push(name);

  setTimeout(() => {
    fn();

    throttling = throttling.filter((x) => x !== name);
  }, delay || 500);
};
