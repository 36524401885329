import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';

import { Styled } from './spinner.style';

export type SpinnerProps = {
  className?: string;
  fullScreen?: { bgColor: string };
  height?: number;
  inline?: boolean;
};

export const Spinner: React.FC<SpinnerProps> = ({
  className,
  fullScreen,
  height,
  inline,
}) => {
  const spinner = (
    <Styled.SpinWrapper className={className} inline={inline}>
      <Styled.Spin height={height} role="progressbar" />
    </Styled.SpinWrapper>
  );

  if (fullScreen?.bgColor) {
    return (
      <Modal open>
        <Styled.FullScreenContainer bgColor={fullScreen.bgColor}>
          {spinner}
        </Styled.FullScreenContainer>
      </Modal>
    );
  }

  return spinner;
};

Spinner.propTypes = {
  fullScreen: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
  }),
  inline: PropTypes.bool,
};
