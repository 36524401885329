import treasureApi from 'api';
import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { Toast } from 'components/layouts';

const postOnboarding = async ({
  businessId,
  data,
}: {
  businessId: string;
  data: any;
}): Promise<AxiosResponse> => {
  const response = await treasureApi('custodian', { businessId }).post(
    `/onboarding/${businessId}`,
    data,
  );

  return response.data;
};

export const useMutateOnboarding = (
  businessId: string,
  onSuccess?: () => void,
) => {
  const queryClient = useQueryClient();

  const { isError, isLoading, mutate } = useMutation(postOnboarding, {
    onSuccess: (response) => {
      queryClient.setQueryData(['onboarding', businessId], response);
      onSuccess && onSuccess();
    },
    onError: (error) => {
      // dispatch the error payload. ErrorToasts
      // will decide what error message to display

      Toast.showError(error);
    },
  });

  return { isError, isLoading, mutate };
};
