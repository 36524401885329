import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Container = styled(Box)`
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-grey6);
  height: 78px;
`;

export const Styled = {
  Container,
};
