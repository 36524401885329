import React from 'react';
import { Box } from '@material-ui/core';
import { Button, Text, Typography, TypographyVariant } from 'components/core';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

export const InvalidResetPasswordLink: React.FC = () => {
  return (
    <Box px={{ xs: 3, sm: 10 }}>
      <Typography color="nero" variant={TypographyVariant.SubHeader2}>
        This reset password link doesn&apos;t work. This can happen when a link
        expires or has already been used.
      </Typography>

      <Box mt={3}>
        <Link to={RoutesPath.noAuth.forgotPassword.path}>
          <Button type="submit" width={100}>
            <Box py={0.75}>
              <Text color="white" variant={3}>
                Request a new reset link
              </Text>
            </Box>
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
