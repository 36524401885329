import styled from 'styled-components';

const Wrapper = styled.div`
  z-index: 1000;
  background-color: var(--color-whiteSmoke4);
  min-height: 100vh;
  min-width: 100vw;
  display: grid;
  grid-template-rows: 68px auto 40px;
  #img-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 25px;
  }
`;

const FooterContainer = styled.div`
  background-color: var(--color-whiteSmoke4);
  footer {
    padding: 30px;
  }
`;

export const Styled = {
  Wrapper,
  FooterContainer,
};
