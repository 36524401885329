export enum IndustryType {
  AGRICULTURE_FORESTRY_AND_FISHING = 'Agriculture, Forestry, and Fishing',
  MINING = 'Mining',
  CONSTRUCTION = 'Construction',
  MANUFACTURING = 'Manufacturing',
  TRANSPORTATION_COMMUNICATIONS_ELECTRIC_GAS_AND_SANITARY_SERVICES = 'Transportation, Communications, Electric, Gas, and Sanitary Services',
  WHOLESALE_TRADE = 'Wholesale Trade',
  RETAIL_TRADE = 'Retail Trade',
  FINANCE_INSURANCE_AND_REAL_ESTATE = 'Finance, Insurance, and Real Estate',
  SERVICES = 'Services',
  PUBLIC_ADMINISTRATION = 'Public Administration',
}
