import React from 'react';
import {
  IProductAllocations,
  ProductAllocationProducts,
} from 'modules/2024-q1/allocation-by-amount-or-percentage/interface/product-allocation';
import { Box, Divider, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { Container } from 'modules/2024-q1/allocation-by-amount-or-percentage/components/container/container';
import { FooterButton } from 'modules/2024-q1/allocation-by-amount-or-percentage/components/footer/footer';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { PortfolioAllocationList } from './components/portfolio-allocation-list/portfolio-allocation-list';
import { Styled } from 'modules/2024-q1/allocation-by-amount-or-percentage/pages/form/form.style';

interface ReviewProps {
  allocation: IProductAllocations;
  initialAllocation: IProductAllocations;
  nextStep: () => void;
  previousStep: () => void;
}

export const Review: React.FC<ReviewProps> = ({
  allocation,
  initialAllocation,
  nextStep,
  previousStep,
}) => {
  return (
    <>
      <Box maxWidth={720} mt={{ xs: 0, sm: 5 }} mx="auto">
        <Container>
          <Box p={{ xs: 0, sm: 3 }}>
            <Box mb={1}>
              <Typography color="black19" variant={TypographyVariant.Header3}>
                Review & Confirm Allocation
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
                Below is an estimated projection of your portfolio after
                allocation. Due to specific asset prices, your funds may not be
                perfectly allocated as requested. We will aim to get as close to
                your desired allocation as possible.
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm>
                <Styled.RoundedContainer p={2.5}>
                  <PortfolioAllocationList
                    allocation={initialAllocation}
                    title="Portfolio Before"
                  />
                </Styled.RoundedContainer>
              </Grid>

              <Grid item xs={12} sm>
                <Styled.RoundedContainer p={2.5}>
                  <PortfolioAllocationList
                    allocation={allocation}
                    title="Portfolio After"
                  />
                </Styled.RoundedContainer>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box height={{ xs: 50, sm: 100 }} />

      <Box bgcolor="white" position="fixed" bottom={0} left="0" right="0">
        <Divider />

        <Box mx="auto" my={{ xs: 0, sm: 2.5 }} width={{ xs: '100%', sm: 700 }}>
          <MobileView
            mobile={
              <FooterButton disabled={false} onClick={nextStep}>
                <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
                  Confirm
                </ButtonContent>
              </FooterButton>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs>
                <FooterButton onClick={previousStep}>
                  <ButtonContent iconStart={SupportedIcons.ArrowLeft}>
                    Previous
                  </ButtonContent>
                </FooterButton>
              </Grid>

              <Grid item xs>
                <FooterButton onClick={nextStep}>
                  <ButtonContent iconEnd={SupportedIcons.ArrowRight}>
                    Confirm
                  </ButtonContent>
                </FooterButton>
              </Grid>
            </Grid>
          </MobileView>
        </Box>
      </Box>
    </>
  );
};
