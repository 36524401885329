import styled from 'styled-components';
import { NavLink as NavLinkAlias } from 'react-router-dom';

const NavLink = styled(NavLinkAlias)`
  &.${(props) => props.activeClassName} span {
    font-weight: bold;
  }
`;

export const Styled = {
  NavLink,
};
