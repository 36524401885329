import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { Typography } from 'components/core';
import { breakpoints } from 'styles/css-constants';

export const ModalHeading = styled(Box)`
  border-bottom: 1px solid var(--color-lightGrey);
  padding: 24px 0;
  text-align: center;
`;

export const ModalBody = styled(Box)`
  padding: 24px 40px;
`;

export const RowLabel = styled(Typography)`
  font-weight: var(--font-weight-bold);
  margin-right: 4px;

  ${breakpoints.down('sm')} {
    display: block;
  }
`;

export const WireTransferGrid = styled(Grid)`
  background-color: var(--color-whiteSmoke4);
  margin-top: 30px;
`;

export const GridItemLeft = styled(Grid)`
  border-bottom: 1px solid var(--color-lightGrey);
  height: fit-content;
  padding: 14px 20px;

  &:nth-child(odd) {
    display: flex;
  }

  &:nth-last-child(2) {
    border-bottom: 1px;
    border-radius: 0 0 3px 3px;
  }
`;

export const GridItemRight = styled(Grid)`
  align-items: center;
  background-color: var(--color-white);
  display: flex;
  padding: 0 14px;
  p {
    display: inline;
    font-size: 13px;
  }
`;

export const Chip = styled.div`
  align-items: center;
  background-color: var(--color-flatRed);
  border-radius: 50px;
  color: var(--color-white);
  display: flex;
  font-size: 10px;
  margin-left: 10px;
  min-width: fit-content;
  max-height: 24px;
  padding: 7px 10px 5px 10px;

  ${breakpoints.down('sm')} {
    display: none;
  }
`;

export const Styled = {
  ModalHeading,
  ModalBody,
  RowLabel,
  WireTransferGrid,
  GridItemLeft,
  GridItemRight,
  Chip,
};
