import React from 'react';
import styled, { css } from 'styled-components';
import { Button as ButtonAlias } from '@material-ui/core';
import { colors, getColor, getFontProperties } from 'styles/css-constants';
import { breakpoints } from 'styles/css-constants';

interface ButtonProps {
  bgColor?: string;

  bgHoverColor?: string;

  borderRadius?: number;

  cursor?: string;

  bottomFixed?: boolean;
}

const Button = styled(
  ({ bgColor, bgHoverColor, borderRadius, cursor, bottomFixed, ...rest }) => (
    <ButtonAlias {...rest} />
  ),
)<ButtonProps>`
  color: inherit;
  font-weight: inherit;
  font-family: ${getFontProperties.fontFamily};
  padding: 0;
  text-transform: inherit;
  user-select: none;

  &.MuiButton-root.Mui-disabled {
    background-color: var(--color-grey4);
  }

  ${(props) => {
    if (props.variant === 'text') {
      return css`
        position: relative;
        top: -1px;
        min-width: inherit;
        text-decoration: underline;

        &:hover {
          background-color: inherit;
          color: var(--color-blue2);
          text-decoration: underline;
        }
      `;
    }
  }};

  ${(props) =>
    props.bottomFixed &&
    css`
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    `};

  ${(props) => {
    let backgroundColor = getColor('buttonPrimaryBackground');
    if (props.bgColor) {
      backgroundColor = getColor(props.bgColor as keyof typeof colors);
    }

    return css`
      background-color: ${backgroundColor};

      &:hover {
        background-color: ${backgroundColor};
      }
    `;
  }};

  ${(props) => {
    let backgroundColor = getColor('buttonPrimaryHoverBackground');
    if (props.bgHoverColor) {
      backgroundColor = getColor(props.bgHoverColor as keyof typeof colors);
    }

    return css`
      &:hover,
      &:active {
        background-color: ${backgroundColor};
      }
    `;
  }};

  ${(props) => {
    if (
      typeof props.borderRadius !== 'undefined' &&
      !Number.isNaN(props.borderRadius)
    ) {
      return css`
        border-radius: ${props.borderRadius}px;
      `;
    }
  }};

  ${(props) => {
    if (props.cursor) {
      return css`
        cursor: ${props.cursor};
      `;
    }

    return null;
  }}
`;

export const Styled = {
  Button,
};
