import styled, { keyframes } from 'styled-components';
import { ToastContainer } from 'react-toastify';

const toastMaxWidth = '400px';

const fadeIn = keyframes`
  0%{
    transform: scaleY(1);
  }
  100%{
    transform: scaleY(0);
  }
`;

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  .toast {
    background: var(--color-white);
    color: var(--color-nero);
    font-weight: 300;
    box-shadow: var(--box-shadow-04);
    margin: 0 auto 1px auto;
    padding: 18px;
    width: 100%;
    max-width: ${toastMaxWidth};
    z-index: var(--z-index-toast-level);
    display: flex;
    align-items: center;
  }

  .body {
    align-items: center;
    display: flex;
  }

  .progress {
    background: var(--color-hanPurple);
    height: 100%;
    width: 5px;
    left: 0;
    top: 0;
    animation: ${fadeIn} linear 1 forwards;
    transform-origin: bottom;
  }

  .Toastify__toast--success .progress {
    background: var(--color-emerald);
  }

  .Toastify__toast--info .progress {
    background: var(--color-hanPurple);
  }

  .Toastify__toast--error .progress {
    background: var(--color-amaranth);
  }
`;

export const Styled = {
  StyledToastContainer,
};
