import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Divider } from './sub-indicators.common.style';

const CenterGrid = styled(Grid)`
  justify-content: center;
`;

export const Styled = {
  CenterGrid,
  Divider,
};
