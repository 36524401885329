import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Allocation as AllocationV1 } from 'modules/2023-q3/allocation/allocation';
import { Allocation as AllocationV2 } from './allocation';

export const AllocationTransition = () => {
  return (
    <FeatureFlag
      disabled={<AllocationV1 />}
      enabled={<AllocationV2 />}
      flag={FeatureFlags.REACT_APP_2024_Q1_ALLOCATION_AMOUNT}
    />
  );
};
