import React from 'react';
import styled, { css } from 'styled-components';
import { Typography as TypographyAlias } from '@material-ui/core';
import { TypographyVariant } from 'enums';
import { getFontProperties } from 'styles/css-constants';

interface StyledText {
  capitalize?: boolean;
  semanticElement?: string;
  styledColor?: string;
  uppercase?: boolean;
  variant: TypographyVariant;
}

const Typography = styled(
  ({
    capitalize,
    semanticElement,
    styledColor,
    uppercase,
    variant,
    ...rest
  }) => {
    return (
      <TypographyAlias
        component={
          semanticElement ||
          getFontProperties.getTypographyProperties(variant).semanticElement
        }
        {...rest}
      />
    );
  },
)<StyledText>`
  font-family: var(--font-family);

  color: ${(props) => {
    if (props.styledColor) {
      return css`var(--color-${props.styledColor})`;
    }

    return 'var(--color-nero)';
  }};

  margin: 0;

  font-size: ${(props) => {
    if (props.variant) {
      return getFontProperties.getTypographyProperties(props.variant).fontSize;
    }
  }};

  font-weight: ${(props) => {
    if (props.variant) {
      return getFontProperties.getTypographyProperties(props.variant)
        .fontWeight;
    }
  }};

  text-transform: ${(props) => {
    if (props.variant) {
      return getFontProperties.getTypographyProperties(props.variant)
        .textTransform;
    }
  }};

  ${(props) => {
    if (props.capitalize) {
      return css`
        text-transform: capitalize;
      `;
    }
  }}

  ${(props) => {
    if (props.uppercase) {
      return css`
        text-transform: uppercase;
      `;
    }
  }}
`;

export const Styled = {
  Typography,
};
