import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { TypographyVariant } from 'components/core';
import { Trans } from 'app/i18n';
import {
  AllocationChartGridWrapper,
  AllocationTooltip,
} from 'modules/portfolio/allocation/components/allocation-modal/components';
import { getReturnDistributions } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import { Allocation } from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { Styled } from 'modules/portfolio/allocation/components/allocation-modal/components/return-distribution-section/return-distribution-section.style';
import { useTracking, TrackEventName } from 'modules/tracking';
import { i18nNamespaceAllocationModal } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal';

interface RDSectionProps {
  allocations: Allocation;
}

export const ReturnDistributionSection: React.FC<RDSectionProps> = ({
  allocations,
}) => {
  const { trackEvent } =
    useTracking<{
      eventName: TrackEventName;
      displayed: boolean;
    }>();
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);

    trackEvent({
      eventName: TrackEventName.ReturnDistributionCharts,
      displayed: !expanded,
    });
  };

  /* Space constraint, Return distribution toggle doesn't fit in the column
   * so making it it's own row and mocking the spacing to look like it spans to columns
   */
  return (
    <>
      <Box px={2}>
        <Grid container>
          <Grid item xs>
            <Box pt={1}>
              <Grid container alignItems="center">
                <Grid item onClick={handleClick}>
                  <Styled.LinkText
                    color="hanPurple"
                    variant={TypographyVariant.SubHeader3}
                  >
                    <Grid container alignItems="center">
                      <Grid item>
                        <Styled.IconArrow expanded={expanded} />
                      </Grid>
                      <Grid item>
                        <Trans
                          i18nKey="returnDistribution"
                          ns={i18nNamespaceAllocationModal}
                        />
                      </Grid>
                    </Grid>
                  </Styled.LinkText>
                </Grid>

                <Grid item>
                  <Box ml={1} mt={0.5}>
                    <AllocationTooltip
                      content={
                        <Trans
                          i18nKey="returnDistributionTooltipContent"
                          ns={i18nNamespaceAllocationModal}
                        />
                      }
                      eventLabel="returnDistribution"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <AllocationChartGridWrapper
        alignItems="flex-start"
        allocations={allocations}
        data={getReturnDistributions({
          allocations,
          expanded,
          onClick: () => setExpanded(!expanded),
        })}
        hideBottomBorder
        hideChartDivider
      />
    </>
  );
};
