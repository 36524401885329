import React from 'react';
import { Box } from '@material-ui/core';
import { Heading } from 'components/core';
import { TreasureSymbol } from 'assets';

export const InvalidToken: React.FC = () => {
  return (
    <Box mt={6}>
      <Box mt="15%" mb={50} textAlign="center">
        <img
          src={TreasureSymbol}
          alt="Treasure logo symbol"
          width={80}
          height={80}
        />

        <Box mt={2} mb={1}>
          <Heading variant={1}>
            Oops! We can’t find what you’re looking for!
          </Heading>
        </Box>

        <Heading variant={2}>
          Ask your customer service rep for a new link.
        </Heading>
      </Box>
    </Box>
  );
};
