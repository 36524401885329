import styled, { css } from 'styled-components';

interface TaskProgressStepProps {
  active?: boolean;
}

const TaskProgressStep = styled.hr<TaskProgressStepProps>`
  border: 2px solid var(--color-brown5b);
  border-radius: var(--border-radius-01px);
  max-width: 225px;

  ${(props) => {
    if (props.active) {
      return css`
        border-color: var(--color-primary);
      `;
    }

    return null;
  }}
`;

export const Styled = {
  TaskProgressStep,
};
