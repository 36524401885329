import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { Toast } from 'components/layouts';
import { useForm } from 'react-hook-form';
import { useTracking, TrackEventName } from 'modules/tracking';
import { TextFieldController } from 'modules/form-helpers';
import { useLoadingContext } from 'modules/no-auth/treasure-logo-box';
import { useSignIn } from 'modules/no-auth/hooks/use-sign-in';
import { formFields } from './form-fields';

export interface ISignInForm {
  email: string;
  password: string;
}

export const SignInForm: React.FC = () => {
  const { control, handleSubmit } = useForm<ISignInForm>();
  const { signIn } = useSignIn();
  const { trackEvent } = useTracking();
  const loadingContext = useLoadingContext();

  const onSubmit = async (data: ISignInForm) => {
    loadingContext.setLoading(true);

    try {
      await signIn(data);

      trackEvent({
        eventName: TrackEventName.SignInSucceeded,
        username: data.email,
      });
    } catch (error) {
      const err = error as { message: string };

      Toast.show('error', err.message);

      trackEvent({
        eventName: TrackEventName.SignInFailed,
        username: data.email,
      });

      loadingContext.setLoading(false);
    }
  };

  return (
    <Box px={{ xs: 3, sm: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <TextFieldController {...formFields.email} control={control} />
        </Box>

        <Box mb={3}>
          <TextFieldController
            {...formFields.password}
            type="password"
            control={control}
          />
        </Box>

        <Box mt={3}>
          <Button type="submit" fullWidth>
            <ButtonContent>
              <Typography color="white" variant={TypographyVariant.Body}>
                Sign In
              </Typography>
            </ButtonContent>
          </Button>
        </Box>
      </form>
    </Box>
  );
};
