import React from 'react';
import { useTranslation } from 'app/i18n';
import { i18nNamespaceMFAContent } from 'modules/2023-q3/components/mfa/mfa';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { useTracking, TrackEventName } from 'modules/tracking';

interface MFAResendCodeProps {
  handleSendMFACode: () => void;
}

export const MFAResendCode: React.FC<MFAResendCodeProps> = ({
  handleSendMFACode,
}) => {
  const { t } = useTranslation(i18nNamespaceMFAContent);

  const { trackEvent } = useTracking();

  const handleResendCode = () => {
    handleSendMFACode();

    trackEvent({
      eventName: TrackEventName.ClickResendCode,
    });
  };

  return (
    <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
      {t('Not seeing the code?')}{' '}
      <Button
        bgColor="white"
        bgHoverColor="white"
        onClick={handleResendCode}
        variant="text"
      >
        {t('Resend')}
      </Button>
    </Typography>
  );
};
