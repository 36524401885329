import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Typography } from 'components/core';

const Container = styled(Box)`
  background: var(--color-white);
  border: 1px solid var(--color-gainsboro0);
  border-radius: var(--border-radius-05);
`;

const Header = styled(Typography)`
  font-weight: 800;
  text-transform: uppercase;
`;

export const Styled = {
  Container,
  Header,
};
