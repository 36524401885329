import React, { useEffect, useState } from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import {
  AllocationBreakdown,
  AllocationBreakdownVariant,
} from 'modules/portfolio/allocation/components/allocation-breakdown/allocation-breakdown';
import {
  applyExistingAllocation,
  AllocationWithOther,
  applyFee,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { useProducts } from 'hooks/products/use-products';
import { Container } from '../container/container';

const getPercentage = (total: number, partial: number) => {
  return (partial / total) * 100;
};

interface Props {
  setShowPortfolio: (showPortfolio: boolean) => void;
}

export const RealizedTreasureReserveBreakdown: React.FC<Props> = ({
  setShowPortfolio,
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );
  const { data: products } = useProducts(business?.Id);
  const treasureFee = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_FEE_CALCULATION_ENABLED,
  )
    ? products?.treasureHighYieldFee || 0
    : 0;

  const showOther = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_ALLOCATION_OTHER_ENABLED,
  );

  const productAmounts = portfolios?.amountsInDollars.productAmounts;

  const treasureReserveBalanceAsNumber =
    portfolios?.amountsInDollars.totalAmount;

  // TODO: can remove treasureReserveBalanceWithoutApexCash if we're always showing other
  const treasureReserveBalanceWithoutApexCash =
    portfolios?.amountsInDollars.totalBlendedRateAmount;

  const percentageTotal = showOther
    ? treasureReserveBalanceAsNumber
    : treasureReserveBalanceWithoutApexCash;

  const allocationPercentages = {
    allocations: {
      cash: {
        percentage: getPercentage(percentageTotal, productAmounts?.cash),
        type: 'CASH',
        // cash aint got no fee
        fee: 0,
      },
      moneyMarket: {
        percentage: getPercentage(percentageTotal, productAmounts?.moneyMarket),
        type: 'MONEY_MARKET',
        fee: treasureFee,
      },
      tbills: {
        percentage: getPercentage(
          percentageTotal,
          productAmounts?.managedTreasuries,
        ),
        type: 'TBILL',
        fee: treasureFee,
      },
      smart: {
        percentage: getPercentage(
          percentageTotal,
          productAmounts?.managedIncome,
        ),
        type: 'HIGH_YIELD',
        fee: treasureFee,
      },
    },
  };

  const allocation = applyExistingAllocation(allocationPercentages);

  let allocationWithOther: AllocationWithOther = { ...allocation };

  if (showOther) {
    const other = {
      allocation: getPercentage(
        treasureReserveBalanceAsNumber,
        productAmounts?.other,
      ),
      color: 'green6',
      liquidity: 3,
      liquidityUnit: 'day',
      name: 'Other',
    };

    allocationWithOther = { ...allocation, other };
  }

  const allocationToDisplay = showOther ? allocationWithOther : allocation;

  // check to make sure the total allocation adds up to 100%
  const totalAllocationPercentage = Object.values(allocationToDisplay).reduce(
    (result: number, current: { allocation: number }) =>
      result + current.allocation,
    0,
  );

  if (
    isLoadingBusiness ||
    isLoadingPortfolios ||
    productAmounts === undefined ||
    allocationToDisplay === undefined
  ) {
    return null;
  }

  if (!portfolios?.productAmounts) {
    setShowPortfolio(false);

    return null;
  }

  // 200 because 100% treasure reserve + 100% of other products
  if (Math.round(totalAllocationPercentage) !== 200) {
    trackEvent({
      eventName: TrackEventName.PortfolioAllocationRealizedAllocationError,
    });

    // reverts to the old UI without showing the portfolio allocation dashboard entry point
    setShowPortfolio(false);

    return null;
  }

  trackEvent({
    eventName: TrackEventName.PortfolioAllocationRealizedAllocationShown,
  });

  return (
    <Container>
      <AllocationBreakdown
        allocation={allocationToDisplay}
        allocationBalance={percentageTotal}
        allocationReturnPercentage={portfolios?.actualBlendedRate * 100}
        aum={treasureReserveBalanceAsNumber}
        variant={AllocationBreakdownVariant.Dashboard}
      />
    </Container>
  );
};
