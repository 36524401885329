import React from 'react';
import { useTranslation } from 'app/i18n';
import { i18nNamespaceMFAContent } from 'modules/2023-q3/components/mfa/mfa';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { ReactComponent as IconXCircleRed } from 'assets/icons/checkmarks/x-circle-red.svg';
import { useBusiness } from 'hooks/business/use-business';
import { useUser } from 'hooks/auth/use-user';
import { maskEmail } from 'modules/2023-q3/utils/mask-email/mask-email';

export const VerificationFailed: React.FC = () => {
  const { t } = useTranslation(i18nNamespaceMFAContent);

  const { data: business } = useBusiness();
  const { data: user } = useUser(business?.Id);
  const userEmail = user?.email;

  return (
    <>
      <Box mb={1}>
        <IconXCircleRed />
      </Box>

      <Typography color="black19" variant={TypographyVariant.Header3}>
        {t('Verification Failed')}
      </Typography>

      <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
        {t(
          userEmail
            ? "This doesn't match the code we sent to"
            : "This doesn't match the code we sent",
          {
            email: maskEmail(userEmail),
          },
        )}
      </Typography>
    </>
  );
};
