import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, InputLabel } from '@material-ui/core';
import { Heading, Text } from 'components/core';
import { Styled } from './form-control.style';

interface TextFieldProps {
  children: React.ReactNode;

  currency?: boolean;

  error?: boolean;

  helperText?: string;

  label?: React.ReactNode;

  labelVariant?: number;

  labelAddon?: React.ReactNode;

  leftLabel?: boolean;

  optional?: boolean;

  placeholder?: string;
}

const FormControl: React.FC<TextFieldProps> = ({
  children,
  currency,
  error,
  helperText,
  label,
  labelAddon,
  labelVariant,
  leftLabel,
  optional,
}) => {
  const getCustomClasses = () => {
    let customClass = '';

    if (leftLabel) {
      customClass += 'left-label ';
    }

    if (currency) {
      customClass += 'currency ';
    }

    return customClass;
  };

  return (
    <Styled.FormControl
      fullWidth
      variant="outlined"
      className={getCustomClasses()}
    >
      {label && (
        <Box mb={0.5}>
          <Grid container alignItems="center">
            <Grid item>
              <InputLabel disableAnimation>
                <Heading
                  color={error ? 'torchRed' : 'gothic'}
                  variant={labelVariant || 5}
                >
                  {label}{' '}
                  {optional && <Styled.Optional>(optional)</Styled.Optional>}
                </Heading>
              </InputLabel>
            </Grid>

            {labelAddon && (
              <Styled.GridRelative item>{labelAddon}</Styled.GridRelative>
            )}
          </Grid>
        </Box>
      )}

      {children}

      {helperText && (
        <Box mt={0.5} className="helper-text">
          <Text color={error ? 'torchRed' : 'gothic'} variant={1}>
            {helperText}
          </Text>
        </Box>
      )}
    </Styled.FormControl>
  );
};

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
  currency: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.node,
  labelAddon: PropTypes.node,
  labelVariant: PropTypes.number,
  leftLabel: PropTypes.bool,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
};

export { FormControl };
