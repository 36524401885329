import React from 'react';
import {
  i18nNamespace,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation, Trans } from 'app/i18n';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Box } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'modules/2023-q3/components/accordion/accordion';
import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrows/caret-down.svg';
import { useListScheduleTransfers } from './hooks/use-list-schedule-transfers';
import { ScheduleTransferTable } from './components/schedule-transfer-table/schedule-transfer-table';

interface ScheduleTransfer {
  direction: string;
}

interface IListScheduleTransfers {
  variant: DepositWithdrawalVariant;
}

export const ListScheduleTransfers: React.FC<IListScheduleTransfers> = ({
  variant,
}) => {
  const { t } = useTranslation(i18nNamespace);
  const { data: scheduleTransfers, isLoading: isLoadingScheduleTransfers } =
    useListScheduleTransfers();

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;
  const isWithdrawal = variant === DepositWithdrawalVariant.Withdrawal;
  const depositOrWithdrawal = t(isDeposit ? 'deposit' : 'withdrawal');
  const DepositOrWithdrawal = t(isDeposit ? 'Deposit' : 'Withdrawal');

  let scheduleTransfersVariant = [];

  if (isDeposit) {
    scheduleTransfersVariant = scheduleTransfers?.filter(
      (scheduleTransfer: ScheduleTransfer) =>
        scheduleTransfer.direction === 'DEBIT',
    );
  } else if (isWithdrawal) {
    scheduleTransfersVariant = scheduleTransfers?.filter(
      (scheduleTransfer: ScheduleTransfer) =>
        scheduleTransfer.direction === 'CREDIT',
    );
  }

  if (isLoadingScheduleTransfers) {
    return (
      <FormContainer>
        <Spinner />
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <Typography color="grey1" variant={TypographyVariant.Paragraph1}>
        Scheduled {DepositOrWithdrawal}s
      </Typography>

      {scheduleTransfersVariant.length > 0 ? (
        <Accordion>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              {scheduleTransfersVariant.length === 1
                ? `There is 1 upcoming scheduled ${depositOrWithdrawal}`
                : `There are ${scheduleTransfersVariant.length} upcoming scheduled ${depositOrWithdrawal}s`}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <ScheduleTransferTable
              scheduleTransfers={scheduleTransfersVariant}
              variant={variant}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box>
          <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
            There are no scheduled {depositOrWithdrawal}s. Schedule one below.
          </Typography>
        </Box>
      )}
    </FormContainer>
  );
};
