import React from 'react';
import { Box } from '@material-ui/core';
import {
  FootnoteProvider,
  LoadingProvider,
  MultipleAccountProvider,
} from 'components/layouts';
import { GlobalServiceIssueNotification } from 'modules/page-errors/global-service-issue-notification/global-service-issue-notification';

export const V3BlankLayout: React.FC = ({ children }) => {
  return (
    <FootnoteProvider>
      <MultipleAccountProvider>
        <GlobalServiceIssueNotification />

        <LoadingProvider>
          <Box bgcolor="white" width="100%" height="100%" overflow="auto">
            {children}
          </Box>
        </LoadingProvider>
      </MultipleAccountProvider>
    </FootnoteProvider>
  );
};
