import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { V2Layout } from 'layouts';
import { NoAuthLayout } from 'modules/2023-q4/no-auth/layout/no-auth-layout/no-auth-layout';
import { SignUp as SignUpV1 } from 'pages/onboarding';
import { SignUp as SignUpV2 } from './sign-up';

export const SignUpTransition = () => {
  return (
    <FeatureFlag
      disabled={
        <V2Layout>
          <SignUpV1 />
        </V2Layout>
      }
      enabled={
        <NoAuthLayout>
          <SignUpV2 />
        </NoAuthLayout>
      }
      flag={FeatureFlags.REACT_APP_2023_Q4_ONBOARDING_ENABLED}
    />
  );
};
