import React from 'react';
import { Box } from '@material-ui/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { Typography, TypographyVariant } from 'components/core';
import {
  IProductAllocations,
  ProductAllocationProducts,
} from 'modules/2024-q1/allocation-by-amount-or-percentage/interface/product-allocation';

interface ResetAllocationProps {
  allocation: IProductAllocations;
  initialAllocation: IProductAllocations;
  setAllocation: React.Dispatch<React.SetStateAction<IProductAllocations>>;
}

export const ResetAllocation: React.FC<ResetAllocationProps> = ({
  allocation,
  initialAllocation,
  setAllocation,
}) => {
  const allocationChange =
    allocation[ProductAllocationProducts.ManagedMoneyMarket].value !==
      initialAllocation[ProductAllocationProducts.ManagedMoneyMarket].value ||
    allocation[ProductAllocationProducts.ManagedTreasuries].value !==
      initialAllocation[ProductAllocationProducts.ManagedTreasuries].value ||
    allocation[ProductAllocationProducts.ManagedIncome].value !==
      initialAllocation[ProductAllocationProducts.ManagedIncome].value ||
    allocation[ProductAllocationProducts.TreasureCash].value !==
      initialAllocation[ProductAllocationProducts.TreasureCash].value;

  const resetAllocation = () => {
    setAllocation(initialAllocation);
  };

  if (!allocationChange) {
    return null;
  }

  return (
    <Button
      bgColor="brown6"
      bgHoverColor="brown4b"
      borderRadius={100}
      onClick={resetAllocation}
    >
      <Box px={2} py={1}>
        <Typography color="black19" variant={TypographyVariant.Paragraph2}>
          Reset Allocations
        </Typography>
      </Box>
    </Button>
  );
};
