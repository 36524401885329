import styled from 'styled-components';
import { Typography } from 'components/core';

const Capitalize = styled(Typography)`
  text-transform: capitalize;
`;

export const Styled = {
  Capitalize,
};
