import React from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from './allocation-switch.style';

interface AllocationSwitchProps {
  checked: boolean;

  color: string;

  eventLabel: string;

  label: React.ReactNode;

  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AllocationSwitch: React.FC<AllocationSwitchProps> = ({
  checked,
  color,
  eventLabel,
  label,
  onChange,
}) => {
  const { trackEvent } =
    useTracking<{
      checked: boolean;
      eventName: TrackEventName;
      label: string;
    }>();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);

    trackEvent({
      checked: !checked,
      eventName: TrackEventName.Checkbox,
      label: eventLabel,
    });
  };

  return (
    <Styled.FormControlLabel
      control={
        <Styled.Switch
          checked={checked}
          onChange={(event) => handleOnChange(event)}
          styledColor={color}
        />
      }
      label={label}
    />
  );
};
