import React from 'react';
import { MFA } from 'modules/2023-q3/components/mfa/mfa';
import { useSendApiUserMFACode } from 'modules/2023-q4/documents/hooks/use-send-api-user-mfa-code';
import { useConfirmAPIUserMFACode } from 'modules/2023-q4/documents/hooks/use-confirm-api-user-mfa-code';

interface DocumentsMFA {
  email: string;

  onPrevious: () => void;

  setBusinessId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const DocumentsMFA: React.FC<DocumentsMFA> = ({
  email,
  onPrevious,
  setBusinessId,
}) => {
  const confirmApiUserMFACode = useConfirmAPIUserMFACode();
  const sendApiUserMFACode = useSendApiUserMFACode();

  // this is a required field for the mfa component but unused because we're
  // passing in a custom confirm MFA and custom MFA success
  const handleMFASuccess = () => {
    return null;
  };

  const handleCustomMFASuccess = ({ businessId }: { businessId: string }) => {
    setBusinessId(businessId);
  };

  const handleOnPrevious = () => {
    onPrevious();
  };

  return (
    <MFA
      customConfirmMFA={{
        mutationResult: confirmApiUserMFACode,
        variables: {
          email,
        },
      }}
      customSendMFA={{
        mutationResult: sendApiUserMFACode,
        variables: {
          email,
        },
      }}
      customVerificationMessage="A verification code has been sent to log in"
      onMFASuccess={handleMFASuccess}
      onCustomMFASuccess={handleCustomMFASuccess}
      onPrevious={handleOnPrevious}
    />
  );
};
