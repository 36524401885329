import styled from 'styled-components';
import { Box, FormControl as FormControlAlias, Grid } from '@material-ui/core';

export const FormControl = styled(FormControlAlias)`
  .MuiInputLabel-outlined {
    transform: none;
  }

  .MuiInputLabel-formControl {
    position: initial;
  }
`;

export const GridRelative = styled(Grid)`
  position: relative;
`;

export const Optional = styled(Box)`
  background-color: var(--color-mischka2);
  border-radius: var(--border-radius-02);
  display: inline-block;
`;

export const Styled = {
  FormControl,
  GridRelative,
  Optional,
};
