import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { BankAccount as BankAccountType } from 'modules/2023-q3/banking-page/v2/types/bank-account';
import { BankAccount } from './components/bank-account/bank-account';
import { BankAccountManual } from './components/bank-account-manual/bank-account-manual';
import { BankPlaidError } from './components/bank-plaid-error/bank-plaid-error';

interface BankProps {
  bankAccounts: BankAccountType[] | undefined;
  institution: any;
  manual?: boolean;
}

export const Bank: React.FC<BankProps> = ({
  bankAccounts,
  institution,
  manual,
}) => {
  return (
    <>
      <Box mb={1.5}>
        <Typography variant={TypographyVariant.Header4}>
          {manual ? 'Manually Added Bank Accounts' : institution}
        </Typography>

        {manual && (
          <Box mb={2}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              Balance and transaction information are not available for manually
              added bank accounts
            </Typography>
          </Box>
        )}

        {!manual && <BankPlaidError bankAccounts={bankAccounts} />}
      </Box>

      <Grid container spacing={3}>
        {bankAccounts?.map((bankAccount) =>
          bankAccount.FinancialDataAggregator === 'PLAID' ? (
            <Grid item key={bankAccount.Id} xs={12}>
              <BankAccount bankAccount={bankAccount} />
            </Grid>
          ) : (
            <Grid item key={bankAccount.Id} xs={12} sm={4}>
              <BankAccountManual bankAccount={bankAccount} />
            </Grid>
          ),
        )}
      </Grid>
    </>
  );
};
