import React from 'react';
import { Hidden } from '@material-ui/core';
import { MobileMenu, UserMenu } from '../';

export const DropdownMenu = () => {
  return (
    <>
      <Hidden mdDown>
        <UserMenu />
      </Hidden>

      <Hidden lgUp>
        <MobileMenu />
      </Hidden>
    </>
  );
};
