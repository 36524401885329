import React from 'react';
import { Grid } from '@material-ui/core';
import { Styled } from './notification-box.style';

export enum NotificationBoxVariant {
  Information = 'information',
  Warning = 'warning',
}

interface NotificationBoxProps {
  children: React.ReactNode;

  variant: NotificationBoxVariant;
}

export const NotificationBox: React.FC<NotificationBoxProps> = ({
  children,
  variant,
}) => {
  switch (variant) {
    case NotificationBoxVariant.Information:
      return (
        <Styled.NotificationBoxInformation>
          <Grid container alignItems="center">
            <Grid item>
              <Styled.Icon />
            </Grid>
            <Grid item>{children}</Grid>
          </Grid>
        </Styled.NotificationBoxInformation>
      );

    case NotificationBoxVariant.Warning:
      return (
        <Styled.NotificationBoxWarning>
          <Grid container alignItems="center">
            <Grid item>
              <Styled.Icon />
            </Grid>
            <Grid item>{children}</Grid>
          </Grid>
        </Styled.NotificationBoxWarning>
      );

    default:
  }

  return null;
};
