import React from 'react';
import { Divider } from '@material-ui/core';
import { ColumnType } from 'modules/dashboard/components';
import { TableRow } from './components/table-row/table-row';

interface TableBodyProps {
  columns?: ColumnType[];

  data: any;
}

export const TableBody: React.FC<TableBodyProps> = ({ columns, data }) => {
  return data.map((row: any, index: number) => (
    <TableRow columns={columns} data={row} key={index} />
  ));
};
