import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface PlaidBankErrorState {
  hideError: () => void;
  resetShowError: () => void;
  showError: boolean;
}

export const useStorePlaidBankError = create<PlaidBankErrorState>()(
  devtools(
    persist(
      (set) => ({
        hideError: () => set((state) => ({ showError: false })),
        resetShowError: () => set((state) => ({ showError: true })),
        showError: true,
      }),
      {
        name: 'bank-plaid-error',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);
