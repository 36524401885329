import styled, { css } from 'styled-components';
import { Button as ButtonAlias } from '@material-ui/core';

interface ButtonProps {
  $width?: number;
}

const Button = styled(ButtonAlias)<ButtonProps>`
  font-family: Nunito Sans; // TODO: replace old Buttons to use Typography
  font-size: var(--font-size-body-02);
  font-weight: var(--font-weight-regular);
  text-transform: initial;
  min-width: 160px;
  min-height: 36px;

  &.MuiButton- {
    &contained {
      background-color: var(--color-bg-button-main);

      color: var(--color-white);
      border-radius: var(--border-radius-04);
      box-shadow: 0 6px 7px rgba(0, 0, 0, 0.09);
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-shadow: 0 6px 7px rgba(0, 0, 0, 0.09);

      &:hover {
        background-color: var(--color-bg-button-main);

        filter: var(--drop-shadow-01);
      }

      &:active {
        background-color: var(--color-bg-button-focus);
      }

      &:focus {
        background-color: var(--color-bg-button-main);
        border: 1px solid var(--color-bg-button-focus);
      }

      &:disabled {
        background-color: var(--color-bg-button-disabled);
        color: var(--color-white);
        border: 1px solid var(--color-bg-button-disabled);
      }
    }

    &outlined {
      border: 1px solid var(--color-bg-button-main);
      border-radius: var(--border-radius-02);
      color: var(--color-bg-button-main);
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      &:hover {
        box-shadow: var(--box-shadow-03);
      }

      &:active {
        background-color: var(--color-bg-button-focus);
      }

      &:focus {
        border: 2px solid var(--color-bg-button-main);
      }

      &:disabled {
        background-color: var(--color-bg-button-disabled);
        color: var(--color-white);
        border: 1px solid var(--color-bg-button-disabled);
      }
    }
  }

  ${(props) =>
    props.$width &&
    css`
      width: ${props.$width}%;
    `};
`;

export const Styled = {
  Button,
};
