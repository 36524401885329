import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Spinner } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { useForm } from 'react-hook-form';
import {
  AutocompleteController,
  FormFieldContainer,
  FormFieldContainerVariant,
  TextFieldController,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { useBusiness } from 'hooks/business/use-business';
import { useBusinessMembers } from 'hooks/business/use-business-members';
import { useInviteUser } from 'modules/multi-user/hooks/use-invite-user';
import { Toast } from 'components/layouts';
import {
  AddressFormSection,
  AddressFormSectionVariant,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/sections/address/address-form-section';
import {
  formattedDateOfBirth,
  formattedNationalIdentifier,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/utils';
import { FormControl } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/common/form-control';
import { useHistory } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { useTracking, TrackEventName } from 'modules/tracking';
import { formFields } from './form-fields';

const unitedStatesCountryValue = 'USA';

interface IInviteUserForm {
  email: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  citizenshipCountry: string;
  residencyCountry: string;
  nationalIdentifier: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

export const InviteUserForm: React.FC = () => {
  const history = useHistory();

  const { trackEvent } =
    useTracking<{
      eventName: TrackEventName;
    }>();

  const { data: business } = useBusiness();
  const { data: businessMembers } = useBusinessMembers();
  const businessName = business?.LegalName || business?.DBA;

  const inviteUser = useInviteUser();

  const { control, handleSubmit, unregister } = useForm<IInviteUserForm>();

  const [isCitizenUS, setIsCitizenUS] = useState(true);
  const [isResidentUS, setIsResidentUS] = useState(true);

  const isCitizenshipCountryUS = (selected: any) => {
    setIsCitizenUS(selected.target.value === unitedStatesCountryValue);

    unregister('nationalIdentifier', { keepTouched: true });

    return selected;
  };

  const isResidencyCountryUS = (selected: any) => {
    setIsResidentUS(selected.target.value === unitedStatesCountryValue);

    unregister(['state', 'zipCode'], { keepTouched: true });

    return selected;
  };

  const onSubmit = (data: IInviteUserForm) => {
    const duplicateEmail = businessMembers.filter(
      (businessMember: { email: string }) => {
        return businessMember.email === data.email;
      },
    );

    if (duplicateEmail.length) {
      Toast.show('info', `${data.email} has already been invited`);
      return;
    }

    const {
      address1,
      address2,
      city,
      state,
      zipCode,
      dateOfBirth,
      nationalIdentifier,
      ...rest
    } = data;

    const peopleData = {
      ...rest,
      dateOfBirth: formattedDateOfBirth(dateOfBirth),
      nationalIdentifier: formattedNationalIdentifier(nationalIdentifier),
      addresses: [
        {
          address1,
          address2,
          city,
          state,
          zipCode,
        },
      ],
    };

    inviteUser.mutate(
      { ...peopleData },
      {
        onSuccess: (inviteUserData, inviteUserVariables) => {
          Toast.show(
            'success',
            `${inviteUserVariables.firstName} ${
              inviteUserVariables.lastName
            } invited ${businessName ? ` to ${businessName}` : ''}`,
          );

          history.push(RoutesPath.pages.settings.pages.team.path);

          trackEvent({
            eventName: TrackEventName.TeamMemberInviteFormSubmitSucceeded,
          });
        },
      },
    );

    trackEvent({
      eventName: TrackEventName.TeamMemberInviteFormSubmitted,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormFieldContainer variant={FormFieldContainerVariant.Small}>
        <TextFieldController {...formFields.firstName} control={control} />
      </FormFieldContainer>

      <FormFieldContainer variant={FormFieldContainerVariant.Small}>
        <TextFieldController {...formFields.lastName} control={control} />
      </FormFieldContainer>

      <FormFieldContainer variant={FormFieldContainerVariant.Small}>
        <TextFieldController {...formFields.email} control={control} />
      </FormFieldContainer>

      <FormFieldContainer variant={FormFieldContainerVariant.Small}>
        <TextFieldController {...formFields.jobTitle} control={control} />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldController {...formFields.dateOfBirth} control={control} />
      </FormFieldContainer>

      <FormFieldContainer variant={FormFieldContainerVariant.Small}>
        <AutocompleteController
          {...formFields.citizenshipCountry}
          control={control}
          defaultValue={unitedStatesCountryValue}
          onAfterChange={isCitizenshipCountryUS}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldController
          {...(isCitizenUS
            ? formFields.USANationalIdentifier
            : formFields.nonUSANationalIdentifier)}
          control={control}
        />
      </FormFieldContainer>

      <FormFieldContainer variant={FormFieldContainerVariant.Small}>
        <AutocompleteController
          {...formFields.residencyCountry}
          control={control}
          defaultValue={unitedStatesCountryValue}
          onAfterChange={isResidencyCountryUS}
        />
      </FormFieldContainer>

      <FormFieldContainer variant={FormFieldContainerVariant.Small}>
        <FormControl label="Address">
          <AddressFormSection
            control={control}
            isUS={isResidentUS}
            variant={AddressFormSectionVariant.Residential}
          />
        </FormControl>
      </FormFieldContainer>

      <Box textAlign="right">
        <Button
          disabled={inviteUser.isLoading}
          type="submit"
          variant="contained"
        >
          <ButtonContent>
            {inviteUser.isLoading ? <Spinner height={22} /> : 'Add Team Member'}
          </ButtonContent>
        </Button>

        <Box mt={3}>
          <i>
            Once invited, a new user will not have immediate access to the
            platform. Please allow 1-2 business days for setup of new user
            accounts.
          </i>
        </Box>
      </Box>
    </form>
  );
};
