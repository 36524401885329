import React from 'react';
import { i18nNamespace } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';

interface Props {
  overMaxAmountMessage: React.ReactNode;
}

export const OverWithdrawalLimitMessage: React.FC<Props> = ({
  overMaxAmountMessage,
}) => {
  const { t } = useTranslation(i18nNamespace);

  return (
    <Box mt={3} textAlign="center">
      <Typography color="red2" variant={TypographyVariant.Paragraph2}>
        {overMaxAmountMessage}
      </Typography>
    </Box>
  );
};
