import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';

export interface ActivityTotalsItemProps {
  label: string;
  trendDirection: string;
  value: number;
}

const ActivityTotalsItem: React.FC<ActivityTotalsItemProps> = ({
  label,
  trendDirection,
  value,
}) => {
  // TODO: handle label content in BFF
  return (
    <Box textAlign="center">
      <Typography color="gothic" variant={TypographyVariant.SmallLabel2}>
        {label === 'Debit' ? 'Operating' : label}
      </Typography>

      <Typography color="nero" variant={TypographyVariant.SubHeader2}>
        {value}
      </Typography>
    </Box>
  );
};

ActivityTotalsItem.propTypes = {
  label: PropTypes.string.isRequired,
  trendDirection: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export { ActivityTotalsItem };
