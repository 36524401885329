import React from 'react';
import styled, { css } from 'styled-components';
import { InputAdornment as InputAdornmentAlias } from '@material-ui/core';

interface IOverMaxAmount {
  isOverMaxAmount: boolean;
}

interface InputAdornmentProps extends IOverMaxAmount {
  placeholderShown: boolean;
}

const InputAdornment = styled(
  ({ isOverMaxAmount, placeholderShown, ...rest }) => (
    <InputAdornmentAlias {...rest} />
  ),
)<InputAdornmentProps>`
  ${(props) => {
    if (!props.placeholderShown) {
      return css`
        .MuiTypography-colorTextSecondary {
          color: var(--color-black19);
        }
      `;
    }

    return null;
  }};

  ${(props) =>
    props.isOverMaxAmount &&
    css`
      .MuiTypography-colorTextSecondary {
        color: var(--color-red2);
      }
    `}}
`;

const NumberFormatContainer = styled(({ isOverMaxAmount, ...rest }) => (
  <div {...rest} />
))<IOverMaxAmount>`
  ${(props) =>
    props.isOverMaxAmount &&
    css`
      .MuiInputBase-input {
        color: var(--color-red2);
      }
    `}}
`;

export const Styled = {
  InputAdornment,
  NumberFormatContainer,
};
