import React from 'react';
import PropTypes from 'prop-types';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
  VerticalStepper,
} from 'components/core';
import { SweepType } from 'enums';
import { StepProps } from 'components/core/vertical-stepper/vertical-stepper';
import { ReactComponent as TransferInitiatedCoins } from 'assets/images/transfer-initiated-coins-green.svg';
import { i18n, useTranslation, Trans } from 'app/i18n';
import { Styled } from './sweep-confirmation.style';
import Content from './sweep-confirmation.content.json';

const i18nSweepConfirmation = 'sweep-confirmation';
i18n.addResourceBundle('en', i18nSweepConfirmation, Content);

export interface SweepConfirmationProps {
  transfer: any;
  direction?: SweepType | null;
}

// TODO: refactor
// MF - I don't think we should default any of this information,
// it should be explicit so we don't run into weird scenarios
// where we display the user with wrong information
const SweepConfirmation: React.FC<SweepConfirmationProps> = ({
  transfer,
  direction = SweepType.Deposit,
}) => {
  const { t } = useTranslation(i18nSweepConfirmation);

  let transferStepsObj: StepProps[] = [
    {
      label: 'Deposit requested',
      description: (
        <Typography color="nero" variant={TypographyVariant.Body}>
          <Currency
            number={transfer.totalAmount}
            variant={CurrencyVariant.Full}
          />
          {` from ${transfer?.accountFrom?.name}`}
        </Typography>
      ),
    },
    {
      label: 'Deposit complete',
      description: 'Start earning once your deposit lands at Treasure',
    },
  ];

  if (
    transfer.direction === SweepType.Withdrawal ||
    direction === SweepType.Withdrawal
  ) {
    transferStepsObj = [
      {
        label: 'Withdrawal requested',
        description: (
          <Typography color="nero" variant={TypographyVariant.Body}>
            <Currency
              number={transfer.totalAmount}
              variant={CurrencyVariant.Full}
            />
            {` to ${transfer.accountTo.name}`}
          </Typography>
        ),
      },
      {
        label: 'Withdrawal complete',
        description: 'To your bank account within the next business day',
      },
    ];
  }

  if (
    (transfer.direction === SweepType.Withdrawal ||
      direction === SweepType.Withdrawal) &&
    !transfer.amount1DayLiquidity &&
    transfer.amount3DayLiquidity
  ) {
    const transferStepsObj3DayLiquidity = [
      {
        label: <Trans t={t} i18nKey="Withdrawal requested" />,
        description: (
          <Typography color="nero" variant={TypographyVariant.Body}>
            <Trans
              t={t}
              i18nKey="From Treasure"
              components={{
                withdrawalAmount: (
                  <Currency
                    number={transfer.amount3DayLiquidity}
                    variant={CurrencyVariant.Full}
                  />
                ),
              }}
            />
          </Typography>
        ),
      },
      {
        label: <Trans t={t} i18nKey="Withdrawal complete" />,
        description: (
          <Trans
            t={t}
            i18nKey="To your bank account"
            count={3}
            components={{
              bankAccount: transfer.accountTo.name,
            }}
          />
        ),
      },
    ];

    return (
      <Styled.ConfirmationContainer>
        <TransferInitiatedCoins />
        <Styled.VerticalStepperContainer>
          <VerticalStepper
            color="stromboli"
            steps={transferStepsObj3DayLiquidity}
            activeStep={0}
          />
        </Styled.VerticalStepperContainer>
      </Styled.ConfirmationContainer>
    );
  }

  if (
    (transfer.direction === SweepType.Withdrawal ||
      direction === SweepType.Withdrawal) &&
    transfer.amount1DayLiquidity &&
    transfer.amount3DayLiquidity
  ) {
    transferStepsObj = [
      {
        label: <Trans t={t} i18nKey="Withdrawal requested" />,
        description: (
          <Typography color="nero" variant={TypographyVariant.Body}>
            <Trans
              t={t}
              i18nKey="From Treasure"
              components={{
                withdrawalAmount: (
                  <Currency
                    number={transfer.amount1DayLiquidity}
                    variant={CurrencyVariant.Full}
                  />
                ),
              }}
            />
          </Typography>
        ),
      },
      {
        label: <Trans t={t} i18nKey="Withdrawal complete" />,
        description: (
          <Trans
            t={t}
            i18nKey="To your bank account"
            count={1}
            components={{
              bankAccount: transfer.accountTo.name,
            }}
          />
        ),
      },
    ];

    const transferStepsObj3DayLiquidity = [
      {
        label: <Trans t={t} i18nKey="Withdrawal requested" />,
        description: (
          <Typography color="nero" variant={TypographyVariant.Body}>
            <Trans
              t={t}
              i18nKey="From Treasure"
              components={{
                withdrawalAmount: (
                  <Currency
                    number={transfer.amount3DayLiquidity}
                    variant={CurrencyVariant.Full}
                  />
                ),
              }}
            />
          </Typography>
        ),
      },
      {
        label: <Trans t={t} i18nKey="Withdrawal complete" />,
        description: (
          <Trans
            t={t}
            i18nKey="To your bank account"
            count={3}
            components={{
              bankAccount: transfer.accountTo.name,
            }}
          />
        ),
      },
    ];

    return (
      <Styled.ConfirmationContainer>
        <TransferInitiatedCoins />
        <Styled.VerticalStepperContainer>
          <VerticalStepper
            color="stromboli"
            steps={transferStepsObj}
            activeStep={0}
          />
        </Styled.VerticalStepperContainer>

        <Styled.VerticalStepperContainer>
          <VerticalStepper
            color="stromboli"
            steps={transferStepsObj3DayLiquidity}
            activeStep={0}
          />
        </Styled.VerticalStepperContainer>
      </Styled.ConfirmationContainer>
    );
  }

  return (
    <Styled.ConfirmationContainer>
      <TransferInitiatedCoins />
      <Styled.VerticalStepperContainer>
        <VerticalStepper
          color="stromboli"
          steps={transferStepsObj}
          activeStep={0}
        />
      </Styled.VerticalStepperContainer>
    </Styled.ConfirmationContainer>
  );
};

SweepConfirmation.propTypes = {
  transfer: PropTypes.object.isRequired,
  direction: PropTypes.oneOf([...Object.values(SweepType)]),
};

export { SweepConfirmation };
