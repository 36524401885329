import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Grid } from '@material-ui/core';
import { useTracking, TrackEventName } from 'modules/tracking';
import { TaskProgressStep } from './components/task-progress-step/task-progress-step';

interface TaskProgressProps {
  activeStep?: number;

  steps: number;
}

export const TaskProgress: React.FC<TaskProgressProps> = ({
  activeStep,
  steps,
}) => {
  const { trackEvent } = useTracking();

  const handleOnClick = () => {
    trackEvent({
      component: 'Task Progress',
      eventName: TrackEventName.Click,
    });
  };

  return (
    <Grid container spacing={2}>
      {[...Array(steps)].map((step, i) => (
        <Grid item key={uuidv4()} onClick={handleOnClick} xs>
          <TaskProgressStep
            active={(activeStep && i + 1 <= activeStep) || false}
          />
        </Grid>
      ))}
    </Grid>
  );
};
