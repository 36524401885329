import React, { useEffect } from 'react';
import { i18n, useTranslation, Trans } from 'app/i18n';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';
import {
  useTracking,
  TrackEventName,
  TrackPageSection,
} from 'modules/tracking';
import Content from './global-service-issue-notification.content.json';

const i18nServiceIssueNotificationContent =
  'i18nServiceIssueNotificationContent';
i18n.addResourceBundle('en', i18nServiceIssueNotificationContent, Content);

export const GlobalServiceIssueNotification = () => {
  const { t } = useTranslation(i18nServiceIssueNotificationContent);
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const { trackEvent } = useTracking<{
    contactSupportReason?: TrackEventName;
    eventName: TrackEventName;
    section: TrackPageSection;
  }>({
    section: TrackPageSection.GlobalServiceIssueNotification,
  });

  const handleContactCustomerSupport = () => {
    trackEvent({
      eventName: TrackEventName.ContactSupportClicked,
      contactSupportReason: TrackEventName.GlobalServiceIssue,
    });
  };

  useEffect(() => {
    if (
      isFeatureFlagEnabled(
        FeatureFlags.REACT_APP_GLOBAL_SERVICE_ISSUE_NOTIFICATION,
      )
    ) {
      trackEvent({
        eventName: TrackEventName.GlobalServiceIssueNotificationShown,
      });
    }
  }, [trackEvent]);

  return (
    <FeatureFlag
      enabled={
        <Box p={2} textAlign="center">
          <Typography variant={TypographyVariant.Body}>
            <Trans
              t={t}
              i18nKey="Our service is experiencing an issue"
              components={{
                supportLink: (
                  <a
                    href="mailto:support@treasure.tech?subject=Contact Support"
                    onClick={handleContactCustomerSupport}
                  >
                    support@treasure.tech
                  </a>
                ),
              }}
            />
          </Typography>
        </Box>
      }
      flag={FeatureFlags.REACT_APP_GLOBAL_SERVICE_ISSUE_NOTIFICATION}
    />
  );
};
