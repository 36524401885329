import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { SelectSwitch } from 'modules/2023-q3/components/forms/components/select-switch/select-switch';

interface SelectSwitchContainerProps {
  isSelecting?: boolean;
}

const SelectSwitchContainer = styled(({ isSelecting, ...rest }) => (
  <Box {...rest} />
))<SelectSwitchContainerProps>`
  ${(props) =>
    props.isSelecting &&
    css`
      height: 274px;
      overflow: scroll;
    `}
`;

export const Styled = { SelectSwitchContainer };
