export const USAZipCodeRegExp = /^\d{5}$/;

export const EINRegExp = /^\d{9}$/;

export const bankRoutingRegExp = /^\d{9}$/;

export const formattedSSNRegExp = /^\d{3}-?\d{2}-?\d{4}$/;

export const nonFormattedSSNRegExp = /^\d{9}$/;

export const onlyNumbersRegExp = /[0-9]/g;

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;
