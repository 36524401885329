import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { ClickAnchor } from 'components/core';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from './search-bar.style';

interface SearchBarProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchText,
  setSearchText,
}) => {
  const { trackEvent } = useTracking();

  const handleSearchBarChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleSearchBarFocus = () => {
    trackEvent({
      eventName: TrackEventName.MultipleAccountTableFilterFocus,
    });
  };

  return (
    <FormControl fullWidth variant="outlined">
      <Styled.SearchInput
        endAdornment={
          searchText ? (
            <InputAdornment position="end">
              <ClickAnchor onClick={() => setSearchText('')}>
                <Styled.IconClear />
              </ClickAnchor>
            </InputAdornment>
          ) : null
        }
        onChange={handleSearchBarChange}
        onFocus={handleSearchBarFocus}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        value={searchText}
      />
    </FormControl>
  );
};

SearchBar.propTypes = {
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
};
