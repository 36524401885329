import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { Typography } from 'components/core';

const Status = styled(Typography)`
  letter-spacing: 3px;
`;

const StatusBox = styled(Box)`
  background-color: var(--color-blackHaze);
  border-radius: var(--border-radius-05);
`;

export const Styled = {
  Status,
  StatusBox,
};
