import React from 'react';
import { Box } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { TreasureReserveCard } from './components/treasure-reserve-card';
import { PerformanceCharts } from './components/performance-charts';

export const displayHeader = (text: string) => {
  return (
    <Box mb={3}>
      <Typography color="nero" variant={TypographyVariant.HeaderExtraBold}>
        {text}
      </Typography>
    </Box>
  );
};

export const DashboardColumnPerformanceCharts: React.FC = () => {
  return (
    <>
      {displayHeader('Performance')}

      <PerformanceCharts />
    </>
  );
};
