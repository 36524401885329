import React from 'react';
import { TypographyVariant } from 'components/core';
import { Styled } from './wrap-table-text.style';

export const wrapTableText = (text: React.ReactNode, bold?: boolean) => {
  if (bold) {
    return (
      <Styled.TableTextLarge
        color="grey1"
        variant={TypographyVariant.Paragraph2}
      >
        {text}
      </Styled.TableTextLarge>
    );
  }

  return (
    <Styled.TableText color="grey1" variant={TypographyVariant.Paragraph2}>
      {text}
    </Styled.TableText>
  );
};
