import { Box, BoxProps } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Logo, Typography } from 'components/core';
import styled, { css } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const TRANSITION_PROPERTY = 'all 0.5s ease';

const AccountCardDisableIcon = styled(CloseIcon)`
  color: var(--color-black);
  cursor: pointer;
  transition: ${TRANSITION_PROPERTY};
`;

const AccountData = styled(Typography)`
  line-height: 20px;
  margin: 0 0 0 3px;
  letter-spacing: 1px;
  display: inline-block;
  display: flex;
  justify-content: flex-end;
`;

const AccountCardBox = styled(Box)<BoxProps & { disabled?: boolean }>`
  transition: ${TRANSITION_PROPERTY};

  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro0);
  border-radius: var(--border-radius-04);

  ${AccountData} {
    color: var(--color-black);
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--color-gainsboro0);

      img {
        filter: grayscale(100%);
        opacity: 0.5;
      }

      ${AccountCardDisableIcon} {
        transform: rotate(-45deg);
      }
    `}
`;

const AccountImageSkeleton = styled(Skeleton)`
  background-color: rgba(0, 0, 0, 0.5);
`;

const AccountMaskData = styled(Box)`
  font-weight: 800;
  color: var(--color-black);
`;

const AccountCardLogo = styled(Logo)`
  width: 35px;
`;

export const Styled = {
  AccountData,
  AccountCardBox,
  AccountCardLogo,
  AccountMaskData,
  AccountImageSkeleton,
  AccountCardDisableIcon,
};
