import React from 'react';
import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { AxiosResponse } from 'axios';

interface ForgotPasswordFields {
  email: string;
}

export const useForgotPassword = () => {
  const { treasureApi } = useTreasureApi(ApiKeys.Authentication);

  const forgotPassword: (user: ForgotPasswordFields) => Promise<AxiosResponse> =
    async (user) => {
      const { data } = await treasureApi().post(
        '/user/sendPasswordResetEmail',
        user,
      );

      return data;
    };

  return { forgotPassword };
};
