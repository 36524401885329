import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { Typography } from 'components/core';
import { breakpoints, colors, getColor } from 'styles/css-constants';

const FirstGridItem = styled(Grid)`
  ${breakpoints.down('sm')} {
    min-width: unset !important; // TODO: overwriting terrible hack found in responsive-scroll-content.style.ts
  }
`;

const ProductLegend = styled(Box)`
  background-color: ${(props) =>
    getColor(props.bgcolor as keyof typeof colors)};
`;

export const Styled = {
  FirstGridItem,
  ProductLegend,
};
