import styled from 'styled-components';
import { Backdrop as BackdropAlias, Box } from '@material-ui/core';
import { ReactComponent as CaretDown } from 'assets/icons/arrows/caret-down.svg';

const Backdrop = styled(BackdropAlias)`
  background: rgba(66, 69, 69, 0.25);
  backdrop-filter: blur(0.5px);
`;

const BoxCursor = styled(Box)`
  cursor: pointer;
`;

const CaretRight = styled(CaretDown)`
  transform: rotate(270deg);
`;

const DrawerContainer = styled.div`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Link = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Styled = {
  Backdrop,
  BoxCursor,
  CaretRight,
  DrawerContainer,
  Link,
};
