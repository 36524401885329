import styled from 'styled-components';
import { CardContent } from '@material-ui/core';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { LogoTreasureReserve } from 'assets';

const BankAccountCardHeader = styled(CardContent)`
  position: relative;
  height: 77px;
  color: var(--color-white);
  text-align: center;
  background: linear-gradient(281deg, #cb4ac6 -63.49%, #4025f3 100%);
  border: 1px solid var(--color-gainsboro2);
  border-top-left-radius: var(--border-radius-05);
  border-top-right-radius: var(--border-radius-05);
  padding-top: 8px;
`;

const EditIcon = styled(Edit)`
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 12px;
`;

const TreasureReserveLogo = styled(LogoTreasureReserve)`
  width: 40px;
  height: 40px;
`;

export const Styled = {
  BankAccountCardHeader,
  EditIcon,
  TreasureReserveLogo,
};
