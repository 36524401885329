import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@material-ui/core';
import { useTracking, TrackEventName } from 'modules/tracking';
import { useClickAway } from './hooks/use-click-away';
import { Styled } from './menu-options.style';

interface MenuOption {
  action: () => void;

  description: string;

  eventName: TrackEventName;

  path?: string;
}

interface MenuOptionsProps {
  onClickAway?: () => void;

  options: MenuOption[];
}

export const MenuOptions: React.FC<MenuOptionsProps> = ({
  onClickAway,
  options,
}) => {
  const { trackEvent } = useTracking();

  const ref = useRef(null);

  useClickAway(ref, () => {
    if (onClickAway) {
      onClickAway();
    }
  });

  const handleClick = (option: MenuOption) => {
    option.action();

    trackEvent({
      buttonText: option.description,
      eventName: option.eventName,
    });
  };

  return (
    <Styled.MenuOptions ref={ref}>
      {options.map((option) => (
        <Styled.MenuOption
          key={uuidv4()}
          onClick={() => handleClick(option)}
          onTouchStart={() => handleClick(option)} // Add onTouchStart event
          path={option.path}
        >
          {option.description}
        </Styled.MenuOption>
      ))}
    </Styled.MenuOptions>
  );
};
