import React from 'react';
import { Typography, TypographyVariant } from 'components/core';
import { useBusiness } from 'hooks/business/use-business';
import { Styled } from './letter-circle.style';

export const LetterCircle: React.FC = () => {
  const { data: business } = useBusiness();

  const companyName = business?.LegalName || business?.DBA;

  return (
    <Styled.Container alignItems="center" container justifyContent="center">
      <Typography
        color="black19"
        uppercase
        variant={TypographyVariant.Paragraph2}
      >
        {companyName.charAt(0)}
      </Typography>
    </Styled.Container>
  );
};
