import React from 'react';
import { AuthenticationCheck } from 'modules/polling/authentication-check/authentication-check';
import { IdleDetection } from 'modules/polling/idle-detection/idle-detection';

export const Polling: React.FC = ({ children }) => {
  return (
    <>
      <AuthenticationCheck />
      <IdleDetection>{children}</IdleDetection>
    </>
  );
};
