import React from 'react';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Styled } from './toast-close-button.style';

export const ToastCloseButton = () => {
  const { trackEvent } = useTracking();

  const handleOnClick = () => {
    trackEvent({
      eventName: TrackEventName.ToastNotificationClosed,
    });
  };

  return <Styled.ToastCloseButton onClick={handleOnClick} />;
};
