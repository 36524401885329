import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const Container = styled(Grid)`
  background-color: var(--color-brown6);
  border-radius: 106px;
  width: 38px;
  height: 38px;
`;

export const Styled = { Container };
