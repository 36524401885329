import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { MultiAccountTable, SearchBar } from 'modules/multi-account';
import { useTracking, TrackPageSection } from 'modules/tracking';

export const MultiAccountSelection: React.FC = () => {
  const [filterText, setFilterText] = useState('');
  const { Track } = useTracking<{
    section: TrackPageSection;
  }>({
    section: TrackPageSection.MultipleAccountModal,
  });

  return (
    <Track>
      <Box pb={5}>
        <Box p={2}>
          <SearchBar searchText={filterText} setSearchText={setFilterText} />
        </Box>

        <Box mx={2}>
          <MultiAccountTable
            filterText={filterText}
            setFilterText={setFilterText}
          />
        </Box>
      </Box>
    </Track>
  );
};
