import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Typography, TypographyVariant } from 'components/core';
import { TreasureLogo } from 'modules/dashboard/header/components';
import { RoutesPath } from 'routes/constants/routes-path';
import { Button } from 'modules/2023-q3/components/button/button';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';

export const NoAuthContainer: React.FC = ({ children }) => {
  return (
    <Box position="relative">
      <Box position="absolute" top={0} left={0} right={0}>
        <Box px={4.5} py={2.5}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <TreasureLogo />
            </Grid>

            <Grid item>
              <Link to={RoutesPath.pages.dashboard.path}>
                <Button bgColor="brown6" bgHoverColor="brown4b">
                  <Box px={2} py={1}>
                    <Typography
                      color="black19"
                      variant={TypographyVariant.Paragraph2}
                    >
                      Log In
                    </Typography>
                  </Box>
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Divider />
      </Box>

      <MobileView mobile={<Box p={3}>{children}</Box>}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          minHeight="100vh"
          px={7}
          py={3}
        >
          <Box p={8}>{children}</Box>
        </Box>
      </MobileView>
    </Box>
  );
};
