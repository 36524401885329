import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: 0 none;
  border-radius: 16px;
  height: 32px;
  min-width: 32px;
  margin: 0 var(--spacing-01);

  &:hover {
    background-color: var(--color-brown4a);
  }
`;

const Ul = styled.ul`
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: center;
`;

const Arrow = styled(Button)`
  &:disabled {
    visibility: hidden;
  }
`;

const Selected = styled(Button)`
  background-color: var(--color-black19);

  &:hover {
    background-color: var(--color-black19);
  }
`;

export const Styled = {
  Arrow,
  Button,
  Selected,
  Ul,
};
