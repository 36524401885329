import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Banking as BankingV1 } from 'modules/2023-q3/banking-page/v1/banking';
import { BankingPage } from 'modules/2023-q3/banking-page/v2/banking-page';

export const Banking: React.FC = () => {
  return (
    <FeatureFlag
      disabled={<BankingV1 />}
      enabled={<BankingPage />}
      flag={FeatureFlags.REACT_APP_2023_Q3_BANKING_PAGE_ENABLED}
    />
  );
};
