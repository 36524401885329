import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  AllocationPieChart,
  AllocationSlider,
  PieChartData,
} from 'modules/portfolio/allocation/components/allocation-modal/components';
import {
  AllocationModalRowKeys,
  getRowHeader,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-content';
import {
  Allocation,
  AllocationProducts,
  //  getAllocationMax,
  initialAllocation,
  treasureReserveProductKey,
} from 'modules/portfolio/allocation/components/allocation-modal/utils/allocation-logic/allocation-logic';
import { Styled } from 'modules/portfolio/allocation/components/allocation-modal/allocation-modal.style';

export const getAllocations = ({
  allocations,
  handleAllocationChange,
  handleResetAllocation,
}: {
  allocations: Allocation;
  handleAllocationChange: (
    allocationType: keyof AllocationProducts,
    allocationWeight: number,
  ) => void;
  handleResetAllocation: () => void;
}) => {
  const allocationPieChartData: PieChartData[] = [];

  Object.entries(allocations).map(([productKey, product]) => {
    if (productKey === treasureReserveProductKey) {
      return null;
    }

    return allocationPieChartData.push({
      name: product.name,
      color: product.color,
      value: product.allocation,
    });
  });

  const handleSliderChange = (
    allocationType: keyof AllocationProducts,
    newAllocation: number,
  ) => {
    handleAllocationChange(allocationType, newAllocation);
  };

  const showResetButton = () => {
    let changeInAllocation = false;

    Object.keys(AllocationProducts).map((productKey) => {
      if (
        allocations[productKey as keyof Allocation].allocation !==
        initialAllocation[productKey as keyof Allocation].allocation
      ) {
        changeInAllocation = true;
      }
    });

    return changeInAllocation;
  };

  const data = [
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        {getRowHeader({ rowKey: AllocationModalRowKeys.Allocation })}
      </Grid>

      {showResetButton() && (
        <Grid item>
          <Styled.RoundedButton
            onClick={handleResetAllocation}
            variant="outlined"
          >
            Reset
          </Styled.RoundedButton>
        </Grid>
      )}
    </Grid>,
  ];

  Object.entries(allocations).map(([productKey, product]) => {
    if (productKey === treasureReserveProductKey) {
      return data.push(
        <AllocationPieChart
          data={allocationPieChartData}
          height="100px"
          width="100px"
        />,
      );
    }

    return data.push(
      <Box pr={5}>
        <AllocationSlider
          allocation={product.allocation}
          color={product.color}
          eventLabel={product.name}
          onChange={(newAllocation) =>
            handleSliderChange(
              productKey as keyof AllocationProducts,
              newAllocation,
            )
          }
          /* max={getAllocationMax({ // optional UX behavior only allowing user to scroll down, not up
          allocations,
          productKey: productKey as keyof Allocation,
        })} */
        />
      </Box>,
    );
  });

  return data;
};
