import React from 'react';
import { Button } from 'modules/2023-q3/components/button/button';
import { useBusiness } from 'hooks/business/use-business';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';

interface WireInstructionsLinkProps {
  onClick: () => void;
  text?: string;
}

export const WireInstructionsLink: React.FC<WireInstructionsLinkProps> = ({
  onClick,
  text,
}) => {
  const { data: business } = useBusiness();
  const { data: treasureReserve } = useTreasureReserve(business?.Id);

  if (!treasureReserve?.custodianAccountNumber) {
    return null;
  }

  return (
    <Button
      bgColor="white"
      bgHoverColor="white"
      onClick={onClick}
      variant="text"
    >
      {text ? text : 'Looking for Wire Instructions?'}
    </Button>
  );
};
