import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Heading,
  ItemDefinition,
  Text,
} from 'components/core';
import { PositionsAssetTable } from 'modules/treasure-reserve-positions/components/positions-asset-table/positions-asset-table';
import { ValueType } from 'modules/treasure-reserve-positions/enums/value-type';
import {
  isManagedIncome,
  isManagedMoneyMarket,
  treasuryBillTickerStartsWith,
  treasuryTIPSTickerStartsWith,
} from 'modules/portfolio/utils/portfolio-product-helpers';

// hooks
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useTreasureReservePositions } from 'modules/treasure-reserve-positions/hooks/use-treasure-reserve-positions';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';

export const TreasureReservePositionsPortfolio: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { data: business } = useBusiness();
  const { data: portfolios } = usePortfolios(business?.Id);
  const { data: treasureReservePositions } = useTreasureReservePositions(
    business?.Id,
  );

  const showOther = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_PORTFOLIO_ALLOCATION_OTHER_ENABLED,
  );

  const productAmounts = portfolios?.amountsInDollars.productAmounts;

  const treasuryBills = treasureReservePositions.filter(
    (position: { ticker: string }) =>
      position.ticker?.startsWith(treasuryBillTickerStartsWith) ||
      position.ticker?.startsWith(treasuryTIPSTickerStartsWith),
  );

  // differentialte between tbills and tips
  // add value at maturity to tbills
  // add + inflation to tips
  treasuryBills.forEach(
    (tbill: {
      cusip: string;
      shares: string;
      ticker: string;
      valueAtMaturity: number;
    }) => {
      // TODO: replace with fund_type
      if (
        tbill.cusip.startsWith('912795') ||
        tbill.cusip.startsWith('912796') ||
        tbill.cusip.startsWith('912797')
      ) {
        tbill.valueAtMaturity = Number(tbill.shares);
      } else {
        tbill.ticker = tbill.ticker.replace(
          treasuryBillTickerStartsWith,
          treasuryTIPSTickerStartsWith,
        );
      }
    },
  );

  // TODO: TEMPORARY HACK managed money market
  const managedMoneyMarket = treasureReservePositions?.filter(
    (position: { ticker: string }) => isManagedMoneyMarket(position.ticker),
  );

  // TODO: TEMPORARY HACK managed income
  const managedIncome = treasureReservePositions?.filter(
    (position: { ticker: string }) => isManagedIncome(position.ticker),
  );

  const otherPositions = treasureReservePositions?.filter(
    (position: { ticker: string }) =>
      !(
        position.ticker?.startsWith(treasuryBillTickerStartsWith) ||
        position.ticker?.startsWith(treasuryTIPSTickerStartsWith)
      ) &&
      !isManagedMoneyMarket(position.ticker) &&
      !isManagedIncome(position.ticker),
  );

  const columnsSecurities = [
    {
      label: 'Ticker Symbol',
      type: ValueType.TickerSymbol,
      value: 'ticker',
    },
    { label: 'Shares', type: ValueType.Number, value: 'shares' },
    {
      label: 'Current Value',
      type: ValueType.CurrencyValue,
      value: 'dollarValue',
    },
  ];

  const columnsTreasuryBills = [
    {
      label: 'Bills & TIPS',
      type: ValueType.TickerSymbol,
      value: 'ticker',
    },
    {
      label: 'Value at Maturity',
      type: ValueType.CurrencyValue,
      value: 'valueAtMaturity',
    },
    {
      label: 'Current Value',
      type: ValueType.CurrencyValue,
      value: 'dollarValue',
    },
  ];

  return (
    <Box p={3}>
      <Box mb={2}>
        <Heading color="regalBlue" variant={2}>
          Treasure Reserve Positions
        </Heading>
      </Box>

      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item>
            <ItemDefinition
              label="Managed Money Market"
              value={
                <Text variant={3}>
                  <Currency
                    number={productAmounts?.moneyMarket}
                    variant={CurrencyVariant.Full}
                  />
                </Text>
              }
            />
          </Grid>

          <Grid item>
            <ItemDefinition
              label="Managed Treasuries"
              value={
                <Text variant={3}>
                  <Currency
                    number={productAmounts?.managedTreasuries}
                    variant={CurrencyVariant.Full}
                  />
                </Text>
              }
            />
          </Grid>

          <Grid item>
            <ItemDefinition
              label="Managed Income"
              value={
                <Text variant={3}>
                  <Currency
                    number={productAmounts?.managedIncome}
                    variant={CurrencyVariant.Full}
                  />
                </Text>
              }
            />
          </Grid>

          {showOther && (
            <Grid item>
              <ItemDefinition
                label="Other"
                value={
                  <Text variant={3}>
                    <Currency
                      number={productAmounts?.otherPositions}
                      variant={CurrencyVariant.Full}
                    />
                  </Text>
                }
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <Box mb={3}>
        <Divider />
      </Box>

      {managedMoneyMarket.length > 0 && (
        <Box mb={5}>
          <Box mb={1.5}>
            <Heading color="regalBlue" variant={3}>
              Managed Money Market
            </Heading>
          </Box>

          <PositionsAssetTable
            columns={columnsSecurities}
            data={managedMoneyMarket}
          />
        </Box>
      )}

      {treasuryBills.length > 0 && (
        <Box mb={5}>
          <Box mb={1.5}>
            <Heading color="regalBlue" variant={3}>
              Managed Treasuries
            </Heading>
          </Box>

          <PositionsAssetTable
            columns={columnsTreasuryBills}
            data={treasuryBills}
          />
        </Box>
      )}

      {managedIncome.length > 0 && (
        <Box mb={5}>
          <Box mb={1.5}>
            <Heading color="regalBlue" variant={3}>
              Managed Income
            </Heading>
          </Box>

          <PositionsAssetTable
            columns={columnsSecurities}
            data={managedIncome}
          />
        </Box>
      )}

      {showOther && otherPositions.length > 0 && (
        <Box mb={5}>
          <Box mb={1.5}>
            <Heading color="regalBlue" variant={3}>
              Other
            </Heading>
          </Box>

          <PositionsAssetTable
            columns={columnsSecurities}
            data={otherPositions}
          />
        </Box>
      )}
    </Box>
  );
};
