import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Box, Grid, Hidden } from '@material-ui/core';
import { useStoreAuthentication } from 'stores';
import { GlobalServiceIssueNotification } from 'modules/page-errors/global-service-issue-notification/global-service-issue-notification';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import {
  DropdownMenu,
  Navigation,
  SweepButton,
  TreasureLogo,
} from './components';
import { HeaderButtons } from './components/header-buttons/header-buttons';
import { RequestDemoButton } from './components/request-demo-button/request-demo-button';
import { SignUpButton } from './components/sign-up-button/sign-up-button';
import { Styled } from './header.style';

export const Header = () => {
  const isSignedIn = useStoreAuthentication((state) => state.isSignedIn);

  const displaySignedInHeader = () => {
    return (
      <>
        <Grid item sm>
          <TreasureLogo />
        </Grid>

        <Hidden mdDown>
          <Navigation />
        </Hidden>

        <Grid container item justifyContent="flex-end" alignItems="center" xs>
          <FeatureFlag
            disabled={
              <>
                <SweepButton />
                <DropdownMenu />
              </>
            }
            enabled={<HeaderButtons />}
            flag={FeatureFlags.REACT_APP_2023_Q3_DEPOSIT_WITHDRAWAL_ENABLED}
          />
        </Grid>
      </>
    );
  };

  const displaySignedOutHeader = () => {
    return (
      <>
        <Grid item xs>
          <Box px={2} py={{ sm: 2 }}>
            <TreasureLogo />
          </Box>
        </Grid>

        <Grid container item justifyContent="flex-end" alignItems="center" xs>
          <RequestDemoButton />
          <SignUpButton />
        </Grid>
      </>
    );
  };

  return (
    <>
      <GlobalServiceIssueNotification />

      <Styled.Container py={{ xs: 2, lg: 0 }}>
        <CenteredContainer>
          <Grid alignItems="center" container justifyContent="space-between">
            {isSignedIn ? displaySignedInHeader() : displaySignedOutHeader()}
          </Grid>
        </CenteredContainer>
      </Styled.Container>
    </>
  );
};
