import React from 'react';
import { Text } from 'components/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  p {
    font-weight: 400;
    font-size: 13px;
    text-align: center;
  }
`;
interface SupportLinkProps {
  subjectTitle: string;
}
export const SupportLink: React.FC<SupportLinkProps> = ({ subjectTitle }) => {
  return (
    <Container>
      <Text variant={2}>
        If you have any questions, please email us{' '}
        <a href={`mailto:support@treasure.tech?subject=${subjectTitle}`}>
          support@treasure.tech
        </a>{' '}
        and we’ll respond within 24 hours
      </Text>
    </Container>
  );
};

SupportLink.propTypes = {
  subjectTitle: PropTypes.string.isRequired,
};
