import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { Box } from '@material-ui/core';
import {
  Button,
  Spinner,
  Typography,
  TypographyVariant,
} from 'components/core';
import { Toast } from 'components/layouts';
import { Person } from 'modules/onboarding/v3-streamlined-onboarding/types/person';
import {
  CheckboxController,
  FormFieldContainer,
  formFields,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { PolicyKey, ReactQueryKeys, TreasureFiLinks } from 'enums';
import { useTracking, TrackEventName } from 'modules/tracking';
import { Trans } from 'app/i18n';

// api
import { postConsents } from './api/post-consents';
import { patchPeople } from './api/patch-people';

import { PersonForm } from './components';

export const GHBAgreementForm: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { trackEvent } = useTracking();
  const queryClient = useQueryClient();
  const { clearErrors, control, getValues, handleSubmit, register, trigger } =
    useForm();

  // gets the business for the current dashboard session
  // TODO: is there a better way to get the currently active business from cache?
  const business: any = queryClient.getQueriesData(['business'])[0][1];

  const onboardingResponse: any = queryClient.getQueryData([
    'onboarding',
    business?.Id,
  ]);

  const savedAccountAgreements =
    onboardingResponse?.data.policiesKeysAgreed?.includes(
      PolicyKey.BankAccountAgreement,
    );

  const policyMutation = useMutation(postConsents, {
    onSuccess: () => {
      queryClient.invalidateQueries('onboarding');
    },
    onError: (error) => {
      Toast.showError(error);

      setLoading(false);
    },
  });

  const peopleMutation = useMutation(patchPeople, {
    onSuccess: () => {
      queryClient.invalidateQueries('onboarding');
    },
    onError: (error) => {
      Toast.showError(error);

      setLoading(false);
    },
  });

  const getPeopleRequestObject = () => {
    const values = getValues();
    const people = values.people
      ? Object.entries(values.people).map((person: any) => {
          person[1].id = person[0];
          if (person[1].ownershipPercentage === '') {
            person[1].ownershipPercentage = 0;
          }

          return person[1];
        })
      : [];

    return people;
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    const people = getPeopleRequestObject();
    await policyMutation.mutateAsync({
      policyKeys: [PolicyKey.BankAccountAgreement],
      businessId: business?.Id,
    });
    await peopleMutation.mutateAsync({ people, businessId: business?.Id });
    queryClient.invalidateQueries(ReactQueryKeys.TreasureReserve);
  };

  const renderPersonsForm = () => {
    const personOnboarding = onboardingResponse?.data.personOnboarding;

    return onboardingResponse?.data.people?.map((person: Person) => {
      // for older accounts, the person data for the person onboarding
      // could be located in personOnboarding and not the people array
      // check the people array first, if there's no data, use personOnboarding
      if (person.id === personOnboarding.id) {
        person.email = person.email || personOnboarding.email;
        person.isBusinessOwner =
          person.isBusinessOwner || personOnboarding.isBusinessOwner;
        person.isEntityOfficer =
          person.isEntityOfficer || personOnboarding.isEntityOfficer;
        person.jobTitle = person.jobTitle || personOnboarding.jobTitle;
        person.ownershipPercentage =
          person.ownershipPercentage || personOnboarding.ownershipPercentage;
        person.phoneNumber = person.phoneNumber || personOnboarding.phoneNumber;
      }

      return (
        <PersonForm
          key={person.id}
          person={person}
          control={control}
          register={register}
        />
      );
    });
  };

  // TODO: put this text in compliance content management
  return (
    <Box p={3}>
      {!loading && (
        <Box my={1}>
          <Typography color="nero" variant={TypographyVariant.Header}>
            Updated terms and information
          </Typography>
          <Box mt={1}>
            <Typography color="nero" variant={TypographyVariant.Body}>
              <Trans i18nKey="ghb agreement header" ns="compliance" />
            </Typography>
          </Box>
        </Box>
      )}

      {loading && (
        <Box mt={8}>
          <Spinner />
        </Box>
      )}

      {!loading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box my={3}>{renderPersonsForm()}</Box>
          <FormFieldContainer>
            <Box display="flex" alignItems="center">
              <CheckboxController
                {...formFields.accountAgreements}
                label="I agree to the"
                control={control}
                defaultValue={savedAccountAgreements || false}
              />
              <Box ml={-1.5}>
                <Typography variant={TypographyVariant.SubLabel} color="nero">
                  <a
                    href={TreasureFiLinks.BankAccountAgreement}
                    onClick={() =>
                      trackEvent({
                        eventName: TrackEventName.BankAccountAgreementOpened,
                        modal: 'GHB Agreement Modal',
                      })
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    Grasshopper Bank N.A. Account Agreement.
                  </a>
                </Typography>
              </Box>
            </Box>
          </FormFieldContainer>
          <Box textAlign="center" mt={3}>
            <Button type="submit" width={100}>
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};
